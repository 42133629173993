import {Outlet, useLocation} from "react-router-dom";
import React, {useCallback, useEffect} from "react";
import PageViewTracking from "../../../components/GoogleAnalytics";
import ShadowHeader from "../../../components/Header/ShadowHeader";
import {Col, Layout, Row} from "antd";
import {TitleFont} from "../../../settings";
import {PortalFooter} from "../../../components/Footer/PageFooter";
import {Content} from "antd/es/layout/layout";
import useWindowResize from "../../Hooks/useWindowResize";

const PortalLayout = ()=>{
    const {windowWidth} = useWindowResize()
    if (!(localStorage.getItem('token') && localStorage.getItem('userInfo'))) {
        window.location.href = '/artistportal/login';
    }
    const {pathname, search} =useLocation();
    const analytics = useCallback(() => {
        PageViewTracking({ path: pathname, search: search, title: pathname.split("/")[1] });
    }, [pathname, search]);

    useEffect(() => {
        analytics();
    }, [analytics]);
    return (
        <div>
            <ShadowHeader/>
            <Layout className="layout" style={{width:'100%'}}>
                <Content>
                    <Row className={'m-img-text'} justify={"center"}
                         style={{padding: '6rem 0 2rem 0',
                             backgroundColor: '#D9D9D9'
                         }}>
                        <Col className={'l-align-dk'} span={10} lg={{span: 20}} xs={20}
                             style={{marginLeft: '1.7%'}}>
                            <h2 className="title-app mobile-left"
                                style={{textAlign: "center",
                                    ...TitleFont,
                                    ...(windowWidth < 600 ? { fontSize: '2rem' } : {}),
                            }}>
                                <span style={{fontWeight: '500'}}>Vieunite</span> Artist Portal.
                            </h2>
                        </Col>
                    </Row>
                    <Outlet/>
                </Content>
                <PortalFooter/>
            </Layout>
        </div>
    );
}
export default PortalLayout;