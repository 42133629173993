import React from 'react';
import "./prostyle.scss";
import vd from '../../../img/Artists/jonathanarmour/1 - Header Image or Video/Jonathan Armour - Desktop.mp4'
import thumb_1 from "../imgs/Pack Test 01.png";
import thumb_2 from "../imgs/Pack Test 03 White 1.png";
import thumb_3 from "../imgs/Pack Test 03 Pine.png";
import thumb_4 from "../imgs/Pack Test 02 Walnut.png";
import img_1 from "../../../img/Textura/1 - Gallery/3 - Black.webp"
import img_2 from "../../../img/Textura/1 - Gallery/11 - White.webp"
import img_3 from "../../../img/Textura/1 - Gallery/1 - Pine.webp"
import img_4 from "../../../img/Textura/1 - Gallery/6 - Walnut.webp"
import img_9 from "../../../img/Textura/1 - Gallery/8 - Vieunite_Mockup_Be Noticed Design - 01.webp";
import img_6 from "../../../img/Textura/1 - Gallery/2 - Vieunite 2023 03.webp";
import img_7 from "../../../img/Textura/1 - Gallery/7 - Vieunite 2023 02.webp";
import img_8 from "../../../img/Textura/1 - Gallery/4 - alexandra-gorn-W5dsm9n6e3g-unsplash.webp";
import img_5 from "../../../img/Textura/1 - Gallery/9 - Frames.png";
// import vd_1 from "../../../img/Textura/1 - Gallery/V FINAL 1080p - Single Logo End.mp4";
import vd_1 from "../../../img/Textura/1 - Gallery/Draft-1.mp4";
import tb_1 from "../../../img/Textura/1 - Gallery/20230822-145615.webp";
import ProCarousel from "./ProCarousel";

class ProVerticalCarousel extends React.Component {
    render() {
        const data = [
            {
                video: vd_1,
                thumbnail:tb_1,
            },
            {
                image: img_1,
                thumbnail: thumb_1,
            }, {
                image: img_2,
                thumbnail: thumb_2,
            }, {
                image: img_3,
                thumbnail: thumb_3,
            }, {
                image: img_4,
                thumbnail: thumb_4,
            },   {
                image: img_5,
            }, {
                image: img_6,
            }, {
                image: img_7,
            }, {
                image: img_8,
            }, {
                image: img_9,
            },];
        const slideNumberStyle = {
            fontSize: '20px', fontWeight: 'bold',
        }

        return (
            <div style={{textAlign: "center", width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '60px'}}>
                <ProCarousel
                    data={data}
                    time={3000}
                    width="45vw"
                    height="60vh"
                    radius="10px"
                    slideNumber={false}
                    slideNumberStyle={slideNumberStyle}
                    captionPosition="bottom"
                    automatic={false}
                    dots={false}
                    pauseIconColor="white"
                    pauseIconSize="40px"
                    slideBackgroundColor="darkgrey"
                    slideImageFit="cover"
                    thumbnails={true}
                    thumbnailWidth="100px"
                    thumbnailHeight={'100px'}
                    style={{
                        textAlign: "center",
                        maxWidth: "100vw",
                        // maxHeight: "500px",
                        // margin: "50px auto",
                        // backgroundColor: 'red'
                        backgroundColor: '#f4f4f4'
                    }}
                />
            </div>
        );
    }
}

export default ProVerticalCarousel;
