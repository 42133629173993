export const UploadIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" viewBox="0 0 84 84"
                                     fill="none"
                                     style={{margin: '0px 0 16px 0'}}>
    >
    <path d="M19.3905 38.918C3.01047 40.073 3.01047 63.908 19.3905 65.063H26.1106" stroke="#727272" stroke-width="4"
          stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M19.5661 38.9193C8.33108 7.66427 55.7209 -4.83071 61.1459 27.9993C76.3009 29.9243 82.4261 50.1193 70.9461 60.1643C67.4461 63.3493 62.931 65.0993 58.206 65.0643H57.8909"
        stroke="#727272" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M59.5 57.8555C59.5 60.4455 58.9399 62.8955 57.8899 65.0655C57.6099 65.6955 57.2951 66.2905 56.9451 66.8505C53.9351 71.9255 48.37 75.3555 42 75.3555C35.63 75.3555 30.0649 71.9255 27.0549 66.8505C26.7049 66.2905 26.3901 65.6955 26.1101 65.0655C25.0601 62.8955 24.5 60.4455 24.5 57.8555C24.5 48.1955 32.34 40.3555 42 40.3555C51.66 40.3555 59.5 48.1955 59.5 57.8555Z"
        stroke="#727272" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M36.5391 57.8558L40.004 61.3208L47.459 54.4258" stroke="#727272" stroke-width="4" stroke-linecap="round"
          stroke-linejoin="round"/>
</svg>

export const BSIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 85 85"
                                 fill="none">
    <path
        d="M61.0075 36.7576V48.0625C61.0075 57.4775 57.2275 61.2576 47.8125 61.2576H36.5075C27.0925 61.2576 23.3125 57.4775 23.3125 48.0625V36.7576C23.3125 27.3426 27.0925 23.5625 36.5075 23.5625H47.8125C57.2625 23.5975 61.0075 27.3426 61.0075 36.7576Z"
        stroke="#727272" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M77.1774 20.6227V31.9277C77.1774 41.3427 73.3974 45.1227 63.9824 45.1227H61.0074V36.7928C61.0074 27.3778 57.2274 23.5977 47.8124 23.5977H39.4824V20.6227C39.4824 11.2077 43.2624 7.42773 52.6774 7.42773H63.9824C73.3974 7.42773 77.1774 11.2077 77.1774 20.6227Z"
        stroke="#727272" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M7.17773 64.2324V52.9274C7.17773 43.5124 10.9577 39.7324 20.3727 39.7324H23.3477V48.0623C23.3477 57.4773 27.1277 61.2574 36.5427 61.2574H44.8727V64.2324C44.8727 73.6474 41.0927 77.4274 31.6777 77.4274H20.3727C10.9577 77.4274 7.17773 73.6474 7.17773 64.2324Z"
        stroke="#727272" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

export const BSUploadIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80"
                                       fill="none">
    <path
        d="M56.0588 66.6684C60.5813 66.7018 64.9351 65.0351 68.2763 62.0351C79.3126 52.5018 73.4063 33.3684 58.8601 31.5684C53.6626 0.435115 8.20132 12.2351 18.9676 41.8684"
        stroke="#292D32" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M24.5698 43.2334C22.7811 42.3334 20.7898 41.8668 18.7986 41.9001C3.0711 43.0001 3.10485 65.6001 18.7986 66.7001"
        stroke="#292D32" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M53.3926 32.9682C55.1476 32.1016 57.0376 31.6349 58.9951 31.6016" stroke="#292D32" stroke-width="3"
          stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M43.7734 66.668H30.2734" stroke="#292D32" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M37.0234 73.3333V60" stroke="#292D32" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"
          stroke-linejoin="round"/>
</svg>