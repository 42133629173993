import React from "react";
import {Helmet} from "react-helmet";

export const A17Meta = () => (
    <Helmet>
        <title> Angélique Nagovskaya | Vieunite</title>
        <meta name="description"
              content="Canadian-American artist. Her paintings aim to invoke universal human experiences and emotions. Depicting figures without clear identity, her works become representations of shared humanity."/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Angelique Nagovskaya"/>
        <meta name="twitter:description"
              value="Angelique Nagovskaya's artistic expressions blend surrealism and emotional depth. Explore her mesmerizing pieces on Vieunite's curated digital canvases."/>
        <meta name="twitter:url" value="https://vieunite.com/artists/angelique-nagovskaya"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_angelique_nagovskaya.jpg"/>
        <meta property="og:url" content="https://vieunite.com/artists/angelique-nagovskaya"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Artist | Angelique Nagovskaya"/>
        <meta property="og:description"
              content="Angelique Nagovskaya's artistic expressions blend surrealism and emotional depth. Explore her mesmerizing pieces on Vieunite's curated digital canvases."/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_angelique_nagovskaya.jpg"/>
    </Helmet>);