import React, {useEffect, useRef} from 'react';
import "./SupportMain.scss"
import {Layout} from "antd";
import {Content} from "antd/es/layout/layout";

function ProHelpRequestSupport(props) {
    useEffect(() => {
    }, []);

    return (<div>
        <Layout>
            <Content style={{backgroundColor: '#F0F0F0', fontFamily: "Proxima Nova, sans-serif"}}>
                <div className="pro-help-center-top-section">
                    <div className="pro-help-center-text-content">
                        <h2 className={'pro-help-center-subtitle'}>Vieunite Pro
                            Support</h2>
                        <h3 className={'pro-help-center-title'}>How Can We
                            Help?</h3>
                    </div>
                </div>

                <p style={{
                    width: '100%',
                    color: '#6A6A6A',
                    fontSize: '1rem',
                    fontWeight: '400',
                    paddingTop: '2rem',
                    paddingLeft: '8vw'
                }}>
                    <a href='/pro/support' style={{cursor: 'pointer', color: '#6A6A6A'}}>Support</a> / Request Support
                </p>
                {/*<div id="jotform-container"></div>*/}
                <div style={{display: 'flex', flexDirection: 'row', overflow: 'hidden'}}>
                    <div className={'spacer'}></div>
                    <div style={{
                        backgroundColor: 'white',
                        width: '50%',
                        minWidth: '25rem',
                        borderRadius: '15px',
                        margin: '2rem 0 5rem 0',
                        // padding: '3rem 0',
                        overflow: 'hidden',
                    }}>
                        <iframe
                            src="https://vieunite.com/pro-support-home/Clone_of_Support_Request_for_Vieunite.html"
                            style={{width: '100%', border: 'none', display: 'inline-block', height: '77rem'}}
                            // title="HTML Viewer"
                        />
                    </div>
                    <div className={'spacer'}></div>
                </div>
            </Content>
        </Layout>
    </div>)
}

export default ProHelpRequestSupport;