import React from 'react';
import {Col, Image, Row,} from "antd";
import QR_Img from "../../img/AppDownload/QR_app.png";
import ios_app from "../../img/AppDownload/Vieunite_app_icons_ios.webp";
import google_app from "../../img/AppDownload/Vieunite_app_icons_google.webp";

class AppDownload extends React.Component {
    componentDidMount() {
        if ((/Android/i.test(navigator.userAgent))) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.app.vieunite&gl=GB';
        }
        if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {
            window.location.href = 'https://apps.apple.com/gb/app/vieunite/id1644381065';
        }
    }

    render() {
        return (
            <div>
                <Row justify={'center'} className={'app-download'} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: "center",
                    background: "#D9D9D9",
                    columnGap: '2rem'
                }}>
                    <Col span={8} lg={8} xs={0}
                         style={{
                             marginLeft: '6vw', marginTop: '5vw',
                             backgroundColor: "white", display: "flex",
                             justifyContent: "center", alignItems: "center",
                             padding: '6rem 0 6rem 0',
                             margin: '8rem 0 4rem 0',
                             borderRadius: '20px',
                         }}>
                        <Image
                            src={QR_Img}
                            preview={false}
                        />
                    </Col>
                    <Col span={8} lg={8} xs={20} style={{
                        backgroundColor: "white",
                        display: "flex", padding: '4rem 0 4rem 0',
                        marginTop: '-5%',
                        justifyContent: "center", alignItems: "center", borderRadius: '20px',
                    }}>
                        <div>
                            <div style={{marginLeft: '2vw', marginTop: '0'}}>
                                <p style={{fontWeight: 'bold', fontSize: '20px'}}>Scan the QR code</p>
                                <p style={{fontWeight: 'bold', fontSize: '20px'}}>to download the Vieunite app.</p>
                            </div>
                            <Image
                                width={'30.1%'}
                                src={ios_app}
                                preview={false}
                                style={{marginLeft: '2vw', marginTop: '1vw'}}
                                onClick={() => {
                                    window.location.href = 'https://apps.apple.com/gb/app/vieunite/id1644381065'
                                }}
                            />
                            <Image
                                width={'30.1%'}
                                src={google_app}
                                preview={false}
                                style={{marginLeft: '3vw', marginTop: '1vw'}}
                                onClick={() => {
                                    window.location.href = 'https://play.google.com/store/apps/details?id=com.app.vieunite&gl=GB'
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default AppDownload