import React from 'react';
import './style.scss'
import {ReactComponent as Logo} from "../../../pro/components/ProHeader/new_logo.svg";
import {LogoutIcon, ShopIcon, SupportIcon,} from "./icons"
import {useNavigate} from "react-router-dom";

const ProPortalHeader = (props) => {
    return (
        <div className={'tou'}>
            <div className={'pro-header'} style={{height: '5rem'}}>
                <div className={'logo'}
                     style={{
                         display: "flex",
                         cursor: 'pointer',
                         alignItems: "center",
                         justifyContent: "center"
                     }}
                     onClick={() => {
                         window.location.href = '/business'
                     }}
                >
                    <Logo/>
                </div>
                <div className={'bp-logo-hd'}>
                    {localStorage.getItem('subscription')==='Standard'&&<div className={'bp-hd-icon-div'}
                         style={{
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "center",
                             justifyContent: "center", cursor: "pointer",
                         }}
                         onClick={() => {
                             window.location.href = window.location.origin + '/proportal/shopping-cart'
                         }}
                    >
                        <ShopIcon/>
                        <p>
                            Shop
                        </p>
                    </div>}
                    <div className={'bp-hd-icon-div'}
                         style={{
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "center",
                             justifyContent: "center",
                             cursor: "pointer",
                         }}
                         onClick={() => {
                             window.location.href = window.location.origin + '/proportal/ProportalSupport'
                         }}
                    >
                        <SupportIcon/>
                        <p>
                            Support
                        </p>
                    </div>
                    <div className={'bp-hd-icon-div'}
                         style={{
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "center",
                             justifyContent: "center", cursor: "pointer",
                         }}
                         onClick={() => {
                             localStorage.clear();
                             window.location.href = '/business'
                         }}
                    >
                        <LogoutIcon/>
                        <p>
                            Logout
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ProPortalHeader;
