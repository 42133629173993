export const CrossIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23"
                                    fill="none">
    <ellipse cx="11.7434" cy="11.5771" rx="11.5257" ry="11" fill="#C30000"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M17.5612 8.16448C18.1784 7.6024 18.2076 6.6754 17.6263 6.09398C17.045 5.51257 16.0734 5.4969 15.4562 6.05899L11.6072 9.56403L7.75827 6.05899C7.14103 5.4969 6.16944 5.51257 5.58816 6.09398C5.00688 6.6754 5.03604 7.6024 5.65328 8.16448L9.3987 11.5752L5.64871 14.9901C5.03147 15.5522 5.00232 16.4792 5.5836 17.0606C6.16487 17.6421 7.13646 17.6577 7.7537 17.0956L11.6072 13.5864L15.4608 17.0956C16.078 17.6577 17.0496 17.6421 17.6309 17.0606C18.2122 16.4792 18.183 15.5522 17.5658 14.9901L13.8158 11.5752L17.5612 8.16448Z"
          fill="white"/>
</svg>
export const CheckIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23"
                                    fill="none">
    <ellipse cx="12.013" cy="11.4316" rx="11.5257" ry="11" fill="#04C300"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M18.7633 7.41377C19.2685 6.7572 19.128 5.83951 18.4496 5.36405C17.7712 4.88859 16.8118 5.0354 16.3066 5.69196L9.79412 14.1558L7.51156 12.2429C6.87661 11.7108 5.90718 11.7737 5.34628 12.3833C4.78537 12.993 4.84539 13.9186 5.48034 14.4507L8.93425 17.3452C8.98966 17.3987 9.05019 17.4488 9.11574 17.4947C9.5289 17.7843 10.0463 17.8431 10.5019 17.6965C10.754 17.6168 10.9883 17.4738 11.1765 17.2692C11.229 17.2121 11.2761 17.1522 11.3178 17.0901L18.7633 7.41377Z"
          fill="white"/>
</svg>
