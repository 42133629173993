import React, {useEffect, useRef, useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import homeExample1 from "./img/app1.png"
import homeExample2 from "./img/app2.png"
import homeExample3 from "./img/app3.png"
import homeExample4 from "./img/app4.png"
import homeExample5 from "./img/app5.png"
import homeExample6 from "./img/app6.png"
import "./VieuniteApp.css"
import "./HomePageJun.scss"
import {HomepageMeta} from "../../components/PageMeta/HomepageMeta";
import {Content} from "antd/es/layout/layout";
import {Layout} from "antd";

function VieuniteApp() {
    const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
    // const isMobile = true;

    const [scrollY, setScrollY] = useState(0);

    // useEffect(() => {
    //     console.log("trigger use effect")
    //     // setScale(scale)
    //     window.addEventListener('scroll', () => {
    //         let supportPageOffset = window.pageXOffset !== undefined;
    //         let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
    //         let scroll = {
    //             x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
    //             y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
    //         };
    //
    //         console.log("scrolling distance", scroll.y)
    //         let viewportHeight = window.innerHeight;
    //         let scrollYInVH = (scroll.y / viewportHeight) * 100;
    //         setScrollY(scrollYInVH)
    //     }, true);
    // }, []);

    useEffect(() => {
        let isScrolling;
        const handleScroll = (event) => {
            if (isMobile) { return }
            let supportPageOffset = window.pageXOffset !== undefined;
            let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
            let scroll = {
                x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
                y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
            };

            // console.log("scrolling distance", scroll.y)
            let viewportHeight = window.innerHeight;
            let scrollYInVH = (scroll.y / viewportHeight) * 100;
            setScrollY(scrollYInVH)
        };

        window.addEventListener('scroll', handleScroll);

        const handleWheel = (event) => {
            if (isMobile) { return }

            const viewportHeight = window.innerHeight;

            const { deltaY } = event;
            // console.log("delta Y", deltaY)

            clearTimeout(isScrolling);

            isScrolling = setTimeout(() => {
                const scrollY = window.scrollY;
                const viewportHeight = window.innerHeight;
                let pageIndex = 0;

                if (deltaY > 0) {
                    // Scrolling down
                    pageIndex = Math.ceil(scrollY / viewportHeight);
                } else {
                    // Scrolling up
                    pageIndex = Math.floor(scrollY / viewportHeight);
                }

                const targetScrollY = pageIndex * viewportHeight;

                window.scrollTo({
                    top: targetScrollY,
                    behavior: 'smooth',
                });
            }, 150);
        };
        window.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('wheel', handleWheel);
        }
    }, []);

    return (
        <div>
            {/*<HomepageMeta></HomepageMeta>*/}
            <Layout>
                <Content>
                    <div className="rainbowContainer">
                        <div className="rainbowContainContainer"
                             style={{top: isMobile ? 0 : `${scrollY}vh`, height: isMobile ? '1150px' : '100vh'}}>
                            <div className="spacer" style={{minHeight: '100px'}}></div>
                            <div style={{height: '10rem', margin: '0 8vw'}}>
                                <p className="rainbowTitleText">Vieunite App.</p>
                                <p className="rainbowBodyText">Unlock a new world of art discovery and appreciation with
                                    Vieunite, an online platform designed to connect you with the art community and the
                                    beauty of visual expression.</p>
                            </div>
                            <div className="spacer" style={{minHeight: '40px'}}></div>
                            <img className="rainbowBodyImg2" src={homeExample1} alt="App demo 1"/>
                            <div className="spacer" style={{minHeight: '40px'}}></div>
                        </div>
                    </div>

                    <div className="rainbowContainer">
                        <div
                            className="rainbowContainContainer"
                            style={{
                                top: isMobile ? 0 : `${scrollY - 100}vh`,
                                height: isMobile ? '1150px' : '100vh'
                            }}
                        >
                            <div className="spacer" style={{minHeight: '100px'}}></div>
                            <div style={{height: '10rem', margin: '0 8vw'}}>
                                <p className="rainbowTitleText">Discover Art.</p>
                                <p className="rainbowBodyText">Access a new world of expertly curated artwork from
                                    world-renowned artists and galleries and immerse yourself into the beauty of visual
                                    expression like never before.</p>
                            </div>
                            <div className="spacer" style={{minHeight: '40px'}}></div>
                            <img className="rainbowBodyImg2" src={homeExample2} alt="App demo 2"/>
                            <div className="spacer" style={{minHeight: '40px'}}></div>
                        </div>
                    </div>

                    <div className="rainbowContainer">
                        <div className="rainbowContainContainer"
                             style={{
                                 top: isMobile ? 0 : `${scrollY - 200}vh`,
                                 height: isMobile ? '1150px' : '100vh'
                             }}>
                            <div className="spacer" style={{minHeight: '100px'}}></div>
                            <div style={{height: '10rem', margin: '0 8vw'}}>
                                <p className="rainbowTitleText">Showcase Treasured Moments.</p>
                                <p className="rainbowBodyText">Upload your own photography or memories of family and
                                    friends
                                    in the form of photos and videos.</p>
                            </div>
                            <div className="spacer" style={{minHeight: '40px'}}></div>
                            <img className="rainbowBodyImg2" src={homeExample3} alt="App demo 3"/>
                            <div className="spacer" style={{minHeight: '40px'}}></div>
                        </div>
                        {/*<img className="rainbowBodyImg2" src={homeExample2} alt="App demo 2"/>*/}
                    </div>

                    <div className="rainbowContainer">
                        <div className="rainbowContainContainer"
                             style={{
                                 top: isMobile ? 0 : `${scrollY - 300}vh`,
                                 height: isMobile ? '1150px' : '100vh'
                             }}>
                            <div className="spacer" style={{minHeight: '100px'}}></div>
                            <div style={{height: '10rem', margin: '0 8vw'}}>
                                <p className="rainbowTitleText">Use Vieunite, Your Way.</p>
                                <p className="rainbowBodyText">Easily group all your artwork and personal content,
                                    create
                                    albums and publish multiple favourite artworks to Textura with one click.
                                </p>
                            </div>

                            <div className="spacer" style={{minHeight: '40px'}}></div>
                            <img className="rainbowBodyImg2" src={homeExample4} alt="App demo 4"/>
                            <div className="spacer" style={{minHeight: '40px'}}></div>
                        </div>
                    </div>

                    <div className="rainbowContainer">
                        <div className="rainbowContainContainer"
                             style={{
                                 top: isMobile ? 0 : `${scrollY - 400}vh`,
                                 height: isMobile ? '1150px' : '100vh'
                             }}>
                            <div className="spacer" style={{minHeight: '100px'}}></div>
                            <div style={{height: '10rem', margin: '0 8vw'}}>
                                <p className="rainbowTitleText">Take Full Control of Your Textura.</p>
                                <p className="rainbowBodyText">Simplify management by scheduling content, adjusting
                                    brightness, volume, and even image duration.
                                </p>
                            </div>

                            <div className="spacer" style={{minHeight: '40px'}}></div>
                            <img className="rainbowBodyImg2" src={homeExample5} alt="App demo 5"/>
                            <div className="spacer" style={{minHeight: '40px'}}></div>
                        </div>
                    </div>

                    <div className="rainbowContainer">
                        <div className="rainbowContainContainer"
                             style={{
                                 top: isMobile ? 0 : `${scrollY - 500}vh`,
                                 height: isMobile ? '1150px' : '100vh'
                             }}
                        >
                            <div className="spacer" style={{minHeight: '100px'}}></div>
                            <div style={{height: '10rem', margin: '0 8vw'}}>
                                <p className="rainbowTitleText">Download the Vieunite App</p>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "0 0 3rem 0",
                                    flexDirection: isMobile ? 'column' : 'row',

                                }}>
                                    <button className="homeMainButton"
                                            style={{width: "15rem", minWidth: "200px", margin: "1rem"}}
                                            onClick={() => window.open("https://apps.apple.com/gb/app/vieunite-discover-share-art/id1644381065")}
                                    >
                                        <a className={'homeMainButtonLnk homeOverV'}>
                                            IOS
                                        </a>
                                    </button>
                                    <button className="homeMainButton"
                                            style={{width: "15rem", minWidth: "200px", margin: "1rem"}}
                                            onClick={() => window.open("https://play.google.com/store/apps/details?id=com.app.vieunite&gl=GB")}
                                    >
                                        <a className={'homeMainButtonLnk homeOverV'}>
                                            GOOGLE PLAY
                                        </a>
                                    </button>
                                </div>
                            </div>

                            <div className="spacer" style={{minHeight: '40px'}}></div>
                            <img className="rainbowBodyImg2" src={homeExample6} alt="App demo 1"/>
                            <div className="spacer" style={{minHeight: '40px'}}></div>
                        </div>
                    </div>
                </Content>
            </Layout>


        </div>
    );
}

export default VieuniteApp;
