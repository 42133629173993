import React, {useEffect, useState} from 'react';
import {message, Tooltip} from 'antd';
import {Button, Checkbox, Col, Dropdown, Image, Input, Layout, Menu, Modal, Row, Select, Space} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import {useLocation, useNavigate} from "react-router-dom";
import ProPortalHeader from "../../componets/ProPortalHeader";
import ProfileRow from "../../componets/ProfileRow";
import Navigator from "../../componets/Navigator";
import {DownOutlined, PlayCircleFilled, PlayCircleOutlined, PlayCircleTwoTone} from "@ant-design/icons";
import SubMenu from "antd/es/menu/SubMenu";
import {get_api, post_api} from "../../serverTemp";
import {BSUploadIcon} from "../icons";
import MBModal from "../MBModal";
import ImgDisplayModal from "./ImgDisplayModal";
import CoverLayout from "../../ProDiscovery/AlbumCoverLayout";
import left from "../../ProDiscovery/images/left.png";
import upRight from "../../ProDiscovery/images/upRight.png";
import downRight from "../../ProDiscovery/images/downRight.png";
import {capitalizeFirstLetter} from "../../utils/stringOps";
import backButton from "../../MyAccount/ExtraServices/UpgradeSubscription/img/Back button.svg";
import {ReactComponent as BackIcon} from "./Back Button and Text.svg";
import {ReactComponent as PlayIcon} from "./Play button.svg";


export function TruncateText({text, maxLength, className}) {
    if (text.length <= maxLength) {
        return <p className={className}>{text}</p>;
    } else {
        return (
            <Tooltip title={text} placement="bottom">
                <p className={className}>{`${text.substring(0, maxLength)}...`}</p>
            </Tooltip>
        );
    }
}

const ImgDisplay = (props) => {
    const {state} = useLocation()
    const {filter, title} = state
    const albumID = state.albumID ?? -1
    const textura_ids = state.textura_ids ?? ''
    const checkMode = state.checkMode ?? false
    const removeMode = state.removeMode ?? false
    const myArtMode = state.myArtMode ?? false
    const repeatMode = state.repeatMode ?? false
    const [imgPreviewModalOpen, setImgPreviewModalOpen] = useState(false);
    const [curPreviewData, setCurPreviewData] = useState({});
    const [images, setImages] = useState([])
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAlbumModalOpen, setIsAlbumModalOpen] = useState(false);
    const [artworkName, setArtworkName] = useState('');
    const [user, setUser] = useState({})
    const [searchVal, setSearchVal] = useState('')
    const [orgData, setOrgData] = useState([])
    const [activeTab, setActiveTab] = useState('uploaded');
    const [curatedImages, setCuratedImages] = useState([])
    const [orgCuratedImages, setOrgCuratedImages] = useState([])
    const [categories, setCategories] = useState([])
    const [checked, setChecked] = useState([])
    const [albums, setAlbums] = useState([])
    const [selectedAlbum, setSelectedAlbum] = useState('')
    const [curImg, setCurImg] = useState({})
    const [savedImg, setSavedImg] = useState([])
    const [orgSavedImg, setOrgSavedImg] = useState([])
    const defaultAlbum = localStorage.getItem('user_id').toString() + '_default'

    console.log(state)
    const subsMap = {
        0: "Standard",
        1: "Advanced",
        2: "Premium"
    }
    useEffect(() => {
        get_api('/artwork/recommendation/categories').then(res => {
            const ct = res.data.map(item => {
                return {
                    id: item.id,
                    txt: capitalizeFirstLetter(item.text)
                }
            })
            console.log(ct)
            setCategories(ct)
        })
    }, [])
    useEffect(() => {
        get_api('/album/all').then(
            res => {
                setAlbums(res.data.map((album) => {
                    return {
                        id: album.id,
                        name: album.name,
                        urls: album.url,
                        item: {
                            left: album.url.split(',')[0] ?? null,
                            upRight: album.url.split(',')[1] ?? null,
                            downRight: album.url.split(',')[2] ?? null,
                        },
                        number: album.numberOfArtworks,
                    }
                }))
            }
        )
    }, [])
    // useEffect(() => {
    //     get_api('/album/filter/uploaded/artworks?uploaded_artwork_filter=2').then(res => {
    //         return res?.data
    //     }).then((preData) => {
    //         get_api(`/album/artworks?album_id=${localStorage.getItem('user_id')}_default`).then(res => {
    //             console.log(res.data)
    //             setCuratedImages([...preData, ...res?.data])
    //             setOrgCuratedImages([...preData, ...res?.data])
    //         })
    //     });
    // }, [])
    useEffect(() => {
        const fetchData = async (path, page = 0, accumulatedData = []) => {
            const res = await get_api(`${path}&page=${page}`);
            const data = [...accumulatedData, ...res.data];

            if (res.has_more) {
                return fetchData(path, page + 1, data);
            } else {
                return data;
            }
        };

        const fetchInitialData = async () => {
            const uploadedArtworkPath = '/album/filter/uploaded/artworks?uploaded_artwork_filter=2';
            const defaultAlbumPath = `/album/artworks?album_id=${localStorage.getItem('user_id')}_default`;

            const uploadedArtworks = await fetchData(uploadedArtworkPath);
            const defaultAlbumArtworks = await fetchData(defaultAlbumPath);

            const combinedData = [...uploadedArtworks, ...defaultAlbumArtworks];
            setCuratedImages(combinedData);
            setOrgCuratedImages(combinedData);
        };

        fetchInitialData();
    }, []);
    useEffect(() => {
        get_api('/user/detail').then(res => {
            setUser(res)
        })
    }, [])
    const handleInputChange = (e) => {
        setArtworkName(e.target.value); // Update state with input value
    };

    const handleConfirmClick = () => {
        post_api('',)
            .then(res => {

            })
        setIsModalOpen(false); // Close the modal after confirmation
    };
    const MBModalprops = {
        isModalOpen: isModalOpen,
        setIsModalOpen: setIsModalOpen,
        handleConfirmClick: handleConfirmClick,
        handleInputChange: handleInputChange,
        albumName: artworkName,
    }
    useEffect(() => {
        let apiPath = '';

        switch (filter) {
            case 'pls':
                apiPath = `/playlist/artworks?playlist_id=${albumID}`;
                break;
            case 'album':
                apiPath = `/album/artworks?album_id=${albumID}`;
                break;
            case -1:
                apiPath = '/album/uploaded/artworks?';
                break;
            case 'current':
                apiPath = `/textura/publish/get/hardware/current/artworks?textura_id=${textura_ids[0]}`;
                break
            case 'myArtMode':
                apiPath = `/album/filter/uploaded/artworks?uploaded_artwork_filter=2`
                break
            default:
                apiPath = `/album/filter/uploaded/artworks?uploaded_artwork_filter=${filter}`;
                break;
        }

        const fetchData = async (path, page = 0, accumulatedData = []) => {
            const res = await get_api(`${path}&page=${page}`);
            const data = [...accumulatedData, ...res.data];

            if (res.has_more) {
                return fetchData(path, page + 1, data);
            } else {
                return data;
            }
        };

        if (apiPath) {
            // get_api(apiPath).then(res => {
            //     if (res.has_more){
            //         //there are more than one page
            //     }
            //     else{
            //         return res?.data
            //     }
            //     // setImages(res.data);
            //     // setOrgData(res.data);
            //     // console.log(res.data);
            // })
            fetchData(apiPath).then((preData) => {
                if (myArtMode) {
                    get_api(`/album/artworks?album_id=${localStorage.getItem('user_id')}_default`).then(res => {
                        console.log('get_api', res.data)
                        setImages([...preData, ...res?.data])
                        setOrgData([...preData, ...res?.data])
                    })
                } else {
                    setImages(preData);
                    setOrgData(preData);
                }
            });
        }
    }, [filter, albumID]);

    const handleMenuClick = (img, e, id) => {
        console.log(img, e, id)
        const handlePreview = () => {
            navigate(
                '/proportal/dashboard',
                {state: {artwork_id: id, isPreview: true, from_: img.from_}}
            )
        };

        const handlePublish = () => {
            if (textura_ids === '') {
                navigate('/proportal/dashboard', {state: {artwork_id: id, from_: img.from_}})
            } else {
                post_api('/textura/publish/artworks',
                    {
                        artwork_ids: [id],
                        textura_ids: textura_ids,
                        froms_: [img.from_]
                    }).then(res => {
                })
            }

        };

        const handleMove = () => {
            console.log(orgData, id)
            const new_artwork_ids = [id, ...orgData.filter(item => item.id !== id).map(item => item.id)]
            console.log(new_artwork_ids, albumID)

            post_api('/album/reorder', {id: albumID, artwork_ids: new_artwork_ids})
                .then(() => {
                    window.location.reload()
                })
        };

        const handleSet = () => {
            post_api('/album/set/cover', {id: albumID, url: img.url})
        };

        const handleDelete = () => {
            if (textura_ids === '') {
                if (filter === 'album' || filter === 2) {
                    post_api('/album/remove/artworks', {
                        id: albumID && albumID !== -1 ? albumID : defaultAlbum,
                        artwork_ids: [id]
                    })
                        .then(() => {
                            window.location.reload()
                        })
                        .catch(error => console.error('Error:', error))
                } else {
                    post_api('/album/delete/uploaded/artwork', {id: id})
                        .then(res => {
                            window.location.reload()
                        }).catch(error => console.error('Error:', error))
                }
            } else {
                post_api('/textura/publish/remove/artwork', {artwork_id: id, textura_id: textura_ids[0]})
                    .then(res => {

                    }).catch(error => console.error('Error:', error))
            }
        };

        const handleRename = () => {
            setIsModalOpen(true)
        };

        const handleDownload = () => {
            message.success('Success!')
            fetch(img.url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const blobUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = img.name || 'download'; // Set the filename for download
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(blobUrl); // Clean up
                })
                .catch(err => {
                    console.error('Failed to download the image', err);
                });
        };

        const handleAddToAlbum = () => {
            if (albums.length > 0) {
                setIsAlbumModalOpen(true)
                setCurImg(img)
            } else {
                message.warning("Sorry, you don't have album!")
            }
        }

        switch (e.key) {
            case 'preview':
                handlePreview();
                break;
            case 'publish':
                handlePublish();
                break;
            case 'move':
                handleMove();
                break;
            case 'set':
                handleSet();
                break;
            case 'album':
                handleAddToAlbum();
                break;
            case 'delete':
                handleDelete();
                break;
            case 'rename':
                handleRename();
                break;
            case 'download':
                handleDownload();
                break;
            default:
                console.log('Unknown action');
        }
    };
    const menu = (img, id) => (
        <Menu onClick={(e) => handleMenuClick(img, e, id)}>
            {textura_ids === '' && <Menu.Item key={'preview'}>Preview on Textura</Menu.Item>}
            <Menu.Item key={'publish'}>Publish to Textura</Menu.Item>
            {filter === 'album' && <Menu.Item key={'move'}>Move to Top</Menu.Item>}
            {filter === 'album' && <Menu.Item key={'set'}>Set as folder cover</Menu.Item>}
            <Menu.Item key={'album'}>Add into album</Menu.Item>
            {/*{filter !== 'pls' && !myArtMode && <Menu.Item key={'delete'}>Delete</Menu.Item>}*/}
            {images.filter(img => img.id === id)[0].from_ === 1 ? (filter !== 'pls' &&
                    <Tooltip
                        title="This artwork can't be deleted as you have purchased its digital copy for display on the Textura Pro Digital canvas."
                        placement="right">
                        <div style={{display: 'inline-block', width: '100%'}}>

                            <Menu.Item key={'delete'} disabled>Delete</Menu.Item>
                        </div>
                    </Tooltip>) :
                (filter !== 'pls' && <Menu.Item key={'delete'}>Delete</Menu.Item>)}
            {/*<Menu.Item key={'rename'}>Rename</Menu.Item>*/}
            {/*<Menu.Item key={'download'}>Download</Menu.Item>*/}
        </Menu>
    );

    const NavigationSpan = ({path, children}) => {
        const navigate = useNavigate();
        return (
            <span onClick={() => navigate(path)} style={{cursor: 'pointer'}}>
                    {children}
            </span>
        );
    };

    const titleMap = {
        'album': (
            <>
                <NavigationSpan path="/proportal/mylibrary">My Library</NavigationSpan> /
                <NavigationSpan path="/proportal/my-albums">My Albums</NavigationSpan> /
            </>
        ),
        'pls': (
            <></>
        ),
        'default': (
            <></>
        ),
        'current': (
            <></>
        )
    };

    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <Row
                    style={(checkMode && (!removeMode)) ? {margin: '8rem 0 0 0'} : {margin: '-6rem 0 0 0'}}>
                    <Col lg={4} style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: 'column'
                    }}>
                        <div className={'pt-profile-div'}>
                            {user.avatar ?
                                <img className={'pt-profile-div-img'}
                                     src={user?.avatar}
                                     style={{
                                         cursor: 'pointer'
                                     }}
                                     alt={'avatar'}
                                     onClick={() => {
                                         window.location.href = '/proportal/AccountSettings'
                                     }}
                                /> :
                                <div className={'pt-profile-div-avatar-out'}>
                                    <div className={'pt-profile-div-avatar'}
                                         onClick={() => {
                                             window.location.href = '/proportal/AccountSettings'
                                         }}
                                    >
                                        {user?.nickname ? user.nickname[0].toUpperCase() : ''}
                                    </div>
                                </div>
                            }
                        </div>
                        <p className={'bp-ds-profile-txt'} style={{
                            marginTop: '1rem',
                        }}>
                            {user?.nickname}
                        </p>
                        <p className={'bp-ds-profile-txt'}>
                            {user?.email}
                        </p>
                        <p className={'bp-ds-profile-txt'} style={{
                            marginBottom: '1rem'
                        }}>
                            {subsMap[user?.subscription]}
                        </p>
                    </Col>
                    <Col lg={8} style={{
                        display: checkMode || myArtMode ? 'flex' : 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '-8rem',
                    }}>
                        <div className="al-ma-tabs-container">
                            <Button
                                className={`${activeTab === 'uploaded' ? 'al-ma-active-tab' : 'al-ma-inactive-tab'}`}
                                onClick={() => setActiveTab('uploaded')}
                            >
                                {myArtMode ? 'Purchased' : 'My Uploaded'}
                            </Button>
                            <Button
                                className={`${activeTab === 'curated' ? 'al-ma-active-tab' : 'al-ma-inactive-tab'}`}
                                onClick={() => setActiveTab('curated')}
                                style={{width: '250px'}}
                            >
                                {myArtMode ? 'Saved' : 'My Vieunite Pro Art'}
                            </Button>
                        </div>
                    </Col>
                    <Col lg={10}
                         style={{
                             // display: checkMode ? 'flex' : 'none',
                             display: "none",
                             alignItems: 'center',
                             justifyContent: 'flex-end',
                             marginTop: '4rem',
                         }}
                    >
                        <Input className={'bp-ds-input'}
                               placeholder={'Search by name'}
                               onChange={(e) => {
                                   console.log(e.target.value)
                                   setSearchVal(e.target.value)
                               }}
                               onKeyDown={() => {
                                   if (searchVal === '' || !searchVal) {
                                       if (activeTab === 'curated') {
                                           setCuratedImages(orgCuratedImages)
                                       } else {
                                           setImages(orgData)
                                       }
                                   } else {
                                       if (activeTab === 'curated') {
                                           setCuratedImages(orgCuratedImages.filter((d) => d.name === searchVal))
                                       } else {
                                           setImages(orgData.filter((d) => d.name === searchVal))
                                       }
                                   }
                               }}
                        />
                        <Button className={'bp-search-btn'}
                                style={{marginLeft: '1vw'}}
                                onClick={() => {
                                    if (searchVal === '' || !searchVal) {
                                        if (activeTab === 'curated') {
                                            setCuratedImages(orgCuratedImages)
                                        } else {
                                            setImages(orgData)
                                        }
                                    } else {
                                        if (activeTab === 'curated') {
                                            setCuratedImages(orgCuratedImages.filter((d) => d.name === searchVal))
                                        } else {
                                            setImages(orgData.filter((d) => d.name === searchVal))
                                        }
                                    }
                                }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"
                                 viewBox="0 0 28 28"
                                 fill="none">
                                <path
                                    d="M12.9531 24.125C18.8557 24.125 23.6406 19.34 23.6406 13.4375C23.6406 7.53496 18.8557 2.75 12.9531 2.75C7.05058 2.75 2.26562 7.53496 2.26562 13.4375C2.26562 19.34 7.05058 24.125 12.9531 24.125Z"
                                    stroke="white" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path d="M24.7656 25.25L22.5156 23" stroke="white" stroke-width="2"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </Button>
                    </Col>
                </Row>
                {/*ImageRow*/}
                <Row>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <Col lg={24} style={{paddingBottom: '10vh'}}>
                                {activeTab === 'uploaded' &&
                                    <Row style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                    }}>

                                        <p className={'mb-dsp-title'}
                                            // style={filter === 'album' ? {margin: '6vh 0 -1.2vh 0'} : {}}
                                        >
                                            <span onClick={_ => window.history.back()}
                                                  style={{cursor: "pointer"}}
                                            >
                                                <BackIcon style={{margin: '0.4vh 0 0 0'}}/>
                                            </span>
                                            {title === 'Current Artworks on Your Textura' ? (
                                                'Current Artworks on Your Textura'
                                            ) : (
                                                <>
                                                    {titleMap[filter] || titleMap['default']}
                                                    {title}
                                                </>
                                            )}
                                        </p>
                                        {(myArtMode || title === 'My Uploads') && <div>
                                            <Button className={'mb-jsb-cate-btn'}
                                                    onClick={() => {
                                                        // setCuratedImages(orgCuratedImages.filter(d => d.width < d.height))
                                                        setImages(orgData.filter(d => d.width < d.height))
                                                    }}
                                                    style={{
                                                        marginLeft: '30vw'
                                                    }}>
                                                Portrait
                                            </Button>
                                            <Button className={'mb-jsb-cate-btn'}
                                                    onClick={() => {
                                                        // setCuratedImages(orgCuratedImages.filter(d => d.width > d.height))
                                                        setImages(orgData.filter(d => d.width >= d.height))
                                                    }}
                                            >
                                                Landscape
                                            </Button>
                                            <Button className={'mb-jsb-cate-btn'}
                                                    onClick={() => {
                                                        setImages(orgData)
                                                    }}
                                            >
                                                Show all
                                            </Button>
                                        </div>}
                                        {filter === 'pls' && <Button className={'mb-jsb-cate-btn'}
                                                                     onClick={() => {
                                                                         console.log(title, albumID, orgData)
                                                                         post_api('/album/add', {
                                                                             name: title
                                                                         }).then((res) => {
                                                                             console.log(res)
                                                                             post_api('/album/add/artwork',
                                                                                 {
                                                                                     id: res.id,
                                                                                     artwork_ids: orgData.map(d => d.id),
                                                                                     from_: 2
                                                                                 })
                                                                         })
                                                                     }}
                                                                     style={{
                                                                         backgroundColor: '#0E487A',
                                                                         marginLeft: '30vw',
                                                                         width: '10vw',
                                                                         color: "white",
                                                                         fontWeight: '600'
                                                                     }}>
                                            Save as an album
                                        </Button>}
                                    </Row>
                                }
                                {activeTab === 'curated' &&
                                    <Row style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                    }}>
                                        <p className={'mb-dsp-title'}>
                                            <span onClick={_ => window.history.back()}
                                                  style={{cursor: "pointer"}}>
                                            <BackIcon style={{margin: '0.4vh 0 0 0'}}/>
                                        </span>
                                            <span
                                                className={'mb-dsp-title'}
                                                style={{margin: '0 0 0 0', opacity: '1'}}
                                            >
                                                {myArtMode ? 'My Saved Vieunite Arts' : title}
                                        </span>
                                        </p>

                                        <Button className={'mb-jsb-cate-btn'}
                                                onClick={() => {
                                                    myArtMode ? setSavedImg(orgSavedImg.filter(d => d.width < d.height)) :
                                                        setCuratedImages(orgCuratedImages.filter(d => d.width < d.height))
                                                }}
                                                style={{
                                                    marginLeft: '24vw'
                                                }}>
                                            Portrait
                                        </Button>
                                        <Button className={'mb-jsb-cate-btn'}
                                                onClick={() => {
                                                    myArtMode ? setSavedImg(orgSavedImg.filter(d => d.width >= d.height)) :
                                                        setCuratedImages(orgCuratedImages.filter(d => d.width >= d.height))
                                                }}
                                        >
                                            Landscape
                                        </Button>
                                    </Row>
                                }
                                {/*Image displaying part*/}
                                {activeTab === 'uploaded' && <div>
                                    {checkMode && images.length > 0 && <Row justify={'center'}
                                                                            style={{
                                                                                margin: '-2.4rem 0 0 0',
                                                                                gap: '2rem'
                                                                            }}
                                    >
                                        <Button className={'mb-img-ds-btn'}
                                                style={{
                                                    marginLeft: '70vw'
                                                }}
                                                onClick={() => {
                                                    console.log(checked)
                                                    if (checked.length === 0) {
                                                        message.info('Please select at least one artwork!')
                                                    } else {
                                                        if (!removeMode) {
                                                            post_api('/textura/publish/artworks', {
                                                                textura_ids: textura_ids,
                                                                artwork_ids: checked.map(c => c.id),
                                                                froms_: checked.map(c => c.from_),
                                                            })
                                                        } else {
                                                            if (removeMode && (!repeatMode)) {//remove
                                                                post_api('/textura/publish/remove/artworks', {
                                                                    textura_id: textura_ids[0],
                                                                    hardware_artwork_ids: checked.map(c => c.hardware_artwork_id)
                                                                }).then(_ => window.location.reload())
                                                                    .catch(error => console.error('Error:', error))
                                                            } else {//repeat
                                                                if (checked.length === 0 || checked.length > 1) {
                                                                    message.info('Please select only one artwork for repeating!')
                                                                }
                                                                else {
                                                                    post_api('/textura/loop/one/artwork/in_textura', {
                                                                        textura_id: textura_ids[0][0],
                                                                        hardware_artwork_id: checked.map(c => c.hardware_artwork_id)[0]
                                                                    }).catch(error => console.error('Error:', error))
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}
                                        >
                                            {removeMode ? (repeatMode ? 'Confirm' : 'Remove') : 'Publish'}
                                        </Button>
                                    </Row>}
                                    {/*Image Row*/}
                                    <Row justify={"start"} style={{
                                        marginLeft: '2vw',
                                        marginBottom: checkMode ? '50rem' : '50rem'
                                    }}>
                                        {images.length === 0 && myArtMode &&
                                            <p className={'mb-no-saved-img-p'} style={{marginTop: '30vh'}}>
                                                You currently have no purchases.
                                                Please visit our&nbsp;
                                                <span onClick={() => window.location.href = '/proportal/discover-art'}>Discover Art</span>&nbsp;
                                                section to get a taste of what is available
                                            </p>
                                        }
                                        {images.length === 0 && (!myArtMode) && filter !== 'current' &&
                                            <p className={'mb-no-saved-img-p'} style={{marginTop: '30vh'}}>
                                                You currently have no uploaded arts.
                                            </p>
                                        }
                                        {images.length === 0 && (!myArtMode) && filter === 'current' &&
                                            <p className={'mb-no-saved-img-p'}
                                               style={{marginTop: '30vh', marginBottom: '40rem'}}>
                                                Currently, there is no content playing on this Textura Pro canvas.
                                            </p>
                                        }
                                        {images?.map((img, idx) =>
                                            <div className={'mb-spec-img-div'}>
                                                <span className={'mb-spec-number-of-img'}>{idx + 1}</span>
                                                <img
                                                    className={'mb-spec-img-div-image'}
                                                    src={img.url}
                                                    alt={'img'}
                                                    style={
                                                        img.height > img.width ?
                                                            {
                                                                height: "205px", width: "115px",
                                                                cursor: "pointer"
                                                            } :
                                                            {
                                                                height: "124px", width: "220px",
                                                                margin: '6vh 0 3vh 0',
                                                                cursor: "pointer"
                                                            }
                                                    }
                                                    onClick={() => {
                                                        setCurPreviewData({
                                                            url: img.url,
                                                            orientation: img.height > img.width ? 'portrait' : 'landscape'
                                                        })
                                                        setImgPreviewModalOpen(true)
                                                    }}
                                                />
                                                {img?.is_video ? <PlayCircleFilled style={{
                                                    marginTop: '-6vh',
                                                    position: 'absolute',
                                                    fontSize: '1vw'
                                                }}/> : <></>}
                                                <p className={'mb-spec-img-div-p'}>
                                                    {/*{img.name}*/}
                                                    <TruncateText text={img.name} maxLength={20}/>
                                                </p>
                                                {checkMode ?
                                                    <Checkbox
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setChecked([...checked, {
                                                                    id: img.id,
                                                                    from_: img.from_,
                                                                    hardware_artwork_id: img.hardware_artwork_id ?? ''
                                                                }])
                                                            } else {
                                                                setChecked(checked.filter(c => c.id !== img.id))
                                                            }
                                                        }}>
                                                    </Checkbox> :
                                                    <Dropdown overlay={menu(img, img.id)} placement={"bottom"}>
                                                        {img.video_transformed === false ? <Button
                                                            loading
                                                            className={'mb-spec-img-div-btn'}
                                                            style={{fontSize: '0.8vw'}}
                                                        >
                                                            Video Transcoding
                                                        </Button> : <Button
                                                            className={'mb-spec-img-div-btn'}>
                                                            Select
                                                            <DownOutlined/>
                                                        </Button>}
                                                    </Dropdown>
                                                }
                                            </div>
                                        )}
                                    </Row>

                                </div>}
                                {activeTab === 'curated' && <div>
                                    {checkMode && curatedImages.length > 0 && <Row justify={'center'}
                                                                                   style={{
                                                                                       margin: '-2.4rem 0 0 0',
                                                                                       gap: '2rem'
                                                                                   }}
                                    >
                                        <Button className={'mb-img-ds-btn'}
                                                style={{
                                                    marginLeft: '70vw'
                                                }}
                                                onClick={() => {
                                                    console.log(checked)
                                                    if (checked.length === 0) {
                                                        message.info('Please select at least one artwork!')
                                                    } else {
                                                        if (!removeMode) {
                                                            post_api('/textura/publish/artworks', {
                                                                textura_ids: textura_ids,
                                                                artwork_ids: checked.map(c => c.id),
                                                                froms_: checked.map(c => c.from_),
                                                            })
                                                        } else {//remove
                                                            post_api('/textura/publish/remove/artworks', {
                                                                textura_id: textura_ids[0],
                                                                hardware_artwork_ids: checked.map(c => c.hardware_artwork_id),
                                                            })
                                                        }
                                                    }
                                                }}
                                        >
                                            {removeMode ? 'Remove' : 'Publish'}
                                        </Button>
                                    </Row>}
                                    <Row justify={"start"} style={{
                                        marginLeft: '2vw',
                                        marginBottom: checkMode ? '50rem' : '50rem'
                                    }}>
                                        {savedImg.length === 0 && myArtMode &&
                                            <p className={'mb-no-saved-img-p'}>
                                                You currently have no saved artwork.
                                                Please visit our&nbsp;
                                                <span onClick={() => window.location.href = '/proportal/discover-art'}>Discover Art</span>&nbsp;
                                                section to get a taste of what is available
                                            </p>
                                        }
                                        {curatedImages.length === 0 && (!myArtMode) &&
                                            <p className={'mb-no-saved-img-p'}>
                                                You currently have no purchased arts.
                                            </p>
                                        }
                                        {myArtMode ? savedImg?.map((img, idx) =>
                                                <div className={'mb-spec-img-div'}>
                                                    <img
                                                        className={'mb-spec-img-div-image'}
                                                        src={img.url}
                                                        alt={'img'}
                                                        style={
                                                            img.height > img.width ?
                                                                {
                                                                    height: "205px", width: "115px",
                                                                    cursor: "pointer"
                                                                } :
                                                                {
                                                                    height: "124px", width: "220px",
                                                                    margin: '6vh 0 3vh 0',
                                                                    cursor: "pointer"
                                                                }
                                                        }
                                                    />
                                                    {img?.is_video ? <PlayCircleFilled style={{
                                                        marginTop: '-6vh',
                                                        position: 'absolute',
                                                        fontSize: '1vw'
                                                    }}/> : <></>}
                                                    <p className={'mb-spec-img-div-p'}>
                                                        {img.name}
                                                    </p>
                                                    {checkMode ?
                                                        <Checkbox
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setChecked([...checked, {
                                                                        id: img.id,
                                                                        from_: img.from_,
                                                                        hardware_artwork_id: img.hardware_artwork_id ?? ''
                                                                    }])
                                                                } else {
                                                                    setChecked(checked.filter(c => c.id !== img.id))
                                                                }
                                                            }}>
                                                        </Checkbox> :
                                                        <Dropdown overlay={menu(img, img.id)} placement={"bottom"}>
                                                            <Button
                                                                className={'mb-spec-img-div-btn'}>
                                                                Select
                                                                <DownOutlined/>
                                                            </Button>
                                                        </Dropdown>
                                                    }
                                                </div>)
                                            : curatedImages?.map((img, idx) =>
                                                <div className={'mb-spec-img-div'}>
                                                    <img
                                                        className={'mb-spec-img-div-image'}
                                                        src={img.url}
                                                        alt={'img'}
                                                        style={
                                                            img.height > img.width ?
                                                                {
                                                                    height: "205px", width: "115px",
                                                                    cursor: "pointer"
                                                                } :
                                                                {
                                                                    height: "124px", width: "220px",
                                                                    margin: '6vh 0 3vh 0',
                                                                    cursor: "pointer"
                                                                }
                                                        }
                                                    />
                                                    {img?.is_video ? <PlayCircleFilled style={{
                                                        marginTop: '-6vh',
                                                        position: 'absolute',
                                                        fontSize: '1vw'
                                                    }}/> : <></>}
                                                    <p className={'mb-spec-img-div-p'}>
                                                        {img.name}
                                                    </p>
                                                    {checkMode ?
                                                        <Checkbox
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setChecked([...checked, {
                                                                        id: img.id,
                                                                        from_: img.from_,
                                                                        hardware_artwork_id: img.hardware_artwork_id ?? ''
                                                                    }])
                                                                } else {
                                                                    setChecked(checked.filter(c => c.id !== img.id))
                                                                }
                                                            }}>
                                                        </Checkbox> :
                                                        <Dropdown overlay={menu(img, img.id)} placement={"bottom"}>
                                                            <Button
                                                                className={'mb-spec-img-div-btn'}>
                                                                Select
                                                                <DownOutlined/>
                                                            </Button>
                                                        </Dropdown>
                                                    }
                                                </div>
                                            )}
                                    </Row>

                                </div>}
                            </Col>
                            <ImgDisplayModal {...MBModalprops}/>
                            <Modal
                                className={'image-add-to-album-modal'}
                                visible={isAlbumModalOpen}
                                onCancel={_ =>
                                    setIsAlbumModalOpen(false)}
                            >
                                <p className={'image-add-to-album-modal-h'}>
                                    Select the Album for this artwork
                                </p>
                                <Row justify={"center"}
                                     style={{
                                         height: '60vh',
                                         margin: 'auto',
                                         overflowY: 'auto',
                                         marginTop: '2vh',
                                         marginBottom: '2vh'
                                     }}>
                                    <Col span={18} style={{
                                        width: '6vw',
                                        height: '14vh',
                                        margin: '0 0 0 0',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}>
                                        <img
                                            src={curImg.url} alt="Album cover"
                                            style={{
                                                width: '6vw',
                                                height: "14vh",
                                                objectFit: "cover",
                                                cursor: 'pointer',
                                            }}
                                        />
                                        <div style={{margin: '-1.4vh 0 0 0px'}}>
                                            <p className={'image-display-add-album-file'}>Filename: </p>
                                            <p className={'image-display-add-album-file-name'}>{curImg.name}</p>
                                        </div>
                                    </Col>
                                    <Col span={18}>
                                        <p className={'image-display-add-album-my-albums'}>My Albums</p>
                                    </Col>
                                    {albums.length > 0 ?
                                        albums.map((album, index) => {
                                            return (
                                                <div className={'img-mb-album-card'}
                                                >
                                                    {album?.urls?.split(',').length >= 3 ?
                                                        <Col span={16}>
                                                            <div className="container"
                                                                 onClick={() => {
                                                                     setSelectedAlbum(album.id)
                                                                 }}
                                                                 style={selectedAlbum === album.id ? {
                                                                     border: '5px solid #000',
                                                                     width: '8vw',
                                                                     height: '17.6vh',
                                                                     marginBottom: 0,
                                                                     cursor: 'pointer',
                                                                 } : {
                                                                     width: '8vw',
                                                                     height: '17.6vh',
                                                                     marginBottom: 0,
                                                                     cursor: 'pointer',
                                                                 }}
                                                            >
                                                                <div className="left">
                                                                    <img style={{height: '98.5%'}}
                                                                         src={album?.item.left || left} alt="Left"/>
                                                                </div>
                                                                <div className="right">
                                                                    <img src={album?.item.upRight || upRight}
                                                                         alt="Top Right"/>
                                                                    <img src={album?.item.downRight || downRight}
                                                                         alt="Bottom Right"/>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        :
                                                        <Col span={16} style={{
                                                            width: '10vw',
                                                            height: '16vh',
                                                        }}>
                                                            <img
                                                                onClick={() => {
                                                                    setSelectedAlbum(album.id)
                                                                }}
                                                                src={album.urls.split(',')[0]} alt="Album cover"
                                                                style={selectedAlbum === album.id ? {
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "cover",
                                                                    cursor: 'pointer',
                                                                    border: '5px solid #000'
                                                                } : {
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "cover",
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </Col>
                                                    }
                                                    <p className={'img-mb-album-name'}>
                                                        {album?.name}
                                                    </p>
                                                    <p className={'img-mb-album-number'}>
                                                        {album?.number} items
                                                    </p>
                                                </div>
                                            )
                                        }) : <></>
                                    }
                                    {albums.length % 2 !== 0 ? <div className={'img-mb-album-card'}></div> :
                                        <div></div>}
                                </Row>
                                <Row justify={'center'} className={'image-add-to-album-modal-final-row'}>
                                    <Button className={'image-add-to-album-modal-btn'}
                                            onClick={_ => {
                                                post_api('/album/add/artwork', {
                                                    id: selectedAlbum,
                                                    artwork_ids: [curImg.id],
                                                    from_: curImg.from_
                                                }).then(res => {
                                                    window.location.reload()
                                                })
                                            }}
                                    >
                                        Confirm
                                    </Button>
                                    <Button className={'image-add-to-album-modal-btn'}
                                            onClick={_ => setIsAlbumModalOpen(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Row>
                            </Modal>
                            <Modal
                                className={`image-display-image-preview-${curPreviewData.orientation}`}
                                visible={imgPreviewModalOpen}
                                onCancel={_ =>
                                    setImgPreviewModalOpen(false)}
                            >
                                <img
                                    src={curPreviewData.url}
                                    alt="image"
                                    style={{
                                        width: '100%',
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                />
                            </Modal>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    )
}

export default ImgDisplay;
