import {APP_API_URL} from "../../../../settings";

const token = localStorage.getItem('token');

export const getBalance = async () => {
    const reqOpt = {
        method: 'GET',
        headers: {
            "X-Access-Token": token,
        },
    };
    return fetch(APP_API_URL + "/photoframe-mobile/finance/balance/yuiop", reqOpt)
        .then(response => response.json())
        .catch(error => console.log('error', error));
}

