export const quizRules = {
    'aaaa': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '1' && ans.contentChange === '1' && ans.manageArtworks === '1'),
        plan: 'foundation',
        message: <p>We would recommend you select the <b>Foundation Plan</b>. This plan is designed for businesses that
            need
            1-2 Textura Pro digital canvases and prefer to display their own artwork. With this plan, you will have
            access
            to free, user-uploaded artwork, and your content will stay fixed for a longer period without needing
            frequent
            changes. You’ll manage the content yourself, giving you full control over what’s displayed. Ideal for
            businesses
            with a limited need for content updates and minimal artwork curation.</p>
    },
    'aaba': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '1' && ans.contentChange === '2' && ans.manageArtworks === '1'),
        plan: 'advanced',
        message: <p>We suggest you go with the <b>Advanced Plan</b>, ideal for businesses needing up 1-2 canvases with
            advanced, royalty-free artwork curation. This plan provides expertly arranged playlists for your displays,
            and you’ll update the content regularly, ensuring your environment remains dynamic. You can manage the
            artwork content yourself, but if you need additional help, the <b>Advanced Plan</b> allows flexibility for
            future
            support.</p>
    },
    'abaa': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '2' && ans.contentChange === '1' && ans.manageArtworks === '1'),
        plan: 'advanced',
        message: <p>We suggest you go with the <b>Advanced Plan</b>, ideal for businesses needing up 1-2 canvases with
            advanced, royalty-free artwork curation. This plan provides expertly arranged playlists for your displays,
            and you’ll update the content regularly, ensuring your environment remains dynamic. You can manage the
            artwork content yourself, but if you need additional help, the <b>Advanced Plan</b> allows flexibility for
            future
            support.</p>
    },
    'abba': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '2' && ans.contentChange === '2' && ans.manageArtworks === '1'),
        plan: 'advanced',
        message: <p>We suggest you go with the <b>Advanced Plan</b>, ideal for businesses needing up 1-2 canvases with
            advanced, royalty-free artwork curation. This plan provides expertly arranged playlists for your displays,
            and you’ll update the content regularly, ensuring your environment remains dynamic. You can manage the
            artwork content yourself, but if you need additional help, the <b>Advanced Plan</b> allows flexibility for
            future
            support.</p>
    },
    'aaab': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '1' && ans.contentChange === '1' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'aabb': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '1' && ans.contentChange === '2' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'aaca': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '1' && ans.contentChange === '3' && ans.manageArtworks === '1'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'aacb': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '1' && ans.contentChange === '3' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'abab': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '2' && ans.contentChange === '1' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'abbb': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '2' && ans.contentChange === '2' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'abca': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '2' && ans.contentChange === '3' && ans.manageArtworks === '1'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'abcb': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '2' && ans.contentChange === '3' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'acaa': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '3' && ans.contentChange === '1' && ans.manageArtworks === '1'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'acab': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '3' && ans.contentChange === '1' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'acba': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '3' && ans.contentChange === '2' && ans.manageArtworks === '1'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'acbb': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '3' && ans.contentChange === '2' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'acca': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '3' && ans.contentChange === '3' && ans.manageArtworks === '1'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'accb': {
        condition: (ans) => (ans.selectedScreens === '1' && ans.artworkType === '3' && ans.contentChange === '3' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>For these combinations, we suggest the <b>Premium Plan</b>, tailored to businesses that need
            advanced
            artwork curation and a premium content experience. You’ll have access to expertly arranged, royalty-free
            artwork with customised playlists to fit your needs. The <b>Premium Plan</b> allows you to update content
            frequently, ensuring your digital canvases remain fresh and engaging. You also get full support from a
            dedicated art curator, taking the burden of content management off your shoulders. Perfect for businesses
            with specific, high-quality content requirements and frequent updates.</p>
    },
    'baaa': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '1' && ans.contentChange === '1' && ans.manageArtworks === '1'),
        plan: 'advanced',
        message: <p>We suggest you go with the <b>Advanced Plan</b>, ideal for businesses needing 3 or more canvases
            with
            advanced, royalty-free artwork curation. This plan provides expertly arranged playlists for your displays,
            and you’ll update the content regularly, ensuring your environment remains dynamic. You can manage the
            artwork content yourself, but if you need additional help, the <b>Advanced Plan</b> allows flexibility for
            future
            support.</p>
    },
    'baab': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '1' && ans.contentChange === '1' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>We recommend you take the <b>Premium Plan</b>, which offers businesses access to 3 or more canvases
            , with
            expertly curated artwork and the flexibility of regular content updates. You will enjoy high-quality,
            royalty-free artwork tailored to your environment. You can either manage the artwork independently or rely
            on the platform’s management services for additional support. Perfect for businesses looking for premium
            artwork and support to keep content updated as frequently as required.</p>
    },
    'baba': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '1' && ans.contentChange === '2' && ans.manageArtworks === '1'),
        plan: 'premium',
        message: <p>We recommend you take the <b>Premium Plan</b>, which offers businesses access to 3 or more canvases
            , with
            expertly curated artwork and the flexibility of regular content updates. You will enjoy high-quality,
            royalty-free artwork tailored to your environment. You can either manage the artwork independently or rely
            on the platform’s management services for additional support. Perfect for businesses looking for premium
            artwork and support to keep content updated as frequently as required.</p>
    },
    'babb': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '1' && ans.contentChange === '2' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>We recommend you take the <b>Premium Plan</b>, which offers businesses access to 3 or more canvases
            , with
            expertly curated artwork and the flexibility of regular content updates. You will enjoy high-quality,
            royalty-free artwork tailored to your environment. You can either manage the artwork independently or rely
            on the platform’s management services for additional support. Perfect for businesses looking for premium
            artwork and support to keep content updated as frequently as required.</p>
    },
    'baca': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '1' && ans.contentChange === '3' && ans.manageArtworks === '1'),
        plan: 'premium',
        message: <p>We recommend you take the <b>Premium Plan</b>, which offers businesses access to 3 or more canvases
            , with
            expertly curated artwork and the flexibility of regular content updates. You will enjoy high-quality,
            royalty-free artwork tailored to your environment. You can either manage the artwork independently or rely
            on the platform’s management services for additional support. Perfect for businesses looking for premium
            artwork and support to keep content updated as frequently as required.</p>
    },
    'bacb': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '1' && ans.contentChange === '3' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>We recommend you take the <b>Premium Plan</b>, which offers businesses access to 3 or more canvases
            , with
            expertly curated artwork and the flexibility of regular content updates. You will enjoy high-quality,
            royalty-free artwork tailored to your environment. You can either manage the artwork independently or rely
            on the platform’s management services for additional support. Perfect for businesses looking for premium
            artwork and support to keep content updated as frequently as required.</p>
    },
    'bbaa': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '2' && ans.contentChange === '1' && ans.manageArtworks === '1'),
        plan: 'advanced',
        message: <p>We recommend the <b>Advanced Plan</b>. You can manage 3 or more canvases with access to royalty-free
            artwork. Regular content changes are supported, and you’ll receive curated playlists to suit your business
            environment. If you need more assistance, you can also opt for content management services, ensuring smooth
            operation and ease of use. This plan is great for businesses looking to maintain dynamic, custom displays
            with moderate frequency of content changes.</p>
    },
    'bbab': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '2' && ans.contentChange === '1' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>We recommend you take the <b>Premium Plan</b>. This plan allows you to manage 3 or more canvases
            with
            premium curated artwork and customised playlists. Your content can be updated regularly or as needed, and
            you’ll receive additional management support to ensure your displays are always fresh. If you want expert
            help to manage and update artwork, this plan provides the best value for businesses requiring high-quality,
            engaging displays with full support.</p>
    },
    'bbba': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '2' && ans.contentChange === '2' && ans.manageArtworks === '1'),
        plan: 'advanced',
        message: <p>We recommend the <b>Advanced Plan</b>, designed for businesses needing 3 or more canvases and
            curated,
            royalty-free artwork. With this plan, you can regularly update content as desired and manage it yourself.
            You also have the option for more advanced platform support, making it a flexible choice for businesses that
            want a balance between independence and occasional assistance with content management.</p>
    },
    'bbbb': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '2' && ans.contentChange === '2' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>We suggest the <b>Premium Plan</b>, which gives you access to 3 or more canvases and premium curated
            artwork, with the flexibility of frequent content updates. This plan also provides full support from art
            curators and platform management services, taking care of all your content needs. It’s the ideal option for
            businesses looking for the best-quality artwork with hands-on management to ensure your displays are always
            up-to-date.</p>
    },
    'bbca': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '2' && ans.contentChange === '3' && ans.manageArtworks === '1'),
        plan: 'premium',
        message: <p>We suggest the <b>Premium Plan</b>, offering the best in curated artwork and full flexibility for your
            displays. With the ability to connect 3 or more canvases, premium artwork, and frequent updates, you’ll be
            able to manage your content seamlessly with support from expert curators. Whether you need customised
            playlists or frequent content changes, this plan provides you with the highest quality artwork and hands-on
            assistance. Perfect for businesses that demand the best, from exclusive artwork to dedicated support in
            managing their digital canvas displays.</p>
    },
    'bbcb': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '2' && ans.contentChange === '3' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>We suggest the <b>Premium Plan</b>, offering the best in curated artwork and full flexibility for your
            displays. With the ability to connect 3 or more canvases, premium artwork, and frequent updates, you’ll be
            able to manage your content seamlessly with support from expert curators. Whether you need customised
            playlists or frequent content changes, this plan provides you with the highest quality artwork and hands-on
            assistance. Perfect for businesses that demand the best, from exclusive artwork to dedicated support in
            managing their digital canvas displays.</p>
    },
    'bcaa': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '3' && ans.contentChange === '1' && ans.manageArtworks === '1'),
        plan: 'premium',
        message: <p>We suggest the <b>Premium Plan</b>, offering the best in curated artwork and full flexibility for your
            displays. With the ability to connect 3 or more canvases, premium artwork, and frequent updates, you’ll be
            able to manage your content seamlessly with support from expert curators. Whether you need customised
            playlists or frequent content changes, this plan provides you with the highest quality artwork and hands-on
            assistance. Perfect for businesses that demand the best, from exclusive artwork to dedicated support in
            managing their digital canvas displays.</p>
    },
    'bcab': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '3' && ans.contentChange === '1' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>We suggest the <b>Premium Plan</b>, offering the best in curated artwork and full flexibility for your
            displays. With the ability to connect 3 or more canvases, premium artwork, and frequent updates, you’ll be
            able to manage your content seamlessly with support from expert curators. Whether you need customised
            playlists or frequent content changes, this plan provides you with the highest quality artwork and hands-on
            assistance. Perfect for businesses that demand the best, from exclusive artwork to dedicated support in
            managing their digital canvas displays.</p>
    },
    'bcba': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '3' && ans.contentChange === '2' && ans.manageArtworks === '1'),
        plan: 'premium',
        message: <p>We suggest the <b>Premium Plan</b>, offering the best in curated artwork and full flexibility for your
            displays. With the ability to connect 3 or more canvases, premium artwork, and frequent updates, you’ll be
            able to manage your content seamlessly with support from expert curators. Whether you need customised
            playlists or frequent content changes, this plan provides you with the highest quality artwork and hands-on
            assistance. Perfect for businesses that demand the best, from exclusive artwork to dedicated support in
            managing their digital canvas displays.</p>
    },
    'bcbb': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '3' && ans.contentChange === '2' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>We suggest the <b>Premium Plan</b>, offering the best in curated artwork and full flexibility for your
            displays. With the ability to connect 3 or more canvases, premium artwork, and frequent updates, you’ll be
            able to manage your content seamlessly with support from expert curators. Whether you need customised
            playlists or frequent content changes, this plan provides you with the highest quality artwork and hands-on
            assistance. Perfect for businesses that demand the best, from exclusive artwork to dedicated support in
            managing their digital canvas displays.</p>
    },
    'bcca': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '3' && ans.contentChange === '3' && ans.manageArtworks === '1'),
        plan: 'premium',
        message: <p>We suggest the <b>Premium Plan</b>, offering the best in curated artwork and full flexibility for your
            displays. With the ability to connect 3 or more canvases, premium artwork, and frequent updates, you’ll be
            able to manage your content seamlessly with support from expert curators. Whether you need customised
            playlists or frequent content changes, this plan provides you with the highest quality artwork and hands-on
            assistance. Perfect for businesses that demand the best, from exclusive artwork to dedicated support in
            managing their digital canvas displays.</p>
    },
    'bccb': {
        condition: (ans) => (ans.selectedScreens === '2' && ans.artworkType === '3' && ans.contentChange === '3' && ans.manageArtworks === '2'),
        plan: 'premium',
        message: <p>We suggest the <b>Premium Plan</b>, offering the best in curated artwork and full flexibility for your
            displays. With the ability to connect 3 or more canvases, premium artwork, and frequent updates, you’ll be
            able to manage your content seamlessly with support from expert curators. Whether you need customised
            playlists or frequent content changes, this plan provides you with the highest quality artwork and hands-on
            assistance. Perfect for businesses that demand the best, from exclusive artwork to dedicated support in
            managing their digital canvas displays.</p>
    },

}