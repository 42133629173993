import React from "react";
import {Helmet} from "react-helmet";

export const A3Meta = () => (
    <Helmet>
        <title>Lucy Nelson | Vieunite</title>
        <meta name="description"
              content="Lucy Nelson is a visual artist primarily working with sculpture and alternative photography techniques as an avenue to explore the themes of growth, impermanence and fragility. "/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@ VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Lucy Nelson "/>
        <meta name="twitter:description"
              value="Lucy Nelson's modern digital art sculptures showcase her innovative approach to themes such as growth, impermanence and fragility. Discover her vibrant creations on Vieunite. "/>
        <meta name="twitter:url" value="https://vieunite.com/artists/lucy-nelson"/>
        <meta name="twitter:image" value=" https://vieunite.com/images/vieunite_sc_lucy_nelson.jpg"/>

        <meta property="og:url" content=" https://vieunite.com/artists/lucy-nelson"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Vieunite Artist | Lucy Nelson"/>
        <meta property="og:description"
              content="Lucy Nelson's modern digital art sculptures showcase her innovative approach to themes such as growth, impermanence and fragility. Discover her vibrant creations on Vieunite."/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_lucy_nelson.jpg"/>
    </Helmet>);