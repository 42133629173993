import React, {useEffect, useState} from 'react';
import './style.scss';
import {Button, Col, Layout, Row, Space, Table} from "antd";
import {Content} from "antd/es/layout/layout";
import {useNavigate} from "react-router-dom";
import ProPortalHeader from "../../../componets/ProPortalHeader";
import ProfileRow from "../../../componets/ProfileRow";
import Navigator from "../../../componets/Navigator";
import {get_api} from "../../../serverTemp";
import backButton from './img/Back button.svg';

const RefundsProportal = () => {
    const [data, setData] = useState([])
    const navigate = useNavigate(); // 使用useNavigate钩子
    useEffect(() => {
        get_api('/order/view?order_status=8').then(res => {
            setData(res.data || []);
        })
    }, []);
    const statusMappings = {
        0: 'Available to Display',
        1: 'In Cart',
        2: 'Processed',
        3: 'Paid',
        4: 'Canceled',
        5: 'Prepare to Deliver',
        6: 'Delivering',
        7: 'Delivered',
        8: 'Refunded',
    };
    const columns = (navigate) => [
        {
            title: 'Order No.',
            dataIndex: 'id',
            key: 'orderNo',
            // render: (text, record) => (
            //     <div style={{borderBottom: '1px solid black', cursor: 'pointer', display: 'inline-block'}}>
            //         <a onClick={() => navigate(`/proportal/PortalOrderDetails`)}
            //            style={{color: 'inherit', textDecoration: 'none'}}>{text}</a>
            //     </div>
            // ),
        },
        {
            title: 'Order date',
            dataIndex: 'createTime',
            key: 'orderDate',
        },
        {
            title: 'Delivery address',
            dataIndex: 'deliveryAddress',
            key: 'deliveryAddress',
        },
        {
            title: 'Item(s)',
            dataIndex: 'itemsNumber',
            key: 'items',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => statusMappings[status] || 'Unknown', // 使用映射对象转换状态码
        },
        {
            title: 'Total price',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            render: totalPrice => `£${totalPrice}`, // Prepend '£' symbol to the price
        },
    ];
    const handleBackClick = () => {
        navigate(-1); // 使用navigate函数导航回上一页
    };
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <Content style={{
                                background: '#F4F4F4',
                            }}>
                                <div className='my-orders-pro'>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '50px',
                                        marginLeft: '-90px'
                                    }}>
                                        <img src={backButton} alt="BackButton" className='BackButtonIcon'
                                             style={{marginRight: '0.5rem', cursor: "pointer"}} onClick={handleBackClick}/>
                                        <p className='my-orders-pro-title' style={{margin: 0}}>Refunds</p>
                                    </div>
                                    <div className='my-orders-table'>
                                        <Table columns={columns(navigate)} dataSource={data}/>
                                    </div>
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>);
}

export default RefundsProportal;



