import React from "react";
import {Helmet} from "react-helmet";

export const A21Meta = () => (
    <Helmet>
        <title> Paul Gittins | Vieunite</title>
        <meta name="description"
              content="Explore Paul Gittins' innovative multimedia art, blending music, performance, sculpture, and video. His large-scale installations and playful creations transform everyday life into mesmerizing abstract patterns, often featuring shadow dancers and live musicians."/>
        <meta name="keywords"
              content=" digital canvas, digital frame, digital art frame, digital art canvas, Paul Gittins, multimedia art, performance art, sculpture, video art, abstract patterns, shadow dancers, live musicians, Oak Apple Orchestra, improvisational music, large-scale installations, Vieunite artist"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Paul Gittins"/>
        <meta name="twitter:description"
              content="Explore Paul Gittins' innovative multimedia art, blending music, performance, sculpture, and video. His large-scale installations and playful creations transform everyday life into mesmerizing abstract patterns, often featuring shadow dancers and live musicians."/>
        <meta name="twitter:url" value="https://vieunite.com/artists/paul-gittins"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_paul_gittins jpg"/>
        <meta property="og:url" content="https://vieunite.com/artists/paul-gittins"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Artist | Paul Gittins"/>
        <meta property="og:description"
              content="Explore Paul Gittins' innovative multimedia art, blending music, performance, sculpture, and video. His large-scale installations and playful creations transform everyday life into mesmerizing abstract patterns, often featuring shadow dancers and live musicians. "/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_paul_gittins.jpg"/>
    </Helmet>);