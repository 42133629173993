import {APP_API_URL} from "../../../../settings";

const token = localStorage.getItem('token');
export const getTransHist = async (pageSize = 100) => {
    const reqOpt = {
        method: 'GET',
        headers: {
            "X-Access-Token": token,
        },
    };
    return fetch(APP_API_URL + `/photoframe-mobile/order/pageShopOrderInfo?pageNo=1&pageSize=${pageSize}&keyword=&startTime=&endTime=`, reqOpt)
        .then(response => response.json())
        .then(res => {
            console.log(res)
            return res.code === 200 ? res : (() => {
                alert(res.message)
                throw Error(res.message)
            })()
        }).then(res => res.result?.page?.records)
        .catch(error => console.log('error', error));
}