import React from 'react';
import {Form, Input, Button} from 'antd';
import './style.scss'
import {Link} from "react-router-dom";
import ProLogo from './imgs/ProLogo.svg';
import {post_api} from "../../portal/serverTemp";
import {message} from 'antd';

const ProForgotPassword = () => {
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        post_api('/user/send/change/password/link', {'email': values.email})
            .then(() => {
                // message.success('Thanks! Please check your email!').then(r => {
                // })
            })
    };

    return (
        <div style={{marginTop: '5vw'}}>
            <div className="ProLogo" style={{marginBottom: '100px'}}>
                <img src={ProLogo} alt="ProLogo" className='ProLogo'/>
            </div>
            <div className="Title" style={{marginTop: '70px'}}>
                Forgot password?
            </div>
            <p className="ProPassword-text-check">
                We'll email you instructions on how to reset your password
            </p>
            <Form
                initialValues={{remember: true}}
                onFinish={onFinish}
                colon={false}
                layout="horizontal"
            >
                <Form.Item
                    name="email"
                    rules={[{message: 'Please input your email!'}]}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 5}}
                >
                    <Input className={'input-Email-form'}
                           style={{width: '30vw', height: '6vh', backgroundColor: '#D9D9D9'}}
                           placeholder="Email"
                    />
                </Form.Item>
                <div className="center-button" style={{marginBottom: '100px'}}>
                    <Button type="primary" htmlType="submit" className="custom-Reset-button"
                            style={{width: '30vw', height: '6vh'}}>
                        Reset password
                    </Button>
                </div>
            </Form>

        </div>
    );
};

export default ProForgotPassword;
