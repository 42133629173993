import React from 'react';
import {Button, Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import img1 from './imgs/Textura Easel_04_Large 1.png'
import img2 from './imgs/Rectangle 7170.png'
import img3 from './imgs/Rectangle 7172.png'
import img4 from './imgs/Ballerina Still 1.png'
import img5 from './imgs/Rectangle 7180.png'
import ProHeader from "../components/ProHeader";
import ProFooter from "../components/ProFooter";
import Title from "antd/es/skeleton/Title";
import useWindowResize from "../../apps/Hooks/useWindowResize";
import m_pic from './imgs/No Hardware Image.webp'

const Explore = (props) => {
    const {windowWidth} = useWindowResize()
    return (
        <div>
            <ProHeader/>
            <Layout>
                {windowWidth > 600 && <Content style={{marginTop: '11vh', display: 'flex'}}>
                    <Col style={{width: '30vw'}}>
                        <img src={img1} style={{width: '30vw', height: '40.25vh'}} alt={'art1'}/>
                        <img src={img2} style={{width: '30vw', height: '40.25vh'}} alt={'art2'}/>
                    </Col>
                    <Col style={{width: '70vw'}}>
                        <Row>
                            <Col style={{width: '49vw'}}>
                                <img style={{width: '49vw', height: '30vh'}} src={img3} alt={'art3'}/>
                            </Col>
                            <Col style={{width: '20vw'}}>
                                <img src={img4} style={{width: '21vw', height: '30vh'}} alt={'art4'}/>
                            </Col>
                        </Row>
                        <Row style={{
                            display: "flex"
                        }}>
                            <Col style={{width: '35vw'}}>
                                <div className={'exp-div'} style={{width: '35vw', height: '50vh'}}>
                                    <p className={'exp-title'}><strong>Let's Begin Your Amazing Vieunite Pro
                                        Journey </strong></p>
                                    <p className={'exp-txt'}>
                                        Experience the full potential of Vieunite Pro by first signing up with our
                                        Vieunite Pro portal. With intuitive tools, you can now effortlessly publish
                                        captivating playlists and seamlessly manage your network of Textura Pro digital
                                        canvases anytime, anywhere. Ready to embark on this journey? <br/><br/>
                                        Come explore our myriad of subscription plans and if you want to learn more,
                                        don't hesitate to reach out to us at info@vieunite.com. We're here to guide
                                        you through the process and answer any questions you have. Alternatively, if
                                        you've already decided on a subscription plan, let's proceed to purchase your
                                        Textura Pro digital canvas below to begin!
                                    </p>
                                    <Button className={'exp-btn'}
                                            onClick={_ => window.location.href = '/business/shopping'}>
                                        Purchase Textura Pro digital canvas
                                    </Button>
                                    <Button className={'exp-btn'}
                                            onClick={_ => window.location.href = '/proportal/'}>
                                        Explore the Vieunite Pro Portal
                                    </Button>
                                </div>
                            </Col>
                            <Col style={{width: '34vw'}}>
                                <img src={img5} style={{width: '35vw', height: '50.5vh'}} alt={'art5'}/>
                            </Col>
                        </Row>
                    </Col>
                </Content>}
                {windowWidth <= 600 &&
                    <Content style={{background: '#F4F4F4', display: 'flex', marginTop: '11vh'}}>
                        <Row justify={"center"} style={{width: '100vw'}}>
                            <p className={'m-curated-h1'}>
                                The Vieunite Pro portal is currently only available on desktop devices.
                            </p>
                            <p className={'m-curated-p'} style={{margin: '1vh auto', width: '60vw'}}>
                                To access your Vieunite Pro account please use a non mobile or tablet device.
                            </p>
                            <Button className={'m-dsa-btn'}
                                    onClick={_ => window.location.href = '/business'}
                                    style={{margin: '1vh auto'}}>
                                Home
                            </Button>
                            <img src={m_pic} style={{
                                width: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                                marginTop: '2vh',
                            }}/>
                            <div style={{
                                background: '#C4C4C4',
                                display: "flex", flexDirection: "column", justifyContent: "center",
                                alignItems: "center", width: '100%', height: 'auto', padding: '2vh',
                            }}>
                                <p className={'m-explore-p-h'}>
                                    Let's Begin Your Amazing Vieunite Journey
                                </p>
                                <p className={'m-explore-p-txt'}>
                                    Experience the full potential of Vieunite Pro by first signing up with our Vieunite
                                    Pro
                                    portal. With intuitive tools, you can now effortlessly publish captivating playlists
                                    and
                                    seamlessly manage your network of Textura Pro digital canvases anytime, anywhere.
                                    Ready
                                    to embark on this journey?
                                </p>
                                <p className={'m-explore-p-txt'}>
                                    Come explore our myriad of subscription plans and if you want to learn more, don't
                                    hesitate to reach out to us at support@vieunite.com. We're here to guide you through
                                    the
                                    process and answer any questions you have. Alternatively, if you've already decided
                                    on a
                                    subscription plan, let's proceed to purchase your Textura Pro digital canvas!
                                </p>
                                <p className={'m-explore-p-end'}>
                                    To either purchase Textura Pro or explore the Vieunite Pro Portal, please use our
                                    desktop version.
                                </p>
                            </div>
                        </Row>
                    </Content>}
            </Layout>
            <ProFooter/>
        </div>

    )
}

export default Explore;
