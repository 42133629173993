import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Input, Layout, message, Modal, Row, Table, Tree} from 'antd';
import './style.scss';
import {Content} from "antd/es/layout/layout";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {get_api, post_api} from "../serverTemp";
import ProPortalHeader from "../componets/ProPortalHeader";
import ProfileRow from "../componets/ProfileRow";
import Navigator from "../componets/Navigator";
import GroupModal from "./GroupModal";
import {useNavigate} from "react-router-dom";
import backButton from './imgs/Back button.svg';


const ProportalGroup = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRemindModalOpen, setIsRemindModalOpen] = useState(false)
    const [originalTreeData, setOriginalTreeData] = useState([]); // 存储从后端获取的原始树形数据
    const [treeData, setTreeData] = useState([]); // 用于展示的树形数据
    const [searchValue, setSearchValue] = useState(''); // 搜索框的值
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [isNewGroup, setIsNewGroup] = useState(false)
    const [mode, setMode] = useState(0)//0:groups 1:canvas
    const [data, setData] = useState([])
    const [orgData, setOrgData] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [curOps, setCurOps] = useState(2)// -1:show all 0:add 1:remove 2:both
    const [reminderText, setReminderText] = useState('')
    const [curGroups, setCurGroups] = useState([])
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };


    const rowSelection = {
        selectedRowKeys, // Set the selected keys from the state
        onChange: onSelectChange, // Update the state when selections change
    };

    useEffect(() => {
        get_api('/textura/group/all').then(res => {
            const fetchedData = res.data || [];
            console.log(fetchedData)
            const newTreeData = fetchedData.map((node, index) => ({
                title: node.name,
                key: `${node.id}-${node.name}-${node.level}-${node.parent_id ?? 'root'}`,
                children: node.children ? node.children.map((child, childIndex) => ({
                    title: child.name,
                    key: `${child.id}-${child.name}-${child.level}-${child.parent_id ?? 'root'}`,
                    children: child.children ? child.children.map((grandchild, grandchildIndex) => ({
                        title: grandchild.name,
                        key: `${grandchild.id}-${grandchild.name}-${grandchild.level}-${grandchild.parent_id ?? 'root'}`,
                        children: []
                    })) : [],
                })) : [],
            }));
            setOriginalTreeData(newTreeData);
            setTreeData(newTreeData);
        });
    }, []);
    useEffect(() => {
        get_api('/textura/all').then(res => {
            console.log(res.data)
            if (res.data.length > 0) {
                setOrgData(res.data.map((d, idx) => {
                    return {...d, key: idx}
                }))
            }
        })
    }, [])
    useEffect(() => {
        if (curGroups.length === 1) {
            get_api(`/textura/group/texturas?level=${curGroups[0].level}&group_id=${curGroups[0].id}`)
                .then((res) => {
                    console.log(res.data)
                    if (res.data.length > 0) {
                        setData(res.data.map((d, idx) => {
                            return {...d, key: idx}
                        }))
                    } else {
                        setData([])
                    }
                })
        }
    }, [curGroups]);

    useEffect(() => {
        setReminderText(curOps === 0 ?
            `Are you sure that you want to add these canvases to the ${curGroups[0]} Group?` :
            `Are you sure that you want to remove these canvases?`
        )
    }, [curOps])
    // 自定义渲染树节点标题的方法
    const renderTreeTitle = (title) => {
        if (!searchValue) {
            return <span>{title}</span>;
        }
        const index = title.toLowerCase().indexOf(searchValue.toLowerCase());
        if (index > -1) {
            const beforeStr = title.substr(0, index);
            const afterStr = title.substr(index + searchValue.length);
            return (
                <span>
                    {beforeStr}
                    <span className="highlight">{title.substr(index, searchValue.length)}</span>
                    {afterStr}
                </span>
            );
        }
        return <span>{title}</span>;
    };
    // 搜索框输入变化时的处理函数
    // const onSearchChange = e => {
    //     const {value} = e.target;
    //     setSearchValue(value);
    //     if (!value) {
    //         setTreeData(originalTreeData);
    //         setExpandedKeys([]);
    //     } else {
    //         // 根据搜索值过滤树形数据
    //         const expandedKeys = [];
    //         const filteredData = originalTreeData
    //             .map(parentNode => {
    //                 const matchingChildren = (parentNode.children || []).filter(child => child.title.includes(value));
    //                 if (matchingChildren.length > 0) {
    //                     expandedKeys.push(parentNode.key);
    //                     return {...parentNode, children: matchingChildren};
    //                 }
    //                 return null;
    //             })
    //             .filter(node => node !== null);
    //         setTreeData(filteredData);
    //         setExpandedKeys(expandedKeys);
    //         setAutoExpandParent(true);
    //     }
    // };
    // const onSearchChange = e => {
    //     const { value } = e.target;
    //     setSearchValue(value);
    //     if (!value) {
    //         setTreeData(originalTreeData);
    //         setExpandedKeys([]);
    //     } else {
    //         const expandedKeys = [];
    //         const filteredData = originalTreeData
    //             .map(parentNode => {
    //                 // 检查子节点是否包含搜索关键字
    //                 const matchingChildren = (parentNode.children || []).filter(child => child.title.includes(value));
    //                 // 检查父节点标题是否匹配搜索关键字
    //                 if (parentNode.title.includes(value)) {
    //                     expandedKeys.push(parentNode.key);
    //                     // 如果父节点匹配，保留所有子节点
    //                     return { ...parentNode, children: parentNode.children };
    //                 } else if (matchingChildren.length > 0) {
    //                     // 只有子节点匹配，过滤父节点中的子节点
    //                     expandedKeys.push(parentNode.key);
    //                     return { ...parentNode, children: matchingChildren };
    //                 }
    //                 return null;
    //             })
    //             .filter(node => node !== null);
    //         setTreeData(filteredData);
    //         setExpandedKeys(expandedKeys);
    //         setAutoExpandParent(true);
    //     }
    // };
    const onSearchChange = e => {
        const {value} = e.target;
        setSearchValue(value);
        if (!value) {
            setTreeData(originalTreeData);
            setExpandedKeys([]);
        } else {
            let expandedKeys = [];

            // 递归搜索节点和其子节点
            const searchNodes = (node) => {
                let isMatch = node.title.includes(value);
                let filteredChildren = [];

                // 如果存在子节点，则递归检查子节点
                if (node.children && node.children.length > 0) {
                    node.children.forEach(child => {
                        const result = searchNodes(child);
                        if (result) {
                            filteredChildren.push(result);
                        }
                    });

                    // 如果任何子节点匹配，父节点应该被展开
                    if (filteredChildren.length > 0) {
                        expandedKeys.push(node.key);
                        isMatch = true;
                    }
                }

                // 如果节点本身或其任何子节点匹配，返回节点及其匹配的子节点
                if (isMatch) {
                    return {...node, children: filteredChildren};
                }
                return null;
            };

            // 应用搜索到所有顶层节点
            const filteredData = originalTreeData.map(node => searchNodes(node)).filter(node => node !== null);

            setTreeData(filteredData);
            setExpandedKeys(expandedKeys);
            setAutoExpandParent(true);
        }
    };

    const onExpand = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onCheck = (checkedKeysValue) => {
        console.log('onCheck', checkedKeysValue);
        setCheckedKeys([...checkedKeysValue.checked]);
        setCurGroups(checkedKeysValue.checked.map(g => {
            const gs = g.split('-')
            return {id: gs[0], name: gs[1], level: gs[2], parent_id: gs[3]}
        }))
        console.log(curGroups)
    };

    const columns = [
        {
            title: 'Device Name',
            dataIndex: 'name',
            key: 'name',
            // sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Serial No.',
            dataIndex: 'sn',
            key: 'sn',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (_, {status}) => (
                status === 3 ? <span><Badge status="success"/>&nbsp;Online</span> :
                    status === 0 ?
                        <span><Badge status="error"/>&nbsp;Power Off</span> :
                        <span><Badge status="warning"/>&nbsp;Sleep</span>
            ),
        },
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
        },
        {
            title: 'Now playing',
            dataIndex: 'playlist',
            key: 'playlist',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (_, {t}) => (
                t === 0 ? <span>Standard</span> :
                    (t === 1 ? <span>Advanced</span> : <span>Premium</span>)
            ),
        },
        {
            title: <span style={{
                display: 'flex',
                // justifyContent: 'center',
            }}>
                Last Heartbeat
            </span>,
            key: 'last_heartbeat',
            dataIndex: 'last_heartbeat',
        },
    ];
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // 使用navigate函数导航回上一页
    };

    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <Content style={{background: '#F4F4F4'}}>
                                <div className='ProportalGroup'>
                                    <Row>
                                        <Col span={8}>
                                            <Row>
                                                <div className='header3'>
                                                    <img src={backButton} alt="BackButton" className='BackButtonIcon4'
                                                         style={{marginRight: '0.5rem', cursor: "pointer"}}
                                                         onClick={handleBackClick}/>
                                                    <p className={'portal-group-p'}>Manage Groups</p>
                                                </div>
                                            </Row>
                                            <Row>
                                                <Button icon={<PlusOutlined/>}
                                                        className={'portal-group-btn'}
                                                        onClick={() => {
                                                            if (curGroups.length <= 1) {
                                                                if (curGroups.length === 1 && curGroups[0].level === 2) {
                                                                    message.error('Sorry, the maximum depth of groups is 3!')
                                                                } else {
                                                                    setIsNewGroup(true);
                                                                    setIsModalOpen(true);
                                                                }
                                                            } else {
                                                                message.error('Please select one group to handle')
                                                            }

                                                        }}
                                                        disabled={mode === 1}
                                                >
                                                    Add
                                                </Button>
                                                <Button
                                                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="20"
                                                               height="16"
                                                               viewBox="0 0 26 20" fill="none">
                                                        <path
                                                            d="M10.084 1.83203H8.25065C3.66732 1.83203 1.83398 3.66536 1.83398 8.2487V13.7487C1.83398 18.332 3.66732 20.1654 8.25065 20.1654H13.7507C18.334 20.1654 20.1673 18.332 20.1673 13.7487V11.9154"
                                                            stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round"/>
                                                        <path
                                                            d="M14.7027 2.76686L7.4794 9.99019C7.2044 10.2652 6.9294 10.806 6.8744 11.2002L6.48023 13.9594C6.33357 14.9585 7.0394 15.6552 8.03857 15.5177L10.7977 15.1235C11.1827 15.0685 11.7236 14.7935 12.0077 14.5185L19.2311 7.29519C20.4777 6.04852 21.0644 4.60019 19.2311 2.76686C17.3977 0.933523 15.9494 1.52019 14.7027 2.76686Z"
                                                            stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                                                            stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path
                                                            d="M13.668 3.80469C14.2821 5.99552 15.9963 7.70969 18.1963 8.33302"
                                                            stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                                                            stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>}
                                                    className={'portal-group-btn'}
                                                    style={{marginLeft: '1vw'}}
                                                    onClick={() => {
                                                        if (checkedKeys.length !== 1) {
                                                            message.info('Please select one group!')
                                                        } else {
                                                            setIsNewGroup(false);
                                                            setIsModalOpen(true);
                                                        }
                                                    }}
                                                    disabled={mode === 1}
                                                >
                                                    Rename
                                                </Button>
                                                <Button danger icon={<DeleteOutlined/>}
                                                        className={'portal-group-btn'}
                                                        style={{marginLeft: '1vw'}}
                                                        onClick={() => {
                                                            if (checkedKeys.length !== 1) {
                                                                message.info('Please select one group!')
                                                            } else {
                                                                post_api('/textura/group/delete', {
                                                                    id: curGroups[0].id,
                                                                    parent_id: curGroups[0].parent_id,
                                                                    level: curGroups[0].level
                                                                })
                                                                    .then(() => window.location.reload())
                                                                    .catch(error => console.error('Error:', error))
                                                            }
                                                        }}
                                                        disabled={mode === 1}
                                                >
                                                    Delete
                                                </Button>
                                            </Row>
                                        </Col>
                                        <Col span={16}>
                                            <Row><p className={'portal-group-p'}>Manage Digital Canvases</p></Row>
                                            <Row>
                                                <Button
                                                    className={'portal-group-btn-canvas'}
                                                    onClick={() => {
                                                        if (checkedKeys.length !== 1) {
                                                            message.info('Please select one group!')
                                                        } else {
                                                            setCurOps(-1)
                                                            setSelectedRowKeys([])
                                                            setMode(1)
                                                        }
                                                    }}
                                                    disabled={curOps === 1 || curOps === 0}
                                                >
                                                    Show all canvas
                                                </Button>
                                                <Button
                                                    className={'portal-group-btn-canvas'}
                                                    onClick={() => {
                                                        if (checkedKeys.length === 0) {
                                                            message.info('Please select at least one group!')
                                                        } else {
                                                            setCurOps(0)
                                                            setSelectedRowKeys([])
                                                            setMode(1)
                                                        }
                                                    }}
                                                    disabled={curOps === 1 || curOps === -1}
                                                    style={{marginLeft: '1vw'}}
                                                >
                                                    Add canvas to group
                                                </Button>
                                                {/*<Button icon={<EditOutlined/>}>Batch Add</Button>*/}
                                                <Button
                                                    style={{marginLeft: '1vw'}}
                                                    className={'portal-group-btn-canvas'}
                                                    onClick={() => {
                                                        if (checkedKeys.length !== 1) {
                                                            message.info('Please select one group!')
                                                        } else {
                                                            setCurOps(1)
                                                            setSelectedRowKeys([])
                                                            setMode(1)
                                                        }
                                                    }}
                                                    disabled={curOps === 0 || curOps === -1}
                                                >
                                                    Remove canvas
                                                </Button>
                                            </Row>
                                        </Col>
                                    </Row>

                                    {mode === 0 && <div>
                                        <div className='GroupNameSearch'>
                                            <Input
                                                placeholder="Enter Group name"
                                                value={searchValue}
                                                onChange={onSearchChange}
                                            />
                                        </div>
                                        <Tree
                                            style={{
                                                background: '#F4F4F4',
                                                marginBottom: '100rem'
                                            }}
                                            className='GroupNameTree'
                                            checkable
                                            onExpand={onExpand}
                                            expandedKeys={expandedKeys}
                                            autoExpandParent={autoExpandParent}
                                            onCheck={onCheck}
                                            checkedKeys={checkedKeys}
                                            treeData={treeData}
                                            checkStrictly
                                            titleRender={(nodeData) => renderTreeTitle(nodeData.title)}
                                        /></div>}
                                    {mode === 1 && <div className={'portal-group-ds'}>
                                        <Row>
                                            <p className={'portal-group-ds-title'}>
                                                {curOps === 0 ? 'All canvases' : 'Canvases in this group'}
                                            </p>
                                        </Row>
                                        <Table style={{
                                            margin: '0 2vw 0 2vw',
                                            borderRadius: '8px',
                                            border: '2px solid #FFF'
                                        }}
                                               columns={columns}
                                               dataSource={curOps === -1 || curOps === 1 ? data : orgData}
                                               rowSelection={curOps !== -1 ? {...rowSelection} : false}
                                               locale={{emptyText: 'No matching Textura Pro canvas has been found'}}
                                            // pagination={false}
                                        />
                                    </div>}
                                </div>
                                {mode === 1 && <Row justify={'center'} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '-8vh 0 10rem 0',
                                    alignItems: 'center',
                                    colGap: '2vw',
                                }}>
                                    <Button className={'portal-group-final-button'}
                                            onClick={() => {
                                                setCurOps(2)
                                                setMode(0)
                                            }}
                                            style={{marginRight: '2vw'}}
                                    >
                                        Cancel
                                    </Button>
                                    <Button className={'portal-group-final-button'}
                                            style={{display: curOps === -1 ? "none" : 'initial'}}
                                            onClick={async () => {
                                                if (selectedRowKeys.length === 0) {
                                                    message.info('Please select at least one canvas!')
                                                } else {
                                                    if(curOps===0){
                                                        setReminderText(`The selected Textura digital canvases will be added to ${curGroups[0].name} Group`)
                                                    }
                                                    if(curOps===1){
                                                        setReminderText(`The selected Textura digital canvases will be removed from ${curGroups[0].name} Group`)
                                                    }
                                                    // const textura_ids = orgData.filter(d => selectedRowKeys.includes(d.key)).map(d => d.id)
                                                    // // setMode(0)
                                                    // if (curOps === 1) {//remove
                                                    //     post_api('/textura/group/unlink', {
                                                    //         id: curGroups[0]?.id,
                                                    //         level: curGroups[0]?.level,
                                                    //         textura_ids: textura_ids,
                                                    //     })
                                                    //         .then(() => window.location.reload())
                                                    //         .catch(error => console.error('Error:', error))
                                                    // }
                                                    // if (curOps === 0) {//add
                                                    //     post_api('/textura/group/link', {
                                                    //         id: curGroups[0]?.id,
                                                    //         level: curGroups[0]?.level,
                                                    //         textura_ids: textura_ids,
                                                    //     })
                                                    //         .then(() => window.location.reload())
                                                    //         .catch(error => console.error('Error:', error))
                                                    // }
                                                    setIsRemindModalOpen(true)
                                                    // await setCurOps(2)
                                                }
                                            }}
                                    >
                                        OK
                                    </Button>
                                </Row>}
                            </Content>
                            <GroupModal isModalOpen={isModalOpen}
                                        setIsModalOpen={setIsModalOpen}
                                        isNewGroup={isNewGroup}
                                        curGroups={curGroups}
                            />
                            <Modal className={'portal-group-remind-modal'}
                                   visible={isRemindModalOpen}
                                   onCancel={_ => setIsRemindModalOpen(false)}
                            >
                                <p className={'j-repeat-s-p'}>{reminderText}</p>
                                <Row justify={"center"}>
                                    <Button
                                        className={'j-repeat-s-btn'}
                                        onClick={() => {
                                            setIsRemindModalOpen(false)
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={'j-repeat-s-btn'}
                                        onClick={() => {
                                            setIsRemindModalOpen(false)
                                            const textura_ids = orgData.filter(d => selectedRowKeys.includes(d.key)).map(d => d.id)
                                            if (curOps === 1) {//remove
                                                post_api('/textura/group/unlink', {
                                                    id: curGroups[0]?.id,
                                                    level: curGroups[0]?.level,
                                                    textura_ids: textura_ids,
                                                }).then(_ => setCurOps(2))
                                                    .then(() => window.location.reload())
                                                    .catch(error => console.error('Error:', error))
                                            }
                                            if (curOps === 0) {//add
                                                post_api('/textura/group/link', {
                                                    id: curGroups[0]?.id,
                                                    level: curGroups[0]?.level,
                                                    textura_ids: textura_ids,
                                                }).then(_ => setCurOps(2))
                                                    .then(() => window.location.reload())
                                                    .catch(error => console.error('Error:', error))
                                            }
                                        }}>
                                        Confirm
                                    </Button>
                                </Row>
                            </Modal>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    );
};

export default ProportalGroup;
