import React from 'react';
import './style.scss'
import {Button, Table} from "antd";
import {useNavigate} from "react-router-dom";

const PaymentAgreementTermsAndConditions = () => {
    const navigate = useNavigate();
    const rows = [
        {
            plan: 'Standard',
            scenario: 'This is a free subscription plan.',
            remarks: [
                'You are entitled to Services paired to up to 2 Products (devices).',
                'Standard Subscription Plan is the basic requirement to bundle Products and Service.',
            ],
        },
        {
            plan: 'Advanced',
            scenario: [
                'You cancel your Advanced Plan (unlimited no. of devices).',
                <br key="line-break-1"/>,
                'By default you fall back to the Standard Plan (Free subscription, up to 2 devices).',
            ],
            remarks: [
                'By default, you fall back from Advanced to Standard Plan, i.e. you are now entitled to Services paired to up to 2 Products (devices).',
                'Devices in excess of two will not work with the default Standard Subscription Plan.',
                'The remaining Products (if any) must be paired with other Subscription Plans (e.g., Free Standard (up to 2 devices), Advanced (unlimited no. of devices) and Premium (unlimited no. of devices).',
                'You may choose to subscribe to multiple free Standard Subscription Plans, or you may choose to upgrade to Advanced and Premium Subscription Plans in the future – based on your business needs.',
            ],
        },
        {
            plan: '',
            scenario: [
                'You choose to upgrade to the Premium Plan (unlimited no. of devices). ',
            ],
            remarks: [
                'You are now entitled to Premium Plan Services paired to an unlimited no. of Products.',
                'Upon mutual agreement, we will manually upgrade you from Advanced to Premium Plan.',
                'Your subscription to Premium Plan starts from the day of payment – you will only pay the price difference for the remainder of the subscription period.',
            ],
        },
        {
            plan: 'Premium',
            scenario: [
                'You cancel your Premium Plan.',
                <br key="line-break-1"/>,
                'By default you fall back to the Standard Plan',
            ],
            remarks: [
                'By default, you fall back from Premium to free Standard Plan. You are now entitled to Services paired to up to 2 Products (devices).',
                'Devices in excess of two will not work with the default Standard Subscription Plan.',
                'The remaining Products (if any) must be paired with other Subscription Plans (e.g., free Standard (up to 2 devices), Advanced (unlimited no. of devices) and Premium (unlimited no. of devices).',
                'You may choose to subscribe to multiple free Standard Subscription Plans, or you may choose to upgrade to Advanced and Premium Subscription Plans in the future, based on your business needs.',
            ],
        },
    ];
    // const handleClose = () => {
    //     // 确认对话框
    //     if (window.confirm("Are you sure you want to close this page?")) {
    //         // 判断是否是弹出窗口
    //         if (window.opener) {
    //             window.close(); // 关闭弹出窗口
    //         } else {
    //             // 关闭主窗口，必须先清空内容
    //             window.open('about:blank', '_self'); // 使用about:blank确保兼容性
    //             window.close(); // 关闭窗口
    //         }
    //     }
    // };

    return (
        <div className="PaymentAgreement-terms-conditions-container">
            <p><strong> Textura and Vieunite Pro Subscription </strong></p>
            <p><strong> Terms and Conditions </strong></p>
            <p> This tells you information about us and the legal terms and conditions (“Terms”) on which the Textura
                products and Vieunite Pro subscription services (“Products” and “Services”)
                listed on our website (http://192.168.1.140/business) and any associated digital platform which may be
                released by us from time to time (together, the “Platform”) are offered for sale to you.</p>
            <p>For ease of reading, we have divided these Terms into several sections:</p>
            <ul>
                <li>INTRODUCTION</li>
                <li>CONTRACT PARTIES</li>
                <li>HOW TO CONTACT US</li>
                <li>ORDERS</li>
                <li>PRODUCTS AND SERVICES</li>
                <li>PRICING</li>
                <li>PAYMENT</li>
                <li>DELIVERY & INSURANCE</li>
                <li>ARTWORK</li>
                <li>YOUR RIGHTS TO RETURN PRODUCTS</li>
                <li>FAULTY PRODUCTS</li>
                <li>YOUR RIGHTS TO CANCEL SERVICES</li>
                <li>OUR LIABILITY</li>
                <li>OTHER IMPORTANT TERMS</li>
            </ul>
            <section>
                <p><strong> INTRODUCTION </strong></p>
                <p>These Terms will apply to any contract for the sale of Products and Services which you enter into
                    using the Platform (each a "Contract").
                    Please read these Terms carefully before ordering any Products and Services from our Platform.
                    By placing an order on our Platform, you agree to be bound by these Terms, as well as any general
                    terms and conditions of use,
                    which may be published on the Platform from time to time.</p>
                <p>Every time you wish to order a Product and Service, please check these Terms as we may have made
                    changes to them since your last order and the Terms in force at the date of each order will apply.
                    We reserve the right to amend these Terms at any time.</p>
            </section>
            <section>
                <p><strong>CONTRACT PARTIES</strong></p>
                <p>“We”, “us”, “our”, “Vieunite” are Vieunite Limited, a company registered in England and Wales under
                    company number 13883746,
                    with our registered office at Allsee Building, 38 Kettles Wood Drive, Birmingham, United Kingdom,
                    B32 3DB.</p>
                <p>“You”, “your”, “yours” are the person who buys or agrees to buy Products and Services on the Platform
                    from us.</p>
                <p>No terms under this document shall be bound by the provisions under the Contracts (Rights of Third
                    Parties) Act 1999 unless provisions hereinunder explicitly state otherwise.</p>
                <p>"Account" means our records of any information which you have supplied to us. This includes
                    information about you;
                    details of your service subscribed (“Subscription Plan”), and details of your use of our
                    Services.</p>
                <p>”Administration Fee” means any charge which Vieunite may levy under this Contract in specified
                    circumstances for the cost of additional and/or customized services to you;</p>
                <p>“Contract” means these General Terms and Conditions and where applicable the order form, Charges,
                    Price Sheet, Subscription Plan, our Code of Practice, the Subscription Terms and Conditions the
                    Terms and Conditions;</p>
                <p>"Charges" means all sums payable to us for the purchase of Products and Services which we make
                    available to you as described in the Price Sheet, including any Administration Fees;</p>
                <p>“Code of Practice” means the Vieunite Pro’s code of practice detailing our method of dealing with any
                    complaints or disputes relating to our Products and Services, which can be found at
                    www.vieunite.com/business</p>
                <p>"Content" means any text, visual or other information, software, photos, video, graphics, sound and
                    other material appearing on or available through the Services for use on our Products or otherwise,
                    including any information supplied from time to time for which Vieunite has the Rights to resell or
                    allows a supplier of such Content to provide them via our portal network;</p>
                <p>“Credit Card” and/or “Debit Card” mean a card issued by a UK or overseas bank in your name from which
                    we are able to take payments under this Contract directly or via our partners;</p>
                <p>“Customer Services” means the Vieunite staff and employees allocated to respond and assist customers
                    with any queries and complaints.
                    We are contactable via Vieunite’s telephone numbers, by WhatsApp Business, by email or in
                    writing;</p>
                <p>“Code of Practice” means the Vieunite Pro’s code of practice detailing our method of dealing with any
                    complaints or disputes relating to our Products and Services, which can be found at
                    www.vieunite.com/business</p>
                <p>“Day” means the period from 00:00 to 23:59;</p>
                <p>“Intellectual Property” or “IP” means any intellectual property rights anywhere in the world whether
                    registerable or not,
                    including, without limitation, patents, trademarks, service marks, designs, copyrights and related
                    rights, database rights, know-how, moral rights and domain names,
                    as well as applications for registration of such rights and the right to apply for registrations,
                    and all equivalent or similar forms of protection notwithstanding the manner in which they arise or
                    in which media;</p>
                <p>“Minimum Term” means the minimum specified period of time for which you have entered into a Contract
                    with us and as specified as part of your Subscription Plan and on which the prices of your plans are
                    based;</p>
                <p>“Month” means a calendar month from the date of any event under this Contract;</p>
                <p>“Product” means any Textura digital canvas supplied to you by us.
                    Please note any leased Product is provided to you under license and it remains our property. We
                    require you to return it at the end of the license;</p>
                <p>“Price Plan” includes any applicable conditions relating to the prices and Charges for the provision
                    of your Vieunite Pro annual subscription plans;</p>
                <p>“Privacy Policy” means the Vieunite policy on controlling your information which can be found at
                    <a href="http://www.vieunite.com/business/privacy">www.vieunite.com/business/privacy</a>;</p>
                <p>"Register" and "Registration" means our acceptance of your application to register your details with
                    us for the Service once you purchase solutions from us;</p>
                <p>"Rights" means copyright, trademark and other relevant proprietary and intellectual property rights
                    relating to Content;</p>
                <p>"Service" means any of the Vieunite Pro Subscription Plans available for use by you via our
                    Platform;</p>
                <p>"User Guide(s)" means any guides or documentation supplied with your Textura and Vieunite Pro
                    Platform
                    that explain to you how the Service works, how to purchase content and/or how to use your
                    Textura;</p>
            </section>
            <section>
                <p><strong>HOW TO CONTACT US</strong></p>
                <p>When we refer,in these Terms, to "inwriting",this will include e-mail.</p>
                <p>If you have any questions or complain about the Products or these Terms, these should be sent to us
                    at <a
                        href="mailto:support@vieunite.com"
                        style={{color: 'black', textDecoration: 'underline'}}>support@vieunite.com</a> or by mail to
                    VieuniteLimited at Allsee Building, 38 Kettles Wood Drive,
                    Birmingham, United Kingdom,
                    B32 3DB. If we have to contact you, we may do so by telephone, or by writing to you at the e-mail
                    address or postal address you have provided to us at the time you place your order.</p>
            </section>
            <section>
                <p><strong>ORDERS</strong></p>
                <p>How we will accept your order</p>
                <p>By placing an order, you are offering to purchase a Product and Service in accordance with these
                    Terms and Conditions.
                    After you place an order for a Product and Service, you will receive an e-mail from us acknowledging
                    and confirming your order.At which point, a binding contract will have come into existence between
                    you and us.
                    We recommend that you print a copy of that order confirmation for your records.</p>
                <p>If you have an issue placing an order,please contact us at <a
                    href="mailto:support@vieunite.com"
                    style={{color: 'black', textDecoration: 'underline'}}>support@vieunite.com.</a></p>
                <p>If we cannot accept your order</p>
                <p>If we are unable to accept your order, for example, because the Platform is down, or the Product and
                    Service are no longer available,
                    we will inform you of this by e-mail and we will not process your order. If you have already paid
                    for the Product and Service,
                    we will refund you the full amount including any Product delivery charges, as soon as
                    practicable.</p>
                <p> We reserve the right to limit Product quantities purchased per business entity, per order and to
                    cancel or
                    refuse orders that exceed such limits for any reason at any time.</p>
                <p> We reserve the right to refuse Service to any business entity for any reason at any time.</p>
                <p> We will assign an Order Number to your order and tell you what it is when your order is accepted.
                    It will help us if you can tell us the Order Number whenever you contact us about your order.</p>
                <p> The responsible person representing or acting on behalf of the business must be 18 or over to
                    purchase the Product and Service.</p>
            </section>
            <section>
                <p><strong>PRODUCTS AND SERVICES</strong></p>
                <p> Products may vary slightly from their pictures as shown on the Platform.</p>
                <p> Our Product's actual colour may not exactly match that which is shown on your device, in our
                    marketing materials or its packaging. Because our Products are handcrafted or hand-assembled,
                    all sizes, weights, capacities, dimensions and measurements indicated on our website may deviate by
                    not more than 2%.</p>
                <p> Product packaging may vary. Also, the packaging of the Products may vary from that shown in the
                    image on the Platform. </p>
                <p> Services must be paired with the Products purchased. The Service is available in three (3) Plans:
                    Standard, Advanced, and Premium. Details are further available in the Price Sheet. </p>
                <ul>
                    <li><strong>Standard</strong>: Free and basic access with core functionalities, designed for smaller
                        businesses or those requiring minimal features. (up to 2 Textura displays)
                    </li>
                    <li><strong>Advanced</strong>: Includes advanced features suitable for most businesses, with some
                        limitations compared to Premium. (unlimited number of Textura displays)
                    </li>
                    <li><strong>Premium</strong>: Offers comprehensive access to all features, including advanced
                        features, priority business support, and customizable features. (unlimited number of Textura
                        displays).
                    </li>
                </ul>
            </section>
            <section>
                <p><strong>PRICING</strong></p>
                <p> Prices on the Platform</p>
                <p> The price of Products and Services will always be shown to you before you submit your order. Prices
                    for Products and Services may change from time to time but changes, other than where mispricing has
                    occurred, will not affect any order which we have accepted by e-mail. </p>
                <p> What happens if the price is wrong?</p>
                <p> It is always possible that, despite our best efforts, some Products and Services may be incorrectly
                    priced on the Platform. We will normally check prices before accepting your order. If a Product’s
                    correct price at your order date is higher than the price stated to you, we will contact you for
                    instructions before we accept your order. If we accept and process your order where a pricing error
                    is obvious and unmistakable and could reasonably have
                    been recognised by you as mispricing, we may end the contract, refund you any sums you have paid and
                    require the return of any such goods provided to you.
                </p>
                <p> Import Duties</p>
                <p> You accept that if you purchase Products to be delivered to an address outside of the UK that your
                    order may attract import taxes and/or other levies and taxes. Where this is the case, you will be
                    responsible for paying those sums and accept that your order may be held by your local customs
                    authority until such sums are paid. You shall not be entitled to cancel an order on the basis of
                    such taxes or levies.</p>
                <p> If the rate of VAT changes between your order date and the date we supply the product, we will
                    adjust the rate of VAT that you pay, unless you have already paid in full before the change in the
                    rate of VAT takes effect.</p>
                <p> Vieunite will supply a VAT receipt upon request. Please email us at <a
                    href="mailto:support@vieunite.com">support@vieunite.com</a> with your
                    request.</p>
            </section>
            <section>
                <p><strong>PAYMENT</strong></p>
                <p> When will payment be taken?</p>
                <p> Full payment is required at the time your order is placed on the Platform for Products. By default,
                    Services (i.e., Advanced and Premium Plans) enjoy a no-obligation, thirty (30) day free trial
                    period. You will be charged the Services Subscription Plan fees after the trial period.</p>
                <p> You pay through our authorized third-party payment processor or any other payment methods which we
                    may add to the Platform from time to time. Your use of any payment service to purchase Products and
                    Services will be subject to the terms and conditions of the applicable payment processor.</p>
                <p> You agree to provide current, complete and accurate purchase and payment information for all
                    purchases made on the Platform including, without limitation, your card details, billing address and
                    other information necessary to effect payment. Where you make a purchase through an account you have
                    registered with us or otherwise, you agree to promptly update your account and other information,
                    including your e-mail address and debit/credit card numbers and expiration dates, so that we can
                    complete your transaction and contact you as needed. You expressly agree that we are not responsible
                    for any loss or damage arising from the submission of false or inaccurate information.</p>
                <p> Payment security</p>
                <p> Our authorized third-party payment processor uses advanced Secure Sockets Layer (SSL) encryption
                    technology to provide you with the maximum possible level of online security.</p>
                <p> By submitting such information, you grant us the right to provide the information to third parties
                    for purposes of facilitating the completion of purchases.</p>
            </section>
            <section>
                <p><strong>DELIVERY & INSURANCE</strong></p>
                <p> Delivery costs</p>
                <p> Shipping is done through a globally recognised and insured courier.</p>
                <p> Delivery costs for the Products will be specified in the Platform and may vary depending on the size
                    of the Product, delivery location and the delivery options available to you.</p>
                <p> All deliveries within the UK are subject to VAT as detailed at the point of payment. </p>
                <p> When will you get your Products?</p>
                <p> During the order process we will let you know when we aim to provide the Products to you. If we
                    consider we are unable to meet the estimated delivery date, we will contact you with a revised
                    estimated delivery date.</p>
                <p> Please be aware that cross-border shipments are subject to inspection by customs and may delay the
                    arrival of your order.</p>
                <p> If you require a specific method of delivery not specified on the Platform, please contact us at
                    <a href="mailto:support@vieunite.com">support@vieunite.com</a> and we may at our discretion try to
                    arrange this.</p>
                <p> Please note these estimates exclude weekends and bank/public holidays. Any dates provided to you by
                    us are simply estimates given in good faith based on the shipping method selected. For the avoidance
                    of doubt such dates are only approximations and we are not bound by them. We accept no liability for
                    any delay in the delivery of the Products.</p>
                <p> If a parcel is lost or takes too long to receive because the information you provided to us was
                    incorrect or incomplete, we accept no liability for any delay in the delivery or
                    misplacement of the Products.
                </p>
                <p> Once the Products have been delivered to you, you will become the owner and you are responsible for
                    risk of loss or damage to them.</p>
                <p> Signature required on delivery.</p>
                <p> A signature may be required for release of the Products to you at the delivery address you gave us.
                    If our delivery agent is unable to deliver to you because you are not present when the goods are
                    delivered and if you do not follow their instructions to rearrange for delivery, then we may end the
                    contract with you and cancel your order. Where this occurs, we reserve the right to charge you for
                    costs that we have incurred attempting to deliver the products to you.</p>
                <p> We accept no liability or responsibility if the signature is not that of the recipient, or nominate
                    recipient and in such instances, we will consider the Products correctly delivered.</p>
                <p> Your Product will be fully insured during delivery.</p>
                <p> A Product will be your responsibility from the time we deliver it to the address you gave us or it
                    is otherwise collected or delivered in accordance with your instructions to the relevant postal
                    service and/or carrier selected, including where the Product is left in a ‘safe place’ in accordance
                    with your instructions. This does not affect your statutory
                    rights to cancel your order but please bear in mind that you will be responsible for any damage that
                    you do to Products once they are in your possession.
                </p>
                <p> Shipping restrictions.</p>
                <p> We are unable to deliver to PO Box numbers, BFPO addresses, mail-forwarding addresses and temporary
                    residence addresses. This is to ensure the safe and undamaged delivery of your Product(s).</p>
            </section>
            <section>
                <p><strong>PERMITTED USE OF PRODUCTS AND SERVICES</strong></p>
                <p> We only supply the Products in our online shop for domestic and private use. By accepting these
                    Terms, you agree not to use the Products for any commercial, business, or resale purposes.</p>
            </section>
            <section>
                <p><strong>ARTWORK</strong></p>
                <p> Artwork which is purchased through the Vieunite Pro Library available via the Vieunite Pro Portal
                    (“Portal”) can be displayed on the Product. </p>
                <p> All artwork IP, including and without limitation to, patents, trademarks, service marks, designs,
                    copyrights and related rights, database rights, know-how, moral rights and domain names, as well as
                    applications for registration of such rights and the right to apply for registrations, and all
                    equivalent or similar forms of protection notwithstanding the manner in which they arise, shall be
                    governed under the Terms and Conditions provided herewith. </p>
            </section>
            <section>
                <p><strong>YOUR RIGHTS TO RETURN PRODUCTS </strong></p>
                <p> Your right to return Products if you change your mind.</p>
                <p> You have the right to return purchased Products for a refund within thirty (30) days of your
                    receiving the Products for any reason. The Products must be returned in a resalable “as- new”
                    condition with the original packaging and all accessories. We reserve the right to charge you a fee
                    for damages including, but not limited to, scratches, dents and other damages beyond reasonable wear
                    and tear.</p>
                <p> Our return process.</p>
                <p> Please contact <a href="mailto:support@vieunite.com">support@vieunite.com</a> to return Products and
                    state the reason for your return. You
                    must enclose a copy of your proof of purchase with your returned Product.</p>
                <p> If you change your mind after products have been dispatched to you or you have received them and you
                    elect to cancel your order as described above, you must return the products to us. You must either
                    return the goods in person to where you bought them, or post them back to us Vieunite Limited at
                    Allsee Building, 38 Kettles Wood Drive, Birmingham, United Kingdom, B32 3DB within 14 days of
                    telling us you wish to exercise your right to return Products. If you choose to return a Product in
                    person, please schedule an appointment by contacting us at <a
                        href="mailto:support@vieunite.com">support@vieunite.com</a> before returning
                    your product in person.</p>
                <p> You will be responsible for paying the cost of postage if you choose to return Products to us. Proof
                    of shipping must be obtained for all returned Products. You will be responsible for the Products
                    until they are returned to us. For further details of how to return items to us will be sent to you
                    in the e-mail confirming your order.</p>
                <p> Your right to receive a refund. </p>
                <p> If your Product hasn't been dispatched when you change your mind, we refund you as soon as possible
                    and within 14 days of you telling us you've changed your mind. If you need to send the Product back
                    to us first, we refund you within 14 days of receiving them back from you. We refund you by the
                    method you used for payment. We do not charge a fee for the refund.</p>
                <p> If you handle the product in a way which would not be acceptable in-store, we will reduce your
                    refund, to compensate us for its reduced value. For example, we reduce your refund if the product's
                    condition is not "as new", price tags have been removed, the product-branded packaging is damaged,
                    or accessories are missing. In some cases, because of the way you have treated the product, no
                    refund may be due. </p>
                <p> Your right to make changes to the order. </p>
                <p> If you wish to make changes to the Products you have ordered, please contact us by emailing
                    <a href="mailto:support@vieunite.com">support@vieunite.com</a>. We will let you know if the change
                    is possible. If it is possible, we will let
                    you know about any changes to the price of the Products, timing of
                    supply or anything else which would be necessary as a result of your requested change and ask for
                    you to confirm whether you wish to go ahead with the change.
                </p>
                <p> This provision does not affect your statutory rights as a consumer (see section below, “Your right
                    to return Products that are faulty or not as described”). </p>
                <p> FAULTY PRODUCTS </p>
                <p> Your right to return Products that are faulty or not as described. </p>
                <p> Your right to make changes to the order. </p>
                <p> If you think there is something wrong with your product, you must contact us at
                    <a href="mailto:support@vieunite.com">support@vieunite.com</a>. We will honour our legal duty to
                    provide you with Products that are as
                    described to you on our Platform and that meet all the requirements imposed by law. </p>
                <p> FAULTY GOODS </p>
                <p> If your Product is faulty and you inform us of the fault within the warranty or guarantee period (36
                    months from the date of purchase), we will offer you an advance replacement of your Product free of
                    charge. We will collect the faulty Product from you at our expense. </p>
                <p> We will not charge you any collection or shipping fees. </p>
                <p> How to benefit from the Guarantee </p>
                <p> Please contact us at Support@Vieunite.com within the guarantee period. You must make sure that: </p>
                <ul>
                    <li> You provide us with proof of purchase; and</li>
                    <li> The Product must be in otherwise resaleable “as-new” conditions, complete with all accessories
                        and with the original box, packaging and manuals.
                    </li>
                </ul>
                <p> Collection of Faulty Product </p>
                <p> We will request that you allow us to collect your Product at our expense, so that we can inspect the
                    Product, verify the fault and attempt to repair the Product. </p>
                <p> Exceptions from the Guarantee </p>
                <p> We do, however, reserve the right to ask you to return the advance replacement Product, in the event
                    we find that the fault is due to accident, neglect, and misuse. As goodwill gesture, we will return
                    your repaired Product at our expense. For the avoidance of doubt, a Product is only covered by the
                    Guarantee where it is faulty. A Product is faulty where there are defects in the design or
                    performance of the Product. We therefore do not cover faults caused by your actions including any
                    accident, neglect, misuse. We also do not cover faults which result from normal wear and tear </p>
            </section>
            <section>
                <p><strong>THIS GUARANTEE DOES NOT AFFECT YOUR LEGAL RIGHTS. WHERE THERE IS ANY CONFLICT BETWEEN THE
                    TERMS OF THIS GUARANTEE AND YOUR LEGAL RIGHTS, YOUR LEGAL AND OTHER STATUTORY RIGHTS WILL PREVAIL.
                    DETAILS OF YOUR LEGAL AND OTHER STATUTORY RIGHTS ARE AVAILABLE FROM PREVAILING TRADING
                    STANDARDS.</strong></p>
                <p> YOUR RIGHTS TO CANCEL SERVICES </p>
                <p> Do I have a trial period for Services? </p>
                <p> Yes. By default, a no-obligation, thirty (30) day free trial period is extended to all fee-paying
                    Advanced and Premium Services Subscription Plans. Standard Plans come free. Before the end of your
                    free trial period, you will be contacted by us to subscribe to the Services Subscription Plans.
                    Payment will be done through a recognized third-party payment provider: </p>
                <ul>
                    <li>For the free Standard Plan, you will be contacted by us via email seven (7) days before the
                        end of the trial to consider the option of purchasing Product(s) to bundle with your free
                        Standard Plan.
                    </li>
                    <li>For fee-paying Advanced Plan, you will also be contacted by us via email seven (7) days
                        before the end of the trial to consider the option of purchasing Products to bundle with the
                        Advanced Plan. You will be requested to also confirm your purchase and agree to the continued
                        use of the Advanced Plan.
                    </li>
                    <li>Customers who are on the trial period for Premium Plan (Price-on-Application (POA)) seven (7)
                        days before the end of the trial would have purchased the requisite number of Products. You will
                        also be contacted by us via email to confirm your purchase and agree to the continued use of the
                        Premium Plan.
                    </li>
                </ul>
                <p> During the trial period, your credit/debit card will be NOT be charged by our authorized third-party
                    payment processor. Subscription Plan fees will be charged only after the free trial period. In any
                    case, we will contact you by email seven days (7) before the end of your free trial period, during
                    which you have the choice to cancel or to continue Service Subscription Plans. </p>
                <p> The 30-day free trial must adhere to the following conditions: </p>
                <ul>
                    <li>1. The offer is exclusive to first-time customers and is limited to one-time usage. To prevent
                        duplication, this offer is linked to your business addresses, and business phone numbers;
                    </li>
                    <li>2. You will be required to provide debit/credit card information during sign-up, but no payment
                        will be taken until the end of free trial period, whereafter it will be processed automatically
                        on an annual basis. We do not have access to your debit/card card information. This is done
                        through our authorized third-party payment processor.
                    </li>
                    <li>3. We will contact you via email seven (7) days before the end of your trial. You have the
                        flexibility to cancel your subscription and remove card information at that time. Even if you
                        cancel immediately after signing up, you still have access to the whole duration of the free
                        trial.
                    </li>
                </ul>
                <p> Your right to receive a refund. </p>
                <p> If you choose to cancel the Services during subscription period, we will process your cancellation
                    as soon as practicable, and within 14 days of your telling us you wish to cancel in writing
                    (including email). We will not charge a fee for processing. However, you will not be refunded for
                    the remainder of the subscription period. By default, you will also be downgraded to the Standard
                    Plan, which does not attract any subscription fee. </p>
                <p> Will you remind me close to the end of my paid Subscription Plan? </p>
                <p> For your convenience, we will send you an email thirty (30) days before the end of my paid
                    Subscription Plan period informing you that the subscription will be auto-renewed upon expiration of
                    the Minimum Period. We will also send you another email seven (7) days before the end of the paid
                    Subscription Plan to remind you one final time on your auto-renewal. You may choose to terminate
                    your subscription (and by default, fall back to the free Standard Plan (for up to 2 Textura
                    displays) or you if you are in the Advanced Plan, you may also choose to upgrade to the paid Premium
                    Plan. </p>
                <p><strong>As the Product and the Service works as a bundle, the following Scenarios may apply to you in
                    the following page: </strong></p>
                <table>
                    <thead>
                    <tr>
                        <th>Existing Subscription Plan</th>
                        <th>Scenario</th>
                        <th>Remarks</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows.map((row, index) => (
                        <tr key={index}>
                            <td>{row.plan}</td>
                            <td>{row.scenario}</td>
                            <td>
                                <ul>
                                    {row.remarks.map((remark, index) => (
                                        <li key={index}>{remark}</li>
                                    ))}
                                </ul>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </section>
            <section>
                <p><strong>OUR LIABILITY</strong></p>
                <p> We are responsible to you for foreseeable loss and damage caused by us </p>
                <p> If we fail to comply with these Terms, we are responsible for loss or damage you suffer that we
                    could have foreseen because the loss or damage was an obvious consequence of our failure to comply
                    with these Terms or our negligence or if they were contemplated by you and us at the time we entered
                    into the Contract. We are not responsible for any loss or damage that we could not have
                    foreseen. </p>
                <p> While we supply Products and Services for business use, we bear no liability to you for any loss of
                    profit, loss of business, business interruption, or loss of business opportunity in the course of
                    using our Products and Services. In addition, we expressly exclude any liability for any loss or
                    damage which may be incurred by you as a result of your breach of these Terms. </p>
                <p> Notwithstanding the generality of the forgoing, we do not exclude or limit in any way our liability
                    to you where it would be unlawful to do so. In simple terms, we do not in any way exclude or limit
                    our liability for death or personal injury caused by our negligence; or fraud or fraudulent
                    misrepresentation; or any other liability which may not be limited or excluded under applicable law.
                    Any statutory warranties to which you are entitled as a business consumer (including, for example,
                    that any service is carried out with reasonable skill and care, and any Products provided are of
                    satisfactory quality) are unaffected by these Terms and Conditions. </p>
                <p> Our total aggregate liability in respect of any and all claims that you may bring against us in
                    connection with Contracts and/or any use by you of the Platform or Products shall be no more than
                    the total of twice the value of the affected Product(s). </p>
                <p> You accept that these limitations of liability shall also apply to any and all claims which you may
                    make in respect of the matters specified in these Terms and their subject matter (howsoever those
                    claims arise). </p>
            </section>
            <section>
                <p><strong>OTHER IMPORTANT TERMS</strong></p>
                <p> You can go to court. These terms are governed by English law and wherever you live you can bring
                    claims against us in the English courts. If you live in Wales, Scotland or </p>
                <p> Northern Ireland, you can also bring claims against us in the courts of the country you live in. We
                    can claim against you in the courts of the country you live in. </p>
                <p> Contract transfer. We can transfer our Contract with you, so that a different organisation is
                    responsible for supplying your product. We'll tell you in writing if this happens. You can only
                    transfer your Contract with us to someone else if we agree to this. </p>
                <p> Nobody else has any rights under each Contract. Each Contract is between you and us. Nobody else can
                    enforce it and neither of us will need to ask anybody else to sign-off on ending or changing it.</p>
                <p> Even if we delay, we might not immediately chase you for not doing something (like paying) or for
                    doing something you're not allowed to, but that doesn’t mean we cannot do it later.</p>
            </section>
            {/*<Button className="PaymentAgreementButton" onClick={handleClose}>Close</Button>*/}

        </div>
    );
};

export default PaymentAgreementTermsAndConditions;
