import React from 'react';
import {Form, Input, Button} from 'antd';
import './style.scss'
import {Link, useLocation, useNavigate} from "react-router-dom";
import ProLogo from './imgs/ProLogo.svg';
import {post_api} from "../../portal/serverTemp";

const ProSignUpVerification = () => {
    const navigate = useNavigate();  // 创建 navigate 实例
    const {state} = useLocation();
    const {email} = state ?? ''

    return (
        <div style={{marginTop: '5vw'}}>
            <div className="ProLogo">
                <img src={ProLogo} alt="ProLogo" className='ProLogo'/>
            </div>
            <div className='ProVerification-Content'>
                <p className="ProVerification-text">
                    Please complete the sign-up process before proceeding to log in. To verify
                    your account, please <strong>click the link</strong> in the email we've sent you. If you don't see
                    the email in your
                    inbox, please check your spam folder.
                </p>
                <div className="custom-OK-Button">
                    <Button type="primary"
                            className="Email-button"
                            onClick={() => {
                                post_api('/user/send/verify/email/link', {email: email})
                                    .catch(error => console.error('Error:', error));
                            }}
                    >
                        Send email again
                    </Button>
                </div>
                <div className="custom-Email-Button">
                    <Button type="primary" className="OK-Button" onClick={() => navigate(-1)}>
                        GO BACK
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ProSignUpVerification;
