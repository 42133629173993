import React, {useState} from 'react';
import {Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import {TitleFont} from "../../settings";
import ShadowHeader from "../../components/Header/ShadowHeader";
import {InputEmail} from "./components/InputEmail";
import {InputCode} from "./components/InputCode";
import {InputNewPassword} from "./components/InputNewPassword";
import useWindowResize from "../Hooks/useWindowResize";


const ForgotPassword = (props) => {
    const [step, setStep] = useState(0)
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const {windowWidth} = useWindowResize()
    return (
        <>
            <ShadowHeader/>
            <Layout>
                <Content style={{overflow: 'hidden', maxHeight: '100vh'}}>
                    <Row justify={"center"}>
                        <Col lg={10} sm={20} className={'login-form-col'} style={{}}>
                            <Row justify={"center"}>
                                <h2 className="title-app"
                                    style={window.outerWidth >= 600 ?
                                        {textAlign: "center", ...TitleFont,} :
                                        {textAlign: "left", ...TitleFont, marginTop:'20%'}}
                                >
                                    Artist Portal
                                </h2>
                            </Row>
                            {step === 0 && <InputEmail setStep={setStep} setEmail={setEmail}/>}
                            {step === 1 && <InputCode setStep={setStep} email={email} setCode={setCode}/>}
                            {step === 2 && <InputNewPassword setStep={setStep} email={email} code={code}/>}
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    )
}

export default ForgotPassword;