import React from "react";
import {Helmet} from "react-helmet";
import socialCard from '../../img/Social Cards/Social Card - Artists.jpg';

export const ArtistsPageMeta = () => (
    <Helmet>
            <title>Artists | Vieunite</title>
            <meta name="description"
                  content="Explore the Textura digital art canvas, elevate your interiors with this display and join a community of art lovers."/>
            <meta name="keywords"
                  content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
            <meta name="twitter:card" value="summary_large_image"/>
            <meta name="twitter:site" value="@VieuniteHQ"/>
            <meta name="twitter:title" value="Artists | Vieunite"/>
            <meta name="twitter:description"
                  value="Explore the Textura digital art canvas, elevate your interiors with this display and join a community of art lovers"/>
            <meta name="twitter:url" value="https://vieunite.com/artists"/>
            <meta name="twitter:image" value={'https://vieunite.com/img/Social Card - Artists.jpg'}/>

            <meta property="og:url" content="https://vieunite.com/artists"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content="Artists | Vieunite"/>
            <meta property="og:description"
                  content="Explore the Textura digital art canvas, elevate your interiors with this display and join a community of art lovers"/>
            <meta property="og:image" content={'https://vieunite.com/img/Social Card - Artists.jpg'}/>
            <meta property="og:image:width" content="930"/>
            <meta property="og:image:height" content="620"/>

    </Helmet>);