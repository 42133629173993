import {APP_API_URL} from "../../settings";
import {type} from "@testing-library/user-event/dist/type";


export const login = async (values, setReminder) => {

    const req = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(values)
    }
    return fetch(APP_API_URL + "/photoframe-mobile/user/login", req)
        .then(res => {
            return res.json();
        })
        .then((data) => {
                console.log(data)
                if (data.code !== 200) {
                    setReminder(data.message);
                    throw Error(data.message)
                } else {
                    localStorage.setItem('token', data.result.token)
                    localStorage.setItem('userInfo', JSON.stringify(data.result.userInfo))
                }
            }
        ).then(() => {
            const storageInfo = JSON.parse(localStorage.getItem('userInfo'))
            const isArtist = storageInfo.isArtist
            if (isArtist) {
                window.location.href = '/artistportal/home'
            } else {
                // window.alert('You are not an artist, please apply first')
                // localStorage.removeItem('token')
                // localStorage.removeItem('userInfo')
                localStorage.removeItem('uris')
                window.location.href = '/artistportal/apply'
            }
        })
}

export const checkIfArtist = async (email) => {
    const req = {
        method: 'GET',
    }

    return fetch(APP_API_URL + `/photoframe-mobile/userAuthorizationSubsystem/is/artist?email=${email}`, req)
        .then(res => res.json())
        .then(data => {
            return data.result
        })
}
export const getVerificationCode = (email) => {
    const req = {
        method: 'GET',
    }
    return fetch(APP_API_URL + `/photoframe-mobile/user/sendEmailVerificationCode?receiver=${email}&sendEmailType=3`, req)
        .then(res => {
            return res.json();
        })
        .then(data => {
                console.log(data)
                if (data.code !== 200) {
                    alert(data.message)
                    throw Error(data.message)
                }
                return data
            }
        )
}

export const verifyCode = (input) => {
    const req = {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(input)
    }
    return fetch(APP_API_URL + `/photoframe-mobile/userAuthorizationSubsystem/verificationCode/verify`, req)
        .then(res => {
            return res.json();
        })
        .then(data => {
                console.log(data)
                if (data.code !== 200) {
                    alert(data.message)
                    throw Error(data.message)
                }
                return data
            }
        )
}

export const resetPassword = (input) => {
    console.log(input)
    const req = {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(input)
    }
    return fetch(APP_API_URL + `/photoframe-mobile/user/forgetPassword`, req)
        .then(res => {
            return res.json();
        })
        .then(data => {
                console.log(data)
                if (data.code !== 200) {
                    if (data.code === 2003) {
                        alert(data.message)
                        window.location.reload()
                    }
                    throw Error(data.message)
                }
                return data
            }
        )
}