import React, {useEffect, useRef} from 'react';
import "./ProCommodity.scss"
import {Button, Col, Collapse, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import Commodity from "../ProCommodity/imgs/Commodity.png";
import Title from "antd/es/skeleton/Title";
import {
    TLayoutStyle2,
    TLayoutStyle2Reversed,
    TLayoutMobile
} from "../ProSubscription/ProTLayout";
import section_background_1 from "../ProCommodity/imgs/background-1.png";
import section_background_2 from "../ProCommodity/imgs/background-2.png";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {PRODUCT_TEXT} from "../../text";
import img1 from "../ProCaseStudy/img/Employee 1.png";
import VerticalCarousel from "../../components/Carousel/VerticalCarousel";
import landscape_vd from "../ProCaseStudy/img/Draft 1.mp4";
import ProVerticalCarousel from "./ProCarousel/ProVerticalCarousel";
import useWindowResize from "../../apps/Hooks/useWindowResize";
import ContactSection from "../ProCaseStudy/components/ContactSection";


const {Panel} = Collapse;
const ProCommodity = (props) => {
    const {windowWidth} = useWindowResize()
    const CommoditySectionTitle1 =
        <p className={"pro-section-commodity-title"}>
            Differentiate Your Brand with the <span style={{color: '#936846'}}> Power of Art </span>
        </p>

    const CommoditySectionTitle2 =
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginRight: '2rem'}}>
            <p className={"pro-section-commodity-title-button"} style={{textAlign: 'right'}}>
                Elevate Your Space: <span style={{color: '#936846'}}> Inspire Creativity </span> and <span
                style={{color: '#936846'}}> Productivity </span>
            </p>
            <button
                onClick={_ => window.location.href = "/pro/customer-stories"}
                className="homeMainButton black-border-hover-white"
                style={{maxWidth: "22rem", width: '100%'}}>
                <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                    EXPLORE CUSTOMER STORIES
                </p>
            </button>
        </div>

    const CommoditySectionTitle1Mobile =
        <p className={"pro-section-commodity-title-mobile"}>
            Differentiate Your Brand with the <span style={{color: '#936846'}}> Power of Art </span>
        </p>

    const CommoditySectionTitle2Mobile =
        <div style={{display: 'flex', flexDirection: 'column', margin: '6vw'}}>
            <p className={"pro-section-commodity-title-button"} style={{margin: '0'}}>
                Elevate Your Space: <span style={{color: '#936846'}}> Inspire Creativity </span> and <span
                style={{color: '#936846'}}> Productivity </span>
            </p>
            <div style={{height: '1rem'}}></div>
            <button
                onClick={_ => window.location.href = "/pro/customer-stories"}
                className="homeMainButton black-border-hover-white"
                style={{width: '100%'}}>
                <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                    EXPLORE CUSTOMER STORIES
                </p>
            </button>
            <div style={{height: '1rem'}}></div>
        </div>

    const page1 = [
        [
            "Redefine Brands",
            "Tailored playlists align with brand values, making art an extension of your company’s narrative.", "link"],
        [
            "Re-engage Clients",
            "Art helps your brand connect deeply with audiences, creating a lasting impression.", "link"],
        [
            "Elevate Customer Experiences",
            "Art creates immersive, memorable, experiences that enhance customer engagement and loyalty.", "link"
        ]
    ];
    const page2 = [
        [
            "Extend Your Corporate Narrative",
            "Your brand carries a story, your narrative bears a legacy. Art becomes an extension of your narrative to foster a strong team spirit within your company.", "link"],
        [
            "Motivate Employees",
            "Art fosters pride and belonging in employees, boosting motivation, productivity, and retention.", "link"],
        [
            "Enhance Mental Well-being",
            "Expertly curated biophilic artworks cut through message fatigue without distracting staff.", "link"
        ]
    ];
    const videoRef = useRef(null);
    const makeMuted = (elt) => {
        if (elt) {
            elt.muted = true;
        }
    };
    return (
        <Layout>
            <Content>
                <Row justify={"center"} style={{width: '82vw', margin: '14vh auto 2vh 4vw'}}
                     className={'pro-commodity-carousel-outer-row'}>
                    <Col style={{width: windowWidth > 920 ? '60vw' : '80vw', height: '60vh',}}>
                        <ProVerticalCarousel/>
                    </Col>
                    <Col style={{
                        width: windowWidth > 920 ? '20vw' : '80vw',
                        // height: '60vh',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: windowWidth > 920 ? 'flex-start' : "center",
                        marginTop: windowWidth > 920 ? '0' : '2rem'
                    }}>
                        <div>
                            <p className={'pro-commodity-top-p1'}>Textura Pro Digital Canvas</p>
                            <p className={'pro-commodity-top-p2'}> Experience art like never before with Textura Pro,
                                featuring a texture-accurate display technology that brings every brushstroke and detail
                                to life in vivid realism.</p>
                            <p className={'pro-commodity-top-p3'}></p>
                            <ul className={'pro-commodity-top-p4'}>
                                <li>Designed for seamless integration, the Textura
                                    Pro: Mounts flush against the wall and adapts effortlessly to landscape or portrait
                                    orientation
                                </li>
                                <li>Offers a choice of four exquisite real wood frame finishes to suit any
                                    space
                                </li>
                                <li> Provides complete management control through the Vieunite Pro Portal,
                                    making
                                    display management simple and fully customisable.
                                </li>
                            </ul>

                        </div>
                        <div>
                            <p className={'pro-commodity-top-p1'}>£619</p>
                            <p className={'pro-commodity-top-p3'} style={{margin: '-1vh auto 1.2vh auto'}}>£742.80 incl.
                                VAT</p>
                            <p className={'pro-commodity-top-p2'} style={{margin: '-1vh auto 3vh auto'}}>SUBSCRIPTION
                                FEES MAY APPLY</p>
                            <button onClick={_ => window.location.href = "/pro/support/request-demo"}
                                    className={'pay-button'}
                                    style={{
                                        marginTop: '1em',
                                        fontFamily: "Proxima Nova Regular, sans-serif",
                                        fontWeight: '800',
                                        borderRadius: "5px",
                                        padding: '0.5em',
                                        width: '100%',
                                        cursor: 'pointer',
                                    }}
                            >
                                ENQUIRY
                            </button>
                        </div>
                    </Col>
                </Row>

                {/*<div className="Commodity-title-container">*/}
                {/*    <Row justify="center" className={'commodity-text'}>*/}
                {/*        <Col span={24} style={{textAlign: 'center'}}>*/}
                {/*            <div className="Commodity-title">*/}
                {/*                Transforming business spaces with seamless art and cutting-edge technology*/}
                {/*            </div>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</div>*/}
                <div className="Commodity-title">
                    Transforming business spaces with seamless art and cutting-edge technology
                </div>
                {windowWidth > 920 ?
                    <div>
                        <TLayoutStyle2
                            HeaderSection={CommoditySectionTitle1}
                            CardList={page1}
                            BackgroundImg={section_background_1}/>
                        <TLayoutStyle2Reversed
                            HeaderSection={CommoditySectionTitle2}
                            CardList={page2}
                            BackgroundImg={section_background_2}
                        />
                    </div> :
                    <div>
                        <TLayoutMobile
                            HeaderSection={CommoditySectionTitle1Mobile}
                            CardList={page1}
                            BackgroundImg={section_background_1}/>
                        {/*<div style={{height: '6rem'}}></div>*/}
                        <TLayoutMobile
                            HeaderSection={CommoditySectionTitle2Mobile}
                            CardList={page2}
                            BackgroundImg={section_background_2}
                        />
                        {/*<div style={{height: '6rem'}}></div>*/}
                    </div>
                }

                <Row justify={"center"} style={{marginBottom: '6vh'}}>
                    <Col span={20} lg={20} md={20}>
                        <Collapse expandIconPosition={"end"}
                                  bordered={false}
                                  style={{
                                      backgroundColor: '#FFF', color: '#FFF', border: "none"
                                  }}
                                  expandIcon={(panelProps) => {
                                      if (panelProps.isActive) {
                                          return <MinusOutlined style={{fontSize: '24px', fontWeight: "900"}}/>
                                      } else {
                                          return <PlusOutlined style={{fontSize: '24px', fontWeight: "900"}}/>
                                      }
                                  }}
                        >
                            <Panel header="Specification"
                                   className={'help-center-collapse-copy'}
                                   key="1">

                                <i className="specification-section-title">Physical Specification</i>
                                <br/>
                                <b className="specification-title">Display Size</b>
                                <p className="specification-body">27 Inches</p>
                                <b className="specification-title">Resolution</b>
                                <p className="specification-body">1920x1080</p>
                                <b className="specification-title">Speakers</b>
                                <p className="specification-body">1.5W, 8Ω (x2)</p>
                                <b className="specification-title">Canvas Orientation</b>
                                <p className="specification-body">Portrait/Landscape</p>
                                <b className="specification-title">Canvas Size</b>
                                <p className="specification-body">711.1x449.7x33.95mm</p>
                                <b className="specification-title">Net Weight</b>
                                <p className="specification-body">5.4Kg</p>
                                <b className="specification-title">Gross Weight</b>
                                <p className="specification-body">8.2Kg</p>
                                <b className="specification-title">Package Size</b>
                                <p className="specification-body">820x545x145mm</p>
                                <br/>
                                <i className="specification-section-title">Technical Specification</i>
                                <br/>
                                <b className="specification-title">CPU</b>
                                <p className="specification-body">UNISOC UIS8581 /8 Core Cortex A55</p>
                                <b className="specification-title">RAM</b>
                                <p className="specification-body">2GB LPDDR4</p>
                                <b className="specification-title">Storage</b>
                                <p className="specification-body">32GB eMMC</p>
                                <b className="specification-title">Control</b>
                                <p className="specification-body">Vieunite App</p>
                                <b className="specification-title">Supported Formats</b>
                                <p className="specification-body">Image (Jpg), Video (MPG, AVI, MP4)</p>
                            </Panel>
                            <Panel header="Description"
                                   className={'help-center-collapse-copy'}
                                   key="2">
                                <p>{PRODUCT_TEXT.Description}</p>
                                <p>{PRODUCT_TEXT.Description_Dimensions}</p>
                            </Panel>
                            <Panel header="Delivery" key="3" className={'help-center-collapse-copy'}>
                                <p>{PRODUCT_TEXT.Delivery}</p>
                            </Panel>
                            <Panel header="Return Policy" key="4" className={'help-center-collapse-copy'}>
                                <p>
                                    {PRODUCT_TEXT["Return Policy"]}
                                    <a href="mailto:info@vieunite.com"
                                       style={{color: "black", textDecoration: "underline"}}>
                                        support@vieunite.com.
                                    </a>
                                </p>
                            </Panel>
                            <Panel header="In the Box" key="5" className={'help-center-collapse-copy'}>
                                <p>{PRODUCT_TEXT["In the Box"]}</p>
                            </Panel>
                            <Panel header="Downloads" key="6" className={'help-center-collapse-copy'}>
                                <a href="/Vieunite - VT27WGV1 - Version 1.0 User Manual.pdf">
                                    Vieunite - VT27WGV1 - Version 1.0 User Manual
                                </a>
                                <br></br>
                                <a href="/VT27WGV1 - Technical Drawing.pdf">
                                    VT27WGV1 - Technical Drawing
                                </a>
                            </Panel>
                            <Panel header="Warranty" key="7" className={'help-center-collapse-copy'}>
                                <p>{PRODUCT_TEXT.Warranty}</p>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <ContactSection bgColor={'rgba(42, 150, 21, 0.08)'} link={"/pro/support/request-demo"}/>
            </Content>
        </Layout>
    )
}

export default ProCommodity;
