import React, { useState } from 'react';
import './style.scss';
import {Button, Layout, Row} from "antd";
import { Content } from "antd/es/layout/layout";
import ProPortalHeader from "../../componets/ProPortalHeader";
import ProfileRow from "../../componets/ProfileRow";
import Navigator from "../../componets/Navigator";

const MainContent = () => {
    // Initial video ID
    const initialVideoId = 'Iu_GtNrsROo';
    // Use useState to track the currently selected video ID
    const [currentVideoId, setCurrentVideoId] = useState(initialVideoId);
    // Array of video IDs, each button corresponds to one ID
    const videoIds = {
        canvasSettings: 'Iu_GtNrsROo',
        uploadingArt: 'nHxYW5ky2ac',
        accountSettings: 'TvL_5MYxYLo',
        album: 'bQUTvmi9g8Y',
        dashboard: '5Ha0zoMsGZI',
        discoverArt: 'I3r_g1cq4vo',
        hardwareDetail: 'qtM_xNutT2M',
        loggingIn: 'bQFhXCeZ9NE',
        subscription: 'kdstx-qoJR8'
    };
    // Function to update the video ID
    const updateVideo = (videoId) => {
        setCurrentVideoId(videoId);
    };

    return (
        <div className='ProTutorial'>
            <p className='ProTutorial-title'>Support</p>
            <div className="video-and-controls">
                <div className="video-section">
                    <iframe className="ProTutVideo"
                            width="660"
                            height="415"
                            src={`https://www.youtube.com/embed/${currentVideoId}?autoplay=1`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                    ></iframe>
                    <div className="video-description">
                        <p className='TutSubT'>Getting Started Video</p>
                        <p className='TutSubT1'>Did you get the help you needed?</p>
                        <p className='TutSubT2'>If not please get in touch with us for any additional help in setting up or using <br/>your Textura digital canvas</p>
                        <p className='TutSubT3'>Call us on 03455-481311</p>
                        <Button className="TutSubT4" onClick={() => {
                            window.location.href = 'mailto:support@vieunite.com';
                        }}>Email us</Button>
                    </div>
                </div>
                <div className="video-controls-grid">
                    <div className="PortalTut" onClick={() => updateVideo(videoIds.canvasSettings)}>
                        <span>Canvas Settings</span>
                        <p className='ProtalTut-Sub'>How to control various aspects of your device</p>
                    </div>
                    <div className="PortalTut" onClick={() => updateVideo(videoIds.uploadingArt)}>
                        <span>Uploading Art</span>
                        <p className='ProtalTut-Sub'>Adding your own content to your Textura</p>
                    </div>
                    <div className="PortalTut" onClick={() => updateVideo(videoIds.accountSettings)}>
                        <span>Account Settings</span>
                        <p className='ProtalTut-Sub'>Updating your profile</p>
                    </div>
                    <div className="PortalTut" onClick={() => updateVideo(videoIds.album)}>
                        <span>Album</span>
                        <p className='ProtalTut-Sub'>Naming and populating an album</p>
                    </div>
                    <div className="PortalTut" onClick={() => updateVideo(videoIds.dashboard)}>
                        <span>Dashboard</span>
                        <p className='ProtalTut-Sub'>View data and information on paired canvases</p>
                    </div>
                    <div className="PortalTut" onClick={() => updateVideo(videoIds.discoverArt)}>
                        <span>Discover Art</span>
                        <p className='ProtalTut-Sub'>Purchase art, searchable by category or artist</p>
                    </div>
                    <div className="PortalTut" onClick={() => updateVideo(videoIds.hardwareDetail)}>
                        <span>Hardware Detail</span>
                        <p className='ProtalTut-Sub'>Name your device and schedule playlists</p>
                    </div>
                    <div className="PortalTut" onClick={() => updateVideo(videoIds.loggingIn)}>
                        <span>Logging In</span>
                        <p className='ProtalTut-Sub'>Accessing the Portal from the home page</p>
                    </div>
                    <div className="PortalTut" onClick={() => updateVideo(videoIds.subscription)}>
                        <span>Subscription</span>
                        <p className='ProtalTut-Sub'>View various plans and upgrade as required</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ProTutorial = () => {
    return (
        <Layout>
            <Content>
                <ProPortalHeader />
                <ProfileRow />
                <Row justify={"center"}>
                    <Layout>
                        <Navigator />
                        <Layout>
                            <MainContent />
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    )
}

export default ProTutorial;
