import React, {useEffect, useState} from 'react';
// import title_image_1 from './images/home-pro-pic1.png'
import title_image_1 from './images/home-pro-video1.mp4'
// import title_image_1_mobile from './images/home-pro-pic1-mobile.png'
import title_image_1_mobile from './images/home-pro-video1-mobile.mp4'
import left_active from './images/pro-button-left-active.svg'
import left_inactive from './images/pro-button-left-inactive.svg'
import right_active from './images/pro-button-right-active.svg'
import right_inactive from './images/pro-button-right-inactive.svg'
import title_image_2 from './images/home-pro-pic2.png'
import title_image_3 from './images/home-pro-pic3.png'

import section3_img from './images/Hospitality Slideshow Compressed.mp4'
import section3_1img from './images/Healing spaces slideshow compressed.mp4'
import section3_2img from './images/Sales Slideshow Compressed.mp4'
import section3_3img from './images/Office Slideshow Compressed.mp4'

// import section3_img from './images/home_pro_section_4.png'
// import section3_1img from './images/home_pro_section_4_1.png'
// import section3_2img from './images/home_pro_section_4_2.png'
// import section3_3img from './images/home_pro_section_4_3.png'

import section5_img from './images/home_pro_section_5_img.png'
import section5_img_mobile from './images/home_pro_section_5_img_mobile.png'
import section3_icon1 from './images/home_pro_icon1.png'
import section3_icon2 from './images/home_pro_icon2.png'
import section3_icon3 from './images/home_pro_icon3.png'

import './style.scss'
import challenge_background from './images/home_pro_challenge.png'
import {Carousel, Tooltip} from "antd";
import {ScrollActive, ScrollInactive, ScrollLeftArrow, ScrollRightArrow} from "./CarouselComponents";

const page1 = [
    [
        section3_icon1,
        "Strengthen brand awareness and identity",
        "Establish and  strengthen your brand in a compelling and completely new way, setting you apart from your competitors.", "link"],
    [
        section3_icon2,
        "Enhance business ambience and customer experience",
        "Elevate the atmosphere of your business in a unique way, opening the doors to a world " +
        "of digital art. With Vieunite  Pro you can captivate and inspire your audience while subtly delivering promotional content or other messaging.", "link"],
    [
        section3_icon3,
        "Boost employee morale",
        "Use a new and stimulating method to motivate and communicate with your workforce, creating a sustainable and positive work environment.", "link"]
];

const page2 = [
    [
        "Transform Hospitality Spaces with Curated Art",
        "Create unforgettable guest experiences with a digital art canvas that redefines any space. Set the ideal mood, promoting relaxation and engagement for a truly memorable atmosphere.",
        section3_img
    ],
    [
        "Healing Spaces with Artful Playlists",
        "Transform your environment with curated art and music to create soothing, healing spaces. Perfect for promoting relaxation and well-being.",
        section3_1img
    ],
    [
        "Boost Sales with Art That Inspires",
        "Elevate your space with inspiring art that captivates and engages. Create an atmosphere that drives customer interest and boosts sales.",
        section3_2img
    ],
    [
        "Elevate Office Vibes with Tailored Art",
        "Enhance your office environment with bespoke artwork that reflects your brand and energises your team. Create a space that fosters creativity and productivity.",
        section3_3img
    ]
]

export function HomeProMobile() {
    const carouselRef = React.createRef();
    const carouselRef2 = React.createRef();
    const [currentTab, setCurrentTab] = useState(0);
    const [currentTab2, setCurrentTab2] = useState(0);
    const cardData = [...page1]

    return (<div>
        <div style={{height: '8rem'}}></div>
        <Carousel
            ref={carouselRef}
            dots={false}
            dotPosition={"bottom"}
            autoplay={false}
            afterChange={(current) => {
                // console.log("show page" + current)
                setCurrentTab(current)
            }}
        >
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <div className={'spacer'}></div>
                        <video src={title_image_1_mobile} alt={'platform'} style={{width: '70vw', height: "auto"}}
                               autoPlay
                               loop
                               muted/>
                    </div>
                    <p className={'home-pro-title2'} style={{padding: '10px 20px 0 20px', fontSize: '2.25rem'}}>
                        <span style={{color: '#936846'}}>Vieunite Pro,</span> <br/> a
                        revolutionary art and communication platform
                    </p>
                    <p className={'home-pro-title3'} style={{padding: '0 20px 20px 20px', margin: '0'}}>
                        Combining innovative and unique display technology with a
                        back-end management system and a huge library of expertly curated artwork
                    </p>
                </div>
            </div>
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <div className={'spacer'}></div>
                        <img src={title_image_2} alt={'platform'} style={{width: '70vw', height: "auto"}}/>
                    </div>
                    <p className={'home-pro-title2'} style={{padding: '10px 20px 0 20px', fontSize: '2.25rem'}}>
                        <span style={{color: '#936846'}}>The Textura Pro digital canvas,</span> <br/> uses cutting-edge technology to
                        faithfully display surface textures
                    </p>
                    <p className={'home-pro-title3'} style={{padding: '0 20px 20px 20px', margin: '0'}}>
                        Featuring an anti-glare surface treatment and a finely finely calibrated backlight, the vivid high-definition display delivers artwork with incredible realism, bringing each piece to life.
                    </p>
                </div>
            </div>
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <div className={'spacer'}></div>
                        <img src={title_image_3} alt={'platform'} style={{width: '70vw', height: "auto"}}/>
                    </div>
                    <p className={'home-pro-title2'} style={{padding: '10px 20px 0 20px', fontSize: '2.25rem'}}>
                        <span style={{color: '#936846'}}>With the Vieunite Pro Portal,</span> <br/> you can easily create captivating visual
                        experiences
                    </p>
                    <p className={'home-pro-title3'} style={{padding: '0 20px 20px 20px', margin: '0'}}>
                        Remotely manage your network of your Textura Pros; create playlists mixed with your own content, schedule content to play at different times and access all of your data for monitoring and analytics.
                    </p>
                </div>
            </div>

        </Carousel>
        <div style={{
            marginLeft: '20vw',
            paddingLeft: '20px',
            backgroundColor: '#F5F7F8',
            height: '81px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}>
            <img
                src={left_active}
                alt={'left'}
                onClick={() => {
                    carouselRef.current.prev()
                }}
            />
            <p className={'home-pro-mobile-carousel-control-text'}> {currentTab + 1} / 3 </p>
            <img
                src={right_active}
                alt={'right'}
                onClick={() => {
                    carouselRef.current.next()
                }}
            />
        </div>

        <div className={'grid-center'} style={{padding: '20vw', margin: '0'}}>
            <p className={'home-pro-description home-pro-description-mobile'}>
                We’re revolutionising business communication with dynamic, art-focused digital display solutions
            </p>
        </div>

        <TLayoutTitleSectionMobile/>

        <TLayoutMobile/>

        <h2 className={'home-pro-section-4-mobile-title'}>
            Versatile Art Canvas for Every Industry
        </h2>
        <p className={'home-pro-section-4-mobile-body'}>
            Our Textura Pro digital canvas seamlessly fits into a variety of industries, from retail and hospitality to
            corporate offices, enhancing brand identity, communication, and customer engagement.
        </p>
        <Carousel
            ref={carouselRef2}
            dots={false}
            dotPosition={"bottom"}
            autoplay={false}
            afterChange={(current) => {
                // console.log("show page" + current)
                setCurrentTab2(current)
            }}
        >
            {page2.map((item, i) =>
                <div>
                    <div style={{padding: '6vw 8vw 0 8vw'}}>
                        <video
                            playsInline
                            loop
                            autoPlay
                            muted
                            src={item[2]}
                            style={{width: '100%', height: "auto", borderRadius: '15px', marginBottom: '20px'}}/>
                        <p
                            className={'home-pro-mobile-title-selected'}
                        >
                            {item[0]}
                        </p>
                        <p
                            className={'home-pro-mobile-body'}
                        >
                            {item[1]}
                        </p>
                    </div>
                </div>
            )}
        </Carousel>

        <div style={{
            height: '81px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <img
                src={left_active}
                alt={'left'}
                onClick={() => {
                    carouselRef2.current.prev()
                }}
            />
            <p className={'home-pro-mobile-carousel-control-text'}> {currentTab2 + 1} / {page2.length} </p>
            <img
                src={right_active}
                alt={'right'}
                onClick={() => {
                    carouselRef2.current.next()
                }}
            />
        </div>
        <div style={{height: '5rem'}}></div>

        <div style={{backgroundColor: '#101820'}}>
            <div style={{height: '3rem'}}></div>
            <div style={{padding: '8vw'}}>
                <h2 className={'home_pro_section5_title'}>Get In <span style={{color: '#AF8462'}}>Touch</span></h2>
                <p className={'home_pro_section5_body'}>Discover the benefits of becoming a reseller for Vieunite
                    Pro</p>
                <button
                    className="homeMainButton product-access-support-button"
                    onClick={_ => window.location.href = "/pro/support/become-a-reseller"}
                    style={{width: "100%"}}
                >
                    <a className={'homeMainButtonLnk homeOverV'}>
                        BECOME A RESELLER
                    </a>
                </button>
            </div>
            <img src={section5_img_mobile} alt={'section5_img'} style={{width: '100%', height: 'auto'}}/>
        </div>
    </div>)
}

export function HomePro() {
    const carouselRef = React.createRef();
    const [currentTab, setCurrentTab] = useState(0);

    return (<div>
        <div style={{position: 'relative', margin: '8rem 8vw'}}>
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 2,
                // padding: '9rem 8vw',
                display: 'flex',
                flexDirection: 'column-reverse',
                background: 'transparent',
                pointerEvents: 'none',

            }}>
                {/*<div className={'spacer'} style={{pointerEvents: 'none'}}></div>*/}
                <div style={{
                    background: '#F5F7F8',
                    height: '7.5rem',
                    width: '75%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: "50rem",
                    pointerEvents: 'auto'
                }}>
                    <div
                        className={currentTab === 0 ? "tab-underline-animation tab-underline-animation-selected home-pro-tab-container" : "tab-underline-animation home-pro-tab-container"}
                        onClick={() => {
                            carouselRef.current.goTo(0)
                        }}
                    >
                        <div className={"grid-center"}>
                            <p
                                className={currentTab === 0 ? 'home-pro-tab-text-selected' : 'home-pro-tab-text'}
                            >
                                Vieunite Pro
                            </p>
                        </div>
                    </div>
                    <div className={'tab-vertical-line'}></div>
                    <div
                        onClick={() => {
                            carouselRef.current.goTo(1)
                        }}
                        className={currentTab === 1 ? "tab-underline-animation tab-underline-animation-selected home-pro-tab-container" : "tab-underline-animation home-pro-tab-container"}
                    >
                        <div className={"grid-center"}>
                            <p
                                className={currentTab === 1 ? 'home-pro-tab-text-selected' : 'home-pro-tab-text'}
                                onClick={() => {
                                    carouselRef.current.goTo(1)
                                }}
                            >
                                Textura Pro
                            </p>
                        </div>
                    </div>
                    <div className={'tab-vertical-line'}></div>
                    <div
                        onClick={() => {
                            carouselRef.current.goTo(2)
                        }}
                        className={currentTab === 2 ? "tab-underline-animation tab-underline-animation-selected home-pro-tab-container" : "tab-underline-animation home-pro-tab-container"}
                    >
                        <div className={"grid-center"}>
                            <p
                                className={currentTab === 2 ? 'home-pro-tab-text-selected' : 'home-pro-tab-text'}
                                onClick={() => {
                                    carouselRef.current.goTo(2)
                                }}
                            >
                                Management System
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Carousel
                ref={carouselRef}
                dots={false}
                dotPosition={"bottom"}
                autoplay={false}
                zindex={1}
                afterChange={(current) => {
                    // console.log("show page" + current)
                    setCurrentTab(current)
                }}
            >
                <div>
                    <div className="home-pro-title-section">
                        <div className={'text-section'}>
                            <p className={'home-pro-title2'}><span style={{color: '#936846'}}>Vieunite Pro,</span>
                                <br/> the revolutionary art and communication platform</p>
                            <p className={'home-pro-title3'}>Combining innovative and unique display technology with a
                                back-end management system and a huge library of expertly curated artwork</p>
                            <button
                                className="homeMainButton black-border-hover-white"
                                onClick={_ => window.location.href = 'pro/customer-stories'}
                                style={{width: "20.5rem"}}>
                                <p
                                    className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                    WHY CHOOSE VIEUNITE PRO?
                                </p>
                            </button>
                            <div style={{height: '8.125rem'}}></div>
                        </div>
                        <video
                            className={'home-pro-section-image'}
                            src={title_image_1}
                            autoPlay
                            loop
                            muted/>
                    </div>
                </div>
                <div>
                    <div className="home-pro-title-section">
                        <img className={'home-pro-section-image'} src={title_image_2}
                             alt={'revolutionary art and communication platform'}/>
                        <div className={'text-section'}>
                            {/*<p className={'home-pro-title1'}>With the Vieunite Pro Portal,</p>*/}
                            <p className={'home-pro-title2'}><span style={{color: '#936846'}}>The Textura Pro digital canvas,</span>
                                <br/> uses cutting-edge technology to faithfully display surface textures</p>
                            <p className={'home-pro-title3'}>Featuring an anti-glare surface treatment and a finely
                                finely calibrated backlight, the vivid high-definition display delivers artwork with
                                incredible realism, bringing each piece to life.</p>
                            <button
                                className="homeMainButton black-border-hover-white"
                                onClick={_ => window.location.href = '/pro/textura-pro'}
                                style={{width: "20rem"}}>
                                <p
                                    className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                    EXPLORE TEXTURA PRO
                                </p>
                            </button>
                            <div style={{height: '8.125rem'}}></div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="home-pro-title-section">
                        <div className={'text-section'}>
                            <p className={'home-pro-title2'}><span style={{color: '#936846'}}>With the Vieunite Pro Portal, </span>
                                <br/> you can easily create captivating visual experiences</p>
                            <p className={'home-pro-title3'}>Remotely manage your network of your Textura Pros; create
                                playlists mixed with your own content, schedule content to play at different times and
                                access all of your data for monitoring and analytics.</p>
                            <button
                                className="homeMainButton black-border-hover-white"
                                onClick={_ => window.location.href = '/pro/software'}
                                style={{width: "25rem"}}>
                                <p
                                    className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                    DISCOVER THE VIEUNITE PRO PORTAL
                                </p>
                            </button>
                            <div style={{height: '8.125rem'}}></div>
                        </div>
                        <img className={'home-pro-section-image'} src={title_image_3}
                             alt={'revolutionary art and communication platform'}/>
                    </div>
                </div>
            </Carousel>
        </div>

        <div className={'grid-center'} style={{padding: '1rem'}}>
            <p className={'home-pro-description'}>
                We’re revolutionising business communication with dynamic, art-focused digital display solutions
            </p>
        </div>

        <TLayout/>

        {/*<div style={{height: '0'}}></div>*/}

        <SelectionLayout/>

        {/*<div style={{height: '0'}}></div>*/}

        <div
            style={{
                // height: '493px',
                margin: '8rem 8vw',
                position: 'relative',
                backgroundColor: '#101820',
                borderRadius: '15px'
            }}>
            <div
                style={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    height: '100%',
                    width: '100%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        margin: '0 4vw',
                        zIndex: '2',
                        // backgroundColor: 'red'
                    }}>
                    <h2 className={'home_pro_section5_title'}>Get In <span style={{color: '#AF8462'}}>Touch</span></h2>
                    <p className={'home_pro_section5_body home_pro_section5_body_desktop'}>Discover the benefits of
                        becoming a reseller for Vieunite
                        Pro</p>
                    <button
                        className="homeMainButton product-access-support-button"
                        onClick={_ => window.location.href = "/pro/support/become-a-reseller"}
                        style={{width: "20rem", maxWidth: '25vw'}}
                    >
                        <a className={'homeMainButtonLnk homeOverV'}>
                            BECOME A RESELLER
                        </a>
                    </button>

                </div>
            </div>
            <img src={section5_img} alt={'get in touch'}
                 style={{zIndex: '1', position: 'relative', width: '50vw', height: 'auto'}}/>
        </div>
    </div>)
}

function SelectionLayout() {
    const [currentTab, setCurrentTab] = useState(0);
    const [fade, setFade] = useState(false);
    const [src, setSrc] = useState(section3_img);

    useEffect(() => {
        setFade(true);
        setTimeout(() => {
            if (currentTab === 0) {
                setSrc(section3_img);
            } else if (currentTab === 1) {
                setSrc(section3_1img);
            } else if (currentTab === 2) {
                setSrc(section3_2img);
            } else if (currentTab === 3) {
                setSrc(section3_3img);
            }

            setFade(false);
        }, 100); // match duration with CSS fade-out
    }, [currentTab]);

    return (
        <div style={{margin: '8rem 8vw'}}>
            <div className="home-pro-title-section">
                <div className={'home-pro-section-4-text-container'}>
                    <h2 className={'title1'}>Versatile Art Canvas for Every Industry</h2>
                    <p className={'body'}>Our Textura Pro digital canvas seamlessly fits into a variety of industries,
                        from retail and hospitality to corporate offices, enhancing brand identity, communication, and
                        customer engagement.</p>
                    <div style={{height: '1vw'}}></div>
                    <ul>
                        {page2.map((item, i) =>
                            <li
                                className={`${currentTab === i ? 'title2' : 'body2'}`}
                                onMouseEnter={_ => {
                                    if (!fade) {
                                        setCurrentTab(i)
                                    }
                                }}
                            >
                                <p>{item[0]}</p>

                                <div className={`collapsible ${currentTab === i ? 'open' : 'collapsed'}`}>
                                    <p className={'body'}>{item[1]}</p>
                                    <div className={'line'}></div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
                <video
                    playsInline
                    loop
                    autoPlay
                    muted
                    className={`home-pro-section-image ${fade ? 'home-pro-fade-out' : 'home-pro-fade-in'}`}
                    src={src}/>
            </div>
        </div>
    );
}

function TLayoutMobile() {
    const cardData = [...page1]

    return (<div>
        <div style={{
            display: 'flex',
            flexDirection: "row",
            height: "38rem",
            margin: '5rem 0',
        }}>
            <div
                style={{
                    backgroundImage: 'url(' + challenge_background + ')',
                    backgroundSize: 'cover',
                    height: '100%',
                    width: '45vw',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div className={'spacer'}></div>
                <p className={'home-pro-author-name'}>‘Marine’ by Marina WitteMann</p>
            </div>

            <div style={{
                display: 'flex',
                flexDirection: "column",
                width: '55vw',
            }}>
                <div className={'spacer'}></div>

                <div style={{display: 'flex', flexDirection: 'row', direction: 'rtl'}}>
                    <div style={{
                        width: "80vw",
                        display: 'flex',
                        flexDirection: 'row',
                        flexShrink: '0',
                        overflowX: 'auto',
                        direction: 'ltr',
                        // backgroundColor: 'red'
                    }}>
                        {cardData.map((item, idx) => {
                            return (
                                // <img src={example} alt={"example"} style={{margin: '10px'}}/>
                                //style={{width: `${100 / pageCapacity - 2}%`}}
                                <div
                                    className={'home-pro-section-3-card-mobile'}
                                >
                                    <div style={{
                                        height: '100%',
                                        backgroundColor: '#F5F7F8',
                                        padding: '1.5rem',
                                        borderRadius: '15px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start'
                                    }}>
                                        <img src={item[0]} alt={'icon'}/>
                                        <div style={{height: '1rem'}}></div>
                                        <p className={'home-pro-section-3-card-title'}>{item[1]}</p>
                                        <div style={{height: '1rem'}}></div>
                                        <p className={'home-pro-section-3-card-body'}>{item[2]}</p>
                                        <div style={{height: '3vw'}}></div>
                                        <div className={'spacer'}></div>
                                        <p className={'home-pro-section-3-card-link'}
                                           onClick={_ => window.location.href = "/pro/customer-stories"}>Learn more
                                            ></p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={{height: '5rem'}}></div>
            </div>
        </div>
    </div>)
}

function TLayout() {

    const cardData = [...page1]

    const [currentIndex, setCurrentIndex] = useState(0);

    return (<div>
        <div style={{
            display: 'flex',
            flexDirection: "row",
            height: "54rem",
            margin: '8rem 8vw', // follow the left/right baseline
        }}>
            <div
                style={{
                    backgroundImage: 'url(' + challenge_background + ')',
                    backgroundSize: 'cover',
                    height: '100%',
                    width: '32vw',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div className={'spacer'}></div>
                <p className={'home-pro-author-name'}>‘Marine’ by Marina WitteMann</p>
            </div>

            {/*<img*/}
            {/*    style={{*/}
            {/*        height: '100%',*/}
            {/*        width: '32vw',*/}
            {/*        objectFit: 'cover',*/}
            {/*        borderRadius: '15px',*/}
            {/*    }}*/}
            {/*    src={challenge_background}*/}
            {/*    alt={'challenge'}/>*/}

            <div style={{
                display: 'flex',
                flexDirection: "column",
                width: '52vw'
            }}>
                <div className={'spacer'}></div>
                <TLayoutTitleSection/>
                <div style={{height: '1.5rem'}}></div>

                <div style={{display: 'flex', flexDirection: 'row', direction: 'rtl'}}>
                    <div style={{
                        width: "70vw",
                        display: 'flex',
                        flexDirection: 'row',
                        flexShrink: '0',
                        overflowX: 'hidden',
                        direction: 'ltr',
                    }}>
                        {cardData.map((item, idx) => {
                            return (
                                // <img src={example} alt={"example"} style={{margin: '10px'}}/>
                                //style={{width: `${100 / pageCapacity - 2}%`}}
                                <div
                                    className={'home-pro-section-3-card'}
                                    style={{
                                        transform: `translateX(-${100 * currentIndex}%)`,
                                        transition: 'transform 0.4s ease'
                                    }}
                                >
                                    <div style={{
                                        height: '100%',
                                        backgroundColor: '#F5F7F8',
                                        padding: '1.5vw',
                                        borderRadius: '15px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start'
                                    }}>
                                        <img src={item[0]} alt={'icon'} style={{height: '3rem', width: '3rem'}}/>
                                        <div style={{height: '1rem'}}></div>
                                        <p className={'home-pro-section-3-card-title'} style={{height: "5rem"}}>{item[1]}</p>
                                        <div style={{height: '1rem'}}></div>
                                        <p className={'home-pro-section-3-card-body'}>{item[2]}</p>
                                        <div style={{height: '3vw'}}></div>
                                        <div className={'spacer'}></div>
                                        <p className={'home-pro-section-3-card-link'}
                                           onClick={_ => window.location.href = "/pro/customer-stories"}>Learn more
                                            ></p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', padding: '20px'}}>
                    <ScrollLeftArrow onClick={() => {
                        if (currentIndex > 0) {
                            setCurrentIndex(currentIndex - 1)
                        }
                    }}/>
                    <div className={'spacer'}></div>
                    {cardData.map((item, index) =>
                        <div key={index} style={{margin: '0 2px'}}>
                            {currentIndex === index ?
                                <ScrollActive/> :
                                <ScrollInactive/>}
                        </div>
                    )}
                    <div className={'spacer'}></div>
                    <ScrollRightArrow onClick={() => {
                        if (currentIndex < (cardData.length - 1)) {
                            setCurrentIndex(currentIndex + 1)
                        }
                    }}/>
                </div>
            </div>
        </div>
    </div>)
}

function TLayoutTitleSection() {
    return (<div style={{display: 'flex', flexDirection: 'column', margin: '0 3rem'}}>
        <p className={'home-pro-title2'} style={{maxWidth: '30rem'}}>What challenges can we <span
            style={{color: '#936846'}}> tackle together?</span></p>
        {/*<p className={'home-pro-title1'}>tackle together?</p>*/}
        <div style={{height: '15px'}}></div>
        <button
            className="homeMainButton black-border-hover-white"
            onClick={_ => window.location.href = 'pro/customer-stories'}
            style={{width: "23rem"}}>
            <p
                className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                SEE ALL CUSTOMER STORIES
            </p>
        </button>
    </div>)
}

function TLayoutTitleSectionMobile() {
    return (<div style={{display: 'flex', flexDirection: 'column', margin: '0 8vw'}}>
        <p className={'home-pro-title2'} style={{maxWidth: '30rem', fontSize: '1.875rem'}}>What challenges can we <br/>
            <span style={{color: '#936846'}}> tackle together?</span></p>
        {/*<p className={'home-pro-title1'}>tackle together?</p>*/}
        {/*<div style={{height: '15px'}}></div>*/}
        <button
            className="homeMainButton black-border-hover-white"
            onClick={_ => window.location.href = 'pro/customer-stories'}
            style={{width: "21rem"}}>
            <p
                className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                SEE ALL CUSTOMER STORIES
            </p>
        </button>
    </div>)
}