import {SINGLE_PRICE, SUB_FEES} from "../../constants/proConstants";

export const initialState = {
    totalPrice: 0,
    clientSecret: '',
    isPayModalOpen: false,
};
export const reducer = (draft, action) => {
    switch (action.type) {
        case 'get_total_price':
            draft.totalCanvas = Object.values(draft.counts).reduce((acc, curr) => acc + curr, 0);
            draft.totalPrice = ((draft.totalCanvas * SINGLE_PRICE + draft.totalCanvas * draft.subFee) * 1.2).toFixed(2);
            break
        case 'set_pay_modal':
            draft.isPayModalOpen = action.isPayModalOpen;
            break;
        case 'set_clientSecret':
            draft.clientSecret = action.clientSecret;
            break;
        default:
            break;
    }
}
