import React from "react";
import {Helmet} from "react-helmet";

export const A8Meta = () => (
    <Helmet>
        <title>Doris Luming | Vieunite</title>
        <meta name="description"
              content="Filmmaker, photographer and visual artist based in London who sees her works as the expression of emotion and the recreation of scenes in dreams. "/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Doris Luming"/>
        <meta name="twitter:description"
              value="Doris Luming infuses her photography with rich cultural influences and vibrant emotions. Explore her visually striking works on Vieunite's digital art displays."/>
        <meta name="twitter:url" value="https://vieunite.com/artists/doris-luming"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_doris_luming.jpg"/>
        <meta property="og:url" content="https://vieunite.com/artists/doris-luming"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Artist | Doris Luming"/>
        <meta property="og:description"
              content="Doris Luming infuses her photography with rich cultural influences and vibrant emotions. Explore her visually striking works on Vieunite's digital art displays."/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_doris_luming.jpg"/>
    </Helmet>);