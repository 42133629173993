import React, {useEffect, useState} from 'react';
import {Button, Carousel, Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import desktopVideo from './Lark20241028-115502.mp4'
import mobilevideo from './Lark20241028-115513.mp4'
import section3_img from "../ProCurated/images/pro_curated_section1.png";
import section3_1img from "../ProCurated/images/pro_curated_section1_1.png";
import section3_2img from "../ProCurated/images/pro_curated_section1_2.png";
import section3_3img from "../ProCurated/images/pro_curated_section1_3.png";
import section4_img from "../ProCurated/images/pro_curated_section2.png";
import section4_1img from "../ProCurated/images/pro_curated_section2_1.png";
import section4_2img from "../ProCurated/images/pro_curated_section2_2.png";
import section_title from "../ProCurated/images/pro_curated_title.png";
import RBSALogo from "../../img/HomePage/9 - Logos/rbsa_logo.png";
import NationalGalleryLogo from "../../pages/Art/img/l2.png";
import EstorikLogo from "../../img/HomePage/9 - Logos/estorik_logo.png";
import bottomSection1 from "../ProCurated/images/pro-curated-bottom1.png";
import bottomSection2 from "../ProCurated/images/pro-curated-bottom2.png";
import bottomSection3 from "../ProCurated/images/pro-curated-bottom3.png";
import bottomSection4 from "../ProCurated/images/pro-curated-bottom4.png";
import bottomSection5 from "../ProCurated/images/pro-curated-bottom5.png";
import bottomSection6 from "../ProCurated/images/pro-curated-bottom6.png";
import ContactSection from "../ProCaseStudy/components/ContactSection";
import left_active from "../ProHome/images/pro-button-left-active.svg";
import right_active from "../ProHome/images/pro-button-right-active.svg";
import useWindowResize from "../../apps/Hooks/useWindowResize";

const ProCurated = (props) => {
    const {windowWidth} = useWindowResize();

    const images = [
        bottomSection1,
        bottomSection2,
        bottomSection3,
        bottomSection4,
        bottomSection5,
        bottomSection6,
    ];

    return (
        <Layout>
            <Content>
                <div style={{
                    // background: 'rgb(5 80 133)',
                    background: '#06467A',
                    width: '100vw',
                    height: 'auto',
                    padding: '16vh 9vw',
                    paddingBottom: '10vh'
                }}>
                    <p className={'pro-curated-head-p'}
                       style={windowWidth > 920 ? {fontSize: '3vw'} : {fontSize: '2rem', width: '70vw'}}>
                        Effortless Art Curation at your Fingertips: Schedule, Share, Inspire
                    </p>
                    <Row style={{margin: '-2rem 0 2vh 0'}}>
                        <button
                            className="to-Blue-homeMainButton black-border-hover-white"
                            onClick={_ => window.location.href = "/pro/support/request-demo"}
                            style={{
                                width: "14rem",
                                height: '5vh',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: windowWidth > 920 ? '0' : '4vh'
                            }}
                        >
                            <p className={'homeMainButtonLnk homeOverV'}
                               style={{margin: 0, fontSize: '1rem', fontWeight: '700', textAlign: 'center'}}>
                                GET STARTED
                            </p>
                        </button>
                        {windowWidth>920&&<button
                            className="Blue-homeMainButton black-border-hover-white"
                            onClick={_ => window.location.href = "/pro/support/request-demo"}
                            style={{
                                width: "14rem",
                                height: '5vh',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: windowWidth > 509 ? '2vw' : '0',
                                marginTop: windowWidth > 920 ? '0' : '4vh'
                            }}
                        >
                            <p className={'homeMainButtonLnk homeOverV'}
                               style={{margin: 0, fontSize: '1rem', fontWeight: '700', textAlign: 'center'}}>
                                FREE TRIAL
                            </p>
                        </button>}
                    </Row>
                    {windowWidth<= 920&&<button
                        className="Blue-homeMainButton black-border-hover-white"
                        onClick={_ => window.location.href = "/pro/support/request-demo"}
                        style={{
                            width: "14rem",
                            height: '5vh',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: '0',
                            marginTop: '2vh'
                        }}
                    >
                        <p className={'homeMainButtonLnk homeOverV'}
                           style={{margin: 0, fontSize: '1rem', fontWeight: '700', textAlign: 'center'}}>
                            FREE TRIAL
                        </p>
                    </button>}
                    <Row justify={"center"} style={{width: '80vw', margin: '4vh 0 0 0'}}>
                        <video playsInline autoPlay muted loop style={{
                            width: '80vw',
                            height: 'auto',
                            objectFit: "cover",
                            borderRadius: '20px'
                        }}>
                            <source src={windowWidth > 720 ? desktopVideo : mobilevideo}
                                    type="video/mp4"/>
                        </video>
                    </Row>
                </div>

                {windowWidth > 900 ? <SelectionLayout/> : <SelectionLayoutMobile/>}


                <div className='pro-curated-bottom'>
                    <Row style={{margin: '0 9vw 1rem 9wv'}}>
                        <h2 className="title-logo" style={{
                            marginTop: '4rem',
                            fontSize: '1.5rem',
                            lineHeight: '36px',
                            width: '100%',
                            textAlign: 'center'
                        }}
                        >
                            <div style={{textAlign: "center"}}>
                                <p>
                                    World-class Collections From <span style={{color: "#936846"}}>A-class</span>
                                </p>
                                <p>
                                    <span style={{color: "#936846"}}>Gallery</span> and <span
                                    style={{color: "#936846"}}>Artists</span>
                                </p>
                            </div>
                        </h2>
                    </Row>
                    <Row style={{margin: '2rem 9vw 6rem 9vw'}}>
                        <div className={'landingPartnershipContainer'} style={{width: "100%"}}>
                            <img onClick={() => {
                                window.open("https://rbsa.org.uk/")
                            }}
                                 alt={'logo'} className={'logo-img landingLogo'} src={RBSALogo}
                                 style={{objectFit: 'contain', width: '350px'}}></img>

                            <img onClick={() => {
                                window.open("https://www.nationalgalleries.org/")
                            }}
                                 alt={'logo'} className={'logo-img landingLogo'} src={NationalGalleryLogo}
                                 style={{objectFit: 'contain', width: '350px'}}></img>

                            <img onClick={() => {
                                window.open("https://www.estorickcollection.com")
                            }}
                                 alt={'logo'} className={'logo-img landingLogo'} src={EstorikLogo}
                                 style={{objectFit: 'contain', width: '350px'}}></img>
                        </div>
                    </Row>

                    <div style={{textAlign: 'center', padding: '20px 0'}}>
                        <Row gutter={[16, 16]} justify="center">
                            {images.map((image, index) => (
                                <Col key={index} xs={8} sm={3} md={3} lg={3} xl={3}
                                     style={{display: 'flex', justifyContent: 'center'}}>
                                    <div style={{
                                        width: '200px',
                                        height: '200px',
                                        borderRadius: '8px',
                                        overflow: 'hidden'
                                    }}>
                                        <img src={image} alt={`Artwork ${index + 1}`}
                                             style={{width: '100%', height: 'auto'}}/>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                        <div style={{marginTop: '15px', marginBottom: '30px', fontSize: '24px', color: '#000'}}>
                            + 1000's more available with Vieunite Pro
                        </div>
                    </div>
                </div>

                <ContactSection
                    bgColor="#FEF5EC"
                    text="Want to try Vieunite Pro for yourself?"
                    buttonText="REQUEST DEMO"
                    link={"/pro/support/request-demo"}
                />
            </Content>
        </Layout>
    )
}

const page2 = [
    [
        "Explore Art, Get Inspired",
        "Vieunite Pro's extensive art library boasts a rich tapestry of styles, from classic fine art to contemporary photography. Our curated playlists empower you to find pieces that resonate with your brand identity and connect with your target audience. Vieunite Pro has the perfect artwork to set your desired tone.",
        section3_img
    ],
    [
        "Curated Experiences",
        "Vieunite Pro offers tailored art collections designed to enhance your business environment. Whether you're seeking to create a professional atmosphere or inspire creativity, our curated experiences align perfectly with your brand’s vision, helping you connect with your audience.",
        section3_1img
    ],
    [
        "Partnering With Artists",
        "Vieunite Pro collaborates with talented artists from around the world to bring you exclusive, high-quality artworks. By partnering with these creatives, we provide unique pieces that elevate your brand, offering a distinctive touch to your business environment.",
        section3_2img
    ],
    [
        "Curated Playlists",
        "Vieunite Pro’s curated playlists complement your business by setting the ideal mood for your space. With our playlists, you can enhance engagement and create a cohesive, dynamic atmosphere that aligns with your brand identity.",
        section3_3img
    ]
]

const page3 = [
    [
        "Effortless Hardware Management at Your Fingertips",
        "Simplify the management of all your hardware and content using our dashboard. Organise your devices into groups, upload artwork to your digital canvas, schedule display times, and customise settings such as brightness, volume, and display durations across single or multiple devices.",
        section4_img
    ],
    [
        "Master Your Content with Seamless Organisation",
        "Effortlessly organise and categorise your artwork for quick updates and easy access. Stay in control of your content with streamlined organisation that keeps everything on-brand and ready to showcase.",
        section4_1img
    ],
    [
        "Unlock Full Control of Your Accounts and Settings",
        "Customise your account and settings to suit your business needs. Adjust preferences, manage permissions, and optimise your displays for a seamless and personalised experience.",
        section4_2img
    ]
]

function SelectionLayoutMobile() {
    const carouselRef2 = React.createRef();
    const [currentTab2, setCurrentTab2] = useState(0);
    const carouselRef3 = React.createRef();
    const [currentTab3, setCurrentTab3] = useState(0);
    return (<div>
        <div style={{height: '5rem'}}></div>
        <h2 className={'home-pro-section-4-mobile-title'}>
            Expertly Curated Art for Tailored Experiences
        </h2>
        <p className={'home-pro-section-4-mobile-body'}>
            Our platform is inspired by a highly skilled curation team with deep knowledge in creating experiences
            through art.
            When you subscribe to our Textura Pro Advanced plan you gain access to personalised playlists, specifically
            tailored to your industry and requirements.
        </p>
        <Carousel
            ref={carouselRef2}
            dots={false}
            dotPosition={"bottom"}
            autoplay={false}
            afterChange={(current) => {
                // console.log("show page" + current)
                setCurrentTab2(current)
            }}
        >
            {page2.map((item, i) =>
                <div>
                    <div style={{padding: '6vw 8vw 0 8vw'}}>
                        <img src={item[2]} alt={'example'}
                             style={{width: '100%', height: "auto", borderRadius: '15px', marginBottom: '20px'}}/>
                        <p
                            className={'home-pro-mobile-title-selected'}
                        >
                            {item[0]}
                        </p>
                        <p
                            className={'home-pro-mobile-body'}
                        >
                            {item[1]}
                        </p>
                    </div>
                </div>
            )}
        </Carousel>

        <div style={{
            height: '81px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <img
                src={left_active}
                alt={'left'}
                onClick={() => {
                    carouselRef2.current.prev()
                }}
            />
            <p className={'home-pro-mobile-carousel-control-text'}> {currentTab2 + 1} / {page2.length} </p>
            <img
                src={right_active}
                alt={'right'}
                onClick={() => {
                    carouselRef2.current.next()
                }}
            />
        </div>

        <div style={{height: '5rem'}}></div>

        <h2 className={'home-pro-section-4-mobile-title'}>
            Take control of what you display
        </h2>
        <p className={'home-pro-section-4-mobile-body'}>
            With Vieunite Pro you have full control over the artwork you showcase. Easily manage and update your
            displays to ensure they align with your brand’s vision and create the perfect environment.
        </p>
        <Carousel
            ref={carouselRef3}
            dots={false}
            dotPosition={"bottom"}
            autoplay={false}
            afterChange={(current) => {
                // console.log("show page" + current)
                setCurrentTab3(current)
            }}
        >
            {page3.map((item, i) =>
                <div>
                    <div style={{padding: '6vw 8vw 0 8vw'}}>
                        <img src={item[2]} alt={'example'}
                             style={{width: '100%', height: "auto", borderRadius: '15px', marginBottom: '20px'}}/>
                        <p
                            className={'home-pro-mobile-title-selected'}
                        >
                            {item[0]}
                        </p>
                        <p
                            className={'home-pro-mobile-body'}
                        >
                            {item[1]}
                        </p>
                    </div>
                </div>
            )}
        </Carousel>

        <div style={{
            height: '81px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <img
                src={left_active}
                alt={'left'}
                onClick={() => {
                    carouselRef3.current.prev()
                }}
            />
            <p className={'home-pro-mobile-carousel-control-text'}> {currentTab3 + 1} / {page3.length} </p>
            <img
                src={right_active}
                alt={'right'}
                onClick={() => {
                    carouselRef3.current.next()
                }}
            />
        </div>
        <div style={{height: '5rem'}}></div>
    </div>)
}

function SelectionLayout() {
    const [currentTab, setCurrentTab] = useState(0);
    const [currentTab1, setCurrentTab1] = useState(0);
    const [fade, setFade] = useState(false);
    const [fade1, setFade1] = useState(false);
    const [src, setSrc] = useState(section3_img);
    const [src1, setSrc1] = useState(section4_img);

    useEffect(() => {
        setFade(true);
        setTimeout(() => {
            if (currentTab === 0) {
                setSrc(section3_img);
            } else if (currentTab === 1) {
                setSrc(section3_1img);
            } else if (currentTab === 2) {
                setSrc(section3_2img);
            } else if (currentTab === 3) {
                setSrc(section3_3img);
            }

            setFade(false);
        }, 100); // match duration with CSS fade-out
    }, [currentTab]);
    useEffect(() => {
        setFade1(true);
        setTimeout(() => {
            if (currentTab1 === 0) {
                setSrc1(section4_img);
            } else if (currentTab1 === 1) {
                setSrc1(section4_1img);
            } else if (currentTab1 === 2) {
                setSrc1(section4_2img);
            }

            setFade1(false);
        }, 100); // match duration with CSS fade-out
    }, [currentTab1]);

    return (<div>
        <div style={{margin: '8rem 8vw'}}>
            <div className="home-pro-title-section">
                <div className={'home-pro-section-5-text-container'}>
                    <img src={section_title} className='proCurated-pic-title'/>
                    <h2 className={'title1'}>Expertly Curated Art for Tailored Experiences</h2>
                    <p className={'body'}>Our platform is inspired by a highly skilled curation team with deep
                        knowledge in creating experiences through art. When you subscribe to our Textura Pro
                        Advanced plan you gain access to personalised playlists, specifically tailored to your
                        industry and requirements.</p>
                    <div style={{height: '2vw'}}></div>
                    <ul>
                        <li
                            className={`${currentTab === 0 ? 'title2' : 'body2'}`}
                            onMouseEnter={_ => setCurrentTab(0)}
                        >
                            Explore Art, Get Inspired
                        </li>
                        <div className={`collapsible ${currentTab === 0 ? 'open' : 'collapsed'}`}>
                            <p className={'body'}>Vieunite Pro's extensive art library boasts a rich tapestry of
                                styles, from classic fine art to contemporary photography. Our curated playlists
                                empower you to find pieces that resonate with your brand identity and connect
                                with your target audience. Vieunite Pro has the perfect artwork to set your
                                desired tone.</p>
                            <div className={'line'}></div>
                        </div>
                        <li
                            className={`${currentTab === 1 ? 'title2' : 'body2'}`}
                            onMouseEnter={_ => setCurrentTab(1)}
                        >
                            Curated Experiences
                        </li>
                        <div className={`collapsible ${currentTab === 1 ? 'open' : 'collapsed'}`}>
                            <p className={'body'}>Vieunite Pro offers tailored art collections designed to enhance your
                                business environment. Whether you're seeking to create a professional atmosphere or
                                inspire creativity, our curated experiences align perfectly with your brand’s vision,
                                helping you connect with your audience.</p>
                            <div className={'line'}></div>
                        </div>

                        <li
                            className={`${currentTab === 2 ? 'title2' : 'body2'}`}
                            onMouseEnter={_ => setCurrentTab(2)}
                        >
                            Partnering With Artists
                        </li>
                        <div className={`collapsible ${currentTab === 2 ? 'open' : 'collapsed'}`}>
                            <p className={'body'}>Vieunite Pro collaborates with talented artists from around the world
                                to bring you exclusive, high-quality artworks. By partnering with these creatives, we
                                provide unique pieces that elevate your brand, offering a distinctive touch to your
                                business environment.</p>
                            <div className={'line'}></div>
                        </div>
                        <li
                            className={`${currentTab === 3 ? 'title2' : 'body2'}`}
                            onMouseEnter={_ => setCurrentTab(3)}
                        >
                            Curated Playlists
                        </li>
                        <div className={`collapsible ${currentTab === 3 ? 'open' : 'collapsed'}`}>
                            <p className={'body'}>Vieunite Pro’s curated playlists complement your business by setting
                                the ideal mood for your space. With our playlists, you can enhance engagement and create
                                a cohesive, dynamic atmosphere that aligns with your brand identity.</p>
                            <div className={'line'}></div>
                        </div>
                    </ul>
                </div>
                <img className={`home-pro-section-image ${fade ? 'home-pro-fade-out' : 'home-pro-fade-in'}`}
                     src={src}
                     alt={'revolutionary art and communication platform'}/>
            </div>
        </div>

        <div style={{margin: '8rem 8vw'}}>
            <div className="home-pro-title-section">
                <img className={`home-pro-section-image ${fade1 ? 'home-pro-fade-out' : 'home-pro-fade-in'}`}
                     src={src1}
                     alt={'revolutionary art and communication platform'}/>

                <div className={'home-pro-section-6-text-container'}>
                    <h2 className={'title1'}>Take control of what you display</h2>
                    <p className={'body'}>With Vieunite Pro you have full control over the artwork you showcase. Easily
                        manage and update your displays to ensure they align with your brand’s vision and create the
                        perfect environment.</p>
                    <div style={{height: '2vw'}}></div>
                    <ul>
                        <li
                            className={`${currentTab1 === 0 ? 'title2' : 'body2'}`}
                            onMouseEnter={_ => setCurrentTab1(0)}
                        >
                            Effortless Hardware Management at Your Fingertips
                        </li>
                        <div className={`collapsible ${currentTab1 === 0 ? 'open' : 'collapsed'}`}>
                            <p className={'body'}>Simplify the management of all your hardware and content using
                                our dashboard. Organise your devices into groups, upload artwork to your digital
                                canvas, schedule display times, and customise settings such as brightness,
                                volume, and display durations across single or multiple devices.</p>
                            <div className={'line'}></div>
                        </div>
                        <li
                            className={`${currentTab1 === 1 ? 'title2' : 'body2'}`}
                            onMouseEnter={_ => setCurrentTab1(1)}
                        >
                            Master Your Content with Seamless Organisation
                        </li>
                        <div className={`collapsible ${currentTab1 === 1 ? 'open' : 'collapsed'}`}>
                            <p className={'body'}>Effortlessly organise and categorise your artwork for quick updates
                                and easy access. Stay in control of your content with streamlined organisation that
                                keeps everything on-brand and ready to showcase.</p>
                            <div className={'line'}></div>
                        </div>

                        <li
                            className={`${currentTab1 === 2 ? 'title2' : 'body2'}`}
                            onMouseEnter={_ => setCurrentTab1(2)}
                        >
                            Unlock Full Control of Your Accounts and Settings
                        </li>
                        <div className={`collapsible ${currentTab1 === 2 ? 'open' : 'collapsed'}`}>
                            <p className={'body'}>Customise your account and settings to suit your business needs.
                                Adjust preferences, manage permissions, and optimise your displays for a seamless and
                                personalised experience.</p>
                            <div className={'line'}></div>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>)
}

export default ProCurated;
