import React, {useEffect, useState} from "react";
import DiscoveryArtworkCardView from "./DiscoveryArtworkCardView"
import ProPortalHeader from "../componets/ProPortalHeader";
import ProfileRow from "../componets/ProfileRow";
import Navigator from "../componets/Navigator";
import {Content} from "antd/es/layout/layout";
import {Button, Form, Layout, Input, Dropdown, Menu, Modal} from "antd";
import {ChromePicker} from 'react-color';
import SearchButton from './images/search-normal.svg'
import {useLocation, useNavigate} from "react-router-dom";
import {get_api, post_api} from "../serverTemp";
import backButton from '.././MyAccount/ExtraServices/UpgradeSubscription/img/Back button.svg';

const {SubMenu} = Menu;

var AlbumCurrentPage = 0;
var totalPage = 0;
const req = {
    method: 'GET',
    headers: {"X-Access-Token": "123456"},
};
const fetchArtworks = () => {
    return fetch(`http://192.168.1.140:8001/artworks?page=${AlbumCurrentPage}`, req)
        .then(res => {
            if (res.ok) {
                console.log("request successfully")
                return res.json()
            }
            throw new Error(`Error HTTP response`);
        })
        .then(data => {
            if (data.code === 200) {
                AlbumCurrentPage = data.result.currentPage
                totalPage = data.result.totalPage
                // console.log("item count")
                // console.log(data.result.data) // 2
                return data.result.data
            } else {
                throw new Error(`Error HTTP format`);
            }
        })
        .catch(error => console.log('error', error));
}

const DiscoveryArtworkSeeAll = (props) => {
    console.log(props)
    var searchContent = ""
    const [artworks, setArtworks] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // fetchArtworks().then((data) => {
        //     setArtworks(data)
        // })
        // console.log("Album");
        // console.log(props.item);
        get_api(`/playlist/artworks?playlist_id=${props.item.id}`).then(res => {
            // console.log("get album details");
            // console.log(res.data);
            setArtworks(res.data);
        });
    }, [])

    const previewOnCanvas = (param) => {
        // console.log("preview");
        // console.log(param);
        navigate('/proportal/dashboard', {state: param});
    }

    const onBack = () => {
        navigate('/proportal/discover-art')
    }

    return (
        <div style={{backgroundColor: '#f4f4f4'}}>
            <div style={{display: 'flex', alignItems: 'center', marginLeft: '20px', marginTop: "10px"}}>
                <img src={backButton} alt="BackButton" className='BackButtonIcon'
                     style={{marginRight: '10px', cursor: "pointer"}} onClick={onBack}/>
                <p className="collection-category-title">{props.item.name}</p>
            </div>

            {localStorage.getItem('subscription') !== "Standard" && (
                <div style={{display: "flex", justifyContent: "flex-start", marginLeft: "88px", marginBottom: "15px"}}>
                    <Button className="discover-category-button"
                            style={{margin: "10px", background: "#0E477A", color: "#FFF"}}
                            onClick={() => previewOnCanvas({
                                album_id: props.item.id,
                                isPreview: false,
                                is_pls: true,
                                from_: props.item.from_
                            })}>Send to Textura Pro</Button>
                    <Button className="discover-category-button"
                            style={{margin: "10px", background: "#0E477A", color: "#FFF"}}
                            // onClick={() => previewOnCanvas({
                            //     album_id: props.item.id,
                            //     isPreview: false,
                            //     is_pls: true,
                            //     from_: props.item.from_
                            // })}
                            onClick={() => {
                                post_api('/album/add', {
                                    name: props.item.name,
                                }).then((res) => {
                                    console.log(res)
                                    post_api('/album/add/artwork',
                                        {
                                            id: res.id,
                                            artwork_ids: artworks.map(d => d.id),
                                            from_: 2
                                        })
                                })
                            }}
                    >
                        Save to my Album
                    </Button>
                </div>
            )}

            <div className="collections-container" style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "center",
                padding: "0 35px 0 95px"
            }}>
                {artworks.map((item, index) => (
                    <div key={index}>
                        <DiscoveryArtworkCardView item={item} style={{marginRight: 95, marginBottom: 40}}/>
                    </div>
                ))}
            </div>

            <div style={{display: 'flex', flexDirection: 'column', height: '30vh'}}>
                <div style={{flex: '1'}}>
                </div>
            </div>
        </div>
    )
}

const AlbumDetailsWrapper = () => {
    const {state} = useLocation()
    const {album} = state
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Layout>
                    <Navigator/>
                    <Layout>
                        <DiscoveryArtworkSeeAll item={album}/>
                    </Layout>
                </Layout>
            </Content>
        </Layout>
    )
}

export default AlbumDetailsWrapper;