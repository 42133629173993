import React, {useEffect, useState} from 'react';
import {Layout, Row, Col, Modal, Button, List, Avatar, Table, Space, Tag, Image, Divider} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import {useLocation, useNavigate} from "react-router-dom";
import {Primary_Font, TitleFont} from "../../../../settings";
import {BackButton} from "../../ArtList/components/BackButton";
import WithdrawModal from "../WithdrawModal";
import useWindowResize from "../../../Hooks/useWindowResize";
import {getBalance} from "./service";
import {getTransHist} from "../TransactionHistory/service";

function BalanceBoard() {
    const {windowWidth} = useWindowResize()
    const navigate = useNavigate();
    const {state} = useLocation();
    const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false)
    const [finance, setFinance] = useState({})
    const [transHist, setTransHist] = useState([])
    useEffect(() => {
        getBalance().then((res) => {
            setFinance({...res})
        })
    }, []);
    useEffect(() => {
        getTransHist(6).then((res) => {
            setTransHist(res)
        })
    }, []);
    const columns = [
        {
            title: 'Img',
            dataIndex: 'pic_url',
            key: 'img',
            render: (src) => <div style={{
                height: "6rem",
                width: "6rem",
                maxHeight: "100%",
                maxWidth: "100%",
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <img src={src}
                     alt={'art'}
                     style={{
                         width: "6rem",
                         height: "6rem",
                         objectFit: 'contain',
                     }}
                />
            </div>,
        },
        window.innerWidth > 600 ? {
            title: 'Date',
            key: 'date',
            render: (txt, record) => <>
                <p><span style={{fontWeight: 'bold'}}>{record.nickname}</span> purchased
                    <span style={{fontWeight: 'bold'}}> '{record.name}'</span>
                </p>
                <p>{record.orderTime}</p>
            </>
        } : {},
        {
            title: 'Paid',
            key: 'paid',
            dataIndex: 'totalPay',
            render: (amt) => <p style={{fontWeight: "bold", width: '140%'}}>+ £{amt}</p>
        },
    ];
    return (
        <Layout>
            <Content className={'grey-bk'}>
                <BackButton text={"< Back to Dashboard"} link={"/artistportal/home"}/>
                <Row justify={"center"}
                     style={{
                         padding: '0.5rem 0 6rem 0',
                         gap: '1rem'
                     }}>
                    <Col lg={6} xs={22}>
                        <Row justify={"center"} style={{
                            backgroundColor: 'white',
                            padding: '3rem',
                            borderRadius: '20px 20px 0 0',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}>
                            <h2 className="title-app mobile-left"
                                style={{textAlign: "left", ...TitleFont, ...(windowWidth > 600 ? {} : {fontSize: '2rem'})}}>
                                <span style={{fontWeight: '500'}}>Vieunite</span> Balance.
                            </h2>
                            <p style={{
                                marginTop: '-3%'
                            }}>Available</p>
                        </Row>
                        <Row className={'bl-bottom-row'}
                             style={{
                                 backgroundColor: 'white',
                                 padding: '0 3rem 3rem 3rem',
                                 borderRadius: '0 0 20px 20px',
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 textAlign: "center",
                                 flexDirection: "column",
                                 marginTop: '-5%',
                             }}>
                            <p style={{
                                fontSize: '3rem',
                                color: 'black',
                                textDecoration: "underline",
                                textDecorationThickness: '0.18rem',
                            }}>
                                {'£' + parseFloat(finance['balance']).toString() ?? 0.0}
                            </p>
                            <Button
                                type={"primary"}
                                onClick={() => {
                                    setIsWithdrawModalOpen(true)
                                }}
                            >
                                WITHDRAW FUNDS
                            </Button>
                            <Button onClick={() => {
                                navigate("/artistportal/transactionhistory")
                            }}>
                                TRANSACTION HISTORY
                            </Button>
                        </Row>
                        <Row justify={"center"} style={{
                            backgroundColor: 'white',
                            padding: '3rem',
                            borderRadius: '20px',
                            marginTop: '1rem'
                        }}>
                            <h2 className="title-app mobile-left"
                                style={{
                                    textAlign: "left",
                                    ...TitleFont,
                                    ...(windowWidth > 600 ? {} : {fontSize: '2rem'})
                                }}>
                                <span style={{fontWeight: '500'}}>Any</span> questions?
                                <p style={{
                                    fontSize: '1rem',
                                    fontWeight: '400',
                                }}>
                                    If you have any questions, invoices or receipts related to your Vieunite balance
                                    please email accounts@vieunite.com
                                </p>
                            </h2>
                        </Row>

                    </Col>
                    <Col lg={14} xs={22} style={{
                        backgroundColor: 'white',
                        padding: '3rem',
                        borderRadius: '20px'
                    }}>
                        <h2 className="title-app mobile-left"
                            style={{textAlign: "left", ...TitleFont, ...(windowWidth > 600 ? {} : {fontSize: '1.5rem'})}}>
                            <span style={{fontWeight: '500'}}>Recent</span> Transactions.
                        </h2>
                        <Divider></Divider>
                        <Table className={'art-table'}
                               columns={columns}
                               dataSource={transHist}
                               showHeader={false}
                               bordered={false}
                               pagination={false}
                        />
                    </Col>

                </Row>
                <WithdrawModal isModalOpen={isWithdrawModalOpen} setIsModalOpen={setIsWithdrawModalOpen}/>
            </Content>
        </Layout>
    )
}

export default BalanceBoard;
