import React from 'react';
import {Form, Input, Button} from 'antd';
import './style.scss'
import {Link} from "react-router-dom";

const ProNewPassword = () => {
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };

    return (
        <div style={{marginTop: '5vw'}}>
            <div className="Title">
                Change password?
            </div>
            <p className="ProPassword-text">Please input your new password</p>
            <Form
                initialValues={{remember: true}}
                onFinish={onFinish}
                colon={false}
                layout="horizontal"
            >
                <Form.Item
                    label={<span className="ProNewPassword-email-label">New Password</span>}
                    name="password"
                    rules={[{required: true, message: 'Please input your new password!'}]} // 更新了rules以包含required属性，并更改了消息文本
                    labelCol={{span: 8}}
                    wrapperCol={{span: 5}}
                >
                    <Input.Password  className={'ProNewPassword-input-Email-form'}/>
                </Form.Item>
            </Form>

            <div className="ProNewPassword-center-button">
                <Button type="primary" htmlType="submit" className="ProNewPassword-custom-Reset-button">
                    Confirm password
                </Button>
            </div>
        </div>
    );
};

export default ProNewPassword;
