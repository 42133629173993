import React, {useEffect, useState} from 'react';
import {Layout, Row, Col, Modal, Button, List, Avatar, Table, Space, Tag, Image, Upload} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.css'
import {useLocation, useNavigate} from "react-router-dom";
import {Primary_Font, TitleFont} from "../../../settings";
import {DeleteOutlined, EditOutlined, PlusOutlined,} from "@ant-design/icons";
import {Editor} from "./components/Editor/Editor";
import {ArtUploader} from "../ArtistPortalHome/components/ArtUploader";
import {applyArtist, deleteArtwork, getAllArts, getPersonalArts} from "./services";
import useWindowResize from "../../Hooks/useWindowResize";
import {BackButton} from "./components/BackButton";
import WallUploader from "../ArtistPortalHome/components/ArtUploader/WallUploader";
import {artlistText} from "./text";


function ArtList(props) {
    // For artist application only
    const {state} = useLocation();
    const {isApplication, applyInfo} = state
    const [disable, setDisable] = useState(true)
    const [uploadedNum, setUploadedNum] = useState(0)
    const [fileList, setFileList] = useState([]);

    const [arts, setArts] = useState([])
    const [isEditorOpen, setIsEditorOpen] = useState(false)
    const [artInfo, setArtInfo] = useState('')
    const {windowWidth} = useWindowResize();
    const navigate = useNavigate()

    useEffect(() => {
        if (isApplication === false) {
            getAllArts().then((result) => {
                    console.log(result);
                    setArts(result)
                }
            )
        }
    }, [])

    const findArtByID = async (idx) => {
        const art = arts.find(obj => obj.id === idx)
        await setArtInfo(art)
    }
    const showEditor = async (idx) => {
        await findArtByID(idx)
        setIsEditorOpen(true);
    };
    const columns = [
        {
            title: '   ',
            dataIndex: 'src',
            key: 'img',
            render: (src) => <div style={{
                height: "6rem",
                width: "6rem",
                maxHeight: "100%",
                maxWidth: "100%",
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <img src={src}
                     alt={'art'}
                     style={{
                         width: "6rem",
                         height: "6rem",
                         objectFit: 'contain',
                     }}
                />
            </div>,
        },
        windowWidth > 600 ? {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        } : {},
        windowWidth > 600 ? {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        } : {},
        windowWidth > 600 ? {
            title: 'Price',
            key: 'price',
            dataIndex: 'price',
        } : {},
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <Space size="large">
                    {isApplication === false ? <EditOutlined onClick={() => {
                        showEditor(record.id);
                    }}/> : <></>}
                    <DeleteOutlined onClick={async () => {
                        await deleteArtwork(record.id)
                        window.location.reload()
                    }}/>
                </Space>
            ),
        },
    ];

    return (
        <Layout>
            <Content className={'grey-bk'} style={{marginTop: '-1.2%'}}>
                {isApplication ? <BackButton text={"< Back to Artist Details"} link={"/artistportal/apply"}/> :
                    <BackButton text={"< Back to Dashboard"} link={"/artistportal/home"}/>}
                <Row justify={"center"} style={{padding: '0.5rem 0 6rem 0'}}>
                    <Col lg={20} xs={24} style={{
                        backgroundColor: 'white',
                        padding: '2rem',
                        borderRadius: windowWidth > 600 ? '20px' : '0',
                    }}>
                        <Row style={{display: "flex"}}>
                            <Col lg={22} xs={24}>
                                <h2 className="title-app mobile-left"
                                    style={{
                                        textAlign: "left",
                                        ...TitleFont,
                                        ...(windowWidth < 600 ? {fontSize: '2rem'} : {}),
                                    }}>
                                    <span
                                        style={{fontWeight: '500'}}>{isApplication ? 'Submit' : 'Manage'}</span> Artwork.
                                </h2>
                            </Col>
                            {isApplication ? <Col lg={2}>
                                <p style={{
                                    textAlign: "left",
                                    ...TitleFont,
                                    ...(windowWidth < 600 ? {fontSize: '2rem'} : {}),
                                    fontWeight: '300',
                                }}>{uploadedNum}/10</p>
                            </Col> : <></>}
                        </Row>
                        {isApplication ? <p style={{
                                ...Primary_Font,
                                marginBottom: '2rem',
                                marginTop: '-2.8%',
                                width: windowWidth > 600 ? '50%' : '100%'
                            }}>
                                {artlistText.submit}<a
                                style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    color: "black"
                                }} href={"mailto: support@vieunite.com"}>support@vieunite.com</a> with a link to the
                                artwork.
                            </p> :
                            <></>
                        }
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: isApplication && windowWidth > 600 ? 'flex-start' : 'center'
                        }}>
                            {
                                isApplication ?
                                    <WallUploader setDisable={setDisable}
                                                  uploadedNum={uploadedNum}
                                                  setUploadedNum={setUploadedNum}
                                                  fileList={fileList}
                                                  setFileList={setFileList}
                                    /> :
                                    <ArtUploader/>
                            }
                        </div>
                        {isApplication ? <></> :
                            <Table className={'art-table'}
                                   columns={columns}
                                   dataSource={arts}
                                   showHeader={false}
                                   bordered={false}
                            />
                        }

                    </Col>
                </Row>
                {
                    isApplication === true ?
                        <Row justify={"center"}
                             style={windowWidth > 600 ?
                                 {paddingBottom: '4rem'} : {
                                     paddingBottom: '1rem',
                                     marginTop: '-4rem'
                                 }}>
                            <Col lg={6} style={{display: "flex", justifyContent: "center"}}>
                                <Button
                                    className="login-form-button"
                                    style={{width: '100%', fontWeight: "bold"}}
                                    disabled={disable}
                                    onClick={() => {
                                        // const uris = localStorage.getItem('uris')
                                        const uris = fileList.map((file) => file.url).join(', ');
                                        const submitInfo = {
                                            ...applyInfo,
                                            artworkUrl: uris,
                                        }
                                        applyArtist(submitInfo)
                                            .then(result => {
                                                console.log(result)
                                                if (result.code !== 200) {
                                                    alert(result.message)
                                                    navigate('/home')
                                                } else {
                                                    navigate('/artistportal/finish')
                                                }
                                            })

                                    }}>
                                    SUBMIT >
                                </Button>
                            </Col>
                        </Row> :
                        <></>
                }
                <Editor
                    isOpen={isEditorOpen}
                    isNewArt={false}
                    artInfo={artInfo}
                />
            </Content>
        </Layout>
    )
}

export default ArtList;
