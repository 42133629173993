import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const Page5 = () => {
    // State to track the selected radio button
    const [relevanceOption, setRelevanceOption] = useState(null);
    const [optionsString, setOptionsString] = useState('');
    const [isFormComplete, setIsFormComplete] = useState(false); // Track form completion
    const navigate = useNavigate();

    const bio = ['Marine', 'Natural Landscape', 'Animals', 'Plants & Trees', 'Presence of Water', 'Seascape', 'Seasonal & Natural Phenomenon', 'Still Life'];
    useEffect(() => {
        const selectedCriteria = JSON.parse(localStorage.getItem('selectedCriteria'));
        if (selectedCriteria) {
            const selectedBio = selectedCriteria
                .map((bit, index) => bit === 1 ? bio[index] : null)
                .filter(item => item !== null) // Remove null values
                .join(', '); // Convert to a comma-separated string
            setOptionsString(selectedBio);
        }
    }, []);



    // Handle submit button click
    const handleSubmit = () => {
        if (relevanceOption) {
            // Store the selected relevance option in localStorage
            localStorage.setItem('relevance1', JSON.stringify(relevanceOption));
            navigate('/Page6'); // Navigate to Page6
        } else {
            message.error('Please select a relevance option.'); // Show error message if nothing is selected
        }
    };


    const handleRelevanceChange = (event) => {
        setRelevanceOption(event.target.value);
        setIsFormComplete(true); // Set form as complete when a selection is made
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '5vw'
        }}>
            {/* Display selected Biophilic features */}
            <div style={{ fontSize: '16px', textAlign: 'center', marginBottom: '1vw', fontWeight: "bolder" }}>
                <p>You have selected the Biophilic Features: {optionsString}.</p>
            </div>

            {/* Section 1: Relevance */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'center',
                width: '30vw',
                marginBottom: '1vw',
            }}>
                <p style={{fontWeight: "bolder"}}>How relevant were our recommendations? <span style={{ color: 'red' }}>*</span></p>
                {['Completely Irrelevant', 'Irrelevant', 'Somewhat Relevant', 'Relevant', 'Extremely Relevant'].map(option => (
                    <div key={option} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5vw', width: '50%' }}>
                        <label htmlFor={option}>{option}</label>
                        <input
                            type="radio"
                            id={option}
                            name="relevance"
                            value={option}
                            onChange={handleRelevanceChange}
                        />
                    </div>
                ))}
            </div>

            {/* Submit button */}
            <div style={{ marginTop: '0.5vw', textAlign: 'center' }}>
                <Button
                    type="primary"
                    onClick={handleSubmit}
                    disabled={!isFormComplete} // Disable the button if no option is selected
                >
                    Next
                </Button>
            </div>
        </div>
    );
};

export default Page5;