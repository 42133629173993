import React, { useEffect, useState } from "react";
import { Button, Layout, Form, Input, } from "antd";
import ProPortalHeader from "../componets/ProPortalHeader";
import ProfileRow from "../componets/ProfileRow";
import Navigator from "../componets/Navigator";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import gallery1 from './images/birmingham.png';
import gallery2 from './images/chicago.png';
import gallery3 from './images/rbsa.png';
import gallery4 from './images/scotland.png';
import gallery5 from './images/washington.png';
import SearchButton from './images/search-normal.svg';
import { get_api } from "../serverTemp";
import backButton from '.././MyAccount/ExtraServices/UpgradeSubscription/img/Back button.svg';

const GalleryCard = (props) => {

    // {
    //     "id": "1",
    //     "name": "Glorious Gallery",
    //     "description": "",
    //     "url": "https://gospelproject.lifeway.com/wp-content/uploads/tgp2018/2021/11/4_3_God_Delivered_His_People.jpg",
    //     "official_website": "https://gospelproject.lifeway.com/wp-content/uploads/tgp2018/2021/11/4_3_God_Delivered_His_People.jpg"
    //   }

    const navigate = useNavigate();
    const onGallery = (values) => {
        navigate('/proportal/discover-art/gallery-details', { state: { gallery: props.item } })
    }

    const onWebsite = (values) => {
        window.open(props.item.official_website, '_blank');
    }

    return (
        <div className="gallery-card-container">
            <img src={props.item.building_url} alt="gallery" />
            <div className="product-info">
                <p className="gallery-card-title"> {props.item.name} </p>
                <p className="gallery-card-body"> {props.item.description} </p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button className="discover-category-button" style={{ margin: "10px", background: "#0E477A", color: "#FFF"}} onClick={onGallery}>View Collections</Button>
                    <Button className="discover-category-button" style={{ margin: "10px", background: "#0E477A", color: "#FFF"}} onClick={onWebsite}>View Website</Button>
                </div>
            </div>
        </div>
    )
}

const DiscoveryGalleriesSeeAll = () => {
    const navigate = useNavigate();
    const onBack = () => {
        navigate('/proportal/discover-art')
    }

    const [filter, setFilter] = useState("");
    const [galleries, setGalleries] = useState([]);
    useEffect(() => {
        get_api('/gallery/all').then(res => {
            setGalleries(res.data);
        });
    }, [])

    const handleSubmit = () => {
        console.log("search ")
        // if (filter.trim() === '') {
        //     get_api('/gallery/all').then(res => {
        //         setGalleries(res.data);
        //     });
        // } 
        // Add logic to handle password change submission
        // You should add validation to check if new password and confirm password are the same, etc. //To Do
    };

    // const galleryList = [{
    //     "id": "1",
    //     "name": "Birmingham Art Gallery",
    //     "url": gallery1,
    // }, {
    //     "id": "2",
    //     "name": "Art Institute Chicago",
    //     "url": gallery2,
    // }, {
    //     "id": "3",
    //     "name": "RBSA Gallery",
    //     "url": gallery3,
    // }, {
    //     "id": "4",
    //     "name": "National Gallery of Scotland",
    //     "url": gallery4
    // }, {
    //     "id": "5",
    //     "name": "National Gallery of Washington",
    //     "url": gallery5
    // }, {
    //     "id": "1",
    //     "name": "Birmingham Art Gallery",
    //     "url": gallery1,
    // }, {
    //     "id": "2",
    //     "name": "Art Institute Chicago",
    //     "url": gallery2,
    // }, {
    //     "id": "3",
    //     "name": "RBSA Gallery",
    //     "url": gallery3,
    // }, {
    //     "id": "4",
    //     "name": "National Gallery of Scotland",
    //     "url": gallery4
    // }, {
    //     "id": "5",
    //     "name": "National Gallery of Washington",
    //     "url": gallery5
    // }]

    return (
        <div style={{ backgroundColor: '#f4f4f4', minHeight: "100vh" }}>
            {/* <p className="collection-category-title">Galleries</p> */}
            
            <div style={{display: 'flex', alignItems: 'center', marginLeft: '28px', marginTop: "25px"}}>
                <img src={backButton} alt="BackButton" className='BackButtonIcon' style={{ marginRight: '0px', cursor: "pointer" }} onClick={onBack}/>
                <p className="all-artworks-title">View all Artworks</p>
            </div>

            <Form
                onFinish={handleSubmit}
                style={{ display: "flex", alignItems: "center", marginLeft: "84px" }}
            >
                <Form.Item className="search-input-frame">
                    <Input placeholder="Search by name" style={{ borderRadius: "6px" }} onChange={(e) => (
                        // searchContent = e.target.value
                        setFilter(e.target.value)
                    )} >
                    </Input>
                </Form.Item>
                <Form.Item className="search-button-frame">
                    <Button className="discover-search-button" style={{ borderRadius: "6px" }} type="primary" htmlType="submit">
                        <img src={SearchButton} alt="search" style={{ width: "20px", height: "20px" }} />
                    </Button>
                </Form.Item>
            </Form>
            {galleries.filter(item => item.name.toLowerCase().includes(filter.toLowerCase())).map((item, index) => (
                <div style={{marginLeft: '43px'}} key={index}>
                    <GalleryCard item={item} />
                </div>
            ))}
        </div>

    )
}

const DiscoveryGalleriesSeeAllWrapper = (props) => {
    return (
        <Layout>
            <Content>
                <ProPortalHeader />
                <ProfileRow />
                <Layout>
                    <Navigator />
                    <Layout>
                        <DiscoveryGalleriesSeeAll />
                    </Layout>
                </Layout>
            </Content>
        </Layout>
    )
}

export default DiscoveryGalleriesSeeAllWrapper;