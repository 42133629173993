import React, {useState} from 'react';
import {Button, Col, Modal, Row, message} from 'antd';
import {Primary_Font} from "../../../../../settings";
import {PortalHomeTxt} from "../../text";
import {useNavigate} from "react-router-dom";
import useWindowResize from "../../../../Hooks/useWindowResize";

const ProfileCard = (props) => {
    const navigate = useNavigate();
    const {data, logout} = props;
    const {windowWidth} = useWindowResize()
    const {windowHeight} = useWindowResize()
    return (

        <div className="small-card" style={{ fontSize: windowWidth > 600 ? windowWidth / 100 * 0.65: windowWidth / 100*3.5}}>
            <p className={'card-title'} style={{fontSize: windowWidth > 600 ? windowWidth / 100 * 1.6 : windowWidth / 100 * 8., marginBottom: windowHeight / 100 * 3.2}}>
                Artist <span style={{fontWeight: '600'}}>Profile</span>.
            </p>
            <Row justify={"center"} style={{gap: '1rem'}}>
                <Col span={10} style={{
                    display: 'flex',
                    margin: 'auto',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                }}>
                    <div style={{
                        height: windowWidth > 600 ? "50%" : "20vw",
                        width: windowWidth > 600 ? "7vw" : "20vw",
                        margin: 'auto',
                        borderRadius: '50%',
                    }}>
                        console.log(data.avatar)
                        <img src={data.avatar}
                             alt={'art'}
                             style={{
                                 width: windowWidth > 600 ? "7vw" : "20vw",
                                 height: windowWidth > 600 ? "7vw" : "20vw",
                                 objectFit: 'cover',
                                 borderRadius: '50%',
                             }}
                        />
                    </div>
                </Col>
                <Col span={10} style={{display: "flex", flexDirection: "column", marginTop: '0.5rem'}}>
                    <p className={'m-none'} style={{
                        ...Primary_Font,
                        color: "black",
                        lineHeight: '0.4rem',
                        fontWeight: '600'
                    }}>
                        Hi {data.nickname.length > 12 ? data.nickname.slice(0, 12) + '...' : data.nickname}
                    </p>
                    <p className={'m-none'} style={{color: "black", fontSize: '0.6vw'}}>
                        {data.info && data.info.length > 65 ? data.info.slice(0, 65) + '...' : data.info}
                    </p>
                    <p style={{
                        color: "black",
                        ...Primary_Font,
                        // fontSize: '1rem',
                        fontWeight: '600',
                        cursor: 'pointer',
                    }}
                       onClick={() =>
                           navigate("/artistportal/profile", {
                               state: {
                                   data: data,
                               }
                           })
                       }
                    >
                        Edit Profile >
                    </p>
                    <p style={{
                        color: "black",
                        ...Primary_Font,
                        // fontSize: '1rem',
                        fontWeight: '600',
                        cursor: 'pointer',
                    }}
                       onClick={logout}
                    >
                        Log Out >
                    </p>
                </Col>
            </Row>
        </div>
    )
}

export default ProfileCard;