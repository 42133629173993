import React from 'react';
import {Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import useWindowResize from "../../../../apps/Hooks/useWindowResize";

const ImgTextCombo = (props) => {
    const {windowWidth} = useWindowResize()
    const {img, goldText, text, leftImg} = props;
    return (
        windowWidth > 920 ? <Row justify={"center"}
                                  style={{
                                      display: "flex",
                                      flexDirection: 'row',
                                      margin: "8vh auto",
                                      width: '80.5vw',
                                      justifyContent: "center",
                                      alignItems: "center",
                                      // borderRadius: '20px',
                                      // border: '3px solid #936846'
                                  }}>
            {leftImg && <Col style={{
                width: "40vw",
                borderRadius: '20px 0 0 20px'
            }}>
                <img src={img}
                     className={"imgTextComboImg-img"}
                     style={{
                         width: '40vw', borderRadius: '20px 0 0 20px', height: '57vh',

                     }}/>
            </Col>}
            <Col style={{
                width: "40vw",
                height: '57vh',
                borderTop: '3px solid #936846',
                borderBottom: '3px solid #936846',
                borderLeft: leftImg ? "none" : '3px solid #936846',
                borderRight: leftImg ? '3px solid #936846' : "none",
                borderRadius: leftImg ? '0 20px 20px 0' : '20px 0 0 20px',
                display: "flex",
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: "flex-start",
            }}>
                <p className={"imgTextComboText-gold"}>{goldText}</p>
                <p className={"imgTextComboText"}>{text}</p>
            </Col>
            {!leftImg && <Col style={{width: "40vw"}}>
                <img src={img}
                     className={"imgTextComboImg-img"}
                     style={{width: '40vw', borderRadius: '0 20px 20px 0', height: '57vh',}}/>
            </Col>}
        </Row> :
            <Col style={{
                width: "80vw",
                height: 'auto',
                border: '3px solid #936846',
                borderRadius: '20px',
                display: "flex",
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: "center",
                margin:'4vh auto'
            }}>
                <img src={img}
                     className={"imgTextComboImg-img"}
                     style={{
                         width: "79vw", borderRadius: '20px 20px 0 0', height: '20rem',
                     }}/>
                <p className={"imgTextComboText-gold"} style={{margin:'2vh auto'}}>{goldText}</p>
                <p className={"imgTextComboText"} style={{margin:'2vh 0'}}>{text}</p>
            </Col>
    )
}

export default ImgTextCombo;
