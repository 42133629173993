import React, {useEffect, useState} from 'react';
import {
    Button, Card,
    Col, Dropdown,
    Form,
    Image,
    Input,
    Layout, Menu,
    message,
    Modal,
    Row,
    Segmented,
    Spin,
    Table,
    Tooltip,
    Upload,
    Tag
} from "antd";
import {CirclePicker} from 'react-color';
import {Content} from "antd/es/layout/layout";
import './style.scss'
import ProPortalHeader from "../componets/ProPortalHeader";
import Navigator from "../componets/Navigator";
import ProfileRow from "../componets/ProfileRow";
import {UploadIcon, BSUploadIcon, BSIcon} from "./icons";
import curated_img from './imgs/Curated Collection Tile.webp'
import {ReactComponent as LandscapeIcon} from './imgs/Landscape icon.svg'
import {ReactComponent as PortraitIcon} from './imgs/Portrait icon.svg'
import AlbumCoverLayout from "../ProDiscovery/AlbumCoverLayout";
import CoverLayout from "../ProDiscovery/AlbumCoverLayout";
import {get_album_data, get_all_arts} from "./services";
import {ReactComponent as DelIcon} from './imgs/Delete.svg'
import {ReactComponent as PubIcon} from './imgs/Publish.svg'
import {ReactComponent as RenameIcon} from './imgs/Rename.svg'
import {useNavigate} from "react-router-dom";
import {get_api, post_api, post_api_upload} from "../serverTemp";
import MBModal from "./MBModal";
import left from "../ProDiscovery/images/left.png";
import upRight from "../ProDiscovery/images/upRight.png";
import downRight from "../ProDiscovery/images/downRight.png";
import SparkMD5 from "spark-md5";
import {getMD5} from "../../apps/ArtistPortal/ArtistPortalHome/components/ArtUploader/utils";
import {CheckOutlined, PlusCircleOutlined} from "@ant-design/icons";
import SearchButton from "../ProDiscovery/images/search-normal.svg";
import {TwitterPicker} from "react-color";
import SubMenu from "antd/es/menu/SubMenu";

const {CheckableTag} = Tag;

const MyLibrary = (props) => {
    const [albums, setAlbums] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMBModalOpen, setIsMBModalOpen] = useState(false)
    const [isNewAlbum, setIsNewAlbum] = useState(true);
    const [curAlbumID, setCurAlbumID] = useState('')
    const navigate = useNavigate()
    const [albumName, setAlbumName] = useState(''); // State to store the input value
    const [uploading, setUploading] = useState(false);

    const [curMode, setCurMode] = useState(0) // 0 uploaded, 1 arts from market
    const [uploadedArts, setUploadedArts] = useState([])
    const [marketArts, setMarketArts] = useState([])
    const [arts, setArts] = useState([])
    const [currentArtsPage, setCurrentArtsPage] = useState(0);
    const [loadingArts, setLoadingArts] = useState(false);
    const [hasMoreArts, setHasMoreArts] = useState(true);
    const [toAddArts, setToAddArts] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [categories, setCategories] = useState([])
    const [remoteColors, setRemoteColors] = useState([])
    const [visible, setVisible] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);


    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows, selectedRowKeys)
            setSelectedRowKeys(selectedRowKeys);
            setToAddArts(selectedRows);
        },
    };


    const fetchAllArtsData = async (page = 0) => {
        setLoadingArts(true);
        const api = curMode === 0 ?
            `/album/uploaded/artworks?page=${page}` :
            (curMode === 1 ? `/artwork/pro/artworks?page=${page}` :
                `/album/filter/uploaded/artworks?uploaded_artwork_filter=2&page=${page}`)
        const res = await get_api(api);

        if (res?.data?.length > 0) {
            setArts(prevData => [...prevData, ...res.data.map(art => ({...art, key: art.id}))]);
            await fetchAllArtsData(page + 1); // Recursively fetch the next page
        } else {
            setLoadingArts(false); // Stop loading when no more data
        }
    };
    const fetchFromSavedAlbum = async (page = 0) => {
        const user_id = localStorage.getItem('user_id').toString()
        setLoadingArts(true)
        const res = await get_api(`/album/artworks?album_id=${user_id}_default&page=${page}`)
        if (res?.data?.length > 0) {
            setArts(prevData => [...prevData, ...res.data.map(art => ({...art, key: art.id}))]);
            await fetchFromSavedAlbum(page + 1); // Recursively fetch the next page
        } else {
            setLoadingArts(false); // Stop loading when no more data
        }
    }
    const fetchArtsfromCats = async (page = 0, level, cat_id) => {
        setLoadingArts(true);
        const res = await get_api(`/artwork/from/categories?level=${level}&category_id=${cat_id}&page=${page}`);
        if (res?.data?.length > 0) {
            setArts(prevData => [...prevData, ...res.data.map(art => ({...art, key: art.id}))]);
            await fetchArtsfromCats(page + 1, level, cat_id); // Recursively fetch the next page
        } else {
            setLoadingArts(false); // Stop loading when no more data
        }
    };
    useEffect(() => {
        setArts([]); // Clear the current arts list
        fetchAllArtsData(); // Start fetching all arts data
        if (curMode === 2) {
            fetchFromSavedAlbum()
        }
    }, [curMode]);
    useEffect(() => {
        if (selectedTags.length === 0) {
            setArts([]); // Clear the current arts list
            fetchAllArtsData(); // Start fetching all arts data
        } else {
            setArts([]); // Clear the current arts list
            Promise.all(selectedTags.map(tag => fetchArtsfromCats(0, tag.level, tag.id))).then(() => {
                console.log("all tags fetched")
            });
        }
    }, [selectedTags]);

    useEffect(() => {
        get_api('/artwork/recommendation/categories').then(res => {
            // console.log(res.data.filter(item => item.text !== 'Colour'));
            console.log("show me categories");
            console.log(res.data);
            setCategories(res.data.filter(item => {
                if (item.text === 'Colour') {
                    let dictionary = {}
                    item.children.filter(child => child.text !== 'Multicolour#Multicolour')
                        .map((color) => {
                            const key = '#' + color.text.split('#')[1]
                            dictionary[key.toLowerCase()] = color.id
                        })
                    console.log("server end colors")
                    console.log(dictionary)
                    setRemoteColors(dictionary)
                    return false
                }
                return true
            }));
        });
    }, []);
    const twitterStyle = {
        default: {
            input: {
                display: "none"
            },
            hash: {
                display: "none"
            }
        }
    };
    const picker = () => {
        return (
            <div className="themePicker">
                <Card className="colorBox" title="Search by colour" size="small">
                    <TwitterPicker
                        //第一个颜色是系统默认颜色
                        colors={Object.keys(remoteColors)}
                        className="themeColorPicker"
                        triangle="top-left"
                        styles={twitterStyle}
                        onChangeComplete={
                            (color) => {
                                setVisible(false)
                                // console.log(color)
                                // console.log(defaultColors[color.hex])
                                // get_api(`/artwork/color?color=${remoteColors[color.hex]}`).then(res => {
                                // setCategories(res.data);
                                // setArtworks(res.data)
                                // });
                                // console.log("choosing color " + color.hex)
                                // console.log(remoteColors[color.hex])
                                // console.log(remoteColors)
                                handleMenuClick(remoteColors[color.hex.toLowerCase()], 1)
                            }
                        }
                    />
                </Card>
            </div>
        )
    };
    const handleMenuClick = (itemId, level) => {

    }
    const dynamicMenu = (param) => (
        <Menu>
            {param.children && param.children.length > 0 ? (
                param.children.map((item, index) => (
                    (item.children && item.children.length > 0 ? (
                        <SubMenu title={item.text}>
                            {item.children.map((subItem, index) => (
                                <Menu.Item onClick={() =>
                                    handleMenuClick(subItem.id, subItem.level)}
                                >
                                    {subItem.text}
                                </Menu.Item>
                            ))}
                        </SubMenu>
                    ) : (
                        <Menu.Item key={item.text} onClick={() =>
                            handleMenuClick(item.id, item.level)}
                        >
                            {item.text}
                        </Menu.Item>
                    ))
                ))) : (
                <p>empty list</p>
            )}
        </Menu>
    );


    const handleInputChange = (e) => {
        setAlbumName(e.target.value); // Update state with input value
    };

    const handleConfirmClick = () => {
        if (isNewAlbum) {
            if (!albumName) {
                message.warning('Please give your album a name.')
            } else {
                post_api('/album/add', {name: albumName})
                    .then(res => {
                        console.log(res.id)
                        const newAlbumID = res.id
                        console.log(toAddArts)
                        if (toAddArts.length > 0) {
                            post_api('/album/add/artwork',
                                {
                                    id: newAlbumID,
                                    artwork_ids: toAddArts.map((a) => a.id),
                                    from_: toAddArts[0].from_
                                })
                                .then(() => {
                                    window.location.reload()
                                })
                                .catch(error => console.error('Error:', error));
                        }
                    })
                    .catch(error => console.error('Error:', error));
            }
        } else {
            post_api('/album/rename', {id: curAlbumID, name: albumName}).then(
                res => {
                    window.location.reload()
                })
        }
    };
    const MBModalprops = {
        isModalOpen: isMBModalOpen,
        setIsModalOpen: setIsMBModalOpen,
        handleConfirmClick: handleConfirmClick,
        handleInputChange: handleInputChange,
        albumName: albumName,
        isNewAlbum: isNewAlbum,
    }
    useEffect(() => {
        get_api('/album/all').then(
            res => {
                setAlbums(res.data.map((album) => {
                    return {
                        id: album.id,
                        name: album.name,
                        urls: album.url,
                        item: {
                            left: album.url.split(',')[0] ?? null,
                            upRight: album.url.split(',')[1] ?? null,
                            downRight: album.url.split(',')[2] ?? null,
                        }
                    }
                }))
            }
        )
    }, [])
    const uploadFile = async (blob) => {
        setUploading(true)
        console.log(blob.name)
        const spark = new SparkMD5.ArrayBuffer();
        let fileMd5 = '';
        await getMD5(blob, spark).then((md5) => {
            fileMd5 = md5
        })
        post_api_upload(
            blob.name,
            fileMd5,
            blob,
            true
        ).then(() => {
            setUploading(false)
        }).catch(error => {
            console.log('error', error)
            if (error.toString().startsWith('HTTP ERROR')) {
                message.error(error)
            } else {
                message.warn('Failed to upload the file.')
            }
            setUploading(false)
        });
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                const displayText = text.length > 40 ? `${text.substring(0, 40)}...` : text;
                return (

                    <div>
                        <Tooltip title={text}>
                            <a style={{color: 'black', fontWeight: 'bolder', fontSize: '0.7vw', width: '8vw'}}>
                                {displayText}
                            </a>
                        </Tooltip>
                    </div>

                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'url',
            key: 'url',
            render: (url, record) =>
                <Image src={url} style={{width: '2vw', height: '2vw'}}/>
        },
    ];
    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t.id !== tag.id);
        console.log('You are interested in: ', nextSelectedTags);
        setSelectedTags(nextSelectedTags);
    };
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row>
                    <Layout>
                        <Navigator/>
                        <Layout style={{marginBottom: '20rem'}}>
                            <Col lg={24}>
                                <p className={'ml-albums-p'} style={{marginTop: '3rem'}}>Manage Content</p>
                                <Row justify={"space-evenly"} style={{margin: '2rem 0 2rem 0'}}>
                                    <div className={'ml-card-divs'}>
                                        <p className={'ml-upload-txt'}>Upload Media</p>

                                        <Upload
                                            showUploadList={false}
                                            maxCount={10} //限制上传数量。当为 1 时，始终用最新上传的文件代替当前文件
                                            multiple={true} //是否支持多选文件，开启后按住 ctrl 可选择多个文件
                                            accept={'.png,.jpg,.jpeg, .mp4'}
                                            // beforeUpload={_ => false}
                                            customRequest={({file}) => {
                                                uploadFile(file)
                                            }}
                                            disabled={uploading}
                                        >
                                            <div className={'ml-up-div'}>
                                                {uploading ?
                                                    <Spin tip="Uploading" size="large"/>
                                                    : <BSUploadIcon style={{opacity: '0.5'}}/>}
                                            </div>
                                            <Button className={'ml-up-btn'} loading={uploading}>
                                                UPLOAD FILES
                                            </Button>
                                        </Upload>
                                        <p className={'ml-format-txt'}>
                                            Supported formats: JPEG, PNG, JPG or MP4
                                        </p>
                                    </div>
                                    <div className={'ml-card-divs'}
                                         onClick={() => {
                                             navigate('/proportal/images',
                                                 {
                                                     state: {
                                                         filter: -1,
                                                         title: 'My Uploads'
                                                     }
                                                 })
                                         }}
                                    >
                                        <UploadIcon/>
                                        <p className={'ml-card-divs-title'} style={{margin: '-20px 0 20px 0'}}>
                                            My Uploads
                                        </p>
                                        <p className={'ml-card-divs-des'}>
                                            Organise files from your most recent upload
                                        </p>
                                    </div>
                                    <div className={'ml-card-divs'}
                                         onClick={() => {
                                             navigate('/proportal/images',
                                                 {
                                                     state: {
                                                         filter: 2,
                                                         title: 'My Vieunite Art',
                                                         myArtMode: true
                                                     }
                                                 })
                                         }}
                                    >
                                        <BSIcon style={{}}/>
                                        <p className={'ml-card-divs-title'} style={{marginTop: '0px'}}>
                                            My Vieunite Art
                                        </p>
                                        <p className={'ml-card-divs-des'}>
                                            View all the artwork
                                            in your collection
                                        </p>
                                    </div>
                                    <div className={'ml-card-divs'}
                                         onClick={() => {
                                             navigate('/proportal/albums')
                                         }}
                                    >
                                        <img style={{width: '16rem', height: '12rem'}}
                                             src={curated_img}
                                             alt={'curated_img'}
                                        />
                                    </div>
                                </Row>
                                <Row className={'ml-albums-p'} style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '85%',
                                    marginTop: '6vh'
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <span>My Albums</span>
                                        <Tooltip title="Create a new album">
                                            <PlusCircleOutlined
                                                className={'ml-plus-icon'}
                                                onClick={() => {
                                                    setIsNewAlbum(true)
                                                    setIsModalOpen(true)
                                                }}/>
                                        </Tooltip>
                                    </div>
                                    {/*<Button className={'ml-add-album-btn'}*/}
                                    {/*        onClick={() => {*/}
                                    {/*            setIsNewAlbum(true)*/}
                                    {/*            setIsModalOpen(true)*/}
                                    {/*        }}*/}
                                    {/*>*/}
                                    {/*<span style={{*/}
                                    {/*    fontSize:'1.5vw',*/}
                                    {/*    marginTop:'-6px'*/}
                                    {/*}}>+</span>*/}
                                    {/*<span>Create a new album</span>*/}

                                    {/*</Button>*/}
                                    <span style={{
                                        cursor: 'pointer',
                                        opacity: '0.75',
                                        fontWeight: '400'
                                    }}
                                          onClick={_ => {
                                              window.location.href = '/proportal/my-albums'
                                          }}
                                    >See All</span>
                                </Row>
                                <Row justify={"space-evenly"}
                                     style={{paddingBottom: '8rem'}}
                                >
                                    {albums.length >= 0 ?
                                        albums.slice(0, 4).map((album, index) => {
                                            return (
                                                <div className={'mb-album-card'} style={{
                                                    margin: 0
                                                }}>
                                                    {album?.urls?.split(',').length >= 3 ?
                                                        <div className="container"
                                                             onClick={() => {
                                                                 navigate('/proportal/images', {
                                                                     state: {
                                                                         albumID: album.id,
                                                                         // albumID:`${localStorage.getItem('user_id')}_default`,
                                                                         title: album.name,
                                                                         filter: 'album'
                                                                     }
                                                                 })
                                                             }}
                                                             style={{
                                                                 width: 200,
                                                                 height: 180,
                                                                 marginLeft: 10,
                                                                 marginTop: 10,
                                                                 marginRight: 10,
                                                                 marginBottom: 0,
                                                                 cursor: 'pointer',
                                                             }}
                                                        >
                                                            <div className="left">
                                                                <img style={{height: '99%'}}
                                                                     src={album?.item.left || left} alt="Left"/>
                                                            </div>
                                                            <div className="right">
                                                                <img src={album?.item.upRight || upRight}
                                                                     alt="Top Right"/>
                                                                <img src={album?.item.downRight || downRight}
                                                                     alt="Bottom Right"/>
                                                            </div>
                                                        </div>
                                                        :
                                                        <img
                                                            onClick={() => {
                                                                navigate('/proportal/images', {
                                                                    state: {
                                                                        albumID: album.id,
                                                                        title: album.name,
                                                                        filter: 'album'
                                                                    }
                                                                })
                                                            }}
                                                            src={album.urls.split(',')[0]} alt="Album cover"
                                                            style={{
                                                                width: "80%",
                                                                height: "50%",
                                                                objectFit: "cover",
                                                                cursor: 'pointer',
                                                            }}/>
                                                    }
                                                    <p className={'mb-album-name'}>
                                                        {album?.name}
                                                    </p>
                                                    <div className={'mb-album-ops'}>
                                                        <div style={{
                                                            cursor: 'pointer', display: 'flex',
                                                            alignItems: 'center',
                                                            flexDirection: 'column',
                                                        }}
                                                             onClick={() => {
                                                                 navigate('/proportal/dashboard', {state: {album_id: album.id}})
                                                             }}
                                                        >
                                                            <PubIcon/>
                                                            <p className={'ml-p-small-icon'}>PUBLISH</p>
                                                        </div>
                                                        <div style={{
                                                            cursor: 'pointer', display: 'flex',
                                                            alignItems: 'center',
                                                            flexDirection: 'column',
                                                        }}
                                                             onClick={() => {
                                                                 setIsNewAlbum(false)
                                                                 setCurAlbumID(album.id)
                                                                 setIsMBModalOpen(true)
                                                             }}>
                                                            <RenameIcon/>
                                                            <p className={'ml-p-small-icon'}>RENAME</p>
                                                        </div>
                                                        <div style={{
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            flexDirection: 'column',
                                                        }}
                                                             onClick={() => {
                                                                 console.log(album.id)
                                                                 post_api('/album/delete', {
                                                                     id: album.id
                                                                 }).then(
                                                                     res => {
                                                                         window.location.reload()
                                                                     }
                                                                 )
                                                             }}>
                                                            <DelIcon/>
                                                            <p className={'ml-p-small-icon'}>DELETE</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <></>
                                    }
                                    {albums.length < 4 && [...Array(4 - albums.length)].map(() =>
                                        <div style={{
                                            width: "275px",
                                            height: "auto",
                                            objectFit: "cover",
                                            // cursor: 'pointer',
                                            background: "#f0f2f5"
                                            // background: "black"
                                        }}/>)}

                                </Row>
                            </Col>
                            <MBModal {...MBModalprops}/>
                            <Modal
                                className={'ml-new-album-modal-2'}
                                visible={isModalOpen}
                                onOk={_ => setIsModalOpen(false)}
                                onCancel={_ => setIsModalOpen(false)}
                            >
                                <Row justify={"center"} style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: 'row',
                                    justifyContent: "flex-end"
                                }}>
                                    {/*<div>*/}
                                    {/*    <span style={{width: '4vw'}}>Name:&nbsp; &nbsp; </span>*/}
                                    {/*    <Input className={''}*/}
                                    {/*           style={{width: '10vw', margin: '0 0 0 0'}}*/}
                                    {/*           placeholder="Album Name"*/}
                                    {/*           value={albumName} // Bind input value to state*/}
                                    {/*           onChange={handleInputChange} // Set up onChange handler*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <Segmented
                                        size={"middle"}
                                        options={localStorage.getItem('subscription') === 'Premium' ?
                                            ['My Uploaded', 'My Purchased', 'On Market'] :
                                            ['My Uploaded', 'My Purchased']}
                                        onChange={(value) => {
                                            if (value === 'My Uploaded') {
                                                // setDraftMode(true)
                                                setCurMode(0)
                                            } // string
                                            else {
                                                if (value === 'My Purchased') {
                                                    setCurMode(2)
                                                } else {
                                                    setCurMode(1)
                                                }
                                            }
                                        }}
                                    />
                                    <Button className={''}
                                            style={{
                                                margin: '0 2vw 0 2vw',
                                                background: '#0E477A',
                                                color: "white"
                                            }}
                                            onClick={handleConfirmClick}>
                                        Confirm
                                    </Button>
                                </Row>
                                {/*<Row justify={"center"} style={{*/}
                                {/*    display: "flex",*/}
                                {/*    alignItems: "center",*/}
                                {/*    flexDirection: 'row',*/}
                                {/*    justifyContent: "flex-start",*/}
                                {/*    margin:'4vh 0 -6vh 2vw'*/}
                                {/*}}>*/}

                                {/*    {categories.map((item, index) => (*/}
                                {/*        <Dropdown overlay={dynamicMenu(item)} placement={"bottom"}*/}
                                {/*                  className={'see-all-dropdown'}>*/}
                                {/*            <Button style={{borderRadius: "6px", margin: "5px"}}>*/}
                                {/*                {item.text}*/}
                                {/*            </Button>*/}
                                {/*        </Dropdown>*/}
                                {/*    ))}*/}
                                {/*    <Dropdown*/}
                                {/*        overlay={picker}>*/}
                                {/*        <Button*/}
                                {/*            style={{borderRadius: "6px", margin: "5px"}}*/}
                                {/*            onClick={() => setVisible(!visible)}*/}
                                {/*        >*/}
                                {/*            Colour*/}
                                {/*        </Button>*/}
                                {/*    </Dropdown>*/}
                                {/*</Row>*/}
                                <Row justify={"center"}>
                                    <Col style={{
                                        width: '16vw',
                                        height: '90vh', // Adjust height as needed
                                        overflowY: 'auto',
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        margin: '-14vh 0 0 0'
                                    }}>
                                        <div style={{display: "flex", marginBottom:'2vh'}}>
                                            <p style={{
                                                fontWeight: "bold",
                                                fontSize: "0.8vw",
                                            }}>Name:</p>
                                            <Input className={''}
                                                   style={{width: '10vw', margin: '0 0 0 1vw', height: '3.4vh'}}
                                                   placeholder="Please input album name"
                                                   value={albumName} // Bind input value to state
                                                   onChange={handleInputChange} // Set up onChange handler
                                            />
                                        </div>
                                        {curMode === 1 && <div>
                                            <p style={{
                                                fontWeight: "bold",
                                                fontSize: "0.8vw",
                                            }}>Categories:</p>
                                            <div style={{
                                                display: "flex", flexDirection: "row",
                                                alignItems: 'flex-start',
                                                flexWrap: "wrap",
                                                marginLeft: '-0.5vw'
                                            }}>
                                                {categories.map((cat) => {
                                                    console.log(cat)
                                                    const cats = []
                                                    cats.push(<CheckableTag
                                                        key={cat.id}
                                                        checked={selectedTags.indexOf(cat) > -1}
                                                        onChange={(checked) => {
                                                            handleChange(cat, checked)
                                                        }}
                                                    >
                                                        {cat.text}
                                                    </CheckableTag>)
                                                    if (cat.children.length > 0) {
                                                        cat.children.map((child) => {
                                                            cats.push(<CheckableTag
                                                                key={child.id}
                                                                checked={selectedTags.indexOf(child) > -1}
                                                                onChange={(checked) => {
                                                                    handleChange(child, checked)
                                                                }}
                                                            >{child.text}
                                                            </CheckableTag>)
                                                        })
                                                    }
                                                    return cats
                                                })}
                                            </div>

                                            <p style={{
                                                fontWeight: "bold",
                                                fontSize: "0.8vw",
                                                margin: '2vh 0 0 0'
                                            }}>Colors:</p>
                                            <div style={{
                                                display: "flex", flexDirection: "row",
                                                alignItems: 'flex-start',
                                                flexWrap: "wrap",
                                                margin: '2vh 0 0 0'
                                            }}>
                                                {Object.entries(remoteColors).map((color) => {
                                                    const colorDict = {id: color[1], level: 1}
                                                    return (
                                                        <CheckableTag
                                                            key={color[1]}
                                                            checked={selectedTags.some(tag => tag.id === colorDict.id)}
                                                            onChange={(checked) => {
                                                                handleChange(colorDict, checked)
                                                            }}
                                                            style={{
                                                                width: '1.6vw',
                                                                height: '1.6vw',
                                                                borderRadius: '10%',
                                                                backgroundColor: color[0],
                                                                margin: '0 0.5vw 0.5vw 0',
                                                                cursor: 'pointer',
                                                                boxShadow: selectedTags.some(tag => tag.id === colorDict.id)
                                                                    ? '0 4px 8px rgba(0, 0, 0, 0.2), inset 0 2px 5px rgba(255, 255, 255, 0.3)'
                                                                    : 'none',
                                                                transform: selectedTags.some(tag => tag.id === colorDict.id) ? 'translateY(-2px)' : 'none',
                                                                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                                                            }}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </div>}

                                    </Col>
                                    <div style={{
                                        width: '1px',
                                        background: 'lightgrey',
                                        height: '62vh',
                                        margin: '-2vh 2vw 0 0'
                                    }}/>
                                    <Col style={{
                                        width: '38vw',
                                        height: '90vh', // Adjust height as needed
                                        overflowY: 'auto'
                                    }}
                                         onScroll={(e) => {
                                             // handleScroll(e, {
                                             //     loading: mode === 2 ? loadingDraftPls : loadingPls,
                                             //     setPage: mode === 2 ? setCurrentDraftPlsPage : setCurrentPlsPage
                                         }}
                                    >
                                        <Table columns={columns}
                                               dataSource={arts}
                                               className="custom-table"
                                               rowSelection={rowSelection}
                                               pagination={{
                                                   defaultPageSize: '5',
                                                   showSizeChanger: false,
                                                   pageSize: '5'
                                               }}
                                               style={{
                                                   border: 'blue'
                                               }}/>
                                    </Col>
                                </Row>

                            </Modal>
                        </Layout>
                    </Layout>
                </Row>

            </Content>
        </Layout>
    )
}

export default MyLibrary;
