import React from 'react';
import {Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import {ReactComponent as Logo} from "./Vieunite_logo_Pro_Logo White.svg";
import img from "./Vieunite_logo_Pro_Logo White.webp"
import {useNavigate} from 'react-router-dom';
import useWindowResize from "../../../apps/Hooks/useWindowResize";


const ProFooter = (props) => {
    const navigate = useNavigate(); // Initialize the navigate hook
    const {windowWidth} = useWindowResize()
    return (
        windowWidth > 600 ?
            <Row className={'pro-footer-div'}>
                <Col lg={4} className={'pro-footer-left-col'}>
                    <img src={img} style={{margin: '10px 0 0 70px', width: '100%'}}/>
                    <p className={'pro-footer-small-txt'} style={{marginTop: '10px'}}>
                        ©2023 Vieunite Limited All Rights Reserved.
                    </p>
                    <p className={'pro-footer-small-txt'}>
                        VAT Registration No. 411723238
                    </p>
                    <p className={'pro-footer-small-txt'}>
                        Company Registration No. 13883746
                    </p>
                    <p className={'pro-footer-small-txt'}>
                    <span
                        style={{textDecoration: "underline", cursor: "pointer"}}
                        onClick={() => navigate('/business/ProTermsAndConditions')}
                    >
                        Terms and Conditions</span>
                        &nbsp;
                        <span
                            style={{textDecoration: "underline", cursor: "pointer"}}
                            onClick={() => navigate('/business/ProPrivacyPolicy')}
                        >
                        Privacy Policy</span>
                    </p>
                </Col>
                <Col lg={4} style={{marginTop: '4rem'}}>
                    <p className={'pro-footer-nav'} onClick={_ =>
                        window.location.href = '/business/home'}>Home</p>
                    <p className={'pro-footer-nav'} onClick={_ =>
                        window.location.href = '/business/textura-pro'}>Textura Pro</p>
                    <p className={'pro-footer-nav'} onClick={_ =>
                        window.location.href = '/business/subscription'}>Subscription</p>
                    <p className={'pro-footer-nav'} onClick={_ =>
                        window.location.href = '/business/software'}>Software</p>
                    <p className={'pro-footer-nav'} onClick={_ =>
                        window.location.href = '/business/curated-collections'}>Curated Collections</p>
                </Col>
            </Row> :
            <Row className={'m-pro-footer-div'}>
                <p className={'m-pro-footer-nav'}
                   style={{marginTop: '4vh'}}
                   onClick={_ =>
                       window.location.href = '/business/home'}>Home</p>
                <p className={'m-pro-footer-nav'} onClick={_ =>
                    window.location.href = '/business/textura-pro'}>Textura Pro</p>
                <p className={'m-pro-footer-nav'} onClick={_ =>
                    window.location.href = '/business/subscription'}>Subscription</p>
                <p className={'m-pro-footer-nav'} onClick={_ =>
                    window.location.href = '/business/software'}>Software</p>
                <p className={'m-pro-footer-nav'} onClick={_ =>
                    window.location.href = '/business/curated-collections'}>Curated Collections</p>
                <div className={'m-pro-footer-divider'}></div>
                <p className={'m-pro-footer-small-txt'}>
                    <span
                        style={{textDecoration: "underline", cursor: "pointer"}}
                        onClick={() => navigate('/business/ProTermsAndConditions')}
                    >
                        Terms and Conditions</span>
                    &nbsp;
                    <span
                        style={{textDecoration: "underline", cursor: "pointer"}}
                        onClick={() => navigate('/business/ProPrivacyPolicy')}
                    >
                        Privacy Policy</span>
                </p>

                <p className={'m-pro-footer-small-txt'} style={{marginTop: '10px'}}>
                    ©2023 Vieunite Limited All Rights Reserved.
                </p>
                <p className={'m-pro-footer-small-txt'}>
                    VAT Registration No. 411723238
                </p>
                <p className={'m-pro-footer-small-txt'} style={{marginBottom: '4vh'}}>
                    Company Registration No. 13883746
                </p>


            </Row>
    )
}

export default ProFooter;
