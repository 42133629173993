import {Button, Form, Input, Row} from "antd";
import {Primary_Font, TitleFont} from "../../../settings";
import {checkIfArtist, getVerificationCode} from "../services";
import {MailOutlined} from "@ant-design/icons";
import React from "react";
import useWindowResize from "../../Hooks/useWindowResize";


export const InputEmail = (props) => {
    const {setStep, setEmail} = props
    const {windowWidth} = useWindowResize()
    return (
        <div>
            <Row justify={"center"}>
                <h2 className="title-app"
                    style={window.outerWidth >= 600 ?
                        {textAlign: "center", marginTop: '-2%', ...TitleFont,} :
                        {textAlign: "left", ...TitleFont,}
                    }>
                    <span style={{fontWeight: '300', fontSize: '2rem'}}>Forgot your password?</span>
                </h2>
            </Row>
            <Row justify={"center"}>
                <p style={{
                    ...Primary_Font,
                    textAlign: "center",
                    width: windowWidth > 600 ? '100%' : '90%'
                }}>
                    We'll email you instructions on how to reset your password
                </p>
            </Row>
            <Row justify={"center"}>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={async (values) => {
                        let isArtist = false
                        isArtist = await checkIfArtist(values.email)
                        if(isArtist) {
                            getVerificationCode(values.email)
                                .then(() => {
                                    setEmail(values.email)
                                }).then(() => {
                                setStep(1)
                            })
                                .catch(error => console.log('error', error));
                        }
                        else {
                            alert('You are not an artist, please apply first!')
                        }
                    }}
                    style={window.outerWidth >= 600 ? {width: '50%'} : {width: '90%'}}
                >
                    <Form.Item
                        labelCol={{span: 24, style: {fontWeight: '600'}}}
                        label={'Email'}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Username!',
                            },
                        ]}
                    >
                        <Input className={'login-input-form'}/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" className="login-form-button">
                            RESET PASSWORD
                        </Button>
                    </Form.Item>
                </Form>
            </Row>
        </div>
    )
}