import React, {useRef, useState} from 'react';
import {Button, Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import {quizRules} from "./QuizRule";
import './ProDynamicTable.scss'
import {Tour} from 'antd-v5'
import raised from "react-color/lib/components/common/Raised";

const TikIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
    <g clip-path="url(#clip0_3047_1672)">
        <path
            d="M14.8496 1.34961H12.1496C6.18493 1.34961 1.34961 6.18493 1.34961 12.1496V14.8496C1.34961 20.8143 6.18493 25.6496 12.1496 25.6496H14.8496C20.8143 25.6496 25.6496 20.8143 25.6496 14.8496V12.1496C25.6496 6.18493 20.8143 1.34961 14.8496 1.34961Z"
            stroke="#936846" stroke-width="2.7"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M11.4336 18.7777L10.4751 17.8192L7.14062 14.4847L9.04412 12.5812L11.4336 14.9707L17.2656 9.13867L19.1691 11.0422L12.3786 17.8327L11.4201 18.7912L11.4336 18.7777Z"
              fill="#936846"/>
    </g>
    <defs>
        <clipPath id="clip0_3047_1672">
            <rect width="27" height="27" fill="white"/>
        </clipPath>
    </defs>
</svg>
const ProVerticalTable = (props) => {
    const {plan, tourMessage, openTour, setOpenTour} = props
    const foundationRef = useRef(null);
    const advancedRef = useRef(null);
    const premiumRef = useRef(null);


    const getTargetRef = () => {

        switch (plan) {

            case 'foundation':
                return foundationRef.current;
            case 'advanced':
                return advancedRef.current;
            case 'premium':
                return premiumRef.current;
            default:
                return null;
        }
    };

    // Define the tour steps
    const steps = [
        {
            title: `${plan.charAt(0).toUpperCase() + plan.slice(1)} Plan`,
            description: tourMessage,
            target: getTargetRef, // Use the target ref based on ans
            placement: 'right',
        },
    ];
    return (
        <Row
            style={{
                width: '85vw',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                paddingLeft: '0vw',
            }}
        >
            {/* Column 1 - Features */}
            <Col
                style={{
                    width: '35vw',
                    textAlign: 'left',
                }}
            >
                <div className="pro-dy-table-title"
                     style={{display: "flex", justifyContent: "start", paddingLeft: '4vw'}}>Features
                </div>
                <div className="pro-dy-table-content-left cell11" style={{background: '#F8F9FA'}}>
                    Access to the Vieunite Pro Portal
                </div>
                <div className="pro-dy-table-content-left" style={{background: 'white'}}>
                    Maximum number of Textura Pro digital canvases
                </div>
                <div className="pro-dy-table-content-left" style={{background: '#F8F9FA'}}>
                    Cloud storage for your media (photos, images, videos)
                </div>
                <div className="pro-dy-table-content-left" style={{background: 'white'}}>
                    Access to Classic Creative Commons Zero (CC0) artwork
                </div>
                <div className="pro-dy-table-content-left" style={{background: '#F8F9FA'}}>
                    Access to exclusive artwork
                </div>
                <div className="pro-dy-table-content-left" style={{background: 'white'}}>
                    Access to premium exclusive artwork
                </div>
                <div className="pro-dy-table-content-left" style={{background: '#F8F9FA'}}>
                    Access to curated playlists with CC0 artwork
                </div>
                <div className="pro-dy-table-content-left" style={{background: 'white'}}>
                    Access to curated playlists with premium exclusive artwork
                </div>
                <div className="pro-dy-table-content-left" style={{background: '#F8F9FA'}}>
                    Access to curated playlists with premium exclusive artwork
                </div>
                <div className="pro-dy-table-content-left" style={{background: 'white'}}>
                    All-inclusive content and platform management service
                </div>
                <div className="pro-dy-table-content-left" style={{background: '#F8F9FA'}}>
                    Custom playlist creation by your own dedicated art curator
                </div>
                <div className="pro-dy-table-content-left" style={{background: 'white'}}>
                    Dedicated support team (telephone, email, and WhatsApp)
                </div>
            </Col>

            {/* Column 2 - Standard */}
            <Col ref={foundationRef} style={{width: '15vw'}}>
                <div className="pro-dy-table-title">Foundation</div>
                <div className="pro-dy-table-content cell12" style={{background: '#F8F9FA'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    Up to 2
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    500MB
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    Pay as priced
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    Pay as priced
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    -
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    -
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    -
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    -
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white', height: 'auto'}}>
                    <p className={'pro-dy-table-p1'}>Foundation</p>
                    <p className={'pro-dy-table-p2'}>£0</p>
                    <p className={'pro-dy-table-p3'}>device / year</p>
                    <button onClick={() => {
                        window.location.href = '/pro/support/request-demo'
                    }}
                            className={'homeMainButton black-border-hover-white pro-dy-table-btn'}>
                        GET STARTED
                    </button>
                </div>
            </Col>

            {/* Column 3 - Advanced */}
            <Col ref={advancedRef} style={{width: '15vw'}}>
                <div className="pro-dy-table-title">Advanced</div>
                <div className="pro-dy-table-content cell13" style={{background: '#F8F9FA'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    Unlimited
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    4GB
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    Pay as priced
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    -
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    -
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    -
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white', height: 'auto'}}>
                    <p className={'pro-dy-table-p1'}>Advanced</p>
                    <p className={'pro-dy-table-p2'}>£99</p>
                    <p className={'pro-dy-table-p3'}>device / year</p>
                    <button className={'homeMainButton black-border-hover-white pro-dy-table-btn'} onClick={() => {
                        window.location.href = '/pro/support/request-demo'
                    }}>
                        GET STARTED
                    </button>
                </div>
            </Col>

            {/* Column 4 - Premium */}
            <Col ref={premiumRef} style={{width: '15vw'}}>
                <div className="pro-dy-table-title">Premium</div>
                <div className="pro-dy-table-content cell14" style={{background: '#F8F9FA'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    Unlimited
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    Unlimited
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: '#F8F9FA'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white'}}>
                    <TikIcon/>
                </div>
                <div className="pro-dy-table-content" style={{background: 'white', height: 'auto'}}>
                    <p className={'pro-dy-table-p1'}>Premium</p>
                    <p className={'pro-dy-table-p2'}>£189</p>
                    <p className={'pro-dy-table-p3'}>device / year</p>
                    <button className={'homeMainButton black-border-hover-white pro-dy-table-btn'} onClick={() => {
                        window.location.href = '/pro/support/request-demo'
                    }}>
                        GET STARTED
                    </button>
                </div>
            </Col>
            {/* ... Table content ... */}
            <Tour
                open={openTour}
                onClose={() => setOpenTour(false)}
                steps={steps}
            />
        </Row>
    );
};

export default ProVerticalTable;
