import React, {useState} from 'react';
import {Button, Form, Input, Modal, Row} from 'antd';
import "./style.scss"
import useWindowResize from "../../Hooks/useWindowResize";
import {Primary_Font} from "../../../settings";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import {login} from "../services";
import {useNavigate} from "react-router-dom";

const LoginModal = (props) => {
    const {loginModalOpen, setLoginModalOpen, setReminder, reminder} = props;
    const {windowWidth} = useWindowResize()
    const navigate = useNavigate()
    const onFinish = (values) => {
        login(values, setReminder).catch(error => console.log('error', error))
    }
    return (
        <div>
            <Modal title={<div>
                <p style={{fontSize: '1.8rem', textAlign: "center", ...Primary_Font}}>
                    <span style={{fontWeight: '400'}}>Apply to become a Vieunite</span> Artist.
                </p>
                <p style={{
                    width: '80%',
                    fontSize: '0.9rem',
                    ...Primary_Font,
                    fontWeight: '500',
                    textAlign: "center",
                    margin:'0 0rem 1rem 3rem'
                }}>
                    The Artist Portal is how artists can apply for and manage their Vieunite Artists accounts. <span
                    style={{
                        fontWeight: '600'
                    }}>To apply
                    to become a Vieunite artist you must have a Vieunite account. You can can create a free account
                    using the Vieunite app.</span> Please log into your Vieunite account to begin the application
                    process
                </p>
            </div>}
                   visible={loginModalOpen}
                   onCancel={() => setLoginModalOpen(false)}
                   footer={null}
                   className={'charge-modal'}
                   width={windowWidth > 600 ? '35%' : '100%'}
            >
                <Row justify={"center"}>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        style={windowWidth >= 600 ? {width: '70%'} : {width: '90%'}}
                    >
                        <Form.Item
                            labelCol={{span: 24, style: {fontWeight: '600'}}}
                            label={'Email'}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input className={'login-input-form'}
                                   prefix={<MailOutlined className="site-form-item-icon"/>}
                            />
                        </Form.Item>
                        <Form.Item
                            labelCol={{span: 24, style: {fontWeight: '600'}}}
                            label={'Password'}
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input className={'login-input-form'}
                                   prefix={<LockOutlined className="site-form-item-icon"/>}
                                   type="password"
                            />
                        </Form.Item>
                        <p style={{
                            color: "red"
                        }}>{reminder}</p>
                        <p className="login-form-forgot"
                           style={{cursor: "pointer"}}
                           onClick={() => navigate('/artistportal/forgotpassword')}>
                            Forgot password?
                        </p>
                        <Form.Item>
                            <Button htmlType="submit" className="login-form-button">
                                LOG IN >
                            </Button>
                        </Form.Item>
                    </Form>
                </Row>
            </Modal>
        </div>
    );
};

export default LoginModal;