import React, {useEffect, useState} from 'react';
import challenge_background from "../ProHome/images/home_pro_challenge.png";
import {ScrollActive, ScrollInactive, ScrollLeftArrow, ScrollRightArrow} from "../ProHome/CarouselComponents";
import './ProTLayout.css'
import './Subscription.css'

export function TLayout( {HeaderSection, CardList, BackgroundImg, BackgroundColor = '#fff'} ) {

    const [currentIndex, setCurrentIndex] = useState(0);

    return (<div style={{backgroundColor: `${BackgroundColor}`}}>
        <div style={{
            display: 'flex',
            flexDirection: "row",
            height: "64rem",
            margin: '0 8vw', // follow the left/right baseline
            padding: '6rem 0',
        }}>
            <img
                style={{
                    height: '100%',
                    width: '30vw',
                    objectFit: 'cover',
                    borderRadius: '15px',
                }}
                src={BackgroundImg}
                alt={'challenge'}/>

            <div style={{
                display: 'flex',
                flexDirection: "column",
                width: '54vw',
            }}>
                <div className={'spacer'}></div>
                {/*<TLayoutTitleSection/>*/}
                <>{HeaderSection}</>
                <div style={{height: '1.5rem'}}></div>

                <div style={{display: 'flex', flexDirection: 'row', direction: 'rtl'}}>
                    <div style={{width: "63vw", display: 'flex', flexDirection: 'row', flexShrink: '0', overflowX: 'hidden', direction: 'ltr'}}>
                        {CardList.map((item, idx) => {
                            return (
                                // <img src={example} alt={"example"} style={{margin: '10px'}}/>
                                //style={{width: `${100 / pageCapacity - 2}%`}}
                                <div
                                    className={'home-pro-t-layout-card'}
                                    style={{
                                        transform: `translateX(-${100 * currentIndex}%)`,
                                        transition: 'transform 0.4s ease'
                                    }}
                                >
                                    <div style={{
                                        height: '100%',
                                        backgroundColor: '#F5F7F8',
                                        padding: '1.5vw',
                                        borderRadius: '15px',
                                    }}>
                                        <div style={{height: '1rem'}}></div>
                                        <p className={'home-pro-t-card-title'}>{item[0]}</p>
                                        <div style={{height: '1rem'}}></div>
                                        <p className={'home-pro-t-card-body'}>{item[1]}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div
                    className={'home-pro-t-layout-card-control'}
                    style={{ visibility: currentIndex !== 0 ? 'visible' : '' }}
                >
                    <ScrollLeftArrow onClick={() => {
                        if (currentIndex > 0) {
                            setCurrentIndex(currentIndex - 1)
                        }
                    }}/>
                    <div className={'spacer'}></div>
                    {CardList.map((item, index) =>
                        <div key={index} style={{margin: '0 2px'}}>
                            {currentIndex === index ?
                                <ScrollActive/> :
                                <ScrollInactive/>}
                        </div>
                    )}
                    <div className={'spacer'}></div>
                    <ScrollRightArrow onClick={() => {
                        if (currentIndex < (CardList.length - 1)) {
                            setCurrentIndex(currentIndex + 1)
                        }
                    }}/>
                </div>

                <div style={{height: '1vw'}}></div>
            </div>
        </div>
    </div>)
}

export function TLayoutReversed( {HeaderSection, CardList, BackgroundImg, BackgroundColor = '#fff'} ) {

    const [currentIndex, setCurrentIndex] = useState(0);

    return (<div style={{backgroundColor: `${BackgroundColor}`}}>
        <div style={{
            display: 'flex',
            flexDirection: "row-reverse",
            height: "64rem",
            margin: '0 8vw', // follow the left/right baseline
            padding: '6rem 0',
        }}>
            <img
                style={{
                    height: '100%',
                    width: '30vw',
                    objectFit: 'cover',
                    borderRadius: '15px',
                }}
                src={BackgroundImg}
                alt={'challenge'}/>

            <div style={{
                display: 'flex',
                flexDirection: "column",
                width: '54vw',
                // backgroundColor: 'red',
            }}>
                <div className={'spacer'}></div>
                {/*<TLayoutTitleSection/>*/}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className={"spacer"}></div>
                    <>{HeaderSection}</>
                </div>

                <div style={{height: '1.5rem'}}></div>

                <div style={{display: 'flex', flexDirection: 'row', direction: 'ltr'}}>
                    <div style={{width: "63vw", display: 'flex', flexDirection: 'row', flexShrink: '0', overflowX: 'hidden'}}>
                        {CardList.map((item, idx) => {
                            return (
                                // <img src={example} alt={"example"} style={{margin: '10px'}}/>
                                //style={{width: `${100 / pageCapacity - 2}%`}}
                                <div
                                    className={'home-pro-t-layout-card'}
                                    style={{
                                        transform: `translateX(-${100 * currentIndex}%)`,
                                        transition: 'transform 0.4s ease'
                                    }}
                                >
                                    <div style={{
                                        height: '100%',
                                        backgroundColor: '#F5F7F8',
                                        padding: '1.5vw',
                                        borderRadius: '15px',
                                    }}>
                                        <div style={{height: '1rem'}}></div>
                                        <p className={'home-pro-t-card-title'}>{item[0]}</p>
                                        <div style={{height: '1rem'}}></div>
                                        <p className={'home-pro-t-card-body'}>{item[1]}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div
                    className={'home-pro-t-layout-card-control'}
                    style={{ visibility: currentIndex !== 0 ? 'visible' : '' }}
                >
                    <ScrollLeftArrow onClick={() => {
                        if (currentIndex > 0) {
                            setCurrentIndex(currentIndex - 1)
                        }
                    }}/>
                    <div className={'spacer'}></div>
                    {CardList.map((item, index) =>
                        <div key={index} style={{margin: '0 2px'}}>
                            {currentIndex === index ?
                                <ScrollActive/> :
                                <ScrollInactive/>}
                        </div>
                    )}
                    <div className={'spacer'}></div>
                    <ScrollRightArrow onClick={() => {
                        if (currentIndex < (CardList.length - 1)) {
                            setCurrentIndex(currentIndex + 1)
                        }
                    }}/>
                </div>

                <div style={{height: '1vw'}}></div>
            </div>
        </div>
    </div>)
}

export function TLayoutStyle2Reversed( {HeaderSection, CardList, BackgroundImg, BackgroundColor = '#fff'} ) {

    const [currentIndex, setCurrentIndex] = useState(0);

    return (<div style={{backgroundColor: `${BackgroundColor}`}}>
        <div style={{
            display: 'flex',
            flexDirection: "row-reverse",
            height: "64rem",
            margin: '0 8vw', // follow the left/right baseline
            padding: '6rem 0',
        }}>
            <img
                style={{
                    height: '100%',
                    width: '42vw',
                    objectFit: 'cover',
                    borderRadius: '15px',
                }}
                src={BackgroundImg}
                alt={'challenge'}/>

            <div style={{
                display: 'flex',
                flexDirection: "column",
                width: '42vw'
            }}>
                <div className={'spacer'}></div>
                {/*<TLayoutTitleSection/>*/}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className={"spacer"}></div>
                    <>{HeaderSection}</>
                </div>
                <div style={{height: '1.5rem'}}></div>

                <div style={{display: 'flex', flexDirection: 'row', direction: 'ltr'}}>
                    <div style={{
                        width: "50vw",
                        display: 'flex',
                        flexDirection: 'row',
                        flexShrink: '0',
                        overflowX: 'hidden'
                    }}>
                        {CardList.map((item, idx) => {
                            return (
                                // <img src={example} alt={"example"} style={{margin: '10px'}}/>
                                //style={{width: `${100 / pageCapacity - 2}%`}}
                                <div
                                    className={'home-pro-t-layout-card-style2'}
                                    style={{
                                        transform: `translateX(-${100 * currentIndex}%)`,
                                        transition: 'transform 0.4s ease'
                                    }}
                                >
                                    <div style={{
                                        height: '100%',
                                        width: '100%',
                                        backgroundColor: '#F5F7F8',
                                        padding: '1.5vw',
                                        borderRadius: '15px',
                                    }}>
                                        <div style={{height: '1rem'}}></div>
                                        <p className={'home-pro-t-card-title'}>{item[0]}</p>
                                        <div style={{height: '1rem'}}></div>
                                        <p className={'home-pro-t-card-body'}>{item[1]}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div
                    className={'home-pro-t-layout-card-control'}
                    style={{ visibility: currentIndex !== 0 ? 'visible' : '' }}
                >
                    <ScrollLeftArrow onClick={() => {
                        if (currentIndex > 0) {
                            setCurrentIndex(currentIndex - 1)
                        }
                    }}/>
                    <div className={'spacer'}></div>
                    {CardList.map((item, index) =>
                        <div key={index} style={{margin: '0 2px'}}>
                            {currentIndex === index ?
                                <ScrollActive/> :
                                <ScrollInactive/>}
                        </div>
                    )}
                    <div className={'spacer'}></div>
                    <ScrollRightArrow onClick={() => {
                        if (currentIndex < (CardList.length - 1)) {
                            setCurrentIndex(currentIndex + 1)
                        }
                    }}/>
                </div>

                <div style={{height: '1vw'}}></div>
            </div>
        </div>
    </div>)
}

export function TLayoutStyle2( {HeaderSection, CardList, BackgroundImg, BackgroundColor = '#fff'} ) {

    const [currentIndex, setCurrentIndex] = useState(0);

    return (<div style={{backgroundColor: `${BackgroundColor}`}}>
        <div style={{
            display: 'flex',
            flexDirection: "row",
            height: "64rem",
            margin: '0 8vw', // follow the left/right baseline
            padding: '6rem 0',
        }}>
            <img
                style={{
                    height: '100%',
                    width: '42vw',
                    objectFit: 'cover',
                    borderRadius: '15px',
                }}
                src={BackgroundImg}
                alt={'challenge'}/>

            <div style={{
                display: 'flex',
                flexDirection: "column",
                width: '42vw',
            }}>
                <div className={'spacer'}></div>
                {/*<TLayoutTitleSection/>*/}
                <>{HeaderSection}</>
                <div style={{height: '1.5rem'}}></div>

                <div style={{display: 'flex', flexDirection: 'row', direction: 'rtl'}}>
                    <div style={{width: "50vw", display: 'flex', flexDirection: 'row', flexShrink: '0', overflowX: 'hidden', direction: 'ltr'}}>
                        {CardList.map((item, idx) => {
                            return (
                                // <img src={example} alt={"example"} style={{margin: '10px'}}/>
                                //style={{width: `${100 / pageCapacity - 2}%`}}
                                <div
                                    className={'home-pro-t-layout-card-style2'}
                                    style={{
                                        transform: `translateX(-${100 * currentIndex}%)`,
                                        transition: 'transform 0.4s ease'
                                    }}
                                >
                                    <div style={{
                                        height: '100%',
                                        width: '100%',
                                        backgroundColor: '#F5F7F8',
                                        padding: '1.5vw',
                                        borderRadius: '15px',
                                    }}>
                                        <div style={{height: '1rem'}}></div>
                                        <p className={'home-pro-t-card-title'}>{item[0]}</p>
                                        <div style={{height: '1rem'}}></div>
                                        <p className={'home-pro-t-card-body'}>{item[1]}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div
                    className={'home-pro-t-layout-card-control'}
                    style={{ visibility: currentIndex !== 0 ? 'visible' : '' }}
                >
                    <ScrollLeftArrow onClick={() => {
                        if (currentIndex > 0) {
                            setCurrentIndex(currentIndex - 1)
                        }
                    }}/>
                    <div className={'spacer'}></div>
                    {CardList.map((item, index) =>
                        <div key={index} style={{margin: '0 2px'}}>
                            {currentIndex === index ?
                                <ScrollActive/> :
                                <ScrollInactive/>}
                        </div>
                    )}
                    <div className={'spacer'}></div>
                    <ScrollRightArrow onClick={() => {
                        if (currentIndex < (CardList.length - 1)) {
                            setCurrentIndex(currentIndex + 1)
                        }
                    }}/>
                </div>

                <div style={{height: '1vw'}}></div>
            </div>
        </div>
    </div>)
}

export function TLayoutMobile( {HeaderSection, CardList, BackgroundImg, BackgroundColor = '#fff', Reversed = false} ) {
    return (
        <div style={{backgroundColor: `${BackgroundColor}`}}>
            <div style={{height: '5rem'}}></div>
            <div style={{direction: Reversed ? 'rtl' : 'ltr'}}>{HeaderSection}</div>
            <div style={{
                display: 'flex',
                flexDirection: Reversed ? "row-reverse" : "row",
                height: "39rem",
                padding: '0 0 0 0',
                // backgroundColor: 'red',
            }}>

                <img
                    src={BackgroundImg}
                    style={{
                        objectFit: 'cover',
                        height: '80%',
                        width: '82vw',
                        display: 'flex',
                        flexDirection: 'column',
                        borderBottomRightRadius: Reversed ? '0' : '25px',
                        borderTopRightRadius: Reversed ? '0' : '25px',
                        borderBottomLeftRadius: Reversed ? '25px' : '0',
                        borderTopLeftRadius: Reversed ? '25px' : '0',
                        zIndex: 1,
                    }}
                />

                <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    width: '18vw',
                    zIndex: 2
                }}>
                    <div className={'spacer'}></div>

                    <div style={{display: 'flex', flexDirection: 'row', direction: Reversed ? 'ltr' : 'rtl'}}>
                        <div style={{
                            width: "87vw",
                            display: 'flex',
                            flexDirection: 'row',
                            flexShrink: '0',
                            overflowX: 'auto',
                            direction: Reversed ? 'rtl' : 'ltr',
                            // backgroundColor: 'red'
                        }}>
                            {CardList.map((item, idx) => {
                                return (
                                    // <img src={example} alt={"example"} style={{margin: '10px'}}/>
                                    //style={{width: `${100 / pageCapacity - 2}%`}}
                                    <div
                                        className={'home-pro-section-3-card-mobile'}
                                    >
                                        <div style={{
                                            height: '100%',
                                            backgroundColor: '#F5F7F8',
                                            padding: '1.5rem',
                                            borderRadius: '25px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start'
                                        }}>
                                            <div style={{height: '1rem'}}></div>
                                            <p className={'home-pro-section-3-card-title'}>{item[0]}</p>
                                            <div style={{height: '1rem'}}></div>
                                            <p className={'home-pro-section-3-card-body'}>{item[1]}</p>
                                            <div style={{height: '3vw'}}></div>
                                            {/*<div style={{height: '1rem'}}></div>*/}
                                            {/*<div className={'spacer'}></div>*/}
                                            {/*<p className={'home-pro-section-3-card-link'}>Learn more ></p>*/}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>


            </div>
            <div style={{height: '5rem'}}></div>
        </div>
    )
}

export function TLayoutMobileStyle2( {HeaderSection, CardList, BackgroundImg, BackgroundColor = '#fff', Reversed = false} ) {
    return (
        <div style={{backgroundColor: `${BackgroundColor}`}}>
            <div style={{height: '5rem'}}></div>
            <div style={{direction: Reversed ? 'rtl' : 'ltr'}}>{HeaderSection}</div>
            <div style={{
                display: 'flex',
                flexDirection: Reversed ? "row-reverse" : "row",
                height: "39rem",
                padding: '2rem 0 5rem 0',
                // backgroundColor: 'red',
            }}>

                <img
                    src={BackgroundImg}
                    style={{
                        objectFit: 'cover',
                        height: '100%',
                        width: '59vw',
                        display: 'flex',
                        flexDirection: 'column',
                        borderBottomRightRadius: Reversed ? '0' : '25px',
                        borderTopRightRadius: Reversed ? '0' : '25px',
                        borderBottomLeftRadius: Reversed ? '25px' : '0',
                        borderTopLeftRadius: Reversed ? '25px' : '0',
                        zIndex: 1,
                    }}
                />

                <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    width: '41vw',
                    zIndex: 2
                }}>
                    <div className={'spacer'}></div>

                    <div style={{display: 'flex', flexDirection: 'row', direction: Reversed ? 'ltr' : 'rtl'}}>
                        <div style={{
                            width: "87vw",
                            display: 'flex',
                            flexDirection: 'row',
                            flexShrink: '0',
                            overflowX: 'auto',
                            direction: Reversed ? 'rtl' : 'ltr',
                            // backgroundColor: 'red'
                        }}>
                            {CardList.map((item, idx) => {
                                return (
                                    // <img src={example} alt={"example"} style={{margin: '10px'}}/>
                                    //style={{width: `${100 / pageCapacity - 2}%`}}
                                    <div
                                        className={'home-pro-section-3-card-mobile'}
                                    >
                                        <div style={{
                                            height: '100%',
                                            backgroundColor: '#F5F7F8',
                                            padding: '1.5rem',
                                            borderRadius: '25px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start'
                                        }}>
                                            <div style={{height: '1rem'}}></div>
                                            <p className={'home-pro-section-3-card-title'}>{item[0]}</p>
                                            <div style={{height: '1rem'}}></div>
                                            <p className={'home-pro-section-3-card-body'}>{item[1]}</p>
                                            <div style={{height: '3vw'}}></div>
                                            <div style={{height: '1rem'}}></div>
                                            {/*<div className={'spacer'}></div>*/}
                                            {/*<p className={'home-pro-section-3-card-link'}>Learn more ></p>*/}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{height: '6vw'}}></div>
                </div>


            </div>
        </div>
    )
}