import React from 'react';
import {Form, Input, Button} from 'antd';
import './style.scss'
import {Link} from "react-router-dom";
import ProLogo from './imgs/ProLogo.svg';

const ProPasswordSaved = () => {

    return (
        <div style={{marginTop: '5vw'}}>
            <div className="ProLogo" style={{ marginBottom: '150px' }}>
                <img src={ProLogo} alt="ProLogo" className='ProLogo'/>
            </div>
            <div className="Title" style={{marginTop: '100px'}}>
                Your password has been saved successfully
            </div>
            <div className='ProPasswordSaved-Content'>
                <p className="ProPasswordSaved-text">
                    please click the button below to log in to your account
                </p>
                <div className="ProPasswordSaved-custom-OK-Button">
                    <Button type="primary"
                            onClick={_=>window.location.href='/business/prosignin'}
                            className="ProPasswordSaved-OK-Button">
                        Login
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ProPasswordSaved;
