import React from "react";
import {Helmet} from "react-helmet";

export const ProductDetailPageMeta = () => (
    <Helmet>
        <title>Textura Digital Canvas | Bring Your Creativity to Life</title>
        <meta name="description" content="Purchase the Textura digital canvas from Vieunite"/>
            <meta name="keywords"
                  content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>

            <meta name="twitter:card" value="summary_large_image"/>
            <meta name="twitter:site" value="@VieuniteHQ"/>
            <meta name="twitter:title" value="VT27WGV1 |  Textura Digital Canvas | Vieunite"/>
            <meta name="twitter:description" value=" Purchase the Textura digital canvas from Vieunite"/>
            <meta name="twitter:url" value="https://vieunite.com/digital-canvas"/>
            <meta name="twitter:image" value="https://vieunite.com/img/Social Card - Textura.jpg"/>

        <meta property="og:url" content="https://vieunite.com/digital-canvas"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="VT27WGV1 |  Textura Digital Canvas | Vieunite"/>
        <meta property="og:description" content=" Purchase the Textura digital canvas from Vieunite"/>
        <meta property="og:image" content="https://vieunite.com/img/Social Card - Textura.jpg"/>
        <meta property="og:image:width" content="930"/>
        <meta property="og:image:height" content="620"/>
    </Helmet>);