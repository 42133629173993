import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Image, Input, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import leftImg from '../../img/LoginSet/Photograph by Linwang Wang.webp'
import leftImg2 from '../../img/LoginSet/Artwork by Jo Vincent.webp'
import leftImg3 from '../../img/LoginSet/The Ivory Tower - Vantropolis.jpg'
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import './style.scss'
import {Primary_Font, TitleFont} from "../../settings";
import QR_Img from "../../img/AppDownload/QR_app.png";
import ShadowHeader from "../../components/Header/ShadowHeader";
import {login} from "./services";
import {useNavigate} from "react-router-dom";
import useWindowResize from "../Hooks/useWindowResize";
import {ReactComponent as Logo} from "../../img/LoginSet/Vieunite.svg";
import {ReactComponent as PortalLoginLogo} from "./Group 1944.svg";
import LoginModal from "./components/LoginModal";
import {NewHeader} from "../../components/NewHeader";

const bgArts = [{
    art: leftImg,
    name: 'Linwang Wang'
}, {
    art: leftImg2,
    name: 'Jo Vincent'
}, {
    art: leftImg3,
    name: 'Christian Redermayer'
}]
const get_random = (list) => {
    return list[Math.floor((Math.random() * list.length))];
}

function Login(props) {
    useEffect(() => {
        if (localStorage.getItem('token') && localStorage.getItem('userInfo')) {
            const storageInfo = JSON.parse(localStorage.getItem('userInfo'))
            const isArtist = storageInfo.isArtist
            if (isArtist) {
                window.location.href = '/artistportal/home'
            } else {
                //     window.location.href = '/artistportal/login'
            }
        }
    }, []);

    const [reminder, setReminder] = useState('')
    const [loginModalOpen, setLoginModalOpen] = useState(false)
    const [bgArt, setBgArt] = useState(get_random(bgArts))

    const navigate = useNavigate();

    const {windowWidth} = useWindowResize();
    const onFinish = (values) => {
        login(values, setReminder).catch(error => console.log('error', error))
    }

    return (
        <>
            <NewHeader></NewHeader>
            <Layout>
                <Content style={{overflow: windowWidth > 600 ? 'hidden' : "inherit", maxHeight: '100vh'}}>
                    <Row>
                        <Col lg={12} xs={0}>
                            <div className={'img-container'} style={{position:"relative"}}>
                                <img className={'img-left protected-img'}
                                     src={bgArt.art}
                                     alt={'background'}/>
                                <div className={'img-left-overlay'} style={{
                                    background: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    padding: "0 1rem",
                                    position:"absolute",
                                    zIndex: '20',
                                    bottom:'2rem',
                                    left:'0',
                                }}>

                                    <p className={'author-name'} style={{
                                        background: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        padding: "0 2rem",
                                        position:"absolute",
                                        zIndex: '20',
                                        bottom:'2rem',
                                        left:'2rem',
                                        width:'14rem',
                                        height:'8vh',
                                        columnGap:'1rem',
                                        borderRadius:'8px'
                                    }}>
                                        <Logo className={'logo-area'}
                                              style={{width: "50px", height: "95px"}}
                                        />
                                        <div>
                                            <span style={{fontWeight: 400}}>Artwork by</span><br/><span
                                            style={{fontWeight: '700'}}>{bgArt.name}</span>
                                        </div>
                                    </p>
                                </div>
                            </div>

                        </Col>

                        <Col lg={10} xs={24} className={'login-form-col'}>
                            <Row justify={windowWidth > 600 ? "center" : 'start'}
                                 style={{margin: windowWidth > 600 ? '15vh 0 0 0' : '15rem 0 0 5%'}}>
                                {/*<h2 className="title-app"*/}
                                {/*    style={windowWidth >= 600 ?*/}
                                {/*        {textAlign: "center", ...TitleFont,} :*/}
                                {/*        {textAlign: "left", ...TitleFont, fontSize: '2rem', lineHeight: '0'}}*/}
                                {/*>*/}
                                {/*    Artist Portal*/}
                                {/*</h2>*/}
                                <PortalLoginLogo className={'login-logo'} style={{width: '100%'}}/>
                            </Row>
                            {/*<Row justify={windowWidth > 600 ? "center" : 'start'}*/}
                            {/*     style={{margin: windowWidth > 600 ? '-1rem 0 -1rem 0' : '-1rem 0 1rem 5%'}}>*/}
                            {/*    <h2 className="title-app"*/}
                            {/*        style={windowWidth >= 600 ?*/}
                            {/*            {textAlign: "center", marginTop: '-4%', ...TitleFont,} :*/}
                            {/*            {textAlign: "left", ...TitleFont, lineHeight: '0'}*/}
                            {/*        }>*/}
                            {/*        <span style={{fontWeight: '300', fontSize: windowWidth > 600 ? '2rem' : '1.4rem'}}>Log In</span>*/}
                            {/*    </h2>*/}
                            {/*</Row>*/}
                            <Row justify={"center"}>
                                <Form
                                    name="normal_login"
                                    className="login-form"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    style={windowWidth >= 600 ? {width: '50%'} : {width: '90%'}}
                                >
                                    <Form.Item
                                        labelCol={{span: 24, style: {fontWeight: '600'}}}
                                        label={'Email'}
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your email!',
                                            },
                                        ]}
                                    >
                                        <Input className={'login-input-form'}
                                               prefix={<MailOutlined className="site-form-item-icon"/>}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        labelCol={{span: 24, style: {fontWeight: '600'}}}
                                        label={'Password'}
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Password!',
                                            },
                                        ]}
                                    >
                                        <Input className={'login-input-form'}
                                               prefix={<LockOutlined className="site-form-item-icon"/>}
                                               type="password"
                                        />
                                    </Form.Item>
                                    <p style={{
                                        color: "red"
                                    }}>{reminder}</p>
                                    <p className="login-form-forgot"
                                       style={{cursor: "pointer"}}
                                       onClick={() => navigate('/artistportal/forgotpassword')}>
                                        Forgot password?
                                    </p>
                                    <Form.Item>
                                        <Button htmlType="submit" className="login-form-button">
                                            LOG IN >
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Row>
                            <Row justify={"center"} style={{}}>
                                <Col lg={12} xs={22}>
                                    <p style={{...Primary_Font}}>
                                        <span
                                            onClick={windowWidth > 600 ?
                                                () => {
                                                    setLoginModalOpen(true)
                                                } :
                                                () => {
                                                    navigate('/artistportal/mapply')
                                                }}
                                            style={{
                                                textDecoration: "underline",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }}>Apply here</span> to
                                        become an official Vieunite Artist
                                    </p>
                                </Col>
                            </Row>

                            {/*<Divider style={{width: '1000%'}}></Divider>*/}
                            {/*<Row justify={"center"} style={{margin: '-2% 0 -2% 0'}}>*/}
                            {/*    <Col lg={12} xs={22}>*/}
                            {/*        <p*/}
                            {/*            style={{*/}
                            {/*                ...Primary_Font,*/}
                            {/*                textAlign: "left",*/}
                            {/*                marginTop: '0%',*/}
                            {/*                fontSize: windowWidth > 600 ? '1.4vw' : '6vw',*/}
                            {/*                fontWeight: '400'*/}
                            {/*            }}>*/}
                            {/*            Download the Vieunite <span style={{fontWeight: '600'}}>App.</span>*/}
                            {/*        </p>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row justify={"center"} className={'m-only-row'}>*/}
                            {/*    <Col xs={22}>*/}
                            {/*        <Button type={"primary"}*/}
                            {/*                style={{*/}
                            {/*                    width: '80%'*/}
                            {/*                }}*/}
                            {/*                onClick={() => {*/}
                            {/*                    if ((/Android/i.test(navigator.userAgent))) {*/}
                            {/*                        window.location.href = 'https://play.google.com/store/apps/details?id=com.app.vieunite&gl=GB';*/}
                            {/*                    }*/}
                            {/*                    if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {*/}
                            {/*                        window.location.href = 'https://apps.apple.com/gb/app/vieunite/id1644381065';*/}
                            {/*                    }*/}
                            {/*                }}*/}
                            {/*        >*/}
                            {/*            VIEUNITE APP >*/}
                            {/*        </Button>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}

                            {/*<Row justify={"center"} className={'m-none'}>*/}
                            {/*    <Image*/}
                            {/*        src={QR_Img}*/}
                            {/*        preview={false}*/}
                            {/*        height={'20%'}*/}
                            {/*        width={'14%'}*/}
                            {/*        style={{marginLeft: '-7.5vw'}}*/}
                            {/*    />*/}
                            {/*</Row>*/}
                            <Row className={'primary-font-footer'} style={{
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '14vh',
                                fontSize: '1rem',
                                fontWeight: '600',
                                width: '100%',
                                marginBottom: '0'
                            }}>
                                <a href="/terms-and-conditions"
                                   style={{color: '#A9A9A9', fontWeight: '600', fontSize: '1rem'}}
                                   className="footer-text">
                                    Terms & Conditions
                                </a>
                                <a href="/end-user-agreement"
                                   style={{color: '#A9A9A9', fontWeight: '600', fontSize: '1rem'}}
                                   className="footer-text">
                                <span style={{
                                    marginLeft: '2vw',
                                }}>
                                End User Agreement
                                </span>
                                </a>
                                <a href="/ArtistsTermsAndConditions"
                                   style={{color: '#A9A9A9', fontWeight: '600', fontSize: '1rem'}}
                                   className="footer-text">
                                <span style={{
                                    marginLeft: '2vw',
                                }}>
                                Artist's Terms & Conditions
                                </span>
                                </a>
                            </Row>
                        </Col>
                    </Row>

                    <LoginModal loginModalOpen={loginModalOpen}
                                setLoginModalOpen={setLoginModalOpen}
                                setReminder={setReminder}
                                reminder={reminder}
                    ></LoginModal>
                </Content>
            </Layout>
        </>
    )
}

export default Login;