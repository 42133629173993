import React from 'react';
import './style.scss'
import {ReactComponent as Logo} from "./new_logo.svg";
import useWindowResize from "../../../apps/Hooks/useWindowResize";
import {Col, Row} from "antd";
import MobileMenu from "../../../components/MobileMenu/MobileMenu";
import ProMobileMenu from "../ProMobileMenu";

const ProHeader = (props) => {
    const {windowWidth} = useWindowResize()
    return (
        windowWidth > 600 ?
            <div className={'tou'}>
                <div className={'top-blue-bar'}>
                    <a href="/home" className="tab">For Home</a>
                    <a href="/business" className="tab tab-active">For Business</a>
                </div>
                <div className={'pro-header'}>
                    <div className={'logo'}
                         onClick={() => window.location.href = "/business/home"}
                         style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Logo style={{width: '10vw', height: 'auto'}}/>
                    </div>
                    <div>
                        <ul className={'pro-menu'}
                            style={{margin: '5px 0 0 -8rem', columnGap: '3rem'}}>
                            <li>
                                <a href="/business/home" className={"hover-underline-animation jing-header-h"}
                                   style={{color: "black", fontWeight: '600', fontSize: '0.9vw'}}>
                                    Home
                                </a>
                            </li>
                            <li>
                                <a href="/business/textura-pro" className={"hover-underline-animation jing-header-h"}
                                   style={{color: "black", fontWeight: '600', fontSize: '0.9vw'}}>
                                    Textura Pro
                                </a>
                            </li>
                            <li>
                                <a href="/business/subscription" className={"hover-underline-animation jing-header-h"}
                                   style={{color: "black", fontWeight: '600', fontSize: '0.9vw'}}>
                                    Subscription
                                </a>
                            </li>
                            <li>
                                <a href="/business/software" className={"hover-underline-animation jing-header-h"}
                                   style={{color: "black", fontWeight: '600', fontSize: '0.9vw'}}>
                                    Software
                                </a>
                            </li>
                            <li>
                                <a href="/business/curated-collections"
                                   className={"hover-underline-animation jing-header-h"}
                                   style={{color: "black", fontWeight: '600', fontSize: '0.9vw'}}>
                                    Curated Collections
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={'logo'}>
                        {!localStorage.getItem('token') &&
                            <a href="/business/prologin" className={"hover-underline-animation"}
                               style={{color: "black", fontWeight: '600', fontSize: '0.9vw'}}
                            >
                                Login
                            </a>}
                        {localStorage.getItem('token') && <div>
                            <div className={"hover-underline-animation"}
                                 style={{color: "black", fontWeight: '600', fontSize: '0.9vw', cursor: "pointer"}}
                                 onClick={() => {
                                     window.location.href = '/proportal'
                                 }}
                            >
                                Vieunite Pro Portal
                            </div>
                            <span style={{
                                margin: "auto 20px",
                                height: '30px'
                            }}>|</span>
                            <div className={"hover-underline-animation"}
                                 style={{color: "black", fontWeight: '600', fontSize: '0.9vw', cursor: "pointer"}}
                                 onClick={() => {
                                     localStorage.clear();
                                     window.location.href = '/business'
                                 }}
                            >
                                Logout
                            </div>
                        </div>

                        }
                    </div>
                </div>
            </div> :
            <Row className={'mobile-header'} justify={'center'}>
                <Col lg={0} xs={20} style={{position: "relative", fontSize: 'x-large'}}>
                    <ProMobileMenu></ProMobileMenu>
                </Col>
            </Row>

    )
}

export default ProHeader;
