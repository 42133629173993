import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal, Row} from 'antd';
import "./style.scss"
import {getBalance} from "../BalanceBoard/service";
import useWindowResize from "../../../Hooks/useWindowResize";
import {withdrawMoney} from "./service";

const WithdrawModal = (props) => {
    const {isModalOpen, setIsModalOpen} = props;
    const {isInsufficient, setIsInsufficient} = useState(false)
    const [finance, setFinance] = useState({})

    const [processing, setProcessing] = useState(false)

    const [form] = Form.useForm();
    useEffect(() => {
        getBalance().then((res) => {
            setFinance({...res})
        })
    }, []);

    const onFinish = async (fieldsValue) => {
        const withdraw_num = parseFloat(form.getFieldValue('withdraw'))
        const balance = parseFloat(form.getFieldValue(finance?.balance ?? '0'))
        if (withdraw_num > balance) {
            setIsInsufficient(true);
        } else {
            await setProcessing(true)
            await withdrawMoney(withdraw_num).then((res) => {
                message.success('Please check your email to process your withdrawal.'
                    , 3,
                    () => window.location.reload())
            })
        }
    }

    return (
        <>
            <Modal title={
                <p style={{fontSize: '2rem', textAlign: "center", marginTop: '-5%'}}>
                    <span style={{fontWeight: 500}}>Vieunite</span> Balance.
                </p>}
                   visible={isModalOpen}
                   onCancel={() => {
                       setIsModalOpen(false)
                   }}
                   footer={null}
                   className={'charge-modal'}
                   width={window.innerWidth > 600 ? '40%' : '100%'}
            >
                <Row justify={"center"}>
                    <p style={{fontWeight: '600', fontSize: '0.75rem',}}>AVAILABLE BALANCE</p>
                </Row>
                <Row justify={"center"}>
                    <p style={{
                        fontWeight: '500',
                        fontSize: '1rem'
                    }}>{'£' + parseFloat(finance?.balance).toString() ?? '0'}</p>
                </Row>
                <Row justify={"center"} style={{padding: '1.4rem 0 0 0'}}>
                    <p style={{fontWeight: '600', fontSize: '0.75rem',}}>AMOUNT TO WITHDRAW</p>
                </Row>
                <Row justify={"center"} style={{marginBottom: '1rem'}}>
                    <Form
                        form={form}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="withdraw"
                            rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/^(?:[1-9]\d{0,3}|0)(?:\.\d{1,3})?$/),
                                    message: 'Please Input a valid number!'
                                },
                            ]}
                        >
                            <Input
                                bordered={true}
                                prefix="£"
                                style={{
                                    fontWeight: 400,
                                    fontSize: '1.6rem',
                                    width: '100%'
                                }}
                            ></Input>
                        </Form.Item>
                    </Form>
                </Row>
                <Row className={'next-btn'} justify={"center"}>
                    <Button type={"primary"}
                            loading={processing}
                            onClick={() => {
                                form.submit();
                            }}>NEXT</Button>
                </Row>
                {isInsufficient ? <Row justify={"center"}>
                    <p style={{color: "red"}}>X INSUFFICIENT FUNDS</p>
                </Row> : <></>}
            </Modal>
        </>
    );
};

export default WithdrawModal;