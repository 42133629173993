import React from "react";
import {Helmet} from "react-helmet";

export const A9Meta = () => (
    <Helmet>
        <title>Linwang Wang | Vieunite</title>
        <meta name="description"
              content="Shanghai based photographer who has been working in the fashion industry for over four years and is inspired by his partner, a screenwriter who was born and raised in Shanghai. "
        />
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Linwang Wang"/>
        <meta name="twitter:description"
              content="Linwang Wang's elegant digital photography feature graceful compositions documenting the working class residences of Shanghai. Discover his serene and captivating works on Vieunite's art platform. "/>
        <meta name="twitter:url" value="https://vieunite.com/artists/linwang-wang"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_linwang_wang.jpg"/>
        <meta property="og:url" content="https://vieunite.com/artists/linwang-wang"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Artist | Linwang Wang"/>
        <meta property="og:description"
              content="Linwang Wang's elegant digital photography feature graceful compositions documenting the working class residences of Shanghai. Discover his serene and captivating works on Vieunite's art platform. "/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_linwang_wang.jpg"/>
    </Helmet>);