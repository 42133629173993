import React, { useState, useEffect } from 'react';
import { Card, Button } from 'antd';
import './CommonCardView.css';
import { useNavigate } from "react-router-dom";

const { Meta } = Card;
const ArtworkCard = (props) => {
    const navigate = useNavigate();
    const pageType = props.item.pageType
    const onClick = () => {
        if (pageType === "artist") {
            navigate('/proportal/discover-art/author-details', { state: { pageType: pageType, info: { artist: props.item } } })
        } else if (pageType === "gallery") {
            navigate('/proportal/discover-art/gallery-details', { state: { gallery: props.item } })
        } else if (pageType === "album") {
            navigate('/proportal/discover-art/album-details', { state: { album: props.item } })
        }
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {/* <Switch checked={!loading} onChange={onChange} /> */}
            <Card
                hoverable
                className='card-main2'
                cover={<img alt={props.item.name} src={props.item.url} style={{
                    width: 200,
                    height: 200,
                    marginLeft: 20,
                    marginTop: 20,
                    marginRight: 20,
                    marginBottom: 0
                }} />}
                onClick={onClick}
            >
                {/* <Meta title={props.item.name} style={{ textAlign: "center" }}/> */}
                <p className='discovery-card-title' style={{ marginTop: -5, marginLeft: -10, marginRight: -10 }}> {props.item.name} </p>
                {/* <button className="btn" type="primary" >
                    <a href={"/digital-canvas"}>
                        View Collections
                    </a>
                </button> */}
                <Button className="ml-up-btn" style={{ fontSize: "16px", fontWeight: 700, borderRadius: "5px", position: "absolute", bottom: 0, width: "85%", marginLeft: -7, marginBottom: 20 }}>View Collections</Button>
            </Card>
        </div>
    );
};

export default ArtworkCard;