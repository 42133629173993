import React, {useEffect, useState} from 'react';
import {Layout, Row, Col, Modal, Button, List, Avatar, Table, Space, Tag, Image, Divider} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import {useLocation, useNavigate} from "react-router-dom";
import {Primary_Font, TitleFont} from '../../../../settings'
import {BackButton} from "../TransactionHistory/index";
import Logo from "../../../../img/ArtistPortal/lg.png"
import {getWithdrawHistory} from "./service";
import {getBalance} from "../BalanceBoard/service";

function WithdrawHistory() {
    const navigate = useNavigate()
    const {state} = useLocation();
    const [hist, setHist] = useState([])
    const [total, setTotal] = useState('0')
    useEffect(() => {
        getWithdrawHistory()
            .then((res) => {
                setHist(res)
            })
    }, [])
    useEffect(() => {
        getBalance()
            .then((res) => {
                console.log(res)
                const totalWithdrew =
                    (parseFloat(res.totalRevenue ?? 0) -
                    parseFloat(res.balance ?? 0) -
                    parseFloat(res.vat ?? 0) -
                    parseFloat(res.vieuniteFee ?? 0)).toFixed(2)
                setTotal(totalWithdrew)
            })
    }, [])
    const columns = [
        window.innerWidth > 600 ? {
            title: 'Img',
            // dataIndex: 'src',
            key: 'img',
            render: (src) => <div style={{
                height: "6rem",
                width: "6rem",
                maxHeight: "100%",
                maxWidth: "100%",
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <img src={Logo}
                     alt={'art'}
                     style={{
                         width: "6rem",
                         height: "6rem",
                         objectFit: 'contain',
                     }}
                />
            </div>,
        } : {},
        {
            title: 'Date',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (t) => <>
                <p><span style={{fontWeight: 'bold'}}>Vieunite account</span> withdraw</p>
                <p>{t}</p>
            </>
        },
        {
            title: 'Amount',
            key: 'amount',
            dataIndex: 'amount',
            render: (amt) => <p style={{color: "red", width: '110%'}}>-£{amt}</p>
        },
    ];
    return (
        <Layout>
            <Content className={'grey-bk'}>
                <BackButton/>
                <Row justify={"center"} style={{padding: '0.5rem 0 6rem 0'}}>
                    <Col lg={20} xs={24} style={{
                        backgroundColor: 'white',
                        padding: '2rem',
                        borderRadius: '20px'
                    }}>
                        <h2 className="title-app mobile-left"
                            style={{textAlign: "left", ...TitleFont}}>
                            <span style={{fontWeight: '500'}}>Withdraw</span> History.
                        </h2>
                        <Col lg={6} xs={20} style={{rowGap: '5rem'}}>
                            <Row style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <span style={{fontWeight: '600'}}>Total amount withdrew:</span>
                                <span style={{fontWeight: '600'}}>{'£' + total}</span>
                            </Row>

                            <Row className={'withdraw-btn'}>
                                <Button onClick={() => {
                                    navigate('/artistportal/transactionhistory')
                                }}>
                                    TRANSACTION HISTORY
                                </Button>
                            </Row>
                        </Col>
                        <Divider></Divider>
                        <Table className={'art-table'}
                               columns={columns}
                               dataSource={hist}
                               showHeader={false}
                               bordered={false}
                        />
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default WithdrawHistory;
