import React from 'react';
import './TermsEnd.css';
import {TERMS_TEXT} from "../../text";


function TitleLogo() {
    return (
        <h2 className="title-app" style={{
            textAlign: "left",
            fontSize: '1.8rem',
            marginTop:'12vh'
        }}>Terms & Conditions</h2>
    );
}

function SubTitle(props) {
    return (
        <div id={props.id} className="sub_title">{props.title}</div>
    );
}

function SubSubTitle(props) {
    return (
        <div className="sub_sub_title">{props.title}</div>
    );
}

function Content(props) {
    return (
        <div className="content">{props.content}</div>
    );
}

function AllContent(props) {
    let content = props.content.slice();
    let output;
    if (typeof content === 'string') {
        output = <Content content={content}/>;
    } else {
        output = content.map((item) => (
            typeof item === 'string' ? <Content content={item}/> :
                <ul className="content">{item.map((item) => <li>{item}</li>)}</ul>
        ));
    }

    return (
        output
    );
}

function Other_Terms(props) {
    let content = props.content.slice();
    let output;
    output = content.map((item) => (
        <div className="content">
            <strong>
                {item[0]}
            </strong>
            {item[1]}
        </div>
    ));
    return (
        output
    );
}

function scrollDown(id) {
    window.location = id;
    window.scrollBy(0, -200);
}

function Table_content() {
    return (
        <ul className="content">
            <li>
                <div className="table_li" onClick={() => scrollDown("#INTRODUCTION")}>INTRODUCTION</div>
            </li>
            <li>
                <div className="table_li" onClick={() => scrollDown("#CONTRACTPARTIES")}>CONTRACT PARTIES</div>
            </li>
            <li>
                <div className="table_li" onClick={() => scrollDown("#HOWTOCONTACTUS")}>HOW TO CONTACT US</div>
            </li>
            <li>
                <div className="table_li" onClick={() => scrollDown("#ORDERS")}>ORDERS</div>
            </li>
            <li>
                <div className="table_li" onClick={() => scrollDown("#PRODUCTS")}>PRODUCTS</div>
            </li>
            <li>
                <div className="table_li" onClick={() => scrollDown("#PRICING")}>PRICING</div>
            </li>
            <li>
                <div className="table_li" onClick={() => scrollDown("#PAYMENT")}>PAYMENT</div>
            </li>
            <li>
                <div className="table_li" onClick={() => scrollDown("#DELIVERY")}>DELIVERY & INSURANCE</div>
            </li>
            <li>
                <div className="table_li" onClick={() => scrollDown("#ARTWORK")}>ARTWORK</div>
            </li>
            <li>
                <div className="table_li" onClick={() => scrollDown("#RETURNS")}>YOUR RIGHTS TO RETURN PRODUCTS</div>
            </li>
            <li>
                <div className="table_li" onClick={() => scrollDown("#FAULTYPRODUCTS")}>FAULTY PRODUCTS</div>
            </li>
            <li>
                <div className="table_li" onClick={() => scrollDown("#OURLIABILITY")}>OUR LIABILITY</div>
            </li>
            <li>
                <div className="table_li" onClick={() => scrollDown("#OTHER")}>OTHER IMPORTANT TERMS</div>
            </li>
        </ul>
    );
}


function TermsAndConditions() {
    return (
        <div className="whole_div">
            <TitleLogo/>
            <SubTitle id="INTRODUCTION" title="INTRODUCTION"/>
            <AllContent content={TERMS_TEXT.Introduction_Before_List}/>
            <Table_content/>
            <AllContent content={TERMS_TEXT.Introduction_After_List}/>
            <SubTitle id="CONTRACTPARTIES" title="CONTRACT PARTIES"/>
            <AllContent content={TERMS_TEXT.CONTRACT_PARTIES}/>
            <SubTitle id="HOWTOCONTACTUS" title="HOW TO CONTACT US"/>
            <AllContent content={TERMS_TEXT.HOW_TO_CONTACT_US}/>
            <SubTitle id="ORDERS" title="ORDERS"/>
            <SubSubTitle title="How we will accept your order"/>
            <AllContent content={TERMS_TEXT.ORDERS_how_we_will_accept_your_order}/>
            <SubSubTitle title="If we cannot accept your order"/>
            <AllContent content={TERMS_TEXT.ORDERS_if_we_cannot_accept_your_order}/>
            <SubSubTitle title="You must be 18 or over"/>
            <AllContent content={TERMS_TEXT.ORDERS_you_must_be_18_or_over}/>
            <SubTitle id="PRODUCTS" title="PRODUCTS"/>
            <SubSubTitle title="Products may vary slightly from their pictures on the Platform"/>
            <AllContent
                content={TERMS_TEXT.PRODUCTS_products_may_vary_slightly_from_their_pictures_on_the_Platform}/>
            <SubSubTitle title="Product packaging may vary"/>
            <AllContent content={TERMS_TEXT.PRODUCTS_product_packaging_may_vary}/>
            <SubTitle id="PRICING" title="PRICING"/>
            <SubSubTitle title="Prices on the Platform"/>
            <AllContent content={TERMS_TEXT.PRICING_prices_on_the_Platform}/>
            <SubSubTitle title="What happens if the price is wrong"/>
            <AllContent content={TERMS_TEXT.PRICING_what_happens_if_the_price_is_wrong}/>
            <SubSubTitle title="Import duties"/>
            <AllContent content={TERMS_TEXT.PRICING_import_duties}/>
            <SubTitle id="PAYMENT" title="PAYMENT"/>
            <SubSubTitle title="When will payment be taken"/>
            <AllContent content={TERMS_TEXT.PAYMENT_when_will_payment_be_taken}/>
            <SubSubTitle title="Payment security"/>
            <AllContent content={TERMS_TEXT.PAYMENT_payment_security}/>
            <SubTitle id="DELIVERY" title="DELIVERY & INSURANCE"/>
            <SubSubTitle title="Delivery costs"/>
            <AllContent content={TERMS_TEXT.DELIVERY_delivery_costs}/>
            <SubSubTitle title="When will you get your Products?"/>
            <AllContent content={TERMS_TEXT.DELIVERY_when_will_you_get_your_products}/>
            <SubSubTitle title="Signature required on delivery"/>
            <AllContent content={TERMS_TEXT.DELIVERY_signature_required_on_delivery}/>
            <SubSubTitle title="Your Product will be fully insured during delivery"/>
            <AllContent content={TERMS_TEXT.DELIVERY_your_product_will_be_fully_insured_during_delivery}/>
            <SubSubTitle title="Shipping restrictions"/>
            <AllContent content={TERMS_TEXT.DELIVERY_shipping_restrictions}/>
            <SubSubTitle title="PERMITTED USE OF PRODUCTS"/>
            <AllContent content={TERMS_TEXT.DELIVERY_PERMITTED_USE_OF_PRODUCTS}/>
            <SubTitle id="ARTWORK" title="ARTWORK"/>
            <AllContent content={TERMS_TEXT.ARTWORK}/>
            <SubTitle id="RETURNS" title="YOUR RIGHTS TO RETURN PRODUCTS"/>
            <SubSubTitle title="Your right to return Products if you change your mind."/>
            <AllContent
                content={TERMS_TEXT.YOUR_RIGHTS_TO_RETURN_PRODUCTS_your_right_to_return_products_if_you_change_your_mind}/>
            <SubSubTitle title="Our return process."/>
            <AllContent content={TERMS_TEXT.YOUR_RIGHTS_TO_RETURN_PRODUCTS_our_return_process}/>
            <SubSubTitle title="Your right to receive a refund."/>
            <AllContent content={TERMS_TEXT.YOUR_RIGHTS_TO_RETURN_PRODUCTS_your_right_to_receive_a_refund}/>
            <SubSubTitle title="Your right to make changes to the order."/>
            <AllContent
                content={TERMS_TEXT.YOUR_RIGHTS_TO_RETURN_PRODUCTS_your_right_to_make_changes_to_the_order}/>
            <SubTitle id="FAULTYPRODUCTS" title="FAULTY PRODUCTS"/>
            <SubSubTitle title="Your right to return Products that are faulty or not as described."/>
            <AllContent
                content={TERMS_TEXT.FAULTY_PRODUCTS_your_right_to_return_products_that_are_faulty_or_not_as_described}/>
            <SubSubTitle title="FAULTY GOODS"/>
            <AllContent content={TERMS_TEXT.FAULTY_PRODUCTS_FAULTY_GOODS}/>
            <SubSubTitle title="How to benefit from the Guarantee"/>
            <AllContent content={TERMS_TEXT.FAULTY_PRODUCTS_how_to_benefit_from_the_guarantee}/>
            <SubSubTitle title="Returning the Product to us"/>
            <AllContent content={TERMS_TEXT.FAULTY_PRODUCTS_returning_the_product_to_us}/>
            <SubSubTitle title="Exceptions from the Guarantee"/>
            <AllContent content={TERMS_TEXT.FAULTY_PRODUCTS_exceptions_from_the_guarantee}/>
            <SubTitle id="OURLIABILITY" title="OUR LIABILITY"/>
            <SubSubTitle title="We are responsible to you for foreseeable loss and damage caused by us"/>
            <AllContent
                content={TERMS_TEXT.OUR_LIABILITY_we_are_responsible_to_you_for_foreseeable_loss_and_damage_caused_by_us}/>
            <SubSubTitle title="We are not liable for business loss"/>
            <AllContent content={TERMS_TEXT.OUR_LIABILITY_we_are_not_liable_for_business_loss}/>
            <SubSubTitle
                title="We do not exclude or limit in any way our liability to you where it would be unlawful to do so"/>
            <AllContent
                content={TERMS_TEXT.OUR_LIABILITY_we_do_not_exclude_or_limit_in_any_way_our_liability_to_you_where_it_would_be_unlawful_to_do_so}/>
            <SubTitle id="OTHER" title="OTHER IMPORTANT TERMS"/>
            <Other_Terms content={TERMS_TEXT.OTHER_IMPORTANT_TERMS}/>
            <div className="sub_title" id="OTHER">PSTI STATEMENT OF COMPLIANCE</div>
            <div className="content">We, VIEUNITE. LTD. hereby declare that under our own responsibility, our devices
                fully comply with the applicable security requirements in Schedule 1 of The Product Security and
                Telecommunications Infrastructure (Security Requirements for Relevant Connectable Products) Regulations
                2023.
            </div>
            <div className="content">Schedule 1 specifies security requirements that relate to manufacturers of relevant
                connectable products which includes the Vieunite brand and its subsidiaries. This includes the
                following:
            </div>
            <div className="content">
                <ol>
                    <li>Our products do not utilise passwords, it inherently meets the requirement of either maintaining
                        unique passwords per product or providing the capability for users to define passwords, thus
                        fulfilling the prescribed legal obligation.
                    </li>
                    <li>Users can report vulnerabilities to Vieunite by emailing us at <a
                        href="mailto:support@vieunite.com">support@vieunite.com</a>. Furthermore, users will receive
                        acknowledgment of the receipt of a security issues report and status updates until the reported
                        security issue is resolved.
                    </li>
                    <li>Vieunite will provide security updates for our products during the pre-defined support period.
                        The defined support period will end 1 year after the product’s end-of-life date. Up-to-date
                        information concerning the defined support periods for the entire Vieunite product range are
                        listed on this page. For further information regarding the products that have entered End of
                        Life, please see the relevant section on this page.
                    </li>
                </ol>
            </div>
        </div>
    )
        ;

}

export default TermsAndConditions;