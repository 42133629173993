import React, {useEffect, useRef} from 'react';
import "./SupportMain.scss"
import "./ProSupportRequestDemoForm.scss"
import {Layout, Row, Col, Divider, List, Typography} from "antd";
import {useMediaQuery} from "react-responsive";


const {Content} = Layout;
const h1_content = 'Experience Art that Transforms Business Spaces';
const body_line_1 = 'Connect with a Vieunite expert to explore how Vieunite Pro can bring captivating, curated art to your digital displays:';
const body_line_2 = 'Discover how Vieunite Pro can help you create meaningful, immersive spaces through art.';
const body_item = [
    "Elevate environments across hospitality, healthcare, retail, and corporate spaces with art that speaks",
    "Access curated playlists and exclusive artist collections tailored to your brand and audience",
    "Effortlessly manage and schedule digital artwork for a truly engaging visual experience",
];
const icon = <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
    <g clip-path="url(#clip0_3554_659)">
        <path
            d="M14.8496 1.34961H12.1496C6.18493 1.34961 1.34961 6.18493 1.34961 12.1496V14.8496C1.34961 20.8143 6.18493 25.6496 12.1496 25.6496H14.8496C20.8143 25.6496 25.6496 20.8143 25.6496 14.8496V12.1496C25.6496 6.18493 20.8143 1.34961 14.8496 1.34961Z"
            stroke="#936846" stroke-width="2.7"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M11.4336 18.7777L10.4751 17.8192L7.14062 14.4847L9.04412 12.5812L11.4336 14.9707L17.2656 9.13867L19.1691 11.0422L12.3786 17.8327L11.4201 18.7912L11.4336 18.7777Z"
              fill="#936846"/>
    </g>
    <defs>
        <clipPath id="clip0_3554_659">
            <rect width="27" height="27" fill="white"/>
        </clipPath>
    </defs>
</svg>


function ProSupportRequestDemoForm(props) {
    useEffect(() => {
    }, []);
    const isMobile = useMediaQuery({query: '(max-width: 1000px)'});

    return (<div>
        <Layout>
            <Content className='pro-support-demo-content'>
                <p style={{
                    width: '100%',
                    color: '#6A6A6A',
                    fontSize: '1rem',
                    fontWeight: '400',
                    paddingLeft: '10vw'
                }}>
                    <a href='/pro/support' style={{cursor: 'pointer', color: '#6A6A6A'}}>Support</a> / Request
                    Demo
                </p>
                <div className='pro-support-demo-out'>
                    <Row justify={"center"} gutter={50} className='pro-support-demo-in'>
                        <Col xs={20} sm={24} md={24} lg={12} xl={12} className='support-demo'>
                            <svg style={{margin: '4vh 0 0 0'}}
                                 xmlns="http://www.w3.org/2000/svg" width="189"
                                 height="62" viewBox="0 0 189 62"
                                 fill="none">
                                <path
                                    d="M12.1658 32.3521L0 0.861084H7.66192L16.3646 25.0491L25.0493 0.861084H32.7291L20.5454 32.3521H12.1658Z"
                                    fill="black"/>
                                <path
                                    d="M34.8643 3.48106C34.8643 1.48932 36.4792 0 38.453 0C40.4268 0 42.0417 1.56109 42.0417 3.48106C42.0417 5.40102 40.4268 7.06978 38.453 7.06978C36.4792 7.06978 34.8643 5.50868 34.8643 3.48106ZM35.4564 9.546H41.4675V32.2985H35.4564V9.546Z"
                                    fill="black"/>
                                <path
                                    d="M45.918 20.9222C45.918 14.319 50.7986 8.95386 57.7249 8.95386C64.6511 8.95386 69.1549 13.996 69.1549 21.5144V22.8601H52.1982C52.6289 25.7491 54.8718 28.1176 58.7476 28.1176C62.6235 28.1176 63.3591 27.2563 64.8126 25.8029L67.4503 29.6608C65.1356 31.7602 61.5468 32.8188 58.0658 32.8188C51.2472 32.8727 45.9359 28.2073 45.9359 20.9043M57.7787 13.7269C54.0823 13.7269 52.4674 16.5081 52.1982 18.769H63.413C63.2515 16.5619 61.6904 13.7269 57.7787 13.7269Z"
                                    fill="black"/>
                                <path
                                    d="M88.838 32.3524V29.4635C87.2769 31.2399 84.4956 32.8908 80.7993 32.8908C77.1029 32.8908 73.4424 30.1095 73.4424 25.7492V9.54614H79.4535V23.3807C79.4535 26.5387 81.0684 27.5615 83.6343 27.5615C86.2003 27.5615 87.7614 26.2696 88.838 24.9776V9.63586H94.8491V32.3883L88.838 32.3345V32.3524Z"
                                    fill="black"/>
                                <path
                                    d="M116.203 32.3524V18.5717C116.203 15.4137 114.588 14.3371 112.022 14.3371C109.456 14.3371 107.842 15.6828 106.819 17.0107V32.3524H100.808V9.54612H106.819V12.4889C108.326 10.7125 111.053 9.00781 114.804 9.00781C118.554 9.00781 122.214 11.8429 122.214 16.3109V32.4063L116.203 32.3524Z"
                                    fill="black"/>
                                <path
                                    d="M127.471 3.48106C127.471 1.48932 129.086 0 131.059 0C133.033 0 134.648 1.56109 134.648 3.48106C134.648 5.40102 133.033 7.06978 131.059 7.06978C129.086 7.06978 127.471 5.50868 127.471 3.48106ZM128.063 9.546H134.074V32.2985H128.063V9.546Z"
                                    fill="black"/>
                                <path
                                    d="M141.144 26.6641V14.7495H137.394V9.54589H141.144V3.37329H147.155V9.59972H151.766V14.8034H147.155V25.0492C147.155 26.4488 147.908 27.5792 149.254 27.5792C150.6 27.5792 151.031 27.2562 151.354 26.8794L152.646 31.3832C151.784 32.1907 150.116 32.8905 147.657 32.8905C143.476 32.8905 141.162 30.6834 141.162 26.6641"
                                    fill="black"/>
                                <path
                                    d="M154.458 20.9222C154.458 14.319 159.339 8.95386 166.265 8.95386C173.191 8.95386 177.695 13.996 177.695 21.5144V22.8601H160.738C161.169 25.7491 163.412 28.1176 167.288 28.1176C171.163 28.1176 171.899 27.2563 173.353 25.8029L175.99 29.6608C173.676 31.7602 170.087 32.8188 166.606 32.8188C159.733 32.8727 154.476 28.2073 154.476 20.9043M166.319 13.7269C162.622 13.7269 161.007 16.5081 160.738 18.769H171.953C171.792 16.5619 170.23 13.7269 166.319 13.7269Z"
                                    fill="black"/>
                                <path
                                    d="M181.284 29.0329C181.284 26.9335 183.061 25.175 185.142 25.175C187.224 25.175 189 26.9515 189 29.0329C189 31.1144 187.224 32.8908 185.142 32.8908C183.043 32.8908 181.284 31.1144 181.284 29.0329Z"
                                    fill="black"/>
                                <path
                                    d="M111.843 60.9545L116.975 37.6458H125.157C128.817 37.6458 131.276 40.3732 131.276 43.4774C131.276 46.5817 128.835 51.2649 122.645 51.2649H116.006L113.835 60.9365H111.843V60.9545ZM122.878 49.4526C127.041 49.4526 129.14 46.6893 129.14 43.621C129.14 40.5526 127.328 39.4581 124.834 39.4581H118.572L116.365 49.4526H122.86H122.878Z"
                                    fill="#0E477A"/>
                                <path
                                    d="M145.595 60.9545L141.199 51.3188H135.924L133.789 60.9545H131.797L136.929 37.6458H144.932C148.072 37.6458 151.248 39.781 151.248 43.5133C151.248 47.2456 148.108 51.0676 143.281 51.2291L147.856 60.9365H145.578L145.595 60.9545ZM142.832 49.4885C146.923 49.4885 149.094 46.7611 149.094 43.621C149.094 40.4808 146.923 39.4581 144.698 39.4581H138.544L136.337 49.4885H142.832Z"
                                    fill="#0E477A"/>
                                <path
                                    d="M166.805 37.251C172.564 37.251 177.248 40.8576 177.248 47.3532C177.248 53.8488 171.452 61.3672 164.346 61.3672C157.241 61.3672 153.867 57.7605 153.867 51.2649C153.867 44.7693 159.663 37.251 166.787 37.251H166.805ZM166.625 39.0812C160.973 39.0812 156.038 44.1413 156.038 51.2111C156.038 58.2809 159.842 59.5549 164.454 59.5549C169.065 59.5549 175.077 54.4948 175.077 47.425C175.077 40.3552 171.237 39.0812 166.625 39.0812Z"
                                    fill="#0E477A"/>
                            </svg>
                            <div className='ProSupport-demo-title'>{h1_content}</div>
                            <div className='ProSupport-demo-content'>{body_line_1}</div>
                            {isMobile ?
                                <div style={{
                                    width: '60vw'
                                }}>
                                    <List
                                        dataSource={body_item}
                                        renderItem={item => (
                                            <List.Item style={{justifyContent:"flex-start"}}>
                                                <div style={{marginRight: '1rem'}}></div>
                                                <Typography.Text>{item}</Typography.Text>
                                            </List.Item>
                                        )}
                                        itemLayout="horizontal"
                                        split={false}
                                    />
                                </div> : <div style={{
                                    width: '30vw'
                                }}>
                                    <List
                                        dataSource={body_item}
                                        renderItem={item => (
                                            <List.Item style={{justifyContent:"flex-start"}}>
                                                <div style={{marginRight: '1rem'}}>{icon}</div>
                                                <Typography.Text>{item}</Typography.Text>
                                            </List.Item>
                                        )}
                                        itemLayout="horizontal"
                                        split={false}
                                    />
                                </div>}
                            <div>{body_line_2}</div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="responsive-form-container">
                            <div className="form-wrapper">
                                <iframe
                                    src="https://vieunite.com/request-demo-form/form.html"
                                    style={{
                                        width: '100%',
                                        border: 'none',
                                        height: 'auto',
                                        aspectRatio: '13/25'
                                        // minHeight: '75rem',
                                    }}
                                />
                            </div>
                        </Col>

                    </Row>
                </div>
            </Content>
        </Layout>
    </div>)
}

export default ProSupportRequestDemoForm;