import React, {useState} from 'react';
import {Button, Modal} from 'antd';
import {Primary_Font} from "../../../../../settings";
import "./style.scss"
import {imagesRule, videosRule} from "./text";
import useWindowResize from "../../../../Hooks/useWindowResize";

const RequirementsModal = (props) => {
    const {isRequirementsModalOpen, closeRequirmentsModal} = props;
    const {windowWidth} = useWindowResize()
    return (
        <div className={'rq-modal'}>
            <Modal title={<p style={windowWidth < 600 ? {fontSize: '1.4rem'} : {}}>Artwork Requirements.</p>}
                   visible={isRequirementsModalOpen}
                   onCancel={closeRequirmentsModal}
                   footer={null}
                   className={'charge-modal'}
                   width={windowWidth > 600 ? '40%' : '100%'}
            >
                <p>All artwork uploaded should be original. If you have difficulties uploading your artwork please
                    contact support@vieunite.com</p>
                <p style={{...Primary_Font, fontWeight: '600', fontSize: '1rem'}}>Images</p>
                <p>Images can be cropped after they are uploaded to fit into a 16:9 aspect ratio. </p>
                <table className={'rq-table'} style={{width: '100%', border: '1px solid black'}}>
                    {imagesRule.map((rule, index) => (
                        <tr key={index}>
                            <td>{rule[0]}</td>
                            <td>{rule[1]}</td>
                        </tr>
                    ))}
                </table>
                <p style={{...Primary_Font, fontWeight: '600', fontSize: '1rem', marginTop: '1.8rem'}}>Videos</p>
                <p>Your video may fail to upload if it does not meet the size requirements.</p>
                <table className={'rq-table'} style={{width: '100%', border: '1px solid black'}}>
                    {videosRule.map((rule, index) => (
                        <tr key={index}>
                            <td>{rule[0]}</td>
                            <td>{rule[1]}</td>
                        </tr>
                    ))}
                </table>
            </Modal>
        </div>
    );
};

export default RequirementsModal;