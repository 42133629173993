import React from "react";
import {Helmet} from "react-helmet";

export const A2Meta = () => (
    <Helmet>
        <title>Ke Yang | Vieunite</title>
        <meta name="description"
              content="Ke Yang is an ARB Registered Architect and a RIBA Chartered Member since 2022. His aim is to use modern fabrication techniques to update traditional construction methods."/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@ VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Ke Yang "/>
        <meta name="twitter:description"
              value="Ke Yang's digital photography realism blends fine detail with architectural structures. Explore his creations on Vieunite's premium art platform. "/>
        <meta name="twitter:url" value="https://vieunite.com/artists/ke-yang"/>
        <meta name="twitter:image" value=" https://vieunite.com/images/vieunite_sc_ke_yang.jpg"/>

        <meta property="og:url" content=" https://vieunite.com/artists/ke-yang"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Vieunite Artist | Ke Yang "/>
        <meta property="og:description"
              content=" Ke Yang's digital photography realism blends fine detail with architectural structures. Explore his creations on Vieunite's premium art platform. "/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_ke_yang.jpg"/>
    </Helmet>);