import React, {useState} from 'react';
import {Layout, Col, Row, Image, Modal} from 'antd';
import "./style.scss";
import {Content} from "antd/es/layout/layout";
import {ReactComponent as Vertical_Rectangle} from './Rectangle 7337.svg'
import {ReactComponent as Landscape_Rectangle} from './Rectangle 7335.svg'
import {
    EthanPlatt,
    JonathanArmour,
    KeYang,
    JoVincent,
    JenetteColdrick,
    LucyNelson,
    RoryWatson,
    CaitlinFlood,
    AnthonyWebb, MatthewStickland, AlexanderRhys
} from '../../img/Artists/index';
import {
    Barbara,
    ChristianRedermayer,
    DorisLuming,
    LinWangWang,
    DavidBickley,
    AngeliqueNagovskaya
} from "../../img/Artists/index";
import {FazarWibisono, GuilleGiagante, RichardBattye} from "../../img/Artists/index";
import ArtistBgVideo from "../../components/BgVideo/ArtistBgVideo";
import {AMeta} from "../../components/PageMeta/ArtistMeta";

const ArtistTable = {
    'alexanderrhys': AlexanderRhys,
    'matthewstickland': MatthewStickland,
    'ethanplatt': EthanPlatt,
    'jonathanarmour': JonathanArmour,
    'keyang': KeYang,
    'lucynelson': LucyNelson,
    'jovincent': JoVincent,
    'jenettecoldrick': JenetteColdrick,
    'barbara': Barbara,
    'christianredermayer': ChristianRedermayer,
    'dorisluming': DorisLuming,
    'linwangwang': LinWangWang,
    'davidbickley': DavidBickley,
    'richardbattye': RichardBattye,
    'guillegiagante': GuilleGiagante,
    // 'fazarwibisono':FazarWibisono,
    'rorywatson': RoryWatson,
    'caitlinflood': CaitlinFlood,
    'anthonywebb': AnthonyWebb,
    'angeliquenagovskaya': AngeliqueNagovskaya,
}

const Primary_Font = {
    fontFamily: "Proxima Nova bold, sans-serif",
}

const TitleFont = {
    fontSize: '42px',
    fontFamily: "Proxima Nova bold, sans-serif",
}

const Img_Radius = {
    borderRadius: '30px',
}

function NewArtist(props) {
    const artist = ArtistTable[props.name];
    // const [hasMounted, setHasMounted] = React.useState(false);
    // React.useEffect(() => {
    //     setHasMounted(true);
    // }, []);
    // if (!hasMounted) {
    //     return null;
    // }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');

    const openModal = (imageSrc) => {
        setCurrentImage(imageSrc);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentImage('');
    };
    return (
        <div>
            {artist.meta()}
            <Layout>
                <Content>
                    {/*Header Video Zone*/}
                    {/*<div>*/}
                    {/*    <ArtistBgVideo*/}
                    {/*        desktopUrl={artist.headerDt}*/}
                    {/*        mobileUrl={artist.headerM}*/}
                    {/*        fullScreen={true}*/}
                    {/*    />*/}
                    {/*    <Row justify={"center"}>*/}
                    {/*        <div*/}
                    {/*            className={"title-over-artist-video"}*/}
                    {/*            style={{*/}
                    {/*                ...Primary_Font,*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <p className={*/}
                    {/*                'featured-artist-p'*/}
                    {/*            }>VIEUNITE FEATURED ARTIST</p>*/}
                    {/*            {artist.name}*/}
                    {/*        </div>*/}
                    {/*    </Row>*/}
                    {/*</div>*/}

                    <Content style={{width: '100%', overflowX: 'hidden',}}>
                        {/*Description row*/}
                        <div style={{
                            backgroundColor: '#F0F0F0',
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingTop: '14vh'
                        }}>
                            <Row justify={"space-between"}
                                 style={{
                                     backgroundColor: '#F0F0F0',
                                     paddingBottom: '2rem',
                                     width: '84vw'
                                 }}
                                 className={"img-text-zone"}
                            >
                                <div className={'artist-bio-m'} style={{width: '40vw'}}>
                                    <div className="shining-effect-container shine">
                                        <img
                                            className={'artist-img-avatar shining-effect-image'}
                                            height={'auto'}
                                            width={'12%'}
                                            src={artist.avatar_square}
                                            style={{borderRadius: '18px'}}
                                        />
                                        <p className={'featured-artist-name'}>{artist.name}</p>
                                        {artist.biography.map((val) =>
                                            <p className="text-content-app"
                                               style={{
                                                   ...Primary_Font,
                                                   color: 'black',
                                                   fontWeight: '500',
                                                   fontSize: '1rem',
                                                   marginTop: 0,
                                                   textAlign: "left"
                                               }}>
                                                {val}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div style={{width: '14vw', marginLeft: '-2vw'}} className={'m-no'}>
                                    <button className="new-normal-btn" style={{margin: '0 0 0 -0.5vw'}}>
                                        <a className={'new-in-normal-btn-link'} href={artist.link_to}>
                                            {'VISIT WEBSITE'}
                                        </a>
                                    </button>
                                </div>
                            </Row>
                            {/*<Row justify={"center"}>*/}
                            <div className={'m-artist-website'}>
                                <button className="new-normal-btn" style={{margin: '0 0 0 0'}}>
                                    <a className={'new-in-normal-btn-link'} href={artist.link_to}>
                                        {'VISIT WEBSITE'}
                                    </a>
                                </button>
                            </div>
                            {/*</Row>*/}
                        </div>

                        {/*Quote row*/}
                        {/*<Row justify={"center"}*/}
                        {/*     style={{backgroundColor: 'white', paddingTop: '2rem', width: '80vw'}}>*/}
                        {/*    <div style={{*/}
                        {/*        width: '60vw',*/}
                        {/*        display: "flex",*/}
                        {/*        flexDirection: "column",*/}
                        {/*        justifyContent: "start",*/}
                        {/*        alignItems: "start"*/}
                        {/*    }}>*/}
                        {/*        <p className="featured-artist-quote" style={{}}>*/}
                        {/*            {artist.quote}*/}
                        {/*        </p>*/}
                        {/*        <p className={'featured-artist-quote-name'}>{artist.name}</p>*/}
                        {/*    </div>*/}
                        {/*</Row>*/}

                        <Row className={'m-img-art m-artwork-row'} justify={'center'}
                             style={{padding: '4rem 0 .5rem 0', background: '#E8E8E8'}}>
                            <Col className={'dt-left'} span={10} lg={{span: 20}} xs={20} style={{marginLeft: '4vw'}}>
                                <h2 className="title-app mobile-left"
                                    style={{textAlign: "left", ...TitleFont, color: "black"}}>
                                    Selected Artworks.
                                </h2>
                                {
                                    artist.name === 'Barbara Safran de Niverville' ? (
                                        <></>
                                    ) : (
                                        <p className="text-content-app mobile-left artist-text"
                                           style={{...Primary_Font, fontWeight: '500'}}>
                                            {'Available on Vieunite.'}
                                        </p>
                                    )
                                }
                            </Col>
                        </Row>
                        {/*Art works row*/}
                        <div style={{
                            background: '#E8E8E8', display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Row className={'m-img-text m-at'} justify={"center"}
                                 style={{
                                     background: '#E8E8E8',
                                     paddingBottom: '5rem',
                                     display: "flex",
                                     alignItems: "center",
                                     justifyContent: "center",
                                     width: '90vw'
                                 }}
                                // gutter={[24, 24]}
                            >
                                {artist.works.map((work, idx) => (
                                    <Col lg={7} xs={24} style={{display: "flex", flexDirection: "column",}}>
                                        {/*{artist.vertical_work ?*/}
                                        <div className={'artist-image-container'}
                                             onClick={() => {
                                                 openModal(work.img)
                                             }}
                                             style={{borderRadius:'30px'}}
                                        >
                                            <img
                                                className={'protected-img artist-img-hover'}
                                                src={work.img}
                                                alt={work.title}

                                                style={{
                                                    ...Img_Radius,
                                                    width: '25vw',
                                                    height: '25vw',
                                                    objectFit: "cover"
                                                }}
                                            />
                                        </div>
                                        {/*<div className={'featured-artist-corner-div'}>*/}
                                        {/*    {artist.vertical_work ?*/}
                                        {/*        <Vertical_Rectangle/> :*/}
                                        {/*        <Landscape_Rectangle/>}*/}
                                        {/*</div>*/}
                                        <p style={{
                                            ...Primary_Font,
                                            fontWeight: '700',
                                            fontSize: '1rem',
                                            margin: '1rem 0'
                                        }}>
                                            {work.title}
                                        </p>
                                        <p className={'m-date'} style={{
                                            ...Primary_Font,
                                            fontWeight: '500',
                                            fontSize: '1rem',
                                            marginTop: '-2%'
                                        }}>
                                            {work.date}
                                        </p>
                                    </Col>
                                ))}
                            </Row>
                        </div>

                        <Modal
                            className={'artist-image-modal'}
                            visible={isModalOpen}
                            onCancel={closeModal}
                            footer={null} // No footer buttons
                            centered
                            width="auto" // Adjust width as needed
                            bodyStyle={{
                                padding: 0, // White padding
                                backgroundColor: '#fff', // White background for padding
                                // border: '35px solid #000', // Black border to resemble photo frame
                                maxWidth: '80vw',
                                maxHeight: '85vh',
                                overflow: 'hidden' // Ensure image doesn't overflow
                            }} // Remove padding for a cleaner look
                        >
                            <img
                                src={currentImage}
                                alt="Preview"
                                style={{
                                    width: 'auto',
                                    height: 'auto',
                                    display: 'block',
                                    maxHeight: '85vh',
                                    maxWidth: '90vw',
                                    // minWidth:'50vw'
                                }}
                            />
                        </Modal>
                    </Content>
                </Content>
            </Layout>
        </div>
    )
}

export default NewArtist;