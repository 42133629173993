import React, { useEffect, useState, useRef } from "react";
import DiscoveryArtworkCardView from "./DiscoveryArtworkCardView";
import ProPortalHeader from "../componets/ProPortalHeader";
import ProfileRow from "../componets/ProfileRow";
import Navigator from "../componets/Navigator";
import { Content } from "antd/es/layout/layout";
import { Button, Form, Layout, Input, Dropdown, Menu, Modal, Card, BackTop } from "antd";
import { ChromePicker, TwitterPicker } from 'react-color';
import SearchButton from './images/search-normal.svg';
import { get_api } from "../serverTemp";
import backButton from '.././MyAccount/ExtraServices/UpgradeSubscription/img/Back button.svg';
import { useNavigate } from "react-router-dom";

const { SubMenu } = Menu;

// const customColors = [
//     { hex: '#FFFFFF', label: 'Multicolor' },
//     { hex: '#A52A2A', label: 'Brown' },
//     { hex: '#000000', label: 'Black' },
//     { hex: '#008000', label: 'Green' },
//     { hex: '#C0C0C0', label: 'Silver' },
//     { hex: '#808080', label: 'Grey' },
//     { hex: '#800080', label: 'Violet' },
//     { hex: '#FFFFFF', label: 'White' },
//     { hex: '#FF0000', label: 'Red' },
//     { hex: '#0000FF', label: 'Blue' },
//     { hex: '#FFFF00', label: 'Yellow' },
//     { hex: '#FFA500', label: 'Orange' },
//     { hex: '#800080', label: 'Purple' },
//     { hex: '#4B0082', label: 'Indigo' },
//     { hex: '#FFD700', label: 'Gold' },
//     { hex: '#FFC0CB', label: 'Pink' },
// ];

// const CustomColorPicker = ({ colors, onColorChange }) => {
//     return (
//         <div className="custom-color-picker">
//             {colors.map((color, index) => (
//                 <div key={color.hex} className={`color-swatch-container ${index >= 8 ? 'second-row' : ''}`}>
//                     <div
//                         className="color-swatch"
//                         style={{ backgroundColor: color.hex }}
//                         onClick={() => onColorChange(color.hex)}
//                     />
//                     <div className="color-label">{color.label}</div>
//                 </div>
//             ))}
//         </div>
//     );
// };

// const picker = (
//     <CustomColorPicker
//         colors={customColors}
//         onColorChange={(color) => {
//             //     TODO
//         }}
//     />
// );

var ArtworksCurrentPage = 0;
var totalPage = 0;
var pageType = 0; // 0: normal 1: category 2: search
// const req = {
//     method: 'GET',
//     headers: { "X-Access-Token": "123456" },
// };
// const fetchArtworks = () => {
//     return fetch(`http://192.168.1.140:8001/artworks?page=${ArtworksCurrentPage}`,
//         req)
//         .then(res => {
//             if (res.ok) {
//                 console.log("request successfully")
//                 return res.json()
//             }
//             throw new Error(`Error HTTP response`);
//         })
//         .then(data => {
//             if (data.code === 200) {
//                 ArtworksCurrentPage = data.result.currentPage
//                 totalPage = data.result.totalPage
//                 // console.log("total page") 
//                 // console.log(totalPage) 
//                 // console.log("item count") 
//                 // console.log(data.result.data) 
//                 return data.result.data
//             } else {
//                 throw new Error(`Error HTTP format`);
//             }
//         })
//         .catch(error => console.log('error', error));
// }

var searchContent = ""
var lastItemId = ""
var lastLevel = ""
const DiscoveryArtworkSeeAll = () => {
    const [categories, setCategories] = useState([]);
    // const [currentPage, setCurrentPage] = useState(0);
    const [artworks, setArtworks] = useState([]);
    const [visible, setVisible] = useState(false);
    const containerRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        // get_api(`/artwork/pro/artworks?page=${ArtworksCurrentPage}`).then(res => {
        //     ArtworksCurrentPage = res.currentPage;
        //     totalPage = res.totalPage;
        //     setArtworks(res.data);
        // });
        
        ArtworksCurrentPage = 0

        loadArtworks();

        get_api('/artwork/recommendation/categories').then(res => {
            // console.log(res.data.filter(item => item.text !== 'Colour'));
            console.log("show me categories");
            console.log(res.data);
            setCategories(res.data.filter(item => { 
                if (item.text === 'Colour') {
                    let dictionary = {}
                    item.children.filter(child => child.text !== 'Multicolour#Multicolour').map((color) => {
                        const key = '#' + color.text.split('#')[1]
                        dictionary[key.toLowerCase()] = color.id
                    })
                    console.log("server end colors")
                    console.log(dictionary)
                    setRemoteColors(dictionary)
                    return false
                }
                return true
            }));
        });

        // console.log("addListener");
        // window.addEventListener('scroll', handleScroll);
        // return () => {
        //     console.log("removeListener");
        //     window.removeEventListener('scroll', handleScroll);
        // };
    }, [])

    useEffect(() => {
        console.log("trigger once")
    }, [ArtworksCurrentPage])

    // const handleScroll = () => {
    //     console.log("handleScroll")

    //     const container = containerRef.current;
    //     if (container) {
    //         const { scrollHeight, scrollTop, clientHeight } = container;
    //         if (scrollHeight - scrollTop === clientHeight) {
    //             if (currentPage < totalPage) {
    //                 if (pageType === 0) {
    //                     currentPage = currentPage + 1;
    //                     loadArtworks();
    //                 } else if (pageType === 1) {
    //                     currentPage = currentPage + 1;
    //                     loadCategory();
    //                 }
    //             }
    //         }
    //     }
    // };

    const loadArtworks = () => {
        pageType = 0;
        get_api(`/artwork/pro/artworks?page=${ArtworksCurrentPage}`).then(res => {
            // currentPage = res.currentPage;
            totalPage = res.totalPage;
            setArtworks(prevArtworks => [...prevArtworks, ...res.data]);
            ArtworksCurrentPage += 1;
            // setCurrentPage(currentPage + 1);
            console.log("current page")
            console.log(ArtworksCurrentPage)
        });
    };

    const loadCategory = () => {
        // console.log("loadCategory")
        // console.log(`/artwork/from/categories?level=${lastLevel}&category_id=${lastItemId}`)
        get_api(`/artwork/from/categories?level=${lastLevel}&category_id=${lastItemId}&page=${ArtworksCurrentPage}`).then(res => {
            totalPage = res.totalPage;
            setArtworks(prevArtworks => [...prevArtworks, ...res.data]);
            console.log("current page");
            console.log(ArtworksCurrentPage);
            // setCurrentPage(currentPage + 1);
            ArtworksCurrentPage += 1;
            console.log(ArtworksCurrentPage);
        });
    };

    const handleMenuClick = (itemId, level) => {
        lastItemId = itemId;
        lastLevel = level;
        pageType = 1;
        console.log("set page to 0");
        // setCurrentPage(0);
        ArtworksCurrentPage = 0;
        console.log(ArtworksCurrentPage);
        setArtworks([]);
        loadCategory();
    };

    const handleshowMore = () => {
        if (ArtworksCurrentPage < totalPage) {
            if (pageType === 0) {
                console.log("show current page")
                console.log(ArtworksCurrentPage)
                loadArtworks();
            } else if (pageType === 1) {
                console.log("show current page")
                console.log(ArtworksCurrentPage)
                loadCategory();
            }
        }
    };

    const dynamicMenu = (param) => (
        <Menu>
            {param.children && param.children.length > 0 ? (
                param.children.map((item, index) => (
                    (item.children && item.children.length > 0 ? (
                        <SubMenu title={item.text}>
                            {item.children.map((subItem, index) => (
                                <Menu.Item onClick={() => handleMenuClick(subItem.id, subItem.level)}>{subItem.text}</Menu.Item>
                            ))}
                        </SubMenu>
                    ) : (
                        <Menu.Item key={item.text} onClick={() => handleMenuClick(item.id, item.level)}> {item.text} </Menu.Item>
                    ))
                ))) : (
                <p>empty list</p>
            )}
        </Menu>
    );

    const categoriesMenu = () => (
        <Menu>
            <Menu.Item key={'Painting'}>Painting</Menu.Item>
            <Menu.Item key={'Photography'}>Photography</Menu.Item>
            <Menu.Item key={'On paper'}>On paper</Menu.Item>
            <Menu.Item key={'Moving art'}>Moving art</Menu.Item>
            <Menu.Item key={'Digital art'}>Digital art</Menu.Item>
        </Menu>
    );

    const industryMenu = () => (
        <Menu>
            <SubMenu title="Hospitality">
                <Menu.Item>Hotels</Menu.Item>
                <Menu.Item>Restaurants</Menu.Item>
                <Menu.Item>Cafe and Coffee shops</Menu.Item>
                <Menu.Item>Bars and Pubs</Menu.Item>
            </SubMenu>
            <SubMenu title="Health & Wellbeing">
                <Menu.Item>Nursing Home</Menu.Item>
                <Menu.Item>Hospitals and Health Care</Menu.Item>
                <Menu.Item>Beauty Salons and Spas</Menu.Item>
            </SubMenu>
            <Menu.Item key={'Retail'}>Retail</Menu.Item>
            <Menu.Item key={'Business & Corporate'}>Business & Corporate</Menu.Item>
            <Menu.Item key={'Educational'}>Educational</Menu.Item>
            <Menu.Item key={'Travel'}>Travel</Menu.Item>
            <Menu.Item key={'Other'}>Other</Menu.Item>
        </Menu>
    );

    const moodMenu = () => (
        // onClick={(e) => handleMenuClick(e)}
        <Menu>
            <Menu.Item key={'Curatorial'}>Curatorial</Menu.Item>
            <Menu.Item key={'Biophilia'}>Biophilia</Menu.Item>
            <Menu.Item key={'Architecture & Interiors'}>Architecture & Interiors</Menu.Item>
            <Menu.Item key={'Masterpiece'}>Masterpiece</Menu.Item>
            <Menu.Item key={'Food & Drink'}>Food & Drink</Menu.Item>
            <Menu.Item key={'Spirituality'}>Spirituality</Menu.Item>
            <Menu.Item key={'Strange & Funny'}>Strange & Funny</Menu.Item>
            <Menu.Item key={'Seasonal'}>Seasonal</Menu.Item>
        </Menu>
    );

    const onBack = () => {
        navigate('/proportal/discover-art')
    }

    const handleSubmit = () => {
        pageType = 3;
        get_api(`/artwork/search?search_word=${searchContent}`).then(res => {
            setArtworks(res.data)
        })
    };

    const [remoteColors, setRemoteColors] = useState([]);
    const defaultColors = {
        '#ffffff': '0',
        '#a52a2a': '1',
        '#000000': '2',
        '#008000': '4',
        '#C0C0C0': '5',
        '#808080': '6',
        '#800080': '7',
        '#FF0000': '9',
        '#0000FF': '10',
        '#FFFF00': '11',
        '#FFA500': '12',
        '#4B0082': '14',
        '#FFD700': '15',
        '#FFC0CB': '16'
    }
    const twitterStyle = {
        default: {
            input: {
                display: "none"
            },
            hash: {
                display: "none"
            }
        }
    };

    const picker = () => {
        return (
            <div className="themePicker">
                <Card className="colorBox" title="Search by colour" size="small">
                    <TwitterPicker
                        //第一个颜色是系统默认颜色
                        colors={Object.keys(remoteColors)}
                        className="themeColorPicker"
                        triangle="top-left"
                        styles={twitterStyle}
                        onChangeComplete={
                            (color) => {
                                setVisible(false)
                                // console.log(color)
                                // console.log(defaultColors[color.hex])
                                // get_api(`/artwork/color?color=${remoteColors[color.hex]}`).then(res => {
                                    // setCategories(res.data);
                                    // setArtworks(res.data)
                                // });
                                // console.log("choosing color " + color.hex)
                                // console.log(remoteColors[color.hex])
                                // console.log(remoteColors)
                                handleMenuClick(remoteColors[color.hex.toLowerCase()], 1)
                            }
                        }
                    />
                </Card>
            </div>
        )
    };

    return (
        <div ref={containerRef} style={{ backgroundColor: '#f4f4f4', minHeight: "100vh" }}>
            <div style={{display: 'flex', alignItems: 'center', marginLeft: '28px', marginTop: "25px"}}>
                <img src={backButton} alt="BackButton" className='BackButtonIcon' style={{ marginRight: '10px', cursor: "pointer" }} onClick={onBack}/>
                <p className="all-artworks-title">View all Artworks</p>
            </div>

            {/* <p className="collection-category-title">View all Artworks</p> */}
            <Form
                onFinish={handleSubmit}
                style={{ display: "flex", alignItems: "center", marginLeft: "91px", marginBottom: "15px" }}
            >
                <Form.Item className="search-input-frame">
                    <Input placeholder="Search arts" style={{ borderRadius: "6px" }} onChange={(e) => (
                        // console.log(e.target.value)
                        searchContent = e.target.value
                    )} >
                    </Input>
                </Form.Item>
                <Form.Item className="search-button-frame">
                    <Button className="discover-search-button" type="primary" style={{ borderRadius: "6px" }} htmlType="submit">
                        <img src={SearchButton} alt="search" style={{ width: "20px", height: "20px" }} />
                    </Button>
                </Form.Item>

                {categories.map((item, index) => (
                    <Dropdown overlay={dynamicMenu(item)} placement={"bottom"} className={'see-all-dropdown'}>
                        <Button style={{ borderRadius: "6px", margin: "5px" }}>
                            {item.text}
                        </Button>
                    </Dropdown>
                ))}

                {/* <Dropdown overlay={categoriesMenu} placement={"bottom"}>
                    <Button style={{ borderRadius: "6px", margin: "5px" }}>
                        Art Categories
                    </Button>
                </Dropdown>
                <Dropdown overlay={industryMenu} placement={"bottom"}>
                    <Button style={{ borderRadius: "6px", margin: "5px" }}>
                        Industry
                    </Button>
                </Dropdown>
                <Dropdown overlay={moodMenu} placement={"bottom"}>
                    <Button style={{ borderRadius: "6px", margin: "5px" }}>
                        Mood & Theme
                    </Button>
                </Dropdown> */}

                {/* <Button style={{ borderRadius: "6px", margin: "5px" }} onClick={showModal}>
                    Color
                </Button> */}

                <Dropdown
                    // visible={visible}
                          overlay={picker}>
                    <Button
                        style={{ borderRadius: "6px", margin: "5px" }}
                        onClick={() => setVisible(!visible)}
                    >
                        Colour
                    </Button>
                </Dropdown>
            </Form>

            <div className="collections-container" style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", alignItems: "center", padding: "0 35px 0 35px" }}>
                {artworks.map((item, index) => (
                    <div style={{ marginLeft: '65px' }} key={index}>
                        <DiscoveryArtworkCardView item={item} style={{ marginRight: 45, marginBottom: 40}}/>
                    </div>
                ))}
            </div>

            {ArtworksCurrentPage < totalPage && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        style={{ borderRadius: "6px", margin: "25px" }}
                        onClick={() => handleshowMore()}
                    >
                        Show More
                    </Button>
                </div>
            )}

        </div>
    )
}

const DiscoveryArtworkSeeAllWrapper = () => {
    return (
        <Layout>
            <Content>
                <ProPortalHeader />
                <ProfileRow />
                <Layout>
                    <Navigator />
                    <Layout>
                        <DiscoveryArtworkSeeAll />
                    </Layout>
                    <BackTop />
                </Layout>
            </Content>
        </Layout>
    )
}

export default DiscoveryArtworkSeeAllWrapper;