import React from "react";

export const RemoveIcon = () => <svg
    style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg" width="25" height="25"
    viewBox="0 0 25 25" fill="none">
    <path
        d="M21.876 6.22982C18.4072 5.88607 14.9176 5.70898 11.4385 5.70898C9.37598 5.70898 7.31348 5.81315 5.25098 6.02148L3.12598 6.22982"
        stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M8.85059 5.17578L9.07975 3.8112C9.24642 2.82161 9.37142 2.08203 11.1318 2.08203H13.861C15.6214 2.08203 15.7568 2.86328 15.9131 3.82161L16.1423 5.17578"
        stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M19.6331 9.53125L18.9561 20.0208C18.8415 21.6562 18.7477 22.9271 15.8415 22.9271H9.15397C6.24772 22.9271 6.15397 21.6562 6.03939 20.0208L5.3623 9.53125"
        stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.7607 17.1914H14.2295" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M9.89062 13.0293H15.099" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
</svg>