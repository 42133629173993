import {SINGLE_PRICE, SUB_FEES} from "../../constants/proConstants";

export const initialState = {
    counts: [0, 0, 0, 0],
    curStep: 0,
    totalPrice: 0,
    totalCanvas: 0,
    subFee: 0,
    sub: null,
    agreed: false,
    clientSecret: '',
    isPayModalOpen: false,
    active: [1, 1, 1]
};
export const reducer = (draft, action) => {
    switch (action.type) {
        case 'add':
            draft.counts[action.item]++;
            break;
        case 'minus':
            draft.counts[action.item] > 0 && draft.counts[action.item]--;
            break;
        case 'get_total_price':
            draft.totalCanvas = Object.values(draft.counts).reduce((acc, curr) => acc + curr, 0);
            draft.totalPrice = ((draft.totalCanvas * SINGLE_PRICE + draft.totalCanvas * draft.subFee) * 1.2).toFixed(2);
            break
        case 'set_purchase_ids':
            draft.purchaseIDs = [
                ...Array(draft.counts[0]).fill('0'),
                ...Array(draft.counts[1]).fill('1'),
                ...Array(draft.counts[2]).fill('2'),
                ...Array(draft.counts[3]).fill('3')
            ];
            break;
        case 'set_step':
            draft.curStep = action.step;
            break;
        case 'set_active_sub':
            draft.active = action.active;
            break
        case 'set_sub':
            draft.sub = action.sub;
            if (action.sub === null) {
                draft.subFee = 0
                draft.active = [1, 1, 1]
            } else {
                draft.subFee = SUB_FEES[action.sub];
                draft.active = [0, 0, 0];
                draft.active[action.sub] = 1;
            }
            break;
        case 'set_pay_modal':
            draft.isPayModalOpen = action.isPayModalOpen;
            break;
        case 'set_agreement':
            draft.agreed = action.agreed;
            break;
        case 'set_clientSecret':
            draft.clientSecret = action.clientSecret;
            break;
        default:
            break;
    }
}
