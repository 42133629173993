import React, {useEffect, useRef, useState} from 'react';
import {
    Layout,
    Row,
    Col,
    Button,
    Form,
    Input, Upload,
} from "antd";
import {Content} from "antd/es/layout/layout";
import {useLocation, useNavigate} from "react-router-dom";
import {APP_API_URL, Primary_Font, TitleFont} from "../../../settings";
import Uploader from "../ArtistPortalHome/components/Uploader";
import {BackButton} from "../ArtList/components/BackButton";
import './style.scss'
import {UploadOutlined} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import {profileUpdateAll, uploadAvatar} from "./service/service";
import {ProfileText} from "./text";
import useWindowResize from "../../Hooks/useWindowResize";

function ProfileEditor() {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const {state} = useLocation();
    state.data.avatar = state.data.avatar.replace(':8082', '').replace('http:', 'https:')
    const {data} = state;
    const [avatarUrl, setAvatarUrl] = useState(data.avatar)
    const cropperRef = useRef(null)
    const {windowWidth} = useWindowResize()

    return (
        <Layout>
            <Content className={'grey-bk'}>
                <BackButton text={"< Back to Dashboard"} link={"/artistportal/home"}/>
                <Form
                    className={'whole-form'}
                    form={form}
                    layout="vertical"
                    name="art_form"
                    initialValues={{
                        // username: data.username,
                        nickname: data.nickname,
                        info: data.info
                    }}
                    onFinish={(values) => {
                        const uploadData = {...values, avatar: avatarUrl}
                        profileUpdateAll(uploadData, navigate)
                    }}
                >
                    <Row justify={"center"} style={{padding: '0.5rem 0 6rem 0'}}>
                        <Col lg={10} xs={24} style={{
                            backgroundColor: 'white',
                            padding: '2rem',
                            borderRadius: windowWidth > 600 ? '20px 0 0 20px' : '0',
                        }}>
                            <h2 className="title-app mobile-left"
                                style={{textAlign: "left", ...TitleFont}}>
                                <span style={{fontWeight: '500'}}>Edit</span> Profile.
                            </h2>
                            <p style={{
                                fontSize: '1rem',
                                fontWeight: '400'
                            }}>Your name, username, bio and links appear on your artist Vieunite profile. Your email
                                address
                                remains private. </p>
                            <Row justify={"start"} gutter={[48, 48]}>
                                <Col lg={20} xs={24}>
                                    <Form.Item
                                        className={'input-form'}
                                        name="nickname"
                                        label="Nick Name"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input bordered={false} size={"large"}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify={"start"} gutter={[48, 48]}>
                                <Col span={20} lg={20} xs={24}>
                                    <Form.Item className={'input-form'} name="info" label="Biography">
                                        <Input.TextArea bordered={false} rows={6}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            lg={10} xs={24}
                            style={{
                                backgroundColor: 'white',
                                padding: '2rem',
                                borderRadius: windowWidth > 600 ? '0 20px 20px 0' : '0',
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <div style={{
                                margin: '0 0 1rem 0',
                                height: windowWidth > 600 ? "20vw":'60vw',
                                width: windowWidth > 600 ? "20vw":'60vw',
                            }}>
                                <img
                                    alt={'avatar'}
                                    style={{
                                        width: windowWidth > 600 ? "20vw":'60vw',
                                        height: windowWidth > 600 ? "20vw":'60vw',
                                        objectFit: 'cover',
                                    }}
                                    src={avatarUrl}/>
                            </div>
                            <ImgCrop
                                ref={cropperRef}
                                rotationSlider={false}
                                modalTitle={<>
                                    <h2 className="title-app"
                                        style={{
                                            textAlign: "left",
                                            ...TitleFont, fontSize: '2rem'
                                        }}>
                                        Upload.
                                    </h2>
                                    <p style={{
                                        fontSize: '0.8rem',
                                        fontWeight: '400'
                                    }}>{ProfileText.upload}
                                    </p>
                                </>}
                                modalOk={'Confirm'}
                            >
                                <Upload
                                    accept={'.png,.jpg,.jpeg,.webp'}
                                    className={'sp-btn'}
                                    listType={null}
                                    showUploadList={false}
                                    name="avatar"
                                    maxCount={1} //限制上传数量。当为 1 时，始终用最新上传的文件代替当前文件
                                    multiple={false} //是否支持多选文件，开启后按住 ctrl 可选择多个文件
                                    customRequest={({file}) => {
                                        uploadAvatar(file, setAvatarUrl)
                                    }}
                                >
                                    <Button>UPDATE PROFILE PICTURE</Button>
                                </Upload>
                            </ImgCrop>
                            <Form.Item className={'sp-btn'}
                                       style={{
                                           display: 'flex',
                                           justifyContent: "center",
                                           alignItems: "center",
                                           margin: '1em 0 1rem 0'
                                       }}
                            >
                                <Button type={'primary'} htmlType="submit">
                                    SAVE CHANGES
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </Layout>
    )
}

export default ProfileEditor;
