import React, {useEffect, useState} from 'react';
import {Button, Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss';
import Background from './imgs/Background Image.webp';
import Settings from './imgs/Account Settings button.svg';
import Orders from './imgs/My Orders button.svg';
import Subscription from './imgs/Subscription button.svg';
import ProPortalHeader from "../componets/ProPortalHeader";
import {useNavigate} from "react-router-dom";
import Navigator from "../componets/Navigator";
import ProfileRow from "../componets/ProfileRow";
import {get_api} from "../serverTemp";

const MyAccountHome = (props) => {
    useEffect(() => {
        // 当组件挂载时禁用滚动
        document.body.style.overflowY = 'hidden';

        // 当组件卸载时恢复滚动
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, []);
    const navigate = useNavigate();
    const handleSubscriptionClick = () => {
        navigate('/proportal/MyAccount');
    };
    const handleOrdersClick = () => {
        navigate('/proportal/MyOrder');
    };
    const handleSettingsClick = () => {
        navigate('/proportal/AccountSettings');
    };
    const [user, setUser] = useState({})
    useEffect(() => {
        get_api('/user/detail').then(res => {
            setUser(res)
        })
    }, [])
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                {/*<ProfileRow/>*/}
                <Row justify={"center"} style={{margin: '88px 0 0 0'}}>
                    <Navigator/>
                    <Layout>
                        <Col style={{position: 'relative', width: '100%', height: '100vh'}}>
                            <div className="hello-admin">Hello {user?.nickname}</div>
                            <div className="icon-container">
                                <div className="first-row-icons icon-hover-effect" onClick={handleSubscriptionClick}>
                                    <img src={Subscription} alt="Subscription" className="Subscription-icon"/>
                                    <span className="icon-text">Subscription</span>
                                </div>
                                <div className="second-row-icons icon-hover-effect" onClick={handleOrdersClick}>
                                    <img src={Orders} alt="Orders" className="Orders-icon"/>
                                    <span className="icon-text">My Orders</span>
                                </div>
                                <div className="third-row-icons icon-hover-effect" onClick={handleSettingsClick}>
                                    <img src={Settings} alt="Settings" className="Settings-icon"/>
                                    <span className="icon-text">Account Settings</span>
                                </div>
                            </div>
                            <div className="background-container"></div>
                        </Col>
                    </Layout>
                </Row>
                <div className="terms-container">
                    <a href="https://vieunite.com/business/ProTermsAndConditions" className="terms-text">Terms and Conditions</a>
                    <a href="https://vieunite.com/business/ProPrivacyPolicy" className="terms-text">Privacy Policy</a>
                </div>
                <div className="Pro-copyright">
                    <p className="Pro-copyright-text">
                        ©2023 Vieunite Limited All Rights Reserved<br/>
                        VAT Registration No. 411723238<br/>
                        Company Registration No. 13883746
                    </p>
                </div>
            </Content>
        </Layout>
    );
}

export default MyAccountHome;
