

export const getMD5 = async (file, spark) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = function (e) {
            spark.append(e.target.result); // Append chunk to spark
            const md5 = spark.end(); // Finalize the hash
            resolve(md5);
        };

        fileReader.onerror = function (e) {
            reject(e);
        };

        fileReader.readAsArrayBuffer(file);
    })
}