import React, {useEffect, useState} from 'react';
import {InputNumber, message, Modal, Radio, Tooltip} from 'antd';
import {Button, Col, Input, Layout, Row, Select, Space, Table, TimePicker, Checkbox} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import ProPortalHeader from "../../../componets/ProPortalHeader";
import ProfileRow from "../../../componets/ProfileRow";
import Navigator from "../../../componets/Navigator";
import Img from "./Static Textura with Start Screen.png"
import {get_api, post_api} from "../../../serverTemp";
import {useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as RepeatOneIcon} from './Repeat Once.svg'
import {ShuffleIcon, RepeatIcon} from "../../Icons";
import {DAYS_A_WEEK} from "../../../../constants/proConstants";
import {useImmerReducer} from "use-immer";
import {initialState, reducer} from "./reducer";
import {RemoveIcon} from "./svg";
import {ReactComponent as InfoIcon} from "../../../../pro/Subscription/Information icon.svg"
import {ReactComponent as BackIcon} from "../../../MyLibrary/ImgDisplay/Back Button and Text.svg";


const Hardware = (props) => {
    const [dsState, dispatch] = useImmerReducer(reducer, initialState)
    const {state} = useLocation();
    const {deviceData} = state
    const [schedule, setSchedule] = useState([])
    const [deviceName, setDeviceName] = useState(localStorage.getItem(`deviceName-${deviceData.id}`) ?? deviceData.name)
    const [infoChanged, setInfoChanged] = useState(false)
    const [nameChanged, setNameChanged] = useState(false)
    const navigate = useNavigate()
    // 0:Schedule 1:Setting 2:Control
    const [cur, setCur] = useState(0)
    const [newScheduleOpen, setNewScheduleOpen] = useState(false)
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false)
    useEffect(() => {
        get_api(`/textura/schedules?textura_id=${deviceData.id}`).then(res => {
            setSchedule(res.data)
        })
    }, [])
    useEffect(() => {
        get_api(`/textura/metadata?textura_id=${deviceData.id}`)
            .then((res) => {
                console.log(res)
                dispatch({type: 'update_meta', item: {...res}})
                dispatch({
                    type: 'set_play_mode',
                    payload: res?.repeat
                })
            }).then(() => {
            console.log(dsState.metaData)
        }).catch(error => console.log('error', error))
    }, [])

    const columns = [
        {
            title: 'Textura Name',
            // dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <span>
                {deviceData.name}
            </span>
            )
        },
        {
            title: 'Start time',
            dataIndex: 'start_time',
            key: 'start',
        },
        {
            title: 'End time',
            key: 'end',
            dataIndex: 'end_time',
        },
        {
            title: <span style={{
                display: 'flex',
                // justifyContent: 'center',
            }}>Day of the week</span>,
            dataIndex: 'days',
            key: 'week',
            render: (days, record) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((d, idx) => {
                        return (
                            <span style={days.includes(idx) ? {
                                background: '#D9D9D9',
                                borderRadius: '50%',
                                width: '20px',
                                textAlign: 'center'
                            } : {}} key={idx}>
                                {d}
                            </span>
                        );
                    })}
                </div>
            )
        },
        {
            title: <span style={{
                display: 'flex',
                justifyContent: 'center',
            }}>Remove</span>,
            key: 'remove',
            render: (_, record) => (
                <Space size="middle" style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
                       onClick={() => {
                           console.log(deviceData.id, record.id)
                           post_api('/textura/delete/schedule', {
                               textura_ids: [deviceData.id],
                               id: record.id
                           }).then(_ => window.location.reload())
                               .catch(error => console.error('Error:', error));
                       }}
                >
                    <RemoveIcon/>
                </Space>
            ),
        },
    ];
    const handleBackClick = () => {
        navigate(-1);
    };
    return (
        <Layout>
            <Content style={{paddingTop: '0.625rem'}}>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row>
                    <Layout>
                        <Navigator/>
                        <Layout style={{marginBottom: '10vh'}}>
                            <Row style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '50px',
                                marginLeft: '20px'
                            }}>
                                <span onClick={_ => window.history.back()}
                                      style={{cursor: "pointer"}}
                                >
                                                <BackIcon style={{margin: 0}}/>
                                            </span>
                                <span className='hard-title' style={{margin: 0}}>Hardware detail</span>
                            </Row>
                            <Row justify={"center"}>
                                <Col span={8} lg={8}>
                                    <div className={'hard-img-div'}>
                                        <img src={Img} className={'hard-img'} alt={'img'}/>
                                    </div>
                                </Col>
                                <Col span={16}>
                                    <Row justify={"center"} className={'hard-row'}>
                                        <div>
                                            <p className={'hard-row-p-txt'}>Device name:</p>
                                            <Input className={'hard-input-with-save'}
                                                   placeholder="Name"
                                                   onChange={(e) => {
                                                       const userInput = e.target.value;
                                                       setDeviceName(userInput);
                                                       setNameChanged(true)
                                                       localStorage.setItem(`deviceName-${deviceData.id}`, userInput); // Save to localStorage
                                                   }}
                                                   defaultValue={deviceName}
                                                   suffix={
                                                       <Button type="primary"
                                                               className={'inside-input-btn'}
                                                               onClick={() => {
                                                                   const curId = deviceData.id
                                                                   post_api('/textura/change/textura/name', {
                                                                       "textura_id": curId,
                                                                       new_textura_name: deviceName
                                                                   })
                                                                       .then(_ => setNameChanged(false))
                                                                       .catch(error => console.error('Error:', error));
                                                               }}
                                                               disabled={!nameChanged}
                                                       >
                                                           Save
                                                       </Button>
                                                   }
                                            />
                                        </div>
                                        <div>
                                            <p className={'hard-row-p-txt'}>Subscription level:</p>
                                            <Input className={'hard-input'} placeholder="Subscription"
                                                   style={{
                                                       color:'grey'
                                                   }}
                                                   disabled={true}
                                                   defaultValue={
                                                       localStorage.getItem('subscription')
                                                   }/>
                                        </div>

                                    </Row>
                                    <Row justify={"center"} className={'hard-row'}>
                                        <div>
                                            <p className={'hard-row-p-txt'}>Serial Number:</p>
                                            <Input className={'hard-input'} placeholder="SN"
                                                   defaultValue={deviceData.sn}
                                                   style={{
                                                       color:'grey'
                                                   }}
                                                   disabled={true}
                                            />
                                        </div>
                                        <div>
                                            <p className={'hard-row-p-txt'}>Orientation:</p>
                                            <Input className={'hard-input'} placeholder="Portrait"
                                                   disabled={true}
                                                   style={{
                                                       color:'grey'
                                                   }}
                                                   defaultValue={deviceData.angle === 90 || deviceData.angle === 270 ?
                                                       'Portrait' : 'Landscape'}/>
                                        </div>

                                    </Row>
                                    <Row justify={"center"} className={'hard-row'}>
                                        <div>
                                            <p className={'hard-row-p-txt'}>Group name:</p>
                                            <Input className={'hard-input'} placeholder="No group has been added yet"
                                                   defaultValue={deviceData.group}
                                                   style={{
                                                       color:'grey'
                                                   }}
                                                   disabled={true}
                                            />
                                        </div>

                                        <Button className={'hard-group-btn'}
                                                onClick={() => {
                                                    window.location.href = '/proportal/ProportalGroup'
                                                }}
                                                style={{
                                                    marginTop: '1.6vh'
                                                }}
                                        >
                                            Manage Groups
                                        </Button>
                                    </Row>
                                    <Row className={'hard-row'} style={{margin: '2rem 0 0 -42.3vw'}}>
                                        <Button className={'hard-btn'}
                                                onClick={_ => setCur(0)}>
                                            Schedule
                                        </Button>
                                        <Button className={'hard-btn'}
                                                onClick={_ => setCur(1)}
                                                style={{margin: '0 0 0 -3rem'}}>
                                            Setting
                                        </Button>
                                        <Button className={'hard-btn'}
                                                onClick={_ => setCur(2)}
                                                style={{margin: '0 0 0 -3rem'}}>
                                            Control
                                        </Button>
                                    </Row>
                                    {cur === 0 && <>
                                        <Row style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'start',
                                            margin: '2rem 0 0 0'
                                        }}>
                                            <p className={'hard-cur-sch'}>
                                                Current schedule
                                            </p>
                                            <Button className={'schedule-hard-btn'}
                                                    style={{}}
                                                    onClick={() => {
                                                        setNewScheduleOpen(true)
                                                        setInfoChanged(true)
                                                        setIsScheduleModalOpen(true)
                                                    }}
                                            >
                                                Add New Schedule
                                            </Button>
                                        </Row>
                                        <Table style={{
                                            margin: '2rem 0rem 0 -2rem',
                                            borderRadius: '8px',
                                            border: '2px solid #FFF'
                                        }}
                                               locale={{
                                                   emptyText: (
                                                       <div style={{textAlign: 'center'}}>
                                                           <p>You currently have no hardware schedules.</p>
                                                       </div>
                                                   )
                                               }}
                                               columns={columns}
                                               dataSource={schedule}
                                               pagination={false}
                                        /></>}
                                    {cur === 1 &&
                                        <div style={{
                                            margin: '4vh 0 4vh -1.4vw',
                                            width: '26vw',
                                            background: '#D9D9D9',
                                            display: 'flex',
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: 'center'
                                        }}>
                                            <Checkbox.Group
                                                style={{
                                                    width: '70%',
                                                    margin: '2rem 0 1rem 0'
                                                }}
                                                // onChange={onChange}
                                            >
                                                <Row justify={'start'}>
                                                    <Radio.Group
                                                        onChange={(e) => {
                                                            dispatch({
                                                                type: 'update_meta',
                                                                item: {angle: e.target.value}
                                                            })
                                                        }}
                                                        value={dsState.metaData.angle}>
                                                        <Radio value={0} className={'jsb-txt'}>
                                                            <p className={'jsb-angle'}><span>0°</span> <span
                                                                className={'jsb-angle-span'}>Landscape</span></p>
                                                        </Radio>
                                                        <Radio value={90} className={'jsb-txt'}>
                                                            <p className={'jsb-angle'}><span>90°</span> <span
                                                                className={'jsb-angle-span'}>Portrait</span></p>
                                                        </Radio>
                                                        <Radio value={180} className={'jsb-txt'}>
                                                            <p className={'jsb-angle'}><span>180°</span> <span
                                                                className={'jsb-angle-span'}>Landscape</span><span
                                                                className={'jsb-angle-span'}>Flipped</span></p>
                                                        </Radio>
                                                        <Radio value={270} className={'jsb-txt'}>
                                                            <p className={'jsb-angle'}><span>270°</span> <span
                                                                className={'jsb-angle-span'}>Portrait</span><span
                                                                className={'jsb-angle-span'}>Flipped</span></p>
                                                        </Radio>
                                                    </Radio.Group>
                                                </Row>
                                            </Checkbox.Group>
                                            <Row justify={'start'} className={'hd-settings-ip'}>
                                                <div>
                                                    <p className={'hd-settings-txt'}>Volume</p>
                                                    <InputNumber min={0} max={100}
                                                                 value={dsState.metaData.volume}
                                                                 onChange={val =>
                                                                     dispatch({
                                                                         type: 'update_meta',
                                                                         item: {volume: val}
                                                                     })
                                                                 }/>
                                                </div>
                                            </Row>
                                            <Row justify={'start'} className={'hd-settings-ip'}
                                                 style={{marginTop: '2vh'}}>
                                                <div>
                                                    <p className={'hd-settings-txt'}>Brightness</p>
                                                    <InputNumber min={0} max={100}
                                                                 value={dsState.metaData.brightness}
                                                                 onChange={val =>
                                                                     dispatch({
                                                                         type: 'update_meta',
                                                                         item: {brightness: val}
                                                                     })
                                                                 }/>
                                                </div>

                                            </Row>
                                            <Row justify={'start'} className={'hd-settings-ip'}>
                                                <div>
                                                    <p className={'hd-settings-txt'}
                                                       style={{width: '10vw', margin: '2vh 0 0 0'}}>Image
                                                        duration(seconds)</p>
                                                    <InputNumber min={0} max={86400}
                                                                 value={dsState.metaData.imageDuration}
                                                                 onChange={val =>
                                                                     dispatch({
                                                                         type: 'update_meta',
                                                                         item: {imageDuration: val}
                                                                     })
                                                                 }/>
                                                </div>

                                            </Row>
                                            <Row className={'hard-final-row'} style={{
                                                margin: '4rem 0 0 0',
                                                paddingBottom: '4rem'
                                            }}>
                                                <Button className={'hard-week-final-btn-mul-s'}
                                                        onClick={() => {
                                                            const curId = [deviceData?.id]
                                                            post_api('/textura/update/metadata', {
                                                                textura_ids: curId,
                                                                ...dsState.metaData
                                                            })
                                                        }}
                                                        style={{
                                                            width: '7vw',
                                                            height: '4vh',
                                                            fontSize: '1vw'
                                                        }}
                                                >
                                                    Save
                                                </Button>
                                            </Row>
                                        </div>}
                                    {cur === 2 && <div>
                                        <div style={{
                                            margin: '2rem 0 0 -2rem', width: '20vw',
                                            borderRadius: '6px',
                                            border: '0px solid #E2E2E2', // Or simply 'none' if you don't need a border
                                            background: '#F4F4F4',
                                            boxShadow: '-2px -3px 10.4px -5px rgba(0, 0, 0, 0.18) inset'
                                        }}>
                                            <Row justify={'start'}>
                                                <p className={'zh-ml-schedule-modal-h'}>Playback Options</p>
                                            </Row>
                                            <Row justify={"start"} style={{margin: '1rem 0 0 0'}}>
                                                <p className={'zh-ml-schedule-modal-p'}>
                                                    When you use this particular function you
                                                    will need to assess your hardware
                                                </p>
                                            </Row>
                                            <Row justify={'start'} style={{margin: '2rem 0 0 0'}}>
                                                <Col span={24}>
                                                    <Row justify={'center'}>
                                                        <div className={'hd-control-div'} style={{
                                                            height: '2.6vw',
                                                            width: '16.2vw',
                                                            // marginLeft: '6vw'
                                                        }}>
                                                            <button className={'hd-control-btn'}
                                                                    style={{
                                                                        margin: '0 0 0 0.5rem',
                                                                        backgroundColor: dsState?.texturaPlayModeStatus === 0 ? '#A06174' : '#0E487A',
                                                                        width: '2vw',
                                                                        height: '2vw'
                                                                    }}
                                                                    onClick={_ => {
                                                                        const curId = [deviceData?.id]
                                                                        post_api('/textura/shuffle', {
                                                                            textura_ids: curId,
                                                                            texturaPlayModeStatusV2: 0,
                                                                            version: 2,
                                                                        })
                                                                            .then(() => dispatch({
                                                                                type: 'set_play_mode',
                                                                                payload: 0
                                                                            })).catch(error => console.log('error', error))
                                                                    }
                                                                    }>
                                                                <RepeatIcon/>
                                                            </button>
                                                            <p className={'hd-control-p'} style={{
                                                                color: dsState?.texturaPlayModeStatus === 0 ? '#000' : '#D9D9D9',
                                                                width: '10vw',
                                                                marginLeft: '1vw'
                                                            }}>
                                                                Loop mode is on
                                                            </p>
                                                            <Tooltip
                                                                title={'Clicking on this mode will continuously repeat the playlist'}>
                                                                <InfoIcon/>
                                                            </Tooltip>
                                                        </div>
                                                    </Row>
                                                    <Row justify={'center'} style={{marginTop: '1vh'}}>
                                                        <p>Loop</p>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row justify={'start'} style={{margin: '0 0 2rem 0'}}>
                                                <Col span={14}>
                                                    <Row justify={'center'}>
                                                        <div className={'hd-control-div'} style={{
                                                            height: '2.6vw',
                                                            width: '8.2vw',
                                                            // marginLeft: '6vw'
                                                        }}>
                                                            <button className={'hd-control-btn'}
                                                                    style={{
                                                                        margin: '0 0 0 0.5rem',
                                                                        backgroundColor: dsState?.texturaPlayModeStatus === 1 ? '#A06174' : '#0E487A',
                                                                        width: '2vw',
                                                                        height: '2vw'
                                                                    }}
                                                                    onClick={_ => {
                                                                        const curId = [deviceData?.id]
                                                                        navigate('/proportal/images', {
                                                                            state: {
                                                                                textura_ids: [curId],
                                                                                title: 'Select one artwork to repeat',
                                                                                filter: 'current',
                                                                                checkMode: true,
                                                                                removeMode: true,
                                                                                repeatMode: true,
                                                                            }
                                                                        })
                                                                        //     post_api('/textura/shuffle', {
                                                                        //         textura_ids: curId,
                                                                        //         texturaPlayModeStatus: 2
                                                                        //     })
                                                                        //         .then(() => dispatch({
                                                                        //             type: 'set_play_mode',
                                                                        //             payload: 2
                                                                        //         })).catch(error => console.log('error', error))
                                                                        // }
                                                                    }}>
                                                                <RepeatOneIcon/>
                                                            </button>
                                                            <p className={'hd-control-p'} style={{
                                                                color: dsState?.texturaPlayModeStatus === 1 ? '#000' : '#D9D9D9',
                                                                // marginLeft: '1vw'
                                                            }}>
                                                                Repeat Single mode is on
                                                            </p>
                                                            <Tooltip
                                                                title={'Clicking on this mode will bring you to the current playlist. Select one image to play continuously'}>
                                                                <InfoIcon/>
                                                            </Tooltip>
                                                        </div>
                                                    </Row>
                                                    <Row justify={'center'} style={{marginTop: '1vh'}}>
                                                        <p>Repeat Single</p>
                                                    </Row>
                                                </Col>
                                                <Col span={6}>
                                                    <Row justify={'center'}>
                                                        <div className={'hd-control-div'} style={{
                                                            height: '2.6vw',
                                                            width: '8.2vw',
                                                        }}>
                                                            <button className={'hd-control-btn'}
                                                                    style={{
                                                                        margin: '0 0 0 0.5rem',
                                                                        backgroundColor: dsState?.texturaPlayModeStatus === 2 ? '#A06174' : '#0E487A',
                                                                        width: '2vw',
                                                                        height: '2vw'
                                                                    }}
                                                                    onClick={_ => {
                                                                        const curId = [deviceData?.id]
                                                                        post_api('/textura/shuffle', {
                                                                            textura_ids: curId,
                                                                            texturaPlayModeStatusV2: 2,
                                                                            version:2
                                                                        }).then(() => {
                                                                            dispatch({
                                                                                type: 'set_play_mode',
                                                                                payload: 2
                                                                            })
                                                                        }).catch(error => console.log('error', error))
                                                                    }}>
                                                                <ShuffleIcon/>
                                                            </button>
                                                            <p className={'hd-control-p'} style={{
                                                                color: dsState?.texturaPlayModeStatus === 2 ? '#000' : '#D9D9D9',
                                                            }}>
                                                                Shuffle mode is on
                                                            </p>
                                                            <Tooltip
                                                                title={'Shuffle mode is also based on Repeat mode, meaning the playlist will continue playing in the shuffled order.'}>
                                                                <InfoIcon/>
                                                            </Tooltip>
                                                        </div>
                                                    </Row>
                                                    <Row justify={'center'}
                                                         style={{marginTop: '1vh'}}>
                                                        <p>Shuffle</p>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row justify={'start'}>
                                            <Button className={'hard-btn'}
                                                    style={{
                                                        width: '8vw',
                                                        marginLeft: '-1.6vw'
                                                    }}
                                                    onClick={() => {
                                                        navigate('/proportal/images', {
                                                            state: {
                                                                textura_ids: [deviceData.id],
                                                                title: 'Select artwork to the current playlist',
                                                                filter: -1,
                                                                checkMode: true
                                                            }
                                                        })
                                                    }}
                                            >
                                                Add Artwork
                                            </Button>
                                            <Button className={'hard-btn'}
                                                    style={{
                                                        width: '9vw',
                                                        marginLeft: '2vw'
                                                    }}
                                                    onClick={() => {
                                                        navigate('/proportal/images', {
                                                            state: {
                                                                textura_ids: [deviceData.id],
                                                                title: 'Please Remove Artworks',
                                                                filter: 'current',
                                                                checkMode: true,
                                                                removeMode: true,
                                                            }
                                                        })
                                                    }}
                                            >
                                                Remove Artwork
                                            </Button>
                                        </Row>
                                    </div>}

                                </Col>
                            </Row>
                            {/*<Row>*/}
                            {/*    <Button className={'hard-btn'}*/}
                            {/*            style={{*/}
                            {/*                width: '199.685px',*/}
                            {/*                margin: '2rem 0 2rem 6rem',*/}
                            {/*                display: cur === 0 ? 'flex' : 'none'*/}
                            {/*            }}*/}
                            {/*            onClick={() => {*/}
                            {/*                setNewScheduleOpen(true)*/}
                            {/*                setInfoChanged(true)*/}
                            {/*            }}*/}
                            {/*    >*/}
                            {/*        Add New Schedule*/}
                            {/*    </Button>*/}
                            {/*</Row>*/}
                            {/*<Row className={'hard-week-above-row'} style={{*/}
                            {/*    display: newScheduleOpen && cur === 0 ? 'flex' : 'none',*/}
                            {/*}}>*/}
                            {/*    <span className={'hard-st-time-txt'}>Start time:</span>*/}
                            {/*    <span className={'hard-end-time-txt'}>End time:</span>*/}
                            {/*</Row>*/}
                            {/*<Row className={'hard-week-row'} style={{*/}
                            {/*    display: newScheduleOpen && cur === 0 ? 'flex' : 'none',*/}
                            {/*}}>*/}
                            {/*    <TimePicker className={'hard-input-week'}*/}
                            {/*                onChange={(time, timeString) =>*/}
                            {/*                    dispatch({type: 'add_schedule', item: {start_time: timeString}})*/}
                            {/*                }*/}
                            {/*                format="HH:mm"*/}
                            {/*    />*/}
                            {/*    <TimePicker className={'hard-input-week'}*/}
                            {/*                onChange={(time, timeString) =>*/}
                            {/*                    dispatch({type: 'add_schedule', item: {end_time: timeString}})*/}
                            {/*                }*/}
                            {/*                format="HH:mm"*/}
                            {/*    />*/}
                            {/*    {DAYS_A_WEEK.map((day, index) => (*/}
                            {/*        <div*/}
                            {/*            key={index}*/}
                            {/*            className={'hard-week-btn'}*/}
                            {/*            style={{backgroundColor: dsState.schedule.days.includes(index) ? '#0E487A' : '#D9D9D9',}}*/}
                            {/*            onClick={() => {*/}
                            {/*                const newDays = dsState.schedule.days.includes(index)*/}
                            {/*                    ? dsState.schedule.days.filter(d => d !== index)*/}
                            {/*                    : [...dsState.schedule.days, index];*/}
                            {/*                dispatch({type: 'add_schedule', item: {days: newDays}})*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            {day}*/}
                            {/*        </div>*/}
                            {/*    ))}*/}
                            {/*</Row>*/}
                            <Row className={'hard-final-row'}
                                 style={{
                                     marginTop: cur === 0 ? '0' : '4rem',
                                     // display: infoChanged && cur !== 2 ? 'flex' : 'none',
                                     display: 'none'
                                 }}>
                                <Button className={'hard-week-final-btn'}
                                        onClick={() => {
                                            const curId = [deviceData.id]
                                            post_api('/textura/create/schedule', {
                                                "textura_ids": curId,
                                                ...dsState.schedule
                                            }).then(_ => window.location.reload()).catch(error => console.error('Error:', error));
                                        }}
                                >
                                    Save
                                </Button>
                                <Button className={'hard-week-final-btn'}
                                        onClick={() => {
                                            window.location.href = '/proportal/dashboard'
                                        }}
                                >
                                    Close
                                </Button>
                            </Row>
                            <Row className={'hard-final-row'}
                                 style={{
                                     marginTop: cur === 0 ? '0' : '4rem',
                                     // display: nameChanged && cur !== 2 ? 'flex' : 'none',
                                     display: 'none'
                                 }}>
                                <Button className={'hard-week-final-btn'}
                                        onClick={() => {
                                            const curId = deviceData.id
                                            post_api('/textura/change/textura/name', {
                                                "textura_id": curId,
                                                new_textura_name: deviceName
                                            })
                                                // .then(_ => window.location.reload())
                                                .catch(error => console.error('Error:', error));
                                        }}
                                >
                                    Save
                                </Button>
                                <Button className={'hard-week-final-btn'}
                                        onClick={() => {
                                            window.location.href = '/proportal/dashboard'
                                        }}
                                >
                                    Close
                                </Button>
                            </Row>
                        </Layout>
                        <Modal
                            className={'ds-ml-schedule-modal'}
                            visible={isScheduleModalOpen}
                            onOk={_ => setIsScheduleModalOpen(false)}
                            onCancel={_ => setIsScheduleModalOpen(false)}
                        >
                            <Row justify={'center'}>
                                <p className={'ds-ml-schedule-modal-h'}>Add New Schedule</p>
                            </Row>
                            <p className={'ds-ml-new-schedule-txt'}>
                                Your Textura digital canvas will automatically default as <span style={{
                                fontWeight: '600',
                                textDecoration: 'underline'
                            }}>always on</span> until you have
                                created
                                a schedule.
                            </p>
                            <Row className={'hard-week-above-row'} style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                margin: '0 0 0 0'
                            }}>
                                    <span className={'hard-st-time-txt'}
                                          style={{
                                              margin: '0 0 0 -4rem'
                                          }}>Start time:</span>
                                <span className={'hard-end-time-txt'} style={{
                                    margin: '0 0 0 0rem'
                                }}>End time:</span>
                            </Row>
                            <Row className={'hard-week-row-mul'} style={{}}>
                                <TimePicker className={'hard-input-week'}
                                            onChange={(time, timeString) =>
                                                dispatch({type: 'add_schedule', item: {start_time: timeString}})
                                            }
                                            format="HH:mm"
                                />
                                <TimePicker className={'hard-input-week'}
                                            onChange={(time, timeString) =>
                                                dispatch({type: 'add_schedule', item: {end_time: timeString}})
                                            }
                                            format="HH:mm"
                                />
                                {DAYS_A_WEEK.map((day, index) => (
                                    <div
                                        key={index}
                                        className={'hard-week-btn'}
                                        style={{
                                            backgroundColor: dsState.schedule.days.includes(index) ? '#0E487A' : '#D9D9D9',
                                            width: '26rem',
                                            margin: '1rem 0 0 0',
                                            height: '2.1rem',
                                            borderRadius: '11px',
                                        }}
                                        onClick={() => {
                                            const newDays = dsState.schedule.days.includes(index)
                                                ? dsState.schedule.days.filter(d => d !== index)
                                                : [...dsState.schedule.days, index];
                                            dispatch({type: 'add_schedule', item: {days: newDays}})
                                        }}
                                    >
                                        {day}
                                    </div>
                                ))}
                            </Row>
                            <Row justify={"center"} style={{margin:'-2.8rem 0 1rem 0'}}>
                                <p style={{fontWeight:"bold",width:'100vw'}}>
                                    Your canvas will restart immediately once new schedule has been saved
                                </p>
                            </Row>
                            <Row className={'hard-final-row'}>
                                <Button className={'hard-week-final-btn-mul'}
                                        onClick={() => {
                                            const curId = deviceData.id
                                            post_api('/textura/create/schedule', {
                                                "textura_ids": [curId],
                                                ...dsState.schedule
                                            }).then(
                                                _ => setIsScheduleModalOpen(false)
                                            ).then(_=>window.location.reload())
                                                .catch(error => console.error('Error:', error));
                                        }}
                                >
                                    Save
                                </Button>
                                <Button className={'hard-week-final-btn-mul'}
                                        onClick={() => {
                                            window.location.href = '/proportal/textura'
                                        }}
                                >
                                    Close
                                </Button>
                            </Row>
                        </Modal>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    )
}

export default Hardware;
