import React, {useEffect, useState} from 'react';
import {Link, redirect, useNavigate} from 'react-router-dom';
import {Layout, Col, Row, Card, Space, Button} from 'antd';
import '../../App.css';
import "./HomePage.scss";
import "../Textura/Textura.css"
import FullScreenBgVideo from "../../components/BgVideo/FullScreenBgVideo";
import {Image} from "antd";
import {Content} from "antd/es/layout/layout";
import {DiscoverText, HOME_TEXT, TEXTURA_TEXT} from "../../text.js";
import landscape_vd from "../../img/HomePage/1 - Header Video/Vieunite Launch - Homepage - Landscape 2.mp4";
import vertical_vd from "../../img/HomePage/1 - Header Video/3334ce07-9121-4893-ba16-c3d5db436bc3.mp4";
import {HomepageMeta} from "../../components/PageMeta/HomepageMeta";
import Artist_Img from "../../img/HomePage/3 - For the Artists/artists.webp";
import Artwork_Img from "../../img/HomePage/4 - For the Artists/artwork.webp";
import Discover_Vieunite_Img from "../../img/HomePage/8 - Discover Vieunite World/Discover_New image 2023.webp";
import Vieunite_App_Vd from "../../img/HomePage/7 - Vieunite App/phone.webm";
import Our_Mission_Img from "../../img/HomePage/6 - Our Mission/4W8A0216-1.webp";
import WhatIs_V from "../../img/HomePage/2 - What is Vieunite/Vieunite Video - Teaser 2.mp4";
import Textura_Art_Img from "../../img/HomePage/5 - The Textura of Art/Textura-Canvas-Homepage-_1_-White-BG.webp";
import QR_Img from "../../img/AppDownload/download.png";
import NTULogo from "../../img/HomePage/9 - Logos/ntu.svg"
import UKTILogo from "../../img/HomePage/9 - Logos/innovate - 2024.svg"
import RBSALogo from "../../img/HomePage/9 - Logos/rbsa.svg"
import RoyalLogo from "../../img/HomePage/9 - Logos/the royal society.svg"
import SaleModal from "../../components/SaleModal";
import {ReactComponent as CreativeBloq} from './img/Creative Bloq wide.svg'
import {ReactComponent as DigitalCameraWorld} from './img/Digital Camera World.svg'
import {ReactComponent as FinancialTimes} from './img/Financial Times.svg'
import {ReactComponent as GQ} from './img/GQ.svg'
import {ReactComponent as Selfridges} from "./img/Selfridges.svg";
import {ReactComponent as Stuff} from "./img/Stuff.svg";
import {ReactComponent as T3} from "./img/T3.svg";
import {ReactComponent as TechRadar} from "./img/Tech Radar wide.svg";
import {ReactComponent as TheAmbient} from "./img/The Ambient.svg";

const Primary_Font = {
    fontFamily: "Proxima Nova bold, sans-serif",
}

const TitleFont = {
    fontSize: '38px',
    fontFamily: "Proxima Nova bold, sans-serif",
}

const Img_Radius = {
    borderRadius: '0.625rem',
}

function HomePage() {
    // For SSG through "react-snap", can't assign the "mute" property directly on <video> tags.
    // So using the following function to do it
    const makeMuted = (elt) => {
        if (elt) {
            elt.muted = true;
        }
    };
    const navigate = useNavigate();
    return (
        <div>
            <HomepageMeta></HomepageMeta>
            <Layout>
                <Content style={{width: '100%', overflowX: 'hidden'}}>
                    <div>
                        <FullScreenBgVideo
                            mobileUrl={vertical_vd}
                            desktopUrl={landscape_vd}
                        />
                        <div className={"title-over-video"}>
                            <div className={'txt-over-v'}>
                                <p>{HOME_TEXT["Video Cover"]}</p>
                            </div>
                            <div>
                                <button className="btn">
                                    <a className={'in-btn-link a-over-v'} href={"/home/digital-canvas"}>
                                        LEARN MORE
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                    <Content style={{width: '100%', overflowX: 'hidden'}}>
                        {/*What is Vieunite*/}
                        <Row justify={"center"} style={{
                            backgroundColor: '#f4f4f4',
                            padding: '2rem 0 4rem 0'
                        }}>
                            <Col span={24} lg={10} xs={20}>
                                <div className="text-container">
                                    <h2 className="title" style={{...TitleFont}}>What is Vieunite?</h2>
                                    <span className="text-content" style={{
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        color: 'black',
                                        ...Primary_Font
                                    }}>
                                        {HOME_TEXT["What is Vieunite?"]}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <p
                            style={{
                                textAlign: 'center',
                                fontWeight: '600',
                                fontSize: '24px',
                                color: 'black',
                                ...Primary_Font,
                                margin: '4vh'
                            }}>As featured in</p>
                        <Row justify={"center"}
                             className={'home-logo-row'}
                             style={{}}>
                            <FinancialTimes className={'home-logo-icons'}/>
                            <GQ className={'home-logo-icons'}/>
                            <T3 className={'home-logo-icons'}/>
                            <CreativeBloq className={'home-logo-icons'}/>
                            <TheAmbient className={'home-logo-icons'}/>
                            <TechRadar className={'home-logo-icons'}/>
                            <Stuff className={'home-logo-icons'}/>
                            <DigitalCameraWorld className={'home-logo-icons'}/>
                            <Selfridges className={'home-logo-icons'}/>
                        </Row>

                        <Row className={'home-next-logo-row'} justify={"center"} style={{margin: '4rem 0 2rem 0'}}>
                            <Col span={10} lg={10} xs={20}>
                                <div className="video-container">
                                    <Image
                                        className={'artist-img'}
                                        src={Artist_Img}
                                        preview={false}
                                        style={{borderRadius: '.625rem'}}
                                        onClick={() => {
                                            navigate('/artists')
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col span={10} lg={{span: 8, offset: 2}} xs={20}>
                                <div className="vertical-center-text">
                                    <h2 className="title-app" style={{...TitleFont}}>For the Artists.</h2>
                                    <span className="text-content-app"
                                          style={{
                                              color: 'black',
                                              fontWeight: '500',
                                              marginBottom: '2em',
                                          }}
                                    >
                                        {HOME_TEXT["For the Artists"]}
                                    </span>
                                    <Link
                                        to={'/artists'}
                                        style={{
                                            ...Primary_Font,
                                            fontWeight: '700',
                                            fontSize: '17px',
                                            color: 'black',
                                        }}
                                    >
                                        DISCOVER MORE >
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        <Row className={'m-img-text'} justify={"center"} style={{margin: '4rem 0 4rem 0'}}>
                            <Col span={10} lg={{span: 8,}} xs={20}>
                                <div className="vertical-center-text">
                                    <h2 className="title-app mobile-left" style={{textAlign: "right", ...TitleFont}}>For
                                        the Art Collectors.</h2>
                                    <span className="text-content-app mobile-left" style={{
                                        color: 'black',
                                        fontWeight: '500',
                                        marginBottom: '2em',
                                        textAlign: 'right',
                                    }}>
                                        {HOME_TEXT["For the Art Lovers"]}
                                    </span>
                                    <Link className={'mobile-left'} style={{
                                        ...Primary_Font,
                                        fontWeight: '700',
                                        fontSize: '17px',
                                        color: "black",
                                        textAlign: 'right',
                                    }}
                                          to={'/artists'}>
                                        <p>DISCOVER MORE ></p>
                                    </Link>
                                </div>
                            </Col>
                            <Col span={10} lg={{span: 10, offset: 2}} xs={20}>
                                <div className="video-container">
                                    <Image
                                        src={Artwork_Img}
                                        preview={false}
                                        style={{borderRadius: '.625rem'}}
                                        className={'artist-img'}
                                        onClick={() => {
                                            navigate('/artists')
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        {/*The Textura of Art*/}
                        <Row justify={"center"}
                             className={"img-text-zone m-padding-bottom"}
                             gutter={16}
                             style={{
                                 backgroundColor: '#000000',
                                 paddingBottom: '6rem'
                             }}
                        >
                            <Col span={0} lg={0} xs={20}>
                                <h2 className="title-app"
                                    style={{color: 'white', ...TitleFont}}>
                                    Texture Accurate Technology.</h2>
                            </Col>
                            <Col span={10} lg={10} xs={20} style={{marginTop: '1rem', marginBottom: '-1.6rem'}}>
                                <div className="video-container">
                                    <Image
                                        style={{...Img_Radius}}
                                        src={Textura_Art_Img}
                                        preview={false}
                                        className={'artist-img'}
                                        onClick={() => {
                                            navigate('/home/digital-canvas')
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col span={12} lg={10} xs={20} style={{marginLeft: '3em'}}
                                 className={'no-left-margin mtop'}>
                                <div className="vertical-center-text">
                                    <h2 className="title-app m-none" style={{color: 'white', ...TitleFont}}>
                                        Texture Accurate Technology.</h2>
                                    <p
                                        style={{
                                            fontFamily: "Proxima Nova, sans-serif",
                                            color: 'white',
                                            fontWeight: '500',
                                            fontSize: '1.25rem',
                                        }}
                                        className={'no-left-margin m-tt'}
                                    >
                                        {HOME_TEXT["The Textura of Art"]}
                                    </p>
                                    <Link
                                        style={{
                                            ...Primary_Font,
                                            fontWeight: '700',
                                            fontSize: '17px',
                                            color: "white",
                                        }}
                                        to={'/digital-canvas'}
                                    >
                                        EXPLORE TEXTURA DIGITAL CANVAS>
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        {/*Our Mission Section*/}
                        <Row justify={"center"}
                             style={{
                                 backgroundColor: '#f4f4f4',
                                 paddingTop: '2em',
                                 paddingBottom: '4em',
                             }}
                        >
                            <Col lg={15} xs={20}>
                                <div className="text-container">
                                    <h2 className="title" style={{...TitleFont}}>Our Mission.</h2>
                                    <span className="text-content"
                                          style={{
                                              fontWeight: '600',
                                              fontSize: '24px',
                                              color: 'black',
                                          }}
                                    >
                                        {HOME_TEXT["Our Mission"]}
                                    </span>
                                </div>
                            </Col>
                        </Row>

                        <Row justify={"center"}
                             style={{
                                 backgroundColor: '#f4f4f4',
                                 columnGap: '0vw',
                                 padding: '0 0 4em 0'
                             }}
                        >
                            <Col lg={20} xs={20}>
                                <div className="video-container"
                                     style={{}}>
                                    <Image
                                        height={'100%'}
                                        width={'100%'}
                                        src={Our_Mission_Img}
                                        preview={false}
                                        style={{
                                            ...Img_Radius,
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        {/*App Section*/}
                        <Row
                            className={'m-padding'}
                            justify={"center"}
                            style={{
                                backgroundColor: '#2f3131',
                                padding: '8em 0 0 0',
                            }}
                        >
                            <Col lg={8} xs={20} style={{display: 'flex', alignItems: 'center'}}>
                                <div className="text-container " style={{margin: '1rem 0 0 0'}}>
                                    <h2 className="title-app" style={{...TitleFont, color: "white"}}>
                                        The Vieunite App.
                                    </h2>
                                    {HOME_TEXT["From the Vieunite App to Textura"].map((txt, idx) =>
                                        <p className="text-content-app"
                                           key={idx}
                                           style={{
                                               ...Primary_Font,
                                               color: "white",
                                               fontWeight: '500'
                                           }}>
                                            {txt}
                                        </p>)}
                                </div>
                            </Col>
                            <Col lg={0} xs={20}>
                                <button type="submit"
                                        className={"pay-button"}
                                        onClick={() => {
                                            if ((/Android/i.test(navigator.userAgent))) {
                                                window.location.href = 'https://play.google.com/store/apps/details?id=com.app.vieunite&gl=GB';
                                            }
                                            if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {
                                                window.location.href = 'https://apps.apple.com/gb/app/vieunite/id1644381065';
                                            }
                                        }}
                                        style={{
                                            fontFamily: "Proxima Nova, sans-serif",
                                            fontWeight: '800', borderRadius: "10px",
                                            width: '100%',
                                            padding: '1em 0 1em 0',
                                            color: "black",
                                            backgroundColor: 'white'
                                        }}
                                >
                                    DOWNLOAD THE VIEUNITE APP
                                </button>
                            </Col>

                            <Col lg={{span: 6, offset: 2}} xs={24}>
                                <div className="video-container"
                                     style={{
                                         display: "flex"
                                     }}>
                                    <video playsInline loop autoPlay ref={makeMuted} muted rel={"preload"} style={{
                                        width: '100%',
                                        height: '100%'
                                    }}>
                                        <source src={Vieunite_App_Vd}></source>
                                    </video>
                                </div>
                            </Col>
                        </Row>
                        <Row className={'dt-download-app'}
                             justify={"center"}
                             style={{backgroundColor: '#2f3131',}}
                        >
                            <Col lg={6} xs={0}
                                 style={{
                                     display: 'flex',
                                     justifyContent: 'center'
                                 }}>
                                <button type="submit"
                                        className={"app-button"}
                                        onClick={() => {
                                            window.location.href = 'https://apps.apple.com/gb/app/vieunite/id1644381065'
                                        }}
                                        style={{}}

                                >
                                    IOS APP STORE
                                </button>
                            </Col>
                            <Col lg={6} xs={0} style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <button type="submit"
                                        className={"app-button"}
                                        onClick={() => {
                                            window.location.href = 'https://play.google.com/store/apps/details?id=com.app.vieunite&gl=GB'
                                        }}
                                >
                                    GOOGLE PLAY STORE
                                </button>
                            </Col>
                        </Row>
                        <Row justify={"center"} className={'m-none'}
                             style={{backgroundColor: '#2f3131', padding: '1em 0 1em 0'}}>
                            <Col lg={5} style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Image
                                    src={QR_Img}
                                    preview={false}
                                    height={'100%'}
                                    width={'40%'}
                                />
                            </Col>
                        </Row>
                        <Row justify={"center"} className={'m-none'} style={{backgroundColor: '#2f3131',}}>
                            <Col lg={6} style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <p style={{color: "white", fontSize: '1em'}}>
                                    Scan to join the Vieunite community.
                                </p>
                            </Col>
                        </Row>
                        {/*Final Section to News*/}
                        <Row justify={"space-evenly"}
                             style={{
                                 backgroundColor: '#f4f4f4',
                                 paddingBottom: '8rem'
                             }}
                             className={"img-text-zone m-img-text"}
                        >
                            <Col span={12} lg={12} xs={20}>
                                <div className="video-container no-left-margin"
                                     style={{marginLeft: '-4.5vw'}}>
                                    <Image
                                        style={{...Img_Radius}}
                                        src={Discover_Vieunite_Img}
                                        preview={false}
                                        className={'artist-img'}
                                        onClick={() => {
                                            window.location.href = 'https://vieunite.com/news/'
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col span={12} lg={11} xs={20}>
                                <div className="vertical-center-text">
                                    <h2 className="title-app" style={{...TitleFont}}>
                                        Explore The World of Vieunite.
                                    </h2>
                                    <DiscoverText/>

                                    {/*{HOME_TEXT["Discover the Vieunite World"].map((*/}
                                    {/*    txt, idx) =>*/}
                                    {/*    <p className="text-content-app" key={idx} style={{*/}
                                    {/*        ...Primary_Font,*/}
                                    {/*        color: 'black',*/}
                                    {/*        fontWeight: '500',*/}
                                    {/*    }}>*/}
                                    {/*        {txt}*/}
                                    {/*    </p>*/}
                                    {/*)}*/}

                                    {/*<a*/}
                                    {/*    style={{*/}
                                    {/*        ...Primary_Font,*/}
                                    {/*        fontWeight: '700',*/}
                                    {/*        fontSize: '17px',*/}
                                    {/*        color: "black",*/}
                                    {/*    }}*/}
                                    {/*    href={'https://vieunite.com/news/'}>*/}
                                    {/*    DISCOVER MORE >*/}
                                    {/*</a>*/}
                                </div>
                            </Col>
                        </Row>
                        <Row className={'logo-row'} justify={"center"}
                             style={{backgroundColor: '#f4f4f4', padding: '0 0 6rem 0'}}>
                            <Col className={'dt-logos'} span={24} lg={20} xs={20}>
                                <h2 className="title-logo" style={{
                                    fontSize: '1.5rem',
                                    paddingBottom: '4rem',
                                    lineHeight: '36px',
                                }}
                                >
                                    IN PARTNERSHIP WITH
                                </h2>
                                <Row justify={"center"} style={{gap: '0'}}>
                                    <Col lg={6} xs={0}
                                         style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img onClick={() => {
                                            window.open("https://www.ukri.org/councils/innovate-uk/")
                                        }}
                                             alt={'logo'} className={'logo-img'} src={UKTILogo}></img>
                                    </Col>
                                    <Col lg={6} xs={0}
                                         style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img onClick={() => {
                                            window.open("https://rbsa.org.uk/")
                                        }}
                                             alt={'logo'} className={'logo-img'} src={RBSALogo}></img>
                                    </Col>
                                    <Col lg={6} xs={0}
                                         style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img onClick={() => {
                                            window.open("https://www.ntu.ac.uk/study-and-courses/academic-schools/art-and-design")
                                        }}
                                             alt={'logo'} className={'logo-img'} src={NTULogo}></img>
                                    </Col>
                                    <Col lg={6} xs={0}
                                         style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img onClick={() => {
                                            window.open("https://royalsociety.org/")
                                        }}
                                             alt={'logo'} className={'logo-img'} src={RoyalLogo}></img>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className={'m-logos'} span={24} lg={20} xs={24}>
                                <h2 className="title-logo" style={{
                                    fontSize: '1rem',
                                    paddingBottom: '2rem',
                                }}
                                >
                                    IN PARTNERSHIP WITH
                                </h2>
                                <Row justify={"center"}>
                                    <Col lg={0} xs={10}
                                         style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img onClick={() => {
                                            window.open("https://www.ukri.org/councils/innovate-uk/")
                                        }}
                                             alt={'logo'} className={'logo-img'} src={UKTILogo}></img>
                                    </Col>
                                    <Col lg={0} xs={10}
                                         style={{display: "flex", justifyContent: 'center', alignItems: "center"}}>
                                        <img onClick={() => {
                                            window.open("https://rbsa.org.uk/")
                                        }}
                                             alt={'logo'} className={'logo-img'} src={RBSALogo}></img>
                                    </Col>
                                </Row>
                                <Row justify={"center"} style={{paddingTop: '2.6rem'}}>
                                    <Col lg={0} xs={10}
                                         style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img onClick={() => {
                                            window.open("https://www.ntu.ac.uk/study-and-courses/academic-schools/art-and-design")
                                        }}
                                             alt={'logo'} className={'logo-img'} src={NTULogo}></img>
                                    </Col>
                                    <Col lg={0} xs={10}
                                         style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img onClick={() => {
                                            window.open("https://royalsociety.org/")
                                        }}
                                             alt={'logo'} className={'logo-img'} src={RoyalLogo}></img>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Content>
            </Layout></div>
    );
}

export default HomePage;