import React, {useState} from 'react';
import {Button, Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import img1 from './imgs/20240620-100905.jpeg'
import './style.scss'
import {SFW_TEXT} from "./text";
import img3 from './imgs/UPDATED_01.webp'
import img_1 from './imgs/Discover Persperctive 1.png'
import img_2 from './imgs/My Library - NEW.webp'
import img_3 from './imgs/Dashboard Perspective 1.png'
import img_4 from './imgs/My Account Perspective 1.png'
import qrcode from './imgs/qr-code-temp.png'
import {useNavigate} from "react-router-dom";
import useWindowResize from "../../apps/Hooks/useWindowResize";
import m_img_1 from './imgs/m_img_sfw_1.webp'
import m_img_2 from './imgs/m_img_sfw_2.png'
import m_img_3 from './imgs/m_img_sfw_3.png'
import m_img_4 from './imgs/m_img_sfw_4.png'
import m_img_5 from './imgs/m_img_sfw_5.png'
import m_img_6 from './imgs/m_img_sfw_6.png'

const Software = (props) => {
    const [hoverText, setHoverText] = useState(null);
    const navigate = useNavigate()
    const {windowWidth} = useWindowResize()

    const circles = [
        {
            id: 'art-library', label: 'Explore our ART LIBRARY',
            text: (
                <p>
                    Explore the Vieunite Pro <span style={{fontWeight: "bold"}}>Art Library</span>, where you can find
                    art of every genre. Fully transform your business surrounding with timeless classic pieces or fresh
                    contemporary artwork.
                </p>
            ),
        },
        {
            id: 'curated-collections', label: 'View exquisite CURATED COLLECTIONS', text: (
                <p>
                    Our Curated Collections are meticulously selected by our in-house
                    experts and feature works from world-renowned art galleries. Alternatively, you can work directly
                    with Vieunite Artists, a selective group of creatives who show incredible potential.
                </p>
            ),
        },
        {
            id: 'image-video-upload', label: 'Upload VIDEO CONTENT and IMAGES', text: (
                <p>
                    With Textura Pro, you can curate your personal gallery by uploading your own content.
                    Our digital canvas seamlessly displays both static images and video content, ensuring an immersive
                    viewing experience for your customers.
                </p>
            ),
        },
        {
            id: 'playlists', label: 'CREATE PLAYLISTS', text: (
                <p>
                    Fully personalise your business spaces by creating playlists for each Textura digital canvas and
                    categorising them by colour scheme, theme, artist or genre.
                </p>
            )
        },
        {
            id: 'manage-devices', label: 'MANAGE DEVICES', text: (
                <p>
                    With the Vieunite Pro Portal you can easily manage your digital canvases no matter when or where.
                    Take advantage of the flexibility that our software offers and make each business location truly
                    unique for your customers.
                </p>
            )
        },
        {
            id: 'art-market', label: 'Connect with a thriving ART MARKET', text: (
                <p>
                    Vieunite Pro gives you exclusive access to our Art Market, a space where you can buy specific pieces
                    of art or request commission bespoke artwork for your businesses.
                    This market connects you with our Vieunite Artists, giving you the opportunity to support them
                    directly.
                </p>
            )
        },
    ];
    return (
        <Layout>
            {windowWidth > 600 && <Content>
                <Row justify={"center"}
                     style={{
                         margin: '8rem 0 0 0',
                         display: "flex",
                         flexDirection: "column",
                         alignItems: "center",
                         justifyContent: "center"
                     }}
                >
                    <p className={'sfw-head'}>
                        Take control
                    </p>
                    <p className={'sfw-subhead'}>
                        of what you display with our intuitive software
                    </p>
                </Row>
                <Row justify={"center"}>
                    <Col lg={20} className={'sfw-circle-col'}>
                        <img src={img1} className={'backgroundImage'}/>
                        {circles.map((circle) => (
                            <div
                                key={circle.id}
                                className={`circle ${circle.id}`}
                                onMouseEnter={() => setHoverText(circle.text)}
                                onMouseLeave={() => setHoverText(null)}
                                style={{fontSize: '0.675vw'}}
                            >{circle.label}</div>
                        ))}
                        <div className="center-text">{hoverText}</div>
                    </Col>
                </Row>
                <Row justify={"center"}>
                    <Button className={'sft-btn-portal pro-btn-hover-to-white'}
                            onClick={_ => {
                                if (localStorage.getItem('token')) {
                                    window.location.href = '/proportal/'
                                } else {
                                    navigate('/business/prosignin', {state: {nextPage: '/proportal/'}})
                                }
                            }}
                    >
                        Explore the Vieunite Portal
                    </Button>
                </Row>

                <Row justify={"center"} style={{margin: '4rem 0 0 0', background: '#D9D9D9'}}>
                    <Col className={'sfw-img-col'}>
                        <img src={img_1} style={{
                            width: '55vw',
                            height: "auto",
                            margin: '0 0 -10vh 0'
                        }}
                             className={'sfw-img'}/>
                    </Col>
                    <Col className={'sfw-img-col'}>
                        <p className={'sfw-small-title'}>Discover Art</p>
                        <p className={'sfw-small-txt'}>{SFW_TEXT['Discover Art']}</p>
                    </Col>
                </Row>
                <Row justify={"center"} style={{background: '#EEE'}}>
                    <Col className={'sfw-img-col'}>
                        <p className={'sfw-small-title'}>Organise your content</p>
                        <p className={'sfw-small-txt'}>{SFW_TEXT['My Library']}</p>
                    </Col>
                    <Col className={'sfw-img-col'}>
                        <img src={img_2} style={{
                            width: '60vw',
                            height: "auto",
                            margin: '0vh 0 -15vh 10vw'
                        }} className={'sfw-img'}/>
                    </Col>
                </Row>
                <Row justify={"center"} style={{background: '#D9D9D9'}}>
                    <Col className={'sfw-img-col'}>
                        <img src={img_3} style={{
                            width: '65vw',
                            height: "auto",
                            margin: '-4vh 0 -10vh 0'
                        }} className={'sfw-img'}/>
                    </Col>
                    <Col className={'sfw-img-col'}>
                        <p className={'sfw-small-title'}>Take control of your hardware</p>
                        <p className={'sfw-small-txt'}>{SFW_TEXT['Dashboard']}</p>
                    </Col>
                </Row>
                <Row justify={"center"} style={{margin: '0 0 8vh 0', background: '#EEE'}}>
                    <Col className={'sfw-img-col'}>
                        <p className={'sfw-small-title'}>Manage accounts and settings</p>
                        <p className={'sfw-small-txt'}>{SFW_TEXT['Account']}</p>
                    </Col>
                    <Col className={'sfw-img-col'}>
                        <img src={img_4} style={{
                            width: '60vw',
                            height: "auto",
                            margin: '-7vh 0 -10vh 0'
                        }} className={'sfw-img'}/>
                    </Col>
                </Row>
                <Row justify={"center"}>
                    <Col lg={20}>
                        <div className="image-container">
                            <img src={img3} alt="Background"
                                 className="background-image"
                                 style={{
                                     width: '76vw',
                                     // height:'80vh'
                                     margin: "2rem auto 8rem auto"
                                 }}
                            />
                            <div className="sfw-content">
                                <p className={'content-head'} style={{marginBottom: '6vh'}}>The Pairing App</p>
                                <p className={'content-txt'}>
                                    An easy-to-use app has been designed specifically for connecting hardware devices.
                                    Its functions include pairing the portal with hardware devices, changing the names
                                    of the hardware, removing hardware, and providing quick control to switch the
                                    hardware on/off.
                                </p>
                                <p className={'content-txt'} style={{fontWeight: "bold"}}>
                                    Use either of the buttons below to be taken to the app store for your device
                                </p>
                                <Row justify={"center"}
                                     style={{
                                         margin: '-12vh 0 0 -2.6vw',
                                         width: '24vw'
                                     }}
                                >
                                    {/*<Col lg={10}>*/}
                                    {/*    <img*/}
                                    {/*        style={{*/}
                                    {/*            'width': '7.2vw', 'height': '7.2vw'*/}
                                    {/*        }}*/}
                                    {/*        alt={'qrcode'}*/}
                                    {/*        src={qrcode}*/}
                                    {/*    />*/}

                                    {/*</Col>*/}
                                    <Col style={{marginLeft: '-1.2vw',width:'10vw',height:'10vh'}}>
                                        <Button className={'content-btn pro-btn-hover-to-white'}
                                                onClick={_ => window.open('https://apps.apple.com/gb/app/vieunite-pro-connect/id6499339979')}
                                                style={{
                                                    width: '12vw',
                                                    height: '4vh',
                                                    fontSize: '0.9vw',
                                                    margin: '0vh 0 4vh 0 '
                                                }}
                                        >
                                            iOS App Store
                                        </Button>
                                        <Button className={'content-btn pro-btn-hover-to-white'}
                                            onClick={_ => window.location.href = 'https://play.google.com/store/apps/details?id=com.vieunite.vieunitepro&pcampaignid=web_share'}
                                                style={{
                                                    width: '12vw',
                                                    height: '4vh',
                                                    fontSize: '0.9vw',
                                                    margin:'0 0 0 0'
                                                }}
                                        >
                                            Google Play Store
                                        </Button>
                                    </Col>
                                </Row>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Content>}
            {windowWidth <= 600 && <Content style={{background: '#F4F4F4'}}>
                <Row justify={"center"}
                     style={{
                         margin: '8rem 0 0 0',
                         display: "flex",
                         flexDirection: "column",
                         alignItems: "center",
                         justifyContent: "center"
                     }}
                >
                    <p className={'m-sfw-head'}>
                        Take control
                    </p>
                    <p className={'m-sfw-subhead'}>
                        of what you display with our intuitive software
                    </p>
                </Row>
                <Row justify={"center"} style={{margin: '4vh 0 0 0'}}>
                    <img src={m_img_1} style={{width: '80vw'}}/>
                </Row>
                <Row justify={"center"} style={{margin: '4vh 0 0 0'}}>
                    <div className={'m-sfw-div-1'}>
                        Explore our ART LIBRARY
                    </div>
                </Row>
                <Row justify={"center"} style={{margin: '4vh 0 0 0'}}>
                    <div className={'m-sfw-div-2'}>
                        View exquisite CURATED COLLECTIONS
                    </div>
                </Row>
                <Row justify={"center"} style={{margin: '4vh 0 0 0'}}>
                    <div className={'m-sfw-div-3'}>
                        Upload VIDEO CONTENT and IMAGES
                    </div>
                </Row>
                <Row justify={"center"} style={{margin: '4vh 0 0 0'}}>
                    <div className={'m-sfw-div-4'}>
                        CREATE PLAYLISTS
                    </div>
                </Row>
                <Row justify={"center"} style={{margin: '4vh 0 0 0'}}>
                    <div className={'m-sfw-div-5'}>
                        MANAGE DEVICES
                    </div>
                </Row>
                <Row justify={"center"} style={{margin: '4vh 0 0 0'}}>
                    <div className={'m-sfw-div-6'}>
                        Connect with a thriving ART MARKET
                    </div>
                </Row>


                <Row justify={"center"} style={{margin: '4rem 0 0 0', background: '#D9D9D9'}}>
                    <p className={'m-sfw-small-title'}>Discover Art</p>
                    <p className={'m-sfw-small-txt'}>Our vast library features thousands of pieces from artists and
                        galleries all over the globe. From classic to contemporary; we’ve got artwork for any space. We
                        invite you to reflect and connect with the art that resonates most deeply with you and your
                        brand.</p>
                    <img src={m_img_2} style={{
                        width: '100vw',
                        height: "auto",
                        margin: '0 0 0 0'
                    }}
                         className={'m-sfw-img'}/>
                </Row>
                <Row justify={"center"} style={{background: '#EEE'}}>
                    <p className={'m-sfw-small-title'}>Organise your content</p>
                    <p className={'m-sfw-small-txt'}>Easily upload, manage, and create your own playlists, seamlessly
                        mixing artwork with promotional content and internal communication messages. Create albums and
                        send them to your entire network of Textura Pro devices in one click. If you’re a Premium
                        subscriber, our expert team can create custom content perfectly tailored to your business.</p>
                    <img src={m_img_3} style={{
                        width: '100vw',
                        height: "auto",
                        margin: '0 0 0 0'
                    }}
                         className={'m-sfw-img'}/>
                </Row>
                <Row justify={"center"} style={{background: '#D9D9D9'}}>
                    <p className={'m-sfw-small-title'}>Take control of your hardware</p>
                    <p className={'m-sfw-small-txt'}>Our dashboard allows you to fully manage your hardware and content,
                        organise your devices into groups, upload artwork to your Textura Pro, schedule display times,
                        and customise settings. With built-in reporting statistics, you'll have a clear view of your
                        hardware's performance.</p>
                    <img src={m_img_4} style={{
                        width: '100vw',
                        height: "auto",
                        margin: '0 0 0 0'
                    }}
                         className={'m-sfw-img'}/>
                </Row>
                <Row justify={"center"} style={{margin: '0 0 8vh 0', background: '#EEE'}}>
                    <p className={'m-sfw-small-title'}>Manage accounts
                        and settings</p>
                    <p className={'m-sfw-small-txt'}>Streamline your account management with a central hub for users,
                        orders, payments and profile settings. Choose the ideal subscription plan for your business and
                        easily resolve any account-related inquiries.</p>
                    <img src={m_img_5} style={{
                        width: '100vw',
                        height: "auto",
                        margin: '0 0 0 0'
                    }}
                         className={'m-sfw-img'}/>
                </Row>
                <Row justify={"center"}>
                    <Col lg={20}>
                        <div className="image-container">
                            <img src={m_img_6} alt="Background"
                                 className="background-image"
                                 style={{
                                     width: '80vw',
                                     // height:'80vh'
                                     margin: "2rem auto 8rem auto"
                                 }}
                            />
                            <div className="m-sfw-content">
                                <p className={'m-sft-content-head'} style={{marginBottom: '6vh'}}>The Pairing App</p>
                                <p className={'m-sft-content-txt'}>
                                    An easy-to-use app has been designed specifically for connecting your Textura Pro.
                                    Pair the portal with hardware devices, rename, remove, and control them with ease.
                                </p>
                                <Row justify={"center"}
                                     style={{
                                         margin: '1vh 0 0 0',
                                         width: '60vw'
                                     }}
                                >
                                    <Col lg={10} style={{marginLeft: '-1.2vw'}}>
                                        <Button className={'content-btn pro-btn-hover-to-white'}
                                                onClick={_ => window.open('https://apps.apple.com/gb/app/vieunite-pro-connect/id6499339979')}
                                                style={{
                                                    width: '46vw',
                                                    height: '4vh',
                                                    fontSize: '4vw',
                                                    margin: '0vh 0 0 0 '
                                                }}
                                        >
                                            Download App
                                        </Button>
                                    </Col>
                                </Row>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Content>}
        </Layout>
    )
}

export default Software;
