import React from 'react';
import {Button, Col, Layout, message, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import img_canvas from "./imgs/Textura Block - Walnut Success 1.png";
import {get_api} from "../../portal/serverTemp";

const Success = (props) => {
    return (
        <Layout>
            <Content style={{background: '#E8E8E8', height: '100vh'}}>
                <Row justify={"center"} style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center"
                }}>
                    <Col className={'pro-success-col'}>
                        <p className={'pro-success-p1'}>Your payment has successfully been processed</p>
                        <p className={'pro-success-p2'}>View your Invoice using the buttons below</p>
                        <Button
                            className={'pro-success-btn'}
                            onClick={() => {
                                get_api('/shopping/cart/get/latest/invoice').then((res) => {
                                    if (res.invoice_url) {
                                        window.open(res.invoice_url, '_blank')
                                    } else {
                                        message.warn('Please wait. We are generating your invoice...')
                                    }
                                })
                            }}
                        >
                            View Invoice
                        </Button>
                        {/*<Button className={'pro-success-btn'}>View Receipt</Button>*/}
                    </Col>
                    <Col className={'pro-success-col'}>
                        <img className={'pro-success-img'} src={img_canvas} alt={'img-success'}/>
                    </Col>
                </Row>

            </Content>
        </Layout>
    )
}

export default Success;
