import React, {useEffect, useState} from 'react';
import './style.scss';
import {Card, Table, Button, Row, Col, Layout} from 'antd';
import {Content} from "antd/es/layout/layout";
import ProPortalHeader from "../../../componets/ProPortalHeader";
import ProfileRow from "../../../componets/ProfileRow";
import Navigator from "../../../componets/Navigator";
import DownloadPDF from './img/Download Icon.svg';
import {get_api} from "../../../serverTemp";
import backButton from './img/Back button.svg';
import {useNavigate} from "react-router-dom";

const MainContent = () => {
    const [order, setOrder] = useState({})
    const [data, setData] = useState([])
    useEffect(() => {
        get_api('/order/view?order_status=-1').then(res => {
            setData(res.data || []);
        })
    }, []);
    useEffect(() => {
        get_api('/order/detail?order_id=data?.id').then(res => {
            setOrder(res);
        })
    }, []);

    const statusMappings = {
        0: 'Available to Display',
        1: 'In Cart',
        2: 'Processed',
        3: 'Paid',
        4: 'Canceled',
        5: 'Prepare to Deliver',
        6: 'Delivering',
        7: 'Delivered',
        8: 'Refunded',
    };
    const firstOrderId = data.length > 0 ? data[0].id : null;
    const orderInfo = {
        orderNumber: firstOrderId,
        orderDetails: [
            {
                key: '1',
                status: order.status,
                orderTotal: `£${order.totalPrice}`,
                placedOn: order.createTime,
            }
        ],
        deliveryDetails: [
            {
                key: '1',
                deliveryAddress: order.deliveryAddress,
            }
        ],
        paymentDetails: [
            {
                key: '1',
                status: order.status,
                billingAddress: order.billingAddress,
                paymentType: order.paymentType,
                amountPaid: `£${order.amountPaid}`,
            }
        ],

        items: order.items?.map((item, index) => ({
            key: String(index),
            url: item.url, // 假设每个条目对象有一个 url 字段
            name: item.name,
            quantity:item.quantity,
            status: order.status,
            price: `£${item.unit_price}`, // 假设每个条目对象有一个 unit_price 字段
        })) || [],

        subtotal: `£${order.totalPrice}`,
        delivery: '£0.00',
        promotions: '£0.00',
        VAT: `£${order.vat}`,
        total: `£${order.totalPrice}`,
    };

    const OrderColumns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => statusMappings[status] || 'Unknown', // 使用映射对象转换状态码
        },
        {
            title: 'Order total',
            dataIndex: 'orderTotal',
            key: 'orderTotal',
        },
        {
            title: 'Placed on',
            dataIndex: 'placedOn',
            key: 'placedOn',
        },

    ];
    const DeliveryColumns = [
        {
            title: 'Delivery address',
            dataIndex: 'deliveryAddress',
            key: 'deliveryAddress',
        },
    ];

    const PaymentColumns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => statusMappings[status] || 'Unknown', // 使用映射对象转换状态码
        },
        {
            title: 'Billing address',
            dataIndex: 'billingAddress',
            key: 'billingAddress',
        },
        {
            title: 'Payment type',
            dataIndex: 'paymentType',
            key: 'paymentType',
        },
        {
            title: 'Amount paid',
            dataIndex: 'amountPaid',
            key: 'amountPaid',
        },
    ];
    const columns = [
        {
            title: 'Item',
            dataIndex: 'url',
            key: 'url',
            width: '14vw',
            render: url => url ? <img src={url} alt="item" style={{ width: '100px' }} /> : 'No image',
        },
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            width: '20vw',
            render: (name, record, index) => (
                <div>
                    {name}
                    <br />
                    {`Textura Pro Standard subscription applied`}
                    <br />
                    {`Quantity: ${record.quantity}`}
                </div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => statusMappings[status] || 'Unknown', // 使用映射对象转换状态码
        },
        {
            title: 'Price per item',
            dataIndex: 'price',
            key: 'price',
        },
    ];
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // 使用navigate函数导航回上一页
    };
    return (
        <div className='portal-Order-Details'>
            <div className='portal-Order-Details-Title-Container'
                 style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '50px', marginLeft: '-6rem'}}>
                    <img src={backButton} alt="BackButton" className='BackButtonIcon' style={{marginRight: '1.5rem',marginTop:'1rem', cursor: "pointer"}}
                         onClick={handleBackClick}/>
                    <p className='portal-Order-Details-Title' style={{margin: 0,marginTop:'1rem'}}>Order Dashboard</p>
                </div>
                <Button className='portal-Order-Details-Button'>
                    <img src={DownloadPDF} alt="Download as PDF" className='DownloadPDFIcon'/>
                    Download as PDF
                </Button>
            </div>
            <div className='portalOrderFirstRow'>
                <Row gutter={50}>
                    <Col span={12}>
                        <div className='portal-OrderDetailsTitle'>Order No: {orderInfo.orderNumber}</div>
                        <div className='portal-OrderDetailsTable'>
                            <Table dataSource={orderInfo.orderDetails} columns={OrderColumns} pagination={false}/>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='portal-DeliveryDetailsTitle'>Delivery details:</div>
                        <div className='portal-DeliveryDetailsTable'>
                            <Table dataSource={orderInfo.deliveryDetails} columns={DeliveryColumns} pagination={false}/>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='portal-PaymentDetailsTitle'>Payment details</div>
            <div className='portal-paymentDetailsTable'>
                <Table dataSource={orderInfo.paymentDetails} columns={PaymentColumns} pagination={false}/>
            </div>
            <div className='portal-itemsTable'>
                <Table dataSource={orderInfo.items} columns={columns} pagination={false}/>
            </div>
            <div className='portal-subTotal'>
                <p className='portal-subTotal-Text'>Subtotal<span
                    className="portal-subTotal-price1">{orderInfo.subtotal}</span></p>
                <p className='portal-subTotal-Text'>Delivery<span
                    className="portal-subTotal-price2">{orderInfo.delivery}</span></p>
                <p className='portal-subTotal-Text'>Promotions<span
                    className="portal-subTotal-price3">{orderInfo.promotions}</span></p>
                <p className='portal-subTotal-Text'>VAT<span
                    className="portal-subTotal-price4">{orderInfo.VAT}</span></p>
                <p className='portal-subTotal-Text'><b>TOTAL<span
                    className="portal-subTotal-price5">{orderInfo.total}</span></b></p>
            </div>
        </div>);
};
const PortalOrderDetails = () => {
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <MainContent/>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>);
}
export default PortalOrderDetails;



