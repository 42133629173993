import React, { useEffect, useState } from "react";
import Navigator from "../componets/Navigator";
import { Button, Layout } from "antd";
import ProPortalHeader from "../componets/ProPortalHeader";
import ProfileRow from "../componets/ProfileRow";
import { Content } from "antd/es/layout/layout";
import DiscoveryArtworkCardView from "./DiscoveryArtworkCardView"
import { useLocation, useNavigate } from "react-router-dom";
import { get_api } from "../serverTemp";
import backButton from '.././MyAccount/ExtraServices/UpgradeSubscription/img/Back button.svg';

var galleryCurrentPage = 0;
var totalPage = 0;
// const req = {
//     method: 'GET',
//     headers: { "X-Access-Token": "123456" },
// };

// const fetchArtworks = () => {
//     return fetch(`http://192.168.1.140:8001/artworks?page=${currentPage}`,
//         req)
//         .then(res => {
//             if (res.ok) {
//                 console.log("request successfully")
//                 return res.json()
//             }
//             throw new Error(`Error HTTP response`);
//         })
//         .then(data => {
//             if (data.code === 200) {
//                 currentPage = data.result.currentPage
//                 totalPage = data.result.totalPage
//                 // console.log("item count")
//                 // console.log(data.result.data) // 2
//                 return data.result.data
//             } else {
//                 throw new Error(`Error HTTP format`);
//             }
//         })
//         .catch(error => console.log('error', error));
// }

const DiscoveryGalleriesSeeAll = (props) => {
    const [artworks, setArtworks] = useState([]);
    const [details, setDetails] = useState([]);
    // const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();

    const onWebsite = (values) => {
        window.open(props.item.official_website, '_blank');
    }

    const onSendGalleries = () => {
        // { artwork_id: props.artwork.id, isPreview: true, from_: props.artwork.from_ }
        navigate('/proportal/dashboard', { state: { gallery_id: props.item.id } });
    }

    const onBack = () => {
        navigate('/proportal/discover-art')
    }

    useEffect(() => {
        galleryCurrentPage = 0;
        loadArtworks();
        get_api(`/gallery/detail?gallery_id=${props.item.id}`).then(res => {
            setDetails(res);
        });
    }, [])

    const loadArtworks = () => {
        get_api(`/gallery/artworks?gallery_id=${props.item.id}&page=${galleryCurrentPage}`).then(res => {
            // currentPage = res.currentPage;
            totalPage = res.totalPage;
            setArtworks(prevArtworks => [...prevArtworks, ...res.data]);
        });
    };

    const handleshowMore = () => {
        if (galleryCurrentPage < totalPage) {
            // setCurrentPage(currentPage + 1);
            galleryCurrentPage += 1;
            loadArtworks();
        }
    };

    return (
        <div style={{ minHeight: "100vh", marginTop: "-15px" }}>
            <div className="product-info" style={{ margin: "27px" }}>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', marginTop: "30px" }}>
                    <img src={backButton} alt="BackButton" className='BackButtonIcon' style={{ marginRight: '0px', cursor: "pointer" }} onClick={onBack} />
                    <p className="gallery-card-title" style={{marginLeft: "-5px"}}> {props.item.name} </p>
                </div>

                <p className="gallery-card-body"> {details.description} </p>
                <div style={{ display: "flex", justifyContent: "flex-start", marginLeft: "53px"}}>
                {localStorage.getItem('subscription') !== "Standard" && 
                    <Button className="discover-category-button" style={{ margin: "10px", background: "#0E477A", color: "#FFF" }} onClick={onSendGalleries}>Send the Collection to Textura Pro</Button>
                }
                    <Button className="discover-category-button" style={{ margin: "10px", background: "#0E477A", color: "#FFF" }} onClick={onWebsite}>Official Website</Button>
                </div>
            </div>
            <div className="collections-container" style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", alignItems: "center", padding: "0px 35px 0 88px" }}>
                {artworks.map((item, index) => (
                    <div key={index}>
                        <DiscoveryArtworkCardView item={item} style={{ marginRight: 95, marginBottom: 40 }} />
                    </div>
                ))}
            </div>

            {galleryCurrentPage < totalPage && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        style={{ borderRadius: "6px", margin: "25px" }}
                        onClick={() => handleshowMore()}
                    >
                        Show More
                    </Button>
                </div>
            )}
        </div>
    )
}

const DiscoveryGalleriesSeeAllWrapper = () => {
    const { state } = useLocation();
    const { gallery } = state
    // {
    //     "id": "1",
    //     "name": "Birmingham Art Gallery",
    //     "url": gallery1,
    // }

    return (
        <Layout>
            <Content>
                <ProPortalHeader />
                <ProfileRow />
                <Layout>
                    <Navigator />
                    <Layout>
                        <DiscoveryGalleriesSeeAll item={gallery} />
                    </Layout>
                </Layout>
            </Content>
        </Layout>
    )
}

export default DiscoveryGalleriesSeeAllWrapper;