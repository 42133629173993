import React, {useEffect, useRef, useState} from 'react';
import {InputNumber, message, Radio} from "antd";
import {Badge, Button, Checkbox, Col, Dropdown, Input, Layout, Menu, Modal, Row, Switch, TimePicker, Tree} from "antd";
import {Content} from "antd/es/layout/layout";
import {Space, Table, Tag} from 'antd';
import ProPortalHeader from "../componets/ProPortalHeader";
import './style.scss'
import {DashboardIcon, AccountIcon, ArtIcon, LibIcon} from './imgs/Icons'
import Navigator from "../componets/Navigator";
import Sider from "antd/es/layout/Sider";
import ProfileRow from "../componets/ProfileRow";
import {useLocation, useNavigate} from "react-router-dom";
import {get_api, post_api} from "../serverTemp";
// import {ReactComponent as RepeatIcon} from './components/Hardware/Repeat Once.svg'
import canvasImg from './imgs/canvas.png'
import {ShuffleIcon, RepeatIcon} from "./Icons";
import {DAYS_A_WEEK} from "../../constants/proConstants";
import swatch from "react-color/lib/components/common/Swatch";
import {useImmerReducer} from "use-immer";
import {initialState, reducer} from "./reducer";
import backButton from "../MyAccount/ExtraServices/UpgradeSubscription/img/Back button.svg";
import moment from 'moment-timezone';
import { toZonedTime, format } from 'date-fns-tz';
import {addHours} from "date-fns";

function flattenTree(tree) {
    let flatList = [];

    function processNode(node) {
        if (!node) return;

        // Add the current node to the flat list
        flatList.push({
            key: node.id,
            title: node.name,
            level: node.level
        });

        // Process children if they exist
        if (Array.isArray(node.children)) {
            node.children.forEach(child => processNode(child));
        }
    }

    // Start processing from the root nodes
    tree.forEach(node => processNode(node));

    return flatList;
}

const DashBoard = (props) => {
    const [dsState, dispatch] = useImmerReducer(reducer, initialState)
    const [data, setData] = useState([])
    const [orgData, setOrgData] = useState([])
    const navigate = useNavigate()
    const [hasDevice, setHasDevice] = useState(true)

    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
    const [isControlModalOpen, setIsControlModalOpen] = useState(false)
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false)
    const [groups, setGroups] = useState([])
    const [previewUrl, setPreviewUrl] = useState('')
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [filter, setFilter] = useState({groups: 'Group', status: 'Status'})

    const {state} = useLocation()
    const is_pls = state?.is_pls || false
    const album_id = state?.album_id || ''
    const artwork_id = state?.artwork_id || ''
    const isPreview = state?.isPreview || false
    const from_ = state?.from_ || 0
    const gallery_id = state?.gallery_id || ''
    const artist_id = state?.artist_id || ''
    // const content_id = state?.content_id || -1
    // const content_mode = state?.content_mode || -1 // -1: no content; 0: artwork; 1: album; 2:playlist 3: gallery 4: artist

    const comeFromOtherPlace = isPreview || album_id !== '' || artwork_id !== ''
    const [isRemindModalOpen, setIsRemindModalOpen] = useState(comeFromOtherPlace)

    const [searchVal, setSearchVal] = useState('')
    const [stat, setStat] = useState({})
    const [user, setUser] = useState({})

    const [openModalAfterUpdate, setOpenModalAfterUpdate] = useState(false);


    useEffect(() => {
        get_api('/textura/status/statictics').then(res => {
            setStat(res)
        })
    }, []);
    useEffect(() => {
        get_api('/user/detail').then(res => {
            setUser(res)
        })
    }, [])
    const subsMap = {
        0: "Standard",
        1: "Advanced",
        2: "Premium"
    }

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys, // Set the selected keys from the state
        onChange: onSelectChange, // Update the state when selections change
    };
    useEffect(() => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log(timezone)
        get_api('/textura/all').then(res => {
            console.log(res.data)
            if (res.data.length > 0) {
                // setData(res.data.map((d, idx) => {
                //     const localTime = moment.tz(d.last_heartbeat,timezone).format('YYYY-MM-DD HH:mm:ss');
                //     return {...d, key: idx, last_heartbeat: localTime}
                // }))
                // setOrgData(res.data.map((d, idx) => {
                //     const localTime = moment.tz(d.last_heartbeat,timezone).format('YYYY-MM-DD HH:mm:ss');
                //     return {...d, key: idx, last_heartbeat: localTime}
                // }))e
                const formattedData = res.data.map((d, idx) => {
                    let zonedDate = toZonedTime(new Date(d.last_heartbeat), timezone);
                    if (timezone === 'Europe/London') {
                        zonedDate = addHours(zonedDate, 1); // Add one hour if the timezone is Europe/London
                    }
                    const localTime = format(zonedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone: timezone });
                    return { ...d, key: idx, last_heartbeat: localTime };
                });

                setData(formattedData);
                setOrgData(formattedData);
            } else {
                setHasDevice(false)
            }
        })
    }, [])
    useEffect(() => {
        get_api('/textura/group/all').then(res => {
            let tree = res.data
            let flatList = flattenTree(tree);
            const treeData = [
                {
                    title: 'parent 1',
                    key: '0-0',
                    children: [
                        {
                            title: 'parent 1-0',
                            key: '0-0-0',
                            disabled: true,
                            children: [
                                {
                                    title: 'leaf',
                                    key: '0-0-0-0',
                                    disableCheckbox: true,
                                },
                                {
                                    title: 'leaf',
                                    key: '0-0-0-1',
                                },
                            ],
                        },
                    ],
                },
            ];
            setGroups(flatList)
        })
    }, [])

    useEffect(() => {
        console.log(dsState.metaData)
        if (openModalAfterUpdate) {
            setIsSettingsModalOpen(true);
            setOpenModalAfterUpdate(false);
        }
    }, [dsState.metaData]);
    const columns = [
        {
            title: 'Device Name',
            dataIndex: 'name',
            key: 'name',
            // sorter: (a, b) => a.name.length - b.name.length,
            render: (_, record) => (
                <span
                    className={'ds-table-col-hover'}
                    style={{
                        // textDecoration: "underline",
                        cursor: "pointer",
                        // color: "#0E487A"
                    }}
                    onClick={() => {
                        navigate('/proportal/textura',
                            {
                                state: {
                                    deviceData: record,
                                }
                            })
                    }}
                >
                    {record.name}
                </span>
            ),
        },
        {
            title: 'Serial No.',
            dataIndex: 'sn',
            key: 'sn',
            render: (text, record) => <span
                className={'ds-table-col-hover'}
                style={{
                // textDecoration: "underline",
                cursor: "pointer",
                // color: "#0E487A"
            }} onClick={(text) => {
                navigate('/proportal/textura',
                    {
                        state: {
                            deviceData: record,
                        }
                    })
            }}>{text}</span>,
        },
        {
            title: <span style={{
                display: "flex",
            }}>Status</span>,
            key: 'status',
            dataIndex: 'status',
            render: (_, {status}) => (
                status === 3 ? <span><Badge status="success"/>&nbsp;Online</span> :
                    status === 0 ?
                        <span><Badge status="error"/>&nbsp;Power Off</span> :
                        <span><Badge status="warning"/>&nbsp;Sleep</span>
            ),
        },
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
        },
        {
            title: 'Now playing',
            dataIndex: 'playlist',
            key: 'playlist',
            render: (text, record) =>
                <span
                    // className={'ds-table-col-hover'}
                    style={{
                        textDecoration: "underline",
                        cursor: "pointer"
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        navigate('/proportal/images', {
                            state: {
                                textura_ids: [record.id],
                                title: 'Current Artworks on Your Textura',
                                filter: 'current',
                                checkMode: true,
                                removeMode: true,
                            }
                        })
                    }}>
                    {text ? text : "Currently playing content"}
                </span>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (_, {t}) => (
                t === 0 ? <span>Standard</span> :
                    (t === 1 ? <span>Advanced</span> : <span>Premium</span>)
            ),
        },
        // {
        //     title: 'Operation',
        //     key: 'sn',
        //     render: (_, record) => (
        //         <Space size="middle">
        //             <a style={{
        //                 textDecoration: "underline",
        //                 color: "#0E487A",
        //             }} onClick={(text) => {
        //                 navigate('/proportal/textura', {state: {deviceData: record}})
        //             }}>
        //                 Manage
        //             </a>
        //         </Space>
        //     ),
        // },
        {
            title: <span style={{
                display: 'flex',
                // justifyContent: 'center',
            }}>
                Last Heartbeat
            </span>,
            key: 'last_heartbeat',
            dataIndex: 'last_heartbeat',
        },
        {
            title: <span style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                Display on/off
            </span>,
            key: 'sn',
            render: (_, record) => (
                <Space size="middle" style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <Switch defaultChecked={record.status === 3}
                            disabled={record.status === 0}
                            onChange={
                                (checked, e) => {
                                    console.log(record)
                                    e.stopPropagation()
                                    console.log(`switch to ${checked}`);
                                    post_api('/textura/sleep', {
                                        textura_ids: [record.id],
                                        texturaSleepStatus: checked ? 3 : 2
                                    }).then(() => {
                                        window.location.reload()
                                    })
                                        .catch(error => console.error('Error:', error));
                                }}/>
                </Space>
            ),
        },
    ];
    const statusMenu = () => (
        <div className={'ds-dropdown'}>
            <Menu onClick={(e) => {
                switch (e.key) {
                    case '1':
                        setData(orgData.filter(d => d.status === 0))
                        setFilter({groups: 'Group', status: 'Power Off'})
                        break;
                    case '2':
                        setData(orgData.filter(d => d.status === 3))
                        setFilter({groups: 'Group', status: 'Online'})
                        break;
                    case '3':
                        setData(orgData.filter(d => d.angle === 90 || d.angle === 270))
                        setFilter({groups: 'Group', status: 'Portrait'})
                        break;
                    case  '4':
                        setData(orgData.filter(d => d.angle === 0 || d.angle === 180))
                        setFilter({groups: 'Group', status: 'Landscape'})
                        break;
                    case  '5':
                        setData(orgData.filter(d => d.status === 2))
                        setFilter({groups: 'Group', status: 'Sleep'})
                        break;
                    default:
                        setFilter({groups: 'Group', status: 'Status'})
                        break;
                }
            }}>
                {/*<Menu.Item key={'1'}>Power Off</Menu.Item>*/}
                <Menu.Item key={'2'}>Online</Menu.Item>
                <Menu.Item key={'5'}>Sleep</Menu.Item>
                <Menu.Item key={'3'}>Portrait</Menu.Item>
                <Menu.Item key={'4'}>Landscape</Menu.Item>
            </Menu>
        </div>

    );
    const settingsMenu = () => (
        <div className={'ds-dropdown'}>
            <Menu onClick={(e) => {
                if (selectedRowKeys.length > 0) {
                    let curId = '1'
                    curId = selectedRowKeys.map((k) => data.filter(d => d.key === k)[0].id)
                    switch (e.key) {
                        case '1':
                            post_api('/textura/restart', {textura_ids: curId})
                                .then(res => {

                                })
                            break;
                        // case '2':
                        //     post_api('/textura/shut/down', {textura_ids: curId, texturaSleepStatus: 0})
                        //         .then(res => {
                        //
                        //         })
                        //     break;
                        case  '5':
                            if (selectedRowKeys.length === 1) {
                                get_api(`/textura/metadata?textura_id=${curId[0]}`)
                                    .then((res) => {
                                        console.log(res)
                                        dispatch({type: 'update_meta', item: {...res}})
                                    }).then(() => {
                                    console.log(dsState.metaData)
                                }).catch(error => console.log('error', error))
                            } else {
                                dispatch({
                                    type: 'update_meta', item: {
                                        angle: 0,
                                        volume: 50,
                                        brightness: 50,
                                        imageDuration: 30
                                    }
                                })
                            }
                            setOpenModalAfterUpdate(true)
                            break;
                        default:
                            break;
                    }
                } else {
                    message.info('Please select one device for remote operations!')
                }
            }}>

                {/*<Menu.Item key={'3'}>Sleep</Menu.Item>*/}
                {/*<Menu.Item key={'4'}>Shuffle Content</Menu.Item>*/}
                <Menu.Item key={'5'}>Canvas Settings</Menu.Item>
                <Menu.Item key={'1'}>Restart</Menu.Item>
                {/*<Menu.Item key={'2'}>Shut Down</Menu.Item>*/}
            </Menu>
        </div>

    );
    const groupMenu = () => (
        <div className={'ds-dropdown'}>
            <Menu onClick={(e) => {
                if (groups.length > 0) {
                    setData(orgData.filter((d) => d.group === e.key))
                    setFilter({groups: e.key, status: 'Status'})
                }
            }}>
                {groups.length > 0 ?
                    groups.map((item, idx) => <Menu.Item key={item?.title}>
                        {item?.title}
                    </Menu.Item>) :
                    <Menu.Item>
                        You don't have groups.
                    </Menu.Item>}
            </Menu>
        </div>
    );
    const controlMenu = () => (
        <div className={'ds-dropdown'}>
            <Menu onClick={(e) => {
                if (selectedRowKeys.length >= 1) {
                    const record = data.filter(d => selectedRowKeys[0] === d.key)[0]
                    switch (e.key) {
                        case '1':
                            dispatch({
                                type: 'set_play_mode',
                                payload: -1
                            })
                            setIsControlModalOpen(true)
                            break;
                        case '2':
                            navigate('/proportal/images', {
                                state: {
                                    title: 'Select artwork to the current playlist',
                                    filter: -1,
                                    textura_ids: orgData.filter(d => selectedRowKeys.includes(d.key)).map(d => d.id),
                                    checkMode: true
                                }
                            })
                            break;
                        default:
                            break;
                    }
                } else {
                    message.info('Please select at least one device!')
                }
            }}>
                <Menu.Item key={'1'}>Select play modes</Menu.Item>
                <Menu.Item key={'2'}>Add more artwork</Menu.Item>
            </Menu>
        </div>
    );

    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <Row style={{margin: '8rem 0 0 0'}}>
                    <Col lg={4} style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: 'column'
                    }}>
                        <div className={'pt-profile-div'}>
                            {user.avatar ?
                                <img className={'pt-profile-div-img'}
                                     src={user?.avatar}
                                     style={{
                                         cursor: 'pointer'
                                     }}
                                     alt={'avatar'}
                                     onClick={() => {
                                         window.location.href = '/proportal/AccountSettings'
                                     }}
                                /> :
                                <div className={'pt-profile-div-avatar-out'}>
                                    <div className={'pt-profile-div-avatar'}
                                         onClick={() => {
                                             window.location.href = '/proportal/AccountSettings'
                                         }}
                                    >
                                        {user?.nickname ? user.nickname[0].toUpperCase() : ''}
                                    </div>
                                </div>
                            }
                        </div>
                        <p className={'bp-ds-profile-txt'}
                           style={{
                               marginTop: '1rem',
                           }}>
                            {user?.nickname}
                        </p>
                        <p className={'bp-ds-profile-txt'}>
                            {user?.email}
                        </p>
                        <p className={'bp-ds-profile-txt'}
                           style={{
                               marginBottom: '1rem'
                           }}>
                            {subsMap[user?.subscription]}
                        </p>
                    </Col>
                    <Col lg={4}>
                        <div className={'bp-ds-status-div'}>
                            <div className={'bp-ds-status-1'}>
                                <div className={'bp-ds-status-sm'}
                                     style={{marginLeft: '-1.2rem'}}
                                >
                                    <p className={'bp-ds-status-title'}
                                    >
                                        Screens
                                    </p>
                                    <p className={'bp-ds-status-txt'}>
                                        Number of screens in subscription
                                    </p>
                                </div>
                                <div className={'bp-ds-status-sm'}>
                                    <p className={'bp-ds-status-title'}>
                                        Online
                                    </p>
                                    <p className={'bp-ds-status-txt'}>
                                        Number of screens currently online
                                    </p>
                                </div>
                                <div className={'bp-ds-status-sm'}>
                                    <p className={'bp-ds-status-title'}
                                       style={{margin: '4px 0 1.6rem 1rem'}}
                                    >
                                        Orientation
                                    </p>
                                    <p className={'bp-ds-status-txt'}>
                                <span style={{
                                    margin: '4rem 0 0 0.4vw'
                                }}>
                                    Portrait
                                </span>

                                        <span style={{
                                            margin: '4rem 0 0 2.9rem'
                                        }}>
                                    Landscape
                                </span>
                                    </p>
                                </div>
                            </div>
                            <div className={'bp-ds-status-2'}>
                                <div className={'nb'} style={{
                                    cursor: 'pointer'
                                }}
                                     onClick={() => {
                                         setData(orgData)
                                     }}
                                >
                                    {stat?.totalScreen}
                                </div>
                                <div className={'vertical-line'}/>
                                <div className={'nb'} style={{
                                    cursor: 'pointer'
                                }}
                                     onClick={() => {
                                         setData(orgData.filter(d => d.status === 3))
                                     }}
                                >
                                    {stat?.onlineScreen}
                                </div>
                                <div className={'vertical-line'}/>
                                <div className={'nb'} style={{
                                    width: '69px', cursor: 'pointer'
                                }}
                                     onClick={() => {
                                         setData(orgData.filter(d => d.angle === 90 || d.angle === 270))
                                     }}
                                >
                                    {stat?.portraitScreen}
                                </div>
                                <div className={'vertical-line'}/>
                                <div className={'nb'} style={{
                                    width: '69px',
                                    cursor: 'pointer'
                                }}
                                     onClick={() => {
                                         setData(orgData.filter(d => d.angle === 0 || d.angle === 180))
                                     }}
                                >
                                    {stat?.landscapeScreen}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout style={{marginBottom: '10rem'}}>
                            <Col lg={24} style={{
                                background: '#F4F4F4',
                            }}>
                                {comeFromOtherPlace &&
                                    <Row style={{display: "flex", alignItems: "center", margin: "1rem 0"}}>
                                        <img src={backButton}
                                             alt="BackButton"
                                             style={{margin: '0 0 -8vh 4vw', cursor: "pointer"}}
                                             onClick={_ => window.history.back()}
                                        />
                                    </Row>}
                                {hasDevice && <div className={'bp-ds-ctrl-div'}
                                                   style={{
                                                       width: '40%',
                                                       margin: '4rem 0 0 4vw'
                                                   }}>

                                    <Input className={'bp-ds-input'}
                                           placeholder={'Search by name or serial number'}
                                           onChange={(e) => {
                                               setFilter({groups: 'Group', status: 'Status'})
                                               console.log(e.target.value)
                                               setSearchVal(e.target.value)
                                               if (e.target.value === '' || !e.target.value) {
                                                   setData(orgData)
                                               } else {
                                                   setData(orgData.filter((d) =>
                                                       d.name.toUpperCase().includes(e.target.value.toUpperCase()) ||
                                                       d.sn.toUpperCase().includes(e.target.value.toUpperCase())))
                                               }
                                           }}
                                           onKeyDown={() => {
                                               setFilter({groups: 'Group', status: 'Status'})
                                               if (searchVal === '' || !searchVal) {
                                                   setData(orgData)
                                               } else {
                                                   setData(orgData.filter((d) =>
                                                       d.name.toUpperCase().includes(searchVal.toUpperCase()) ||
                                                       d.sn.toUpperCase().includes(searchVal.toUpperCase())))
                                               }
                                           }}
                                    />
                                    <Button className={'bp-search-btn'}
                                            onClick={() => {
                                                setFilter({groups: 'Group', status: 'Status'})
                                                if (searchVal === '' || !searchVal) {
                                                    setData(orgData)
                                                } else {
                                                    setData(orgData.filter((d) => d.name.toUpperCase().includes(searchVal) || d.sn.toUpperCase().includes(searchVal)))
                                                }
                                            }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"
                                             viewBox="0 0 28 28"
                                             fill="none">
                                            <path
                                                d="M12.9531 24.125C18.8557 24.125 23.6406 19.34 23.6406 13.4375C23.6406 7.53496 18.8557 2.75 12.9531 2.75C7.05058 2.75 2.26562 7.53496 2.26562 13.4375C2.26562 19.34 7.05058 24.125 12.9531 24.125Z"
                                                stroke="white" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                            <path d="M24.7656 25.25L22.5156 23" stroke="white" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </Button>

                                    <Dropdown className={'ds-dropdown'} overlay={groupMenu} placement={"bottom"}>
                                        <Button className={'bp-ds-ctrl-btn'} style={{
                                            width: 'auto',
                                            background: filter.groups === 'Group' ? '#0E487A' : '#A06174',
                                            color: filter.groups === 'Group' ? '#fff' : '#fff',
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around"
                                        }}>
                                            {/*Group*/}
                                            {filter.groups}
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="13" height="8"
                                                 style={{marginLeft: '0.5vw'}}
                                                 viewBox="0 0 13 8" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M11.2948 0.333336C11.6854 -0.0571889 12.3185 -0.057189 12.7091 0.333336C13.0996 0.72386 13.0996 1.35703 12.7091 1.74755L9.59816 4.85845L7.89959 6.55702L7.92836 6.58579L6.51415 8L0.292347 1.7782C-0.0981782 1.38768 -0.0981782 0.754511 0.292347 0.363987C0.682871 -0.0265374 1.31604 -0.0265374 1.70656 0.363987L6.48538 5.1428L8.18395 3.44424L11.2948 0.333336Z"
                                                      fill={filter.groups === 'Group' ? '#fff' : '#fff'}
                                                />
                                            </svg>
                                        </Button>
                                    </Dropdown>
                                    <Dropdown className={'ds-dropdown'} overlay={statusMenu} placement={"bottom"}>
                                        <Button className={'bp-ds-ctrl-btn'} style={{
                                            width: 'auto',
                                            background: filter.status === 'Status' ? '#0E487A' : '#A06174',
                                            color: filter.status === 'Status' ? '#fff' : '#fff',
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around"
                                        }}>
                                            {/*Status*/}
                                            {filter.status}
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="13" height="8"
                                                 style={{marginLeft: '0.5vw'}}
                                                 viewBox="0 0 13 8" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M11.2948 0.333336C11.6854 -0.0571889 12.3185 -0.057189 12.7091 0.333336C13.0996 0.72386 13.0996 1.35703 12.7091 1.74755L9.59816 4.85845L7.89959 6.55702L7.92836 6.58579L6.51415 8L0.292347 1.7782C-0.0981782 1.38768 -0.0981782 0.754511 0.292347 0.363987C0.682871 -0.0265374 1.31604 -0.0265374 1.70656 0.363987L6.48538 5.1428L8.18395 3.44424L11.2948 0.333336Z"
                                                      fill={filter.status === 'Status' ? '#fff' : '#fff'}
                                                />
                                            </svg>
                                        </Button>
                                    </Dropdown>
                                </div>}

                                {hasDevice && <div className={'bp-ds-ctrl-div'} style={{
                                    marginLeft: comeFromOtherPlace ? '-8vw' : '3.2vw'
                                }}>
                                    <Button
                                        className={'bp-ds-ctrl-btn'}
                                        onClick={() => {
                                            if (selectedRowKeys.length > 0) {
                                                const textura_ids = data.filter(d => selectedRowKeys.includes(d.key)).map(d => d.id)
                                                if (gallery_id !== '') {
                                                    post_api('/textura/publish/gallery', {
                                                        textura_ids: textura_ids,
                                                        gallery_id: gallery_id
                                                    })
                                                        .then(res => {
                                                            setSelectedRowKeys([])
                                                        })
                                                        .catch(error => console.error('Error:', error));
                                                }
                                                if (artist_id !== '') {
                                                    post_api('/textura/publish/artist', {
                                                        textura_ids: textura_ids,
                                                        artist_id: artist_id
                                                    })
                                                        .then(res => {
                                                            setSelectedRowKeys([])
                                                        })
                                                        .catch(error => console.error('Error:', error));
                                                }

                                                if (album_id !== '') {
                                                    //publish an album or pls
                                                    if (is_pls) {
                                                        post_api('/textura/publish/playlist', {
                                                            textura_ids: textura_ids,
                                                            playlist_id: album_id
                                                        })
                                                            .then(res => {
                                                                setSelectedRowKeys([])
                                                            })
                                                    } else {
                                                        post_api('/textura/publish/album', {
                                                            textura_ids: textura_ids,
                                                            album_id: album_id
                                                        })
                                                            .then(res => {
                                                                setSelectedRowKeys([])
                                                            })
                                                    }
                                                }

                                                if (artwork_id !== '') {
                                                    isPreview ? (
                                                        get_api(`/artwork/detail?artwork_id=${artwork_id}&from_=${from_}`)
                                                            .then((res) => {
                                                                console.log(res.url)
                                                                setPreviewUrl(res.url)
                                                                setIsPreviewModalOpen(true)
                                                            }).then(() => {
                                                            post_api('/textura/publish/preview/artwork', {
                                                                artwork_id: artwork_id,
                                                                from_: from_,
                                                                textura_ids: textura_ids
                                                            })
                                                        })
                                                    ) : (
                                                        post_api('/textura/publish/artworks', {
                                                            textura_ids: textura_ids,
                                                            artwork_ids: [artwork_id],
                                                            froms_: [from_]
                                                        })
                                                            .then(res => {
                                                                setSelectedRowKeys([])
                                                            })
                                                    )
                                                }
                                                if (gallery_id === '' && artist_id === '' && album_id === '' && artwork_id === '') {
                                                    navigate('/proportal/images', {
                                                        state: {
                                                            textura_ids: textura_ids,
                                                            title: "Choose the artwork to add to the canvas's playlist",
                                                            filter: -1,
                                                            checkMode: true
                                                        }
                                                    })
                                                }
                                            } else {
                                                message.info('Please select at least one device!')
                                            }
                                        }}
                                        style={comeFromOtherPlace && selectedRowKeys.length > 0 ?
                                            {backgroundColor: '#A06174'} :
                                            {}
                                        }
                                    >
                                        {isPreview ? 'Preview' : 'Publish'}
                                    </Button>
                                    {!comeFromOtherPlace && <Button
                                        className={'bp-ds-ctrl-btn'}
                                        onClick={() => {
                                            if (selectedRowKeys.length >= 1) {
                                                const record = data.filter(d => selectedRowKeys[0] === d.key)[0]
                                                // navigate('/proportal/textura', {state: {deviceData: record}})
                                                setIsScheduleModalOpen(true)
                                            } else {
                                                message.info('Please select at least one device for scheduling!')
                                            }
                                        }}
                                        disabled={comeFromOtherPlace}
                                    >
                                        Schedule
                                    </Button>}
                                    {!comeFromOtherPlace && <Dropdown className={'ds-dropdown'}
                                                                      overlay={settingsMenu}
                                                                      placement={"bottom"}
                                                                      disabled={comeFromOtherPlace}
                                    >
                                        <Button
                                            className={'bp-ds-ctrl-btn'}
                                            onClick={() => {
                                                if (selectedRowKeys.length > 0) {
                                                    // const record = data.filter(d => selectedRowKeys[0] === d.key)[0]
                                                    // navigate('/proportal/textura', {state: {deviceData: record}})
                                                } else {
                                                    message.info('Please select at least one device for remote operations!')
                                                }
                                            }}
                                        >
                                            Settings
                                        </Button>
                                    </Dropdown>}

                                    {!comeFromOtherPlace && <Dropdown className={'ds-dropdown'}
                                                                      overlay={controlMenu}
                                                                      placement={"bottom"}
                                                                      disabled={comeFromOtherPlace}
                                    >
                                        <Button
                                            className={'bp-ds-ctrl-btn'}
                                            onClick={() => {

                                            }}
                                        >
                                            Controls
                                        </Button>
                                    </Dropdown>}
                                </div>}
                                {hasDevice ?
                                    <Table style={{
                                        margin: '8rem 4vw 10rem 4vw',
                                        borderRadius: '8px',
                                        border: '2px solid #FFF',
                                    }}
                                           className={'bp-ds-table'}
                                           columns={columns}
                                           dataSource={data}
                                           rowSelection={{...rowSelection}}
                                           locale={{emptyText: 'No matching Textura Pro canvas has been found'}}
                                        // onRow={(record, rowIndex) => {
                                        //     return {
                                        //         onClick: event => {
                                        //             navigate('/proportal/textura',
                                        //                 {
                                        //                     state: {
                                        //                         deviceData: record,
                                        //                     }
                                        //                 })
                                        //             // setSelectedRowKeys([...selectedRowKeys, record.key])
                                        //         },
                                        //     }
                                        // }}
                                    /> : <div className={'ds-empty-device-div'}>
                                        <p className={'ds-empty-device-p'} style={{margin: '40px 0 -20px 0'}}>
                                            To add a Textura digital canvas please follow the link below to download the
                                            pairing app
                                        </p>
                                        <p className={'ds-empty-device-p'}
                                           style={{cursor: 'pointer', textDecoration: 'underline'}}
                                           onClick={() => {
                                               window.open('https://apps.apple.com/gb/app/vieunite-pro-connect/id6499339979')
                                           }}
                                        >
                                            https://vieunite.com/app.html
                                        </p>
                                    </div>}
                            </Col>
                            <Modal
                                className={'ds-ml-new-album-modal'}
                                visible={isPreviewModalOpen}
                                onOk={_ => setIsPreviewModalOpen(false)}
                                onCancel={_ => {
                                    setIsPreviewModalOpen(false)
                                    setSelectedRowKeys([])
                                    window.history.back()
                                }}
                            >
                                <p className={'ds-preview-h1'}>Image Preview</p>
                                <p className={'ds-preview-h2'}>The artwork will appear on the selected Textura Pro for
                                    25 seconds </p>
                                <img className={'ds-preview-img'} src={previewUrl} alt="previewImage"/>
                            </Modal>
                            <Modal
                                className={'ds-ml-schedule-modal'}
                                visible={isScheduleModalOpen}
                                onOk={_ => setIsScheduleModalOpen(false)}
                                onCancel={_ => setIsScheduleModalOpen(false)}
                            >
                                <Row justify={'center'}>
                                    <p className={'ds-ml-schedule-modal-h'}>Add New Schedule</p>
                                </Row>
                                <p className={'ds-ml-new-schedule-txt'}>
                                    Your Textura digital canvas will automatically default as <span style={{
                                    fontWeight: '600',
                                    textDecoration: 'underline'
                                }}>always on</span> until you have
                                    created
                                    a schedule. Click ‘SAVE’ to confirm, or create your schedule using the options
                                    below.
                                </p>
                                <Row className={'hard-week-above-row'} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-evenly',
                                    margin: '0 0 0 0'
                                }}>
                                    <span className={'hard-st-time-txt'}
                                          style={{
                                              margin: '0 0 0 -4rem'
                                          }}>Start time:</span>
                                    <span className={'hard-end-time-txt'} style={{
                                        margin: '0 0 0 0rem'
                                    }}>End time:</span>
                                </Row>
                                <Row className={'hard-week-row-mul'} style={{}}>
                                    <TimePicker className={'hard-input-week'}
                                                onChange={(time, timeString) =>
                                                    dispatch({type: 'add_schedule', item: {start_time: timeString}})
                                                }
                                                format="HH:mm"
                                    />
                                    <TimePicker className={'hard-input-week'}
                                                onChange={(time, timeString) =>
                                                    dispatch({type: 'add_schedule', item: {end_time: timeString}})
                                                }
                                                format="HH:mm"
                                    />
                                    {DAYS_A_WEEK.map((day, index) => (
                                        <div
                                            key={index}
                                            className={'hard-week-btn'}
                                            style={{
                                                backgroundColor: dsState.schedule.days.includes(index) ? '#0E487A' : '#D9D9D9',
                                                width: '26rem',
                                                margin: '1rem 0 0 0',
                                                height: '2.1rem',
                                                borderRadius: '11px',
                                            }}
                                            onClick={() => {
                                                const newDays = dsState.schedule.days.includes(index)
                                                    ? dsState.schedule.days.filter(d => d !== index)
                                                    : [...dsState.schedule.days, index];
                                                dispatch({type: 'add_schedule', item: {days: newDays}})
                                            }}
                                        >
                                            {day}
                                        </div>
                                    ))}
                                </Row>
                                <Row justify={"center"} style={{margin:'-2.8rem 0 1rem 0'}}>
                                    <p style={{fontWeight:"bold",width:'100vw'}}>
                                        Your canvas will restart immediately once new schedule has been saved
                                    </p>
                                </Row>
                                <Row className={'hard-final-row'}>
                                    <Button className={'hard-week-final-btn-mul'}
                                            onClick={() => {
                                                const curId = selectedRowKeys.map((k) => data.filter(d => d.key === k)[0].id)
                                                post_api('/textura/create/schedule', {
                                                    "textura_ids": curId,
                                                    ...dsState.schedule
                                                }).then(
                                                    _ => setIsScheduleModalOpen(false)
                                                ).catch(error => console.error('Error:', error));
                                            }}
                                    >
                                        Save
                                    </Button>
                                    <Button className={'hard-week-final-btn-mul'}
                                            onClick={() => {
                                                window.location.href = '/proportal/dashboard'
                                            }}
                                    >
                                        Close
                                    </Button>
                                </Row>
                            </Modal>
                            <Modal
                                className={'ds-ml-settings-modal'}
                                visible={isSettingsModalOpen}
                                onOk={_ => setIsSettingsModalOpen(false)}
                                onCancel={_ => setIsSettingsModalOpen(false)}
                            >
                                <Row justify={'center'}>
                                    <p className={'ds-ml-schedule-modal-h'}>Canvas Settings</p>
                                </Row>
                                <Row justify={'center'} className={'hd-settings-txt'}
                                     style={{margin: '2rem 0 1.6rem 3.5rem'}}
                                >
                                    <p className={'jsb-txt'}>Orientation</p>
                                </Row>
                                <Row justify={'center'} style={{margin: '0 0 1.2rem -15px'}}>
                                    <Col span={4} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        margin: '-4vh -3rem 0 3.1rem'
                                    }}>
                                        <span className={'hd-settings-txt jsb-txt'}>
                                            Angle</span>
                                    </Col>
                                    <Radio.Group
                                        onChange={(e) => {
                                            dispatch({type: 'update_meta', item: {angle: e.target.value}})
                                        }}
                                        value={dsState.metaData.angle}>
                                        <Radio value={0} className={'jsb-txt'}>
                                            <p className={'jsb-angle'}><span>0°</span> <span
                                                className={'jsb-angle-span'}>Landscape</span></p>
                                        </Radio>
                                        <Radio value={90} className={'jsb-txt'}>
                                            <p className={'jsb-angle'}><span>90°</span> <span
                                                className={'jsb-angle-span'}>Portrait</span></p>
                                        </Radio>
                                        <Radio value={180} className={'jsb-txt'}>
                                            <p className={'jsb-angle'}><span>180°</span> <span
                                                className={'jsb-angle-span'}>Landscape</span><span
                                                className={'jsb-angle-span'}>Flipped</span></p>
                                        </Radio>
                                        <Radio value={270} className={'jsb-txt'}>
                                            <p className={'jsb-angle'}><span>270°</span> <span
                                                className={'jsb-angle-span'}>Portrait</span><span
                                                className={'jsb-angle-span'}>Flipped</span></p>
                                        </Radio>
                                    </Radio.Group>
                                </Row>
                                <Row justify={'center'} className={'hd-settings-txt-2'}
                                     style={{margin: '0rem 0 0 4rem'}}
                                >
                                    <Col span={12}><p className={'jsb-txt'}>Volume</p></Col>
                                    <Col span={8} className={'hd-settings-ip-2'}>
                                        <InputNumber min={0} max={100}
                                                     value={dsState.metaData.volume}
                                                     onChange={val =>
                                                         dispatch({type: 'update_meta', item: {volume: val}})
                                                     }/>
                                    </Col>
                                </Row>
                                <Row justify={'center'} className={'hd-settings-txt-2'}
                                     style={{margin: '1rem 0 0 4rem'}}
                                >
                                    <Col span={12}><p className={'jsb-txt'}>Brightness</p></Col>
                                    <Col span={8} className={'hd-settings-ip-2'}>
                                        <InputNumber min={0} max={100}
                                                     value={dsState.metaData.brightness}
                                                     onChange={val =>
                                                         dispatch({type: 'update_meta', item: {brightness: val}})
                                                     }/>
                                    </Col>
                                </Row>
                                <Row justify={'center'} className={'hd-settings-txt-2'}
                                     style={{margin: '1rem 0 0 4rem'}}
                                >
                                    <Col span={12}>
                                        <p className={'jsb-txt'}>Image duration</p>
                                        <p className={'jsb-txt-2'} style={{
                                            margin: '-12px 0 0 0'
                                        }}>(in seconds)</p>
                                    </Col>
                                    <Col span={8} className={'hd-settings-ip-2'}>
                                        <InputNumber min={0} max={86400}
                                                     value={dsState.metaData.imageDuration}
                                                     onChange={val =>
                                                         dispatch({type: 'update_meta', item: {imageDuration: val}})
                                                     }/>
                                    </Col>
                                </Row>

                                <Row className={'hard-final-row'} style={{margin: '2rem 0 0 0'}}>
                                    <Button className={'hard-week-final-btn-mul'}
                                            onClick={() => {
                                                const curId = selectedRowKeys.map((k) => data.filter(d => d.key === k)[0].id)
                                                post_api('/textura/update/metadata', {
                                                    textura_ids: curId,
                                                    ...dsState.metaData
                                                })
                                            }}
                                    >
                                        Save
                                    </Button>
                                    <Button className={'hard-week-final-btn-mul'}
                                            onClick={() => {
                                                window.location.href = '/proportal/dashboard'
                                            }}
                                    >
                                        Done
                                    </Button>
                                </Row>
                            </Modal>
                            <Modal
                                className={'ds-ml-control-modal'}
                                visible={isControlModalOpen}
                                onOk={_ => setIsControlModalOpen(false)}
                                onCancel={_ => setIsControlModalOpen(false)}
                            >
                                <Row justify={'center'}>
                                    <p className={'ds-ml-schedule-modal-h'}>Playback Options</p>
                                </Row>
                                <Row justify={"center"}>
                                    <p className={'ds-ml-schedule-modal-p'}>
                                        When you use this particular function you
                                        will need to assess your hardware
                                    </p>
                                </Row>
                                <Row justify={'start'} style={{margin: '4rem 0 2rem 0'}}>
                                    <Col span={12}>
                                        <Row justify={'center'}>
                                            <div className={'hd-control-div'}>
                                                <button className={'hd-control-btn'}
                                                        style={{
                                                            margin: '0 0 0 0.8rem',
                                                            backgroundColor: dsState.texturaPlayModeStatus === 2 ? '#04C300' : '#0E487A',
                                                            // backgroundColor: '#0E487A',
                                                        }}
                                                        onClick={_ => {
                                                            const curId = selectedRowKeys.map((k) => data.filter(d => d.key === k)[0].id)
                                                            post_api('/textura/shuffle', {
                                                                textura_ids: curId,
                                                                texturaPlayModeStatusV2: 0,
                                                                version:2
                                                            })
                                                                .then(() => dispatch({
                                                                    type: 'set_play_mode',
                                                                    payload: 2
                                                                })).catch(error => console.log('error', error))
                                                        }}
                                                >
                                                    <RepeatIcon/>
                                                </button>
                                                <p className={'hd-control-p'} style={{
                                                    color: dsState.texturaPlayModeStatus === 2 ? '#000' : '#D9D9D9',
                                                }}>
                                                    Loop mode is on
                                                </p>
                                            </div>

                                        </Row>
                                        <Row justify={'center'} style={{marginTop: '1vh'}}>
                                            <p>Loop</p>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row justify={'center'}>
                                            <div className={'hd-control-div'}>
                                                <button className={'hd-control-btn'}
                                                        style={{
                                                            margin: '0 0 0 0.8rem',
                                                            backgroundColor: dsState.texturaPlayModeStatus === 0 ? '#A06174' : '#0E487A',
                                                        }}
                                                        onClick={_ => {
                                                            const curId = selectedRowKeys.map((k) => data.filter(d => d.key === k)[0].id)
                                                            post_api('/textura/shuffle', {
                                                                textura_ids: curId,
                                                                texturaPlayModeStatusV2: 2,
                                                                version: 2,
                                                            }).then(() => {
                                                                dispatch({type: 'set_play_mode', payload: 0})
                                                            }).catch(error => console.log('error', error))
                                                        }}>
                                                    <ShuffleIcon/>
                                                </button>
                                                <p className={'hd-control-p'} style={{
                                                    color: dsState.texturaPlayModeStatus === 0 ? '#000' : '#D9D9D9',
                                                }}>
                                                    Shuffle mode is on
                                                </p>
                                            </div>
                                        </Row>
                                        <Row justify={'center'} style={{marginTop: '1vh'}}>
                                            <p>Shuffle</p>
                                        </Row>
                                    </Col>
                                </Row>
                            </Modal>
                            <Modal
                                className={'ds-ml-remind-modal'}
                                visible={isRemindModalOpen}
                                onOk={_ => setIsRemindModalOpen(false)}
                                onCancel={_ => setIsRemindModalOpen(false)}
                            >
                                <Row justify={'center'}>
                                    <Col span={20} className={'ds-ml-remind-modal-col'}>
                                        <p className={'ds-ml-remind-modal-h'}>
                                            To proceed you will need to choose which Textura digital canvas will display
                                            your selected artwork
                                        </p>
                                    </Col>
                                </Row>
                                <Button className={'ds-ml-remind-modal-final-ok'}
                                        onClick={() => {
                                            setIsRemindModalOpen(false)
                                        }}
                                >
                                    OK
                                </Button>
                            </Modal>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    )
}

export default DashBoard;
