import React, {useState} from 'react';
import {Form, Input, Button, Col, Row} from 'antd';
import './style.scss'
import {Link, useLocation} from "react-router-dom";
import {post_api} from "../../portal/serverTemp";
import leftImg from "../../img/LoginSet/Photograph by Linwang Wang.webp";
import leftImg2 from "../../img/LoginSet/Artwork by Jo Vincent.webp";
import leftImg3 from "../../img/LoginSet/The Ivory Tower - Vantropolis.jpg";
import {ReactComponent as Logo} from "../../img/LoginSet/Vieunite.svg";
import {ReactComponent as PortalLoginLogo} from "./Group 1939.svg";
import {Content} from "antd/es/layout/layout";
import useWindowResize from "../../apps/Hooks/useWindowResize";
import {NewHeader} from "../../components/NewHeader";

const subMap = {
    0: 'Standard',
    1: 'Advanced',
    2: 'Premium',
}
const ProSignIn = () => {
    const {state} = useLocation();
    const nextPage = state?.nextPage || ''
    console.log(nextPage)
    const {windowWidth} = useWindowResize();
    const bgArts = [{
        art: leftImg,
        name: 'Linwang Wang'
    }, {
        art: leftImg2,
        name: 'Jo Vincent'
    }, {
        art: leftImg3,
        name: 'Christian Redermayer'
    }]
    const get_random = (list) => {
        return list[Math.floor((Math.random() * list.length))];
    }
    const [bgArt, setBgArt] = useState(get_random(bgArts))
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        const data = {
            "account": values.email,
            "password": values.Password,
        }
        post_api('/user/login', data)
            .then(res => {
                console.log(res);
                localStorage.setItem("token", res.token);
                localStorage.setItem("subscription", subMap[res.subscription])
                localStorage.setItem("user_id", res.id)
                localStorage.setItem("tried", res.tried)
            }).then(() => {
            if (nextPage) {
                window.location.href = nextPage;
            } else {
                window.location.href = '/proportal/';
            }
        });
    };

    return (
        <Content style={{overflow: windowWidth > 600 ? 'hidden' : "inherit", maxHeight: '100vh'}}>
            <NewHeader/>
            <Row>
                <Col lg={12} xs={0}>
                    <div className={'img-container'} style={{position: "relative"}}>
                        <img className={'img-left protected-img'}
                             src={bgArt.art}
                             alt={'background'}/>
                        <div className={'img-left-overlay'} style={{
                            background: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            padding: "0 1rem",
                            position: "absolute",
                            zIndex: '20',
                            bottom: '2rem',
                            left: '0',
                        }}>

                            <p className={'author-name'} style={{
                                background: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                padding: "0 2rem",
                                position: "absolute",
                                zIndex: '20',
                                bottom: '2rem',
                                left: '2rem',
                                width: '14rem',
                                height: '8vh',
                                columnGap: '1rem',
                                borderRadius: '8px'
                            }}>
                                <Logo className={'logo-area'}
                                      style={{width: "50px", height: "95px"}}
                                />
                                <div>
                                    <span style={{fontWeight: 400}}>Artwork by</span><br/><span
                                    style={{fontWeight: '700'}}>{bgArt.name}</span>
                                </div>

                            </p>
                        </div>
                    </div>

                </Col>
                <Col lg={10} xs={22} className={'ProSignIn-right'}
                     style={{
                         display: "flex",
                         flexDirection: "column",
                         justifyContent: "center",
                         alignItems: "center",
                         width: "100%",
                         margin: windowWidth > 600 ? '15vh 0 0 0' : '15rem 0 0 5%'
                     }}>
                    <PortalLoginLogo/>
                    <Form
                        initialValues={{remember: true}}
                        onFinish={onFinish}
                        colon={false}
                        // style={{
                        //     margin: "4rem auto 0 auto"
                        // }}
                        style={windowWidth >= 600 ? {width: '50%'} : {width: '90%'}}
                    >
                        <Form.Item
                            labelCol={{span: 24, style: {fontWeight: '600'}}}
                            label={'Email'}
                            name="email"
                            rules={[
                                {required: true, message: 'Please input your email!'},
                                {type: 'email', message: 'Please enter a valid email!'}
                            ]}
                        >
                            <Input className={'login-input-form'}/>
                        </Form.Item>
                        <Form.Item
                            label={'Password'}
                            name="Password"
                            rules={[
                                {required: true, message: 'Please input your password!'},
                                {
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
                                    // message: 'Password must be at least 10 characters and include @$!%*?&, lowercase, uppercase, and numbers.'
                                    message: ''
                                }
                            ]}
                            labelCol={{span: 24, style: {fontWeight: '600'}}}
                        >
                            <Input.Password className={'login-input-form'}/>
                        </Form.Item>
                        <div className="forgot-password-link" style={{
                            color: "#0E477A",
                            cursor: "pointer",
                            width: "100%",
                            textAlign: "left",
                            marginTop: "6rem",
                        }}>
                            <p onClick={_ => window.location.href = "/business/ProForgotPassword"}>Forgot
                                password?</p>
                        </div>
                        <div className="ProSignIn-center-button">

                            <button
                                htmlType="submit"
                                className="pay-button"
                            >
                                Login
                            </button>
                        </div>
                        <div className="ProSignIn-center-button">
                            <button
                                className="pay-button"
                                onClick={_ => window.location.href = '/business/prosignup'}
                            >
                                Create an account
                            </button>
                        </div>
                    </Form>
                    <Row className={'primary-font-footer'} style={{
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '14vh',
                        fontSize: '1rem',
                        fontWeight: '600',
                        width: '100%',
                        marginBottom: '0'
                    }}>
                        <a href="/terms-and-conditions"
                           style={{color: '#A9A9A9', fontWeight: '600', fontSize: '1rem'}}
                           className="footer-text">
                            Terms & Conditions
                        </a>
                        <a href="/end-user-agreement"
                           style={{color: '#A9A9A9', fontWeight: '600', fontSize: '1rem'}}
                           className="footer-text">
                                <span style={{
                                    marginLeft: '2vw',
                                }}>
                                End User Agreement
                                </span>
                        </a>
                        <a href="/ArtistsTermsAndConditions"
                           style={{color: '#A9A9A9', fontWeight: '600', fontSize: '1rem'}}
                           className="footer-text">
                                <span style={{
                                    marginLeft: '2vw',
                                }}>
                                Artist's Terms & Conditions
                                </span>
                        </a>
                    </Row>

                </Col>
            </Row>
        </Content>
    );
};

export default ProSignIn;
