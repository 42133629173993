import React, {useEffect, useRef, useState} from 'react';
import {useStripe, useElements, PaymentElement, AddressElement} from '@stripe/react-stripe-js';
import './style.scss'
import {Button} from "antd";
import {message} from 'antd';

const CheckoutForm = (props) => {
    const {dispatch} = props
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const {error, paymentIntent} = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: window.location.origin,
            },
            redirect: 'if_required'
        });
        if (error) {
            console.log("Error:", error);
            setErrorMessage(error.message);
            message.error(`Payment failed: ${error.message}`);
        } else if (paymentIntent && paymentIntent.status === 'succeeded') {
            message.success("Paid successfully!", 3, () => {
                dispatch({type: 'set_pay_modal', isPayModalOpen: false})
                window.location.reload()
            })
        } else {
            message.info("Payment processing. Please wait.");
        }
    };
    const fetchReceiptData = async (paymentIntentId) => {
        // Replace with your own endpoint to fetch receipt data
        const response = await fetch(`/api/stripe/receipt/${paymentIntentId}`);
        const data = await response.json();
        return data;
    };
    return (
        <form onSubmit={handleSubmit} style={{
            display: "flex",
            flexDirection: 'column',
        }}>
            <PaymentElement/>
            <p style={{fontWeight: '700', fontSize: '1vw', margin: '1vh 0'}}>
                Shipping Information
            </p>
            <AddressElement options={{mode: 'shipping'}}/>
            <Button className={'stripe-pay-btn'} htmlType="submit" disabled={!stripe}>Pay</Button>
        </form>
    )
};

export default CheckoutForm;