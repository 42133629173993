import React from 'react';
import {Button, Input, Layout, Modal} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'

const ImgDisplayModal = (props) => {
    const {
        isModalOpen,
        setIsModalOpen,
        artworkName,
        handleInputChange,
        handleConfirmClick,
    } = props
    return (
        <Modal
            className={'im-ml-new-album-modal'}
            visible={isModalOpen}
            onOk={_ => setIsModalOpen(false)}
            onCancel={_ => setIsModalOpen(false)}
        >
            <p className={'ml-new-album-modal-sub-title'}>Rename Your Artwork</p>
            <Input className={'ml-new-album-modal-input'}
                   placeholder="Artwork Name"
                   value={artworkName} // Bind input value to state
                   onChange={handleInputChange} // Set up onChange handler
            />
            <Button className={'ml-new-album-modal-btn'}
                    onClick={handleConfirmClick}>
                Confirm
            </Button>
        </Modal>
    )
}

export default ImgDisplayModal;
