import React, {useState, useEffect} from 'react';
import {Card, Button} from 'antd';
import './style.scss';
import {useNavigate} from "react-router-dom";
import AlbumCoverLayout from "../../ProDiscovery/AlbumCoverLayout";
import {post_api} from "../../serverTemp";
import {TruncateText} from "../ImgDisplay";

const {Meta} = Card;
const subMap = {
    'Standard': 0,
    'Advanced': 1,
    'Premium': 2,
}

const AlbumCardSingle = (props) => {
    const {album, textura_ids, is_pls} = props

    const navigate = useNavigate();
    const onClick = () => {
        if (textura_ids.length === 0) {
            navigate('/proportal/dashboard', {state: {album_id: album.id, is_pls: is_pls ? is_pls : true}})
        } else {
            if (is_pls) {
                post_api('/textura/publish/playlist', {textura_ids: textura_ids, playlist_id: album.id})
                    .then(res => {

                    })
            } else {
                post_api('/textura/publish/album', {textura_ids: textura_ids, album_id: album.id})
                    .then(res => {

                    })
            }
        }
    }
    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center",margin:'auto', width:'auto'}}>
            <Card
                hoverable
                className='al-card-main'
                cover={
                    <div style={{
                        width: 200,
                        height: 190,
                        marginLeft: 20,
                        marginTop: 20,
                        marginRight: 10,
                        marginBottom: 0,
                        overflow: "hidden"
                    }}
                         onClick={() => {
                             const userLevel = subMap[localStorage.getItem('subscription')]
                             if (userLevel < album.level) {
                                 console.log(userLevel,album.level)
                                 return
                             }
                             textura_ids?.length === 0 &&
                             navigate('/proportal/images', {
                                 state: {
                                     albumID: album?.id,
                                     title: album?.name,
                                     filter: 'pls'
                                 }
                             })
                         }}
                    >
                        {album?.urls?.length > 2 && (
                            <AlbumCoverLayout item={album?.item}/>
                        )}
                        {album.urls.length === 1 || album?.urls.length === 2 ? (
                            <img src={album?.urls[0]} alt="Album cover"
                                 style={{width: "100%", height: "100%", objectFit: "cover"}}/>
                        ) : null}
                    </div>
                }
            >
                <p className='al-discovery-card-title'>
                    {/*{album?.name}*/}
                    <TruncateText text={album?.name} maxLength={35}/>
                </p>
                <p className={'al-text-single'}>Contains {album?.num} items</p>
                <Button className="al-card-single"
                        onClick={onClick}
                        style={{
                            borderRadius: "5px",
                            position: "absolute",
                            bottom: 0,
                            width: "40%",
                            marginLeft: 45,
                            marginBottom: 8,
                        }}
                        disabled={is_pls && localStorage.getItem('subscription') === 'Standard'}
                >
                    Publish
                </Button>
            </Card>
        </div>
    );
};
export default AlbumCardSingle;