import React from 'react';
import { Carousel } from 'antd';
const contentStyle = {
    // height: '300px',
    color: 'black',
    lineHeight: '260px',
    textAlign: 'center',
    background: 'red',
    // padding: '10px'
};
function Brochure() {
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    return (
        <div style={{padding: '100px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <p>1234</p>
            <div className={'spacer'}></div>
            <p>1234</p>
        </div>

    );
}

export default Brochure;