import React, {useEffect, useState} from 'react';
import {Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import {get_api, post_api} from "../../serverTemp";
import ProPortalHeader from "../../componets/ProPortalHeader";
import ProfileRow from "../../componets/ProfileRow";
import CoverLayout from "../../ProDiscovery/AlbumCoverLayout";
import {useNavigate} from "react-router-dom";
import {ReactComponent as DelIcon} from '../imgs/Delete.svg'
import {ReactComponent as PubIcon} from '../imgs/Publish.svg'
import {ReactComponent as RenameIcon} from '../imgs/Rename.svg'
import Navigator from "../../componets/Navigator";
import MBModal from "../MBModal";
import left from "../../ProDiscovery/images/left.png";
import upRight from "../../ProDiscovery/images/upRight.png";
import downRight from "../../ProDiscovery/images/downRight.png";
import {ReactComponent as BackIcon} from "../../MyLibrary/ImgDisplay/Back Button and Text.svg";


const MyAlbums = (props) => {
    const [albums, setAlbums] = useState([])
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNewAlbum, setIsNewAlbum] = useState(true);
    const [curAlbumID, setCurAlbumID] = useState('')
    const [albumName, setAlbumName] = useState(''); // State to store the input value
    const handleInputChange = (e) => {
        setAlbumName(e.target.value); // Update state with input value
    };

    const handleConfirmClick = () => {
        if (isNewAlbum) {
            post_api('/album/add', {name: albumName}).then(
                res => {
                    window.location.reload()
                })
        } else {
            post_api('/album/rename', {id: curAlbumID, name: albumName}).then(
                res => {
                    window.location.reload()
                })
        }
    };
    const MBModalprops = {
        isModalOpen: isModalOpen,
        setIsModalOpen: setIsModalOpen,
        handleConfirmClick: handleConfirmClick,
        handleInputChange: handleInputChange,
        albumName: albumName,
        isNewAlbum: isNewAlbum,
    }
    useEffect(() => {
        get_api('/album/all').then(
            res => {
                setAlbums(res.data.map((album) => {
                    return {
                        id: album.id,
                        name: album.name,
                        urls: album.url,
                        item: {
                            left: album.url.split(',')[0] ?? null,
                            upRight: album.url.split(',')[1] ?? null,
                            downRight: album.url.split(',')[2] ?? null,
                        }
                    }
                }))
            }
        )
    }, [])
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <p className={'ml-albums-p'} style={{
                                display: 'flex',
                                justifyContent: 'start',
                                width: '85%',
                                margin: '3rem 0 4vh 0vw'
                            }}>
                                <span onClick={_ => window.history.back()}
                                      style={{cursor: "pointer"}}
                                >
                                                <BackIcon style={{margin: '0 0 0 0'}}/>
                                            </span>
                                <span>My Albums</span>
                            </p>

                            {albums.length > 0 && <Row justify={"flex-start"}
                                                       style={{paddingBottom: '80rem'}}
                            >
                                {albums.length > 0 ?
                                    albums.map((album, index) => {
                                        return (
                                            <div className={'mb-album-card'}>
                                                {album?.urls?.split(',').length >= 3 ?
                                                    <div className="container"
                                                         onClick={() => {
                                                             navigate('/proportal/images', {
                                                                 state: {
                                                                     albumID: album.id,
                                                                     title: album.name,
                                                                     filter: 'album'
                                                                 }
                                                             })
                                                         }}
                                                         style={{
                                                             width: 200,
                                                             height: 200,
                                                             marginLeft: 10,
                                                             marginTop: 10,
                                                             marginRight: 10,
                                                             marginBottom: 0,
                                                             cursor: 'pointer',
                                                         }}
                                                    >
                                                        <div className="left">
                                                            <img style={{height: '99%'}} src={album?.item.left || left}
                                                                 alt="Left"/>
                                                        </div>
                                                        <div className="right">
                                                            <img src={album?.item.upRight || upRight} alt="Top Right"/>
                                                            <img src={album?.item.downRight || downRight}
                                                                 alt="Bottom Right"/>
                                                        </div>
                                                    </div>
                                                    :
                                                    <img
                                                        onClick={() => {
                                                            navigate('/proportal/images', {
                                                                state: {
                                                                    albumID: album.id,
                                                                    title: album.name,
                                                                    filter: 'album'
                                                                }
                                                            })
                                                        }}
                                                        src={album.urls.split(',')[0]} alt="Album cover"
                                                        style={{
                                                            width: "80%",
                                                            height: "50%",
                                                            objectFit: "cover",
                                                            cursor: 'pointer',
                                                        }}/>
                                                }
                                                <p className={'mb-album-name'}>
                                                    {album?.name}
                                                </p>
                                                <div className={'mb-album-ops'}>
                                                    <div style={{
                                                        cursor: 'pointer', display: 'flex',
                                                        alignItems: 'center',
                                                        flexDirection: 'column',
                                                    }}
                                                         onClick={() => {
                                                             navigate('/proportal/dashboard', {state: {album_id: album.id}})
                                                         }}
                                                    >
                                                        <PubIcon/>
                                                        <p className={'ml-p-small-icon'}>PUBLISH</p>
                                                    </div>
                                                    <div style={{
                                                        cursor: 'pointer', display: 'flex',
                                                        alignItems: 'center',
                                                        flexDirection: 'column',
                                                    }}
                                                         onClick={() => {
                                                             setIsNewAlbum(false)
                                                             setCurAlbumID(album.id)
                                                             setIsModalOpen(true)
                                                         }}>
                                                        <RenameIcon/>
                                                        <p className={'ml-p-small-icon'}>RENAME</p>
                                                    </div>
                                                    <div style={{
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexDirection: 'column',
                                                    }}
                                                         onClick={() => {
                                                             post_api('/album/delete', {
                                                                 id: album.id
                                                             }).then(
                                                                 res => {
                                                                     window.location.reload()
                                                                 }
                                                             )
                                                         }}>
                                                        <DelIcon/>
                                                        <p className={'ml-p-small-icon'}>DELETE</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : <></>
                                }
                            </Row>}
                            {albums.length === 0 && <p className={'mb-no-saved-img-p'} style={{marginTop: '20vh',marginBottom:'80vh'}}>
                                You currently have no albums.
                            </p>}
                            <MBModal {...MBModalprops}/>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    )
}

export default MyAlbums;
