import React, { useState, useEffect } from 'react';
import { Card, Button } from 'antd';
import CoverLayout from "./AlbumCoverLayout"
import './CommonCardView.css';
import { useNavigate } from "react-router-dom";

const { Meta } = Card;

const ArtworkCard = (props) => {
    const subsMap = {
        "Standard": 0,
        "Advanced": 1,
        "Premium": 2
    }

    const navigate = useNavigate();
    const onClick = () => {
        console.log("check album userlevel vs album level")
        const userLevelString = localStorage.getItem('subscription')
        const userLevel = subsMap[userLevelString]
        const albumLevel = props.item.level
        console.log(userLevelString)
        console.log(userLevel)
        console.log(albumLevel)
        if (albumLevel > userLevel) {
            alert("To access the full playlist, please upgrade your account.")
        } else {
            navigate('/proportal/discover-art/album-details', { state: { album: props.item } })
        }
    }

    const urlList = props.item.url.split(',')

    // {
    //     "id": "5",
    //     "name": "National Gallery of Washington",
    //     "url": gallery5
    //   }
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {/* <Switch checked={!loading} onChange={onChange} /> */}
            <Card
                hoverable
                className='album-card-main'
                cover={
                    <div style={{
                        width: 200,
                        height: 200,
                        marginLeft: 20,
                        marginTop: 20,
                        marginRight: 20,
                        marginBottom: 0,
                        overflow: "hidden"
                    }}>
                        {urlList.length > 2 && (
                            <CoverLayout item={{left: urlList[0], upRight: urlList[1], downRight: urlList[2]}} />
                        )}
                        {urlList.length === 1 || urlList.length === 2 ? (
                            <img src={urlList[0]} alt="Album cover" style={{width: "100%", height: "100%", objectFit: "cover"}}/>
                        ) : null}
                    </div>
                }
                onClick={onClick}
            >
                {/* <Meta title={props.item.name} style={{ textAlign: "center" }}/> */}
                <p className='discovery-card-title' style={{ marginTop: -5 }}> {props.item.name} </p>
                <Button className="ml-up-btn"
                    style={{ borderRadius: "5px", position: "absolute", bottom: 0, width: "85%", marginLeft: -7, marginBottom: 20 }}>
                    {props.btn ? props.btn : "View Collections"}
                </Button>
            </Card>
        </div>
    );
};
export default ArtworkCard;