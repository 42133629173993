import React from "react";
import {Helmet} from "react-helmet";

export const A10Meta = () => (
    <Helmet>
            <title>David Bickley | Vieunite</title>
            <meta name="description"
                  content="An award-winning media artist whose body of work spans the primitive technological of the 1970’s to the digital cutting edge of today."/>
            <meta name="keywords"
                  content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@VieuniteHQ"/>
            <meta name="twitter:title" content="David Bickley | Vieunite"/>
            <meta name="twitter:description"
                  value="An award-winning media artist whose body of work spans the primitive technological of the 1970’s to the digital cutting edge of today. "/>
            <meta name="twitter:url" content="https://vieunite.com/davidbickley"/>
            <meta name="twitter:image" content={'https://vieunite.com/img/Social Card - David Bickley.jpg'}/>
            <meta property="og:url" content="https://vieunite.com/davidbickley"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content="David Bickley | Vieunite"/>
            <meta property="og:description"
                  content="An award-winning media artist whose body of work spans the primitive technological of the 1970’s to the digital cutting edge of today. "/>
            <meta property="og:image" content="https://vieunite.com/img/Social Card - David Bickley.jpg"/>
            <meta property="og:image:width" content="930"/>
            <meta property="og:image:height" content="620"/>
    </Helmet>);