import React, {useEffect, useState} from 'react';
import {Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import {get_api} from "../../serverTemp";

const ProfileRow = (props) => {
    const [stat, setStat] = useState({})
    const [user, setUser] = useState({})
    useEffect(() => {
        get_api('/textura/status/statictics').then(res => {
            setStat(res)
        })
    }, []);
    useEffect(() => {
        get_api('/user/detail').then(res => {
            setUser(res)
        })
    }, [])
    const subsMap = {
        0: "Standard",
        1: "Advanced",
        2: "Premium"
    }
    return (
        <Row style={{margin: '-6rem 0 0 0'}}>
            <Col lg={4} style={{
                display: "flex",
                alignItems: "center",
                flexDirection: 'column'
            }}>
                <div className={'pt-profile-div'}>
                    <img className={'pt-profile-div-img'}
                         src={user?.avatar}
                         style={{
                             cursor: 'pointer'
                         }}
                         alt={'avatar'}
                         onClick={() => {
                             window.location.href = '/proportal/AccountSettings'
                         }}
                    />
                </div>
                <p className={'bp-ds-profile-txt'} style={{
                    marginTop: '1rem',
                }}>
                    {user?.nickname}
                </p>
                <p className={'bp-ds-profile-txt'}>
                    {user?.email}
                </p>
                <p className={'bp-ds-profile-txt'} style={{
                    marginBottom: '1rem'
                }}>
                    {subsMap[user?.subscription]}
                </p>
            </Col>
            {/*<Col lg={4}>*/}
            {/*    <div className={'bp-ds-status-div'}>*/}
            {/*        <div className={'bp-ds-status-1'}>*/}
            {/*            <div className={'bp-ds-status-sm'}*/}
            {/*                 style={{marginLeft: '-1.2rem'}}*/}
            {/*            >*/}
            {/*                <p className={'bp-ds-status-title'}*/}
            {/*                >*/}
            {/*                    Screens*/}
            {/*                </p>*/}
            {/*                <p className={'bp-ds-status-txt'}>*/}
            {/*                    Number of screens in subscription*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*            <div className={'bp-ds-status-sm'}>*/}
            {/*                <p className={'bp-ds-status-title'}>*/}
            {/*                    Online*/}
            {/*                </p>*/}
            {/*                <p className={'bp-ds-status-txt'}>*/}
            {/*                    Number of screens currently online*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*            <div className={'bp-ds-status-sm'}>*/}
            {/*                <p className={'bp-ds-status-title'}*/}
            {/*                   style={{margin: '4px 0 1.6rem 1rem'}}*/}
            {/*                >*/}
            {/*                    Orientation*/}
            {/*                </p>*/}
            {/*                <p className={'bp-ds-status-txt'}>*/}
            {/*                    <span style={{*/}
            {/*                        margin: '4rem 0 0 0'*/}
            {/*                    }}>*/}
            {/*                        Portrait*/}
            {/*                    </span>*/}

            {/*                    <span style={{*/}
            {/*                        margin: '4rem 0 0 2.9rem'*/}
            {/*                    }}>*/}
            {/*                        Landscape*/}
            {/*                    </span>*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={'bp-ds-status-2'}>*/}
            {/*            <div className={'nb'}>*/}
            {/*                {stat?.totalScreen}*/}
            {/*            </div>*/}
            {/*            <div className={'vertical-line'}/>*/}
            {/*            <div className={'nb'}>*/}
            {/*                {stat?.onlineScreen}*/}
            {/*            </div>*/}
            {/*            <div className={'vertical-line'}/>*/}
            {/*            <div className={'nb'} style={{*/}
            {/*                width: '69px',*/}
            {/*            }}>*/}
            {/*                {stat?.portraitScreen}*/}
            {/*            </div>*/}
            {/*            <div className={'vertical-line'}/>*/}
            {/*            <div className={'nb'} style={{*/}
            {/*                width: '69px',*/}
            {/*            }}>*/}
            {/*                {stat?.landscapeScreen}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Col>*/}
        </Row>
    )
}

export default ProfileRow;
