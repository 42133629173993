import React from "react";
import {Helmet} from "react-helmet";

export const A18Meta = () => (
    <Helmet>
            <title> Ethan Platt | Vieunite</title>
            {/*<meta name="description" content="Canadian-American artist. Her paintings aim to invoke universal human experiences and emotions. Depicting figures without clear identity, her works become representations of shared humanity."/>*/}
            <meta name="keywords"
                  content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
            <meta name="twitter:card" value="summary"/>
            <meta name="twitter:site" value="@VieuniteHQ"/>
            <meta name="twitter:title" value="Vieunite Artist | Ethan Platt"/>
            <meta name="twitter:description"
                  value="Experience Ethan Platt's modern oil paintings, fusing technology and artistic vision. View his innovative creations on Vieunite's digital art platform."/>
            <meta name="twitter:url" value="https://vieunite.com/artists/ethan-platt"/>
            <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_ethan_platt.jpg"/>
            <meta property="og:url" content="https://vieunite.com/artists/ethan-platt"/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content=" Vieunite Artist | Ethan Platt"/>
            <meta property="og:description"
                  content="Experience Ethan Platt's modern oil paintings, fusing technology and artistic vision. View his innovative creations on Vieunite's digital art platform."/>
            <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_ethan_platt.jpg"/>
    </Helmet>);