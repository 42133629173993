import React, {useEffect, useState} from 'react';
import {Layout, Row, Col, Modal, Button, List, Avatar, Table, Space, Tag, Image, Divider} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import {useLocation, useNavigate} from "react-router-dom";
import {Primary_Font, TitleFont} from '../../../../settings'
import {ChargeText} from "./text";
import ChargeModal from "./components/ChargeModal";
import {getBalance} from "../BalanceBoard/service";
import {getTransHist} from "./service";

export const BackButton = () => {
    const navigate = useNavigate();
    return (
        <Row justify={"center"} style={{margin: '1rem 0 0 0'}}>
            <Col lg={20} xs={20}>
                <span style={{
                    color: "black",
                    ...Primary_Font,
                    fontSize: '1rem',
                    fontWeight: '400',
                    cursor: 'pointer',
                    textAlign: 'left'
                }}
                      onClick={() =>
                          navigate("/artistportal/balance")
                      }
                >
                    {"< Back to Vieunite Balance"}
                </span>
            </Col>
        </Row>
    )
}

function TransactionHistory() {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const navigate = useNavigate()
    const {state} = useLocation();
    const [finance, setFinance] = useState();
    const [transHist, setTransHist] = useState([])
    useEffect(() => {
        getBalance().then((res) => {
            const totalWithdrew =
                (parseFloat(res.totalRevenue ?? 0) -
                    parseFloat(res.balance ?? 0) -
                    parseFloat(res.vat ?? 0) -
                    parseFloat(res.vieuniteFee ?? 0)).toFixed(2)
            setFinance({...res, 'totalWithdrew': totalWithdrew})
        })
    }, []);
    useEffect(() => {
        getTransHist().then((res) => {
            console.log(res)
            setTransHist(res)
        })
    }, []);
    const onOK = () => {
        setIsModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
    }
    const columns = [
        {
            title: 'Img',
            dataIndex: 'pic_url',
            key: 'img',
            render: (src) => <div style={{
                height: "6rem",
                width: "6rem",
                maxHeight: "100%",
                maxWidth: "100%",
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <img src={src}
                     alt={'art'}
                     style={{
                         width: "6rem",
                         height: "6rem",
                         objectFit: 'contain',
                     }}
                />
            </div>,
        },
        window.innerWidth>600?{
            title: 'Date',
            key: 'date',
            render: (txt,record) => <>
                <p><span style={{fontWeight: 'bold'}}>{record.nickname}</span> purchased
                    <span style={{fontWeight: 'bold'}}> '{record.name}'</span></p>
                <p>{record.orderTime}</p>
            </>
        }:{},
        {
            title: 'Paid',
            key: 'paid',
            dataIndex: 'totalPay',
            render: (amt) => <p style={{fontWeight: "bold", width: '110%'}}>+ £{amt}</p>
        },
    ];
    return (
        <Layout>
            <Content className={'grey-bk'}>
                <BackButton/>
                <Row justify={"center"} style={{padding: '0.5rem 0 6rem 0'}}>
                    <Col lg={20} xs={22} style={{
                        backgroundColor: 'white',
                        padding: '2rem',
                        borderRadius: '20px'
                    }}>
                        <h2 className="title-app mobile-left"
                            style={{textAlign: "left", ...TitleFont}}>
                            <span style={{fontWeight: '500'}}>Transaction</span> History.
                        </h2>
                        <Col lg={6} xs={20} style={{rowGap: '5rem'}}>
                            <Row style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <span style={{fontWeight: '600'}}>Total Revenue:</span>
                                <span style={{fontWeight: '600'}}>{'£' + parseFloat(finance?.totalRevenue).toString() ?? '0'}</span>
                            </Row>
                            <Row style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <span style={{color: "#828282"}}>VAT(20%):</span>
                                <span style={{color: "#828282"}}>{'£' + parseFloat(finance?.vat).toString() ?? '0'}</span>
                            </Row>
                            <Row style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <span style={{color: "#828282"}}>Vieunite Fee(10% after VAT):</span>
                                <span style={{color: "#828282"}}>{'£' + parseFloat(finance?.vieuniteFee).toString() ?? '0'}</span>
                            </Row>
                            <Row style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <p style={{
                                    color: "grey",
                                    fontSize: '0.8em',
                                    cursor: "pointer"
                                }}
                                   onClick={onOK}
                                >
                                    VAT and Vieunite Fee Explained >
                                </p>
                            </Row>
                            <Row style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: '.6rem 0 .1rem 0'
                            }}>
                                <span style={{fontWeight: '600'}}>Total Withdrew:</span>
                                <span style={{fontWeight: '600'}}>{'£' + parseFloat(finance?.totalWithdrew).toString() ?? '0'}</span>
                            </Row>
                            <Row style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <span style={{fontWeight: '600'}}>Available Balance:</span>
                                <span style={{fontWeight: '600'}}>{'£' + parseFloat(finance?.balance).toString() ?? '0'}</span>
                            </Row>
                            <Row className={'withdraw-btn'}>
                                <Button onClick={() => {
                                    navigate('/artistportal/withdrawhistory')
                                }}>
                                    WITHDRAW HISTORY
                                </Button>
                            </Row>
                        </Col>
                        <Divider></Divider>
                        <Table className={'art-table'}
                               columns={columns}
                               dataSource={transHist}
                               showHeader={false}
                               bordered={false}
                        />
                    </Col>
                </Row>
                <ChargeModal
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                />
            </Content>
        </Layout>
    )
}

export default TransactionHistory;
