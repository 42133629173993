import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Image, Input, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import leftImg from '../../img/LoginSet/Photograph by Linwang Wang.webp'
import leftImg2 from '../../img/LoginSet/Artwork by Jo Vincent.webp'
import leftImg3 from '../../img/LoginSet/The Ivory Tower - Vantropolis.jpg'
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import './style.scss'
import {Primary_Font, TitleFont} from "../../settings";
import ShadowHeader from "../../components/Header/ShadowHeader";
import {login} from "./services";
import {useNavigate} from "react-router-dom";
import useWindowResize from "../Hooks/useWindowResize";
import {ReactComponent as Logo} from "../../img/LoginSet/Vieunite.svg";

const bgArts = [{
    art: leftImg,
    name: 'Linwang Wang'
}, {
    art: leftImg2,
    name: 'Jo Vincent'
}, {
    art: leftImg3,
    name: 'Christian Redermayer'
}]
const get_random = (list) => {
    return list[Math.floor((Math.random() * list.length))];
}

function ApplyLogin(props) {
    useEffect(() => {
        if (localStorage.getItem('token') && localStorage.getItem('userInfo')) {
            const storageInfo = JSON.parse(localStorage.getItem('userInfo'))
            const isArtist = storageInfo.isArtist
            if (isArtist) {
                window.location.href = '/artistportal/home'
            } else {
                //     window.location.href = '/artistportal/login'
            }
        }
    }, []);

    const [reminder, setReminder] = useState('')
    const [bgArt, setBgArt] = useState(get_random(bgArts))
    const navigate = useNavigate();
    const {windowWidth} = useWindowResize();
    const onFinish = (values) => {
        login(values, setReminder).catch(error => console.log('error', error))
    }

    return (
        <>
            <ShadowHeader></ShadowHeader>
            <Layout>
                <Content style={{overflow: windowWidth > 600 ? 'hidden' : "inherit", maxHeight: '100vh'}}>
                    <Row>
                        <Col lg={12} xs={24}>
                            <div className={'img-container'}>
                                <img className={'img-left protected-img'}
                                     src={bgArt.art}
                                     alt={'background'}/>
                                <Logo className={'logo-area'}
                                      style={{width: "80px", height: "105px"}}>
                                </Logo>
                                <p className={'author-name'}>
                                    <span style={{fontWeight: 400}}>Artwork by</span><br/> {bgArt.name}
                                </p>
                            </div>
                        </Col>

                        <Col lg={10} xs={24} className={'login-form-col'}>
                            <Row justify={windowWidth > 600 ? "center" : 'start'}
                                 style={{margin: windowWidth > 600 ? '0' : '1rem 0 0 5%'}}>
                                <h2 className="title-app"
                                    style={windowWidth >= 600 ?
                                        {textAlign: "center", ...TitleFont,} :
                                        {textAlign: "left", ...TitleFont, fontSize: '1.6rem',}}
                                >
                                    <span style={{fontWeight: '400'}}>Apply to become a Vieunite</span> Artist.
                                </h2>
                            </Row>
                            <Row justify={"center"}
                                 style={{margin: '1rem 5% 1rem 5%', width: '90%'}}>
                                <p style={{
                                    fontSize: '0.7rem', ...Primary_Font,
                                    fontWeight: '500',
                                    textAlign: "start"
                                }}>
                                    The Artist Portal is how artists can apply for and manage their Vieunite Artists
                                    accounts. <span style={{
                                    fontWeight: '600'
                                }}>To apply
                    to become a Vieunite artist you must have a Vieunite account. You can can create a free account
                    using the Vieunite app.</span> Please log into your Vieunite account to begin the application
                                    process
                                </p>
                            </Row>
                            <Row justify={"center"}>
                                <Form
                                    name="normal_login"
                                    className="login-form"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    style={windowWidth >= 600 ? {width: '50%'} : {width: '90%'}}
                                >
                                    <Form.Item
                                        labelCol={{span: 24, style: {fontWeight: '600'}}}
                                        label={'Email'}
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your email!',
                                            },
                                        ]}
                                    >
                                        <Input className={'login-input-form'}
                                               prefix={<MailOutlined className="site-form-item-icon"/>}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        labelCol={{span: 24, style: {fontWeight: '600'}}}
                                        label={'Password'}
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Password!',
                                            },
                                        ]}
                                    >
                                        <Input className={'login-input-form'}
                                               prefix={<LockOutlined className="site-form-item-icon"/>}
                                               type="password"
                                        />
                                    </Form.Item>
                                    <p style={{
                                        color: "red"
                                    }}>{reminder}</p>
                                    <p className="login-form-forgot"
                                       style={{cursor: "pointer"}}
                                       onClick={() => navigate('/artistportal/forgotpassword')}>
                                        Forgot password?
                                    </p>
                                    <Form.Item>
                                        <Button htmlType="submit" className="login-form-button">
                                            LOG IN >
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Row>
                        </Col>
                    </Row>

                </Content>
            </Layout>
        </>
    )
}

export default ApplyLogin;