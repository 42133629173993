import {CheckIcon, CrossIcon} from "./svg";

export const standardList = [
    'Up to 2',
    '500MB',
    <CheckIcon/>,
    <CrossIcon/>,
    <CrossIcon/>,
    'Pay as priced',
    <CrossIcon/>,
    <CrossIcon/>,
    // <CrossIcon/>,
    <CheckIcon/>,
    <CrossIcon/>,
]
export const advancedList = [
    'Unlimited',
    '4GB',
    <CheckIcon/>,
    <CheckIcon/>,
    <CrossIcon/>,
    'Unlimited',
    <CrossIcon/>,
    <CrossIcon/>,
    // <CrossIcon/>,
    <CheckIcon/>,
    <CheckIcon/>,
]
export const premiumList = [
    'Unlimited',
    'Unlimited',
    <CheckIcon/>,
    <CheckIcon/>,
    <CheckIcon/>,
    'Unlimited',
    <CheckIcon/>,
    <CheckIcon/>,
    // <CheckIcon/>,
    <CheckIcon/>,
    <CheckIcon/>,
]

export const detailList = [
    ['Number of Textura Pro Canvases Displayable', 'Standard Plan allows users’ media to be concurrently displayed on up to 2 Textura digital canvases.Both Advanced and Premium Plans allows users to display their media on unlimited number of Textura devices.'],
    ['Cloud storage for your media (photos, images, videos)', 'Standard Plan allows users to store and display their media, such as artworks, images, photos, videos etc., on 500MB of cloud storage space.Advanced Plan provides a 4GB cloud space, while Premium Plans offers an unlimited cloud space for users’ own media.'],
    ['Access to Classic Creative Commons Artwork (CC0)', 'Access an unlimited number of royalty-free artworks under the Creative Commons license. As a Standard Plan user, one can easily browse through our vast collection of royalty-free artworks in the Art Library within Vieunite Pro Platform.'],
    ['Access to Advanced Royalty-free Classic Artwork (CC0)', 'Advanced and Premium Plan gives users access to expertly curated advanced playlist to choose from in the Marketplace Artwork. Users are able to choose from all the advanced artwork playlist specially curated, both from classical art pieces to current artwork from living artists.'],
    ['Access to Premium Playlist with Rare Artwork', 'Premium Plan users enjoy exclusively unlimited access to all premium playlists expertly curated by the Art Curation Team. They also have unlimited access to Premium Playlists that comprise both paid and rare masterpiece collection from leading artists and world-class institutions both in the UK as well as overseas.'],
    ['Priced Artwork', 'Standard Plan users can browse the Vieunite Pro’s Marketplace Artwork section to discover a treasure trove of pay-as-priced artworks from leading artists around the world, as well as other world-class museums and galleries. Advanced and Premium Plan users have exclusive unlimited access to all the artworks within Vieunite Pro’s Marketplace Artwork section. Users will be have free access to a treasure trove of all artworks from leading artists around the world, including masterpieces and other rare collections from world-class museums and galleries, both in the UK and overseas.'],
    ['Platform Management Services', 'Premium Plan users can leave the management of Vieunite Pro platform to our designated executive who can assist to publish desired content on demand, and schedule content over the Textura displays at specific dates in accordance with the needs of the business.'],
    ['Artwork Content Management with your own dedicated Art Curator', 'Premium Plan users get exclusive, bespoke playlists and premium art content expertly curated by our art curation team. In accordance with business requirements, our expert art curation team meticulously blends arts with corporate messages/promotions/campaigns/other business displays for an optimised brand experience. In essence, our dedicated art curation team led by UK’s key opinion leader in arts curation, Dr. Benedict Carpenter van Barthold, promises a new way of curating premium art playlists tailored to the user’s brand by combining arts with other pertinent business content provided by the user. While we do not design the corporate media provided, we make sure that the combined bespoke playlists creates the wow factor for the brand.'],
    // ['Dedicated designer for bespoke playlists', 'Premium Plan further allows users to have bespoke playlist carefully tailored by a dedicated designer. The designer ensures that the bespoke playlist are designed to best differentiate Premium Plan users’ businesses.'],
    ['Dedicated Technical Support Team (Telephone and Email Support)', 'Our technical support team will provide the essential email and telephone technical support (Mon-Fri) for all Standard Plan users. Advanced Plan users will be allocated a dedicated technical support team which will provide priority email and telephone support that meets the Service Level Agreement (SLA) of offering 99.9% uptime, responding within 24 working hours (Mon-Fri) for all technical support issues. Premium Plan users will be further allocated a dedicated technical support team which will provide urgent email and telephone support that meets the Service Level Agreement (SLA) of offering 99.9% uptime, responding within 6 working hours (Mon-Fri) for all technical support issues.'],
    ['Dedicated Commercial Support Team (WhatsApp Business)', 'Premium Plan users enjoy an exclusive and dedicated Commercial Support Team which will be the essential point of contact for all matters related to Vieunite Pro solution suite. Users will have undivided attention through a 24/7 WhatsApp Business Support service looking after their Premium account.'],
]
export const mDetailList = [
    'Number of Textura Pro Canvases Displayable',
    'Cloud storage for your media (photos, images, videos)',
    'Access to Classic Creative Commons Artwork (CC0)',
    'Access to Advanced Royalty-free Classic Artwork (CC0)',
    'Access to Premium Playlist with Rare Artwork',
    'Priced Artwork',
    'Platform Management Services',
    'Artwork Content Management with your own dedicated Art Curator',
    'Dedicated designer for bespoke playlists',
    'Dedicated Technical Support Team (Telephone and Email Support)',
    'Dedicated Commercial Support Team (WhatsApp Business)',
]