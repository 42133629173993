import {APP_API_URL} from "../../../../../settings";

const token = localStorage.getItem('token');
const req = {
    method: 'GET',
    headers: {"X-Access-Token": token},
};
export const getDisciplines = (setDiscipline) => {
    fetch(APP_API_URL + '/photoframe-mobile/system/pframeArtworkCategory/getArtworkCategorylist', req)
        .then(res => res.json())
        .then(data => {
            if (data.code !== 200) {
                throw Error(`Response code: ${data.code}`)
            } else {
                setDiscipline(data.result);
            }
        })
        .catch(error => console.log('error', error));
}

export const editArtwork = async (artInfo) => {
    const reqOpt = {
        method: 'POST',
        headers: {
            "X-Access-Token": token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(artInfo)
    };
    return fetch(APP_API_URL + "/photoframe-mobile/system/pframeArtwork/edit", reqOpt)
        .then(response => response.json())
        .then(result => result.code)
}

export const getArtInfo = (id, setInfo) => {
    fetch(APP_API_URL + `/photoframe-mobile/home/selectPicById?id=${id}&type=0`,
        req)
        .then(res => res.json())
        .then(data => {
            setInfo(data.result[0]);
        })
        .catch(error => console.log('error', error));
}

export const uploadNewArt = async (artInfo) => {
    const reqBody = {
        "artworklist": [artInfo],
        "shopId": ""
    }
    const reqOpt = {
        method: 'POST',
        headers: {
            "X-Access-Token": token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(reqBody)
    };
    return fetch(APP_API_URL + "/photoframe-mobile/system/pframeArtist/addArtwork", reqOpt)
        .then(response => response.json())
        .then(result => result.code)
}