import React, {useEffect} from 'react';
import {Button, Layout} from "antd";
import {Content} from "antd/es/layout/layout";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss';
import img_1 from './imgs/Slider Image 1.webp';
import img_2 from './imgs/Slider Image 2.webp';
import img_3 from './imgs/Slider Image 3.webp';
import img_4 from './imgs/Slider Image 4.webp';
import img_5 from './imgs/Slider Image 5.webp';
import Dashboard from './imgs/Dashboard Icon_no text.svg';
import Discover from './imgs/Discover Icon_no text.svg';
import Account from './imgs/My Account Icon_no text.svg';
import Library from './imgs/My Library Icon_no text.svg';
import QR from './imgs/QR tab_no copy.svg';
import ProPortalHeader from "../componets/ProPortalHeader";
import {useNavigate} from "react-router-dom";

const ProPortalHome = (props) => {
    useEffect(() => {
        // 当组件挂载时禁用滚动
        document.body.style.overflowY = 'hidden';

        // 当组件卸载时恢复滚动
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, []);

    const navigate = useNavigate();
    const token = localStorage.getItem('token')
    if (!token) {
        window.location.href = '/business/prosignin'
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
    };
    const handleDiscoverClick = () => {
        navigate('/proportal/discover-art');
    };
    const handleLibraryClick = () => {
        navigate('/proportal/mylibrary');
    };
    const handleDashboardClick = () => {
        navigate('/proportal/dashboard');
    };
    const handleAccountClick = () => {
        navigate('/proportal/MyAccountHome');
    };

    return (
        <Layout>
            <ProPortalHeader/>
            <Content style={{position: 'relative', width: '100%', height: '100vh'}}>
                <div className="proportalHome-icon-container">
                    <div className="proportalHome-first-row-icons">
                        <div className="proportalHome-icon-with-text icon-hover-effect" onClick={handleDashboardClick}>
                            <img src={Dashboard} alt="Dashboard" className="Dashboard-icon"/>
                            <span className="proportalHome-icon-text-Dashboard">Dashboard</span>
                        </div>
                        <div className="proportalHome-icon-with-text icon-hover-effect" onClick={handleLibraryClick}>
                            <img src={Library} alt="Library" className="Library-icon"/>
                            <span className="proportalHome-icon-text">My Library</span>
                        </div>
                    </div>
                    <div className="proportalHome-second-row-icons">
                        <div className="proportalHome-icon-with-text icon-hover-effect" onClick={handleDiscoverClick}>
                            <img src={Discover} alt="Discover" className="Discover-icon"/>
                            <span className="proportalHome-icon-text">Discover Art</span>
                        </div>
                        <div className="proportalHome-icon-with-text icon-hover-effect" onClick={handleAccountClick}>
                            <img src={Account} alt="Account" className="Account-icon"/>
                            <span className="proportalHome-icon-text">My Account</span>
                        </div>
                    </div>
                    {/*<div className="qr-icon-container">*/}
                    {/*    <img src={QR} alt="QR" className="QR-icon"/>*/}
                    {/*    <span className="qr-text">*/}
                    {/*         /!*Please download the Vieunite Pro  <br /> Connecting app to  <br />link your Textura  <br />digital canvas with  <br />your business portal. <br/>To bypass the sign-in <br />step for connecting  <br />your unit within the  <br />app, please scan the  <br />QR code below:*!/*/}
                    {/*        Please download the Vieunite Pro Connecting app to link your Textura digital canvas with your business portal. To bypass the sign-in step for connecting your unit within the app, please scan the QR code below:*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                </div>
                <div className="slider-container">
                    <Slider {...settings}>
                        {[img_1, img_2, img_3, img_4, img_5].map((img, index) => (
                            <div key={index}>
                                <img src={img} alt={`Slider Image ${index + 1}`}
                                     style={{width: '100%', height: '100vh', objectFit: 'cover'}}/>
                            </div>
                        ))}
                    </Slider>
                </div>
            </Content>
        </Layout>
    );
}

export default ProPortalHome;
