import React from 'react';
import "./SupportMain.scss"
import {Col, Collapse, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import image1 from "./VieuniteApp/image001.png";
import image2 from "./VieuniteApp/image002.png";
import image3 from "./VieuniteApp/image003.png";
import image4 from "./VieuniteApp/image004.png";
import image5 from "./VieuniteApp/image005.png";
import image6 from "./VieuniteApp/image006.png";
import image7 from "./VieuniteApp/image007.png";
import image8 from "./VieuniteApp/image008.png";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";

const {Panel} = Collapse;

function ProHelpVieuniteApp() {
    return (<div>
        <Layout>
            <Content style={{backgroundColor: '#F0F0F0', fontFamily: "Proxima Nova, sans-serif"}}>
                <div className="pro-help-center-top-section">
                    <div className="pro-help-center-text-content">
                        <h2 className={'pro-help-center-subtitle'}>Vieunite Pro
                            Support</h2>
                        <h3 className={'pro-help-center-title'}>How Can We
                            Help?</h3>
                    </div>
                </div>
                <p style={{
                    width: '100%',
                    color: '#6A6A6A',
                    fontSize: '1rem',
                    fontWeight: '400',
                    paddingTop: '2rem',
                    paddingLeft: '8vw'
                }}>
                    <a href='/pro/support' style={{cursor: 'pointer', color: '#6A6A6A'}}>Support</a> / Pro
                    Portal
                </p>
                <Row justify={"center"}
                     style={{
                         backgroundColor: 'rgba(122, 6, 59, 0.08)',
                         margin: "2rem 8vw 4rem 8vw",
                         borderRadius: "15px",
                         overflow: "hidden",
                         height: "auto"
                     }}
                >
                    <Col span={10} lg={10} xs={24}>
                        <div style={{
                            marginLeft: '10%', height: '100%', display: 'flex', flexDirection: 'column'
                        }}>
                            <div className='spacer' style={{minHeight: '40px'}}></div>
                            <div style={{
                                display: "flex", flexDirection: "row", alignItems: "center",
                            }}>
                                {/*<img src={YouTubeLogo}/>*/}
                                <h2 className="title-app"
                                    style={{
                                        fontSize: '2rem', textAlign: 'left', lineHeight: 1, margin: 0 // margin: "0 1rem"
                                    }}
                                >
                                    Transform Your Business with Curated Art
                                </h2>
                            </div>

                            <p className="text-content-app"
                               style={{width: '100%', paddingRight: '2rem'}}>
                                Welcome to the Vieunite Pro App! Unlock a world of curated, high-quality art designed to
                                elevate your business. With intuitive controls, Vieunite Pro enhances your ability to
                                showcase remarkable artworks, fostering stronger brand identity and engagement. Focused
                                on delivering a seamless experience, it empowers you to create impactful, dynamic
                                environments that connect with your audiences.
                            </p>
                            <div className='spacer'></div>
                        </div>

                    </Col>

                    <Col span={14} lg={{span: 14}} xs={24}>
                        <div className="iframe-container">
                            <iframe width="640" height="auto"
                                    src="https://www.youtube.com/embed/Iu_GtNrsROo"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                                    style={{margin: "4rem 2rem", aspectRatio: '16/10'}}></iframe>
                        </div>
                    </Col>
                </Row>
                <Row justify={"center"}
                     style={{
                         // backgroundColor: '#f4f4f4',
                     }}>
                    <Col span={20} lg={20} md={20}>
                        <Collapse expandIconPosition={"end"}
                                  bordered={false}
                                  style={{
                                      backgroundColor: '#f0f0f0', color: '#f4f4f4', border: "none"
                                  }}
                                  expandIcon={(panelProps) => {
                                      if (panelProps.isActive) {
                                          return <MinusOutlined style={{fontSize: '24px', fontWeight: "900"}}/>
                                      } else {
                                          return <PlusOutlined style={{fontSize: '24px', fontWeight: "900"}}/>
                                      }
                                  }}
                        >
                            <Panel header="How do I connect to my Textura digital canvas?"
                                   className={'help-center-collapse-copy'}
                                   key="1">
                                <div>
                                    <p>Begin by linking your canvas to your Vieunite account through the application.
                                        Visit the home page and download the Vieunite Pro connection app to associate
                                        the Textura digital canvas with your business portal. To streamline the sign-in
                                        process within the app, scan the QR code available on the home page. </p>

                                    <p>After successfully connecting your canvas to your account, you can proceed with
                                        updates. For initial updates, refer to the "<a
                                            href="https://www.youtube.com/watch?v=bQFhXCeZ9NE" target="_blank">Getting
                                            Started</a>" video. This
                                        resource guides you on sending art from your PC to your canvas and provides a
                                        comprehensive overview of the software.</p>
                                </div>
                            </Panel>
                            <Panel header="How do I upload personal media files?"
                                   className={'help-center-collapse-copy'}
                                   key="2">
                                <p>You can do this within the “My Library section” of the application.</p>
                                <ol>
                                    <li>
                                        Begin by selecting “My Library” on the home-page of the Vieunite pro software.
                                    </li>
                                    <li>
                                        Navigate to “Upload Files” button within the library.
                                    </li>
                                    <li>
                                        Upload your art work to the system here.
                                    </li>
                                </ol>
                            </Panel>
                            <Panel header="How can I preview art on my Textura digital canvas before purchasing it?"
                                   className={'help-center-collapse-copy'}
                                   key="3">
                                <p>You can preview art on your textura digital canvas prior to purchasing just to ensure
                                    this is the correct piece of art work for your environment. You can do this when
                                    selecting any piece of art-work within the art library.</p>
                                <ol>
                                    <li>
                                        Begin by selecting “Discover Art” on the home-page of the Vieunite Pro software.
                                    </li>
                                    <li>
                                        Navigate and select a piece of art-work you wish to preview on your Textura
                                        digital canvas.
                                    </li>
                                    <li>
                                        Select the button “Preview it on Textura” to push this piece of content to your
                                        Textura Digital Canvas.
                                    </li>
                                </ol>
                            </Panel>
                            <Panel header="What are the recommended media formats for my Textura digital canvas?"
                                   className={'help-center-collapse-copy'}
                                   key="4">
                                <p> Before uploading any files to the Vieunite Pro software please ensure that they meet
                                    the requirements below:
                                    <p>Image:</p>
                                    <div>Container - JPEG</div>
                                    <div>Compression - Baseline</div>
                                    <div>Colour Mode - RGB</div>
                                    <div>Width - 1920 (1080 for portrait)</div>
                                    <div>Height - 1080 (1920 for portrait)</div>
                                    <div>DPI - 300</div>
                                    <div></div>
                                    <p>Video:</p>
                                    <div>Container - MP4, AVI, MPG</div>
                                    <div>Codec - MPEG-1, MPEG-2, MPEG-4, XVID*, H.264</div>
                                    <div>Width - 1920 (1080 for portrait)</div>
                                    <div>Height - 1080 (1920 for portrait</div>
                                    <div>Audio - AAC or MP3</div>
                                    <div></div>
                                    <p>For help with resizing content, please see the Best Quality Content for your
                                        Textura digital canvas <a href="https://www.youtube.com/watch?v=X3dItvqKT2s"
                                                                  target="_blank">here</a>.</p>
                                </p>
                            </Panel>
                            <Panel header="How do I reset my device to factory default?"
                                   className={'help-center-collapse-copy'}
                                   key="5">
                                <p>If your canvas is not as smooth as it previously was, or you just simply want to get
                                    it back to factory default standards, please follow the steps below:</p>
                                <ol>
                                    <li>
                                        Remove your canvas from the wall and turn it around to access the back.
                                    </li>
                                    <li>
                                        Locate the area pictured below on the back of the canvas.
                                    </li>
                                    <li>
                                        Take a small object, such as a paperclip, and insert it into the circled area.
                                        There is one included in the accessory pack with the unit.
                                    </li>
                                    <li>
                                        Keep the object inserted for approximately 10 seconds until the device is
                                        completely reset.
                                    </li>
                                </ol>
                                <p>By performing these steps, you will successfully reset the canvas device to its
                                    default settings.</p>
                                <p>For help with resetting the device, please see the video tutorial on how to do so
                                    <a href="https://www.youtube.com/watch?v=h65Mc_AQjfM" target="_blank"> here</a>.</p>
                            </Panel>
                            <Panel header="Why is my device not turning on?"
                                   className={'help-center-collapse-copy'}
                                   key="6">
                                <p>If your canvas is turning on as expected, or generally showing no sign of life,
                                    please follow these steps listed below.</p>
                                <p>Firstly, please confirm that your canvas is connected to a functional wall socket and
                                    ensure that the socket is switched on. Additionally, check that the USB Type-C
                                    connection is securely plugged into the canvas.</p>
                                <p>If you are still facing issues with this specific instance and the canvas is no
                                    longer powering on as intended, please contact us on: <a
                                        href="mailto:support@vieunite.com">support@vieunite.com</a></p>
                            </Panel>
                            <Panel header="How do I change the orientation of my Textura digital canvas?"
                                   className={'help-center-collapse-copy'}
                                   key="7">
                                <p>If you wish to change the orientation of your textura digital canvas, please follow
                                    the steps listed below:</p>
                                <ul>
                                    <li>
                                        Begin by rotating the canvas and removing the frame off the canvas.
                                    </li>
                                    <li>
                                        Remove the canvas from the wall.
                                    </li>
                                    <li>
                                        Re-seat the canvas in the desired orientation on the mounting bracket.
                                    </li>
                                    <li>
                                        Re-add the frame onto the Canvas and this should automatically power on.
                                    </li>
                                </ul>
                                <p>For further help with orientation mounting,
                                    please see a video preview <a href="https://www.youtube.com/watch?v=OGcmq7z89f0"
                                                                  target="_blank"> here</a>.</p>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>

                <Row justify={"center"}
                     style={{
                         backgroundColor: 'rgba(240, 129, 14, 0.08)',
                         margin: "0 8vw 4vw 8vw",
                         borderRadius: "15px",
                     }}
                >
                    <div style={{padding: "3rem", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <h2 className="title-app m-text"
                            style={{
                                color: 'black',
                                display: "flex",
                                justifyContent: "center",
                                margin: '0 1rem',
                            }}>
                            Request Support
                        </h2>
                        <p className="text-content-app m-text"
                           style={{
                               color: 'black',
                               textAlign: "center",
                               paddingLeft: '8rem',
                               paddingRight: '8rem',
                               // paddingTop: "1rem",
                               // paddingBottom: "1rem",
                               // margin: '0 1rem',
                           }}>
                            If you have a question that is not answered here, or you are having any issues with using
                            your
                            Textura digital canvas, please get in touch with us today via our support form
                        </p>
                        {/*<button*/}
                        {/*    className="homeMainButton product-access-support-button"*/}
                        {/*    onClick={_ => window.location.href = "/pro/support/request-support"}*/}
                        {/*    style={{width: '30vw', marginTop: '20px'}}*/}
                        {/*>*/}
                        {/*    <a className={'homeMainButtonLnk homeOverV'}>*/}
                        {/*        SUPPORT FORM*/}
                        {/*    </a>*/}
                        {/*</button>*/}
                        <button
                            className="homeMainButton black-border-hover-white"
                            onClick={_ => window.location.href = '/pro/support/request-support'}
                            style={{width: "20.5rem"}}>
                            <p
                               className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                SUPPORT FORM
                            </p>
                        </button>
                    </div>
                </Row>
            </Content>
        </Layout>
    </div>)
}

export default ProHelpVieuniteApp;