import {APP_API_URL} from "../../../../settings";

const token = localStorage.getItem('token');
export const withdrawMoney = async (num) => {
    const raw = JSON.stringify({
        "amount": parseFloat(num).toFixed(2).toString()
    });
    const reqOpt = {
        method: 'POST',
        headers: {
            "X-Access-Token": token,
            "Content-Type": "application/json"
        },
        body: raw
    };
    return fetch(APP_API_URL + "/photoframe-mobile/finance/withdraw/balance", reqOpt)
        .then(response => response.json())
        .then(res => {
            console.log(res)
            return res.code === 200 ? res : (() => {
                alert(res.message)
                throw Error(res.message)
            })()
        })
        .catch(error => console.log('error', error));
}