import React, { useState, useEffect } from 'react';
import { Avatar, Card, Button, Image, Tooltip } from 'antd';
import { useNavigate } from "react-router-dom";
import './DiscoveryArtworkCardView.css';
import { get_api, post_api } from "../serverTemp";

const openDetailsType = "artwork"

const { Meta } = Card;
const ArtworkCard = (props) => {
  const [isInCart, setIsInCart] = useState(false);
  const navigate = useNavigate();
  const onClick = () => {
    // console.log(props.item)
    navigate('/proportal/discover-art/author-details', { state: { pageType: "artwork", info: props.item } })
    window.location.reload()
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const goSubscription = (e) => {
    e.stopPropagation();
    navigate("/proportal/MyAccount")
  } 

  const addToCart = (e) => {
    e.stopPropagation();
    post_api('/shopping/cart/add/sku', { sku_id: props.item.id, sku_type: 0 })
      .then(res => {
        setIsInCart(true)
      })
  }

  function TruncateText({ text, maxLength, className }) {
    if (text.length <= maxLength) {
      return <p className={className}>{text}</p>;
    } else {
      return (
        <Tooltip title={text} placement="bottom">
            <p className={className}>{`${text.substring(0, maxLength)}...`}</p>
        </Tooltip>
      );
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1rem", ...props.style }}>
      <Card
        hoverable
        style={{
          width: 280,
          height: 395,
          borderRadius: "14px",
          // border: "2px solid #DFDFDF"
        }}
        cover={<div style={{ width: 250, height: 200, marginTop: "15px", marginLeft: "15px" }}>
          <img alt={props.item.description} src={props.item.url} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
        </div>}
        onClick={onClick}
      >
        {/* <Meta title={props.item.name} description={props.item.description} /> */}
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <TruncateText className='artwork-card-title' text={props.item.name} maxLength={19} />
          <TruncateText className='artwork-card-author' text={props.item.artist.name} maxLength={21} />
          {/* <p className='artwork-card-author'>{props.item.artist.name}</p> */}
          <p className='artwork-card-price-description'><span className='artwork-card-price'>£{props.item.price}</span>with Standard Subscription</p>
          <p className='artwork-card-subscription-description' onClick={goSubscription}>included as part of<br /> <span className='artwork-card-subscription-type'>Advanced & Premium Subscription</span></p>

          {isInCart || (localStorage.getItem('subscription') !== "Standard") ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 36 35" fill="none">
              <circle cx="18.4512" cy="17.5" r="17.5" fill="#00AE11" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M29.4212 11.3793C30.1024 10.5098 29.917 9.29712 29.0072 8.67077C28.0974 8.04441 26.8076 8.24157 26.1265 9.11112L14.9122 23.4275L10.695 19.956C9.84223 19.254 8.53879 19.3384 7.7837 20.1446C7.02861 20.9508 7.10782 22.1734 7.96062 22.8754L13.8608 27.7324C14.7136 28.4344 16.017 28.3499 16.7721 27.5437C16.876 27.4329 16.9641 27.3141 17.0365 27.1899L29.4212 11.3793Z" fill="white" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none" onClick={addToCart}>
              <circle cx="17.5" cy="17.5" r="17.5" fill="#0E487A" />
              <path d="M6.60547 8.64062H9.12141C9.5472 8.64062 9.92629 8.91023 10.066 9.31243L10.7073 11.1583M10.7073 11.1583L13.4364 19.013C13.5761 19.4152 13.9552 19.6848 14.381 19.6848H22.5743C22.9341 19.6848 23.2661 19.4916 23.4438 19.1788L27.1517 12.6523C27.5305 11.9856 27.049 11.1583 26.2823 11.1583H10.7073Z" stroke="white" stroke-width="2" />
              <path d="M26.3833 24.7221H13.8688C10.6245 24.7221 9.85922 19.668 14.3151 19.668" stroke="white" stroke-width="2" stroke-linecap="round" />
              <ellipse cx="6.65239" cy="8.62635" rx="0.652389" ry="0.62635" fill="white" />
              <ellipse cx="15.2542" cy="28.9141" rx="1.13111" ry="1.08596" fill="white" />
              <ellipse cx="21.9553" cy="28.9141" rx="1.13111" ry="1.08596" fill="white" />
            </svg>
          )}
        </div>
      </Card>

      {/* <Card
        style={{
          width: 300,
          marginTop: 16,
        }}
        actions={[
          <SettingOutlined key="setting" />,
          <EditOutlined key="edit" />,
          <EllipsisOutlined key="ellipsis" />,
        ]}
      >
        <Skeleton loading={loading} avatar active>
          <Meta
            avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />}
            title="Card title"
            description="This is the description"
          />
        </Skeleton>
      </Card> */}
      
    </div>
  );
};
export default ArtworkCard;