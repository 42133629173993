import React, { useEffect, useState, useRef } from "react";
import ArtistCollections from "./DiscoveryArtistsSlickView";
import ArtworkCollections from "./DiscoveryArtworkSlickView";
import GalleryCollections from "./DiscoveryGallerySlickView";
import AlbumCollections from "./DiscoveryAlbumSlickView";
import { Button, Layout } from "antd";
import ProPortalHeader from "../componets/ProPortalHeader";
import ProfileRow from "../componets/ProfileRow";
import Navigator from "../componets/Navigator";
import { Content } from "antd/es/layout/layout";
import "./DiscoveryCollections.css"
import { useNavigate } from "react-router-dom";
import { get_api } from "../serverTemp";

const DiscoveryCollections = () => {
    const targetRef = useRef(null)

    const navigate = useNavigate();
    useEffect(() => {
        get_api('/artwork/recommendation/artworks').then(res => {
            setArtworks(res.data);
        });
        get_api('/artist/all').then(res => {
            setArtists(res.data);
        });
        get_api('/gallery/all').then(res => {
            setGalleries(res.data);
        });
        get_api('/playlist/featured').then(res => {
            setAlbums(res.data);
        });
    }, [])

    const seeAllArtworks = () => {
        navigate("/proportal/discover-art/artworks-seeall")
    };
    const [artworks, setArtworks] = useState([]);

    const seeAllArtists = () => {
        navigate("/proportal/discover-art/artists-seeall")
    };
    const [artists, setArtists] = useState([]);

    const seeAllGalleries = () => {
        navigate("/proportal/discover-art/galleries-seeall")
    };
    const [galleries, setGalleries] = useState([]);

    const seeAllAlbums = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth', block: "end" });
          }
    };
    const [albums, setAlbums] = useState([]);

    return (
        <Layout>
            <Content>
                <ProPortalHeader />
                <ProfileRow />
                <Layout>
                    <Navigator />
                    <Layout style={{ minHeight: "100vh" }}>
                        <p className="discover-main-filter-title">Marketplace Artwork</p>
                        <div style={{marginLeft: "40px"}}>
                            {/* <Button className="discover-category-button" style={{ background: "#0E477A", color: "#FFF", borderRadius: "5px"}}>All</Button> */}
                            <Button className="discover-category-button" style={{ borderRadius: "5px"}} onClick={seeAllArtworks}>All Arts</Button>
                            <Button className="discover-category-button" style={{ borderRadius: "5px"}} onClick={seeAllAlbums}>Playlists</Button>
                            <Button className="discover-category-button" style={{ borderRadius: "5px"}} onClick={seeAllArtists}>Artists</Button>
                            <Button className="discover-category-button" style={{ borderRadius: "5px"}} onClick={seeAllGalleries}>Galleries & Museums</Button>
                        </div>
                        <div className="collections-container" style={{ margin: "0 35px 0 35px" }}>
                            <ArtworkCollections artworks={artworks} />
                            <div style={{ display: "flex", justifyContent: "flex-end", margin: "0 40px 0 40px" }}>
                                <p className="discover-see-all-font" onClick={seeAllArtworks}>See All</p>
                            </div>
                            <AlbumCollections albums={albums} />
                            <div style={{height: "60px"}} ref={targetRef}></div>
                            <ArtistCollections artists={artists}/>
                            <div style={{ display: "flex", justifyContent: "flex-end", margin: "0 40px 0 40px" }} >
                                <p className="discover-see-all-font" onClick={seeAllArtists}>See All</p>
                            </div>
                            <div style={{height: "10px"}} ></div>
                            <GalleryCollections galleries={galleries} />
                            <div style={{ display: "flex", justifyContent: "flex-end", margin: "0 40px 40px 40px" }}>
                                <p className="discover-see-all-font" onClick={seeAllGalleries}>See All</p>
                            </div>
                        </div>
                    </Layout>
                </Layout>
            </Content>
        </Layout>
    );
};

export default DiscoveryCollections;