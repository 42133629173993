import React, {useEffect, useState} from 'react';
import './style.scss';
import {Button, Checkbox, Layout, Row, Table} from "antd";
import {Content} from "antd/es/layout/layout";
import ProPortalHeader from "../../../componets/ProPortalHeader";
import ProfileRow from "../../../componets/ProfileRow";
import Navigator from "../../../componets/Navigator";
import {useNavigate} from "react-router-dom";
import {get_api, post_api} from "../../../serverTemp";
import backButton from './img/Back button.svg';


const statusMappings = {
    0: 'poweroff',
    1: 'poweron',
    2: 'sleep',
    3: 'wake',
};
const typeMappings = {
    0: 'Standard',
    1: 'Advanced',
    2: 'Premium',
};
const columns = [
    {
        title: '',
        dataIndex: 'select',
        render: () => <Checkbox/>,
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Serial No.',
        dataIndex: 'sn',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: status => statusMappings[status] || 'Unknown', // 使用映射对象转换状态码
    },
    {
        title: 'Group',
        dataIndex: 'group',
    },
    {
        // title: 'Playing (which Playlist)',
        title: 'Playlist',
        dataIndex: 'playlist',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        render: type => typeMappings[type] || 'Unknown', // 使用映射对象转换状态码
    },
];

const MainContent = () => {
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    useEffect(() => {
        get_api('/textura/all').then(res => {
            const newData = res.data || [];
            setData(newData);
            // 基于新数据初始化selectedRows
            setSelectedRows(newData.map(item => item.key));
        })
    }, []);
    // Toggle selection
    const toggleRowSelection = (record) => {
        const newSelectedRows = selectedRows.includes(record.key)
            ? selectedRows.filter(key => key !== record.key)
            : [...selectedRows, record.key];
        setSelectedRows(newSelectedRows);
    };

    // Adjust the render method for the checkbox to handle selection
    const updatedColumns = columns.map(col => {
        if (col.dataIndex === 'select') {
            return {
                ...col,
                render: (_, record) => (
                    <Checkbox
                        checked={true} // 总是选中状态
                        onClick={(e) => e.preventDefault()} // 阻止点击事件
                    />
                ),
            };
        }
        return col;
    });

    // Use rowClassName to conditionally apply the class to the row
    const getRowClassName = (record) => {
        return selectedRows.includes(record.key) ? 'row-selected' : '';
    };
    const navigate = useNavigate();
    const handleChangeUpdateClick = () => {
        post_api('/subscription/upgrade', {subscription_type: 1})
            .then(() => {
                navigate('/business/success');
            })
            .catch(error => console.error('Error:', error))
    };
    const handleBackClick = () => {
        navigate(-1); // 使用navigate函数导航回上一页
    };
    return (
        <div className='Portal-Upgrade-Sub' style={{paddingLeft: '10px'}}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '50px', marginLeft: '-80px'}}>
                <img src={backButton} alt="BackButton" className='BackButtonIcon'
                     style={{marginRight: '1.5rem', cursor: "pointer"}} onClick={handleBackClick}/>
                <p className='Portal-Upgrade-Sub-Title' style={{margin: 0}}>Update your subscription</p>
            </div>
            <Table
                className='custom-proaccount-table'
                columns={updatedColumns}
                dataSource={data}
                pagination={false}
                rowClassName={getRowClassName}
            />
            <div style={{marginTop: '10rem', marginLeft: '25vw'}}>
                <p className="Portal-Upgrade-Para">These Textura canvases will be upgraded</p>
                <Button className="Portal-Upgrade-Button" onClick={handleChangeUpdateClick}>
                    Upgrade to Advanced
                </Button>
            </div>
        </div>
    );
};
const UpgradeSubscription = () => {
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <MainContent/>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    );
}
export default UpgradeSubscription;