import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {Layout, Menu} from 'antd';
import {HomeIcon, DashboardIcon, LibIcon, ArtIcon, AccountIcon} from "../../DashBoard/imgs/Icons";
import './style.scss';

const {Sider} = Layout;

const Navigator = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    // const [collapsed, setCollapsed] = useState(false);
    const [collapsed, setCollapsed] = useState(() => {
        const isCollapsed = localStorage.getItem('sidebarCollapsed');
        return isCollapsed === 'true'; // Convert string back to boolean
    });
    // 定义路由与菜单键的映射
    const pathKeyMap = {
        '/proportal': '0',
        '/proportal/dashboard': '1',
        '/proportal/mylibrary': '2',
        '/proportal/discover-art': '3',
        '/proportal/MyAccountHome': '4',
    };

    // 根据当前路由设置选中的菜单项
    const [selectedKey, setSelectedKey] = useState(pathKeyMap[location.pathname]);

    useEffect(() => {
        // 根据 selectedKey 导航
        const path = Object.keys(pathKeyMap).find(key => pathKeyMap[key] === selectedKey);
        if (path && location.pathname !== path) {
            navigate(path);
        }
    }, [selectedKey, navigate, location.pathname]);

    useEffect(() => {
        // 监听路由变化来更新 selectedKey
        const key = pathKeyMap[location.pathname];
        setSelectedKey(key);
    }, [location]);

    useEffect(() => {
        // Save collapsed state to localStorage on change
        localStorage.setItem('sidebarCollapsed', collapsed);
    }, [collapsed]);

    const items = [
        {key: '0', label: <p className={'bp-nav-txt'}>Home</p>, icon: <HomeIcon/>},
        {key: '1', label: <p className={'bp-nav-txt'}>Dashboard</p>, icon: <DashboardIcon/>},
        {key: '2', label: <p className={'bp-nav-txt'}>My Library</p>, icon: <LibIcon/>},
        {key: '3', label: <p className={'bp-nav-txt'}>Discover Art</p>, icon: <ArtIcon/>},
        {key: '4', label: <p className={'bp-nav-txt'}>My Account</p>, icon: <AccountIcon/>},
    ];

    return (
        <Sider
            className={'sticky-sider'}
            collapsible collapsed={collapsed} onCollapse={(value) => {
            setCollapsed(value);
            localStorage.setItem('sidebarCollapsed', value); // Update localStorage when sidebar collapses/uncollapses
        }}>
            <Menu
                selectedKeys={[selectedKey]}
                mode="inline"
                theme="dark"
                inlineCollapsed={collapsed}
                items={items}
                onClick={(e) => {
                    setSelectedKey(e.key);
                }}
            />
        </Sider>
    );
};

export default Navigator;
