import React, {useState} from 'react';
import {Button, Input, Layout, message, Modal, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import {post_api} from "../../serverTemp";

const GroupModal = (props) => {
    const {
        isModalOpen,
        setIsModalOpen,
        // groupName,
        // handleInputChange,
        // handleConfirmClick,
        isNewGroup,
        curGroups
    } = props
    const [newName, setNewName] = useState('')
    return (
        <Modal
            className={'gp-ml-new-album-modal'}
            visible={isModalOpen}
            onOk={_ => setIsModalOpen(false)}
            onCancel={_ => setIsModalOpen(false)}
        >
            {isNewGroup ?
                <div>
                    <p className={'ml-new-album-modal-title'}>Create a New Group</p>
                </div> : <p className={'ml-new-album-modal-title'}>Rename Your Group</p>}

            <p className={'ml-new-album-modal-sub-title'} style={{
                fontSize: '0.9vw',
                marginTop: '0'
            }}>Give Your Group a Name</p>
            <Input className={'ml-new-album-modal-input'}
                   style={{width: '17vw', margin: '4vh 0 0 5vw', height: '4.6vh'}}
                   placeholder="Group Name"
                   onChange={(e) => {
                       setNewName(e.target.value)
                   }}/>
            <Row justify={'center'} style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '4vh',
            }}>
                <Button className={'h-gp-btn'}
                        onClick={() => {
                            console.log(curGroups)
                            if (isNewGroup) {
                                post_api('/textura/group/add', {
                                    name: newName,
                                    level: curGroups.length === 1 ? parseInt(curGroups[0].level) + 1 : 0,
                                    parent_id: curGroups.length === 1 ? curGroups[0].id : null
                                }).then(() => {
                                    setIsModalOpen(false)
                                    window.location.reload()
                                })
                                    .catch(error => console.error('Error:', error))
                            } else {
                                post_api('/textura/group/rename', {
                                    id: curGroups[0].id,
                                    name: newName,
                                    level: curGroups[0].level,
                                }).then(() => {
                                    setIsModalOpen(false)
                                    window.location.reload()
                                }).catch(error => console.error('Error:', error))
                            }

                        }}
                >
                    Confirm
                </Button>
                <Button className={'h-gp-btn'}
                        style={{marginLeft: '1vw'}}
                        onClick={() => {
                            setIsModalOpen(false)
                        }}
                >
                    Close
                </Button>
            </Row>

        </Modal>
    )
}

export default GroupModal;
