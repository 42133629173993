import React from 'react';

export const ShopIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 40 37"
                                   fill="none">
    <path
        d="M1.84766 1.94141H6.09304C6.5234 1.94141 6.90548 2.21674 7.04166 2.62498L8.15968 5.97669M8.15968 5.97669L12.4903 18.9594C12.6265 19.3676 13.0086 19.6429 13.4389 19.6429H26.7227C27.0887 19.6429 27.4254 19.4431 27.6006 19.1218L33.964 7.45554C34.3275 6.78916 33.8452 5.97669 33.0861 5.97669H8.15968Z"
        stroke="black" stroke-width="2.5"/>
    <path d="M32.2833 27.7219H13.0258C8.03337 27.7219 6.8558 19.6211 13.7125 19.6211" stroke="black" stroke-width="2.5"
          stroke-linecap="round"/>
    <circle cx="1.92188" cy="1.92578" r="1.00391" fill="black"/>
    <circle cx="15.1585" cy="34.4437" r="1.74056" fill="black"/>
    <circle cx="25.471" cy="34.4437" r="1.74056" fill="black"/>
</svg>

export const SupportIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 40 43"
                                      fill="none">
    <path
        d="M30.459 33.0222H23.2923L15.3194 38.3255C14.1369 39.1138 12.5423 38.2718 12.5423 36.8385V33.0222C7.16732 33.0222 3.58398 29.4389 3.58398 24.0639V13.3138C3.58398 7.9388 7.16732 4.35547 12.5423 4.35547H30.459C35.834 4.35547 39.4173 7.9388 39.4173 13.3138V24.0639C39.4173 29.4389 35.834 33.0222 30.459 33.0222Z"
        stroke="#292D32" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M21.4995 20.3516V19.9754C21.4995 18.757 22.2521 18.112 23.0046 17.5924C23.7391 17.0908 24.4736 16.4458 24.4736 15.2633C24.4736 13.615 23.1478 12.2891 21.4995 12.2891C19.8512 12.2891 18.5254 13.615 18.5254 15.2633"
        stroke="#292D32" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.4913 24.6341H21.5074" stroke="#292D32" stroke-width="2.5" stroke-linecap="round"
          stroke-linejoin="round"/>
</svg>


export const LogoutIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 52 61"
                                     fill="none">
    <path
        d="M15.4492 46.5378H32.7826C38.7626 46.5378 43.6159 40.8444 43.6159 33.8294C43.6159 26.8144 38.7626 21.1211 32.7826 21.1211H8.94922"
        stroke="black" stroke-width="2.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.9314 27.4743L8.38477 20.9676L13.9314 14.4609" stroke="black" stroke-width="2.6" stroke-linecap="round"
          stroke-linejoin="round"/>
</svg>