import React from "react";
import {Helmet} from "react-helmet";

export const A5Meta = () => (
    <Helmet>
        <title>Jenette Coldrick | Vieunite</title>
        <meta name="description"
              content="Working from her studios in Redcar and micro pottery in Stamford, Jenette uses her work to explore her emotions, memory and the environment and fills her work with the excitement she feels for life."/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@ VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Jenette Coldrick"/>
        <meta name="twitter:description"
              value="Jenette Coldrick's expressive digital portraits capture emotion and depth. Discover her stunning visual storytelling on Vieunite's digital canvases. "/>
        <meta name="twitter:url" value="https://vieunite.com/artists/jenette-coldrick"/>
        <meta name="twitter:image" value=" https://vieunite.com/images/vieunite_sc_jenette_coldrick.jpg"/>

        <meta property="og:url" content=" https://vieunite.com/artists/jenette-coldrick"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Vieunite Artist | Jenette Coldrick"/>
        <meta property="og:description"
              content="Jenette Coldrick's expressive digital portraits capture emotion and depth. Discover her stunning visual storytelling on Vieunite's digital canvases."/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_jenette_coldrick.jpg"/>
    </Helmet>);