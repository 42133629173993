import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Divider, Form, Image, Input, Layout, Row, Select} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import {useNavigate} from "react-router-dom";
import useWindowResize from "../Hooks/useWindowResize";
import ShadowHeader from "../../components/Header/ShadowHeader";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import {Primary_Font, TitleFont} from "../../settings";
import {ApplyText} from "./text";
const Apply = (props) => {

    const navigate = useNavigate();

    const {windowWidth} = useWindowResize();
    const onFinish = (values) => {
        navigate('/artistportal/artlist', {state: {isApplication: true, applyInfo: values}})
    }

    return (
        <>
            <ShadowHeader></ShadowHeader>
            <Layout>
                <Content className={'grey-bk'} style={{maxHeight: 'auto', height: 'auto'}}>
                    <Row justify={"center"}
                         style={windowWidth > 600 ? {padding: '8rem 0 6rem 0',} : {marginTop: '1rem'}}>
                        <Col lg={12} xs={24}
                             style={{
                                 backgroundColor: 'white',
                                 padding: windowWidth > 600 ? '4rem' : '2rem',
                                 borderRadius: windowWidth > 600 ? '20px' : 0,
                                 display: 'flex',
                                 justifyContent: "center",
                                 flexDirection: "column"
                             }}>
                            <Row justify={"center"}
                                 style={{}}>
                                <h2 className="title-app"
                                    style={windowWidth >= 600 ?
                                        {textAlign: "center", ...TitleFont,} :
                                        {textAlign: "left", ...TitleFont, fontSize: '2rem',}}
                                >
                                    Become a Vieunite Artist
                                </h2>
                            </Row>
                            <Row justify={"center"}
                                 style={{}}>
                                <h2 className="title-app"
                                    style={windowWidth >= 600 ?
                                        {textAlign: "center", marginTop: '-4%', ...TitleFont,} :
                                        {display: "none", textAlign: "left", ...TitleFont, lineHeight: '0'}
                                    }>
                                    <span style={{fontWeight: '300', fontSize: windowWidth > 600 ? '2rem' : '1.4rem'}}>
                                        Artist Details
                                    </span>
                                </h2>
                            </Row>
                            <Row justify={"center"}>
                                {ApplyText['intro'].map((text, index) =>
                                    <p key={index} style={windowWidth > 600 ? {textAlign: "center", ...Primary_Font} : {
                                        textAlign: "left", ...Primary_Font
                                    }}>{text}</p>
                                )}
                            </Row>
                            <Row justify={windowWidth > 600 ? "center" : 'start'} style={{marginTop: '-2%'}}>
                                <p style={windowWidth > 600 ? {
                                    textAlign: "center", ...Primary_Font,
                                    fontSize: '0.8rem'
                                } : {
                                    textAlign: "left", ...Primary_Font, fontSize: '0.8rem'
                                }}><span style={{color: 'red'}}>*</span> Indicates a required field</p>
                            </Row>
                            <Row justify={"center"}>
                                <Form
                                    name="application"
                                    className="login-form"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    style={windowWidth >= 600 ? {width: '50%'} : {width: '90%'}}
                                >
                                    <Form.Item
                                        labelCol={{span: 24, style: {fontWeight: '600'}}}
                                        name="selfintroduction"
                                        label="Biography"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input this information!',
                                            },
                                        ]}
                                    >
                                        <Input.TextArea maxLength={10000} allowClear showCount className={'login-input-form'} rows={3}/>
                                    </Form.Item>
                                    <Form.Item
                                        hasFeedback={true}
                                        labelCol={{span: 24, style: {fontWeight: '600'}}}
                                        label={'Portfolio/Website Link'}
                                        name="artistRelateWebsites"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input this information!',
                                            },
                                            {
                                                type: 'url',
                                                message: 'Please input a valid url(start from "https://" or "http://")',
                                            },
                                        ]}
                                    >
                                        <Input maxLength={255} className={'login-input-form'}/>
                                    </Form.Item>
                                    <Form.Item
                                        labelCol={{span: 24, style: {fontWeight: '600'}}}
                                        label={'Education'}
                                        name="graduationSchool"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please input this information!',
                                            },
                                        ]}
                                    >
                                        <Input maxLength={255} className={'login-input-form'}/>
                                    </Form.Item>
                                    <Form.Item
                                        labelCol={{span: 24, style: {fontWeight: '600'}}}
                                        label={'Credentials and past exhibitions'}
                                        name="artistRelatecertificates"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please input this information!',
                                            },
                                        ]}
                                    >
                                        <Input className={'login-input-form'}/>
                                    </Form.Item>
                                    <Form.Item style={{marginTop: '4rem'}}>
                                        <Button htmlType="submit" className="login-form-button"
                                                style={{fontWeight: 'bold'}}>
                                            SUBMIT >
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Row>
                            <Row justify={"center"}>
                                <p style={{
                                    textAlign: "center",
                                    ...Primary_Font,
                                    textDecoration: "underline",
                                    cursor: 'pointer',
                                }}
                                   onClick={() => {
                                       localStorage.clear();
                                       window.location.href = '/home';
                                   }}
                                >
                                    Log out
                                </p>
                            </Row>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    )
}
export default Apply;