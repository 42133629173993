import {APP_API_URL} from "../../../settings";
const token = localStorage.getItem('token');
export const getAllHomeArts = async (setUserData) => {
    const req = {
        method: 'GET',
        headers: {"X-Access-Token": token},
    };
    const userId = JSON.parse(localStorage.getItem('userInfo')).id
    let pageNo = 1
    let artsInfo = [];
    const fetchArts = () => {
        return fetch(APP_API_URL + `/photoframe-mobile/system/pframeUser/public_page/queryById?id=${userId}&userId=${userId}&pageNo=${pageNo}`,
            req)
            .then(res => res.json())
            .then(data => {
                if (data.result.userInfo.avatar !== null) {
                    data.result.userInfo.avatar = data.result.userInfo.avatar.replace(':8082', '').replace('http:', 'https:')
                }
                else{
                    data.result.userInfo.avatar = 'https://vieunite.com/img/avatar.jpg'
                }
                if (data.result.userInfo.backgroundUrl !== null) {
                    data.result.userInfo.backgroundUrl = data.result.userInfo.backgroundUrl.replace(':8082', '').replace('http', 'https')
                }
                setUserData(data.result.userInfo);
                let records = data.result.artworkList.records;
                artsInfo.push(...records.filter(art => art.powerType === 1).map(art => {
                    return {src: art.picUrl, width: art.picWidth, height: art.picHeight}
                }))
                if (records.length === 20) {
                    pageNo++;
                    return fetchArts();
                }
                return artsInfo
                // setArts(arts);
            })
            .catch(error => console.log('error', error));
    }
    return fetchArts();
}