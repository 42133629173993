import React from 'react';
import './style.scss'

const ArtistsTermsAndConditions = () => {
    return (
        <div className="privacy-policy-container">
            <p><strong>Vieunite Artist</strong></p>
            <p><strong>Terms and Conditions</strong></p>
            <p><strong>Updated: 9-Jul-24</strong></p>
            {/*<p> This tells you information about us and the legal terms and conditions (“Terms”) on which the Textura*/}
            {/*    products and Vieunite Pro subscription services (“Products” and “Services”) listed on our website*/}
            {/*    <a href="https://vieunite.com/business"> https://vieunite.com/business </a> and any associated digital*/}
            {/*    platform which may be released by us from time to*/}
            {/*    time (together, the “Platform”) are offered for sale to you.</p>*/}
            <p> This tells you information about us and the legal terms and conditions (“Terms”) on which the Vieunite
                services listed on our website (www.vieunite.com) and any associated digital platform which may be
                released by us from time to time (together, the “Platform”) are offered for you to make your Artwork
                available to consumers and businesses via our Platform. </p>
            <section>
                <p><strong> INTRODUCTION </strong></p>
                <p>Vieunite provides a Platform for Artists to make their Artwork available to businesses and consumers
                    and generate income.</p>
                <p>These Terms apply to any Artwork you make available to Vieunite Users via the Platform, together with
                    the associated Artist Content and information required to set up an Artist Account, such as your
                    biography and profile Ppicture. Please read these Terms carefully before applying for and opening an
                    Artist Account and each time you make Artwork available through the Platform. By applying for and
                    opening an Artist Account and making Artwork available on the Platform, you agree to be bound by
                    these Terms and any general terms and conditions of use, which may be published on the Platform from
                    time to time.</p>
                <p>Every time you wish to publish an Artwork, please check these Terms, as we may have made changes to
                    them since your last upload. The Terms in force at the date of each order will apply. We reserve the
                    right to amend these Terms at any time.</p>
            </section>
            <section>
                <p><strong>CONTRACT PARTIES</strong></p>
                <p>“We”, “us”, “our”, “Vieunite” are Vieunite Limited, a company registered in England and Wales under
                    company number 13883746, with our registered office at Allsee Building, 38 Kettles Wood Drive,
                    Birmingham, United Kingdom, B32 3DB.</p>
                <p>“You,” “your,” or “yours” refers to the person who applies or is accepted as an Artist on the
                    Vieunite Platform. </p>
                <p>No terms under this document shall be bound by the provisions under the Contracts (Rights of Third
                    Parties) Act 1999 unless provisions hereinunder explicitly state otherwise.</p>
                <p>"Account" means our records of any information you supplied to us. This includes information about
                    you, your Artwork, and your use of our Services. </p>
                <p>“Artist” means someone Vieunite has accepted to publish their Artwork and Artist Content on our
                    Platform. </p>
                <p>“Artist Content” is all the material an Artist provides for publication on the Platform. It includes
                    a profile picture, the Artist’s name, the Artist’s biography or statement, any other text, image, or
                    weblink provided by the Artist, and all Artwork and associated Artwork data such as date of
                    completion, media, title, price, etc. It does not include the Artist’s email address or other
                    personally sensitive data.
                </p>
                <p>“Artwork” is the digital images made by the Artist and published through the Platform for Users to
                    view. </p>
                <p>“Contract” means these General Terms and Conditions, the Subscription Terms and Conditions, and other
                    policies, such as our Privacy Policy. </p>
                <p>“Code of Practice” means Vieunite Pro’s code of practice detailing our method of dealing with any
                    complaints or disputes relating to our Products and Services, which can be found at
                    www.vieunite.com/business.</p>
                <p>"Content" means any text, visual or other information, software, photos, video, graphics, sound and
                    other material appearing on or available through the Services for use on our Products or otherwise,
                    including any information supplied from time to time for which Vieunite has the Rights to resell or
                    allows a supplier of such Content to provide them via our portal network.</p>
                <p>“Intellectual Property” or “IP” means any intellectual property rights anywhere in the world, whether
                    registerable or not, including, without limitation, patents, trademarks, service marks, designs,
                    copyrights and related rights, database rights, know-how, moral rights and domain names, as well as
                    applications for registration of such rights and the right to apply for registrations, and all
                    equivalent or similar forms of protection notwithstanding how they arise or in which media.</p>
                <p>“Platform” means the Vieunite and the Vieunite Pro Platform, available through our website and web
                    applications. </p>
                <p>“Privacy Policy” means the Vieunite policy on controlling your information which can be found at
                    www.vieunite.com/business/privacy-policy;</p>
                <p>"Rights" means copyright, trademark and other relevant proprietary and intellectual property rights
                    relating to Content;</p>
                <p>“Service” means the ability of an Artist to publish Artwork and Artist Content through the
                    Platform.</p>
                <p>“User” means a business or consumer using the Platform to access Artwork. </p>
                <p>“Vieunite Creative Commitment Fund” is a mechanism for returning a basic income to Artists with a
                    minimum of five works in the Vieunite Pro Platform. ;
                </p>
            </section>
            <section>
                <p><strong>HOW TO CONTACT US</strong></p>
                <p>When we refer to "in writing" in these Terms, this will include e-mail.</p>
                <p>If you have any questions or complain about the Products or these Terms, these should be sent to us
                    at <a
                        href="mailto:support@vieunite.com"
                    >support@vieunite.com</a> or by mail to
                    VieuniteLimited at Allsee Building, 38 Kettles Wood Drive,
                    Birmingham, United Kingdom,
                    B32 3DB. If we have to contact you, we may do so by telephone, or by writing to you at the e-mail
                    address or postal address you have provided to us at the time you place your order.</p>
            </section>
            <section>
                <p><strong>Creating an Artist Account</strong></p>
                <p>Artists are accepted onto the Vieunite Platform either by invitation or application. Vieunite is a
                    curated library, and we reserve the right to reject applications without providing a reason. If you
                    wish to make your work available through our Platform, you will need to register and create an
                    Account with us using the Platform. </p>
                <p>When you create an Account, you must not:</p>
                <p>(a) Impersonate another individual;</p>
                <p>(b) Create ‘bot’ Accounts or any Account which is controlled by other automated means; or</p>
                <p>(c) Share your password, give others access to your account, or transfer your account to anyone
                    else.</p>
                <p>You agree to take measures to maintain the security and secrecy of your Account username, password,
                    and Account information. You must notify us immediately of any breach of security or unauthorised
                    use of your Account that you become aware of.</p>
                <p>You agree that you will be solely responsible for all activity under your Account.</p>
                <p>You confirm that the information you provide when creating your Account is accurate and complete and
                    that you will update us via the Platform as and when your information changes.</p>
                <p>By setting up an Account, you confirm that you are at least 18 years of age, not precluded by
                    domestic laws from using the Services, and have not been previously banned from using the Platform
                    and/or its Services.</p>
            </section>
            <section>
                <p><strong>License</strong></p>
                <p>We grant you a revocable, non-transferable, non-exclusive license to use the Platform, receive the
                    Services on your Devices, and make your Artwork available to Users, subject to the terms of this
                    Agreement, the Privacy Policy, and any other documents referred to hereunder. </p>
                <p>For any reason, we may suspend, terminate or withdraw your access to the Platform and Services
                    without notice, reason or liability to you. </p>
            </section>
            <section>
                <p><strong>Artist Content</strong></p>
                <p>You accept that it is your sole responsibility, and not ours, to ensure that your Artist Content is
                    accurate, complete and up-to-date and that you are responsible for all aspects of your Artist
                    Content. </p>
                <p>You agree not to provide Artist Content that is:</p>
                <p>(a) false, inaccurate or misleading;</p>
                <p>(b) in breach of any third-party rights (including without limitation their Intellectual Property
                    Rights, rights to privacy, or rights to confidentiality);</p>
                <p>(c) abusive or otherwise intended to bully, harass, insult, intimate or humiliate; or</p>
                <p>(d) defamatory, libellous, hateful, violent, obscene, pornographic, unlawful, or otherwise offensive,
                    as determined by Vieunite in its sole discretion.</p>
                <p>You represent and warrant that you have all rights, permissions and consents to submit any Artist
                    Content to us.</p>
                <p>You agree that your Artwork and Artist Content will be available to all Users.</p>
                <p>We have the right to disclose your identity to any third party who is claiming that any Artist
                    Content posted or uploaded by you to our Services constitutes a violation of their rights,
                    including, without limitation, their Intellectual Property Rights, right to privacy or
                    confidentiality or is otherwise in breach of the terms of this Agreement.</p>
                <p>Vieunite may, but shall not be obligated to, review, monitor, or remove your Artist Content at any
                    time and for any reason without notice.</p>
                <p>Artists are responsible for determining the sale price of Artist Content made available through the
                    Vieunite application. Vieunite retains a small proportion of the sale price to cover Fees and VAT.
                    The take-home price is clearly displayed in the Vieunite Artist Portal.</p>
                <p>Artist Content and Artwork uploaded to the Platform may also be made available through the Vieunite
                    Pro web portal. Irrespective of the sale price for individual licenses in the Vieunite app, revenue
                    generated through Vieunite Pro is disbursed based on the display timeshare for each item of Artwork.
                    Vieunite returns 20% of the revenue generated directly to the Artist’s account. 10% is retained for
                    the Vieunite Creative Commitment fund.</p>
                <p>Every Artist with a minimum of five works available for inclusion in the Vieunite Pro web portal
                    receives an equal share of the Vieunite Creative Commitment fund. In this way, the average amount
                    returned to an Artist is 30% of the revenue raised through this service. However, the exact figure
                    will depend on the performance of an artist’s Artist Content in any given period.</p>
            </section>
            <section>
                <p><strong>Vieunite Pro</strong></p>
                <p>Vieunite Pro is Vieunite’s business Platform. This operates on a subscription basis, and Vieunite
                    provides curated image playlists to Users. </p>
                <p>Although you may remove your Artwork at any time from the Vieunite library, thereby making it
                    immediately inaccessible on the Vieunite app, you accept that there may be a delay of up to three
                    calendar months before the same image is removed from the Vieunite Pro Platform. </p>
                <p>You may opt out of Vieunite Pro altogether, in which case you accept that you will receive no revenue
                    via the Vieunite Creative Commitment fund. </p>
                <p>Unless you have chosen to opt-out, you accept that Vieunite determines at our sole discretion whether
                    Artist Content shall be made available through Vieunite Pro.</p>
            </section>
            <section>
                <p><strong>Intellectual Property</strong></p>
                <p> You warrant that you own and freely control the Intellectual Property inherent in your Artwork and
                    Artist Content.</p>
                <p> Vieunite makes no intellectual property claim to any Artist Content in the Library. You retain full
                    Copyright over your work. </p>
                <p>You accept that Artwork and Artist Content will be shared on the Platform. </p>
                <p>You permit that Vieunite may share without notice your Artist Content and your Artwork on our Social
                    Media accounts, and on the Social Media accounts associated with Vieunite, (i.e. the professional
                    Social Media accounts of senior employees and representatives) for marketing purposes. </p>
                <p>You permit Vieunite to share your Artist Content and Artwork without notice on our Website and in any
                    print or web media for marketing purposes. </p>
            </section>
            <section>
                <p><strong>Acceptable Use Restrictions</strong></p>
                <p> As a condition of being granted access to the Platform and the Services you agree:</p>
                <p> (a) not to (or attempt to) circumvent, disable or otherwise interfere with any security-related
                    features of the Service or features that enforce limitations on use of the Service or the
                    Content; </p>
                <p>(b) not to launch any automated system (including, without limitation, any robot, spider or offline
                    reader) that accesses the Services in a manner that sends more request messages to the Vieunite
                    servers in a given period of time than a human can reasonably produce in the same period by using a
                    publicly available, standard web browser; </p>
                <p>(c) not to submit or transmit any material, including Artist Content, that is unlawful, in breach of
                    third-party rights, inaccurate, false, defamatory, offensive or otherwise objectionable in relation
                    to your use of the Platform or any Service; </p>
                <p>(d) not to use the Platform or Service for any unlawful purpose, or in any manner inconsistent with
                    this Agreement, or act fraudulently or maliciously, for example, by hacking into or inserting
                    malicious code, including viruses, or harmful data, into the Platform, any Service or any operating
                    system;</p>
                <p>(e) not to use the Platform or Service to offer, solicit, arrange, or engage in, any kind of activity
                    or arrangement which is, or which would be unlawful;</p>
                <p>(f) not to infringe our rights or those of any third party in relation to your use of the Platform or
                    any Service (to the extent that such use is not expressly licensed by this Agreement);</p>
                <p>(g) not to use the Platform or Service in a way that could damage, disable, overburden, impair or
                    compromise our systems or security or interfere with other Artists and Users; and</p>
                <p>(h) not to collect, extract or harvest any information or data from the Platform, any Service or our
                    systems or attempt to decipher any transmissions to or from the servers running any Service.</p>
                <p>Together, such conditions shall be the “Acceptable Use Restrictions”.</p>
            </section>
            <section>
                <p><strong>Termination of Agreement</strong></p>
                <p> You may terminate this Agreement at any time, by:</p>
                <p> (a) notifying Vieunite; and</p>
                <p> (b) closing your Account.</p>
                <p> You understand and accept that it may take up to three calendar months to remove your work from the
                    Vieunite Pro Platform.
                </p>
                <p>Without prejudice to our rights hereunder, we may terminate this Agreement immediately without notice
                    to you:</p>
                <p>(a) if you commit a breach of this Agreement which you fail to remedy (if remediable) within three
                    days after being notified to do so;</p>
                <p> (b) if you breach any of the License Restrictions or the Acceptable Use Restrictions;</p>
                <p> (c) if we have any reason to suspect that your use of the Platform and/or Services is unlawful, or
                    that it would bring us and/or other Artists and/or Users or the Platform into disrepute;</p>
                <p> (d) if we believe that we are required to terminate your use of the Platform and/or Services by law
                    or any instruction of a regulator or other body with competent authority; or</p>
                <p> (e) if we withdraw the Platform from service, or otherwise reorganise or restructure our business so
                    as to necessitate the termination or suspension of provision of the Platform to you.</p>
                <p> Without prejudice to our rights hereunder, we may terminate this Agreement, or generally cease
                    offering or deny access to the Platform and Services or any portion thereof, at any time for any or
                    no reason whatsoever, immediately by notifying you in writing.</p>
            </section>
            <section>
                <p><strong>Information Security</strong></p>
                <p>Vieunite Limited is committed to ensuring the security of your personal information and data on the
                    Vieunite Pro Portal. We implement a variety of security measures to maintain the safety of your
                    personal data. These measures include:</p>
                <p> Encryption Standards: All data transmitted to and from our servers is encrypted using
                    industry-standard encryption protocols to ensure its confidentiality and integrity.</p>
                <p> Access Controls: We implement strict access control mechanisms to ensure that only authorized
                    personnel have access to sensitive information. Access is granted based on roles and
                    responsibilities, and is regularly reviewed.</p>
                <p> Regular Security Audits: Vieunite Pro undergoes regular security audits to identify and mitigate
                    potential vulnerabilities in our systems. These audits are conducted by third-party security experts
                    to ensure unbiased assessments.</p>
                <p> Compliance with Information Security Standards: Our servers adhere to the international standard
                    ISO/IEC 27001:2022, 27017:2015, 27018:2019, 27701:2019, 22301:2019, 20000-1:2018, 9001:2015, and CSA
                    STAR CCM v4.0 for information security management systems, ensuring comprehensive and effective
                    security practices.</p>
            </section>
            <section>
                <p><strong>DATA PROTECTION</strong></p>
                <p> We are dedicated to protecting your personal data in accordance with the EU General Data Protection
                    Regulation (GDPR) as well as the UK Data Protection Act 2018. Our data protection policies
                    include:</p>
                <p> Lawful Basis for Processing: We ensure that personal data is processed lawfully, fairly, and in a
                    transparent manner. Data is collected and used for specified, explicit, and legitimate purposes.</p>
                <p> Data Subject Rights: You have the right to access, rectify, erase, restrict, and object to the
                    processing of your personal data. To exercise these rights, you can contact our Data Protection
                    Officer at support@vieunite.com. Requests will be handled promptly and in accordance with applicable
                    regulations.</p>
                <p> Data Minimization: We collect only the data that is necessary for the purposes for which it is
                    processed. Personal data is kept accurate and up to date. </p>
                <p>Data Retention: Personal data is retained only for as long as necessary to fulfill the purposes for
                    which it was collected and processed, and as required by applicable laws.</p>
                <p> Data Security: We employ technical and organizational measures to ensure the security of personal
                    data, including encryption, access controls, and regular security testing.</p>
                <p> User Consent Procedures: We obtain and document user consent where required for data processing
                    activities, ensuring compliance with UK DPA 2018 and GDPR requirements.</p>
                <p> Third-Party Processors: Any third-party service providers are Financial Conduct Authority
                    (FCA)-compliant and process personal data on our behalf are subject to stringent data protection
                    obligations to ensure the security and confidentiality of your data.</p>
                <p> International Transfers: If personal data is transferred outside the European Economic Area (EEA),
                    we ensure that adequate safeguards are in place, such as standard contractual clauses or binding
                    corporate rules.</p>
                <p> Data Breach Notification: In the event of a data breach that poses a risk to your rights and
                    freedoms, we will notify you and the relevant supervisory authority without undue delay.
                </p>
                <p> For any inquiries regarding our data protection policies, please contact our Data Protection Officer
                    at <a
                        href="mailto:support@vieunite.com"
                    >support@vieunite.com</a>.</p>
            </section>
            <section>
                <p>Other Important Terms</p>
                <p> You can go to court. These terms are governed by English law and wherever you live you can bring
                    claims against us in the English courts. If you live in Wales, Scotland or
                    Northern Ireland, you can also bring claims against us in the courts of the country you live in.
                </p>
                <p> Contract transfer. We can transfer our Contract with you, so that a different organisation is
                    responsible for supplying your product. We'll tell you in writing if this happens. You can only
                    transfer your Contract with us to someone else if we agree to this. </p>
                <p>Nobody else has any rights under each Contract. Each Contract is between you and us. Nobody else can
                    enforce it and neither of us will need to ask anybody else to sign-off on ending or changing
                    it. </p>
                <p> Even if we delay, we might not immediately chase you for not doing something (like paying) or for
                    doing something you're not allowed to, but that doesn’t mean we cannot do it later.</p>
                <p> Intellectual Property Rights: Vieunite owns all legal right, title and interest in and to the
                    Vieunite Platforms. Nothing in these Terms and Conditions of Use shall be construed to fully or
                    partially assign any such rights, titles and interests to the users, save for what is required for
                    the purposes of publishing the curated artworks and playlists. These Terms and Conditions of Use
                    only give you a corporate worldwide, non-assignable and non-exclusive license to use the Vieunite
                    Pro Portal.</p>
                <p> Without prejudice to your right under statutory law within your jurisdiction, UK or otherwise, You
                    may not copy, modify, reproduce, create a derivative work of, reverse engineer, decompile, or
                    otherwise attempt to extract the source code of the software running on the Vieunite Pro Portal.
                    Manipulating or changing the Portal or its content therein in any way, or providing links to or from
                    the Portal without Vieunite’s explicit consent is prohibited.</p>
                <p>Nothing in these Terms and Conditions gives you the right to use Vieunite’s trade names, trademarks,
                    service marks, logos, domain names etc.</p>
                <p> Limitation of Liability: The Vieunite Pro Portal platform does not store content which are not
                    legally obtained. Third party content owners (i.e., onboarded Vieunite Artists) are solely
                    responsible for the quality and continuity of the artworks put on the Vieunite Pro Artwork
                    Marketplace. Vieunite is in no way liable for any content provided by third party content owners.
                    Your access to content provided by these third party content owners is done at your own risk, with
                    the understanding that you may be exposed to content which you may find offensive, or objectionable.
                    The artworks provided on the Vieunite Pro Artwork Marketplace are provided “As Is” and “As
                    Available”. Accordingly, Vieunite disclaims all liability as regards the accuracy, completeness and
                    reliability of any third-party content.</p>
                <p> TO THE EXTENT ALLOWED BY APPLICABLE LAW, VIEUNITE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
                    INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU AS A RESULT OF
                    THE USE OF THE VIEUNITE PRO PORTAL, INCLUDING BUT NOT LIMITED TO LOSS OF PROFIT, LOSS OF BUSINESS
                    GOODWILL OR REPUTATION, COST OF PROCUREMENT OF SUBSTITUTE GOOD OR SERVICES ETC. FURTHERMORE,
                    VIEUNITE DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING
                    BUT NOT LIMITED TO IMPLIED WARRANTIES AND CONDITIONS OF MERCHANGTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE ETC, SAVE FOR WHATEVER IS PROVIDED FOR UNDER STATUTORY LAW IN THE JURISDICTION OF THE
                    USER.</p>
                <p> Any disputes arising out of these Terms and Conditions will be governed by and interpreted in
                    accordance with the laws of England and Wales. If You choose to bring proceedings in connection with
                    these Terms and Conditions, You must do so in the courts of England Wales, unless You live in
                    Scotland or Northern Ireland, in which case You can choose to bring proceedings there. </p>
            </section>
        </div>
    );
}

export default ArtistsTermsAndConditions;
