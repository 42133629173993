import React, {useEffect, useState} from 'react';
import {Layout, Row, Col, Modal, Button, Image} from "antd";
import {Content} from "antd/es/layout/layout";
import PhotoAlbum from "react-photo-album";

import {APP_API_URL, Primary_Font, TitleFont} from "../../../settings";
import './ArtistPortalHome.css';
import RequirementsModal from "./components/RequirementsModal";
import {PortalHomeTxt} from "./text";
import SupportCard from "./components/SupportCard";
import {useNavigate, useParams,useLocation} from "react-router-dom";
import artList from "../ArtList";
import ProfileCard from "./components/ProfileCard";
import BalanceCard from "./components/BalanceCard";
import {ArtUploader} from "./components/ArtUploader";
import useWindowResize from "../../Hooks/useWindowResize";
import {getAllHomeArts} from "./services";

const logout = () => {
    localStorage.clear();
    window.location.href = '/artistportal/login';
}

function ArtistPortalHome(props) {
    // After user enter this page, check if there's token from local storage, if not, execute effect to set the token,id and isArtist gotten from parameters into local storage
    // const {token, userInfo} = useParams();
    const {windowWidth} = useWindowResize()
    const navigate = useNavigate()
    const location = useLocation();
    const [userData, setUserData] = useState();
    const [arts, setArts] = useState();
    const queryParams = new URLSearchParams(location.search);
    const userInfo = JSON.parse(queryParams.get("userInfo"));
    useEffect(() => {
        if (userInfo) {
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
        }
        getAllHomeArts(setUserData).then((arts) => {
            setArts(arts)
        })
        const storageInfo = JSON.parse(localStorage.getItem('userInfo'))
        const isArtist = storageInfo.isArtist
        if (!isArtist) {
            window.location.href = '/artistportal/apply';
        }
    }, []);
    // useEffect(() => {
    //     console.log(token, userInfo);
    //     const storageToken = localStorage.getItem("token");
    //     if (token) {
    //         localStorage.setItem("token", token);
    //         localStorage.setItem("userInfo", JSON.stringify(userInfo));
    //         getAllHomeArts(setUserData).then((arts) => {
    //             setArts(arts)
    //         })
    //     } else {
    //         if (storageToken) {
    //             getAllHomeArts(setUserData).then((arts) => {
    //                 setArts(arts)
    //             })
    //         }
    //     }
    //     const storageInfo = JSON.parse(localStorage.getItem('userInfo'))
    //     const isArtist = storageInfo.isArtist
    //     if (!isArtist) {
    //         window.location.href = '/artistportal/apply';
    //     }
    // }, []);


    // useEffect(() => {
    //     getAllHomeArts(setUserData).then((arts)=>{
    //         setArts(arts)
    //     })
    // }, []);
    // const storageInfo = JSON.parse(localStorage.getItem('userInfo'))
    // const isArtist = storageInfo.isArtist
    // if (!isArtist) {
    //     window.location.href = '/artistportal/apply';
    // }
    const [isRequirementsModalOpen, setIsRequirementsModalOpen] = useState(false);
    const showRequirmentsModal = () => {
        setIsRequirementsModalOpen(true);
    };

    const closeRequirmentsModal = () => {
        setIsRequirementsModalOpen(false);
    };
    const renderPhoto = ({layout, layoutOptions, imageProps: {alt, style, ...restImageProps}}) => (
        <div>
            <Image alt={alt} style={{...style, width: "100%", padding: 0}} {...restImageProps} />
        </div>
    );

    if (!arts) return <div></div>
    return (
        <Layout>
            <Content className={'grey-bk'}>
                <Row justify={"center"} gutter={[18, 18]}
                     style={{
                         margin: '4rem 0 0 0',
                     }}>
                    <Col lg={5} xs={22}>
                        <ProfileCard data={userData} logout={logout}></ProfileCard>
                    </Col>
                    <Col lg={5} xs={22}>
                        <BalanceCard/>
                    </Col>
                    <Col lg={5} xs={22}>
                        <div className="small-card" style={{
                            overflow: 'hidden',
                            fontSize: windowWidth > 600 ? windowWidth / 100 * 0.65 : windowWidth / 100 * 3.5
                        }}>
                            <p className={'card-title'}
                               style={{fontSize: windowWidth > 600 ? windowWidth / 100 * 1.6 : windowWidth / 100 * 8.}}>
                                Upload <span style={{fontWeight: '600'}}>Artwork</span>.
                            </p>
                            <p style={{color: "black", marginTop: '-10%', cursor: "pointer"}}
                               onClick={showRequirmentsModal}>
                                Artwork Requirements
                            </p>
                            <ArtUploader isCardDisplay={windowWidth > 600}/>
                        </div>
                    </Col>
                    <Col lg={5} xs={22}>
                        <SupportCard/>
                    </Col>
                </Row>
                <Row className={'m-img-text'} justify={"center"} style={{margin: '2rem 0 2rem 0',}}>
                    <Col className={'l-align-dk'} span={10} lg={{span: 20}} xs={24}
                         style={{background: "white", borderRadius: windowWidth > 600 ? '20px' : '0'}}>
                        <div className="img-small-card">
                            <h2 className="title-app mobile-left"
                                style={{textAlign: "left", ...TitleFont, ...(windowWidth > 600 ? {} : {fontSize: '2rem'})}}>
                                <span style={{fontWeight: '500'}}>Manage</span> Artwork.&nbsp;&nbsp;
                                <span style={{
                                    color: "black",
                                    ...Primary_Font,
                                    fontSize: '1rem',
                                    fontWeight: '400',
                                    cursor: 'pointer',
                                    display: windowWidth < 600 ? "block" : "inline"
                                }}
                                      onClick={() =>
                                          navigate("/artistportal/artlist", {
                                              state: {
                                                  isApplication: false,
                                                  applyInfo: ''
                                              }
                                          })
                                      }
                                >
                                    Edit Details >
                                </span>
                            </h2>

                            <PhotoAlbum
                                layout="masonry"
                                columns={(containerWidth) => {
                                    return containerWidth > 600 ? 3 : 2
                                }}
                                photos={arts}
                                renderPhoto={renderPhoto}
                            />
                        </div>
                    </Col>
                </Row>
                <Row justify={"center"} style={{background: "white", padding: '2rem'}}>
                    <Button htmlType="submit"
                            className="login-form-button"
                            onClick={logout}
                            style={{width: '30%', textAlign: "center"}}
                    >
                        LOG OUT
                    </Button>
                </Row>
                <RequirementsModal
                    isRequirementsModalOpen={isRequirementsModalOpen}
                    closeRequirmentsModal={closeRequirmentsModal}
                />
            </Content>
        </Layout>

    )
}

export default ArtistPortalHome;
