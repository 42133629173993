import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Button} from "antd";
const Survey = () => {
    const navigate = useNavigate();
    const handleNext = () => {
        // Navigate to Page2 (replace '/page2' with your actual route)
        navigate('/page2');
    };
    return (
        <div style={{marginTop: '20px'}}>
            <h1 style={{marginTop: '80px', position: 'static', marginLeft: '700px'}}>PARTICIPANT INFORMATION</h1>
            <div style={{marginTop: '30px', marginLeft: '400px'}}>
                <b>Project Title: DEVELOPING A PROTOCOL RECOMMENDATION SYSTEM FOR BIOPHILIC ARTWORKS</b>
            </div>
            <div style={{marginTop: '20px',marginLeft:'10px'}}>
                Thank you for agreeing to consider participating in this research project.
                <br/>
                Before deciding to answer the survey questionnaire, it is important that you understand the reason
                why the research is being conducted, and what your participation will involve.
                <br/>
                Request you to kindly read the following information carefully and discuss it with your colleagues
                or other people if you wish to. Please do not hesitate to contact us if any of the information is
                unclear or you wish to discuss your participation in this project.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>What is the purpose of the study?</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                The aim of this project is to provide a detailed investigation into the emotional impacts of
                exposure to Biophilia. In collaboration with the Vieunite App (Digital artwork display app), we aim
                to build a personalized recommender system that will recommend Biophilic artworks based
                on the user’s emotional state, environmental conditions, time of day, personal circadian cycles,
                etc. The ultimate goal is to make Biophilic artwork more accessible to the public and improve mental
                well-being.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>Who is running the study?</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                The study is being headed and conducted by Dr. Yangang Xing in collaboration with Dr. Alexander
                Sumich.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>Why is my input relevant?</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                This is a voluntary participation study involving a prototype of a recommender system that
                recommends Biophilic artwork based on the individual Biophilic feature preferences. Your responses could help develop a strong
                recommender system that uses biophilic features that will benefit people with
                different personality traits so as to achieve balanced stress levels in their immediate
                surroundings.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>Do I have to take part?</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                No, your participation in this research is entirely voluntary. You can withdraw from the study at
                any time during the survey by exiting the questionnaire or even after submitting your response. If
                you wish, your data can still be removed from the final analysis up to December 30th 2024.
                If you do decide to take part, we request you to read and fully understand the information on this
                sheet and provide your consent at the end of this form. If you decide not to take part in the
                research, you will not be asked to give any explanation.
                Also, please see the contact details below if you decide to take part in the survey and after
                submitting your response, you change your mind and decide to withdraw your data.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>What do I need to do?</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                You would be requested to answer some questions at your own convenience. This questionnaire would
                take approximately 10 minutes of your time. At a point during the task, we will ask you to tell us
                what type of Biophilic feature you are interested in and based on your answers, we will show you some
                Biophilic pictures and then ask you whether the recommendation was up to your liking. We request you to
                kindly use a computer or laptop for this study since the pictures are not mobile-friendly.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>What will happen to the information I provide?</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                Your responses will be recorded and saved on a password protected secured server and can be accessed
                by the researchers only for purposes of studying them. Your responses will be employed to support
                the study and produce results that may be published as a research paper
                in due time. The data will then be deleted from the server in a secure manner. You will not be named
                or otherwise identified in any publication arising from this research.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>How will the research team deal with confidentiality and protect my anonymity?</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                All data will be stored electronically on a private password-protected server at all times. Only the
                research team will have access to your responses. Hard copies of data, if any, will be kept in a
                locked drawer and electronic copies will be stored on a private computer in
                encrypted/password protected files in line with the British Psychological Society’s code of ethics.
                Your responses will be employed to support the study and produce results that may be published as a
                research paper in due time. The data will then be deleted from the cloud in a
                secure manner.<br/>
                You will not be named or otherwise identified in any publication arising from this research.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>What are the possible risks/disadvantages of taking part?</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                The main cost to you will be the time taken with the experiment. The risks to you may include
                providing information that you may not feel comfortable with. However, as outlined above, any
                information you do provide will be kept anonymous and secure. In addition, you can choose
                not to answer any of the questions or withdraw your data at any time until December 30th 2024.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>What are the possible benefits/advantages?</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                We hope that you will find the survey and its purpose interesting, and will take satisfaction from
                helping to develop a greater understanding of how being present in real or synthetic nature affects
                a person’s mood based on their personality.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>What will happen to the results of the research?</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                The results of the research will be published in a peer-reviewed journal. These results of the study
                can be made available to you as a report, upon request, when the research is completed. Data may
                contribute to dissertations and thesis of students in part completion of
                undergraduate, postgraduate or doctoral training.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>How can I find out more about this project and its results?</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                For more information about the project, please do not hesitate to contact the research team who will
                be happy to provide with you supplemental reading material to understand this better. An electronic
                copy of the research paper can also be availed, upon request, once the
                research is completed and published.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>Has the study been reviewed by anyone?</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                The research has been subject to ethical approval by the University’s School of Social Sciences
                Research Ethics Committee. It has been designed with reference to the British Psychological
                Society’s code of ethics.
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>Contacts and further information</b>
            </div>
            <div style={{marginLeft:'10px'}}>
                <b>Contact Details:</b><br />
                <a href="mailto:yangang.xing@ntu.ac.uk">Dr. Yangang Xing [yangang.xing@ntu.ac.uk]</a><br />
                <a href="mailto:Alexander.sumich@ntu.ac.uk">Dr. Alexander Sumich [Alexander.sumich@ntu.ac.uk]</a><br />
                <a href="mailto:purna.kar@ntu.ac.uk">Ms Purna Kar [purna.kar@ntu.ac.uk]</a>
            </div>
            <div style={{ marginLeft: '900px' }}>
                <Button type="primary" onClick={handleNext}>Next</Button>
            </div>
        </div>
    );
};

export default Survey;

