import React, {useEffect, useState} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Button, Card, Col, Dropdown, Layout, Menu, message, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import ProPortalHeader from "../../componets/ProPortalHeader";
import ProfileRow from "../../componets/ProfileRow";
import Navigator from "../../componets/Navigator";
import {useLocation, useNavigate} from "react-router-dom";
import {get_album_data} from "../services";
import {DownOutlined} from "@ant-design/icons";
import AlbumCollections from "../../ProDiscovery/DiscoveryAlbumSlickView";
import {AlbumSlider} from "./AlbumSlider";
import {TwitterPicker, CompactPicker, CirclePicker} from "react-color";
import {get_api, post_api} from "../../serverTemp";
import CoverLayout from "../../ProDiscovery/AlbumCoverLayout";
import {ReactComponent as DelIcon} from '../imgs/Delete.svg'
import {ReactComponent as PubIcon} from '../imgs/Publish.svg'
import {ReactComponent as RenameIcon} from '../imgs/Rename.svg'
import {DropIcon} from "./SVG";
import {useImmerReducer} from "use-immer";
import {initialState, reducer} from "./reducer";
import multiColorImg from './imgs/multi-color.png'
import backButton from "../../DashBoard/components/Hardware/img/Back button.svg";
import {ReactComponent as BackIcon} from "../../MyLibrary/ImgDisplay/Back Button and Text.svg";


const Albums = (props) => {
    const [plsState, dispatch] = useImmerReducer(reducer, initialState)
    const [albums, setAlbums] = useState([])
    const navigate = useNavigate()
    const {state} = useLocation()
    const textura_ids = state?.textura_ids || []
    const [isColorVisible, setIsColorVisible] = useState(false)
    const [activeTab, setActiveTab] = useState('curated');
    const [categories, setCategories] = useState([])
    const [filterCategories, setFilterCategories] = useState([])
    const [rowPlaylists, setRowPlayLists] = useState([])
    const [curOri, setCurOri] = useState(null)//0:portrait 1:landscape
    const nullColor = ['Multicolour', 'Red', 'Yellow', 'Orange', 'Purple', 'Gold', 'Pink']
    const DropDownMenu = (items, idx) => (<div className={'ds-dropdown-al-hw'}>
        <Menu onClick={(e) => {
            switch (e.key) {
                case '1':
                    break;
                default:
                    break;
            }
        }}>
            {items?.map((item) =>
                <Menu.Item key={item?.key}
                           onClick={() => {
                               const updatedFilters = filterCategories.map((cat, i) => {
                                   if (i === idx) {
                                       // Return a new object to replace the old one at this index
                                       return {...item};
                                   }
                                   return {...cat};
                               });
                               setFilterCategories(updatedFilters);
                           }}>
                    {item?.text}
                </Menu.Item>)}
        </Menu>
    </div>)
    useEffect(() => {
        get_api('/artwork/recommendation/categories')
            .then(async (res) => {
                const originalCategories = res.data
                setCategories(originalCategories)
                setFilterCategories(originalCategories)
                console.log(originalCategories)
                originalCategories.forEach((ct) => {
                    if (ct.text === 'Colour') {
                        dispatch({
                            type: 'add_color',
                            colors: ct?.children?.filter((color) => !nullColor.includes(color?.text?.split('#')[0]))
                                .map((color) => {
                                        return {
                                            label: color?.text?.split('#')[0],
                                            hex: '#' + color?.text?.split('#')[1],
                                            ...color
                                        }
                                    }
                                )
                        })
                    }
                })
            })
    }, []);

    useEffect(() => {
        console.log('filters', filterCategories, categories)
        Promise.all(
            filterCategories.map((category, idx) =>
                get_api(curOri === null ? `/playlist/in/category?level=${category.level}&category_id=${category.id}&page=0` :
                    `/playlist/in/category?level=${category.level}&is_landscape=${curOri}&category_id=${category.id}&page=0`)))
            .then(results => {
                let tempRows = []
                results.map((res, idx) => {
                    const abData = res.data
                    console.log(abData)
                    if (!abData || abData.length === 0) {
                        tempRows.push([])
                    } else {
                        tempRows.push(abData.map(album => {
                            return {
                                id: album.id,
                                name: album.name,
                                urls: album.url,
                                item: {
                                    left: album?.url?.split(',')[0] ?? null,
                                    upRight: album?.url?.split(',')[1] ?? null,
                                    downRight: album?.url?.split(',')[2] ?? null,
                                },
                                num: album.numberOfArtworks,
                                level: album.level
                            }
                        }))
                    }
                })
                setRowPlayLists(tempRows)
            })
    }, [filterCategories, curOri])
    // useEffect(() => {
    //     get_api(activeTab === 'curated' ? '/playlist/all' : '/album/all').then(
    //         res => {
    //             const abData = res.data
    //             if (!abData || abData.length === 0) {
    //                 message.error('No album found')
    //             } else {
    //                 setAlbums(abData.map(album => {
    //                     return {
    //                         id: album.id,
    //                         name: album.name,
    //                         urls: album.url,
    //                         item: {
    //                             left: album?.url?.split(',')[0] ?? null,
    //                             upRight: album?.url?.split(',')[1] ?? null,
    //                             downRight: album?.url?.split(',')[2] ?? null,
    //                         },
    //                         num: album.numberOfArtworks
    //                     }
    //                 }))
    //             }
    //         }
    //     )
    // }, [activeTab])
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <Row style={{marginTop: '5rem'}}>
                    <Layout>
                        <Navigator/>
                        {activeTab === 'curated' && <Layout>
                            <div className={'standard-special-header-div'}
                                 style={{
                                     display: localStorage.getItem('subscription') !== 'Standard' ?
                                         "none" : "flex"
                                 }}
                            >
                                <span
                                    onClick={_ => window.history.back()}
                                    style={{cursor: "pointer"}}
                                >
                                                <BackIcon style={{margin: '0vh 0 0 -6vw'}}/>
                                            </span>
                                <div className={'standard-special-header-div-2'}>
                                    <p className={'standard-special-header-div-p1'}>My Library/Vieunite Curated
                                        Collection</p>
                                    <p className={'standard-special-header-div-p2'}>Upgrade you subscription for free
                                        access on all playlists!</p>
                                </div>
                                <Button className={'standard-special-header-div-btn'}
                                        onClick={_ => window.location.href = '/proportal/MyAccount'}
                                >
                                    Upgrade
                                </Button>
                            </div>
                            <Row style={{
                                display: localStorage.getItem('subscription') === 'Standard' ? "none" : "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                margin: '0 0 2rem 0'
                            }}>
                                <span onClick={_ => window.history.back()}
                                      style={{cursor: "pointer"}}
                                >
                                                <BackIcon style={{margin: '6.4vh 0 0 1.6vw'}}/>
                                            </span>
                                <span className={'mb-dsp-title'} style={{marginLeft: '1.2vw', fontWeight: '500',
                                    opacity: 1}}>
                                    Vieunite Curated Collection
                                </span>
                            </Row>
                            <Row style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                margin: '4vh 0 -2vh 7.8vw'
                            }}>
                                <Button className={'album-filter-btn'}
                                        onClick={_ => setCurOri(0)}
                                        style={{
                                            marginRight: '1vw'
                                        }}>Portrait</Button>
                                <Button className={'album-filter-btn'}
                                        onClick={_ => setCurOri(1)}
                                        style={{
                                            marginRight: '1vw'
                                        }}
                                >Landscape</Button>
                                <Button className={'album-filter-btn'}
                                        onClick={_ => setCurOri(null)}
                                >Show all</Button>
                            </Row>
                            {categories.map((category, idx) =>
                                <div className="al-collections-container"
                                     style={{margin: "80px 35px 0 35px"}}
                                >
                                    <p className='album-collection-title'
                                       style={{margin: '0 0 0 6vw'}}
                                    >
                                        {category.text === 'Colour' ?
                                            <Dropdown className={'ds-dropdown'}
                                                      overlay={<div className="custom-color-picker">
                                                          {plsState.colors.map((color, index) => (
                                                              <div key={color.hex}
                                                                   className={`color-swatch-container ${index >= 8 ? 'second-row' : ''}`}>
                                                                  {color.label === 'Multicolour' ?
                                                                      <img className="color-swatch"
                                                                           style={{marginLeft: '0.1vw'}}
                                                                           src={multiColorImg}
                                                                           alt={'multiple-colors'}
                                                                           onClick={() => {
                                                                               console.log(filterCategories)
                                                                               const updatedFilters = filterCategories.map((cat, i) => {
                                                                                   console.log(cat.text, color)
                                                                                   if (cat.text === 'Colour' || cat.text.includes('#')) {
                                                                                       return {...color};
                                                                                   }
                                                                                   return {...cat};
                                                                               });
                                                                               setFilterCategories(updatedFilters);
                                                                           }}
                                                                      /> :
                                                                      <div
                                                                          className="color-swatch"
                                                                          style={{backgroundColor: color.hex}}
                                                                          onClick={() => {
                                                                              console.log(filterCategories)
                                                                              const updatedFilters = filterCategories.map((cat, i) => {
                                                                                  if (cat.text === 'Colour' || cat.text.includes('#')) {
                                                                                      return {...color};
                                                                                  }
                                                                                  return {...cat};
                                                                              });
                                                                              setFilterCategories(updatedFilters);
                                                                          }}
                                                                      />}
                                                                  <div className="color-label">{color.label}</div>
                                                              </div>
                                                          ))}
                                                      </div>}
                                                      placement={"bottom"}>
                                                <Button className={'album-ct-btn album-ct-btn-dropdown'}>
                                                    {category.text}&nbsp;&nbsp;<DropIcon/>
                                                </Button>
                                            </Dropdown> : category.text}
                                    </p>
                                    {category.children.length > 0 &&
                                        <Row justify={"start"}
                                             style={{
                                                 margin: '0 0 0 6vw',
                                                 gap: '20px'
                                             }}>
                                            {
                                                category.text !== 'Colour' &&
                                                category.children.map((subCategory) =>
                                                    subCategory.children.length > 0 ?
                                                        <Dropdown className={'ds-dropdown'}
                                                                  overlay={DropDownMenu(subCategory.children, idx)}
                                                                  placement={"bottom"}>
                                                            <Button className={'album-ct-btn album-ct-btn-dropdown'}
                                                                    onClick={() => {
                                                                        const updatedFilters = filterCategories.map((cat, i) => {
                                                                            if (i === idx) {
                                                                                // Return a new object to replace the old one at this index
                                                                                return {...subCategory};
                                                                            }
                                                                            return {...cat};
                                                                        });
                                                                        setFilterCategories(updatedFilters);
                                                                    }}>
                                                                {subCategory.text}&nbsp;&nbsp;<DropIcon/>
                                                            </Button>
                                                        </Dropdown> :
                                                        <Button
                                                            className={'album-ct-btn'}
                                                            onClick={() => {
                                                                const updatedFilters = filterCategories.map((cat, i) => {
                                                                    if (i === idx) {
                                                                        // Return a new object to replace the old one at this index
                                                                        return {...subCategory};
                                                                    }
                                                                    return {...cat};
                                                                });
                                                                setFilterCategories(updatedFilters);
                                                            }}
                                                        >
                                                            {subCategory.text}
                                                        </Button>
                                                )
                                            }
                                        </Row>}
                                    {rowPlaylists[idx] ?
                                        <AlbumSlider albums={rowPlaylists[idx]} textura_ids={textura_ids}
                                                     is_pls={true}/> :
                                        <></>}
                                </div>)
                            }
                        </Layout>
                        }
                        {
                            activeTab === 'albums' && <Layout>
                                <Row style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    margin: '0 0 2rem 0'
                                }}>
                                    <p className={'mb-dsp-title'} style={{margin: '4rem 0 -2rem 4.4rem'}}>
                                        My Albums
                                    </p>
                                </Row>
                                <Row justify={"flex-start"}
                                     style={{margin: '4rem 0 100rem 0'}}
                                >
                                    {albums.length > 0 ?
                                        albums.map((album, index) => {
                                            return (
                                                <div className={'mb-album-card'}>
                                                    {album?.urls?.split(',').length >= 3 ?
                                                        <CoverLayout
                                                            // onClick={() => {
                                                            //     navigate('/proportal/images', {
                                                            //         state: {
                                                            //             albumID: album.id,
                                                            //             title: album.name,
                                                            //             filter: 'album'
                                                            //         }
                                                            //     })
                                                            // }}
                                                            item={album?.item}
                                                            style={{
                                                                width: 200,
                                                                height: 200,
                                                                marginLeft: 10,
                                                                marginTop: 10,
                                                                marginRight: 10,
                                                                marginBottom: 0
                                                            }}/>
                                                        :
                                                        <img
                                                            // onClick={() => {
                                                            //     navigate('/proportal/images', {
                                                            //         state: {
                                                            //             albumID: album.id,
                                                            //             title: album.name,
                                                            //             filter: 'album'
                                                            //         }
                                                            //     })
                                                            // }}
                                                            src={album.urls.split(',')[0]} alt="Album cover"
                                                            style={{
                                                                width: "80%",
                                                                height: "50%",
                                                                objectFit: "cover",
                                                                cursor: 'pointer',
                                                            }}/>
                                                    }
                                                    <p className={'mb-album-name'}>
                                                        {album?.name}
                                                    </p>
                                                    <div className={'mb-album-ops'}>
                                                        <div style={{
                                                            cursor: 'pointer', display: 'flex',
                                                            alignItems: 'center',
                                                            flexDirection: 'column',
                                                            margin: 'auto'
                                                        }}
                                                             onClick={() => {
                                                                 if (textura_ids.length > 0) {
                                                                     message.success('Succeed!')
                                                                 } else {
                                                                     navigate('/proportal/dashboard', {state: {album_id: album.id}})
                                                                 }
                                                             }}
                                                        >
                                                            <PubIcon/>
                                                            <p className={'ml-p-small-icon'}>PUBLISH</p>
                                                        </div>
                                                        {/*<div style={{*/}
                                                        {/*    cursor: 'pointer', display: 'flex',*/}
                                                        {/*    alignItems: 'center',*/}
                                                        {/*    flexDirection: 'column',*/}
                                                        {/*}}*/}
                                                        {/*     onClick={() => {*/}
                                                        {/*         setIsNewAlbum(false)*/}
                                                        {/*         setCurAlbumID(album.id)*/}
                                                        {/*         setIsModalOpen(true)*/}
                                                        {/*     }}>*/}
                                                        {/*    <RenameIcon/>*/}
                                                        {/*    <p className={'ml-p-small-icon'}>RENAME</p>*/}
                                                        {/*</div>*/}
                                                        {/*<div style={{*/}
                                                        {/*    cursor: 'pointer',*/}
                                                        {/*    display: 'flex',*/}
                                                        {/*    alignItems: 'center',*/}
                                                        {/*    flexDirection: 'column',*/}
                                                        {/*}}*/}
                                                        {/*     onClick={() => {*/}
                                                        {/*         post_api('/album/delete', {*/}
                                                        {/*             id: album.id*/}
                                                        {/*         }).then(*/}
                                                        {/*             res => {*/}
                                                        {/*                 window.location.reload()*/}
                                                        {/*             }*/}
                                                        {/*         )*/}
                                                        {/*     }}>*/}
                                                        {/*    <DelIcon/>*/}
                                                        {/*    <p className={'ml-p-small-icon'}>DELETE</p>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            )
                                        }) : <></>
                                    }
                                </Row>
                            </Layout>}
                    </Layout>
                </Row>
            </Content>
        </Layout>
    )
}

export default Albums;
