import React from "react";
import {Helmet} from "react-helmet";

export const A1Meta = () => (
    <Helmet>
        <title>Jonathan Armour | Vieunite</title>
        <meta name="description"
              content="Johnathan Armours practice builds from a previous career in engineering and is an interplay between digital and oil-based works, including mappings of non-normative bodies, and digital investigations of the human body as a celebrated, objectified, abstracted form."/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@ VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Jonathan Armour"/>
        <meta name="twitter:description"
              value="Jonathan Armour's art merges digital and oil-based media to explore non-normative bodies and the human form in abstracted, objectified ways. Experience his cutting-edge works on Vieunite's digital art platform."/>
        <meta name="twitter:url" value="https://vieunite.com/artists/jonathan-armour"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_jonathan_armour.jpg"/>

        <meta property="og:url" content=" https://vieunite.com/artists/jonathan-armour"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Vieunite Artist | Jonathan Armour"/>
        <meta property="og:description"
              content="Jonathan Armour's art merges digital and oil-based media to explore non-normative bodies and the human form in abstracted, objectified ways. Experience his cutting-edge works on Vieunite's digital art platform."/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_jonathan_armour.jpg"/>
    </Helmet>);