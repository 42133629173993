import React from 'react';
import './ArtistBgVideo.css';


function ArtistBgVideo(props) {
    const makeMuted = (elt) => {
        if (elt) {
            elt.muted = true;
        }
    };
    return (
        <section className="afullScreenVideoSection">
            <video className="afullScreenVideo" playsInline loop autoPlay ref={makeMuted} muted rel={"preload"}>
                <source src={props.desktopUrl} type={'video/mp4'}></source>
            </video>
            <video className="afullScreenVideoMobile" playsInline loop autoPlay ref={makeMuted} muted rel={"preload"}>
                <source src={props.mobileUrl}></source>
            </video>
        </section>

    );
}

export default ArtistBgVideo;