import {useNavigate} from "react-router-dom";
import {Col, Row} from "antd";
import {Primary_Font} from "../../../../../settings";
import React from "react";

export const BackButton = (props) => {
    const {text, link} = props
    const navigate = useNavigate();
    return (
        <Row justify={"center"} style={{margin: '1rem 0 0 0'}}>
            <Col lg={20} xs={20}>
                <span style={{
                    color: "black",
                    ...Primary_Font,
                    fontSize: '1rem',
                    fontWeight: '400',
                    cursor: 'pointer',
                    textAlign: 'left'
                }}
                      onClick={() =>
                          navigate(link)
                      }
                >
                    {text}
                </span>
            </Col>
        </Row>
    )
}