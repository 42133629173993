import React, {useEffect, useState} from 'react';
import './style.scss';
import {Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import ProPortalHeader from "../../componets/ProPortalHeader";
import ProfileRow from "../../componets/ProfileRow";
import Navigator from "../../componets/Navigator";
import {Col, Card, Button} from 'antd';
import Title from "antd/es/skeleton/Title";
import {useNavigate} from "react-router-dom";
import {get_api} from "../../serverTemp";
import backButton from './imgs/Back button.svg';


const AccountDetailsCard = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({})
    const handleChangeDetailsClick = () => {
        navigate('/proportal/ChangeAccountDetails');
    };
    useEffect(() => {
        get_api('/user/detail').then(res => {
            setUser(res)
        })
    }, [])
    const subsMap = {
        0: "Standard",
        1: "Advanced",
        2: "Premium"
    }
    return (
        <Card className='DetailsCard'>
            <Card className='DetailsCard-sub'>Account Details</Card>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <p className='DetailsCard-content'>Name: {user?.nickname}</p>
                    <p className='DetailsCard-content'>Email: {user?.email}</p>
                    <p className='DetailsCard-content'>Subscription type: {subsMap[user?.subscription]}</p>
                </div>
                {/*<div className={'change-profile-settings'}>*/}
                {/*    <img className={'change-profile-settings-avatar'} src={user?.avatar} alt={'avatar'}*/}
                {/*         onClick={handleChangeDetailsClick}/>*/}
                {/*</div>*/}
                <div className={'account-pt-profile-div'}>
                    {user.avatar ?
                        <img className={'account-pt-profile-div-img'}
                             src={user?.avatar}
                             style={{
                                 cursor: 'pointer'
                             }}
                             alt={'avatar'}
                             onClick={() => {
                                 window.location.href = '/proportal/ChangeAccountDetails'
                             }}
                        /> :
                        <div className={'account-pt-profile-div-avatar-out'}>
                            <div className={'account-pt-profile-div-avatar'}
                                 onClick={() => {
                                     window.location.href = '/proportal/ChangeAccountDetails'
                                 }}
                            >
                                {user?.nickname ? user.nickname[0].toUpperCase() : ''}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Button className='DetailsCard-button' onClick={handleChangeDetailsClick}>Change your details</Button>
        </Card>
    );
};
const PasswordChangeCard = () => {
    const navigate = useNavigate();
    // const handleChangePasswordClick = () => {
    //     navigate('/proportal/ChangePasswordPro');
    // };
    const handleChangePasswordClick = () => {
        navigate('/business/ProForgotPassword');
    };
    return (
        <Card className='PasswordCard'>
            <Card className='DetailsCard-sub'>Password Change</Card>
            <p className='PasswordCard-content'> You can change your password as often as you like</p>
            <Button className='Password-button' onClick={handleChangePasswordClick}>Change your password</Button>
        </Card>
    );
};
const MainContent = () => (
    <Row gutter={16}>
        <Col span={12}>
            <AccountDetailsCard/>
        </Col>
        <Col span={12}>
            <PasswordChangeCard/>
        </Col>
    </Row>
);
const AccountSettings = () => {
    const [user, setUser] = useState({});

    useEffect(() => {
        get_api('/user/detail').then(res => {
            setUser(res);
        });
    }, []);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // 使用navigate函数导航回上一页
    };
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <div className='ProAccountDetails1'>
                                {/*<p className='ProAccountDetails-title'>Hello Vieunite Ltd</p>*/}
                                {/*<p className='ProAccountDetails-title'>Hello {user?.nickname}</p>*/}
                                <div className='header2'>
                                    <img src={backButton} alt="BackButton" className='BackButtonIcon3'
                                         style={{marginRight: '1.0rem', cursor: "pointer"}} onClick={handleBackClick}/>
                                    <p className='ProAccountDetails-title1'>Hello {user?.nickname}</p>
                                </div>
                                <MainContent/>
                            </div>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>);
}

export default AccountSettings;