import React from 'react';

export const ScrollInactive = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="4" viewBox="0 0 30 4" fill="none">
        <path d="M2 2H28" stroke="#D9D9D9" stroke-width="3" stroke-linecap="round"/>
    </svg>
);

export const ScrollActive = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="4" viewBox="0 0 30 4" fill="none">
        <path d="M2 2H28" stroke="#717171" strokeWidth="3" strokeLinecap="round"/>
    </svg>
);

export const ScrollLeftArrow = ({ onClick }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="35" viewBox="0 0 20 35" fill="none" onClick={onClick} style={{cursor: 'pointer'}}>
        <path d="M18.5 2L3 17.5L18.5 33" stroke="#717171" stroke-width="3" stroke-linecap="round"/>
    </svg>
);

export const ScrollRightArrow = ({ onClick }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="35" viewBox="0 0 20 35" fill="none" onClick={onClick} style={{cursor: 'pointer'}}>
        <path d="M2 33L17.5 17.5L2 2" stroke="#717171" stroke-width="3" stroke-linecap="round"/>
    </svg>
);