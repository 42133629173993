import React, {useState} from 'react';
import {Link, redirect, useNavigate} from 'react-router-dom';
import {Layout, Col, Row, Card, Space, Button, Image} from 'antd';
import '../../App.css';
import "../Home/HomePage.scss";
import "../Textura/Textura.css"
import "./Artists.css"
import {Content} from "antd/es/layout/layout";
import {ARTISTS_TEXT} from "../../text";
import {ArtistsPageMeta} from "../../components/PageMeta/ArtistsPageMeta";
import {ArtistDataBase} from "./ArtistDataBase";

const Primary_Font = {
    fontFamily: "Proxima Nova bold, sans-serif",
}

const TitleFont = {
    fontSize: '42px',
    fontFamily: "Proxima Nova bold, sans-serif",
}

const Img_Radius = {
    borderRadius: '0.625rem',
}

function Artists() {
    const navigate = useNavigate();
    const [curPage, setCurpage] = useState(0)
    const totalPages = ArtistDataBase.length
    return (
        <div>
            <ArtistsPageMeta/>
            <Layout>
                <Content>
                    <Row className={'m-img-text'} justify={"center"} style={{margin: '6rem 0 2rem 0'}}>
                        <Col className={'l-align-dk'} span={10} lg={{span: 20}} xs={20}
                             style={{marginLeft: '1.7%'}}>
                            <h2 className="title-app mobile-left"
                                style={{textAlign: "left", ...TitleFont}}>
                                <span style={{fontWeight: '500'}}>Vieunite</span> Artists.
                            </h2>
                            <span className="text-content-app mobile-left artist-text">
                                        {ARTISTS_TEXT["Vieunite Artists"]}
                            </span>
                            <span className="text-content-app mobile-left artist-text">
                                To find out more about becoming a Vieunite Artist&nbsp;
                                <a style={{color: "black", textDecoration: 'underline'}}
                                   href={'/vieunite-artist-faq-pre-application.pdf'}>
                                    click here
                                </a> or&nbsp;
                                <a href="mailto:info@vieunite.com"
                                   style={{color: 'black', textDecoration: "underline"}}
                                >
                                    drop us an email
                                </a>.
                            </span>
                        </Col>
                    </Row>
                    <Row className={'m-img-text-at'} justify={"center"}
                         style={{margin: '8rem 0 2rem 0', paddingBottom: '4rem'}}
                         gutter={[24, 24]}>
                        {ArtistDataBase[curPage].map(artist => (
                            <Col lg={{span: 5}} xs={21}
                                 style={{display: "flex", flexDirection: 'column', justifyContent: 'flex-start'}}>
                                <Image
                                    className={'artist-img protected-img'}
                                    src={artist.img}
                                    preview={false}
                                    style={{...Img_Radius}}
                                    onClick={() => {
                                        navigate(artist.path)
                                    }}
                                />
                                {/*react-snap can't recognise navigate(). The static pages can only be generated if there are a tags*/}
                                <a href={artist.path}></a>
                                <p style={{
                                    ...Primary_Font,
                                    fontWeight: '700',
                                    fontSize: '1rem',
                                    margin: '1rem 0',
                                    cursor: "pointer",
                                }}
                                   onClick={() => {
                                       navigate(artist.path)
                                   }}
                                >
                                    {artist.name}
                                </p>
                            </Col>
                        ))}
                        <Col lg={{span: 5}} xs={0}></Col>
                        <Col lg={{span: 5}} xs={0}></Col>
                        <Col lg={{span: 5}} xs={0}></Col>
                    </Row>
                    <Row justify={"center"} style={{
                        margin: '0 0 0 0',
                        paddingBottom: '4rem',
                        borderBottom: '1px solid #a1a1a1',
                    }}>
                            <span style={{
                                display: curPage !== 0 ? "initial" : 'none',
                                cursor: "pointer",
                                margin: '0 0.5% 0 0.5%',
                                fontSize: '1rem',
                            }}
                                  onClick={_ => {
                                      setCurpage(curPage - 1)
                                  }}
                            >
                            {'<'}
                        </span>

                        {[1, 2].map((num, idx) => {
                            const isSelected = curPage === idx; // Check if the current number is selected
                            const fontWeight = isSelected ? 'bold' : 'normal'; // Apply bold font if selected

                            return (
                                <span style={{
                                    cursor: "pointer",
                                    margin: '0 0.5% 0 0.5%',
                                    fontSize: '1rem',
                                    fontWeight: fontWeight,
                                }}
                                      onClick={_ => setCurpage(idx)}
                                >{num}</span>
                            )
                        })}
                        <span style={{
                            display: curPage !== totalPages - 1 ? 'initial' : 'none',
                            cursor: "pointer",
                            margin: '0 0.5% 0 0.5%',
                            fontSize: '1rem',
                        }} onClick={_ => {
                            setCurpage(curPage + 1)
                        }}
                        >
                            {'>'}
                        </span>

                    </Row>
                </Content>
            </Layout>
        </div>
    )
}

export default Artists;