import React from "react";
import {Helmet} from "react-helmet";

export const A15Meta = () => (
    <Helmet>
        <title> Rory Watson | Vieunite</title>
        <meta name="description"
              content="He considers his photography as an 'autobiographical photography' intertwined with his life and fed by the emotions that his interaction with the world triggers."/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Rory Watson"/>
        <meta name="twitter:description"
              value="Rory Watson's digital abstract art explores the depth of form and texture. Experience his bold and expressive works on Vieunite's curated art platform. "/>
        <meta name="twitter:url" value="https://vieunite.com/artists/rory-watson"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_rory_watson.jpg"/>
        <meta property="og:url" content="https://vieunite.com/artists/rory-watson"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Artist | Rory Watson"/>
        <meta property="og:description"
              content=" Rory Watson's digital abstract art explores the depth of form and texture. Experience his bold and expressive works on Vieunite's curated art platform. "/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_rory_watson.jpg"/>
    </Helmet>);