import React from 'react';
import {Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import img1 from '../../img/Employee 1.png'
import useWindowResize from "../../../../apps/Hooks/useWindowResize";

const ContactSection = (props) => {
    const {
        bgColor,
        text = "Ready to redefine art and business with Vieunite Pro?",
        buttonText = "CONTACT US",
        link = "/contact"
    } = props
    const {windowWidth} = useWindowResize()
    return (
        <Row justify={'center'} style={{margin: '-8vh auto 4vh auto'}}>
            <Col style={{
                width: windowWidth > 920 ? '40vw' : '80vw',
                borderRadius: '20px',
                background: bgColor ?? 'rgba(6, 70, 122, 0.08)',
                marginLeft: '-1vw',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
                paddingLeft: '4vw',
                height: windowWidth > 920 ? 'auto' : '50vw'
            }}>
                {/*<p className={'pro-contact-section-p'} style={{width: windowWidth > 920 ? '22vw' : '75vw'}}>*/}
                {/*    Ready to redefine art and business with Vieunite Pro?*/}
                {/*</p>*/}
                <p className={'pro-contact-section-p'} style={{width: windowWidth > 920 ? '22vw' : '75vw'}}>
                    {text}
                </p>
                <button
                    className="homeMainButton" style={{width: "12.6vw", minWidth: "220px", marginTop: '-1vh'}}
                    onClick={_ => window.location.href = link}
                >
                    <a className={'homeMainButtonLnk homeOverV'}>
                        {buttonText}
                    </a>
                </button>
            </Col>
            <Col style={{width: windowWidth > 920 ? '40vw' : '80vw',}}>
                <img src={img1} style={{
                    width: windowWidth > 920 ? '40vw' : '80vw',
                    borderRadius: '20px',
                    marginLeft: windowWidth > 920 ? '1vw' : '0',
                    marginTop: windowWidth > 920 ? '0' : '1vh'
                }}/>
            </Col>
        </Row>
    )
}

export default ContactSection;
