import {uploadChunkFile, mergeChunkFile} from "./services";
// Define the function to upload multiple chunks
export const uploadChunks = (chunks) => {
    const uploadPromises = chunks.map((chunk, index) => {
        return uploadChunkFile(chunk)
            .then(result => {
                console.log(result)
                console.log(`Chunk ${index} uploaded successfully.`);
                return result;
            })
            .catch(error => {
                console.log(`Error uploading chunk ${index}: ${error}`);
                throw error;
            });
    });

    return Promise.all(uploadPromises)
        .then(results => {
            console.log("All chunks uploaded successfully.");
            console.log(results); // Return the results if needed for further processing
        })
        .catch(error => {
            console.log("Error uploading chunks:", error);
            throw error;
        });
};

// Usage example


