import React, {useState} from 'react';
import {Layout, Col, Row, Image, Modal} from 'antd';
import '../../App.css';
import "../Home/HomePage.scss";
import "../Textura/Textura.css";
import "./Profile.css";
import "../Artists/Artists.css"
import {Content} from "antd/es/layout/layout";
import {
    EthanPlatt,
    JonathanArmour,
    KeYang,
    JoVincent,
    JenetteColdrick,
    LucyNelson,
    RoryWatson,
    CaitlinFlood,
    AnthonyWebb, MatthewStickland,AlexanderRhys
} from '../../img/Artists/index';
import {
    Barbara,
    ChristianRedermayer,
    DorisLuming,
    LinWangWang,
    DavidBickley,
    AngeliqueNagovskaya
} from "../../img/Artists/index";
import {FazarWibisono, GuilleGiagante, RichardBattye} from "../../img/Artists/index";
import ArtistBgVideo from "../../components/BgVideo/ArtistBgVideo";
import {AMeta} from "../../components/PageMeta/ArtistMeta";

const ArtistTable = {
    'alexanderrhys': AlexanderRhys,
    'matthewstickland': MatthewStickland,
    'ethanplatt': EthanPlatt,
    'jonathanarmour': JonathanArmour,
    'keyang': KeYang,
    'lucynelson': LucyNelson,
    'jovincent': JoVincent,
    'jenettecoldrick': JenetteColdrick,
    'barbara': Barbara,
    'christianredermayer': ChristianRedermayer,
    'dorisluming': DorisLuming,
    'linwangwang': LinWangWang,
    'davidbickley': DavidBickley,
    'richardbattye': RichardBattye,
    'guillegiagante': GuilleGiagante,
    // 'fazarwibisono':FazarWibisono,
    'rorywatson': RoryWatson,
    'caitlinflood': CaitlinFlood,
    'anthonywebb': AnthonyWebb,
    'angeliquenagovskaya': AngeliqueNagovskaya,
}

const Primary_Font = {
    fontFamily: "Proxima Nova bold, sans-serif",
}

const TitleFont = {
    fontSize: '42px',
    fontFamily: "Proxima Nova bold, sans-serif",
}

const Img_Radius = {
    borderRadius: '0.625rem',
}

function Profile(props) {
    const artist = ArtistTable[props.name];
    // const [hasMounted, setHasMounted] = React.useState(false);
    // React.useEffect(() => {
    //     setHasMounted(true);
    // }, []);
    // if (!hasMounted) {
    //     return null;
    // }
    return (
        <div>
            {artist.meta()}
            <Layout>
                <Content>
                    {/*Header Video Zone*/}
                    <div>
                        <ArtistBgVideo
                            desktopUrl={artist.headerDt}
                            mobileUrl={artist.headerM}
                            fullScreen={true}
                        />
                        <Row justify={"center"}>
                            <div
                                className={"title-over-artist-video"}
                                style={{
                                    ...Primary_Font,
                                }}
                            >
                                {artist.name}
                            </div>
                        </Row>
                    </div>

                    <Content style={{width: '100%', overflowX: 'hidden'}}>
                        <Row justify={"center"} style={{backgroundColor: 'white', paddingTop: '2rem'}}>
                            <Col span={24} lg={14} xs={20}>
                                <div className="text-container">
                                    <span className="text-content m-quote" style={{
                                        fontWeight: '600',
                                        fontSize: '2rem',
                                        color: 'black',
                                        ...Primary_Font
                                    }}>
                                        {artist.quote}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row justify={"space-evenly"}
                             style={{
                                 backgroundColor: 'white',
                                 paddingBottom: 0
                             }}
                             className={"img-text-zone"}
                        >
                            <Col span={12} lg={12} xs={24}>
                                <div className="video-container no-left-margin"
                                     style={{marginLeft: '-4.5vw'}}>
                                    <Image
                                        height={'auto'}
                                        width={'100%'}
                                        src={artist.avatar_square}
                                        preview={false}
                                    />
                                </div>
                            </Col>
                            <Col span={12} lg={11} xs={20}>
                                <div className={'vertical-center-text'}>
                                    <div style={{marginTop: '2rem'}}>
                                        {artist.biography.map((val) =>
                                            <p className="text-content-app m-left-text m-bio"
                                               style={{
                                                   ...Primary_Font,
                                                   color: 'black',
                                                   fontWeight: '500',
                                                   fontSize: artist.name === 'Richard Battye' ||
                                                   artist.name === 'Guille Giagante' ?
                                                       '1vw' :
                                                       '1.3vw',
                                                   marginTop: 0,
                                                   textAlign: "center"
                                               }}>
                                                {val}
                                            </p>
                                        )}
                                    </div>
                                    <Row justify={"center"} className={'website-row'}>
                                        <button className="normal-btn">
                                            <a className={'in-normal-btn-link'} href={artist.link_to}>
                                                {artist.name.toUpperCase() + ' WEBSITE'}
                                            </a>
                                        </button>
                                    </Row>
                                </div>

                            </Col>
                        </Row>
                        <Row className={'m-img-art m-artwork-row'} justify={'center'}
                             style={{padding: '4rem 0 .5rem 0', background: '#f4f4f4'}}>
                            <Col className={'dt-left'} span={10} lg={{span: 20}} xs={20}>
                                <h2 className="title-app mobile-left"
                                    style={{textAlign: "left", ...TitleFont}}>
                                    Artwork.
                                </h2>
                                {
                                    artist.name === 'Barbara Safran de Niverville' ? (
                                        <></>
                                    ) : (
                                        <p className="text-content-app mobile-left artist-text"
                                           style={{...Primary_Font, fontWeight: '500'}}>
                                            {'Available on Vieunite.'}
                                        </p>
                                    )
                                }
                            </Col>
                        </Row>
                        {/*Art works row*/}
                        <Row className={'m-img-text m-at'} justify={"center"}
                             style={{background: '#f4f4f4', paddingBottom: '5rem'}}
                             gutter={[24, 24]}
                        >
                            {artist.works.map((work, idx) => (
                                <Col lg={7} xs={20} style={{display: "flex", flexDirection: "column", height: ''}}>
                                    {artist.vertical_work ?
                                        <div className={'heightwidthbox'}>
                                            <img
                                                className={'artwork-img protected-img'}
                                                src={work.img}
                                                style={{
                                                    ...Img_Radius,
                                                    marginLeft: idx === 1 ? '8rem' : idx === 2 ? '6rem' : 'initial',
                                                }}
                                            />
                                        </div>
                                        : <div>
                                            <Image
                                                className={'protected-img'}
                                                src={work.img}
                                                preview={false}
                                                style={{
                                                    ...Img_Radius,
                                                }}
                                            />
                                        </div>
                                    }
                                    <p style={{
                                        ...Primary_Font,
                                        fontWeight: '700',
                                        fontSize: '1rem',
                                        margin: '1rem 0'
                                    }}>
                                        {work.title}
                                    </p>
                                    <p className={'m-date'} style={{
                                        ...Primary_Font,
                                        fontWeight: '500',
                                        fontSize: '1rem',
                                        marginTop: '-2%'
                                    }}>
                                        {work.date}
                                    </p>
                                </Col>
                            ))}
                        </Row>
                        {/*<ImgViewer/>*/}
                    </Content>
                </Content>
            </Layout>
        </div>
    )
}

export default Profile;