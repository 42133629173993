import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Layout, Input, Dropdown, Menu, Modal, Card } from "antd";
import SearchButton from './images/search-normal.svg'
import './DiscoveryArtistSeeAll.scss';
import ArtistSlick from "./DiscoveryArtistsSlickView";
import ProPortalHeader from "../componets/ProPortalHeader";
import ProfileRow from "../componets/ProfileRow";
import Navigator from "../componets/Navigator";
import { Content } from "antd/es/layout/layout";
import { get_api } from "../serverTemp";
import backButton from '.././MyAccount/ExtraServices/UpgradeSubscription/img/Back button.svg';

import artist1 from './images/LucyNelson.png'
import artist2 from './images/KeYang.png'
import artist3 from './images/JonathaArmour.png'
import artist4 from './images/JoVincent.png'
import artist5 from './images/JenetteColdrick.png'

const DiscoveryArtistSeeAll = () => {
    const [searchContent, setSearchContent] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    const [featuredArtists, setFeaturedArtists] = useState([]);
    const [classicArtists, setClassicArtists] = useState([]);
    // const [otherArtists, setOtherArtists] = useState([]);
    const [artists1, setArtists1] = useState([]);
    const [artists2, setArtists2] = useState([]);
    const [artists3, setArtists3] = useState([]);
    const [artists4, setArtists4] = useState([]);

    const navigate = useNavigate();

    // const [search1, setSearch1] = useState([]);
    // const [search2, setSearch2] = useState([]);
    // const [search3, setSearch3] = useState([]);
    // const [search4, setSearch4] = useState([]);

    const splitListInHalf = (list) => {
        const middleIndex = Math.floor(list.length / 2);
        const firstHalf = list.slice(0, middleIndex);
        const secondHalf = list.length > 1 ? list.slice(middleIndex) : [];
        return [firstHalf, secondHalf];
    };

    const openArtistDetails = (param) => {
        navigate('/proportal/discover-art/author-details', { state: { pageType: "artist", info: { artist: param } }})
    }

    const openArtistDetailsWithName = (name) => {
        get_api(`/artist/search?search_word=${name}`).then(res => {
            console.log(res.data[0])
            navigate('/proportal/discover-art/author-details', { state: { pageType: "artist", info: { artist: res.data[0] } }})
        })
    }

    useEffect(() => {
        get_api('/artist/featured').then(res => {
            setFeaturedArtists(res.data);
        });
        get_api('/artist/classic').then(res => {
            setClassicArtists(res.data);
        });
        get_api('/artist/names').then(res => {
            // setOtherArtists(res);
            // console.log("check names data")
            // console.log(res)
            const [firstHalf, secondHalf] = splitListInHalf(res);
            const [list1, list2] = splitListInHalf(firstHalf);
            const [list3, list4] = splitListInHalf(secondHalf);
            setArtists1(list1);
            setArtists2(list2);
            setArtists3(list3);
            setArtists4(list4);
        });
    }, [])

    // var artists1 = [{
    //     "id": "1",
    //     "name": "Lucy Nelson",
    //     "description": "I'm dead",
    //     "url": artist1,
    // }, {
    //     "id": "2",
    //     "name": "Ke Yang",
    //     "description": "I'm dead",
    //     "url": artist2,
    // }, {
    //     "id": "3",
    //     "name": "Jonatha Armour",
    //     "description": "I'm dead",
    //     "url": artist3,
    // }, {
    //     "id": "4",
    //     "name": "Jo Vincent",
    //     "description": "I'm dead",
    //     "url": artist4
    // }, {
    //     "id": "5",
    //     "name": "Jenette Coldrick",
    //     "description": "I'm dead",
    //     "url": artist5
    // }, {
    //     "id": "1",
    //     "name": "Lucy Nelson",
    //     "description": "I'm dead",
    //     "url": artist1,
    // }, {
    //     "id": "2",
    //     "name": "Ke Yang",
    //     "description": "I'm dead",
    //     "url": artist2,
    // }, {
    //     "id": "3",
    //     "name": "Jonatha Armour",
    //     "description": "I'm dead",
    //     "url": artist3,
    // }, {
    //     "id": "4",
    //     "name": "Jo Vincent",
    //     "description": "I'm dead",
    //     "url": artist4
    // }, {
    //     "id": "5",
    //     "name": "Jenette Coldrick",
    //     "description": "I'm dead",
    //     "url": artist5
    // }]

    // var artists2 = [{
    //     "id": "1",
    //     "name": "Lucy Nelson",
    //     "description": "I'm dead",
    //     "url": artist1,
    // }, {
    //     "id": "2",
    //     "name": "Ke Yang",
    //     "description": "I'm dead",
    //     "url": artist2,
    // }, {
    //     "id": "3",
    //     "name": "Jonatha Armour",
    //     "description": "I'm dead",
    //     "url": artist3,
    // }, {
    //     "id": "4",
    //     "name": "Jo Vincent",
    //     "description": "I'm dead",
    //     "url": artist4
    // }, {
    //     "id": "5",
    //     "name": "Jenette Coldrick",
    //     "description": "I'm dead",
    //     "url": artist5
    // }, {
    //     "id": "1",
    //     "name": "Lucy Nelson",
    //     "description": "I'm dead",
    //     "url": artist1,
    // }, {
    //     "id": "2",
    //     "name": "Ke Yang",
    //     "description": "I'm dead",
    //     "url": artist2,
    // }, {
    //     "id": "3",
    //     "name": "Jonatha Armour",
    //     "description": "I'm dead",
    //     "url": artist3,
    // }, {
    //     "id": "4",
    //     "name": "Jo Vincent",
    //     "description": "I'm dead",
    //     "url": artist4
    // }, {
    //     "id": "5",
    //     "name": "Jenette Coldrick",
    //     "description": "I'm dead",
    //     "url": artist5
    // }]

    const handleSubmit = () => {
        get_api(`/artist/search?search_word=${searchContent}`).then(res => {
            setSearchResult(res.data)
            // console.log(res.data)
            // const [firstHalf, secondHalf] = splitListInHalf(res.data);
            // const [list1, list2] = splitListInHalf(firstHalf);
            // const [list3, list4] = splitListInHalf(secondHalf);
            // setSearch1(list1);
            // setSearch2(list2);
            // setSearch3(list3);
            // setSearch4(list4);
        })
    };

    const prefetchResult  = (props) => {
        get_api(`/artist/search?search_word=${props}`).then(res => {
            setSearchResult(res.data)
        })
    };

    const onBack = () => {
        navigate('/proportal/discover-art')
    }

    return (
        <div style={{ margin: "35px", minHeight: "100vh" }}>
            {/* <p className="artists-seeall-searchbar-title">Artists</p> */}

            <div style={{display: 'flex', alignItems: 'center', marginLeft: '-5px', marginTop: "-10px"}}>
                <img src={backButton} alt="BackButton" className='BackButtonIcon' style={{ marginRight: '15px', cursor: "pointer" }} onClick={onBack}/>
                <p className="artists-seeall-searchbar-title">Artists</p>
            </div>

            <Form
                onFinish={handleSubmit}
                style={{ display: "flex", alignItems: "center", marginTop: "-15px", marginBottom: "-15px", marginLeft: "65px"}}
            >
                <Form.Item className="search-input-frame">
                    <Input placeholder="Search by name" style={{ borderRadius: "6px" }} onChange={(e) => {
                        setSearchContent(e.target.value);
                        // console.log(e.target.value);
                        // setSearchResult(otherArtists.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())));
                        prefetchResult(e.target.value);
                    }} >
                    </Input>
                </Form.Item>
                <Form.Item className="search-button-frame">
                    <Button className="discover-search-button" style={{ borderRadius: "6px" }} type="primary" htmlType="submit">
                        <img src={SearchButton} alt="search" style={{ width: "20px", height: "20px" }} />
                    </Button>
                </Form.Item>
            </Form>

            {searchContent === "" ? (
                <div><ArtistSlick style={{marginLeft: "75px"}} titleText="Featured Artists" artists={featuredArtists} />
                    <ArtistSlick style={{marginLeft: "75px"}} titleText="Classic Artists" artists={classicArtists} />
                    <p className='collection-title' style={{marginTop: "50px", marginLeft: "75px"}}> All Artists </p>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <div style={{ width: "25%" }}>
                            {artists1.map((item, index) => (
                                <p key={index} className="artists-name-in-bottom-list artists-name-in-bottom-list-center" onClick={() => openArtistDetailsWithName(item)}> {item} </p>
                            ))}
                        </div>
                        <div style={{ width: "25%" }}>
                            {artists2.map((item, index) => (
                                <p key={index} className="artists-name-in-bottom-list artists-name-in-bottom-list-center" onClick={() => openArtistDetailsWithName(item)}> {item} </p>
                            ))}
                        </div>
                        <div style={{ width: "25%" }}>
                            {artists3.map((item, index) => (
                                <p key={index} className="artists-name-in-bottom-list artists-name-in-bottom-list-center" onClick={() => openArtistDetailsWithName(item)}> {item} </p>
                            ))}
                        </div>
                        <div style={{ width: "25%" }}>
                            {artists4.map((item, index) => (
                                <p key={index} className="artists-name-in-bottom-list artists-name-in-bottom-list-center" onClick={() => openArtistDetailsWithName(item)}> {item} </p>
                            ))}
                        </div>
                    </div></div>
            ) : (
                <div style={{ marginTop: "20px", marginLeft: "15px" }}>
                    {searchResult.map((item, index) => (
                        <span key={index} className="artists-name-in-bottom-list" onClick={() => openArtistDetails(item)}> {item.name} </span>
                    ))}
                    {/* <div style={{ width: "25%" }}>
                        {search1.map((item, index) => (
                            <p key={index} className="artists-name-in-bottom-list"> {item.name} </p>
                        ))}
                    </div>
                    <div style={{ width: "25%" }}>
                        {search2.map((item, index) => (
                            <p key={index} className="artists-name-in-bottom-list"> {item.name} </p>
                        ))}
                    </div>
                    <div style={{ width: "25%" }}>
                        {search3.map((item, index) => (
                            <p key={index} className="artists-name-in-bottom-list"> {item.name} </p>
                        ))}
                    </div>
                    <div style={{ width: "25%" }}>
                        {search4.map((item, index) => (
                            <p key={index} className="artists-name-in-bottom-list"> {item.name} </p>
                        ))}
                    </div> */}
                </div>
            )}
            
        </div>
    );
};

const DiscoveryArtistsSeeAllWrapper = () => {
    return (
        <Layout>
            <Content>
                <ProPortalHeader />
                <ProfileRow />
                <Layout>
                    <Navigator />
                    <Layout>
                        <DiscoveryArtistSeeAll />
                    </Layout>
                </Layout>
            </Content>
        </Layout>
    )
}

export default DiscoveryArtistsSeeAllWrapper;