import {Button, Form, Input, Row} from "antd";
import {Primary_Font, TitleFont} from "../../../settings";
import {getVerificationCode, verifyCode} from "../services";
import {MailOutlined} from "@ant-design/icons";
import React from "react";
import VerificationInput from "react-verification-input";
import "./style.scss"
import useWindowResize from "../../Hooks/useWindowResize";

export const InputCode = (props) => {
    const {setStep, setCode, email} = props
    const {windowWidth} = useWindowResize()
    return (
        <div>
            <Row justify={"center"}>
                <h2 className="title-app"
                    style={window.outerWidth >= 600 ?
                        {textAlign: "center", marginTop: '-2%', ...TitleFont,} :
                        {textAlign: "left", ...TitleFont,}
                    }>
                    <span style={{fontWeight: '300', fontSize: '2rem'}}>Update Password</span>
                </h2>
            </Row>
            <Row justify={"center"}>
                <p style={{
                    ...Primary_Font,
                    textAlign: "center",
                    width: windowWidth > 600 ? '100%' : '90%'
                }}>
                    Please input the code sent to your email address to reset your password.
                </p>
            </Row>
            <Row justify={"center"}>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={(values) => {
                        setCode(values.code)
                        setStep(2)
                    }}
                    style={windowWidth >= 600 ? {width: '50%'} : {width: '90%'}}
                >
                    <Form.Item
                        className={'verification-form'}
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: 'Please Input Verification Code!',
                            },
                        ]}
                    >
                        <VerificationInput
                            length={6}
                            placeholder={''}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" className="login-form-button">
                            VERIFY
                        </Button>
                    </Form.Item>
                </Form>
            </Row>
        </div>
    )
}