import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import AlbumCard from "../../ProDiscovery/DiscoveryAlbumCardView";
import { useLocation } from "react-router-dom";
import AlbumCardSingle from "./AlbumCardSingle";

export const AlbumSlider = (props) => {
    const { albums, textura_ids, is_pls } = props
    console.log(albums)
    const placeholderAlbum = {
        id: "placeholder",
        name: "placeholder",
        urls: "",
        item: {
            left: null,
            upRight: null,
            downRight: null,
        },
        num: 0,
        level: 0,
    };
    while (albums.length < 4) {
        albums.push(placeholderAlbum);
    }
    var sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: Math.min(albums.length,4),
        slidesToScroll: 4,
        initialSlide: Math.min(albums.length,4),
        nextArrow: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="41" viewBox="0 0 25 41" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1.32977 36.6463C0.353459 37.6226 0.353459 39.2055 1.32977 40.1818C2.30608 41.1581 3.88899 41.1581 4.8653 40.1818L14.4377 30.6094L24.0101 21.0371L23.9733 21.0003L24.1172 20.8564L4.97243 1.71161C3.99612 0.735296 2.41321 0.735296 1.4369 1.71161C0.460586 2.68792 0.460586 4.27083 1.4369 5.24714L17.0829 20.8932L10.9022 27.0739L1.32977 36.6463Z"
                fill="#0E487A" />
        </svg>,
        prevArrow: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="41" viewBox="0 0 25 41" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M23.6761 36.6463C24.6524 37.6226 24.6524 39.2055 23.6761 40.1818C22.6998 41.1581 21.1169 41.1581 20.1406 40.1818L10.5682 30.6094L0.995795 21.0371L1.03258 21.0003L0.88867 20.8564L20.0334 1.71161C21.0097 0.735296 22.5927 0.735296 23.569 1.71161C24.5453 2.68792 24.5453 4.27083 23.569 5.24714L7.92295 20.8932L14.1037 27.0739L23.6761 36.6463Z"
                fill="#0E487A" />
        </svg>,
    };
    return (
        <div style={{ margin: "2rem 4vw 1rem 2vw" }}>
            <Slider style={{minWidth: "1000px", width:'auto'}} {...sliderSettings}>
                {albums.map((ab, index) => (
                    ab.id !== "placeholder"?
                    <div key={index}>
                        <AlbumCardSingle album={ab} textura_ids={textura_ids} is_pls={is_pls}/>
                    </div>:<div></div>
                ))}
            </Slider>
        </div>
    );
}
