import {APP_API_URL} from "../../../../../settings";

const token = localStorage.getItem('token');
export const uploadChunkFile = (chunk) => {
    return new Promise((resolve, reject) => {
        const {file, md5, md5Chunk, totalChunks, chunkIndex, fileName} = chunk
        const formData = new FormData();
        formData.append("fileName", file.name);
        formData.append("md5", md5);
        formData.append("md5Chunk", md5Chunk);
        formData.append("chunks", totalChunks);
        formData.append("chunkIndex", chunkIndex);
        formData.append("file", file, fileName);
        const req = {
            method: 'POST',
            headers: {"X-Access-Token": token,},
            body: formData,
        };
        fetch(APP_API_URL + "/photoframe-mobile/system/tools/uploadChunkFile", req)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                resolve(result)
            })
            .catch(error => {
                console.log('error', error);
                reject(error);
            });
    })
}

export const mergeChunkFile = (data) => {
    return new Promise((resolve, reject) => {
        const req = {
            method: 'POST',
            headers: {"X-Access-Token": token, "Content-Type": "application/json"},
            body: JSON.stringify(data),
        };
        return fetch(APP_API_URL + "/photoframe-mobile/system/tools/mergeChunkFileApp", req)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                result.code === 200 ? resolve(result) : reject(result.message)
            })
            .catch(error => reject('error', error));
    })
}