import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import DiscoveryArtistCardView from "./CommonCardView"
import { Skeleton } from 'antd';
import './CommonCardView.css';

import artist1 from './images/LucyNelson.png'
import artist2 from './images/KeYang.png'
import artist3 from './images/JonathaArmour.png'
import artist4 from './images/JoVincent.png'
import artist5 from './images/JenetteColdrick.png'

const DiscoverySlick = (props) => {
  //   {
  //     "id": "1",
  //     "name": "I am who I am",
  //     "description": "King of kings",
  //     "url": "https://gospelproject.lifeway.com/wp-content/uploads/tgp2018/2021/11/4_3_God_Delivered_His_People.jpg"
  // }
  // const [gallleries, setGalleries] = useState([]);

  // const galleryList = [{
  //   "id": "1",
  //   "name": "Lucy Nelson",
  //   "description": "I'm dead",
  //   "url": artist1,
  // }, {
  //   "id": "2",
  //   "name": "Ke Yang",
  //   "description": "I'm dead",
  //   "url": artist2,
  // }, {
  //   "id": "3",
  //   "name": "Jonatha Armour",
  //   "description": "I'm dead",
  //   "url": artist3,
  // }, {
  //   "id": "4",
  //   "name": "Jo Vincent",
  //   "description": "I'm dead",
  //   "url": artist4
  // }, {
  //   "id": "5",
  //   "name": "Jenette Coldrick",
  //   "description": "I'm dead",
  //   "url": artist5
  // }, {
  //   "id": "1",
  //   "name": "Lucy Nelson",
  //   "description": "I'm dead",
  //   "url": artist1,
  // }, {
  //   "id": "2",
  //   "name": "Ke Yang",
  //   "description": "I'm dead",
  //   "url": artist2,
  // }, {
  //   "id": "3",
  //   "name": "Jonatha Armour",
  //   "description": "I'm dead",
  //   "url": artist3,
  // }, {
  //   "id": "4",
  //   "name": "Jo Vincent",
  //   "description": "I'm dead",
  //   "url": artist4
  // }, {
  //   "id": "5",
  //   "name": "Jenette Coldrick",
  //   "description": "I'm dead",
  //   "url": artist5
  // }]

  // useEffect(() => {
  //   setGalleries(galleryList)
  // }, [])

  var sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="41" viewBox="0 0 25 41" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.32977 36.6463C0.353459 37.6226 0.353459 39.2055 1.32977 40.1818C2.30608 41.1581 3.88899 41.1581 4.8653 40.1818L14.4377 30.6094L24.0101 21.0371L23.9733 21.0003L24.1172 20.8564L4.97243 1.71161C3.99612 0.735296 2.41321 0.735296 1.4369 1.71161C0.460586 2.68792 0.460586 4.27083 1.4369 5.24714L17.0829 20.8932L10.9022 27.0739L1.32977 36.6463Z" fill="#0E487A" />
    </svg>,
    prevArrow: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="41" viewBox="0 0 25 41" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.6761 36.6463C24.6524 37.6226 24.6524 39.2055 23.6761 40.1818C22.6998 41.1581 21.1169 41.1581 20.1406 40.1818L10.5682 30.6094L0.995795 21.0371L1.03258 21.0003L0.88867 20.8564L20.0334 1.71161C21.0097 0.735296 22.5927 0.735296 23.569 1.71161C24.5453 2.68792 24.5453 4.27083 23.569 5.24714L7.92295 20.8932L14.1037 27.0739L23.6761 36.6463Z" fill="#0E487A" />
    </svg>,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
          breakpoint: 1000,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }
    ]
  };
  return (
    <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
      <p className='collection-title' style={props.style}> {props.titleText ? (
        props.titleText
      ) : (
        "Artists"
      )} </p>
      {/*props.gallleries.length === 0 && (
        <Skeleton active />
      )*/}
      {props.artists.length !== 0 && (
        <Slider {...sliderSettings}>
          {props.artists.map((item, index) => (
            <div key={index}>
              <DiscoveryArtistCardView item={{ ...item, pageType: "artist" }} />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
}

export default DiscoverySlick;