import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

const AutoScrollToTop = ({ children }) => {
    const location = useLocation()
    useLayoutEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, [location.pathname])
    return children
}

export default AutoScrollToTop
