import React, {useEffect, useRef, useState} from 'react';
import {Col, DatePicker, Form, Image, Input, message, Modal, Radio, Row, Select} from "antd";
import {APP_API_URL} from "../../../../../settings";
import Tags from "../Tags";
import './Editor.scss'
import {editArtwork, getArtInfo, getDisciplines, uploadNewArt} from "./services";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import useWindowResize from "../../../../Hooks/useWindowResize";

export const Editor = (props) => {
    const {windowWidth} = useWindowResize()
    const {isOpen, artInfo, isNewArt, mergedRes} = props;

    const [info, setInfo] = useState({});
    const [discipline, setDiscipline] = useState([])
    const [initTags, setInitTags] = useState(['Discipline', 'Genre'])

    const tagsRef = useRef([])

    const [form] = Form.useForm();
    const priceValue = Form.useWatch('price', form)
    const disciplineSelected = Form.useWatch('discipline', form)

    useEffect(() => {
        const update = async () => {
            if (isNewArt) {
                setInfo({'pic_url': mergedRes.iconUri});
            } else {
                await setInfo(artInfo || {})
                if (artInfo.tags) {
                    await setInitTags(artInfo.tags)
                }
            }
        }
        update()
    }, [artInfo, isNewArt, mergedRes]);
    useEffect(() => {
        getDisciplines(setDiscipline);
    }, [])

    const onFinish = async (fieldsValue) => {
        // console.log(mergedRes)
        const price = parseFloat(form.getFieldValue('price'))
        const values = {
            ...fieldsValue,
            'dateProduced': fieldsValue['date'] ? fieldsValue['date'].format('YYYY-MM-DD') : '',
            'picUrl': isNewArt ? info.pic_url : null,
            'tags': tagsRef.current.tags.toString(),
            'costPrice': (price / 1.2 * 0.9).toFixed(2).toString(),
            'picWidth': isNewArt ? mergedRes.width : null,
            'picHeight': isNewArt ? mergedRes.height : null,
            'userStoreId': isNewArt ? mergedRes.id : null,
            'price': price.toFixed(2).toString(),
            'markId': '', //new only
            'artworkCategoryId': '',
            // 'powerType': isNewArt ? mergedRes.type : info.powerType,
            'powerType': 1,
            'displayType': isNewArt ? (mergedRes.width > mergedRes.height ? 1 : 0) : null,
            'isFree': price > 0 ? 1 : 0,
            "updownStatus": 1, //'上下架状态 1已上架 2已下架'
            "markNameStr": isNewArt ? mergedRes.newName : null,
            ...(isNewArt ? {"watermarkUrl": mergedRes.iconWaterMarkUri} : {}),
            ...(isNewArt ? {} : {'id': info.id}),
            ...(isNewArt ? {} : {'name': fieldsValue.artworkName}),
        };

        delete values.date
        delete values.discipline
        delete values.genre
        console.log('Received values of form: ', values);

        // Final step: Send all info to server
        if (isNewArt) {
            await uploadNewArt(values).then((code) => {
                if (code === 200) {
                    message.success('Uploaded successfully!', 1)
                        .then(() => window.location.reload())
                } else throw Error(code)
            }).catch(error => console.log('error', error));
        } else {
            await editArtwork(values)
                .then((code) => {
                    if (code === 200) {
                        message.success('Updated successfully!', 1)
                            .then(() => window.location.reload())
                    } else throw Error(code)
                })
                .catch(error => console.log('error', error));
        }
    }
    return (
        <Modal title={
            <>
                <p style={{
                    fontSize: windowWidth > 600 ? '2rem' : '1.5rem',
                }}>
                    <span style={{fontWeight: '400',}}>Upload</span> Artwork.
                </p>
                <p style={{
                    fontSize: windowWidth > 600 ? '1rem' : '0.7rem',
                    fontWeight: '500'
                }}>Complete information about your artwork. Please note that after you click confirm you will not be
                    able to
                    change the artwork. </p>
            </>}
               className={'art-editor'}
               visible={isOpen}
               onOk={() => {
                   form.submit();
                   // window.location.reload();
               }}
               onCancel={() => {
                   form.resetFields();
                   window.location.reload();
               }}
               width={windowWidth > 600 ? '90%' : 'auto'}
               height={'auto'}
               destroyOnClose={true}
        >
            <Row justify={"center"}>
                <Col lg={8} xs={24}>
                    <div style={{
                        height: windowWidth > 600 ? "26rem" : "20rem",
                        width: windowWidth > 600 ? "26rem" : "20rem",
                        maxHeight: "100%",
                        maxWidth: "100%",
                        display: 'flex',
                        margin: 'auto',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    }}>
                        <img src={info.pic_url}
                             alt={'art'}
                             style={{
                                 width: windowWidth > 600 ? "26rem" : "20rem",
                                 height: windowWidth > 600 ? "26rem" : "20rem",
                                 objectFit: 'contain',
                             }}
                        />
                    </div>
                </Col>
                <Col lg={14} xs={24}>
                    <Form
                        className={'whole-form'}
                        form={form}
                        layout="vertical"
                        name="art_form"
                        initialValues={{
                            modifier: 'public',
                            artworkName: info.name || "",
                            date: info.dateProduced ? moment(info.dateProduced, 'YYYY-MM-DD') : null,
                            discipline: info.discipline || "",
                            genre: info.genre || '',
                            price: info.originalPrice || 0,
                            introduce: info.introduce || "",
                        }}
                        onFinish={onFinish}
                        preserve={false}
                    >
                        <Row justify={"center"} gutter={[48, 48]}>
                            <Col lg={10} xs={24}>
                                <Form.Item
                                    className={'input-form'}
                                    name="artworkName"
                                    label="Name"
                                    rules={[
                                        {
                                            required: true,
                                            type: 'string',
                                        },
                                    ]}
                                >
                                    <Input bordered={false} size={"large"}/>
                                </Form.Item>
                            </Col>
                            <Col lg={10} xs={24}>
                                <Form.Item
                                    className={'input-form'}
                                    name="date"
                                    label="Date Produced"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <DatePicker bordered={false} size={"large"} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={"center"} gutter={[48, 48]}>
                            <Col lg={10} xs={24}>
                                <Form.Item className={'input-form'}
                                           name="discipline"
                                           label="Discipline"
                                           rules={[
                                               {
                                                   required: true,
                                               },
                                           ]}
                                >
                                    <Select bordered={false} size={"large"}
                                            placeholder="Please select a discipline."
                                        // defaultValue={discipline[0].name}
                                            onSelect={(val) => {
                                                setInitTags([val, ...initTags.slice(1)]);
                                            }}
                                    >
                                        {discipline.map((obj, index) =>
                                            <Select.Option key={index} value={obj.name}>{obj.name}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={10} xs={24}>
                                <Form.Item className={'input-form'}
                                           name="price"
                                           label="Price"
                                           rules={[
                                               {
                                                   required: true,
                                                   pattern: new RegExp(/^(?:[1-9]\d{0,3}|0)(?:\.\d{1,2})?$/),
                                                   message: 'Please Input a number between 0 and 9999.99'
                                               },
                                           ]}
                                >
                                    <Input bordered={false}
                                           size={"large"}
                                           className={'input-form'}
                                           addonBefore="£"
                                           style={{
                                               width: '100%',
                                           }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={"center"} gutter={[48, 48]}>
                            <Col lg={10} xs={24}>
                                <Form.Item className={'input-form'}
                                           name="genre"
                                           label="Genre (Please select Discipline first!)"
                                           rules={[
                                               {
                                                   required: true,
                                               },
                                           ]}
                                >
                                    <Select bordered={false} size={"large"}
                                            className={'input-form'}
                                            placeholder="Please select a genre."
                                            defaultActiveFirstOption={true}
                                            onSelect={(val) => {
                                                setInitTags([initTags[0], val, ...initTags.slice(2)]);
                                            }}
                                    >
                                        {
                                            discipline.map((obj) =>
                                                obj.name === disciplineSelected ?
                                                    obj.genres.split(',').map((g, index) =>
                                                        <Select.Option key={index} value={g}>{g}</Select.Option>
                                                    ) : <></>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={10} xs={24}>
                                {/*When using name field on the Form.Item,
                                it means that the Form component will handle the value and handleChange on the field from then on.
                                You don't need to add them.
                                So if you need to add value and handleChange, remove name prop from Form.Item */}
                                <Form.Item className={'input-form'}
                                    // name={'costPrice'}
                                           label="Take Home Price (After VAT and Vieunite Fees)"
                                >
                                    <Input bordered={false}
                                           size={"large"}
                                           addonBefore="£"
                                           value={(priceValue / 1.2 * 0.9).toFixed(2)}
                                           disabled={true}
                                    ></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={"center"} gutter={[48, 48]}>
                            <Col span={20} lg={20} xs={24}>
                                <Form.Item className={'input-form'} name="introduce" label="Description">
                                    <Input.TextArea bordered={false} rows={3}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={"center"} gutter={[48, 48]}>
                            <Col span={20} lg={20} xs={24}>
                                <Form.Item name="tags" label="Tags">
                                    <Tags
                                        ref={tagsRef}
                                        initTags={initTags}
                                        key={`${initTags}`}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>
        </Modal>)
}