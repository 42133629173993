import React, {useEffect, useState} from 'react';
import './style.scss';
import {Button, Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import ProPortalHeader from "../../../componets/ProPortalHeader";
import ProfileRow from "../../../componets/ProfileRow";
import Navigator from "../../../componets/Navigator";
import {get_api, post_api} from "../../../serverTemp";
import backButton from "./imgs/Back button.svg";
import {useNavigate} from "react-router-dom";


const AssignDevices = () => {
    const [user, setUser] = useState({});
    const [newData, setNewData] = useState({
        id: localStorage.getItem('user_id'),
        avatar: '',
        email: '',
        nickname: '',
    });

    const [formData, setFormData] = useState({
        numberOnAccount: '',
        accountType: '',
        numberAssigned: '',
        email: ''
    });

    const token = localStorage.getItem('token');  // 获取 token

    // 封装一个函数来获取库存信息
    const fetchStockData = () => {
        if (token) {
            get_api('/textura/reseller/textura/stock', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                console.log(res);

                const subscriptionMap = {
                    0: 'basic',
                    1: 'advanced',
                    2: 'premium'
                };

                const subscriptionType = subscriptionMap[res.subscription] || 'unknown';

                // 更新库存信息
                setFormData(prevState => ({
                    ...prevState,
                    numberOnAccount: res.number_of_stock || '',
                    accountType: subscriptionType,
                }));
            }).catch(err => {
                console.error("Error fetching Textura stock:", err);
            });
        }
    };

    useEffect(() => {
        // 获取用户详情
        get_api('/user/detail').then(res => {
            console.log(res);
            setUser(res);
            setNewData({
                ...newData,
                avatar: res.avatar,
                email: res.email,
                nickname: res.nickname
            });
        });

        // 初始获取库存信息
        fetchStockData();
    }, []);

    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // 使用 navigate 函数导航回上一页
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Submitting form...');
        if (token) {
            post_api('/textura/reseller/assign/textura', {
                amount: formData.numberAssigned,
                email: formData.email
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                console.log('Response:', res);
                alert("Textura assigned successfully!");

                // 分配成功后重新获取最新的库存信息
                fetchStockData();

                // 用户点击OK后刷新当前页面
                window.location.reload();  // 或者你可以选择直接刷新组件状态而不刷新页面

            }).catch(err => {
                console.error("Error assigning Textura:", err);
                alert("Failed to assign Textura. Please try again.");
            });
        } else {
            alert("No token found. Please log in again.");
        }
    };

    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <div className='ProAccountDetails'>
                                <div className='header3'>
                                    <img src={backButton} alt="BackButton" className='BackButtonIcon8'
                                         style={{marginRight: '0.5rem', cursor: "pointer"}} onClick={handleBackClick}/>
                                    <p className='ProAccountDetails-title'>Hello {user?.nickname}</p>
                                </div>

                                <div className='PortalAssignDevices'>
                                    <form onSubmit={handleSubmit}>
                                        <div className='flexContainer'>
                                            <div className='leftPanel'>
                                                <div className='PortalAssignDevicesTitle1'>User Assigning Texturas</div>
                                                <div className='PortalAssignDevicesLable1'>
                                                    <label>
                                                        Number of Textura on account:
                                                    </label>
                                                    <input className='PortalAssignDevicesInput1'
                                                           type="text"
                                                           name="numberOnAccount"
                                                           value={formData.numberOnAccount}
                                                           onChange={handleChange}
                                                           style={{ color: '#000' }}
                                                           readOnly
                                                    />
                                                </div>
                                                <div className='PortalAssignDevicesLable2'>
                                                    <label>
                                                        Account type:
                                                    </label>
                                                    <input className='PortalAssignDevicesInput2'
                                                           type="text"
                                                           name="accountType"
                                                           value={formData.accountType}
                                                           onChange={handleChange}
                                                           style={{ color: '#000' }}
                                                           readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className='divider'></div>
                                            <div className='rightPanel'>
                                                <div className='PortalAssignDevicesTitle2'>User being assigned</div>
                                                <div className='PortalAssignDevicesLable3'>
                                                    <label>
                                                        Number of Textura assigned:
                                                    </label>
                                                    <input className='PortalAssignDevicesInput3'
                                                           type="text"
                                                           name="numberAssigned"
                                                           value={formData.numberAssigned}
                                                           onChange={handleChange}
                                                           style={{ color: '#000' }}
                                                    />
                                                </div>
                                                <div className='PortalAssignDevicesLable4'>
                                                    <label>
                                                        Email address of assignee:
                                                    </label>
                                                    <input className='PortalAssignDevicesInput4'
                                                           type="text"
                                                           name="email"
                                                           value={formData.email}
                                                           onChange={handleChange}
                                                           style={{ color: '#000' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <Button htmlType="submit" className='assignDevicesButton'>Assign</Button>
                                    </form>
                                </div>
                            </div>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    );
}

export default AssignDevices;
