import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Divider, Form, Image, Input, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import useWindowResize from "../Hooks/useWindowResize";
import ShadowHeader from "../../components/Header/ShadowHeader";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import {Primary_Font, TitleFont} from "../../settings";
import {ApplyText} from "./text";


const Finish = (props) => {
    const {windowWidth} = useWindowResize();
    const logout = () => {
        localStorage.clear();
        window.location.href = '/home';
    }

    return (
        <>
            <ShadowHeader></ShadowHeader>
            <Layout>
                <Content className={'grey-bk'} style={{maxHeight: 'auto', height: 'auto'}}>
                    <Row justify={"center"}
                         style={windowWidth>600?
                        {padding: '12rem 0 20rem 0'}:
                        {padding: '12rem 0 0 0',backgroundColor:"white"}}
                    >
                        <Col lg={12} xs={24}
                             style={{
                                 backgroundColor: 'white',
                                 padding: windowWidth > 600 ? '7rem' : '3rem',
                                 borderRadius: windowWidth > 600 ? '20px' : 0,
                                 display: 'flex',
                                 justifyContent: "center",
                                 flexDirection: "column"
                             }}>
                            <Row justify={"center"}
                                 style={{}}>
                                <h2 className="title-app"
                                    style={windowWidth >= 600 ?
                                        {textAlign: "center", ...TitleFont,} :
                                        {textAlign: "left", ...TitleFont, fontSize: '2rem',}}
                                >
                                    Become a Vieunite Artist
                                </h2>
                            </Row>
                            <Row justify={"center"}
                                 style={{}}>
                                <h2 className="title-app"
                                    style={windowWidth >= 600 ?
                                        {textAlign: "center", marginTop: '-4%', ...TitleFont,} :
                                        {textAlign: "center", ...TitleFont, marginTop: '-4%', }
                                    }>
                                    <span style={{fontWeight: '300', fontSize: windowWidth > 600 ? '2rem' : '1.4rem'}}>
                                        Thanks for your application
                                    </span>
                                </h2>
                            </Row>
                            <Row justify={"center"}>
                                <p style={{textAlign: "center", ...Primary_Font}}>
                                    {'Your application will now be manually reviewed by the Vieunite team within 5 working days. You will be notified by email once this is ready.'}
                                </p>
                            </Row>
                            <Row justify={"center"} style={{}}>
                                <Button
                                    className="login-form-button"
                                    style={{width: '60%'}}
                                    onClick={logout}
                                >
                                    LOG OUT >
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    )
}
export default Finish;