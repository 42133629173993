import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Attention2 = () => {
    const navigate = useNavigate();
    const imageUrls = JSON.parse(localStorage.getItem('imageUrls')) || [];

    useEffect(() => {
        // Set a timer to navigate to the next page after 10 seconds
        const timer = setTimeout(() => {
            navigate('/ImagePage2', { state: { imageUrls } });
        }, 10000); // 10-second delay

        // Cleanup the timer when the component unmounts
        return () => clearTimeout(timer);
    }, [navigate, imageUrls]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
            fontSize: '30px',
            fontWeight: 'bolder',
        }}>
            <div style={{fontSize: '30px', textAlign: 'center', lineHeight: '2vw'}}>
                <p style={{fontSize: '30px'}}>
                    You will now be presented with five more images. Again, please pay attention to the images and
                    consider how you feel whilst viewing these images.
                </p>
                <p style={{fontSize: '30px'}}>
                    After you have been presented with the five images, you will be asked to rate your feelings.
                </p>
            </div>

            {/* You can add a countdown message or progress indicator if needed */}
        </div>
    );
};

export default Attention2;
