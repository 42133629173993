import React, {useEffect, useRef, useState} from 'react';
import landing1 from './img/mobile_landing_1.png'
import landing2 from './img/mobile_landing_2.png'
import landingBackground1 from './img/landing_mobile_background_1.png'
import landingBackground2 from './img/landing_mobile_background_2.png'
import home_logo from './img/landing_home_logo.png'
import pro_logo from './img/landing_pro_logo.png'

import demo1 from "./img/CreationShowcases/demo1.png"
import demo2 from "./img/CreationShowcases/demo2.png"
import demo3 from "./img/CreationShowcases/demo3.png"
import demo4 from "./img/CreationShowcases/demo4.png"
import demo5 from "./img/CreationShowcases/demo5.png"
import demo6 from "./img/CreationShowcases/demo6.png"
import demo7 from "./img/CreationShowcases/demo7.png"
import demo8 from "./img/CreationShowcases/demo8.png"
import demo9 from "./img/CreationShowcases/demo9.png"

import action11 from "./img/Actions/1-1.png"
import action12 from "./img/Actions/1-2.png"
import action13 from "./img/Actions/1-3.png"
import action14 from "./img/Actions/1-4.png"
import action15 from "./img/Actions/1-5.png"
import action16 from "./img/Actions/1-6.png"
import action17 from "./img/Actions/1-7.png"

import innovating_cover from "./img/landing_innovating_cover.png"
import leftScroll from "./img/Textura/5 - Textura App/LeftScroll.png";
import rightScroll from "./img/Textura/5 - Textura App/RightScroll.png";
import {Carousel} from "antd";
import landing_video from "./img/landing_main.mp4";
import cover from "./img/landing_cover_mobile.webp";
import {AnimatePresence, motion} from "framer-motion";


const Landing = (props) => {
    const demoArts = [
        {img: demo1, artwork: "'Under the Wave off Kanagawa'", artist: "Katsushika Hokusai"},
        {img: demo2, artwork: "Lady Agnew of Lochnaw", artist: "John Singer Sargent"},
        {img: demo3, artwork: "'Winter Night in the Mountains'", artist: "Harald Sohlberg"},
        {img: demo4, artwork: "Final Study for' Bathers at Asnieres'", artist: "Georges Seurat"},
        {img: demo5, artwork: "Equestrienne (At the Cirque Fernando)'", artist: "Henri de Toulouse-Lautrec"},
        {img: demo6, artwork: "Mrs.WilliamBonham", artist: "William Bonnell"},
        {img: demo7, artwork: "Starry, starry night", artist: "Guille Giagante"},
        {img: demo8, artwork: "Girl and Bike", artist: "Anthony Webb"},
        {img: demo9, artwork: 'Sleeve Shock', artist: "Jonathan Armour"},

    ]
    // const demo_list = [demo1, demo2, demo3, demo4, demo5, demo6, demo7, demo8, demo9, demo10, demo11, demo12, demo13, demo14, demo15, demo16, demo17, demo18];
    const action_list = [action11, action12, action13, action14, action15, action16, action17];
    const action1_titles = [
        'Antarctica Exhibition',
        "Celebrating Anthony Webb",
        "Jonathan Armour Exhibition",
        "A Year of Growth",
        "Vieunite Partners with Estorick Collection",
        "What Makes Us Unique",
        "Vieunite Partners with RBSA"
    ]
    const action1_bodies = [
        "Explore this unique exhibition by our Vieunite Artist, Guille Giagante",
        "Learn about the talented Anthony Webb and his photography work",
        "Visit \"Birth Sleeves\" exhibition, brought to you by Jonathan Armour",
        "Dive into our journey of innovation and artistry",
        "Fusing Art and Technology: Vieunite’s Partnership with Estorick Collection",
        "Explore the benefits of Vieunite and how we are supporting the art world",
        "Learn about our partnership with the RBSA Gallery and how you can get exclusive access to their collections"
    ]
    const action1_links = [
        "https://vieunite.com/news/vieunite-proudly-supports-antarctica-a-challenge-to-the-senses-art-exhibition-in-argentina/",
        "https://vieunite.com/news/celebrating-anthony-webb-a-photographer-with-an-artists-eye/",
        "https://vieunite.com/jonathanarmour/",
        "https://vieunite.com/news/vieunite-2023-in-review-a-year-of-milestones-and-growth/",
        "https://vieunite.com/news/fusing-art-and-technology-vieunites-partnership-with-estorick-collection/",
        "https://vieunite.com/news/beyond-the-frame-texturas-innovative-approach-to-the-digital-canvas/",
        "https://vieunite.com/news/uniting-art-and-innovation-vieunite-joins-forces-with-rbsa-gallery-for-milestone-partnership/"
    ]
    const ScrollInactive = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="4" viewBox="0 0 30 4" fill="none">
            <path d="M2 2H28" stroke="#D9D9D9" stroke-width="3" stroke-linecap="round"/>
        </svg>
    );

    const ScrollActive = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="4" viewBox="0 0 30 4" fill="none">
            <path d="M2 2H28" stroke="#717171" strokeWidth="3" strokeLinecap="round"/>
        </svg>
    );

    const [currentIndex, setCurrentIndex] = useState(0);
    // const [percent, setPercent] = useState(0);
    const [showTitle, setShowTitle] = useState(true);
    const [brightness, setBrightness] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [shiftX, setShiftX] = useState(0);
    const [shiftY, setShiftY] = useState(0);
    const [scale, setScale] = useState(1);
    const [visible, setVisible] = useState(true);

    const handleScroll = () => {
        let scroll = {
            x: document.documentElement.scrollLeft,
            y: document.documentElement.scrollTop
        };
        const vh = window.innerHeight;
        const vw = window.innerHeight;

        const scrollLength = Math.max(scroll.y / vh, 0)

        setVisible(scrollLength < 1);

        const percentage = Math.min(scrollLength, 1)
        // setPercent(percentage)
        setShowTitle(percentage < 0.12);
        setBrightness(percentage > 0.4 ? 0.7 : 1);

        setRotate(-30 * percentage);
        setShiftY(11 * percentage);
        setShiftX(-32 * percentage);
        setScale(2.5 * percentage + 1);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div style={{marginTop: '60px'}}>
            {/*<img src={landing1} style={{width: '100%', height: 'auto'}} alt="Landing1"/>*/}
            {/*<img src={landing2} style={{width: '100%', height: 'auto'}} alt="Landing2"/>*/}

            <div className={'landingVideoContainer'}>
                <video
                    playsInline
                    loop
                    autoPlay
                    muted
                    rel={"preload"}
                    className={'landingVideoImg'}
                    style={{transition: 'filter 2s ease', filter: `brightness(${brightness})`}}
                >
                    <source src={landing_video} type={'video/mp4'}></source>
                </video>

                {/*<img className={'landingVideoImg'}*/}
                {/*     style={{transition: 'filter 2s ease', filter: `brightness(${brightness})`}} src={landing}/>*/}
                <img className={'landingVideoImg'} style={{
                    opacity: visible ? 1 : 0,
                    transform: `rotate(${rotate}deg) translateX(${shiftX}vw) scale(${scale}) translateY(${shiftY}vh)`,
                    // transition: 'transform 0.2s ease'
                }} src={cover}/>
                {/*translateX(${(100 / 5) * percent}vw) translateY(${(-200 / 8) * percent}vh) scale(${4 * percent + 1})*/}

                <AnimatePresence mode="wait">
                    {showTitle && (
                        <motion.div
                            key={showTitle}
                            initial={{y: -40, opacity: 0}}
                            animate={{y: 0, opacity: 1}}
                            exit={{y: 40, opacity: 0}}
                            transition={{duration: 0.5}}
                        >
                            <div style={{
                                backgroundColor: 'transparent',
                                color: 'white',
                                position: 'absolute',
                                width: '100%',
                                height: '100vh',
                                display: 'flex',
                                flexDirection: 'column-reverse',
                                alignItems: 'center'
                            }}>
                                <div style={{height: '6rem'}}></div>
                                <div>
                                    <p className={'landingTitle'} style={{textAlign: 'center', fontSize: '3rem'}}>
                                        Inspiring Connection, Uniting People.
                                    </p>
                                </div>
                                <div className={'spacer'}></div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            <div style={{
                position: "relative",
                width: '100vw',
                height: '190vh',
                display: 'flex',
                color: 'white',
                flexDirection: 'column',
                backgroundColor: 'transparent',
                justifyContent: 'center'
            }}>
                <p className={'landingDescriptionMobile'}>At Vieunite, we make art accessible through our innovative Textura
                    digital canvas, offering a diverse collection that enriches any space. Our mission is to
                    revolutionise
                    how people and businesses experience
                    art, making it an integral part of daily life.</p>
            </div>

            <div style={{padding: '50px 30px', backgroundColor: '#F0F0F0', position: "relative",}}>
                <img src={landingBackground1} style={{width: '100%', height: 'auto', paddingBottom: '40px'}}
                     alt="background1"/>
                <img style={{paddingBottom: '15px'}} src={home_logo} alt="home_logo"/>
                <p className={'landingMobileText'}>Allow us to bring art into your life like never before. Access
                    millions of pounds worth of masterpieces, faithfully replicated using specialised texture-accurate
                    display technology, all from
                    the comfort of your own home.</p>
                <button
                    className="homeMainButton black-border-hover-white"
                    onClick={_ => window.location.href = '/home'}
                    style={{width: "100%"}}>
                    <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                        EXPLORE VIEUNITE HOME
                    </p>
                </button>
            </div>
            <div style={{padding: '50px 30px', backgroundColor: '#F0F0F0', position: "relative",}}>
                <img src={landingBackground2} style={{width: '100%', height: 'auto', paddingBottom: '40px'}}
                     alt="background1"/>
                <img style={{paddingBottom: '15px'}} src={pro_logo} alt="home_logo"/>
                <p className={'landingMobileText'}>Transform your business space with unparalleled artistry and
                    innovation. Access an extensive library
                    of masterpieces and expertly curated art collections, showcased on our advanced texture-accurate
                    Textura digital canvases. Capture the attention of customers and staff alike with exquisite artwork
                    then convert this engagement with promotional or internal communication messaging.</p>
                <button
                    className="homeMainButton black-border-hover-white"
                    onClick={_ => window.location.href = '/business/home'}
                    style={{width: "100%"}}>
                    <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                        EXPLORE VIEUNITE PRO
                    </p>
                </button>
            </div>
            <div style={{padding: '50px 30px 5px 30px', backgroundColor: '#F0F0F0', position: "relative",}}>
                <p className={'landingMobileTitle landingCentralText'}>Unlock Timeless Artworks and Creations</p>
                <p className={'landingMobileText landingCentralText'}>Through strategic partnerships with prestigious
                    art galleries worldwide, Vieunite brings you exclusive access to curated collections of iconic
                    artworks. Each partnership represents our commitment to delivering unparalleled cultural richness
                    and aesthetic value to your space. Explore our gallery collaborations and unlock a world of artistic
                    excellence.</p>
            </div>
            <div style={{backgroundColor: '#F0F0F0', padding: '2vw', position: "relative",}}>
                {demoArts.map((obj, index) =>
                    <img className={'landingCreationsMobileImg'} src={obj.img} alt={index}/>
                )}
            </div>
            <div style={{backgroundColor: '#F0F0F0', height: '70px', position: "relative",}}>
            </div>

            <div style={{padding: '50px 8.6vw', backgroundColor: '#DFDFDF', position: "relative",}}>
                <img src={innovating_cover} style={{width: '100%', height: 'auto', paddingBottom: '20px'}}
                     alt="innovating icon"/>
                <p className={'landingMobileTitle'} style={{marginBottom: '20px'}}>Innovating for Tomorrow</p>
                <p className={'landingMobileText'}>At Vieunite, we're not just about digital art frames and online
                    galleries; we actively engage in academic and R&D initiatives that drive innovation at the
                    intersection of art and technology. Our expansive library showcases timeless masterpieces and
                    emerging talents. Beyond this, Vieutopia, our AI-powered artwork creation platform, democratises
                    creativity for everyone. We actively collaborate on ground-breaking academic research, such as our
                    KTP project with Nottingham Trent University, exploring the therapeutic benefits of biophilic
                    imagery and video. Join us in shaping the future of arttech. Stay informed through our News Page and
                    social media accounts for the latest updates and community contributions.</p>
                <button
                    className="homeMainButton black-border-hover-white"
                    onClick={_ => window.location.href = '/research'}
                    style={{width: "100%"}}>
                    <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                        LEARN MORE
                    </p>
                </button>
            </div>

            <div style={{backgroundColor: '#F0F0F0', position: "relative",}}>
                <p className={'landingMobileTitle'} style={{padding: '50px 8.5vw 0 8.5vw'}}>Vieunite in Action</p>

                <Carousel
                    dots={true}
                    dotPosition={"bottom"}
                    autoplay={false}
                    afterChange={(current) => {
                        setCurrentIndex(current)
                    }}
                >
                    {action_list.map((item, index) =>
                        <div>
                            <div style={{
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                overflow: 'hidden',
                                margin: '0 8.6vw'
                            }}>
                                <img src={item} style={{width: '100%', height: 'auto'}}/>
                                <div style={{
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '19rem'
                                }}>
                                    <p className={'landingMobileTitle'}
                                       style={{marginBottom: '10px'}}>{action1_titles[index]}</p>
                                    <p className={'landingMobileText'}>{action1_bodies[index]}</p>
                                    <div className={'spacer'}></div>
                                    <button
                                        className="homeMainButton black-border-hover-white"
                                        onClick={_ =>
                                            window.open(action1_links[index], '_blank')
                                        }
                                        style={{width: "100%"}}>
                                        <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                            LEARN MORE
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </Carousel>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '20px'}}>
                    {action_list.map((item, index) =>
                        <div key={index} style={{margin: '0 2px'}}>
                            {currentIndex === index ? <ScrollActive/> : <ScrollInactive/>}
                        </div>
                    )}
                </div>

            </div>

            <div style={{padding: '70px 30px', backgroundColor: '#F0F0F0', position: "relative",}}>
                <p className={'landingMobileTitle landingCentralText'}>Embark on Your Vieunite Journey</p>
                <p className={'landingMobileText landingCentralText'}>At Vieunite, we’re driven by a vision to make
                    inspirational art accessible to everyone. Whether at home or in business, our goal is to transform
                    the world through the fusion of art and technology. Join us on this remarkable journey and help us
                    shape a brighter, more beautiful future. Start your Vieunite journey today by reaching out to us.
                </p>
                <button
                    className="homeMainButton black-border-hover-white"
                    onClick={_ => window.location.href = '/contact'}
                    style={{width: "100%"}}>
                    <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                        CONTACT US
                    </p>
                </button>
            </div>
        </div>
    )
}

export default Landing;