export const initialState = {
    colors : [],
}
export const reducer = (draft, action) => {
    switch (action.type) {
        case 'add_color':
            draft.colors = action.colors;
            break;
        default:
            break;
    }
}