import React from 'react';
import {Layout, Row, Col, Button, Divider, Tooltip, Collapse} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import {FeatureText, QAText} from "./text";
import {ReactComponent as TickIcon} from "./Tick icon.svg"
import {ReactComponent as InfoIcon} from "./Information icon.svg"
import {PlusOutlined} from "@ant-design/icons";
import {PRODUCT_TEXT} from "../../text";
import unlock_img from './Cafe_01 1.png'
import {advancedList, detailList, mDetailList, premiumList, standardList} from "../Shopping/text";
import {useNavigate} from "react-router-dom";
import {ProSubMeta} from "./meta";
import useWindowResize from "../../apps/Hooks/useWindowResize";

const {Panel} = Collapse;


const ProSubPage = (props) => {
    const navigate = useNavigate()
    const {windowWidth} = useWindowResize()
    return (
        <div>
            <ProSubMeta/>
            <Layout>
                {windowWidth > 600 && <Content>
                    <Row className={'pro-bg-color sub-head-row'} justify={'center'}>
                        <p className={'sub-title'}>Pick the plan that’s right for you</p>
                        <p className={'sub-txt'}>
                            Start your Vieunite Pro journey with plans tailored for businesses of all sizes.
                        </p>
                        <p className={'sub-txt'} style={{margin: '-3vh 0 8vh 0'}}>
                            If you are unsure of the right plan, you can call us on 03455-481311
                        </p>
                    </Row>
                    <Row className={'pro-bg-color'}
                         justify={"center"}
                         style={{
                             columnGap: '2rem',
                             padding: '0 0 3rem 0'
                         }}
                    >
                        <Col className={'sub-option v2-shop-package-col'}>
                            <p className={'sub-opt-title'} style={{marginBottom: '6.8vh'}}>Package details</p>
                            {/*<p className={'sub-opt-per-year'} style={{color: 'white'}}>p</p>*/}
                            {/*<p className={'sub-opt-per-year'} style={{color: 'white'}}>p</p>*/}
                            <div className={'shop-divide-line-top-dt'}/>
                            {detailList.map((txt, idx) => {
                                return (
                                    <div>
                                        <div className={'sub-dt-div'}>
                                            <span className={'sub-dt-inner'}>{txt[0]}</span>
                                            <Tooltip title={txt[1]}><InfoIcon/></Tooltip>
                                        </div>
                                        <div className={'shop-divide-line-dt'}/>
                                    </div>
                                )
                            })}
                        </Col>
                        <Col className={'sub-option'}
                        >
                            <p className={'sub-opt-title'}>Standard</p>
                            <p className={'sub-opt-subtitle'}>Free</p>
                            <p className={'sub-opt-per-year'} style={{color: 'white'}}>per device annually</p>
                            <div className={'shop-divide-line-top'}/>
                            {standardList.map((txt, idx) => {
                                return (
                                    <div>
                                        <div className={'sub-features-div'}>
                                            <p className={'sub-features-inner'}>{txt}</p>
                                        </div>
                                        <div className={'shop-divide-line'}/>
                                    </div>
                                )
                            })}
                            <Button className={'shop-sub-opt-btn'}
                                    onClick={() => {
                                        window.location.href = '/business/explore'
                                    }}
                            >
                                Get started
                            </Button>
                        </Col>
                        <Col className={'sub-option'}
                        >
                            <p className={'sub-opt-title'}>Advanced</p>
                            <p className={'sub-opt-subtitle'}>£99.00(ex VAT)</p>
                            <p className={'sub-opt-per-year'}>per device annually</p>
                            <div className={'shop-divide-line-top'}/>
                            {advancedList.map((txt, idx) => {
                                return (
                                    <div>
                                        <div className={'sub-features-div'}>
                                            <p className={'sub-features-inner'}>{txt}</p>
                                        </div>
                                        <div className={'shop-divide-line'}/>
                                    </div>
                                )
                            })}
                            <Button className={'shop-sub-opt-btn'}
                                    onClick={() => {
                                        window.location.href = '/business/explore'
                                    }}
                            >Get started</Button>
                        </Col>
                        <Col className={'sub-option'}
                        >
                            <p className={'sub-opt-title'}>Premium</p>
                            <p className={'sub-opt-subtitle'}>£189.00 (ex VAT)</p>
                            <p className={'sub-opt-per-year'}>per device annually</p>
                            <div className={'shop-divide-line-top'}/>
                            {premiumList.map((txt, idx) => {
                                return (
                                    <div>
                                        <div className={'sub-features-div'}>
                                            <p className={'sub-features-inner'}>{txt}</p>
                                        </div>
                                        <div className={'shop-divide-line'}/>
                                    </div>
                                )
                            })}
                            <Button className={'shop-sub-opt-btn'}
                                    onClick={() => {
                                        window.location.href = '/business/explore'
                                    }}
                            >Get started</Button>
                        </Col>
                    </Row>
                    <Row justify={"center"} style={{
                        backgroundColor: '#f4f4f4',
                        padding: '0 0 2rem 0'
                    }}>
                        <p className={'sub-text-under'}>
                            When choosing the Vieunite Pro Advanced package, a 12-month subscription fee of £99.00(ex
                            VAT)
                            will be
                            charged at checkout. Your subscription is per Textura and will automatically renew every 12
                            months until you wish to opt out. Plus, with the Advanced and Premium package, you can enjoy
                            a
                            30-day trial
                            period to experience the benefits of our services before fully committing. Please check
                            our <span
                            onClick={_ =>
                                window.open('https://vieunite.com/proportal/ProPaymentAgreement', '_blank')
                            }
                            style={{
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}>
                        Terms and Conditions
                    </span> for more
                            details.
                        </p>
                    </Row>
                    <Row justify={"center"} style={{
                        // margin:'8rem 0 8rem 0',
                        backgroundColor: '#f4f4f4',
                    }}>
                        <Col lg={12}
                             style={{
                                 backgroundColor: "white",
                                 display: "flex",
                                 flexDirection: "column",
                                 alignItems: 'flex-end',
                                 justifyContent: "center",
                             }}
                        >
                            <p className={'z-h1'}>Unlock a World of Art</p>
                            <p className={'z-txt'}>
                                Vieunite Pro provides businesses a curated<br/>
                                selection of artwork via a user-friendly portal.
                            </p>
                            <Button
                                className={'learn-more-btn pro-btn-hover-to-white'}
                                style={{}}
                                onClick={_ => {
                                    if (localStorage.getItem('token')) {
                                        window.location.href = '/proportal/discover-art'
                                    } else {
                                        navigate('/business/prosignin', {state: {nextPage: '/proportal/discover-art'}})
                                    }
                                }}
                            >
                                Learn More
                            </Button>
                        </Col>
                        <Col lg={12}>
                            <img alt={'unlock'} src={unlock_img}/>
                        </Col>
                    </Row>
                    <Row justify={"center"} style={{
                        backgroundColor: '#f4f4f4',
                        color: '#f4f4f4',
                        border: "none",
                        paddingTop: '8rem'
                    }}>
                        <Col span={20} lg={20} md={20}
                             style={{
                                 backgroundColor: '#f4f4f4',
                                 color: '#f4f4f4',
                                 border: "none",
                                 margin: '-4rem 0 -3rem 2rem'
                             }}
                        >
                            <p className={'qa-title'}>Frequently Asked Questions</p>
                        </Col>
                    </Row>
                    <Row justify={"center"}
                         style={{
                             backgroundColor: '#f4f4f4',
                         }}>
                        <Col span={20} lg={20} md={20}>
                            <Collapse expandIconPosition={"end"}
                                      bordered={false}
                                      style={{
                                          backgroundColor: '#f4f4f4',
                                          color: '#f4f4f4',
                                          border: "none"
                                      }}
                                      expandIcon={() => <PlusOutlined style={{
                                          fontSize: '24px', fontWeight: "900",
                                      }}/>}
                            >
                                {QAText.map((txt, idx) => {
                                    return (
                                        <Panel header={txt[0]}
                                               style={{
                                                   fontSize: '24px', fontWeight: "600",
                                               }}
                                               key={idx}>
                                            <p>{txt[1]}</p>
                                        </Panel>
                                    )
                                })}
                            </Collapse>
                        </Col>
                    </Row>
                </Content>}
                {windowWidth <= 600 && <Content>
                    <Row className={'pro-bg-color sub-head-row'} justify={'center'}>
                        <p className={'sub-title'}
                           style={{width: '80vw', fontSize: '8vw'}}>
                            Pick the plan that’s right for you</p>
                        <p className={'sub-txt'} style={{margin: '-3vh 0 8vh 0', width: '80vw'}}>
                            If you are unsure of the right plan, you can call us on 03455-481311
                        </p>
                    </Row>
                    <Row className={'pro-bg-color'}
                         justify={"center"}
                         style={{
                             columnGap: '2rem',
                             padding: '0 0 3rem 0'
                         }}
                    >
                        <Col className={'m-sub-option'}
                        >
                            <p className={'m-sub-opt-title'}>Standard</p>
                            <p className={'m-sub-opt-subtitle'}>Free</p>
                            <p className={'m-sub-opt-per-year'} style={{color: 'white'}}>per device annually</p>
                            <div className={'m-shop-divide-line-top'}/>
                            {standardList.map((txt, idx) => {
                                return (
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        justifyContent: "center",
                                        alignItems: 'center'
                                    }}>
                                        <p className={'m-sub-features-inner'} style={{
                                            margin: '1vh 0 0 0',
                                            textAlign: "center",
                                            width: '90vw'
                                        }}>{mDetailList[idx]}</p>
                                        <p className={'m-sub-features-inner'} style={{
                                            margin: '0.2vh 0 1vh 0', fontWeight: '700'
                                        }}>{txt}</p>
                                        <div className={'m-shop-divide-line'}/>
                                    </div>
                                )
                            })}
                            <Button className={'m-shop-sub-opt-btn'}
                                    onClick={() => {
                                        window.location.href = '/business/explore'
                                    }}
                            >
                                Get started
                            </Button>
                        </Col>
                        <Col className={'m-sub-option'}
                        >
                            <p className={'m-sub-opt-title'}>Advanced</p>
                            <p className={'m-sub-opt-subtitle'}>£99.00(ex VAT)</p>
                            <p className={'m-sub-opt-per-year'}>per device annually</p>
                            <div className={'m-shop-divide-line-top'}/>
                            {advancedList.map((txt, idx) => {
                                return (
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        justifyContent: "center",
                                        alignItems: 'center'
                                    }}>
                                        <p className={'m-sub-features-inner'} style={{
                                            margin: '1vh 0 0 0',
                                            textAlign: "center",
                                            width: '90vw'
                                        }}>{mDetailList[idx]}</p>
                                        <p className={'m-sub-features-inner'} style={{
                                            margin: '0.2vh 0 1vh 0', fontWeight: '700'
                                        }}>{txt}</p>
                                        <div className={'m-shop-divide-line'}/>
                                    </div>
                                )
                            })}
                            <Button className={'m-shop-sub-opt-btn'}
                                    onClick={() => {
                                        window.location.href = '/business/explore'
                                    }}
                            >Get started</Button>
                        </Col>
                        <Col className={'m-sub-option'}
                        >
                            <p className={'m-sub-opt-title'}>Premium</p>
                            <p className={'m-sub-opt-subtitle'}>£189.00 (ex VAT)</p>
                            <p className={'m-sub-opt-per-year'}>per device annually</p>
                            <div className={'m-shop-divide-line-top'}/>
                            {premiumList.map((txt, idx) => {
                                return (<div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        justifyContent: "center",
                                        alignItems: 'center'
                                    }}>
                                        <p className={'m-sub-features-inner'} style={{
                                            margin: '1vh 0 0 0',
                                            textAlign: "center",
                                            width: '90vw'
                                        }}>{mDetailList[idx]}</p>
                                        <p className={'m-sub-features-inner'} style={{
                                            margin: '0.2vh 0 1vh 0', fontWeight: '700'
                                        }}>{txt}</p>
                                        <div className={'m-shop-divide-line'}/>
                                    </div>
                                )
                            })}
                            <Button className={'m-shop-sub-opt-btn'}
                                    onClick={() => {
                                        window.location.href = '/business/explore'
                                    }}
                            >Get started</Button>
                        </Col>
                    </Row>


                    <Row justify={"center"} style={{
                        backgroundColor: '#f4f4f4',
                        padding: '0 0 2rem 0'
                    }}>
                        <p className={'m-sub-text-under'}>
                            When choosing the Vieunite Pro Advanced package, a 12-month subscription fee of £99.00(ex
                            VAT)
                            will be
                            charged at checkout. Your subscription is per Textura and will automatically renew every 12
                            months until you wish to opt out. Plus, with the Advanced and Premium package, you can enjoy
                            a
                            30-day trial
                            period to experience the benefits of our services before fully committing. Please check
                            our <span
                            onClick={_ =>
                                window.open('https://vieunite.com/proportal/ProPaymentAgreement', '_blank')
                            }
                            style={{
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}>
                        Terms and Conditions
                    </span> for more
                            details.
                        </p>
                    </Row>
                    <Col lg={24}>
                        <img alt={'unlock'} src={unlock_img}/>
                    </Col>

                    <Row justify={"center"} style={{
                        // margin:'8rem 0 8rem 0',
                        background: '#F4F4F4'
                    }}>
                        <Col
                            style={{
                                background: '#F4F4F4',
                                display: "flex",
                                flexDirection: "column",
                                alignItems: 'center',
                                justifyContent: "center",
                                width: '100vw'
                            }}
                        >
                            <p className={'m-z-h1'}>Unlock a World of Art</p>
                            <p className={'m-z-txt'}>
                                Vieunite Pro provides businesses a curated
                                selection of artwork via a user-friendly portal.
                            </p>
                            <Button
                                className={'learn-more-btn pro-btn-hover-to-white'}
                                style={{height: '6vh', width: '50vw', margin: '2vh auto', fontSize: '6vw'}}
                                onClick={_ => {
                                    window.location.href = '/business/explore'
                                }}
                            >
                                Learn More
                            </Button>
                        </Col>

                    </Row>
                    <Row justify={"center"} style={{
                        backgroundColor: '#f4f4f4',
                        color: '#f4f4f4',
                        border: "none",
                        paddingTop: '8rem'
                    }}>
                        <Col
                            style={{
                                backgroundColor: '#f4f4f4',
                                color: '#f4f4f4',
                                border: "none",
                                margin: '-4rem 0 -3rem 2rem',
                                width: '100vw'
                            }}
                        >
                            <p className={'m-qa-title'}>Frequently Asked Questions</p>
                        </Col>
                    </Row>
                    <Row justify={"center"}
                         style={{
                             backgroundColor: '#f4f4f4',
                         }}>
                        <Col span={20} lg={20} md={20} className={'m-qa-col'}>
                            <Collapse expandIconPosition={"end"}
                                      bordered={false}
                                      style={{
                                          backgroundColor: '#f4f4f4',
                                          color: '#f4f4f4',
                                          border: "none"
                                      }}
                                      expandIcon={() => <PlusOutlined style={{
                                          fontSize: '24px', fontWeight: "900",
                                      }}/>}
                            >
                                {QAText.map((txt, idx) => {
                                    return (
                                        <Panel header={txt[0]}
                                               style={{
                                                   fontSize: '24px', fontWeight: "600",
                                               }}
                                               key={idx}>
                                            <p className={'m-qa-questions'}>{txt[1]}</p>
                                        </Panel>
                                    )
                                })}
                            </Collapse>
                        </Col>
                    </Row>
                </Content>}
            </Layout>
        </div>
    )
}

export default ProSubPage;
