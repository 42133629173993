export const imagesRule=[
    ["Container (file format)","JPEG"],
    ["Compression","Baseline"],
    ["Colour Mode","RGB"],
    ["Width","1920 (1080 for portrait)"],
    ["Height","1080 (1920 for portrait)"],
    ["Aspect Ratio","16:9 (9:16 for portrait)"],
]

export const videosRule=[
    ["Container (file format)","MP4, AVI or MPG"],
    ["Compression","MPEG-1, MPEG-2, MPEG-4, XVID or H.264"],
    ["Width","1920 (1080 for portrait)"],
    ["Height","1080 (1920 for portrait)"],
    ["Aspect Ratio","16:9 (9:16 for portrait)"],
]