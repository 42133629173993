import './AlbumCoverLayout.css';

import left from './images/left.png'
import upRight from './images/upRight.png'
import downRight from './images/downRight.png'

const AlbumCoverLayout = (props) => {
    return (
        <div className="container" style={ props.style }>
            <div className="left">
                <img src={ props.item.left || left} alt="Left" />
            </div>
            <div className="right">
                <img src={ props.item.upRight || upRight} alt="Top Right" />
                <img src={ props.item.downRight || downRight} alt="Bottom Right" />
            </div>
        </div>
    );
}

export default AlbumCoverLayout;