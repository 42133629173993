import React, {useEffect, useState} from 'react';
import {Button, Col, Modal, Row} from 'antd';
import {Primary_Font} from "../../../../../settings";
import {PortalHomeTxt} from "../../text";
import {useNavigate} from "react-router-dom";
import useWindowResize from "../../../../Hooks/useWindowResize";
import {getBalance} from "../../../BalanceSystem/BalanceBoard/service";

const BalanceCard = (props) => {
    const navigate = useNavigate();
    const {windowWidth} = useWindowResize()
    const {windowHeight} = useWindowResize()
    const [finance, setFinance] = useState({})
    useEffect(() => {
        getBalance().then((res) => {
            console.log(res)
            setFinance({...res})
        })
    }, []);
    return (
        <div className="small-card" style={{
            overflow: 'hidden',
            fontSize: windowWidth > 600 ? windowWidth / 100 * 0.65 : windowWidth / 100 * 3.5
        }}>
            <p className={'card-title'} style={{
                fontSize: windowWidth > 600 ? windowWidth / 100 * 1.6 : windowWidth / 100 * 8.,
                marginBottom: windowHeight / 100 * 3.2
            }}>
                Vieunite <span style={{fontWeight: '600'}}>Balance</span>.
            </p>
            <Row justify={"center"}>
                <Col span={10} style={{
                    display: 'flex',
                    margin: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <p style={{
                        fontSize: '3rem',
                        color: 'black',
                        textDecoration: "underline",
                        textDecorationThickness: '0.18rem',
                    }}>
                        {"£" + parseFloat(finance['balance']).toString() ?? 0}
                    </p>
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col span={24}>
                    <p style={{
                        color: "black",
                        ...Primary_Font,
                        // fontSize: '1rem',
                        fontWeight: '600',
                        cursor: 'pointer',
                    }}
                       onClick={() =>
                           navigate("/artistportal/balance", {
                               state: {}
                           })
                       }
                    >
                        Manage Account >
                    </p>
                </Col>
            </Row>
        </div>
    )
}

export default BalanceCard;