import React from 'react';
import './TermsEnd.css';
import {END_TERM} from "../../text";


function TitleLogo() {
    return (
        <h2 className="title-app" style={{
            textAlign:"left",
            fontSize:'1.8rem',
            marginTop:'12vh'
        }}>End User Agreement</h2>
    );
}

function Announcement(props) {
    return (
        <div className="content" style={{marginTop:'0.5rem',fontSize:'9px'}}>
                {props.text}
        </div>
    );
}

function SubTitle(props) {
    return (
        <div id={props.id} className="sub_title">{props.title}</div>
    );
}

function SubSubTitle(props) {
    return (
        <div className="sub_sub_title">{props.title}</div>
    );
}

function Description(props) {
    let content = props.content.slice();
    let output;
    output = content.map((item) => (
        <div className="content">
            <span style={{fontWeight: "bold"}}>
                {item[0]}
            </span>
            {item[1]}
        </div>
    ));
    output = output.concat(
        <div className="content">
            Where this Agreement refers to
            <span style={{fontWeight: "bold"}}> “you” </span>
            or
            <span style={{fontWeight: "bold"}}> “your” </span>
            it means the individual accepting this agreement; where it refers to
            <span style={{fontWeight: "bold"}}> “us” </span>
            ,
            <span style={{fontWeight: "bold"}}> “our” </span>
            or
            <span style={{fontWeight: "bold"}}> “we” </span>
            it means Vieunite.
        </div>);
    return (
        output
    );
}

function Content(props) {
    return (
        <div className="content">{props.content}</div>
    );
}



function Agreed(props) {
    let total = props.content.slice();
    let output;
    output = total.map((item) => (
        <>
            <SubSubTitle title={item[0]}/>
            <AllContent content={item.slice(1, item.length)}/>
        </>
    ));
    return (
        output
    );
}


function AllContent(props) {
    let content = props.content.slice();
    let output;
    if (typeof content === 'string') {
        output = <Content content={content}/>;
    } else {
        output = content.map((item) => (
            typeof item === 'string' ? <Content content={item}/> :
                <ul className="content">{item.map((item) => <li>{item}</li>)}</ul>
        ));
    }

    return (
        output
    );
}




function TermsAndConditions() {
    return (
        <div className="whole_div">
            <TitleLogo/>
            <Announcement text={END_TERM.ANNOUNCEMENT}/>
            <SubTitle id="IMPORTANT-WORDS-AND-DEFINITIONS" title="IMPORTANT WORDS AND DEFINITIONS"/>
            <Description content={END_TERM.Definitions_and_Interpretation}/>
            <SubTitle id="Agreed-terms" title="Agreed terms"/>
            <Agreed content={END_TERM.Agreed_term}/>
        </div>
    )
        ;

}

export default TermsAndConditions;