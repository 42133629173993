import React from 'react';

const Submit = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '150px' }}>
            <p style={{ fontWeight: 'bold', fontSize: '24px' }}>Thank You for Your Participation</p>
            <p>
                Please keep your participant code saved, as you will need it in case you decide to withdraw your participation.
                For further queries on the project or to withdraw your participation contact:
                <a href="mailto:purna.kar@ntu.ac.uk">purna.kar@ntu.ac.uk</a>.
            </p>
            <p>You can now close this browser.</p>
        </div>
    );
};

export default Submit;
