import {APP_API_URL} from "../../../../settings";

export const uploadAvatar = (file, setAvatarUrl) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append("files", file, file.name);
    console.log(file.name)
    const req = {
        method: 'POST',
        headers: {"X-Access-Token": token},
        body: formData,
    };
    fetch(APP_API_URL + `/photoframe-mobile/system/tools/uploadPic`,
        req)
        .then(res => res.json())
        .then(data => {
            console.log(data.result)
            if (data.result[0]) {
                setAvatarUrl(data.result[0].replace(':8082', '').replace('http:', 'https:'))
                console.log(data.result[0])
            } else {
                throw Error
            }
        })
        .catch(error => console.log('error', error));
}
export const profileUpdateAll = (uploadData, navigate) => {
    const token = localStorage.getItem('token');
    const req = {
        method: 'POST',
        headers: {"X-Access-Token": token, 'Content-Type': 'application/json'},
        body: JSON.stringify(uploadData),
    };
    fetch(APP_API_URL + `/photoframe-mobile/system/pframeUser/editUserInfo`,
        req)
        .then(res => res.json())
        .then(data => {
            if (data.code === 200) {
                navigate("/artistportal/home")
            } else {
                throw Error
            }
        })
        .catch(error => console.log('error', error));
}