import {Button, Form, Input, Row} from "antd";
import {Primary_Font, TitleFont} from "../../../settings";
import {getVerificationCode, resetPassword} from "../services";
import {MailOutlined} from "@ant-design/icons";
import React from "react";
import VerificationInput from "react-verification-input";
import "./style.scss"
import {useNavigate} from "react-router-dom";

export const InputNewPassword = (props) => {
    const {email, code} = props
    const navigate=useNavigate()
    return (
        <div>
            <Row justify={"center"}>
                <h2 className="title-app"
                    style={window.outerWidth >= 600 ?
                        {textAlign: "center", marginTop: '-2%', ...TitleFont,} :
                        {textAlign: "left", ...TitleFont,}
                    }>
                    <span style={{fontWeight: '300', fontSize: '2rem'}}>Update Password</span>
                </h2>
            </Row>
            <Row justify={"center"}>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={(values) => {
                        if (values.new_pd === values.confirm_pd) {
                            const reqInput = {
                                email: email,
                                verificationCode: code,
                                password: values.new_pd,
                                confirmPassWord: values.confirm_pd,
                            }
                            resetPassword(reqInput)
                                .then(() => {
                                    alert('Success!')
                                    navigate("/artistportal/login")
                                })
                                .catch(error => {
                                    console.log('error', error);
                                })
                        } else {
                            alert("Please ensure the input passwords are the same!")
                        }
                    }
                    }
                    style={window.outerWidth >= 600 ? {width: '50%'} : {width: '90%'}}
                >
                    <Form.Item
                        labelCol={{span:24, style:{ fontWeight:'600'}}}
                        name="new_pd"
                        label={"New Password"}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your new password!',
                            },
                            {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                                message: 'Password must contain at least 8 characters, including 1 lowercase letter, 1 uppercase letter, and 1 digit.',
                            },
                        ]}
                    >
                        <Input className={'login-input-form'}
                               placeholder="Password"/>
                    </Form.Item>
                    <Form.Item
                        labelCol={{span:24, style:{ fontWeight:'600'}}}
                        name="confirm_pd"
                        label={"Confirm New Password"}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your new password again!',
                            },
                        ]}
                    >
                        <Input className={'login-input-form'}
                               placeholder="Password"/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" className="login-form-button">
                            CONFIRM PASSWORD
                        </Button>
                    </Form.Item>
                </Form>
            </Row>
        </div>
    )
}