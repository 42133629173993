import React, {useEffect} from 'react';
import "./SupportMain.scss"
import {Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import square1 from "./img/prohelp1square.png"
import square2 from "./img/prohelp2square.png"
import square3 from "./img/prohelp3square.png"
import square4 from "./img/prohelp4square.png"
import img1 from "../ProCaseStudy/img/Employee 1.png";
import useWindowResize from "../../apps/Hooks/useWindowResize";

function SupportMain() {
    const {windowWidth} = useWindowResize()
    return (<div>
            <Layout>
                <Content style={{fontFamily: "Proxima Nova, sans-serif"}}>
                    <div className="pro-help-center-top-section">
                        <div className="pro-help-center-text-content">
                            <h2 className={'pro-help-center-subtitle'}>Vieunite Pro
                                Support</h2>
                            <h3 className={'pro-help-center-title'}>How Can We
                                Help?</h3>
                        </div>
                    </div>

                    <div className="pro-help-center-options-section">
                        <div className={'pro-help-center-option-card-box'} onClick={() => {
                            window.location.href = '/pro/support/portal'
                        }}>
                            <img src={square3} className={'pro-help-center-option-card'}/>
                        </div>
                        <div className={'pro-help-center-option-card-box'} onClick={() => {
                            window.location.href = '/pro/support/textura'
                        }}>
                            <img src={square4} className={'pro-help-center-option-card'}/>
                        </div>
                    </div>

                    <Row className="custom-gutter-row-support">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div className="pro-help-center-support-bottom-section">
                                <p className="homeSection1TitleText" style={{textAlign: "center"}}>Request Support</p>
                                <p className="homeSupportBody">If you have a question that is not answered here, or you
                                    are
                                    having
                                    any issues with using your Textura digital canvas, please get in touch with us today
                                    via
                                    our
                                    support form
                                </p>
                                <button className="homeSupportButton" onClick={() => {
                                    window.location.href = '/pro/support/request-support'
                                }}>
                                    <p className={'homeSupportButtonLnk homeOverV'} style={{margin: 0}}>
                                        SUPPORT FORM
                                    </p>
                                </button>
                            </div>
                        </Col>
                    </Row>

                    <Row justify={'center'} style={{margin: '-8vh auto 4vh auto'}}>
                        <Col style={{
                            paddingTop: '2vh',
                            paddingBottom: '2vh',
                            marginLeft: '1vw',
                            paddingLeft: '4vw',
                            paddingRight: '4vw',
                            width: windowWidth > 920 ? '40vw' : '80vw',
                            // height: windowWidth > 920 ? '15vw' : '60vw',
                            borderRadius: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            background: 'rgba(42, 150, 21, 0.08)',
                            marginTop: windowWidth > 920 ? '0' : '3vh'
                        }}>
                            <p className={'pro-support-section-p'} style={{width: windowWidth > 920 ? '23vw' : '60vw'}}>
                                Discover how Vieunite Pro can redefine your business.
                            </p>
                            <button
                                className="homeMainButton"
                                style={{
                                    width: windowWidth > 920 ? 'auto' : '100%',
                                    minWidth: "200px",
                                    marginTop: '-1vh',
                                }}
                                onClick={_ => window.location.href = "/pro/support/request-demo"}
                            >
                                <a className={'homeMainButtonLnk homeOverV'}>
                                    REQUEST DEMO
                                </a>
                            </button>
                        </Col>

                        <Col style={{
                            marginLeft: '2vw',
                            paddingTop: '2vh',
                            paddingBottom: '2vh',
                            paddingLeft: '4vw',
                            paddingRight: '4vw',
                            width: windowWidth > 920 ? '40vw' : '80vw',
                            // height: windowWidth > 920 ? 'auto' : '60vw',
                            borderRadius: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            background: 'rgba(122, 6, 59, 0.08)',
                            marginTop: windowWidth > 920 ? '0' : '5vh',
                        }}>
                            <p className={'pro-support-section-p'} style={{width: windowWidth > 920 ? '23vw' : '60vw'}}>
                                Share the power of the Textura digital canvas with your customers.
                            </p>
                            <button
                                className="homeMainButton"
                                style={{
                                    width: windowWidth > 920 ? 'auto' : '100%',
                                    minWidth: "200px",
                                    marginTop: '-1vh'
                                }}
                                onClick={_ => window.location.href = "/pro/support/become-a-reseller"}
                            >
                                <a className={'homeMainButtonLnk homeOverV'}>
                                    BECOME A RESELLER
                                </a>
                            </button>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </div>
    )
}

export default SupportMain;