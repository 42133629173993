import React from "react";
import {Helmet} from "react-helmet";

export const A19Meta = () => (
    <Helmet>
            <title> Matthew Stickland | Vieunite</title>
            {/*<meta name="description" content="Canadian-American artist. Her paintings aim to invoke universal human experiences and emotions. Depicting figures without clear identity, her works become representations of shared humanity."/>*/}
            <meta name="keywords"
                  content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
            <meta name="twitter:card" value="summary"/>
            <meta name="twitter:site" value="@VieuniteHQ"/>
            <meta name="twitter:title" value="Vieunite Artist | Matthew Stickland"/>
            <meta name="twitter:description"
                  value="Matthew Stickland's unique artworks explore the intersection  of his Irish and Trinidadian roots. Discover his captivating pieces on Vieunite's digital art platform."/>
            <meta name="twitter:url" value="https://vieunite.com/artists/matthew-stickland"/>
            <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_matthew_stickland.jpg"/>
            <meta property="og:url" content="https://vieunite.com/artists/matthew-stickland"/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Vieunite Artist | Matthew Stickland"/>
            <meta property="og:description"
                  content="Matthew Stickland's unique artworks explore the intersection  of his Irish and Trinidadian roots. Discover his captivating pieces on Vieunite's digital art platform."/>
            <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_matthew_stickland.jpg"/>
    </Helmet>);