import React from "react";

export const DropIcon = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="13"
         height="9"
         viewBox="0 0 13 9"
         fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M11.2958 1.13021C11.6863 0.739686 12.3195 0.739686 12.71 1.13021C13.1006 1.52073 13.1006 2.1539 12.71 2.54442L9.59914 5.65532L7.90057 7.35389L7.92934 7.38266L6.51512 8.79688L0.293323 2.57508C-0.0972016 2.18455 -0.0972017 1.55139 0.293323 1.16086C0.683847 0.770338 1.31701 0.770338 1.70754 1.16086L6.48635 5.93968L8.18492 4.24111L11.2958 1.13021Z"
              fill="#0E487A"/>
    </svg>
