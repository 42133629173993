import React from 'react';
import {Form, Input, Button, Select, Row, Col, Layout} from 'antd';
import './style.scss'
import {Content} from "antd/es/layout/layout";
import {post_api} from "../../portal/serverTemp";
import {useNavigate} from "react-router-dom";

const {Option} = Select;

const ProSignUp = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const validateConfirmPassword = (_, value) => {
        const password = form.getFieldValue('password');
        if (password === value) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Passwords do not match!'));
    };

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        const post_data = {
            title: values.title,
            first_name: values.firstName,
            surname: values.lastName,
            email: values.email,
            password: values.password,
            confirm_password: values.confirmPassword,
            postcode: values.postcode ?? 'none',
            full_name: values.fullName,
            business_name: values.businessName,
            business_number: values.businessNumber,
            business_address: values.businessAddress ?? 'none',
            account_username: values.accountUsername,
            nature_of_business: 0,
        }
        post_api('/user/register', post_data).then(
            res => {
                console.log(res)
                navigate('/business/ProSignUpVerification', {state: {email: values.email}})
            }
        ).catch(error => console.error('Error:', error));
    };
    const onValuesChange = (changedValues) => {
        if (changedValues.password) {
            form.validateFields(['confirmPassword']);
        }
    };

    return (
        <Layout>
            <Content style={{background: '#F4F4F4'}}>
                <div style={{
                    marginTop: '5vw', marginBottom: '5vw',
                }}>
                    <div className="Title"
                         style={{margin: '8rem 0 4rem 0'}}
                    >
                        Vieunite Pro Business Sign Up
                    </div>
                    <Form
                        style={{marginRight: '25vw', marginLeft: '25vw'}}
                        form={form}
                        name="signup_form"
                        onFinish={onFinish}
                        onValuesChange={onValuesChange}
                        layout="horizontal"
                    >
                        <Form.Item
                            name="fullName"
                            label="Full name"
                            rules={[{required: true}]}
                            labelCol={{span: 5}}
                            wrapperCol={{span: 15}}
                        >
                            <Row gutter={16} style={{marginTop: '-30px', marginBottom: '-15px'}}>
                                <Col span={6}>
                                    <Form.Item name="title" label={'Title'}>
                                        <Select className="custom-select" allowClear
                                                style={{margin: '30px 0 0 -40px', width: '120px'}}>
                                            <Select.Option value="Mr">Mr</Select.Option>
                                            <Select.Option value="Mrs">Mrs</Select.Option>
                                            <Select.Option value="Ms">Ms</Select.Option>
                                            <Select.Option value="Dr">Dr</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={'firstName'} label={'First Name'}>
                                        <Input className="custom-input"
                                               style={{margin: '30px 0 0 -82px', width: '200px'}}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8} offset={1}>
                                    <Form.Item name={'lastName'} label={'Surname'}>
                                        <Input className="custom-input"
                                               style={{margin: '30px 0 0 -76px', width: '200px'}}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item
                            name="businessName"
                            label="Business name"
                            rules={[{required: true, message: 'Please input your Business name!'}]}
                            labelCol={{span: 5}}
                            wrapperCol={{span: 15}}
                        >
                            <Input className="custom-input"/>
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{required: true, message: 'Please input your Email!'}]}
                            labelCol={{span: 5}}
                            wrapperCol={{span: 15}}
                        >
                            <Input className="custom-input"/>
                        </Form.Item>
                        <Form.Item
                            name="businessNumber"
                            label="Business contact number"
                            rules={[{required: true, message: 'Please input your Business contact number!'}]}
                            labelCol={{span: 5}}
                            wrapperCol={{span: 15}}
                        >
                            <Input className="custom-input"/>
                        </Form.Item>
                        <Form.Item
                            name="businessAddress"
                            label="Business address"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 15}}
                        >
                            <Input className="custom-input"/>
                        </Form.Item>
                        <Form.Item
                            name="postcode"
                            label="Postcode"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 8}}
                        >
                            <Input className="custom-input"/>
                        </Form.Item>
                        <Form.Item
                            name="natureOfBusiness"
                            label="Nature of business"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 15}}
                        >
                            <Select placeholder="-- Select --" className="custom-select">
                                <Option value="Hospitality">Hospitality</Option>
                                <Option value="Healthcare">Healthcare</Option>
                                <Option value="Legal">Legal</Option>
                                <Option value="Construction">Construction</Option>
                                <Option value="Education">Education</Option>
                                <Option value="Others">Others</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="accountUsername"
                            label="Account username"
                            rules={[{required: true, message: 'Please input Account username!'}]}
                            labelCol={{span: 5}}
                            wrapperCol={{span: 15}}
                        >
                            <Input className="custom-input custom-placeholder"
                                   placeholder="Your username can be either your business name or your personal name"/>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                {required: true, message: 'Please input your password!'},
                                {
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
                                    message: 'Password must be at least 10 characters and include @$!%*?&, lowercase, uppercase, and numbers.'
                                }
                            ]}
                            labelCol={{span: 5}}
                            wrapperCol={{span: 15}}
                        >
                            <Input.Password className="jack-custom-input"/>
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            label="Confirm password"
                            rules={[{required: true, message: 'Please input your password!'},
                                {
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
                                    message: 'Password must be at least 10 characters and include @$!%*?&, lowercase, uppercase, and numbers.'
                                },
                                {
                                    validator: validateConfirmPassword
                                }
                            ]}
                            labelCol={{span: 5}}
                            wrapperCol={{span: 15}}
                        >
                            <Input.Password className="jack-custom-input"/>
                        </Form.Item>
                        <p className="remember-text">Remember to include numbers,capitals & lower case</p>
                        <p className="required-field-text">*Denotes required field</p>
                        <Form.Item className="center-button">
                            <Button type="primary" htmlType="submit" className="custom-button">
                                Create your account
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Content></Layout>
    )
        ;
};

export default ProSignUp;
