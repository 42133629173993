import React from 'react';

export const ArtIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
                d="M18.9414 10.3242C18.9414 14.918 15.2227 18.6367 10.6289 18.6367C6.03516 18.6367 2.31641 14.918 2.31641 10.3242C2.31641 5.73047 6.03516 2.01172 10.6289 2.01172"
                stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.8164 19.5117L18.0664 17.7617" stroke="white" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M13.2543 5.62542C12.9481 4.66292 13.3068 3.46417 14.3218 3.14042C14.8556 2.96542 15.5118 3.11417 15.8881 3.63042C16.2381 3.09667 16.9206 2.97417 17.4456 3.14042C18.4606 3.46417 18.8193 4.66292 18.5131 5.62542C18.0318 7.15667 16.3518 7.95292 15.8881 7.95292C15.4156 7.95292 13.7531 7.17417 13.2543 5.62542Z"
                stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}
export const AccountIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
            <path
                d="M11.2543 11.9768C11.193 11.9677 11.1143 11.9677 11.0443 11.9768C9.5043 11.9218 8.2793 10.6018 8.2793 8.97932C8.2793 7.32016 9.5568 5.97266 11.1493 5.97266C12.733 5.97266 14.0193 7.32016 14.0193 8.97932C14.0105 10.6018 12.7943 11.9218 11.2543 11.9768Z"
                stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M17.045 18.0264C15.4875 19.5206 13.4225 20.4281 11.1475 20.4281C8.8725 20.4281 6.8075 19.5206 5.25 18.0264C5.3375 17.1647 5.8625 16.3214 6.79875 15.6614C9.19625 13.9931 13.1162 13.9931 15.4962 15.6614C16.4325 16.3214 16.9575 17.1647 17.045 18.0264Z"
                stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M11.1484 20.4271C15.9809 20.4271 19.8984 16.323 19.8984 11.2604C19.8984 6.19781 15.9809 2.09375 11.1484 2.09375C6.31595 2.09375 2.39844 6.19781 2.39844 11.2604C2.39844 16.323 6.31595 20.4271 11.1484 20.4271Z"
                stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}
export const LibIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path
                d="M20.7331 7.84125V4.13792C20.7331 2.68042 20.1464 2.09375 18.6889 2.09375H14.9856C13.5281 2.09375 12.9414 2.68042 12.9414 4.13792V7.84125C12.9414 9.29875 13.5281 9.88542 14.9856 9.88542H18.6889C20.1464 9.88542 20.7331 9.29875 20.7331 7.84125Z"
                stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M10.1921 8.07042V3.90875C10.1921 2.61625 9.60539 2.09375 8.14789 2.09375H4.44456C2.98706 2.09375 2.40039 2.61625 2.40039 3.90875V8.06125C2.40039 9.36292 2.98706 9.87625 4.44456 9.87625H8.14789C9.60539 9.88542 10.1921 9.36292 10.1921 8.07042Z"
                stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M10.1921 18.3842V14.6809C10.1921 13.2234 9.60539 12.6367 8.14789 12.6367H4.44456C2.98706 12.6367 2.40039 13.2234 2.40039 14.6809V18.3842C2.40039 19.8417 2.98706 20.4284 4.44456 20.4284H8.14789C9.60539 20.4284 10.1921 19.8417 10.1921 18.3842Z"
                stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.8574 16.3047H19.3574" stroke="white" stroke-width="1.3" stroke-linecap="round"/>
            <path d="M16.6074 19.0547V13.5547" stroke="white" stroke-width="1.3" stroke-linecap="round"/>
        </svg>
    )
}

export const HomeIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
                                   fill="none">
    <path
        d="M8.26801 2.60328L3.32717 6.45328C2.50217 7.09495 1.83301 8.46078 1.83301 9.49661V16.2891C1.83301 18.4158 3.56551 20.1574 5.69217 20.1574H16.3072C18.4338 20.1574 20.1663 18.4158 20.1663 16.2983V9.62494C20.1663 8.51578 19.4238 7.09495 18.5163 6.46245L12.8513 2.49328C11.568 1.59495 9.50551 1.64078 8.26801 2.60328Z"
        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 16.4908V13.7408" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
export const DashboardIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path
                d="M8.31706 20.9779H13.8171C18.4004 20.9779 20.2337 19.1445 20.2337 14.5612V9.0612C20.2337 4.47786 18.4004 2.64453 13.8171 2.64453H8.31706C3.73372 2.64453 1.90039 4.47786 1.90039 9.0612V14.5612C1.90039 19.1445 3.73372 20.9779 8.31706 20.9779Z"
                stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.3398 17.7703V14.1953" stroke="white" stroke-width="1.3" stroke-miterlimit="10"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.3398 7.64297V5.85547" stroke="white" stroke-width="1.3" stroke-miterlimit="10"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M14.3384 12.4073C15.6547 12.4073 16.7217 11.3402 16.7217 10.024C16.7217 8.70768 15.6547 7.64062 14.3384 7.64062C13.0221 7.64062 11.9551 8.70768 11.9551 10.024C11.9551 11.3402 13.0221 12.4073 14.3384 12.4073Z"
                stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round"/>
            <path d="M7.79297 17.7719V15.9844" stroke="white" stroke-width="1.3" stroke-miterlimit="10"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.79297 9.43047V5.85547" stroke="white" stroke-width="1.3" stroke-miterlimit="10"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M7.79349 15.9815C9.10977 15.9815 10.1768 14.9145 10.1768 13.5982C10.1768 12.2819 9.10977 11.2148 7.79349 11.2148C6.47721 11.2148 5.41016 12.2819 5.41016 13.5982C5.41016 14.9145 6.47721 15.9815 7.79349 15.9815Z"
                stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
    )
}