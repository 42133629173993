import React from 'react';
import {Row, Col, Card} from 'antd';
import sixteenNine from './imgs/logo1.png';
import interactive from './imgs/logo2.png';
import mediaDigital from './imgs/logo3.png';
import techRadar from './imgs/logo4.png';
import independent from './imgs/logo5.png';
import creativeBlog from './imgs/logo6.png';
import "./ProCasestudies.scss";
import {useMediaQuery} from "react-responsive";

function IndustryQuotes() {
    const isMobile = useMediaQuery({query: '(max-width: 1000px)'});
    return (
        <div style={{padding: '0 9.5vw 10rem 9.5vw'}}>
            <div className='IndustryQuotesTitle'>What the industry is saying</div>
            <Row className="custom-gutter-row" gutter={[40, 86]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className='SixteenNine-part'>
                        <div className='case-pic'>
                            <img src={sixteenNine} className='sixteenNine'></img>
                        </div>
                        <div className='sisteen-nine-content'>
                            “[Vieunite Pro] blends curated artwork, screens optimised for display art, and a CMS that
                            can run the art but also handle things like workplace communications...the premise of
                            multi-tasking digital art displays to also do workplace communications is kind of
                            interesting.”
                        </div>
                        <div className='sisteen-nine-author'>
                            Dave Haynes, Owner and Editor, Sixteen:Nine
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className='interactive-part'>
                        <div className='case-pic'>
                            <img src={interactive} className='interactive'></img>
                        </div>
                        <div className='interactive-content'>
                            “I absolutely love the purity of the Textura Pro digital canvas. My clients stand in awe
                            when
                            they see modernistic and contemporary art switch over to classical artwork. In the world of
                            creativity… pixels are the new palette”
                        </div>
                        <div className='interactive-author'>
                            Russell Harwood, Interactive Touch Technology
                        </div>
                    </div>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className='mediaDigital-part'>
                        <div className='case-pic'>
                            <img src={mediaDigital} className='mediaDigital'></img>
                        </div>
                        <div className='mediaDigital-content'>
                            “This fantastic product has so many use cases, I see hospitality being a considerable one,
                            imagine checking into your hotel and beforehand requesting a particular theme of artwork
                            ready
                            for your arrival.
                            I love it, love it, love it…”
                        </div>
                        <div className='mediaDigital-author'>
                            Dean Brettle, Media Group
                        </div>
                    </div>
                </Col>

                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div className='techRadar-part'>
                        <div className='case-pic'>
                            <img src={techRadar} className='techRadar'></img>
                        </div>
                        <div className='techRadar-content'>
                            “The Vieunite Textura Digital Canvas dwarfs standard digital photo frames for size and is
                            next-level in almost every regard thanks to its ultra high-quality 27-inch display . . . ”
                        </div>
                    </div>
                </Col>

                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div className='independent-part'>
                        <div className='case-pic'>
                            <img src={independent} className='independent'></img>
                        </div>
                        <div className='independent-content'>
                            “We love that this frame gives us the ultimate flexibility in what we display on our wall .
                            . .”
                        </div>
                    </div>
                </Col>

                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div className='creativeBlog-part'>
                        <div className='case-pic'>
                            <img src={creativeBlog} className='creativeBlog'></img>
                        </div>
                        <div className='CreativeBloq-content'>
                            “The Vieunite Textura Digital Canvas not only looks good and has a beautiful screen, but it
                            becomes a portal to a new world of artists, right there in your home. . . . ”
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default IndustryQuotes;
