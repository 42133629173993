import React, {useEffect, useState} from 'react';
import './style.scss';
import {Card, Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import ProPortalHeader from "../componets/ProPortalHeader";
import ProfileRow from "../componets/ProfileRow";
import Navigator from "../componets/Navigator";
import Title from "antd/es/skeleton/Title";
import Paragraph from "antd/es/skeleton/Paragraph";
import FAQ from './img/FAQ.svg';
import Support from './img/Request.svg';
import Tutorials from './img/Tutorials.svg';
import {useNavigate} from "react-router-dom";
// import backButton from './img/Back button.svg';


const MainContent = () => {
    const navigate = useNavigate();
    const handleProFQAClick = () => {
        navigate('/proportal/ProportalQA');
    };
    const handleProSupportClick = () => {
        navigate('/proportal/ProContact');
    };
    const handleProTutClick = () => {
        navigate('/proportal/ProTutorial');
    };
    // const handleBackClick = () => {
    //     navigate(-1); // 使用navigate函数导航回上一页
    // };

    return (
        <div className="support-section">
            {/*<div className='header4'>*/}
            {/*<img src={backButton} alt="BackButton" className='BackButtonIcon5'*/}
            {/*     style={{marginRight: '-0.1rem', cursor: "pointer"}} onClick={handleBackClick}/>*/}
            <div className="support-section-title">Support</div>
            {/*</div>*/}
            <div className="support-section-paragragh">
                If you have any questions, or require additional technical support, we are here to assist you. We have
                created the following sections as a quick reference for training and to <br/>
                answer common questions that users have. If your query can't be answered using one of these sections
                please feel free to get in touch with us and one of our expert <br/>
                advisors will be in touch to help you.
            </div>
            <Row gutter={16} justify="space-around">
                <Col span={6}>
                    <Card hoverable className="Prosupport-Card" onClick={handleProTutClick}>
                        <img src={Tutorials} alt="Tutorials" className="Tutorials-icon"/>
                        <div className="Tutorials-Card-Name">Video Tutorials</div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card hoverable className="Prosupport-Card" onClick={handleProFQAClick}>
                        <img src={FAQ} alt="FAQ" className="FAQ-icon"/>
                        <div className="FAQ-Card-Name">FAQ</div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card hoverable className="Prosupport-Card" onClick={handleProSupportClick}>
                        <img src={Support} alt="Support" className="Support-icon"/>
                        <div className="Support-Card-Name">Support Request</div>
                    </Card>
                </Col>
            </Row>
            {/*<div className="support-section-downloads">Downloads & Documents</div>*/}
            {/*<a href="/Docs/Vieunite_Pro_User_Manual.pdf" download="Vieunite_Pro_User_Manual.pdf">*/}
            {/*    <div className="support-section-manual">Vieunite Pro User Manual</div>*/}
            {/*</a>*/}
            <div className="support-section-downloads">Downloads & Documents</div>
            <a href="https://indd.adobe.com/view/ea729861-875e-4267-8fe8-884d7455dd66" target="_blank"
               rel="noopener noreferrer">
                <div className="support-section-manual">Vieunite Pro User Manual</div>
            </a>

        </div>)
};

const ProportalSupport = () => {
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <div className="Portalsupport-container">
                                <MainContent/>
                            </div>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    );
};
export default ProportalSupport;