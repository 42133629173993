import React, {useState} from 'react';
import './style.scss'
import {Divider} from "antd";
import {useNavigate} from "react-router-dom";
import {ReactComponent as Logo} from "./new_mb_logo.svg";


export default function ProMobileMenu() {
    return (
        <>
            {/*<h1>*/}
            {/*    <a href="/home"><Logo/></a>*/}
            {/*</h1>*/}
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: '10vh',
                marginBottom: '-10vh',
                zIndex: '100',
                top: 0,
                position: 'sticky',
                backgroundColor: 'white'
            }}>
                <div className={'logo'}
                     onClick={() => window.location.href = "/business/home"}
                     style={{display: "flex", zIndex: '7', position: 'fixed', marginTop: '1vh', height: '6vh'}}>

                    <Logo style={{width: '30vw', height: 'auto'}}/>
                    {/*<svg xmlns="http://www.w3.org/2000/svg" width="162" height="49" viewBox="0 0 162 53" fill="none">*/}
                    {/*    <path*/}
                    {/*        d="M10.705 27.5883L0.341797 0.808594H6.87017L14.266 21.3838L21.6617 0.808594H28.1901L17.8269 27.5883H10.705Z"*/}
                    {/*        fill="#0E477A"/>*/}
                    {/*    <path*/}
                    {/*        d="M30.0156 3.04546C30.0156 1.35747 31.3852 0.0800781 33.0744 0.0800781C34.7635 0.0800781 36.1331 1.4031 36.1331 3.04546C36.1331 4.73345 34.7635 6.10209 33.0744 6.10209C31.4309 6.10209 30.0156 4.77907 30.0156 3.04546ZM30.5178 8.20067H35.6309V27.5441H30.5178V8.20067Z"*/}
                    {/*        fill="#0E477A"/>*/}
                    {/*    <path*/}
                    {/*        d="M39.4199 17.8728C39.4199 12.2613 43.5743 7.69922 49.4636 7.69922C55.2615 7.69922 59.1877 11.9876 59.1877 18.3746V19.5151H44.7613C45.1265 21.9787 47.044 23.986 50.331 23.986C52.0201 23.986 54.2571 23.2561 55.4898 22.0243L57.7268 25.309C55.7637 27.0883 52.7049 28.0007 49.7375 28.0007C43.9396 28.0463 39.4199 24.0773 39.4199 17.8728ZM49.5092 11.7595C46.3592 11.7595 44.9896 14.1318 44.7613 16.0479H54.3028C54.1658 14.1774 52.8419 11.7595 49.5092 11.7595Z"*/}
                    {/*        fill="#0E477A"/>*/}
                    {/*    <path*/}
                    {/*        d="M75.9422 27.5902V25.1267C74.6183 26.6322 72.2444 28.0464 69.0943 28.0464C64.8486 28.0464 62.8398 25.6741 62.8398 21.9788V8.20117H67.953V19.9715C67.953 22.6631 69.3226 23.5299 71.5139 23.5299C73.477 23.5299 75.0292 22.435 75.9422 21.3401V8.29242H81.0554V27.6358L75.9422 27.5902Z"*/}
                    {/*        fill="#0E477A"/>*/}
                    {/*    <path*/}
                    {/*        d="M99.2255 27.5894V15.8647C99.2255 13.1731 97.8559 12.2606 95.6645 12.2606C93.6558 12.2606 92.1036 13.4012 91.2362 14.5417V27.5894H86.123V8.20035H91.2362V10.7095C92.5145 9.20402 94.8428 7.74414 98.0385 7.74414C102.284 7.74414 104.339 10.1621 104.339 13.9486V27.635L99.2255 27.5894Z"*/}
                    {/*        fill="#0E477A"/>*/}
                    {/*    <path*/}
                    {/*        d="M108.812 3.04546C108.812 1.35747 110.182 0.0800781 111.871 0.0800781C113.56 0.0800781 114.93 1.4031 114.93 3.04546C114.93 4.73345 113.56 6.10209 111.871 6.10209C110.228 6.10209 108.812 4.77907 108.812 3.04546ZM109.315 8.20067H114.428V27.5441H109.315V8.20067Z"*/}
                    {/*        fill="#0E477A"/>*/}
                    {/*    <path*/}
                    {/*        d="M120.455 22.7528V12.6248H117.26V8.19957H120.455V2.95312H125.569V8.2452H129.495V12.6705H125.569V21.3841C125.569 22.5703 126.208 23.5283 127.349 23.5283C128.08 23.5283 128.856 23.2546 129.13 22.9353L130.225 26.7674C129.495 27.4518 128.08 28.0448 125.979 28.0448C122.419 28.0448 120.455 26.1744 120.455 22.7528Z"*/}
                    {/*        fill="#0E477A"/>*/}
                    {/*    <path*/}
                    {/*        d="M131.777 17.8728C131.777 12.2613 135.932 7.69922 141.821 7.69922C147.619 7.69922 151.545 11.9876 151.545 18.3746V19.5151H137.119C137.484 21.9787 139.401 23.986 142.688 23.986C144.378 23.986 146.615 23.2561 147.847 22.0243L150.084 25.309C148.121 27.0883 145.062 28.0007 142.095 28.0007C136.251 28.0463 131.777 24.0773 131.777 17.8728ZM141.867 11.7595C138.717 11.7595 137.347 14.1318 137.119 16.0479H146.66C146.523 14.1774 145.199 11.7595 141.867 11.7595Z"*/}
                    {/*        fill="#0E477A"/>*/}
                    {/*    <path*/}
                    {/*        d="M154.604 24.7613C154.604 22.9821 156.11 21.4766 157.891 21.4766C159.671 21.4766 161.178 22.9821 161.178 24.7613C161.178 26.5405 159.671 28.046 157.891 28.046C156.11 28.046 154.604 26.5405 154.604 24.7613Z"*/}
                    {/*        fill="#0E477A"/>*/}
                    {/*    <path*/}
                    {/*        d="M95.5273 51.9051L99.91 32.1055H106.849C109.954 32.1055 112.054 34.4322 112.054 37.0782C112.054 40.1348 109.954 43.6933 104.704 43.6933H99.0426L97.2165 51.9051H95.5273ZM104.886 42.1422C108.447 42.1422 110.228 39.8155 110.228 37.1694C110.228 35.299 108.675 33.611 106.575 33.611H101.234L99.3622 42.0965H104.886V42.1422Z"*/}
                    {/*        fill="#0E477A"/>*/}
                    {/*    <path*/}
                    {/*        d="M124.243 51.9051L120.499 43.6933H116.025L114.199 51.9051H112.51L116.892 32.1055H123.695C126.388 32.1055 129.082 33.9303 129.082 37.0782C129.082 40.7735 126.388 43.5108 122.325 43.6477L126.206 51.9051H124.243ZM121.869 42.1422C125.338 42.1422 127.21 39.8155 127.21 37.1694C127.21 35.0709 125.384 33.611 123.466 33.611H118.216L116.345 42.1422H121.869Z"*/}
                    {/*        fill="#0E477A"/>*/}
                    {/*    <path*/}
                    {/*        d="M142.275 31.7871C147.16 31.7871 151.178 34.8437 151.178 40.3639C151.178 47.0702 146.247 52.2711 140.221 52.2711C135.29 52.2711 131.319 49.2144 131.319 43.6943C131.273 36.9879 136.204 31.7871 142.275 31.7871ZM142.138 33.2926C137.299 33.2926 133.145 37.581 133.145 43.603C133.145 48.2564 136.386 50.7199 140.312 50.7199C145.106 50.7199 149.352 46.4315 149.352 40.4095C149.306 35.7562 146.065 33.2926 142.138 33.2926Z"*/}
                    {/*        fill="#0E477A"/>*/}
                    {/*</svg>*/}
                </div>
                <input id="burger" type="checkbox"/>

                <label htmlFor="burger" className={'m-label'}>
                    <span></span>
                    <span></span>
                    <span></span>
                </label>
                <nav>
                    <ul>
                        <li><a href="/business/">Home</a>
                            <Divider className={'m-menu-divider'}/>
                        </li>
                        <li>
                            <a href="/business/textura-pro">Textura Pro</a>
                            <Divider className={'m-menu-divider'}/>
                        </li>
                        <li>
                            <a href="/business/software">Software</a>
                            <Divider className={'m-menu-divider'}/>
                        </li>
                        <li>
                            <a href="/business/curated-collections">Curated Collection</a>
                            <Divider className={'m-menu-divider'}/>
                        </li>
                        <li><a href="/business/subscription">Subscription</a>
                            <Divider className={'m-menu-divider'}/>
                        </li>
                        <li>
                            <a href="/business/prologin" style={{
                                fontWeight: '400'
                            }}>
                                Login
                            </a>
                        </li>
                        <li style={{flexGrow: 1}}></li>
                        {/* This will push the last <li> to the bottom */}
                        <li style={{marginBottom: '16vh'}}>
                            <Divider className={'m-menu-divider'}/>
                            <a href="/" style={{display: "flex"}}>
                                Vieunite for Home
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="30" viewBox="0 0 33 30"
                                     fill="none" style={{
                                    margin: "auto"
                                }}>
                                    <path
                                        d="M31.8966 16.4943C32.6777 15.7132 32.6777 14.4469 31.8966 13.6659L19.1687 0.937943C18.3877 0.156894 17.1213 0.156894 16.3403 0.937943C15.5592 1.71899 15.5592 2.98532 16.3403 3.76637L27.654 15.0801L16.3403 26.3938C15.5592 27.1748 15.5592 28.4412 16.3403 29.2222C17.1213 30.0033 18.3877 30.0033 19.1687 29.2222L31.8966 16.4943ZM2.48242 13.0801C1.37785 13.0801 0.482422 13.9755 0.482422 15.0801C0.482422 16.1846 1.37785 17.0801 2.48242 17.0801V13.0801ZM30.4824 13.0801L2.48242 13.0801V17.0801L30.4824 17.0801V13.0801Z"
                                        fill="#105B63"/>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}