import React, {useEffect, useState} from 'react';
import './style.scss';
import {Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import ProPortalHeader from "../../componets/ProPortalHeader";
import ProfileRow from "../../componets/ProfileRow";
import Navigator from "../../componets/Navigator";


const MainContent = () => {
    return (
        <div className="PortalContact-container">
            <div className="PortalPhone-Title">Call us</div>
            <div className="PortalPhone-Paragragh" style={{
                marginBottom:'10vh'
            }}>
                We’re just a phone call away! Reach out to us with any questions you have.
                Call 03455-481311
            </div>
            {/*<button type="submit"*/}
            {/*        className={"PortalPhone-button"}*/}
            {/*        onClick={() => {*/}
            {/*            window.location.href = 'tel:03455-481311';*/}
            {/*        }}*/}
            {/*        style={{*/}
            {/*            fontFamily: "Proxima Nova, sans-serif",*/}
            {/*            fontWeight: '600',*/}
            {/*            borderRadius: "8.69px",*/}
            {/*            padding: '0.5rem',*/}
            {/*            width: '25%',*/}
            {/*            margin: '2rem 0.1rem 4rem'*/}
            {/*        }}*/}
            {/*>*/}
            {/*    CALL*/}
            {/*</button>*/}
            <div className="PortalEmail-Title">Email us</div>
            <div className="PortalEmail-Paragragh">
                Have a question? Shoot us an email at support@vieutopia.com and our team will be happy to help! <br/>We aim to respond to all emails within 1 working day.
            </div>
            <button type="submit"
                    className={"PortalEmail-button"}
                    onClick={() => {
                        window.location.href = 'mailto:support@vieunite.com';
                    }}
                    style={{
                        padding: '0.3rem',
                        margin: '2rem 0.1rem 4rem',
                    }}
            >
                Email
            </button>
        </div>
    );
};

const ProContact = () => {
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <MainContent/>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>

    );
};

export default ProContact;