import React from "react";
import {Helmet} from "react-helmet";

export const A6Meta = () => (
    <Helmet>
        <title>Barbara | Vieunite</title>
        <meta name="description"
              content="Her recent paintings celebrate movement and freedom in the natural world with loosely applied dry pastel and transparent acrylic on wood."/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@ VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Barbara Safran de Niverville"/>
        <meta name="twitter:description"
              value="Barbara Safran de Niverville's nature-inspired digital art captures the beauty of landscapes and organic forms. Explore her tranquil works on Vieunite."/>
        <meta name="twitter:url" value="https://vieunite.com/artists/barbara-safran-de-niverville"/>
        <meta name="twitter:image" value=" https://vieunite.com/images/vieunite_sc_barbara_safran_de_niverville.jpg"/>

        <meta property="og:url" content=" https://vieunite.com/artists/barbara-safran-de-niverville"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Vieunite Artist | Barbara Safran de Niverville "/>
        <meta property="og:description"
              content="Barbara Safran de Niverville's nature-inspired digital art captures the beauty of landscapes and organic forms. Explore her tranquil works on Vieunite."/>
        <meta property="og:image" content="https://vieunite.com images/vieunite_sc_barbara_safran_de_niverville.jpg"/>
    </Helmet>);