import {APP_API_URL} from "../../../settings";

const token = localStorage.getItem('token');
export const deleteArtwork = async (id) => {
    const reqBody = {
        "artworkId": id,
    }
    const reqOpt = {
        method: 'POST',
        headers: {
            "X-Access-Token": token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(reqBody)
    };
    return fetch(APP_API_URL + "/photoframe-mobile/user/deleteArtwork", reqOpt)
        .then(response => response.json())
        .then(result => console.log(result.code))
        .catch(error => console.log('error', error));
}
export const applyArtist = async (info)=>{
    const req = {
        method: 'POST',
        headers: {"X-Access-Token": token, "Content-Type": "application/json"},
        body: JSON.stringify(info)
    };
    return fetch(APP_API_URL + `/photoframe-mobile/system/pframeArtist/applyArtist`,
        req)
        .then(res => res.json())
        .catch(error => console.log('error', error));
}
export const getPersonalArts = async ()=>{
    const req = {
        method: 'GET',
        headers: {"X-Access-Token": token},
    };
    let artsInfo = [];
    const fetchArts = () => {
        return fetch(APP_API_URL + `/photoframe-mobile/albums/user/personal`,
            req)
            .then(res => res.json())
            .then(data => {
                const records = data.result.records;
                artsInfo.push(
                    ...records
                        .map((art, idx) => {
                            const tagsList = art.artTags ? art.artTags.split(',') : ""
                            return {
                                ...art,
                                key: idx + 1,
                                id: art.id,
                                src: art.pic_url,
                                name: art.artName,
                                date: art.createdAt ? new Intl.DateTimeFormat('en-US', {
                                    month: 'short',
                                    year: 'numeric'
                                }).format(new Date(art.createdAt)) : '',
                                price: '£' + art.price,
                                originalPrice: art.price,
                                pic_url: art.picUrl,
                                discipline: tagsList.length > 1 ? tagsList[0] : '',
                                genre: tagsList.length > 2 ? tagsList[1] : '',
                                tags: tagsList,
                            }
                        })
                )
                return artsInfo
            })
            .catch(error => console.log('error', error));
    }
    return fetchArts();
}

export const getAllArts = async () => {
    const req = {
        method: 'GET',
        headers: {"X-Access-Token": token},
    };
    const userId = JSON.parse(localStorage.getItem('userInfo')).id
    let pageNo = 1
    let artsInfo = [];
    const fetchArts = () => {
        return fetch(APP_API_URL + `/photoframe-mobile/system/pframeUser/public_page/queryById?id=${userId}&userId=${userId}&pageNo=${pageNo}`,
            req)
            .then(res => res.json())
            .then(data => {
                const records = data.result.artworkList.records;
                artsInfo.push(
                    ...records
                        .filter(art => art.powerType === 1)
                        .map((art, idx) => {
                            const tagsList = art.tags ? art.tags.split(',') : ""
                            return {
                                ...art,
                                key: idx + 1,
                                id: art.id,
                                src: art.picUrl,
                                name: art.name,
                                // date: art.createTime ? new Intl.DateTimeFormat('en-US', {
                                //     month: 'short',
                                //     year: 'numeric'
                                // }).format(new Date(art.createTime)) : '',
                                date: art.dateProduced,
                                price: '£' + art.price,

                                originalPrice: art.price,
                                pic_url: art.picUrl,
                                discipline: tagsList.length > 1 ? tagsList[0] : '',
                                genre: tagsList.length > 2 ? tagsList[1] : '',
                                tags: tagsList,
                            }
                        })
                )
                if (records.length === 20) {
                    pageNo++;
                    return fetchArts();
                }
                return artsInfo
            })
            .catch(error => console.log('error', error));
    }
    return fetchArts();
}