import React, {useEffect, useRef, useState} from 'react';
import {Button, Row, Upload, Modal, message,} from 'antd';
import SparkMD5 from 'spark-md5'
import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';
import {PlusOutlined} from "@ant-design/icons";
import {Editor} from "../../../ArtList/components/Editor/Editor";
import {mergeChunkFile} from "./services";
import {uploadChunks} from "./models";
import './style.scss'
import {Primary_Font} from "../../../../../settings";
import {getMD5} from "./utils";
import useWindowResize from "../../../../Hooks/useWindowResize";

export const ArtUploader = (props) => {
    const {windowWidth} = useWindowResize()
    const {isCardDisplay,} = props
    const {Dragger} = Upload;

    const [editImageModalVisible, setEditImageModalVisible] = useState(false)
    const [srcCropper, setSrcCropper] = useState('')// srcCropper: '', // 当前图片路径
    const [selectImgName, setSelectImgName] = useState('') // 当前文件名称
    const [isEditorOpen, setIsEditorOpen] = useState(false)
    const [mergedRes, setMergedRes] = useState({})
    const [isNextDisabled, setIsNextDisabled] = useState(false);

    const cropperRef = useRef(null)

    useEffect(() => {
        if (Object.keys(mergedRes).length > 0) {
            setIsEditorOpen(true);
            message.destroy()
            setIsNextDisabled(true);
        }
    }, [mergedRes])

    const uploadBefore = (file) => {
        // - FileReader 对象允许Web应用程序异步读取存储在用户计算机上的文件（或原始数据缓冲区）的内容，
        // 使用 File 或 Blob 对象指定要读取的文件或数据。
        // - 事件 FileReader.onload，在读取操作完成时触发
        if (file.type.startsWith('image')) {
            const reader = new FileReader();
            // Image()函数将会创建一个新的HTMLImageElement实例, Image(width, height)
            // 它的功能等价于 document.createElement('img')
            // const image = new Image();

            reader.readAsDataURL(file);
            //开始读取指定的Blob中的内容。一旦完成，result属性中将包含一个data: URL格式的Base64字符串以表示所读取文件的内容。
            // 1. img.onload
            // - img.onload 是回调函数，当img载入完成，就执行这个函数
            // - 当image的src发生改变，浏览器就会跑去加载这个src里的资源。
            // 这个操作是异步的，就是说，js不会傻傻地在原地等待图片的加载，而是继续读代码，
            // 直到图片加载完成，触发onload事件，js才会回来执行onload里面的内容。

            // 2. reader.onload
            // - 当 FileReader 读取文件的方式为  readAsArrayBuffer, readAsBinaryString, readAsDataURL
            // 或者 readAsText 的时候，会触发一个 load 事件。从而可以使用  FileReader.onload 属性对该事件进行处理。

            reader.onload = e => { // onload 事件在图片加载完成后立即执行。
                // image.src = reader.result;
                // image.onload = () => {
                setSrcCropper(e.target.result)
                setSelectImgName(file.name)
                setEditImageModalVisible(true)
                // };
            };
            // Must return false to prevent AntD's Upload Component send img to server
            return false;
        } else if (file.type.startsWith('video')) {
            return true
        } else {
            alert('Unsupported file format!')
            return false;
        }
    };
    const uploadFile = async (blob) => {
        message.loading('Processing...', 0, () => {
            message.success('Succeeded!');
        });
        const spark = new SparkMD5.ArrayBuffer();

        let fileMd5 = '';
        await getMD5(blob, spark).then((md5) => {
            fileMd5 = md5
        })
        const chunks = []
        const chunkSize = 10 * 1024 * 1024
        const totalChunks = Math.ceil(blob.size / chunkSize)
        const createFileChunks = async (blob, size, spark) => {
            let chunkIndex = 0
            let cur = 0;
            while (cur < blob.size) {
                const chunk = blob.slice(cur, cur + size)
                chunks.push({
                    file: chunk,
                    fileName: selectImgName || blob.name,
                    md5: fileMd5,
                    md5Chunk: await getMD5(chunk, spark),
                    chunkIndex: chunkIndex.toString(),
                    totalChunks: totalChunks.toString(),
                });
                cur += size;
                chunkIndex += 1
            }
        };
        await createFileChunks(blob, chunkSize, spark)
        uploadChunks(chunks)
            .then(results => {
                const mergeData = {
                    "md5": fileMd5,
                    "chunkCount": totalChunks,
                    "fileName": selectImgName || blob.name,
                };
                mergeChunkFile(mergeData).then(result => {
                    setMergedRes(result.result)
                }).catch(err => {
                    console.log('Error uploading or merging chunks:' + err)
                });
            })
            .catch(error => {
                console.log("Error uploading or merging chunks:" + error,);
            });
    }
    const handleSaveImg = () => {
        if (srcCropper) {
            setIsNextDisabled(true)
            /*
            The getCroppedCanvas() method is to get a canvas drawn from the cropped image (lossy compression).
            If it is not cropped, then returns a canvas drawn the whole image.
            After then, we can display the canvas as an image directly,
            or use HTMLCanvasElement.toDataURL to get a Data URL,
            or use HTMLCanvasElement.toBlob to get a blob and upload it to server with FormData if the browser supports these APIs.
            Avoid getting a blank (or black) output image, it needs to set the maxWidth and maxHeight properties to limited numbers,
            because of the size limits of a canvas element.
            Also, limit the maximum zoom ratio (in the zoom event) for the same reason.
            */
            // message.loading('Processing...', 0, () => {
            //     message.success('Succeeded!');
            // });
            cropperRef.current.cropper.getCroppedCanvas({
                minWidth: 1080,
                minHeight: 1080,
                maxWidth: 1920,
                maxHeight: 1920,
                fillColor: '#fff',
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high',
            }).toBlob(async (blob) => {
                await uploadFile(blob)
                // Assume 'blob' variable contains the image blob
                // reader.readAsDataURL(blob);
                // reader.onload = () => {
                //     const imageSrc = reader.result;
                //     // Create an anchor element
                //     const downloadLink = document.createElement('a');
                //     downloadLink.href = imageSrc;
                //     downloadLink.download = 'image.png'; // Set the download file name
                //     downloadLink.click(); // Click the anchor element to start download
                // };

            },);
        }
    };

    return (
        <div>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                {isCardDisplay ? (
                    <Dragger className={''}
                             accept={'.png,.jpg,.jpeg,.mp4,.avi,.mpg'}
                             showUploadList={false}
                             maxCount={1} //限制上传数量。当为 1 时，始终用最新上传的文件代替当前文件
                             multiple={false} //是否支持多选文件，开启后按住 ctrl 可选择多个文件
                             beforeUpload={uploadBefore}
                             style={{
                                 borderRadius: '1rem',
                                 width: windowWidth > 600 ? '16vw' : '100%',
                                 backgroundColor: '#E5E5E5',
                             }}
                             customRequest={({file}) => {
                                 uploadFile(file)
                             }}
                    >
                        <p className="ant-upload-drag-icon primary-font">
                            <PlusOutlined style={{color: "grey", fontSize: '3vw'}}/>
                        </p>
                        <p className="ant-upload-text primary-font" style={{color: "grey", fontWeight: '600'}}>
                            Drag and drop or click here
                        </p>
                        <p className="ant-upload-hint">
                            to upload your artwork
                        </p>
                    </Dragger>
                ) : (
                    <Upload
                        className={''}
                        accept={'.png,.jpg,.jpeg,.mp4,.avi,.mpg'}
                        showUploadList={false}
                        maxCount={1} //限制上传数量。当为 1 时，始终用最新上传的文件代替当前文件
                        multiple={false} //是否支持多选文件，开启后按住 ctrl 可选择多个文件
                        beforeUpload={uploadBefore}
                        customRequest={({file}) => {
                            uploadFile(file)
                        }}
                    >
                    <span style={{
                        fontSize: '4rem',
                        textAlign: "center",
                        verticalAlign: 'center',
                        cursor: "pointer"
                    }}>
                        +
                    </span>
                    </Upload>
                )}
            </div>
            <Modal
                className="upload_cropper_modal"
                visible={editImageModalVisible}
                width={820}
                onCancel={() => setEditImageModalVisible(false)}
                title={<div>
                    <p style={{fontSize: '2rem'}}>Upload Artwork.</p>
                    <p style={{...Primary_Font, fontWeight: 500, fontSize: '0.8rem'}}>
                        Position the artwork within the 16:9 aspect ratio. Use the buttons below to change between
                        landscape or portrait cropping.
                    </p>
                </div>}
                footer={[
                    <Button
                        onClick={() => setEditImageModalVisible(false)}
                        disabled={isNextDisabled}
                    >
                        Cancel
                    </Button>,
                    <Button type="primary" onClick={handleSaveImg} disabled={isNextDisabled}>
                        Next
                    </Button>,
                ]}
            >
                <Row
                    className={'ratio-row'}
                    justify={"center"} style={{
                    display: "flex",
                    gap: '4rem',
                    padding: '0 0 2rem 0'
                }}>
                    <Button onClick={() => {
                        cropperRef.current.cropper?.setAspectRatio(16 / 9);
                    }}>
                        Landscape
                    </Button>
                    <Button onClick={() => {
                        cropperRef.current.cropper?.setAspectRatio(9 / 16);
                    }}>
                        Portrait
                    </Button>
                </Row>
                <Cropper
                    src={srcCropper || ''} // 图片路径，即是base64的值，在Upload上传的时候获取到的
                    ref={cropperRef}
                    // preview=".uploadCrop"
                    viewMode={1} // 定义cropper的视图模式
                    // zoomable={false} // 是否允许放大图像
                    // movable
                    // guides={false} // 显示在裁剪框上方的虚线
                    background={false} // 是否显示背景的马赛克
                    // rotatable={false} // 是否旋转
                    autoCropArea={1} // 默认值0.8（图片的80%）。--0-1之间的数值，定义自动剪裁区域的大小
                    style={{width: '100%', height: '400px'}}
                    aspectRatio={16 / 9} // 可以自己设置比例, 默认情况为自由比例
                    // cropBoxResizable={true} // 默认true ,是否允许拖动 改变裁剪框大小
                    // cropBoxMovable={true}  // 是否可以拖拽裁剪框 默认true
                    // dragMode="move" // 拖动模式, 默认crop当鼠标 点击一处时根据这个点重新生成一个 裁剪框，move可以拖动图片，none:图片不能拖动
                    // center
                />
            </Modal>
            <Editor
                key={isEditorOpen}
                isOpen={isEditorOpen}
                isNewArt={true}
                id={''}
                mergedRes={mergedRes}
            />
        </div>)
}