import React from "react";
import {Helmet} from "react-helmet";

export const A22Meta = () => (
    <Helmet>
        <title> Hu Yuanyuan | Vieunite</title>
        <meta name="description"
              content="Explore Hu Yuanyuan's art."/>
        <meta name="keywords"
              content=" digital canvas, digital frame, digital art frame, digital art canvas, Paul Gittins, multimedia art, performance art, sculpture, video art, abstract patterns, shadow dancers, live musicians, Oak Apple Orchestra, improvisational music, large-scale installations, Vieunite artist"/>
    </Helmet>);