import React, {useState} from 'react';
import './ShadowHeader.css'
import {Col, Dropdown, Empty, Menu, Row} from "antd";
import {ReactComponent as Logo} from "../../img/SVG/Vieunite_logo_Text-black-01.svg";
import MobileMenu from "../MobileMenu/MobileMenu";

export default class ShadowHeader extends React.Component {
    constructor() {
        super();
        this.state = {
            expandedMobileMenu: false,
        }
    }


    render() {
        return (
            <div className={'tou'}>
                <div className={'top-blue-bar'} style={{backgroundColor: '#016E6E'}}>
                    <a href="/home" className="tab-h tab-active-h">For Home</a>
                    <a href="/business" className="tab-h">For Business</a>
                </div>
                <Row className={'mobile-header'} justify={'center'}>
                    <Col lg={0} xs={20} style={{position: "relative", fontSize: 'x-large'}}>
                        <MobileMenu></MobileMenu>
                    </Col>
                </Row>

                <div className={'desktop-header'}>
                    <div className={'logo'}>
                        <a href="/home"><Logo/></a>
                    </div>
                    <div>
                        <ul className={'menu menu-desktop'}
                            style={{marginBottom: '-5px', columnGap: '3rem'}}>
                            <li>
                                <a href="/digital-canvas" className={"hover-underline-animation"}
                                   style={{color: "black", fontWeight: '600', fontSize: '1rem'}}>
                                    Textura
                                </a>
                            </li>
                            <li>
                                <a href="/artists" className={"hover-underline-animation"}
                                   style={{color: "black", fontWeight: '600', fontSize: '1rem'}}>Artists</a>
                            </li>
                            <li>
                                <a href="/news" className={"hover-underline-animation"}
                                   style={{color: "black", fontWeight: '600', fontSize: '1rem'}}
                                >
                                    News
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={'logo'}>
                        <a href="/artistportal/login" className={"hover-underline-animation"}
                           style={{color: "black", fontWeight: '600', fontSize: '1rem'}}
                        >
                            Login
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}