import React, {useEffect} from 'react';
import {Button, Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import ProPortalHeader from "../../../componets/ProPortalHeader";
import ProfileRow from "../../../componets/ProfileRow";
import Navigator from "../../../componets/Navigator";
// import {get_all_sku} from "./services";
import {get_api, post_api} from "../../../serverTemp";

const UpgradeSummary = (props) => {
    const [sku, setSku] = React.useState('');
    const [price, setPrice] = React.useState('');
    useEffect(() => {
        get_api('/shopping/cart/view/skus').then((ret) => {
            console.log(ret.data)
            let final_price = 0
            for (let i = 0; i < ret.data.length; i++) {
                final_price += parseFloat(ret.data[i].unit_price)
            }
            setPrice(final_price)
            setSku(ret.data)
        })
    }, []);
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <Row>
                                <Col lg={15} style={{margin: '4rem 0 20rem 4rem'}}>
                                    <Row justify={"start"} className={'upgrade-summary-title-div'}>
                                        <Col span={24}>
                                            <p className={'upgrade-summary-title-p'}>
                                                Shopping Cart
                                            </p>
                                        </Col>
                                        <Col span={24}>
                                            <p className={'upgrade-summary-title-p-item'}>{sku.length} items</p>
                                        </Col>
                                    </Row>
                                    {sku.length > 0 ?
                                        sku.map((item, index) => <Row
                                            style={{
                                                margin: '2rem 0 2rem 4rem',
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                            <Col lg={8}>
                                                <p className={'upgrade-summary-txt'}>You have opted to upgrade to Advanced subscription</p>
                                            </Col>
                                            <Col lg={8} className = {'upgrade-summary-delete-btn'}>
                                                <svg style={{cursor: "pointer"}}
                                                     onClick={() => {
                                                         post_api('/shopping/cart/remove/sku', {
                                                             id: item.id,
                                                             sku_type: item.sku_type,
                                                         }).then(res => {
                                                             window.location.reload()
                                                         })
                                                     }}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="25" height="25"
                                                     viewBox="0 0 25 25" fill="none">
                                                    <path
                                                        d="M21.876 6.23177C18.4072 5.88802 14.9176 5.71094 11.4385 5.71094C9.37598 5.71094 7.31348 5.8151 5.25098 6.02344L3.12598 6.23177"
                                                        stroke="#AAAAAA" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round"/>
                                                    <path
                                                        d="M8.85059 5.17187L9.07975 3.80729C9.24642 2.81771 9.37142 2.07812 11.1318 2.07812H13.861C15.6214 2.07812 15.7568 2.85938 15.9131 3.81771L16.1423 5.17187"
                                                        stroke="#AAAAAA" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round"/>
                                                    <path
                                                        d="M19.6331 9.53125L18.9561 20.0208C18.8415 21.6562 18.7477 22.9271 15.8415 22.9271H9.15397C6.24772 22.9271 6.15397 21.6562 6.03939 20.0208L5.3623 9.53125"
                                                        stroke="#AAAAAA" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round"/>
                                                    <path d="M10.7607 17.1953H14.2295" stroke="#AAAAAA" stroke-width="2"
                                                          stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M9.89062 13.0312H15.099" stroke="#AAAAAA" stroke-width="2"
                                                          stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </Col>
                                            <div className={'upgrade-summary-divider'}/>
                                        </Row>)
                                        : <></>
                                    }
                                </Col>

                                <Col lg={8} style={{margin: '8rem -20rem 20rem -7rem'}}>
                                    <div className={'upgrade-summary-order-div'}>
                                        <p className={'upgrade-summary-order-title'}>
                                            Order Summary
                                        </p>
                                        <p className={'upgrade-summary-order-my-bag'}>
                                            My bag ({sku.length} items)
                                        </p>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            margin: '0 5rem 0 2rem'
                                        }}>
                                            <p className={'upgrade-summary-order-total'}>Total</p>
                                            <p className={'upgrade-summary-order-total'}>£{price}</p>
                                        </div>
                                        <Button className={'upgrade-summary-order-btn'}>
                                            Secure Checkout
                                        </Button>
                                    </div>
                                </Col>

                            </Row>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    )
}

export default UpgradeSummary;
