import React, {useEffect, useState} from 'react';
import './style.scss';
import {Button, Col, Layout, Row, Space, Table} from "antd";
import {Content} from "antd/es/layout/layout";
import ProPortalHeader from "../../componets/ProPortalHeader";
import ProfileRow from "../../componets/ProfileRow";
import Navigator from "../../componets/Navigator";
import {useNavigate} from "react-router-dom";
import {get_api} from "../../serverTemp";
import Invoice from './imgs/Invoice download.svg';
import backButton from "./MyRefunds/img/Back button.svg";

const MyOrdersPro = () => {
    const [data, setData] = useState([])
    const navigate = useNavigate(); // 使用useNavigate钩子
    useEffect(() => {
        get_api('/order/view?order_status=-1').then(res => {
            setData(res.data || []);
        })
    }, []);
    // const statusMappings = {
    //     0: 'Available to Display',
    //     1: 'In Cart',
    //     2: 'Processed',
    //     3: 'Paid',
    //     4: 'Canceled',
    //     5: 'Prepare to Deliver',
    //     6: 'Delivering',
    //     7: 'Delivered',
    //     8: 'Refunded',
    // };
    const statusMappings = {
        0: 'Unpaid',
        1: 'Paid',
        6: 'Canceled',
        7: 'Error',
    };
    const columns = (navigate) => [
        {
            title: 'Order No.',
            dataIndex: 'id',
            key: 'orderNo',
            render: (text, record) => (
                <div style={{borderBottom: '1px solid black', cursor: 'pointer', display: 'inline-block'}}>
                    <a onClick={() => navigate(`/proportal/PortalOrderDetails`)}
                       style={{color: 'inherit', textDecoration: 'none'}}>{text}</a>
                </div>
            ),
        },
        {
            title: 'Order date',
            dataIndex: 'createTime',
            key: 'orderDate',
        },
        {
            title: 'Delivery address',
            dataIndex: 'deliveryAddress',
            key: 'deliveryAddress',
        },
        {
            title: 'Item(s)',
            dataIndex: 'itemsNumber',
            key: 'items',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => (
                <div>
                    {statusMappings[status] || 'Unknown'}
                    {record.invoice_url && (
                        <a href={record.invoice_url} target="_blank" rel="noopener noreferrer" style={{marginLeft: '100px'}}>
                            <img src={Invoice} alt="Invoice" style={{width: '100px', height: '100px'}} />
                        </a>
                    )}
                </div>
            ),
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     render: status => statusMappings[status] || 'Unknown', // 使用映射对象转换状态码
        // },
        // {
        //     title: 'Invoice',
        //     dataIndex: 'invoice_url',
        //     key: 'invoice_url',
        //     render: (text, record) => (
        //         <a href={record.invoice_url} target="_blank" rel="noopener noreferrer">
        //             <img src={Invoice} alt="Invoice" style={{width: '100px', height: '100px'}} />
        //         </a>
        //     ),
        // },
        {
            title: 'Total price',
            dataIndex: 'totalPrice',
            key: 'data.totalPrice',
            render: totalPrice => `£${totalPrice}`, // Prepend '£' symbol to the price
        },
    ];
    const handleBackClick = () => {
        navigate(-1); // 使用navigate函数导航回上一页
    };
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <Content style={{
                                background: '#F4F4F4',
                            }}>
                                <div className='my-orders-pro'>
                                    <div className='header1'>
                                        <img src={backButton} alt="BackButton" className='BackButtonIcon2'
                                             style={{marginRight: '1rem', cursor: "pointer"}} onClick={handleBackClick}/>
                                        <div className='my-orders-pro-Title'>
                                            Your orders
                                        </div>
                                    </div>

                                    <div className='my-orders-pro-SubTitle'>
                                        <a href="./RefundsProportal" className="refunds-link">Refunds</a>
                                    </div>
                                    <div className='my-orders-pro-title'>
                                        Order Dashboard
                                    </div>
                                    <div className='my-orders-table'>
                                        <Table columns={columns(navigate)} dataSource={data}
                                               scroll={{x: 'max-content'}}/>
                                    </div>
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>);
}
export default MyOrdersPro;