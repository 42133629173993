export const initialState = {
    metaData: {
        angle: 0,
        volume: 50,
        brightness: 50,
        imageDuration: 30
    },
    schedule: {
        name: 'deviceName',
        start_date: null,
        end_date: null,
        days: [],
        start_time: "",
        end_time: ""
    },
    texturaPlayModeStatus: -1 //0:shuffle;1:loop one artwork (no use);2:loop all contents in order
}
export const reducer = (draft, action) => {
    switch (action.type) {
        case 'update_meta':
            draft.metaData = {...draft.metaData, ...action.item}
            break;
        case 'add_schedule':
            draft.schedule = {...draft.schedule, ...action.item}
            break;
        case 'set_play_mode':
            draft.texturaPlayModeStatus = action.payload
            break
        default:
            break;
    }
}