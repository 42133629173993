import React, {useEffect} from 'react';
import {Button, Checkbox, Col, Layout, message, Modal, Row, Tooltip} from "antd";
import {useImmerReducer} from 'use-immer';
import {Content} from "antd/es/layout/layout";
import img1 from "./imgs/Textura Birch.webp"
import img2 from "./imgs/Textura Black.webp"
import img3 from "./imgs/Textura Pine.webp"
import img4 from "./imgs/Textura Walnut.webp"
import './style.scss'
import bar1 from "./imgs/bar1.png"
import bar2 from "./imgs/bar2.png"
import {ReactComponent as InfoIcon} from "../Subscription/Information icon.svg"
import {CardElement, Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./components/CheckoutForm";
import {post_api, post_api_pay_textura} from "../../portal/serverTemp";
import {advancedList, detailList, premiumList, standardList} from "./text";
import {initialState, reducer} from "./reducer";
import {IdxToCanvas, SINGLE_PRICE, STRIPE_PUBLIC_KEY} from "../../constants/proConstants";
import {useNavigate} from "react-router-dom";

const stripePromise = loadStripe(
    STRIPE_PUBLIC_KEY
);
const Shopping = (props) => {
    const [state, dispatch] = useImmerReducer(reducer, initialState)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch({type: 'get_total_price'});
        dispatch({type: 'set_purchase_ids'});
    }, [state.counts, state.subFee]);

    const token = localStorage.getItem('token')
    if (!token) {
        navigate('/business/prosignin', {state: {nextPage: '/business/shopping'}})
    }

    return (
        <Layout>
            <Content style={{background: '#E8E8E8'}}>
                {/*Navigation bar*/}
                <Row className={'progress-bar'}/>
                <Row justify={"center"} style={{
                    margin: '4rem 0 2rem 0'
                }}>
                    {state.curStep === 0 ?
                        <div className="sp-image-container">
                            <img src={bar1}/>
                            <div className="clickable-area-right"
                                 onClick={() => dispatch({
                                     type: 'set_step', step: 1
                                 })}/>
                        </div> :
                        <div className="sp-image-container">
                            <img src={bar2} style={{paddingTop: '1rem'}}/>
                            <div className="clickable-area-left"
                                 onClick={() => dispatch({
                                     type: 'set_step', step: 0
                                 })}/>
                        </div>
                    }
                </Row>

                {/*Select Canvases*/}
                {state.curStep === 0 && <Row justify={"center"} className={'t-row'}>
                    {[img1, img2, img3, img4].map((img, idx) =>
                        <Col lg={5} className={'t-col'}>
                            <div className={'shop-pd-col'}>
                                <img className={'shop-exp-image'} src={img}/>
                                <p className={'shop-pd-name'}>{IdxToCanvas[idx]} - £{SINGLE_PRICE}</p>
                            </div>
                            <div className={'shop-select-div'}>
                                <div className={'shop-select-op'}
                                     onClick={() => dispatch({type: 'minus', item: idx})}
                                >-
                                </div>
                                {state.counts[idx]}
                                <div className={'shop-select-op'}
                                     onClick={() => dispatch({type: 'add', item: idx})}
                                >+
                                </div>
                            </div>
                        </Col>)}
                </Row>}

                {/*Select Subscription*/}
                {state.curStep === 1 &&
                    <Row
                        justify={"center"}
                        style={{
                            columnGap: '1vw',
                            padding: '0 0 3rem 0'
                        }}
                    >
                        <Col className={'sub-option v2-shop-package-col'}>
                            <p className={'sub-opt-title'} style={{marginBottom: '6.8vh'}}>Package details</p>
                            <div className={'shop-divide-line-top-dt'}/>
                            {detailList.map((txt, idx) => {
                                return (
                                    <div>
                                        <div className={'sub-dt-div'}>
                                            <span className={'sub-dt-inner'}>{txt[0]}</span>
                                            <Tooltip title={txt[1]}><InfoIcon/></Tooltip>
                                        </div>
                                        <div className={'shop-divide-line-dt'}/>
                                    </div>
                                )
                            })}
                        </Col>
                        <Col className={'sub-option'}
                             style={state.active[0] === 0 ? {background: ' #D9D9D9', opacity: '0.5'} : {}}
                        >
                            <p className={'sub-opt-title'}>Standard</p>
                            <p className={'sub-opt-subtitle'} style={{marginBottom: '3.6vh'}}>Free</p>
                            <div className={'shop-divide-line-top'}/>
                            {standardList.map((txt, idx) => {
                                return (
                                    <div>
                                        <div className={'sub-features-div'}>
                                            <p className={'sub-features-inner'}>{txt}</p>
                                        </div>
                                        <div className={'shop-divide-line'}/>
                                    </div>
                                )
                            })}
                            <Button className={'shop-sub-opt-btn'}
                                    onClick={() => {
                                        dispatch({type: 'set_sub', sub: 0})
                                    }}
                                    disabled={state.totalCanvas > 2}
                            >
                                Add to basket
                            </Button>
                        </Col>
                        <Col className={'sub-option'}
                             style={state.active[1] === 0 ? {background: ' #D9D9D9', opacity: '0.5'} : {}}
                        >
                            <p className={'sub-opt-title'}>Advanced</p>
                            <p className={'sub-opt-subtitle'}>£99.00(ex VAT)</p>
                            <p className={'sub-opt-per-year'}>per device annually</p>
                            <div className={'shop-divide-line-top'}/>
                            {advancedList.map((txt, idx) => {
                                return (
                                    <div>
                                        <div className={'sub-features-div'}>
                                            <p className={'sub-features-inner'}>{txt}</p>
                                        </div>
                                        <div className={'shop-divide-line'}/>
                                    </div>
                                )
                            })}
                            <Button className={'shop-sub-opt-btn'}
                                    onClick={() => {
                                        dispatch({type: 'set_sub', sub: 1})
                                    }}
                            >{localStorage.getItem('tried') ? 'Add to basket' : 'Try free for 30 days'}</Button>
                        </Col>
                        <Col className={'sub-option'}
                             style={state.active[2] === 0 ? {background: '#D9D9D9', opacity: '0.7'} : {}}
                        >
                            <p className={'sub-opt-title'}>Premium</p>
                            <p className={'sub-opt-subtitle'}>£189.00 (ex VAT)</p>
                            <p className={'sub-opt-per-year'}>per device annually</p>
                            <div className={'shop-divide-line-top'}/>
                            {premiumList.map((txt, idx) => {
                                return (
                                    <div>
                                        <div className={'sub-features-div'}>
                                            <p className={'sub-features-inner'}>{txt}</p>
                                        </div>
                                        <div className={'shop-divide-line'}/>
                                    </div>
                                )
                            })}
                            <Button className={'shop-sub-opt-btn'}
                                    onClick={() => dispatch({
                                        type: 'set_sub',
                                        sub: 2
                                    })}
                            >{localStorage.getItem('tried') ? 'Add to basket' : 'Try free for 30 days'}</Button>
                        </Col>
                    </Row>
                }

                {/*Order Summary Information Section*/}
                <p className={'shop-order-summary'} hidden={state.totalCanvas === 0}>Order summary</p>
                <Row justify={"center"} gutter={[32, 32]} hidden={state.totalCanvas === 0} style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <Col lg={state.sub === null ? 15 : 12} className={'order-col'}>
                        {[img1, img2, img3, img4].map((img, idx) =>
                            state.counts[idx] > 0 ?
                                <div className={'shop-pd-col'} style={{marginTop: '15px'}}>
                                    <img src={img} style={{width: '45%'}} alt={'canvas'}/>
                                    <p className={'shop-pd-name'}>
                                        {IdxToCanvas[idx]} - £{SINGLE_PRICE} x {state.counts[idx]}
                                    </p>
                                </div> : <div></div>)}
                    </Col>
                    {state.sub === null && <Col lg={5} className={'order-col order-col-right'}>
                        <p className={'shop-txt-step-1-txt'}>
                            You have chosen {state.totalCanvas} Textura digital canvases, with
                            the total shown below
                        </p>
                        <p className={'shop-txt-total'}>Total</p>
                        <p className={'shop-txt-pay-num'}>£{state.totalCanvas * SINGLE_PRICE} excl. VAT</p>
                        <p className={'shop-txt-step-1-txt'}>
                            Click the ‘Next’ button below to select your level of subscription
                        </p>
                    </Col>}
                    {state.sub !== null &&
                        <Col lg={8} className={'order-col order-col-right'}>
                            <div className={'shop-final-summary-div-1'}>
                                <p className={'shop-txt-step-2-top-txt'} style={{margin: 'auto', marginLeft: '1vw'}}>
                                    <span
                                        className={'shop-final-summary-div-1-span-1'}>Textura digital canvas:</span> Qty {state.totalCanvas}
                                </p>
                                <p className={'shop-final-summary-div-1-p-2'} style={{margin: '-1vh auto auto 1vw'}}>3
                                    years warranty</p>
                            </div>
                            <div className={'shop-final-summary-div-2'}>
                                <p className={'shop-txt-new-ds-st'}>
                                    <span>Canvas Subtotal</span>
                                    <span>£{(state.totalCanvas * SINGLE_PRICE).toFixed(2)}</span>
                                </p>
                                <p className={'shop-txt-new-ds-st'}>
                                    <span>VAT @20%</span>
                                    <span>£{(state.totalCanvas * SINGLE_PRICE * 0.2).toFixed(2)}</span>
                                </p>
                                <p className={'shop-txt-new-ds-st'}>
                                    <span>Subscription</span>
                                    <span>£{state.subFee}</span>
                                </p>
                                <p className={'shop-txt-new-ds-st'}>
                                    <span>Canvas Total</span>
                                    <span>£{((state.totalCanvas * SINGLE_PRICE) * 1.2).toFixed(2)}</span>
                                </p>
                            </div>
                            <div className={'shop-final-summary-div-3'}>
                                <p className={'shop-txt-step-2-top-txt'} style={{margin: '0 0 0 1vw'}}>
                                    <span style={{fontWeight: '700'}}>Subscription type:</span> {state.sub === 1 ?
                                    'Advanced: £99.00 ex VAT for 12 months. Billed annually' :
                                    state.sub === 0 ? 'Standard: Free' : 'Premium: £189.00 ex VAT for 12 months. Billed annually'}
                                </p>
                            </div>
                        </Col>}

                </Row>
                <Row justify={'end'}
                     style={{
                         display: state.curStep === 1 ? 'flex' : 'none',
                         margin: '1vh 6vw -2vh 0',
                     }}
                     className={'shop-accept-tc-checkbox'}
                >
                    <Checkbox onChange={(e) => {
                        dispatch({type: 'set_agreement', agreed: e.target.checked})
                    }}>
                        <p className={'shop-txt-new-ds-btm'} style={{margin: '0px 0 -2px 0'}}>
                            I accept the conditions of the 30 days free trial.
                        </p>
                        <p className={'shop-txt-new-ds-btm'}
                           style={{
                               fontWeight: '600',
                               textDecoration: 'underline',
                               cursor: 'pointer'
                           }}
                           onClick={_ =>
                               window.open('http://192.168.1.140/proportal/ProPaymentAgreement', '_blank')
                           }
                        >
                            Read the Vieunite Limited Terms & Conditions
                        </p>
                    </Checkbox>
                </Row>
                <Row justify={"center"} className={'shop-step-row'}>
                    <Button
                        className={'shop-step-btn'}
                        onClick={() => {
                            dispatch({
                                type: 'set_step', step: state.curStep - 1
                            })
                            dispatch({
                                type: 'set_sub', sub: null
                            })
                        }}
                        disabled={state.curStep <= 0}
                    >
                        Back
                    </Button>
                    <Tooltip title={state.curStep === 1 && (!state.totalCanvas || !state.sub || !state.agreed) ?
                        'Select at least one Textura Pro Digital Canvas along with a subscription plan, and then agree to the terms and conditions to proceed to the next step.' : ''}
                        // color={'blue'}
                    >
                        <Button className={'shop-step-btn'}
                                onClick={() => {
                                    switch (state.curStep) {
                                        case 0:
                                            if (state.total > SINGLE_PRICE * 3) {
                                                dispatch({
                                                    type: 'set_active_sub',
                                                    active: [1, 0, 1]
                                                })
                                            }
                                            dispatch({type: 'set_step', step: state.curStep + 1})
                                            break
                                        case 1://Able to pay now
                                            post_api('/shopping/cart/directly/pay/texturas', {
                                                "textura_purchase_ids": state.purchaseIDs,
                                                "subscription_type": state.sub
                                            }).then((res) => {
                                                console.log(res)
                                                if (res.automaticCharge === true) {//second time purchase
                                                    navigate('/business/success')
                                                    throw new Error('Automatic charge')
                                                } else {
                                                    window.open(res.pay_url)
                                                    throw new Error('pay')
                                                }
                                                // dispatch({
                                                //     type: 'set_clientSecret',
                                                //     clientSecret: res?.clientSecret
                                                // })
                                            })
                                                // .then(() => {
                                                // dispatch({
                                                //     type: 'set_pay_modal',
                                                //     isPayModalOpen: true
                                                // })
                                                // })
                                                .catch(error => console.log('error', error));
                                            break
                                    }
                                    let topBlueBar = document.querySelector('.progress-bar');
                                    topBlueBar.scrollIntoView();
                                }}
                                disabled={state.curStep === 1 && (!state.totalCanvas || state.sub === null || !state.agreed)}
                        >
                            {state.curStep === 1 && state.totalCanvas > 0 && state.sub >= 0 && state.agreed ? 'Pay now' : 'Next'}
                            {/*<span className={'shop-step-btn-span'}>Next</span>*/}
                        </Button>
                    </Tooltip>
                </Row>
            </Content>
            <Modal className={'portal-payment-modal'}
                   visible={state.isPayModalOpen}
                   onCancel={_ => dispatch({type: 'set_pay_modal', isPayModalOpen: false})}
            >
                {/*<Col>*/}
                {/*    Stripe Receipt*/}
                {/*</Col>*/}
                <Elements stripe={stripePromise} options={{
                    clientSecret: state.clientSecret,
                }}>
                    <CheckoutForm dispatch={dispatch}/>
                </Elements>
            </Modal>
        </Layout>
    )
}

export default Shopping;
