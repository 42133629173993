import React from "react";
import {Helmet} from "react-helmet";

export const A7Meta = () => (
    <Helmet>
        <title>Christian Redermayer | Vieunite</title>
        <meta name="description"
              content="He considers his photography as an 'autobiographical photography' intertwined with his life and fed by the emotions that his interaction with the world triggers."/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@ VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Christian Redermayer"/>
        <meta name="twitter:description"
              value="Christian Redermayer crafts architectural photographs. Explore his captivating pieces on Vieunite's digital art displays."/>
        <meta name="twitter:url" value="https://vieunite.com/artists/christian-redermayer"/>
        <meta name="twitter:image" value=" https://vieunite.com/images/vieunite_sc_christian_redermayer.jpg"/>
        <meta property="og:url" content=" https://vieunite.com/artists/christian-redermayer"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Vieunite Artist | Christian Redermayer "/>
        <meta property="og:description" content=" Christian Redermayer crafts architectural photographs. Explore his
              captivating pieces on Vieunite's digital art displays."/>
        <meta property="og:image" content="https://vieunite.com images/vieunite_sc_christian_redermayer.jpg"/>
    </Helmet>);