import { GA4React } from "ga-4-react";

const ga4react = new GA4React("G-Q2GKF9FJ3F").initialize();


const PageViewTracking = (data) => {
    const { path, search, title } = data;
    ga4react
        .then((ga) => {
            ga.pageview(path, search, title);
        })
        .catch((err) => console.error(`Analytics failed: ${err}`));
};

export default PageViewTracking;