import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import DiscoveryGalleryCardView from "./CommonCardView"
import { Skeleton } from 'antd';
import './CommonCardView.css';

import gallery1 from './images/birmingham.png'
import gallery2 from './images/chicago.png'
import gallery3 from './images/rbsa.png'
import gallery4 from './images/scotland.png'
import gallery5 from './images/washington.png'

const DiscoverySlick = (props) => {
  // const [gallleries, setGalleries] = useState([]);

  // const galleryList = [{
  //   "id": "1",
  //   "name": "Birmingham Art Gallery",
  //   "url": gallery1,
  // }, {
  //   "id": "2",
  //   "name": "Art Institute Chicago",
  //   "url": gallery2,
  // }, {
  //   "id": "3",
  //   "name": "RBSA Gallery",
  //   "url": gallery3,
  // }, {
  //   "id": "4",
  //   "name": "National Gallery of Scotland",
  //   "url": gallery4
  // }, {
  //   "id": "5",
  //   "name": "National Gallery of Washington",
  //   "url": gallery5
  // }, {
  //   "id": "1",
  //   "name": "Birmingham Art Gallery",
  //   "url": gallery1,
  // }, {
  //   "id": "2",
  //   "name": "Art Institute Chicago",
  //   "url": gallery2,
  // }, {
  //   "id": "3",
  //   "name": "RBSA Gallery",
  //   "url": gallery3,
  // }, {
  //   "id": "4",
  //   "name": "National Gallery of Scotland",
  //   "url": gallery4
  // }, {
  //   "id": "5",
  //   "name": "National Gallery of Washington",
  //   "url": gallery5
  // }]

  // useEffect(() => {
  //   setGalleries(galleryList)
  // }, [])

  var sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="41" viewBox="0 0 25 41" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.32977 36.6463C0.353459 37.6226 0.353459 39.2055 1.32977 40.1818C2.30608 41.1581 3.88899 41.1581 4.8653 40.1818L14.4377 30.6094L24.0101 21.0371L23.9733 21.0003L24.1172 20.8564L4.97243 1.71161C3.99612 0.735296 2.41321 0.735296 1.4369 1.71161C0.460586 2.68792 0.460586 4.27083 1.4369 5.24714L17.0829 20.8932L10.9022 27.0739L1.32977 36.6463Z" fill="#0E487A" />
    </svg>,
    prevArrow: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="41" viewBox="0 0 25 41" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.6761 36.6463C24.6524 37.6226 24.6524 39.2055 23.6761 40.1818C22.6998 41.1581 21.1169 41.1581 20.1406 40.1818L10.5682 30.6094L0.995795 21.0371L1.03258 21.0003L0.88867 20.8564L20.0334 1.71161C21.0097 0.735296 22.5927 0.735296 23.569 1.71161C24.5453 2.68792 24.5453 4.27083 23.569 5.24714L7.92295 20.8932L14.1037 27.0739L23.6761 36.6463Z" fill="#0E487A" />
    </svg>,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
          breakpoint: 1000,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }
    ]
  };
  return (
    <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
      <p className='collection-title'> Galleries & Museums </p>
      {/*props.gallleries.length === 0 && (
        <Skeleton active />
      )*/}
      {props.galleries.length !== 0 && (
        <Slider {...sliderSettings}>
          {props.galleries.map((item, index) => {
            // 从item中获取web_version_url字段的值
            const webVersionUrl = item.web_version_url;
            // 将item中的url字段的值替换为web_version_url字段的值
            const newItem = {
              ...item,
              url: webVersionUrl, // 替换url字段的值为web_version_url字段的值
              pageType: "gallery"
            };
            return (
              <div key={index}>
                <DiscoveryGalleryCardView item={newItem} />
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
}

export default DiscoverySlick;