import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import {Primary_Font} from "../../../../../settings";
import {ChargeText} from "../text";
import "./ChargeModal.scss"
import useWindowResize from "../../../../Hooks/useWindowResize";

const ChargeModal = (props) => {
    const {windowWidth} = useWindowResize()
    const {isModalOpen,closeModal} = props;
    return (
        <div >
            <Modal title={null}
                   className={'charge-modal'}
                   visible={isModalOpen}
                   onCancel={closeModal}
                   footer={null}
                   width={windowWidth>600?'60%':'100%'}
            >
                <p className={'charge-md-title'}>{ChargeText.VAT_title}</p>
                <p>{ChargeText.VAT_text}</p>
                <p className={'charge-md-title'}>{ChargeText.fee_title}</p>
                <p>{ChargeText.fee_text}</p>
            </Modal>
        </div>
    );
};

export default ChargeModal;