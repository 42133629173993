import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Progress } from 'antd';

const ImagePage2 = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location;
    const { imageUrls } = state || {};

    // Slice the second batch of images (from index 5 to 9)
    const limitedImageUrls = imageUrls.slice(5, 10);
    const lastIndex = limitedImageUrls.length - 1;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [progress, setProgress] = useState(0);

    // Image cycling logic
    useEffect(() => {
        if (limitedImageUrls.length > 0) {
            const intervalId = setInterval(() => {
                setCurrentIndex((prevIndex) => {
                    if (prevIndex < lastIndex) {
                        return prevIndex + 1;
                    } else {
                        clearInterval(intervalId);
                        return prevIndex;
                    }
                });

                setProgress((currentIndex + 1) * (100 / (lastIndex + 1)));
            }, 5000);

            return () => clearInterval(intervalId);
        }
    }, [limitedImageUrls, lastIndex, currentIndex]);

    // Navigation to the next page after the last image is displayed
    useEffect(() => {
        if (currentIndex === lastIndex) {
            const timeoutId = setTimeout(() => {
                const imageData = {
                    files_list: limitedImageUrls.map((url) => ({ url })),
                };

                localStorage.setItem('imageData', JSON.stringify(imageData));
                navigate('/Page7');
            }, 5000);

            return () => clearTimeout(timeoutId);
        }
    }, [currentIndex, lastIndex, limitedImageUrls, navigate]);

    if (!limitedImageUrls || limitedImageUrls.length === 0) {
        return <div>No images available</div>;
    }

    const containerStyle = {
        height: '80vh',
        marginLeft: '12vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const imageStyle = {
        height: '60vh',
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vw' }}>


            {/* Image container */}
            <div style={containerStyle}>
                <img src={limitedImageUrls[currentIndex]} alt={`Image ${currentIndex + 1}`} style={imageStyle} />
            </div>

            {/* Image counter */}
            <div style={{ width: '100%', textAlign: 'center', fontSize: '14px' }}>
                {currentIndex + 1}/{limitedImageUrls.length}
            </div>
        </div>
    );
};

export default ImagePage2;
