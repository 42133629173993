import React, {useEffect, useState} from 'react';
import './style.scss';
import {Collapse, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import ProPortalHeader from "../../componets/ProPortalHeader";
import ProfileRow from "../../componets/ProfileRow";
import Navigator from "../../componets/Navigator";

const { Panel } = Collapse;
const insertHyperlinks = (answer) => {
    const hyperlinkRegex = /\bhere\b/g; // This regex matches "here" as a standalone word
    return answer.split(hyperlinkRegex).map((part, index, array) => (
        <React.Fragment key={index}>
            {part}
            {index < array.length - 1 && <a href="https://www.youtube.com/watch?v=X3dItvqKT2s" target="_blank" rel="noopener noreferrer">here</a>}
        </React.Fragment>
    ));
};

const insertMailtoLink = (answer) => {
    const emailRegex = /Support@vieunite.com/g;
    return answer.split(emailRegex).map((part, index, array) => (
        <React.Fragment key={index}>
            {part}
            {index < array.length - 1 && <a href="mailto:Support@vieunite.com">support@vieunite.com</a>}
        </React.Fragment>
    ));
};


const MainContent = () => {
    const faqs = [
        {
            key: '1',
            question: '1. Connecting your Canvas',
            answer: 'Begin by linking your canvas to your Vieunite account through the application. Visit the home page and download the Vieunite Pro connection app to associate the Textura digital canvas with your business portal. To streamline the sign-in process within the app, scan the QR code available on the home page.\n' +
                '\n' +
                'After successfully connecting your canvas to your account, you can proceed with updates. For initial updates, refer to the "Getting Started" video. This resource guides you on sending art from your PC to your canvas and provides a comprehensive overview of the software.\n'
        },
        {
            key: '2',
            question: '2. Upload personal media files',
            answer: 'You can do this within the “My Library section” of the application. \n'  +
                '1 of 3. Begin by selecting “My Library” on the home-page of the Vieunite pro software. \n' +
                '2 of 3. Navigate to “Upload Files” button within the library.\n' +
                '3 of 3. Upload your art work to the system here.\n'
        },
        {
            key: '3',
            question: '3. How do I preview art on my Textura Digital Canvas before purchasing',
            answer: 'You can preview art on your textura digital canvas prior to purchasing just to ensure this is the correct piece of art work for your environment. You can do this when selecting any piece of art-work within the art library.\n' +
                '\n' +
                '1 of 3. Begin by selecting “Discover Art” on the home-page of the Vieunite Pro software.\n' +
                '2 of 3. Navigate and select a piece of art-work you wish to preview on your Textura digital canvas.\n' +
                '3 of 3. Select the button “Preview it on Textura” to push this piece of content to your Textura Digital Canvas.\n'
        },
        {
            key: '4',
            question: '4. What are the recommendedsupport media formats for my Canvas',
            answer: 'Before uploading any files to the Vieunite Pro software please ensure that they meet the requirements below:\n' +
                'Image:\n' +
                'Container - JPEG\n' +
                'Compression - Baseline\n' +
                'Colour Mode - RGB\n' +
                'Width - 1920 (1080 for portrait)\n' +
                'Height - 1080 (1920 for portrait)\n' +
                'DPI - 300\n' +
                '\n' +
                'Video:\n' +
                'Container - MP4, AVI, MPG\n' +
                'Codec - MPEG-1, MPEG-2, MPEG-4, XVID*, H.264\n' +
                'Width - 1920 (1080 for portrait)\n' +
                'Height - 1080 (1920 for portrait\n' +
                'Audio - AAC or MP3\n' +
                '\n' +
                'For help with resizing content, please see the Best Quality Content for your Textura digital canvas here\n'
        },
        {
            key: '5',
            question: '5. My Art looks stretched, unfocused or blurry',
            answer: 'Please ensure your content is created using the following format and resolution to ensure the best image quality on the canvas:\n' +
                '\n' +
                'Image:\n' +
                'Container - JPEG\n' +
                'Compression - Baseline\n' +
                'Colour Mode - RGB\n' +
                'Width - 1920 (1080 for portrait)\n' +
                'Height - 1080 (1920 for portrait)\n' +
                'DPI - 300\n' +
                '\n' +
                'Video:\n' +
                'Container - MP4, AVI, MPG\n' +
                'Codec - MPEG-1, MPEG-2, MPEG-4, XVID*, H.264\n' +
                'Width - 1920 (1080 for portrait)\n' +
                'Height - 1080 (1920 for portrait\n' +
                'Audio - AAC or MP3\n' +
                '\n' +
                '\n' +
                'For help with resizing content, please see the best quality content for your Textura digital canvas here\n'
        },
        {
            key: '6',
            question: '6. How do i reset my device to factory default',
            answer: 'If your canvas is not as smooth as it previously was, or you just simply want to get it back to factory default standards, please follow the steps below:\n' +
                '\n' +
                '1 of 4. Remove your canvas from the wall and turn it around to access the back.\n' +
                '\n' +
                '2 of 4. Locate the area pictured below on the back of the canvas.\n' +
                '\n' +
                '3 of 4. Take a small object, such as a paperclip, and insert it into the circled area. There is one included in the accessory pack with the unit. \n' +
                '\n' +
                '4 of 4. Keep the object inserted for approximately 10 seconds until the device is completely reset.\n' +
                '\n' +
                'By performing these steps, you will successfully reset the canvas device to its default settings.\n' +
                '\n' +
                'For help with resetting the device, please see the video tutorial on how to do so here'
        },
        {
            key: '7',
            question: '7. My Device won’t turn on',
            answer: 'If your canvas is turning on as expected, or generally showing no sign of life, please follow these steps listed below.\n' +
                '\n' +
                'Firstly, please confirm that your canvas is connected to a functional wall socket and ensure that the socket is switched on. Additionally, check that the USB Type-C connection is securely plugged into the canvas.\n' +
                '\n' +
                '\n' +
                'If you are still facing issues with this specific instance and the canvas is no longer powering on as intended, please contact us on: Support@vieunite.com \n'
        },
        {
            key: '8',
            question: '8. How do i change the orientation of my texutra digital canvas',
            answer: 'If you wish to change the orientation of your textura digital canvas, please follow the steps listed below:  \n' +
                '\n' +
                '1 of 4. Begin by rotating the canvas and removing the frame off the canvas.\n' +
                '\n' +
                '2 of 4. Remove the canvas from the wall.\n' +
                '\n' +
                '3 of 4. Re-seat the canvas in the desired orientation on the mounting bracket\n' +
                '\n' +
                '4 of 4. Re-add the frame onto the Canvas and this should automatically power on.\n' +
                '\n' +
                'For further help with orientation mounting, please see a video preview here\n'
        }
    ];
    // Custom expand icon
    const customExpandIcon = (panelProps) => {
        return (
            <i
                className={`arrow ${panelProps.isActive ? 'up' : 'down'}`}
            />
        );
    }
    return (
        <Collapse
            accordion
            className="custom-collapse"
            expandIconPosition="right"
            expandIcon={({ isActive }) => customExpandIcon({ isActive })}
        >
            {faqs.map(faq => (
                <Panel className="ProQAPanel" header={faq.question} key={faq.key}>
                    <div className="faq-answer">
                        {faq.key === '7' ? insertMailtoLink(faq.answer) :
                            ['4', '5', '6', '8'].includes(faq.key) ? insertHyperlinks(faq.answer) :
                                faq.answer.split('\n').map((line, index, array) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        {index < array.length - 1 ? <br /> : null}
                                    </React.Fragment>
                                ))
                        }
                    </div>
                </Panel>
            ))}
        </Collapse>
    );
};

const ProportalQA = () => {
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <div className='ProPortalQA'>
                                <p className='ProPortalQA-title'>Frequently Asked Questions</p>
                                <MainContent/>
                            </div>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    )
}
export default ProportalQA;