import {useNavigate} from 'react-router-dom';
import React, {useState, useEffect, useRef} from 'react';
import {TLayout, TLayoutReversed, TLayoutMobile, TLayoutMobileStyle2} from './ProTLayout'
import section_background_1 from './img/background-1.png'
import section_background_2 from './img/background-2.png'
import section_background_3 from './img/background-3.png'
import ProDynamicTable from "./ProDynamicTable";
import {Col, Collapse, Row} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {PRODUCT_TEXT} from "../../text";
import img1 from "../ProCaseStudy/img/Employee 1.png";
import {Modal} from "antd";
import useWindowResize from "../../apps/Hooks/useWindowResize";
import ContactSection from "../ProCaseStudy/components/ContactSection";
import ProVerticalTable from "./ProVerticalTable";
import {Divider, Steps} from 'antd';
import {Button, Radio} from 'antd';
import {Card} from 'antd';
import {quizRules} from "./QuizRule";

function Subscription() {
    const navigate = useNavigate();
    const {windowWidth} = useWindowResize();
    const {Step} = Steps;
    const checkWidth = windowWidth <= 1270;

    const proDynamicTableRefDesktop = useRef(null);
    const proDynamicTableRefMobile = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
    useEffect(() => {
        // Update `isMobile` state based on window resize
        const handleResize = () => setIsMobile(window.innerWidth <= 900);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Define the scroll function
    const scrollToSection = () => {
        const ref = isMobile ? proDynamicTableRefMobile : proDynamicTableRefDesktop;
        ref.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
    };

    const {Panel} = Collapse;

    // State variables to handle quiz and steps
    const [quizStarted, setQuizStarted] = useState(false);  // Modal state
    const [step, setStep] = useState(1);  // Current step of the quiz
    const closeModal = () => {
        console.log("Closing modal");
        setQuizStarted(false);
    };
    const [selectedScreens, setSelectedScreens] = useState('');
    const [artworkType, setArtworkType] = useState('');
    const [contentChange, setContentChange] = useState('');
    const [manageArtworks, setManageArtworks] = useState('');
    const [result, setResult] = useState();
    const [openTour, setOpenTour] = React.useState(false);

    const resetStates = () => {
        setSelectedScreens('');
        setArtworkType('');
        setContentChange('');
        setManageArtworks('');
    };
    const closeQuizModal = () => {
        resetStates();  // Reset state variables when modal is closed
        setQuizStarted(false);
    };


    const handlePrevious = () => setStep(prevStep => Math.max(1, prevStep - 1));
    const handleNext = () => setStep(prevStep => prevStep + 1);
    const startQuiz = () => {
        setQuizStarted(true);
        setStep(1);
    }

    const clearCache = () => {
        localStorage.removeItem('quizData'); // Example cache-clearing logic
    };

    const handleFinish = () => {
        clearCache();
        resetStates();
        setQuizStarted(false);     // Close the modal
        setStep(1);             // Reset to the initial step
    };

    // Storage variables for chosen options
    const handleSelection = (value) => {
        setSelectedScreens(value);

    };
    const handleSelection2 = (value) => {
        setArtworkType(value);

    };
    const handleSelection3 = (value) => {
        setContentChange(value);

    };
    const handleSelection4 = (value) => {
        setManageArtworks(value);

    };

    const getResults = () => {
        return {
            selectedScreens,
            artworkType,
            contentChange,
            manageArtworks
        };
    }

    const [plan, setPlan] = useState('');
    const [tourMessage, setTourMessage] = useState('');
    const handleLogic = () => {
        for (const key in quizRules) {
            if (quizRules[key].condition(result)) {
                setPlan(quizRules[key].plan);
                setTourMessage(quizRules[key].message);
                return;
            }
        }
        throw new Error('No matching rule found');

    };

    useEffect(() => {
        if (result) {
            handleLogic();
        }
    }, [result]);


    const CompletedStepIcon = () => (
        <div style={{position: 'relative', width: '20px', height: '20px'}}>
            {/* Outer Circle */}
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                 viewBox="0 0 20 20" fill="none"
                 style={{position: 'absolute', top: '0', left: '0'}}>
                <circle cx="10" cy="10" r="9.5" fill="white" stroke="black"/>
            </svg>
            {/* Inner Circle */}
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                 viewBox="0 0 12 12" fill="none"
                 style={{position: 'absolute', top: '4px', left: '4px'}}>
                <circle cx="6" cy="6" r="6" fill="#06467A"/>
            </svg>
        </div>
    );


    const PendingStepIcon = () => (
        <div style={{position: 'relative', width: '20px', height: '20px'}}>
            {/* Outer Circle */}
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                 viewBox="0 0 20 20" fill="none"
                 style={{position: 'absolute', top: '0', left: '0'}}>
                <circle cx="10" cy="10" r="9.5" fill="white" stroke="black"/>
            </svg>
        </div>
    );
    const QuizHeaderIcon = () => (
        <div style={{textAlign: 'center', paddingTop: '2vh'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50"
                 viewBox="0 0 51 50"
                 fill="none">
                <path
                    d="M18.8152 49.0351L0 0.332031H11.8497L25.3089 37.7404L38.7404 0.332031H50.6179L31.7749 49.0351H18.8152Z"
                    fill="black"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="50"
                 viewBox="0 0 13 50"
                 fill="none">
                <path
                    d="M0.920898 5.3837C0.920898 2.30333 3.41849 0 6.4711 0C9.52372 0 12.0213 2.41434 12.0213 5.3837C12.0213 8.35306 9.52372 10.9339 6.4711 10.9339C3.41849 10.9339 0.920898 8.51956 0.920898 5.3837ZM1.83668 14.7635H11.1333V49.9518H1.83668V14.7635Z"
                    fill="black"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="38"
                 viewBox="0 0 36 38"
                 fill="none">
                <path
                    d="M0.0146484 19.3577C0.0146484 9.14534 7.56292 0.847778 18.2748 0.847778C28.9867 0.847778 35.9522 8.64581 35.9522 20.2735V22.3548H9.72751C10.3935 26.8227 13.8624 30.4859 19.8566 30.4859C25.8509 30.4859 26.9886 29.1538 29.2365 26.906L33.3159 32.8725C29.736 36.1193 24.1858 37.7566 18.8021 37.7566C8.2567 37.8399 0.0424012 30.6246 0.0424012 19.33M18.3581 8.22955C12.6414 8.22955 10.1438 12.531 9.72751 16.0276H27.0719C26.8221 12.6142 24.4078 8.22955 18.3581 8.22955Z"
                    fill="black"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="37"
                 viewBox="0 0 34 37"
                 fill="none">
                <path
                    d="M24.3953 36.0351V31.5672C21.981 34.3145 17.6796 36.8676 11.9629 36.8676C6.24617 36.8676 0.584961 32.5662 0.584961 25.8227V0.76355H9.88155V22.1596C9.88155 27.0438 12.3791 28.6256 16.3475 28.6256C20.3159 28.6256 22.7303 26.6275 24.3953 24.6294V0.902305H33.6919V36.0906L24.3953 36.0073V36.0351Z"
                    fill="black"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="38"
                 viewBox="0 0 35 38"
                 fill="none">
                <path
                    d="M24.7156 37.0351V15.7223C24.7156 10.8381 22.2181 9.17308 18.2497 9.17308C14.2813 9.17308 11.7837 11.2544 10.2019 13.308V37.0351H0.905273V1.76356H10.2019V6.31473C12.533 3.56738 16.7511 0.93103 22.5511 0.93103C28.351 0.93103 34.0122 5.31569 34.0122 12.2257V37.1184L24.7156 37.0351Z"
                    fill="black"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="50"
                 viewBox="0 0 12 50"
                 fill="none">
                <path
                    d="M0.143555 5.3837C0.143555 2.30333 2.64115 0 5.69376 0C8.74637 0 11.244 2.41434 11.244 5.3837C11.244 8.35306 8.74637 10.9339 5.69376 10.9339C2.64115 10.9339 0.143555 8.51956 0.143555 5.3837ZM1.05935 14.7635H10.3559V49.9518H1.05935V14.7635Z"
                    fill="black"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="46"
                 viewBox="0 0 25 46"
                 fill="none">
                <path
                    d="M6.28921 36.238V17.8113H0.489258V9.76352H6.28921V0.217163H15.5858V9.84677H22.7178V17.8946H15.5858V33.7404C15.5858 35.905 16.7513 37.6533 18.8327 37.6533C20.914 37.6533 21.58 37.1538 22.0795 36.571L24.0776 43.5365C22.7456 44.7853 20.1647 45.8676 16.3628 45.8676C9.89684 45.8676 6.31697 42.4542 6.31697 36.238"
                    fill="black"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="38"
                 viewBox="0 0 37 38"
                 fill="none">
                <path
                    d="M0.880859 19.3577C0.880859 9.14534 8.42915 0.847778 19.141 0.847778C29.8529 0.847778 36.8184 8.64581 36.8184 20.2735V22.3548H10.5937C11.2597 26.8227 14.7286 30.4859 20.7228 30.4859C26.7171 30.4859 27.8549 29.1538 30.1027 26.906L34.1821 32.8725C30.6022 36.1193 25.052 37.7566 19.6683 37.7566C9.03965 37.8399 0.908604 30.6246 0.908604 19.33M19.2243 8.22955C13.5076 8.22955 11.01 12.531 10.5937 16.0276H27.9381C27.6883 12.6142 25.274 8.22955 19.2243 8.22955Z"
                    fill="black"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                 viewBox="0 0 13 13"
                 fill="none">
                <path
                    d="M0.369141 6.90116C0.369141 3.65429 3.11649 0.934692 6.33561 0.934692C9.55473 0.934692 12.3021 3.68204 12.3021 6.90116C12.3021 10.1203 9.55473 12.8676 6.33561 12.8676C3.08874 12.8676 0.369141 10.1203 0.369141 6.90116Z"
                    fill="black"/>
            </svg>
        </div>
    );
    const QuizHeaderIcon2 = () => (

        <div style={{
            marginLeft: '7vw',
            textAlign: 'center'
        }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="103" height="38"
                 viewBox="0 0 103 38"
                 fill="none">
                <path
                    d="M0.970703 37.2701L8.90749 1.22156H21.562C27.2232 1.22156 31.0251 5.43971 31.0251 10.2406C31.0251 15.0416 27.2509 22.2846 17.6768 22.2846H7.40894L4.05106 37.2424H0.970703V37.2701ZM18.0376 19.4817C24.4758 19.4817 27.7227 15.2081 27.7227 10.4627C27.7227 5.71723 24.9198 4.02441 21.0624 4.02441H11.3773L7.96396 19.4817H18.0098H18.0376Z"
                    fill="#0E477A"/>
                <path
                    d="M53.1706 37.2701L46.3716 22.3678H38.2128L34.9104 37.2701H31.8301L39.7669 1.22156H52.1438C57.0003 1.22156 61.9122 4.52393 61.9122 10.2961C61.9122 16.0684 57.0558 21.9793 49.5907 22.2291L56.6672 37.2424H53.1429L53.1706 37.2701ZM48.897 19.5372C55.2242 19.5372 58.5821 15.3191 58.5821 10.4627C58.5821 5.60622 55.2242 4.02441 51.7831 4.02441H42.2645L38.8511 19.5372H48.897Z"
                    fill="#0E477A"/>
                <path
                    d="M85.9714 0.611084C94.8795 0.611084 102.122 6.18904 102.122 16.2349C102.122 26.2808 93.1589 37.9085 82.1695 37.9085C71.1801 37.9085 65.9629 32.3305 65.9629 22.2846C65.9629 12.2388 74.9265 0.611084 85.9436 0.611084H85.9714ZM85.6939 3.44168C76.9523 3.44168 69.3208 11.2675 69.3208 22.2014C69.3208 33.1353 75.204 35.1056 82.336 35.1056C89.468 35.1056 98.7646 27.2798 98.7646 16.3459C98.7646 5.41201 92.8259 3.44168 85.6939 3.44168Z"
                    fill="#0E477A"/>
            </svg>
        </div>

    );


    return (

        <div>
            {/* Desktop Version */}
            <div className={"subscription-container"}>
                <div style={{fontFamily: 'Proxima Nova'}}>
                    {/* Top Section with amber background color */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        backgroundColor: '#FFF5EC',
                        paddingTop: '5vh',
                    }}>
                        <div style={{
                            maxWidth: '50vw',
                            marginTop: '5vw',
                            marginBottom: '0'

                        }}>
                            <p style={{
                                fontSize: '3rem',
                                fontWeight: '400',
                                color: '#000',
                                fontStyle: 'normal',

                                lineHeight: '1'
                            }}>
                                Choose the Right Plan for You
                            </p>
                            <p style={{
                                textAlign: 'center',
                                maxWidth: '40vw',
                                fontSize: '1rem',
                                fontWeight: '400',
                                lineHeight: '1.5',
                                color: '#000',
                                justifyContent: 'center',
                                fontStyle: 'normal',
                                marginLeft: '5vw',
                                marginRight: '5vw'
                            }}>
                                Easily browse through our selection of art playlists, masterfully curated by our
                                expert team. Regularly updated with fresh, high-quality content, Vieunite Pro is the
                                perfect solution for businesses to effortlessly showcase stunning art collections.
                            </p>

                        </div>
                        <div style={{
                            maxWidth: '50vw',
                            textAlign: 'center',
                            marginTop: '2vh'

                        }}>
                            <p style={{
                                fontSize: '0.875rem',
                                fontWeight: '600',
                                color: '#936846',
                                fontStyle: 'italic'

                            }}>
                                Not sure which subscription is right for you? Answer a few quick questions to find
                                the
                                best
                                fit
                                for your needs.
                            </p>

                            <button
                                className="homeMainButton black-border-hover-white"
                                onClick={startQuiz}  // Start quiz when button is clicked
                                style={{width: "20vw", marginLeft: '2vw', marginTop: '2vh', marginBottom: '5vh'}}
                            >
                                <p className={'homeMainButtonLnk homeOverV'}
                                   style={{margin: 0, fontSize: '1rem', fontWeight: '700', textAlign: 'center'}}>
                                    TAKE OUR QUIZ
                                </p>
                            </button>
                        </div>
                    </div>
                    {/* Modal for Quiz Popup */}
                    <Modal

                        visible={quizStarted}
                        onCancel={closeQuizModal}
                        footer={null}
                        width="55vw"
                        bodyStyle={{
                            padding: '2rem',
                            overflow: 'hidden',
                            height: 'auto',
                        }}
                        style={{

                            overflow: 'hidden',
                            paddingBottom: 0,
                        }}
                        maskStyle={{

                            overflow: 'hidden',
                        }}
                        wrapClassName="custom-rounded-modal"
                    >
                        {/* Modal Content */}
                        <div style={{
                            borderRadius: '1vw',
                            overflow: 'hidden',
                        }}>


                            {/* Step 1 */}
                            {step === 1 && (
                                <>

                                    <QuizHeaderIcon/>
                                    <QuizHeaderIcon2/>


                                    <div style={{//height: '25vh',
                                    }}>
                                        <h2 style={{
                                            marginTop: '4vh',
                                            fontSize: '2.125rem',
                                            textAlign: 'left',
                                            marginLeft: '3vw',
                                            fontWeight: '400',
                                            fontStyle: 'normal',
                                            fontFamily: 'Proxima Nova',
                                            display: 'flex',
                                        }}>
                                            How many displays does your company need?
                                        </h2>
                                        <form style={{
                                            marginTop: '2vh',
                                            textAlign: 'left',
                                            marginLeft: '3vw',
                                            fontSize: '1rem',
                                            fontWeight: '500',
                                            fontStyle: 'normal',
                                            fontFamily: 'Proxima Nova',

                                        }}>
                                            <Radio.Group
                                                name="screens"
                                                onChange={(e) => handleSelection(e.target.value)}
                                                value={selectedScreens}  // Controlled component with selected value
                                            >
                                                <Radio
                                                    value="1"
                                                    style={{display: 'block', marginBottom: '1vh'}}
                                                >
                                                    1-2 screens
                                                </Radio>
                                                <Radio
                                                    value="2"
                                                    style={{display: 'block', marginBottom: '2vh'}}
                                                >
                                                    Multiple screens
                                                </Radio>
                                            </Radio.Group>
                                        </form>
                                    </div>


                                    {/* Previous & Next button */}
                                    <div style={{
                                        display: 'flex',
                                        marginLeft: '3vw',
                                        marginTop: '2vh'

                                    }}>
                                        <button
                                            className="homeMainButton"
                                            onClick={handlePrevious}
                                            disabled={step === 1}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.boxShadow = 'none';  // Disable hover effect right when mouse enters
                                            }}
                                            onMouseEnter={(e) => {
                                                e.currentTarget.style.boxShadow = 'none';  // Ensure box-shadow is removed on hover
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.style.boxShadow = 'none';  // Remove box-shadow on mouse leave
                                            }}
                                            style={{
                                                width: "8rem",
                                                height: "2.5rem",
                                                marginTop: '2rem',
                                                marginBottom: '2rem',
                                                marginRight: '1rem',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '2px solid grey',
                                                color: 'grey',
                                                backgroundColor: 'white',
                                                cursor: step === 1 ? 'not-allowed' : 'pointer',
                                            }}
                                        >
                                            <p
                                                className={'homeMainButtonLnk'}
                                                style={{
                                                    margin: 0,
                                                    fontSize: '0.875rem',
                                                    fontWeight: '700',
                                                    textAlign: 'center',
                                                    color: 'grey',
                                                }}
                                            >
                                                PREVIOUS
                                            </p>
                                        </button>
                                        <button className="homeMainButton black-border-hover-white"
                                                onClick={handleNext}
                                                disabled={!selectedScreens}
                                                onMouseEnter={(e) => {
                                                    const buttonElement = e.currentTarget;
                                                    const textElement = buttonElement.querySelector('p');

                                                    if (!selectedScreens) {

                                                        buttonElement.style.boxShadow = 'none';
                                                    } else {

                                                        buttonElement.style.backgroundSize = '200% 100%';
                                                        buttonElement.style.backgroundPosition = 'left bottom';
                                                        buttonElement.style.transition = 'background-position 0.2s ease-in-out, box-shadow 1s linear';
                                                        buttonElement.style.boxShadow = 'inset 0rem 0 0 0 #000000';
                                                        buttonElement.style.color = 'white';
                                                        textElement.style.color = 'white';
                                                    }
                                                }}
                                                onMouseLeave={(e) => {
                                                    const buttonElement = e.currentTarget;
                                                    const textElement = buttonElement.querySelector('p');

                                                    if (!selectedScreens) {
                                                        buttonElement.style.boxShadow = 'none';
                                                    } else {
                                                        buttonElement.style.background = 'linear-gradient(to right, black 50%, white 50%)';
                                                        buttonElement.style.backgroundSize = '200% 100%';
                                                        buttonElement.style.backgroundPosition = 'right bottom';
                                                        buttonElement.style.transition = 'background-position 0.2s ease-in-out, box-shadow 1s linear';
                                                        buttonElement.style.boxShadow = 'inset 0rem 0 0 0 #FFFFFF';
                                                        buttonElement.style.color = 'black';
                                                        textElement.style.color = 'black';
                                                    }
                                                }}
                                                onMouseOver={(e) => {
                                                    const buttonElement = e.currentTarget;
                                                    const textElement = buttonElement.querySelector('p');

                                                    if (!selectedScreens) {

                                                        buttonElement.style.boxShadow = 'none';
                                                    } else {
                                                        buttonElement.style.backgroundColor = 'black';
                                                        buttonElement.style.color = 'white';
                                                        textElement.style.color = 'white';
                                                    }
                                                }}
                                                style={{
                                                    width: "8rem",
                                                    height: "2.5rem",
                                                    marginTop: '2rem',
                                                    marginBottom: '2rem',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    border: `2px solid ${selectedScreens ? 'black' : 'grey'}`,
                                                    color: selectedScreens ? 'black' : 'grey',
                                                    cursor: !selectedScreens ? 'not-allowed' : 'pointer',
                                                }}>
                                            <p className={'homeMainButtonLnk homeOverV'}
                                               style={{
                                                   margin: 0,
                                                   fontSize: '0.875rem',
                                                   fontWeight: '700',
                                                   textAlign: 'center',
                                                   color: selectedScreens ? 'black' : 'grey',
                                               }}>
                                                NEXT
                                            </p>
                                        </button>
                                    </div>
                                    {/* Progress Bar Container */}

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',  // Makes sure the progress bar takes up the full container
                                        alignItems: 'center',
                                        marginTop: '0.5vh',
                                        marginLeft: '3vw',
                                        marginRight: '3vw',
                                        width: '85%',
                                        marginBottom: '1vh'
                                    }}>
                                        <Steps
                                            direction="horizontal"
                                            current={0}
                                            size="small"

                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Step icon={<CompletedStepIcon/>}/>
                                            <Step icon={<PendingStepIcon/>}/>
                                            <Step icon={<PendingStepIcon/>}/>
                                            <Step icon={<PendingStepIcon/>}/>
                                        </Steps>

                                    </div>


                                </>
                            )}

                            {/* Step 2 */}
                            {step === 2 && (
                                <>
                                    <QuizHeaderIcon/>
                                    <QuizHeaderIcon2/>
                                    <div style={{//height: '25vh'
                                    }}>
                                        <h2 style={{
                                            marginTop: '4vh',
                                            fontSize: '2.125rem',
                                            textAlign: 'left',
                                            marginLeft: '3vw',
                                            fontWeight: '400',
                                            fontStyle: 'normal',
                                            fontFamily: 'Proxima Nova',
                                            display: 'flex',
                                        }}>
                                            What type of artwork do you want to display?
                                        </h2>
                                        <form style={{
                                            marginTop: '2vh',
                                            textAlign: 'left',
                                            marginLeft: '3vw',
                                            fontSize: '1rem',
                                            fontWeight: '500',
                                            fontStyle: 'normal',
                                            fontFamily: 'Proxima Nova',

                                        }}>
                                            <Radio.Group
                                                name="artworktype"
                                                onChange={(e) => handleSelection2(e.target.value)}
                                                value={artworkType}  // Controlled component with selected value
                                            >
                                                <Radio
                                                    value="1"
                                                    style={{display: 'block', marginBottom: '1vh'}}
                                                >
                                                    Free and my own uploaded pictures
                                                </Radio>
                                                <Radio
                                                    value="2"
                                                    style={{display: 'block', marginBottom: '1vh'}}
                                                >
                                                    I need advanced, royalty-free artwork
                                                    with expertly arranged playlist
                                                </Radio>
                                                <Radio
                                                    value="3"
                                                    style={{display: 'block', marginBottom: '2vh'}}
                                                >
                                                    I need exclusive, rare, premium artwork
                                                    with customised playlists.
                                                </Radio>
                                            </Radio.Group>
                                        </form>
                                    </div>


                                    {/* Previous & Next button */}
                                    <div style={{
                                        display: 'flex',
                                        marginLeft: '3vw',
                                        marginTop: '1vh'

                                    }}>
                                        <button
                                            className="homeMainButton black-border-hover-white"
                                            onClick={handlePrevious}
                                            disabled={step === 1}
                                            style={{
                                                width: "8rem",
                                                height: "2.5rem",
                                                marginTop: '2rem',
                                                marginBottom: '2rem',
                                                marginRight: '1rem',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',

                                            }}
                                        >
                                            <p
                                                className={'homeMainButtonLnk homeOverV'}
                                                style={{
                                                    margin: 0,
                                                    fontSize: '0.875rem',
                                                    fontWeight: '700',
                                                    textAlign: 'center',

                                                }}
                                            >
                                                PREVIOUS
                                            </p>
                                        </button>
                                        <button className="homeMainButton black-border-hover-white"
                                                onClick={handleNext}
                                                disabled={!artworkType}
                                                onMouseEnter={(e) => {
                                                    const buttonElement = e.currentTarget;
                                                    const textElement = buttonElement.querySelector('p');

                                                    if (!artworkType) {
                                                        // If selectedScreens is false (or blank), remove boxShadow
                                                        buttonElement.style.boxShadow = 'none';

                                                    } else {
                                                        buttonElement.style.backgroundSize = '200% 100%';
                                                        buttonElement.style.backgroundPosition = 'left bottom';
                                                        buttonElement.style.transition = 'background-position 0.2s ease-in-out, box-shadow 0.5s linear';
                                                        buttonElement.style.boxShadow = 'inset 0rem 0 0 0 #000000';
                                                        buttonElement.style.color = 'white';  // Change font color to white
                                                        textElement.style.color = 'white';
                                                    }
                                                }}
                                                onMouseLeave={(e) => {
                                                    const buttonElement = e.currentTarget;
                                                    const textElement = buttonElement.querySelector('p');

                                                    if (!artworkType) {
                                                        buttonElement.style.boxShadow = 'none';
                                                    } else {
                                                        buttonElement.style.background = 'linear-gradient(to right, black 50%, white 50%)';
                                                        buttonElement.style.backgroundSize = '200% 100%';
                                                        buttonElement.style.backgroundPosition = 'right bottom';
                                                        buttonElement.style.transition = 'background-position 0.2s ease-in-out, box-shadow 0.5s linear';
                                                        buttonElement.style.boxShadow = 'inset 0rem 0 0 0 #FFFFFF';
                                                        buttonElement.style.color = 'black';
                                                        textElement.style.color = 'black';
                                                    }
                                                }}
                                                onMouseOver={(e) => {
                                                    const buttonElement = e.currentTarget;
                                                    const textElement = buttonElement.querySelector('p');

                                                    if (!artworkType) {

                                                        buttonElement.style.boxShadow = 'none';
                                                    } else {
                                                        buttonElement.style.backgroundColor = 'black';
                                                        buttonElement.style.color = 'white';
                                                        textElement.style.color = 'white';
                                                    }
                                                }}
                                                style={{
                                                    width: "8rem",
                                                    height: "2.5rem",
                                                    marginTop: '2rem',
                                                    marginBottom: '2rem',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    border: `2px solid ${artworkType ? 'black' : 'grey'}`,
                                                    color: artworkType ? 'black' : 'grey',
                                                    cursor: !artworkType ? 'not-allowed' : 'pointer'
                                                }}>
                                            <p className={'homeMainButtonLnk homeOverV'}
                                               style={{
                                                   margin: 0,
                                                   fontSize: '0.875rem',
                                                   fontWeight: '700',
                                                   textAlign: 'center',
                                                   color: artworkType ? 'black' : 'grey',
                                               }}>
                                                NEXT
                                            </p>
                                        </button>
                                    </div>
                                    {/* Progress Bar Container */}

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: '0.5vh',
                                        marginLeft: '3vw',
                                        marginRight: '3vw',
                                        width: '85%',
                                        marginBottom: '1vh'
                                    }}>
                                        <Steps
                                            direction="horizontal"
                                            current={1}
                                            size="small"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Step icon={<CompletedStepIcon/>}/>
                                            <Step icon={<CompletedStepIcon/>}/>
                                            <Step icon={<PendingStepIcon/>}/>
                                            <Step icon={<PendingStepIcon/>}/>
                                        </Steps>

                                    </div>
                                </>
                            )}

                            {/* Step 3 */}
                            {step === 3 && (
                                <>
                                    <QuizHeaderIcon/>
                                    <QuizHeaderIcon2/>
                                    <div style={{//height: '25vh'
                                    }}>
                                        <h2 style={{
                                            marginTop: '4vh',
                                            fontSize: '2.125rem',
                                            textAlign: 'left',
                                            marginLeft: '3vw',
                                            fontWeight: '400',
                                            fontStyle: 'normal',
                                            fontFamily: 'Proxima Nova',
                                            display: 'flex',
                                        }}>
                                            How often do you change the content on display?
                                        </h2>
                                        <form style={{
                                            marginTop: '2vh',
                                            textAlign: 'left',
                                            marginLeft: '3vw',
                                            fontSize: '1rem',
                                            fontWeight: '500',
                                            fontStyle: 'normal',
                                            fontFamily: 'Proxima Nova'
                                        }}>
                                            <Radio.Group
                                                name="screens"
                                                onChange={(e) => handleSelection3(e.target.value)}
                                                value={contentChange}  // Controlled component with selected value
                                            >
                                                <Radio
                                                    value="1"
                                                    style={{display: 'block', marginBottom: '1vh'}}
                                                >
                                                    Rarely changed, fixed for a period of time.
                                                </Radio>
                                                <Radio
                                                    value="2"
                                                    style={{display: 'block', marginBottom: '1vh'}}
                                                >
                                                    Monthly or regular change.
                                                </Radio>
                                                <Radio
                                                    value="3"
                                                    style={{display: 'block', marginBottom: '2vh'}}
                                                >
                                                    Need to change frequently, have specific content needs.
                                                </Radio>
                                            </Radio.Group>
                                        </form>
                                    </div>


                                    {/* Previous & Next button */}
                                    <div style={{
                                        display: 'flex',
                                        marginLeft: '3vw',
                                        marginTop: '1vh'

                                    }}>
                                        <button
                                            className="homeMainButton black-border-hover-white"
                                            onClick={handlePrevious}
                                            disabled={step === 1}
                                            style={{
                                                width: "8rem",
                                                height: "2.5rem",
                                                marginTop: '2rem',
                                                marginBottom: '2rem',
                                                marginRight: '1rem',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',

                                            }}
                                        >
                                            <p
                                                className={'homeMainButtonLnk homeOverV'}
                                                style={{
                                                    margin: 0,
                                                    fontSize: '0.875rem',
                                                    fontWeight: '700',
                                                    textAlign: 'center',

                                                }}
                                            >
                                                PREVIOUS
                                            </p>
                                        </button>
                                        <button className="homeMainButton black-border-hover-white"
                                                onClick={handleNext}
                                                disabled={!contentChange}
                                                onMouseEnter={(e) => {
                                                    const buttonElement = e.currentTarget;
                                                    const textElement = buttonElement.querySelector('p');

                                                    if (!contentChange) {

                                                        buttonElement.style.boxShadow = 'none';
                                                    } else {
                                                        buttonElement.style.backgroundSize = '200% 100%';
                                                        buttonElement.style.backgroundPosition = 'left bottom';
                                                        buttonElement.style.transition = 'background-position 0.2s ease-in-out, box-shadow 0.5s linear';
                                                        buttonElement.style.boxShadow = 'inset 0rem 0 0 0 #000000';
                                                        buttonElement.style.color = 'white';
                                                        textElement.style.color = 'white';
                                                    }
                                                }}
                                                onMouseLeave={(e) => {
                                                    const buttonElement = e.currentTarget;
                                                    const textElement = buttonElement.querySelector('p');

                                                    if (!contentChange) {
                                                        buttonElement.style.boxShadow = 'none';// Revert text color to black when hover ends
                                                    } else {
                                                        buttonElement.style.background = 'linear-gradient(to right, black 50%, white 50%)';
                                                        buttonElement.style.backgroundSize = '200% 100%';
                                                        buttonElement.style.backgroundPosition = 'right bottom';
                                                        buttonElement.style.transition = 'background-position 0.2s ease-in-out, box-shadow 0.5s linear';
                                                        buttonElement.style.boxShadow = 'inset 0rem 0 0 0 #FFFFFF';
                                                        buttonElement.style.color = 'black';
                                                        textElement.style.color = 'black';
                                                    }
                                                }}
                                                onMouseOver={(e) => {
                                                    const buttonElement = e.currentTarget;
                                                    const textElement = buttonElement.querySelector('p');

                                                    if (!contentChange) {
                                                        // If selectedScreens is false (or blank), remove boxShadow
                                                        buttonElement.style.boxShadow = 'none';
                                                    } else {
                                                        buttonElement.style.backgroundColor = 'black';
                                                        buttonElement.style.color = 'white';
                                                        textElement.style.color = 'white';
                                                    }
                                                }}
                                                style={{
                                                    width: "8rem",
                                                    height: "2.5rem",
                                                    marginTop: '2rem',
                                                    marginBottom: '2rem',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    border: `2px solid ${contentChange ? 'black' : 'grey'}`,
                                                    color: contentChange ? 'black' : 'grey',
                                                    cursor: !contentChange ? 'not-allowed' : 'pointer'
                                                }}>
                                            <p className={'homeMainButtonLnk homeOverV'}
                                               style={{
                                                   margin: 0,
                                                   fontSize: '0.875rem',
                                                   fontWeight: '700',
                                                   textAlign: 'center',
                                                   color: contentChange ? 'black' : 'grey',
                                               }}>
                                                NEXT
                                            </p>
                                        </button>
                                    </div>
                                    {/* Progress Bar Container */}

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: '0.5vh',
                                        marginLeft: '3vw',
                                        marginRight: '3vw',
                                        width: '85%',
                                        marginBottom: '1vh'
                                    }}>
                                        <Steps
                                            direction="horizontal"
                                            current={2}
                                            size="small"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Step icon={<CompletedStepIcon/>}/>
                                            <Step icon={<CompletedStepIcon/>}/>
                                            <Step icon={<CompletedStepIcon/>}/>
                                            <Step icon={<PendingStepIcon/>}/>
                                        </Steps>

                                    </div>
                                </>
                            )}

                            {/* Step 4 */}
                            {step === 4 && (
                                <>

                                    <QuizHeaderIcon/>
                                    <QuizHeaderIcon2/>
                                    <div style={{//height: '25vh'
                                    }}>
                                        <h2 style={{
                                            marginTop: '4vh',
                                            fontSize: '2.125rem',
                                            textAlign: 'left',
                                            marginLeft: '3vw',
                                            fontWeight: '400',
                                            fontStyle: 'normal',
                                            fontFamily: 'Proxima Nova',
                                            display: 'flex',
                                        }}>
                                            What are your needs for managing artwork content?
                                        </h2>
                                        <form style={{
                                            marginTop: '2vh',
                                            textAlign: 'left',
                                            marginLeft: '3vw',
                                            fontSize: '1rem',
                                            fontWeight: '500',
                                            fontStyle: 'normal',
                                            fontFamily: 'Proxima Nova'
                                        }}>
                                            <Radio.Group
                                                name="screens"
                                                onChange={(e) => handleSelection4(e.target.value)}
                                                value={manageArtworks}  // Controlled component with selected value
                                            >
                                                <Radio
                                                    value="1"
                                                    style={{display: 'block', marginBottom: '1vh'}}
                                                >
                                                    I can manage the content myself, no help needed.
                                                </Radio>
                                                <Radio
                                                    value="2"
                                                    style={{display: 'block', marginBottom: '2vh'}}
                                                >
                                                    I need a dedicated art curator and platform management service.
                                                </Radio>
                                            </Radio.Group>
                                        </form>
                                    </div>


                                    {/* Previous & Next button */}
                                    <div style={{
                                        display: 'flex',
                                        marginLeft: '3vw',
                                        marginTop: '1vh'

                                    }}>
                                        <button
                                            className="homeMainButton black-border-hover-white"
                                            onClick={handlePrevious}
                                            disabled={step === 1}
                                            style={{
                                                width: "8rem",
                                                height: "2.5rem",
                                                marginTop: '2rem',
                                                marginBottom: '2rem',
                                                marginRight: '1rem',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',

                                            }}
                                        >
                                            <p
                                                className={'homeMainButtonLnk homeOverV'}
                                                style={{
                                                    margin: 0,
                                                    fontSize: '0.875rem',
                                                    fontWeight: '700',
                                                    textAlign: 'center',

                                                }}
                                            >
                                                PREVIOUS
                                            </p>
                                        </button>
                                        <button className="homeMainButton black-border-hover-white"
                                                onClick={() => {
                                                    closeModal();
                                                    scrollToSection();
                                                    const result = getResults()
                                                    setResult(result)
                                                    setOpenTour(true)
                                                    handleFinish();
                                                }}

                                                disabled={!manageArtworks}
                                                onMouseEnter={(e) => {
                                                    const buttonElement = e.currentTarget;
                                                    const textElement = buttonElement.querySelector('p');

                                                    if (!manageArtworks) {

                                                        buttonElement.style.boxShadow = 'none';
                                                    } else {
                                                        buttonElement.style.backgroundSize = '200% 100%';
                                                        buttonElement.style.backgroundPosition = 'left bottom';
                                                        buttonElement.style.transition = 'background-position 0.2s ease-in-out, box-shadow 0.5s linear';
                                                        buttonElement.style.boxShadow = 'inset 0rem 0 0 0 #000000';
                                                        buttonElement.style.color = 'white';
                                                        textElement.style.color = 'white';
                                                    }
                                                }}
                                                onMouseLeave={(e) => {
                                                    const buttonElement = e.currentTarget;
                                                    const textElement = buttonElement.querySelector('p');

                                                    if (!manageArtworks) {
                                                        buttonElement.style.boxShadow = 'none';// Revert text color to black when hover ends
                                                    } else {
                                                        buttonElement.style.background = 'linear-gradient(to right, black 50%, white 50%)';
                                                        buttonElement.style.backgroundSize = '200% 100%';
                                                        buttonElement.style.backgroundPosition = 'right bottom';
                                                        buttonElement.style.transition = 'background-position 0.2s ease-in-out, box-shadow 0.5s linear';
                                                        buttonElement.style.boxShadow = 'inset 0rem 0 0 0 #FFFFFF';
                                                        buttonElement.style.color = 'black';
                                                        textElement.style.color = 'black';
                                                    }
                                                }}
                                                onMouseOver={(e) => {
                                                    const buttonElement = e.currentTarget;
                                                    const textElement = buttonElement.querySelector('p');

                                                    if (!manageArtworks) {

                                                        buttonElement.style.boxShadow = 'none';
                                                    } else {
                                                        buttonElement.style.backgroundColor = 'black';
                                                        buttonElement.style.color = 'white';
                                                        textElement.style.color = 'white';
                                                    }
                                                }}
                                                style={{
                                                    width: "8rem",
                                                    height: "2.5rem",
                                                    marginTop: '2rem',
                                                    marginBottom: '2rem',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    border: `2px solid ${manageArtworks ? 'black' : 'grey'}`,
                                                    color: manageArtworks ? 'black' : 'grey',
                                                    cursor: !manageArtworks ? 'not-allowed' : 'pointer'
                                                }}>
                                            <p className={'homeMainButtonLnk homeOverV'}
                                               style={{
                                                   margin: 0,
                                                   fontSize: '0.875rem',
                                                   fontWeight: '700',
                                                   textAlign: 'center',
                                                   color: manageArtworks ? 'black' : 'grey',
                                               }}>
                                                FINISH
                                            </p>
                                        </button>
                                    </div>

                                    {/* Progress Bar Container */}

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: '0.5vh',
                                        marginLeft: '3vw',
                                        marginRight: '3vw',
                                        marginBottom: '1vh',
                                        width: '85%',

                                    }}>
                                        <Steps
                                            direction="horizontal"
                                            current={3}
                                            size="small"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Step icon={<CompletedStepIcon/>}/>
                                            <Step icon={<CompletedStepIcon/>}/>
                                            <Step icon={<CompletedStepIcon/>}/>
                                            <Step icon={<CompletedStepIcon/>}/>
                                        </Steps>

                                    </div>
                                </>
                            )}

                        </div>
                    </Modal>


                    {/* Bottom Section with white background color */}
                    <div style={{
                        backgroundColor: '#FFF',
                        marginTop: '10vh',
                        marginBottom: '10vh',
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            marginTop: '10vh',
                        }}>
                            <div style={{
                                borderTop: '0.3vw solid #06467a',
                                borderLeft: '0.3vw solid #06467a',
                                borderRight: '0.3vw solid #06467a',
                                borderBottom: 'none',
                                borderTopLeftRadius: '1vw',
                                borderTopRightRadius: '1vw',
                                borderBottomLeftRadius: '0',
                                borderBottomRightRadius: '0',
                                width: checkWidth ? '25vw' : '22vw',
                                backgroundColor: '#EBF1F5',
                                textAlign: 'center',
                                height: 'auto',

                            }}>
                                <p style={{
                                    fontSize: '1rem',
                                    color: '#000',
                                    fontWeight: '600',
                                    marginBottom: '1vh',
                                    marginTop: '1vh'
                                }}>
                                    MOST POPULAR
                                </p>

                            </div>

                        </div>
                        <div style={{
                            height: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'stretch',
                            gap: '1.5vw',

                        }}>
                            <div style={{
                                borderTop: '0.2vw solid #3F3F3F',
                                borderLeft: '0.2vw solid #3F3F3F',
                                borderRight: '0.2vw solid #3F3F3F',
                                borderBottom: '0.2vw solid #3F3F3F',
                                borderTopLeftRadius: '1vw',
                                borderTopRightRadius: '1vw',
                                borderBottomLeftRadius: '0',
                                borderBottomRightRadius: '0',
                                width: checkWidth ? '25vw' : '21vw',
                                backgroundColor: '#FFF',
                                textAlign: 'left',


                            }}>
                                <p style={{
                                    color: '#936846',
                                    fontWeight: '400',
                                    fontSize: '3rem',
                                    fontStyle: 'normal',
                                    lineHeight: '1',
                                    margin: 0,
                                    marginTop: '2vh',
                                    marginLeft: '2vw',
                                    marginRight: '2vw'

                                }}>Foundation</p>
                                <p style={{
                                    color: '#000',
                                    fontWeight: '400',
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    lineHeight: '1',
                                    margin: '0',
                                    marginBottom: '2vh',
                                    marginLeft: '2vw',
                                    marginRight: '2vw'
                                }}>
                                    For small businesses looking to explore creative possibilities with access to
                                    incredible artwork for up to 2 canvases.
                                </p>

                            </div>
                            <div style={{

                                borderLeft: '0.3vw solid #06467a',
                                borderRight: '0.3vw solid #06467a',
                                borderBottom: '0.2vw solid #000',
                                borderTopLeftRadius: '0',
                                borderTopRightRadius: '0',
                                borderBottomLeftRadius: '0',
                                borderBottomRightRadius: '0',
                                width: checkWidth ? '25vw' : '22vw',
                                backgroundColor: '#FFF',
                                textAlign: 'left',


                            }}>
                                <p style={{
                                    color: '#936846',
                                    fontWeight: '400',
                                    fontSize: '3rem',
                                    fontStyle: 'normal',
                                    lineHeight: '1',
                                    margin: '0',
                                    marginTop: '2vh',
                                    marginLeft: '2vw',
                                    marginRight: '2vw'
                                }}>Advanced</p>
                                <p style={{
                                    color: '#000',
                                    fontWeight: '400',
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    lineHeight: '1',
                                    margin: '0',
                                    marginBottom: '2vh',
                                    marginLeft: '2vw',
                                    marginRight: '2vw'
                                }}>
                                    For businesses seeking to elevate their visual content without restrictions and
                                    with
                                    dedicated commercial support.
                                </p>

                            </div>
                            <div style={{
                                borderTop: '0.2vw solid #3F3F3F',
                                borderLeft: '0.2vw solid #3F3F3F',
                                borderRight: '0.2vw solid #3F3F3F',
                                borderBottom: '0.2vw solid #3F3F3F',
                                borderTopLeftRadius: '1vw',
                                borderTopRightRadius: '1vw',
                                borderBottomLeftRadius: '0',
                                borderBottomRightRadius: '0',
                                width: checkWidth ? '25vw' : '21vw',
                                backgroundColor: '#FFF',
                                textAlign: 'left',


                            }}>
                                <p style={{
                                    color: '#936846',
                                    fontWeight: '400',
                                    fontSize: '3rem',
                                    fontStyle: 'normal',
                                    lineHeight: '1',
                                    margin: '0',
                                    marginTop: '2vh',
                                    marginLeft: '2vw',
                                    marginRight: '2vw'
                                }}>Premium</p>
                                <p style={{
                                    color: '#000',
                                    fontWeight: '400',
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    lineHeight: '1',
                                    margin: '0',
                                    marginBottom: '2vh',
                                    textAlign: 'left',
                                    marginLeft: '2vw',
                                    marginRight: '2vw'
                                }}>
                                    For organisation ready for comprehensive management and total control of an
                                    extensive art collection.
                                </p>

                            </div>

                        </div>
                        <div style={{
                            height: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'stretch',
                            gap: '1.5vw',

                        }}>
                            <div style={{

                                borderLeft: '0.2vw solid #3F3F3F',
                                borderRight: '0.2vw solid #3F3F3F',
                                borderBottom: '0.2vw solid #3F3F3F',
                                borderTopLeftRadius: '0',
                                borderTopRightRadius: '0',
                                borderBottomLeftRadius: '0',
                                borderBottomRightRadius: '0',
                                width: checkWidth ? '25vw' : '21vw',
                                backgroundColor: '#FFF',
                                textAlign: 'left',


                            }}>
                                <div style={{
                                    height: '10vh',
                                    marginLeft: '2vw',
                                    marginRight: '2vw',
                                }}>
                                    <p style={{
                                        fontSize: '3rem',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        margin: '0',
                                        lineHeight: '1',
                                        marginTop: '2vw'
                                    }}>£0
                                    </p>
                                    <p style={{
                                        fontSize: '1rem',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        margin: '0',
                                        lineHeight: '1',

                                    }}>device /
                                        year
                                    </p>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    paddingLeft: '2vw',
                                    paddingRight: '2vw',
                                }}>
                                    <button
                                        className="homeMainButton black-border-hover-white"
                                        onClick={() => navigate('/pro/support/request-demo')}
                                        style={{
                                            width: "100%",
                                            marginTop: '2vh',
                                            marginBottom: '4vh',
                                        }}>
                                        <p className={'homeMainButtonLnk homeOverV'}
                                           style={{
                                               margin: 0,
                                               fontSize: '0.875rem',
                                               fontWeight: '700',
                                               textAlign: 'center'
                                           }}>
                                            CONTACT US
                                        </p>
                                    </button>
                                </div>


                            </div>
                            <div style={{

                                borderLeft: '0.3vw solid #06467a',
                                borderRight: '0.3vw solid #06467a',
                                borderBottom: '0.2vw solid #000',
                                borderTopLeftRadius: '0',
                                borderTopRightRadius: '0',
                                borderBottomLeftRadius: '0',
                                borderBottomRightRadius: '0',
                                width: checkWidth ? '25vw' : '22vw',
                                backgroundColor: '#FFF',
                                textAlign: 'left',


                            }}>
                                <div style={{
                                    height: '10vh',
                                    marginLeft: '2vw',
                                    marginRight: '2vw',
                                }}>
                                    <p style={{
                                        fontSize: '3rem',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        margin: '0',
                                        lineHeight: '1',
                                        marginTop: '2vw'
                                    }}>£99
                                    </p>
                                    <p style={{
                                        fontSize: '1rem',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        margin: '0',
                                        lineHeight: '1',

                                    }}>device /
                                        year
                                    </p>
                                </div>

                                <div style={{
                                    textAlign: 'center',
                                    paddingLeft: '2vw',
                                    paddingRight: '2vw',
                                }}>
                                    <button
                                        className="homeMainButton black-border-hover-white"
                                        onClick={() => navigate('/pro/support/request-demo')}
                                        style={{
                                            width: "100%",
                                            marginTop: '2vh',
                                            marginBottom: '4vh',
                                            backgroundColor: '#06467a',
                                            color: '#FFF',
                                            borderColor: '#06467a',
                                            borderWidth: '2px',
                                            borderStyle: 'solid'
                                        }}>
                                        <p className={'homeMainButtonLnk homeOverV'}
                                           style={{
                                               margin: 0,
                                               fontSize: '0.875rem',
                                               fontWeight: '700',
                                               textAlign: 'center',
                                               color: '#FFF'
                                           }}>
                                            CONTACT US
                                        </p>
                                    </button>
                                </div>

                            </div>
                            <div style={{

                                borderLeft: '0.2vw solid #3F3F3F',
                                borderRight: '0.2vw solid #3F3F3F',
                                borderBottom: '0.2vw solid #3F3F3F',
                                borderTopLeftRadius: '0',
                                borderTopRightRadius: '0',
                                borderBottomLeftRadius: '0',
                                borderBottomRightRadius: '0',
                                width: checkWidth ? '25vw' : '21vw',
                                backgroundColor: '#FFF',
                                textAlign: 'left',


                            }}>
                                <div style={{
                                    height: '10vh',
                                    marginLeft: '2vw',
                                    marginRight: '2vw',
                                }}>
                                    <p style={{
                                        fontSize: '3rem',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        margin: '0',
                                        lineHeight: '1',
                                        marginTop: '2vw'
                                    }}>£189
                                    </p>
                                    <p style={{
                                        fontSize: '1rem',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        margin: '0',
                                        lineHeight: '1',

                                    }}>device /
                                        year
                                    </p>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    paddingLeft: '2vw',
                                    paddingRight: '2vw',
                                }}>
                                    <button
                                        className="homeMainButton black-border-hover-white"
                                        onClick={() => navigate('/pro/support/request-demo')}
                                        style={{
                                            width: "100%",
                                            marginTop: '2vh',
                                            marginBottom: '4vh',

                                        }}
                                    >
                                        <p
                                            className="homeMainButtonLnk homeOverV"
                                            style={{
                                                margin: 0,
                                                fontSize: '0.875rem',
                                                fontWeight: '700',
                                                textAlign: 'center',
                                            }}
                                        >
                                            CONTACT US
                                        </p>
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div style={{
                            height: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'stretch',
                            gap: '1.5vw',

                        }}>
                            <div style={{

                                borderLeft: '0.2vw solid #3F3F3F',
                                borderRight: '0.2vw solid #3F3F3F',
                                borderTopLeftRadius: '0vw',
                                borderTopRightRadius: '0vw',
                                borderBottomLeftRadius: '0',
                                borderBottomRightRadius: '0',
                                width: checkWidth ? '25vw' : '21vw',
                                backgroundColor: '#FFF',
                                textAlign: 'left',


                            }}>
                                <p style={{
                                    fontSize: '1rem',
                                    marginTop: '2vw',
                                    marginLeft: '2vw',
                                    marginRight: '2vw',
                                    fontWeight: '400',
                                    fontStyle: 'normal'
                                }}><span style={{color: '#936846'}}>Up to 2</span> Textura Pro
                                    Canvases</p>
                            </div>
                            <div style={{

                                borderLeft: '0.3vw solid #06467a',
                                borderRight: '0.3vw solid #06467a',
                                borderTopLeftRadius: '0',
                                borderTopRightRadius: '0',
                                borderBottomLeftRadius: '0',
                                borderBottomRightRadius: '0',
                                width: checkWidth ? '25vw' : '22vw',
                                backgroundColor: '#FFF',
                                textAlign: 'left',

                            }}>
                                <p style={{
                                    fontSize: '1rem',
                                    marginTop: '2vw',
                                    marginLeft: '2vw',
                                    marginRight: '2vw',
                                    fontWeight: '400',
                                    fontStyle: 'normal'
                                }}><span style={{color: '#936846'}}>Unlimited</span> Textura Pro Canvases</p>
                            </div>
                            <div style={{

                                borderLeft: '0.2vw solid #3F3F3F',
                                borderRight: '0.2vw solid #3F3F3F',
                                borderTopLeftRadius: '0',
                                borderTopRightRadius: '0',
                                borderBottomLeftRadius: '0',
                                borderBottomRightRadius: '0',
                                width: checkWidth ? '25vw' : '21vw',
                                backgroundColor: '#FFF',
                                textAlign: 'center',


                            }}>
                                <p style={{
                                    fontSize: '1rem',
                                    marginTop: '2vw',
                                    marginLeft: '2vw',
                                    marginRight: '2vw',
                                    fontWeight: '400',
                                    fontStyle: 'normal'
                                }}><span style={{color: '#936846'}}>Unlimited</span> Textura Pro Canvases</p>
                            </div>
                        </div>
                        <div style={{
                            height: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'stretch',
                            gap: '1.5vw',

                        }}>
                            <div style={{

                                borderLeft: '0.2vw solid #3F3F3F',
                                borderRight: '0.2vw solid #3F3F3F',
                                borderBottom: '0.2vw solid #3F3F3F',
                                borderTopLeftRadius: '0',
                                borderTopRightRadius: '0',
                                borderBottomLeftRadius: '1vw',
                                borderBottomRightRadius: '1vw',
                                width: checkWidth ? '25vw' : '21vw',
                                backgroundColor: '#FFF',
                                textAlign: 'left',


                            }}>

                                <p style={{
                                    fontSize: '0.875rem',
                                    marginLeft: '2vw',
                                    marginRight: '2vw',
                                    fontWeight: '600',
                                    fontStyle: 'normal',
                                    marginTop: '2vh',
                                    marginBottom: '2vh'
                                }}>FOUNDATION INCLUDES</p>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '2vw',
                                    gap: '1vw',
                                    marginRight: '2vw',
                                    marginBottom: '2vh'
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12"
                                         viewBox="0 0 16 12"
                                         fill="none">
                                        <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                              stroke-linecap="round"/>
                                    </svg>
                                    <p style={{
                                        fontSize: '1rem',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        margin: '0'
                                    }}>
                                        Access to CC0 Artwork
                                    </p>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '2vw',
                                    gap: '1vw',
                                    marginRight: '2vw',
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12"
                                         viewBox="0 0 16 12"
                                         fill="none">
                                        <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                              stroke-linecap="round"/>
                                    </svg>
                                    <p style={{
                                        fontSize: '1rem',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        marginRight: '2vw',
                                        margin: '0'
                                    }}>
                                        Dedicated Technical Support
                                    </p>
                                </div>

                                <p style={{
                                    color: '#000',
                                    fontSize: '1.125rem',
                                    marginLeft: '2vw',
                                    fontWeight: '600',
                                    fontStyle: 'normal',
                                    marginTop: '2vh'
                                }}>
                                    <a onClick={scrollToSection} style={{textDecoration: 'none', color: '#000'}}>
                                        Learn more about Foundation &gt;
                                    </a>
                                </p>

                            </div>
                            <div style={{

                                borderLeft: '0.3vw solid #06467a',
                                borderRight: '0.3vw solid #06467a',
                                borderBottom: '0.3vw solid #06467a',
                                borderTopLeftRadius: '0vw',
                                borderTopRightRadius: '0vw',
                                borderBottomLeftRadius: '1vw',
                                borderBottomRightRadius: '1vw',
                                width: checkWidth ? '25vw' : '22vw',
                                backgroundColor: '#FFF',
                                textAlign: 'left',


                            }}>

                                <p style={{
                                    fontSize: '0.875rem',
                                    marginLeft: '2vw',
                                    marginRight: '2vw',
                                    fontWeight: '600',
                                    fontStyle: 'normal',
                                    marginTop: '2vh',
                                    marginBottom: '2vh'
                                }}>EVERYTHING IN FOUNDATION, AND</p>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '2vw',
                                    gap: '1vw',
                                    marginRight: '2vw',
                                    marginBottom: '2vh'
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12"
                                         viewBox="0 0 16 12"
                                         fill="none">
                                        <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                              stroke-linecap="round"/>
                                    </svg>
                                    <p style={{
                                        fontSize: '1rem',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        margin: '0'
                                    }}>
                                        Access to advanced CC0 Artwork
                                    </p>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '2vw',
                                    gap: '1vw',
                                    marginRight: '2vw',
                                    marginBottom: '2vh'
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12"
                                         viewBox="0 0 16 12"
                                         fill="none">
                                        <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                              stroke-linecap="round"/>
                                    </svg>
                                    <p style={{
                                        fontSize: '1rem',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        marginRight: '2vw',
                                        margin: '0'
                                    }}>
                                        Exclusive free access to priced artwork
                                    </p>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '2vw',
                                    gap: '1vw',
                                    marginRight: '2vw',
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12"
                                         viewBox="0 0 16 12"
                                         fill="none">
                                        <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                              stroke-linecap="round"/>
                                    </svg>
                                    <p style={{
                                        fontSize: '1rem',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        marginRight: '2vw',
                                        margin: '0'
                                    }}>
                                        Dedicated Commercial Support
                                    </p>
                                </div>

                                <p style={{
                                    color: '#000',
                                    fontSize: '1.125rem',
                                    marginLeft: '2vw',
                                    fontWeight: '600',
                                    fontStyle: 'normal',
                                    marginTop: '2vh',
                                    marginBottom: '2vh'
                                }}>
                                    <a onClick={scrollToSection} style={{textDecoration: 'none', color: '#000'}}>
                                        Learn more about Advanced &gt;
                                    </a>
                                </p>
                            </div>
                            <div style={{

                                borderLeft: '0.2vw solid #3F3F3F',
                                borderRight: '0.2vw solid #3F3F3F',
                                borderBottom: '0.2vw solid #3F3F3F',
                                borderTopLeftRadius: '0',
                                borderTopRightRadius: '0',
                                borderBottomLeftRadius: '1vw',
                                borderBottomRightRadius: '1vw',
                                width: checkWidth ? '25vw' : '21vw',
                                backgroundColor: '#FFF',
                                textAlign: 'left',


                            }}>

                                <p style={{
                                    fontSize: '0.875rem',
                                    marginLeft: '2vw',
                                    marginRight: '2vw',
                                    fontWeight: '600',
                                    fontStyle: 'normal',
                                    marginTop: '2vh',
                                    marginBottom: '2vh'
                                }}>EVERYTHING IN ADVANCED, AND</p>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '2vw',
                                    gap: '1vw',
                                    marginRight: '2vw',
                                    marginBottom: '2vh'
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12"
                                         viewBox="0 0 16 12"
                                         fill="none">
                                        <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                              stroke-linecap="round"/>
                                    </svg>
                                    <p style={{
                                        fontSize: '1rem',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        margin: '0'
                                    }}>
                                        Unlimited Cloud Storage
                                    </p>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '2vw',
                                    gap: '1vw',
                                    marginRight: '2vw',
                                    marginBottom: '2vh'
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12"
                                         viewBox="0 0 16 12"
                                         fill="none">
                                        <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                              stroke-linecap="round"/>
                                    </svg>
                                    <p style={{
                                        fontSize: '1rem',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        marginRight: '2vw',
                                        margin: '0'
                                    }}>
                                        Premium playlists
                                    </p>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '2vw',
                                    gap: '1vw',
                                    marginRight: '2vw',
                                    marginBottom: '2vh'
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12"
                                         viewBox="0 0 16 12"
                                         fill="none">
                                        <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                              stroke-linecap="round"/>
                                    </svg>
                                    <p style={{
                                        fontSize: '1rem',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        marginRight: '2vw',
                                        margin: '0'
                                    }}>
                                        Platform Management Services
                                    </p>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '2vw',
                                    gap: '1vw',
                                    marginRight: '2vw',
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12"
                                         viewBox="0 0 16 12"
                                         fill="none">
                                        <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                              stroke-linecap="round"/>
                                    </svg>
                                    <p style={{
                                        fontSize: '1rem',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        marginRight: '2vw',
                                        margin: '0'
                                    }}>
                                        Artwork Content Management
                                    </p>
                                </div>

                                <p style={{
                                    color: '#000',
                                    fontSize: '1.125rem',
                                    marginLeft: '2vw',
                                    fontWeight: '600',
                                    fontStyle: 'normal',
                                    marginTop: '2vh',
                                    marginBottom: '5vh'
                                }}>
                                    <a onClick={scrollToSection} style={{textDecoration: 'none', color: '#000'}}>
                                        Learn more about Premium &gt;
                                    </a>
                                </p>
                            </div>


                        </div>


                        <div style={{
                            display: 'flex',
                            marginTop: '5vh',
                            marginBottom: '5vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <button
                                className="homeMainButton black-border-hover-white"
                                onClick={scrollToSection}
                                style={{width: "20vw"}}
                            >
                                <p
                                    className={'homeMainButtonLnk homeOverV'}
                                    style={{
                                        margin: 0,
                                        fontSize: '0.875rem',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                    }}
                                >
                                    COMPARE ALL FEATURES
                                </p>
                            </button>
                        </div>
                    </div>
                    <HomeProSection2/>
                    <p className={'pro-plans-title'} style={{
                        color: '#000',
                        fontSize: '2.2rem',
                        fontWeight: '400',
                        fontStyle: 'normal',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: '2vh auto 6vh auto',
                    }}>Compare All Plans</p>
                    <div ref={proDynamicTableRefDesktop}>
                        <ProVerticalTable plan={plan} tourMessage={tourMessage} openTour={openTour} setOpenTour={setOpenTour}/>
                    </div>

                    <Row justify={"center"} className='pro-subscription-FAQ'>
                        <Col span={20} lg={20} md={20}>
                            <Collapse expandIconPosition={"end"}
                                      bordered={false}
                                      style={{
                                          backgroundColor: '#FFF', color: '#FFF', border: "none"
                                      }}
                                      expandIcon={(panelProps) => {
                                          if (panelProps.isActive) {
                                              return <MinusOutlined style={{fontSize: '24px', fontWeight: "900"}}/>
                                          } else {
                                              return <PlusOutlined style={{fontSize: '24px', fontWeight: "900"}}/>
                                          }
                                      }}
                            >
                                <div className="ProSubFAQ">
                                    Frequently Asked Questions
                                </div>
                                <Panel header="How does the subscription plan work with Textura Pro?"
                                       className={'help-center-collapse-copy'}
                                       key="1">
                                    <p>When you purchase a subscription plan for Vieunite Pro, it
                                        automatically links to your user account and the Textura Pro device,
                                        assigning
                                        the
                                        right
                                        number of licenses to the number of Textura Pros purchased. This streamlined
                                        process
                                        ensures businesses can now tailor the licenses to their specific needs when
                                        deploying
                                        Textura Pro displays. In addition, it allows for effortless remote
                                        management of
                                        all
                                        Textura Pro displays through your Vieunite Pro platform. Additionally,
                                        selecting
                                        a
                                        subscription plan is essential for you to pair all your Textura Pros under
                                        your
                                        user
                                        account. While our Standard plan is a free subscription, it is limited to
                                        displaying
                                        contents on up to two Textura Pro devices. It is important to note that your
                                        subscription plan license is linked to both the Textura Pro hardware and
                                        your
                                        user
                                        account. This means all hardware under each user account must be covered by
                                        the
                                        same
                                        subscription plan, so as to grant you access to all the features offered
                                        under
                                        the
                                        Standard (free), Advanced and Premium subscription plans.</p>
                                </Panel>
                                <Panel header="Do I need a subscription plan for every Textura Pro?"
                                       className={'help-center-collapse-copy'}
                                       key="2">
                                    <p>Yes, you need a Vieunite Pro subscription plan license for every Textura Pro
                                        device.
                                        All
                                        subscription license links to your account rather than the Textura Pro
                                        device
                                        itself.
                                        This setup allows for flexibility in assigning required number of licenses
                                        to
                                        the
                                        Textura Pro units.</p>
                                </Panel>
                                <Panel
                                    header="Can I purchase a Textura Pro for my business without a subscription plan?"
                                    key="3" className={'help-center-collapse-copy'}>
                                    <p>No, Vieunite Pro subscription plans licenses are absolutely required to
                                        unlock
                                        the
                                        functionality of every Textura Pro device. That is to say, every Textura Pro
                                        must be
                                        linked with a subscription plan license so that it can be controlled via the
                                        Vieunite
                                        Pro Platform, where the Textura Pro device can then access all the fantastic
                                        features,
                                        including the art library, and marketplace artworks etc.</p>
                                </Panel>
                                <Panel
                                    header="How can I purchase additional Textura Pro digital canvases and subscription plans as my business needs grows?"
                                    key="4" className={'help-center-collapse-copy'}>
                                    <p>
                                        Every Textura Pro device requires a subscription plan license which is
                                        linked to
                                        your
                                        user account. The subscription plan must be the same for all Textura Pro
                                        devices
                                        so
                                        as
                                        to allow smooth control through the Vieunite Pro Platform. For example, say
                                        if
                                        you
                                        have
                                        subscribed to an annual Premium Plan for 10 Textura Pros on 1 Jun 2024 which
                                        ends on
                                        31
                                        May 2025. If at any time during your subscription period, your business
                                        needs
                                        grow
                                        and
                                        you wish to increase the number of Textura Pro from 10 to 20 devices at the
                                        same
                                        Premium
                                        subscription plan, kindly reach out to us via email (support@vieunite.com)
                                        or
                                        phone
                                        (0121-421-4458). Our dedicated Commercial Support team will assist you in
                                        acquiring
                                        10
                                        more Textura Pros (total of 20 devices). We will stack on 10 Premium
                                        subscription
                                        licenses (total of 20 licenses) at a pro-rated basis to your account, which
                                        ends
                                        on
                                        the
                                        same date of your first purchase, i.e., 31 May 2025. Rest assured that you
                                        will
                                        only
                                        be
                                        charged a pro-rated subscription for the additional 10 licenses for the
                                        remaining
                                        subscription period. For all queries, we are always happy to assist at
                                        support@vieunite.com and 0121-421-4458 during office hours.
                                    </p>
                                </Panel>
                                <Panel header="Do you offer trials for Vieunite Pro subscription plans?" key="5"
                                       className={'help-center-collapse-copy'}>
                                    <p>Yes, by default, we offer all customers a no-obligation, thirty (30) day free
                                        trial
                                        to
                                        all paid Advanced and Premium Plans. Kindly note that there is no free trial
                                        for
                                        the
                                        Standard Plan as it is already offered free to users for content display on
                                        up
                                        to
                                        two
                                        (2) Textura Pro devices. For the trial period, you will be contacted by
                                        email
                                        seven
                                        (7)
                                        days before trial ends to confirm your purchase of Products to link with
                                        your
                                        desired
                                        plans. Payment is done through an authorised FCA-compliant third-party
                                        payment
                                        provider.
                                        Once you have subscribed to our plans, the minimum subscription period is
                                        one
                                        year.
                                        You
                                        will be contacted by email thirty (30) days before subscription period ends
                                        to
                                        remind
                                        you about renewal of your subscription plans. As part of our service
                                        commitment,
                                        we
                                        also
                                        further send you a reminder email seven (7) days subscription period ends to
                                        confirm
                                        the
                                        renewal of your plans.</p>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>

                    {/*<Row justify={'center'} style={{margin: '3vh auto 4vh auto'}}>*/}
                    {/*    <Col style={{*/}
                    {/*        width: '40vw',*/}
                    {/*        borderRadius: '20px',*/}
                    {/*        background: 'rgba(122, 6, 59, 0.08)',*/}
                    {/*        marginLeft: '-1vw',*/}
                    {/*        display: 'flex',*/}
                    {/*        flexDirection: 'column',*/}
                    {/*        justifyContent: 'center',*/}
                    {/*        alignItems: 'flex-start',*/}
                    {/*        paddingLeft: '4vw'*/}
                    {/*    }}>*/}
                    {/*        <p className={'pro-contact-section-p'}>*/}
                    {/*            Ready to redefine art and business with Vieunite Pro?*/}
                    {/*        </p>*/}
                    {/*        <button*/}
                    {/*            className="homeMainButton"*/}
                    {/*            style={{width: "12.6vw", minWidth: "200px", marginTop: '-1vh'}}*/}
                    {/*            onClick={_ => window.location.href = "/contact"}*/}
                    {/*        >*/}
                    {/*            <a className={'homeMainButtonLnk homeOverV'}>*/}
                    {/*                CONTACT US*/}
                    {/*            </a>*/}
                    {/*        </button>*/}
                    {/*    </Col>*/}
                    {/*    <Col style={{width: '40vw'}}>*/}
                    {/*        <img src={img1} style={{width: '40vw', borderRadius: '20px', marginLeft: '1vw'}}/>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <ContactSection
                        bgColor="rgba(122, 6, 59, 0.08)"
                        text="Ready to redefine art and business with Vieunite Pro?"
                        buttonText="CONTACT US"
                        link={"/pro/support/request-demo"}
                    />
                </div>
            </div>
            {/* Mobile Version */}
            <div className={"subscription-container-mobile"}>
                <div style={{fontFamily: 'Proxima Nova'}}>
                    {/* Top Section with amber background color */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        backgroundColor: '#FFF5EC',
                        paddingTop: '12vh',
                        paddingBottom: '3vh'
                    }}>
                        <div style={{
                            maxWidth: '50vw',
                            marginTop: '5vw',
                            marginBottom: '0'

                        }}>
                            <p style={{
                                fontSize: '2.25rem',
                                fontWeight: '400',
                                color: '#000',
                                fontStyle: 'normal',
                                margin: '0',
                                lineHeight: '1'
                            }}>
                                Manage digital displays with a more convenient way
                            </p>
                            <div style={{
                                maxWidth: '40vw',
                                fontSize: '1rem',
                                fontWeight: '400',
                                lineHeight: '1.5',
                                color: '#000',
                                justifyContent: 'center',
                                fontStyle: 'normal',
                                margin: '0 auto',
                                textAlign: 'center',
                                paddingTop: '0.5vh'


                            }}>
                                <p style={{textAlign: 'center'}}>
                                    Easily browse through our selection of art playlists, masterfully curated by our
                                    expert team. Regularly updated with fresh, high-quality content, Vieunite Pro is
                                    the
                                    perfect solution for businesses to effortlessly showcase stunning art
                                    collections.
                                </p>
                            </div>

                        </div>

                    </div>


                    {/* Bottom Section with white background color */}
                    <div style={{
                        backgroundColor: '#FFF',
                        marginTop: '8vh',
                        marginBottom: '4vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',

                    }}>
                        {/* Containers */}

                        {/* Standard Plan */}
                        <div style={{
                            border: '0.4vw solid #3F3F3F',
                            borderRadius: '2.5vw',
                            width: '60vw',
                            backgroundColor: '#FFF',
                            textAlign: 'left',
                            boxShadow: '0vw 0.5vh 1vh rgba(0, 0, 0, 0.1)',

                        }}>


                            <div style={{
                                marginLeft: '5vw',
                                marginRight: '5vw',
                                marginTop: '3vh',
                                marginBottom: '3vh'

                            }}>
                                <p style={{
                                    color: '#936846',
                                    fontWeight: '400',
                                    fontSize: '3rem',
                                    fontStyle: 'normal',
                                    lineHeight: '1',
                                    margin: '0'
                                }}>Foundation</p>

                                <p style={{
                                    color: '#000',
                                    fontWeight: '400',
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    lineHeight: '1',
                                    margin: '0'
                                }}>
                                    For small businesses looking to explore creative possibilities with access to
                                    incredible artwork for up to 2 canvases.
                                </p>
                            </div>
                            {/* Horizontal Divider */}
                            <div style={{
                                width: '100%',
                                height: '0.2vh',
                                backgroundColor: '#000',
                                margin: '1vh 0'
                            }}></div>
                            <div style={{
                                height: '10vh',
                                marginLeft: '5vw',
                                marginRight: '5vw',
                                marginTop: '3vh',
                            }}>
                                <p style={{
                                    fontSize: '3rem',
                                    fontWeight: '400',
                                    fontStyle: 'normal',
                                    margin: '0',
                                    lineHeight: '1',
                                }}>£0
                                </p>
                                <p style={{
                                    fontSize: '1rem',
                                    fontWeight: '400',
                                    fontStyle: 'normal',
                                    margin: '0',
                                    lineHeight: '1',

                                }}>device /
                                    year
                                </p>
                            </div>


                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '3vh',

                            }}>
                                <button
                                    className="homeMainButton black-border-hover-white"
                                    onClick={() => navigate('/pro/support/request-demo')}
                                    style={{
                                        width: "16.375rem",
                                        borderWidth: '0.3vh',
                                        borderStyle: 'solid'

                                    }}
                                >
                                    <p className={'homeMainButtonLnk homeOverV'}
                                       style={{
                                           margin: 0,
                                           fontSize: '0.875rem',
                                           fontWeight: '700',
                                           textAlign: 'center'
                                       }}>
                                        CONTACT US
                                    </p>
                                </button>
                            </div>
                            {/* Horizontal Divider */}
                            <div style={{
                                width: '100%',
                                height: '0.2vh',
                                backgroundColor: '#000',
                                margin: '2vh 0'
                            }}></div>

                            <p style={{
                                fontSize: '1rem',
                                marginTop: '3vw',
                                marginLeft: '5vw',
                                marginRight: '5vw',
                                fontWeight: '400',
                                fontStyle: 'normal'
                            }}><span style={{color: '#936846'}}>Up to 2</span> Textura Pro
                                Canvases</p>
                            <p style={{
                                fontSize: '0.875rem',
                                marginLeft: '5vw',
                                marginRight: '5vw',
                                fontWeight: '600',
                                fontStyle: 'normal',
                                marginTop: '2vh',
                                marginBottom: '2vh'
                            }}>FOUNDATION INCLUDES</p>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '5vw',
                                gap: '1vw',
                                marginRight: '5vw',
                                marginBottom: '2vh'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                                     fill="none">
                                    <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg>
                                <p style={{fontSize: '1rem', fontStyle: 'normal', fontWeight: '400', margin: '0'}}>
                                    Access to CC0 Artwork
                                </p>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '5vw',
                                gap: '1vw',
                                marginRight: '5vw',
                                marginBottom: '3vh'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                                     fill="none">
                                    <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg>
                                <p style={{
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    marginRight: '2vw',
                                    margin: '0',
                                }}>
                                    Dedicated Technical Support
                                </p>
                            </div>

                        </div>

                    </div>
                    <div style={{
                        backgroundColor: '#FFF',
                        marginTop: '8vh',
                        marginBottom: '4vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',

                    }}>
                        {/* Advanced Plan */}
                        <div style={{
                            border: '0.5vw solid #06467a',
                            borderRadius: '2vw',
                            width: '60vw',
                            backgroundColor: '#FFF',
                            textAlign: 'left',
                            boxShadow: '0vw 0.5vh 1vh rgba(0, 0, 0, 0.1)',
                        }}>
                            <div style={{
                                backgroundColor: '#EBF1F5',
                                borderTopLeftRadius: '2vw',
                                borderTopRightRadius: '2vw',
                                padding: '1vh',
                                textAlign: 'center'
                            }}>
                                <p style={{
                                    fontSize: '1rem',
                                    color: '#000',
                                    fontWeight: '600',
                                    margin: '0'
                                }}>
                                    MOST POPULAR
                                </p>
                            </div>
                            <div style={{
                                marginLeft: '5vw',
                                marginRight: '5vw',
                                marginTop: '3vh',
                                marginBottom: '3vh'

                            }}>
                                <p style={{
                                    color: '#936846',
                                    fontWeight: '400',
                                    fontSize: '3rem',
                                    fontStyle: 'normal',
                                    lineHeight: '1',
                                    margin: '0'
                                }}>Advanced</p>

                                <p style={{
                                    color: '#000',
                                    fontWeight: '400',
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    lineHeight: '1',
                                    margin: '0'
                                }}>
                                    For businesses seeking to elevate their visual content without restrictions and
                                    with
                                    dedicated commercial support.
                                </p>
                            </div>
                            {/* Horizontal Divider */}
                            <div style={{
                                width: '100%',
                                height: '0.2vh',
                                backgroundColor: '#000',
                                margin: '1vh 0'
                            }}></div>
                            <div style={{
                                marginLeft: '5vw',
                                marginRight: '5vw',
                                marginTop: '3vw'

                            }}>
                                <p style={{
                                    fontSize: '3rem',
                                    fontWeight: '400',
                                    fontStyle: 'normal',
                                    margin: '0',
                                    lineHeight: '1',

                                }}>£99
                                </p>
                                <p style={{
                                    fontSize: '1rem',
                                    fontWeight: '400',
                                    fontStyle: 'normal',
                                    margin: '0',
                                    lineHeight: '1',
                                }}>device /
                                    year
                                </p>
                            </div>


                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '3vh',
                                marginBottom: '3vh',
                            }}>
                                <button
                                    className="homeMainButton black-border-hover-white"
                                    onClick={() => navigate('/pro/support/request-demo')}
                                    style={{
                                        width: "16.375rem",
                                        backgroundColor: '#06467a',
                                        color: '#FFF',
                                        borderColor: '#06467a',
                                        borderWidth: '0.3vh',
                                        borderStyle: 'solid'

                                    }}
                                >
                                    <p className={'homeMainButtonLnk homeOverV'}
                                       style={{
                                           margin: 0,
                                           fontSize: '0.875rem',
                                           fontWeight: '700',
                                           textAlign: 'center',
                                           color: '#FFF'
                                       }}>
                                        CONTACT US
                                    </p>
                                </button>
                            </div>
                            {/* Horizontal Divider */}
                            <div style={{
                                width: '100%',
                                height: '0.2vh',
                                backgroundColor: '#000',
                                margin: '2vh 0'
                            }}></div>

                            <p style={{
                                fontSize: '1rem',
                                marginTop: '2vw',
                                marginLeft: '5vw',
                                marginRight: '5vw',
                                fontWeight: '400',
                                fontStyle: 'normal'
                            }}><span style={{color: '#936846'}}>Unlimited</span> Textura Pro Canvases</p>
                            <p style={{
                                fontSize: '0.875rem',
                                marginLeft: '5vw',
                                marginRight: '5vw',
                                fontWeight: '600',
                                fontStyle: 'normal',
                                marginTop: '2vh',
                                marginBottom: '2vh'
                            }}>EVERYTHING IN FOUNDATION, AND</p>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '5vw',
                                gap: '1vw',
                                marginRight: '5vw',
                                marginBottom: '2vh'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                                     fill="none">
                                    <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg>
                                <p style={{fontSize: '1rem', fontStyle: 'normal', fontWeight: '400', margin: '0'}}>
                                    Access to advanced CC0 Artwork
                                </p>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '5vw',
                                gap: '1vw',
                                marginRight: '5vw',
                                marginBottom: '2vh'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                                     fill="none">
                                    <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg>
                                <p style={{
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    marginRight: '5vw',
                                    margin: '0'
                                }}>
                                    Exclusive free access to priced artwork
                                </p>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '5vw',
                                gap: '1vw',
                                marginRight: '5vw',
                                marginBottom: '3vh'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                                     fill="none">
                                    <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg>
                                <p style={{
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    marginRight: '5vw',
                                    margin: '0'
                                }}>
                                    Dedicated Commercial Support
                                </p>
                            </div>


                        </div>
                    </div>
                    <div style={{
                        backgroundColor: '#FFF',
                        marginTop: '8vh',
                        marginBottom: '4vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',

                    }}>
                        {/* Premium Plan */}
                        <div style={{
                            border: '0.4vw solid #3F3F3F',
                            borderRadius: '2vw',
                            width: '60vw',
                            backgroundColor: '#FFF',
                            textAlign: 'left',
                            boxShadow: '0vw 0.5vh 1vh rgba(0, 0, 0, 0.1)',
                        }}>

                            <div style={{
                                marginLeft: '5vw',
                                marginRight: '5vw',
                                marginTop: '3vh',
                                marginBottom: '3vh',
                            }}>
                                <p style={{
                                    color: '#936846',
                                    fontWeight: '400',
                                    fontSize: '3rem',
                                    fontStyle: 'normal',
                                    margin: '0',
                                    lineHeight: '1'

                                }}>Premium</p>

                                <p style={{
                                    color: '#000',
                                    fontWeight: '400',
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    margin: '0',
                                    lineHeight: '1'

                                }}>
                                    For organisation ready for comprehensive management and total control of an
                                    extensive art collection.
                                </p>
                            </div>
                            {/* Horizontal Divider */}

                            <div style={{
                                width: '100%',
                                height: '0.2vh',
                                backgroundColor: '#000',
                                margin: '1vh 0'
                            }}></div>
                            <div style={{
                                height: '10vh',
                                marginLeft: '5vw',
                                marginRight: '5vw',
                            }}>
                                <p style={{
                                    fontSize: '3rem',
                                    fontWeight: '400',
                                    fontStyle: 'normal',
                                    margin: '0',
                                    lineHeight: '1',
                                    marginTop: '3vw'
                                }}>£189
                                </p>
                                <p style={{
                                    fontSize: '1rem',
                                    fontWeight: '400',
                                    fontStyle: 'normal',
                                    margin: '0',
                                    lineHeight: '1',

                                }}>device /
                                    year
                                </p>
                            </div>

                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '3vh',
                                marginBottom: '3vh',
                            }}>
                                <button
                                    className="homeMainButton black-border-hover-white"
                                    onClick={() => navigate('/pro/support/request-demo')}
                                    style={{
                                        width: "16.375rem",
                                        borderWidth: '0.3vh',
                                        borderStyle: 'solid'
                                    }}
                                >
                                    <p className={'homeMainButtonLnk homeOverV'}
                                       style={{
                                           margin: 0,
                                           fontSize: '0.875rem',
                                           fontWeight: '700',
                                           textAlign: 'center'
                                       }}>
                                        CONTACT US
                                    </p>
                                </button>
                            </div>


                            {/* Horizontal Divider */}
                            <div style={{
                                width: '100%',
                                height: '0.2vh',
                                backgroundColor: '#000',
                                margin: '2vh 0'
                            }}></div>

                            <p style={{
                                fontSize: '1rem',
                                marginTop: '2vw',
                                marginLeft: '5vw',
                                marginRight: '5vw',
                                fontWeight: '400',
                                fontStyle: 'normal'
                            }}><span style={{color: '#936846'}}>Unlimited</span> Textura Pro Canvases</p>
                            <p style={{
                                fontSize: '0.875rem',
                                marginLeft: '5vw',
                                marginRight: '5vw',
                                fontWeight: '600',
                                fontStyle: 'normal',
                                marginTop: '2vh',
                                marginBottom: '2vh'
                            }}>EVERYTHING IN ADVANCED, AND</p>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '5vw',
                                gap: '1vw',
                                marginRight: '5vw',
                                marginBottom: '2vh'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                                     fill="none">
                                    <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg>
                                <p style={{fontSize: '1rem', fontStyle: 'normal', fontWeight: '400', margin: '0'}}>
                                    Unlimited Cloud Storage
                                </p>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '5vw',
                                gap: '1vw',
                                marginRight: '5vw',
                                marginBottom: '2vh'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                                     fill="none">
                                    <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg>
                                <p style={{
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    marginRight: '2vw',
                                    margin: '0'
                                }}>
                                    Premium playlists
                                </p>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '5vw',
                                gap: '1vw',
                                marginRight: '5vw',
                                marginBottom: '2vh'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                                     fill="none">
                                    <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg>
                                <p style={{
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    marginRight: '2vw',
                                    margin: '0'
                                }}>
                                    Platform Management Services
                                </p>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '5vw',
                                gap: '1vw',
                                marginRight: '5vw',
                                marginBottom: '3vh'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                                     fill="none">
                                    <path d="M1 6L5.5 10.5L15 1" stroke="#936846" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg>
                                <p style={{
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    marginRight: '2vw',
                                    margin: '0'
                                }}>
                                    Artwork Content Management
                                </p>
                            </div>


                        </div>
                    </div>
                    {/*<div style={{height: '7rem'}}></div>*/}
                    <HomeProSection2/>

                    <Row justify={"center"} className='pro-subscription-FAQ'>
                        <Col span={20} lg={20} md={20}>
                            <Collapse expandIconPosition={"end"}
                                      bordered={false}
                                      style={{
                                          backgroundColor: '#FFF', color: '#FFF', border: "none"
                                      }}
                                      expandIcon={(panelProps) => {
                                          if (panelProps.isActive) {
                                              return <MinusOutlined style={{fontSize: '24px', fontWeight: "900"}}/>
                                          } else {
                                              return <PlusOutlined style={{fontSize: '24px', fontWeight: "900"}}/>
                                          }
                                      }}
                            >
                                <div className="ProSubFAQ">
                                    Frequently Asked Questions
                                </div>
                                <Panel header="How does the subscription plan work with Textura Pro?"
                                       className={'help-center-collapse-copy'}
                                       key="1">
                                    <p>When you purchase a subscription plan for Vieunite Pro, it
                                        automatically links to your user account and the Textura Pro device,
                                        assigning
                                        the
                                        right
                                        number of licenses to the number of Textura Pros purchased. This streamlined
                                        process
                                        ensures businesses can now tailor the licenses to their specific needs when
                                        deploying
                                        Textura Pro displays. In addition, it allows for effortless remote
                                        management of
                                        all
                                        Textura Pro displays through your Vieunite Pro platform. Additionally,
                                        selecting
                                        a
                                        subscription plan is essential for you to pair all your Textura Pros under
                                        your
                                        user
                                        account. While our Standard plan is a free subscription, it is limited to
                                        displaying
                                        contents on up to two Textura Pro devices. It is important to note that your
                                        subscription plan license is linked to both the Textura Pro hardware and
                                        your
                                        user
                                        account. This means all hardware under each user account must be covered by
                                        the
                                        same
                                        subscription plan, so as to grant you access to all the features offered
                                        under
                                        the
                                        Standard (free), Advanced and Premium subscription plans.</p>
                                </Panel>
                                <Panel header="Do I need a subscription plan for every Textura Pro?"
                                       className={'help-center-collapse-copy'}
                                       key="2">
                                    <p>Yes, you need a Vieunite Pro subscription plan license for every Textura Pro
                                        device.
                                        All
                                        subscription license links to your account rather than the Textura Pro
                                        device
                                        itself.
                                        This setup allows for flexibility in assigning required number of licenses
                                        to
                                        the
                                        Textura Pro units.</p>
                                </Panel>
                                <Panel
                                    header="Can I purchase a Textura Pro for my business without a subscription plan?"
                                    key="3" className={'help-center-collapse-copy'}>
                                    <p>No, Vieunite Pro subscription plans licenses are absolutely required to
                                        unlock
                                        the
                                        functionality of every Textura Pro device. That is to say, every Textura Pro
                                        must be
                                        linked with a subscription plan license so that it can be controlled via the
                                        Vieunite
                                        Pro Platform, where the Textura Pro device can then access all the fantastic
                                        features,
                                        including the art library, and marketplace artworks etc.</p>
                                </Panel>
                                <Panel
                                    header="How can I purchase additional Textura Pro digital canvases and subscription plans as my business needs grows?"
                                    key="4" className={'help-center-collapse-copy'}>
                                    <p>
                                        Every Textura Pro device requires a subscription plan license which is
                                        linked to
                                        your
                                        user account. The subscription plan must be the same for all Textura Pro
                                        devices
                                        so
                                        as
                                        to allow smooth control through the Vieunite Pro Platform. For example, say
                                        if
                                        you
                                        have
                                        subscribed to an annual Premium Plan for 10 Textura Pros on 1 Jun 2024 which
                                        ends on
                                        31
                                        May 2025. If at any time during your subscription period, your business
                                        needs
                                        grow
                                        and
                                        you wish to increase the number of Textura Pro from 10 to 20 devices at the
                                        same
                                        Premium
                                        subscription plan, kindly reach out to us via email (support@vieunite.com)
                                        or
                                        phone
                                        (0121-421-4458). Our dedicated Commercial Support team will assist you in
                                        acquiring
                                        10
                                        more Textura Pros (total of 20 devices). We will stack on 10 Premium
                                        subscription
                                        licenses (total of 20 licenses) at a pro-rated basis to your account, which
                                        ends
                                        on
                                        the
                                        same date of your first purchase, i.e., 31 May 2025. Rest assured that you
                                        will
                                        only
                                        be
                                        charged a pro-rated subscription for the additional 10 licenses for the
                                        remaining
                                        subscription period. For all queries, we are always happy to assist at
                                        support@vieunite.com and 0121-421-4458 during office hours.
                                    </p>
                                </Panel>
                                <Panel header="Do you offer trials for Vieunite Pro subscription plans?" key="5"
                                       className={'help-center-collapse-copy'}>
                                    <p>Yes, by default, we offer all customers a no-obligation, thirty (30) day free
                                        trial
                                        to
                                        all paid Advanced and Premium Plans. Kindly note that there is no free trial
                                        for
                                        the
                                        Standard Plan as it is already offered free to users for content display on
                                        up
                                        to
                                        two
                                        (2) Textura Pro devices. For the trial period, you will be contacted by
                                        email
                                        seven
                                        (7)
                                        days before trial ends to confirm your purchase of Products to link with
                                        your
                                        desired
                                        plans. Payment is done through an authorised FCA-compliant third-party
                                        payment
                                        provider.
                                        Once you have subscribed to our plans, the minimum subscription period is
                                        one
                                        year.
                                        You
                                        will be contacted by email thirty (30) days before subscription period ends
                                        to
                                        remind
                                        you about renewal of your subscription plans. As part of our service
                                        commitment,
                                        we
                                        also
                                        further send you a reminder email seven (7) days subscription period ends to
                                        confirm
                                        the
                                        renewal of your plans.</p>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>

                    {/*<Row justify={'center'} style={{margin: '3vh auto 4vh auto'}}>*/}
                    {/*    <Col style={{*/}
                    {/*        width: '40vw',*/}
                    {/*        borderRadius: '20px',*/}
                    {/*        background: 'rgba(122, 6, 59, 0.08)',*/}
                    {/*        marginLeft: '-1vw',*/}
                    {/*        display: 'flex',*/}
                    {/*        flexDirection: 'column',*/}
                    {/*        justifyContent: 'center',*/}
                    {/*        alignItems: 'flex-start',*/}
                    {/*        paddingLeft: '4vw'*/}
                    {/*    }}>*/}
                    {/*        <p className={'pro-contact-section-p'}>*/}
                    {/*            Ready to redefine art and business with Vieunite Pro?*/}
                    {/*        </p>*/}
                    {/*        <button*/}
                    {/*            className="homeMainButton"*/}
                    {/*            style={{width: "12.6vw", minWidth: "200px", marginTop: '-1vh'}}*/}
                    {/*            onClick={_ => window.location.href = "/contact"}*/}
                    {/*        >*/}
                    {/*            <a className={'homeMainButtonLnk homeOverV'}>*/}
                    {/*                CONTACT US*/}
                    {/*            </a>*/}
                    {/*        </button>*/}
                    {/*    </Col>*/}
                    {/*    <Col style={{width: '40vw'}}>*/}
                    {/*        <img src={img1} style={{width: '40vw', borderRadius: '20px', marginLeft: '1vw'}}/>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <ContactSection
                        bgColor="rgba(122, 6, 59, 0.08)"
                        text="Ready to redefine art and business with Vieunite Pro?"
                        buttonText="CONTACT US"
                        link={"/pro/support/request-demo"}
                    />
                </div>
            </div>
        </div>
    );
}

function HomeProSection2() {
    const {windowWidth} = useWindowResize();

    const page1 = [
        [
            "Digital Display Control",
            "Easily control up to two digital displays via our portal.", "link"],
        [
            "Free Cloud Storage",
            "Store your personal content and artworks in the cloud.", "link"],
        [
            "Various Free Artwork",
            "Access all artwork collection under the Creative Commons license.", "link"
        ],
        [
            "Technical Support",
            "Email and telephone technical support (Mon-Fri). ", "link"
        ]
    ];

    const page2 = [
        [
            "20+ Expertly Arranged Playlists for All Industries",
            "Deliver your message with impact and clarity. Each playlist is more than visuals—it's a powerful storytelling tool for your brand."
        ],
        [
            "2000+ Selected Artworks",
            "Explore our art collections, featuring works from world-renowned galleries and hundreds of artists, specially curated for impactful visual experiences."
        ],
        [
            "One-to-one Commercial Support Team",
            "Get 24/7 support via WhatsApp Business, ensuring seamless communication and quick solutions for all your needs."
        ],
        [
            "Unlimited Display Control",
            "Manage unlimited digital canvases with ease, giving you full control to showcase captivating content across multiple platforms."
        ]
    ];

    const page3 = [
        [
            "Exclusive Customised Playlists Just For You",
            "Blend eye-catching art with corporate messages, promotions, and campaigns for an optimised experience by collaborating with our curation experts."
        ],
        [
            "World-Class Masterpieces",
            "Free access to paid and rare collections from leading artists and institutions."
        ],
        [
            "Content Management and Publication",
            "An experienced specialist is on hand to expertly manage and seamlessly publish content on  Textura Pro digital canvases whenever required."
        ],
        [
            "Delicated Support Team",
            "We can help you address all commercial and technical issues as quickly as possible."
        ]
    ]

    const SectionTitle1 =
        <p className={windowWidth < 900 ? "home-pro-section-title-mobile" : "home-pro-section-title"}>
            Free access to curated content for <span style={{color: '#936846'}}> Foundation </span> License users
        </p>

    const SectionTitle2 =
        <p className={windowWidth < 900 ? "home-pro-section-title-mobile" : "home-pro-section-title"}
           style={{direction: "rtl"}}>
            Playlists and artwork crafted for our <span style={{color: '#936846'}}> Advanced </span> License holders
        </p>

    const SectionTitle3 =
        <p className={windowWidth < 900 ? "home-pro-section-title-mobile" : "home-pro-section-title"}>
            Exclusive bespoke content available to our <span style={{color: '#936846'}}> Premium </span> License
            users
        </p>

    return (
        windowWidth > 900 ?
            <div>
                <TLayout
                    HeaderSection={SectionTitle1}
                    CardList={page1}
                    BackgroundImg={section_background_1}/>

                <TLayoutReversed
                    HeaderSection={SectionTitle2}
                    CardList={page2}
                    BackgroundImg={section_background_2}
                    BackgroundColor='#e8eef2'
                />

                <TLayout
                    HeaderSection={SectionTitle3}
                    CardList={page3}
                    BackgroundImg={section_background_3}/>
            </div> :
            <div>
                <TLayoutMobileStyle2
                    HeaderSection={SectionTitle1}
                    CardList={page1}
                    BackgroundImg={section_background_1}/>
                <TLayoutMobileStyle2
                    HeaderSection={SectionTitle2}
                    CardList={page2}
                    BackgroundImg={section_background_2}
                    BackgroundColor='#e8eef2'
                    Reversed={true}
                />
                <TLayoutMobileStyle2
                    HeaderSection={SectionTitle3}
                    CardList={page3}
                    BackgroundImg={section_background_3}/>
            </div>
    );
}

export default Subscription;
