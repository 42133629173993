import React from "react";

export const RepeatIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
            <path
                d="M17.8155 4.39844L20.8481 7.30678L10.9673 7.2819C6.53027 7.2819 2.88867 10.9235 2.88867 15.3854C2.88867 17.6102 3.79595 19.636 5.26254 21.1026"
                stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M12.8441 26.7745L9.81152 23.8661L19.6923 23.891C24.1294 23.891 27.771 20.2494 27.771 15.7875C27.771 13.5627 26.8637 11.5369 25.3971 10.0703"
                stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.3629 10.0717L20.8486 7.58594" stroke="white" stroke-width="3.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M12.2982 21.108L9.8125 23.5938" stroke="white" stroke-width="3.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    )
}
export const ShuffleIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                                      viewBox="0 0 44 44" fill="none">
    <path
        d="M5.85352 32.9953L10.4222 33.0132C12.0527 33.0132 13.5756 32.207 14.4714 30.8632L25.9202 13.6991C26.816 12.3554 28.3389 11.5312 29.9693 11.5491L38.1214 11.585"
        stroke="white" stroke-width="3.5" stroke-linecap="round"
        stroke-linejoin="round"/>
    <path d="M34.5195 36.5794L38.1029 32.9961" stroke="white"
          stroke-width="3.5" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path
        d="M16.4065 16.2257L14.4714 13.5382C13.5577 12.2661 12.0885 11.5136 10.5297 11.5316L5.85352 11.5495"
        stroke="white" stroke-width="3.5" stroke-linecap="round"
        stroke-linejoin="round"/>
    <path
        d="M23.7168 28.3379L25.9026 31.1508C26.8164 32.3333 28.2497 33.032 29.7547 33.032L38.1218 32.9962"
        stroke="white" stroke-width="3.5" stroke-linecap="round"
        stroke-linejoin="round"/>
    <path d="M38.1029 11.5677L34.5195 7.98438" stroke="white"
          stroke-width="3.5" stroke-linecap="round"
          stroke-linejoin="round"/>
</svg>