import React from 'react';
import './style.scss'

function ProPrivacyPolicy() {
    return (
        <div className="privacy-policy-container">
            <p><strong>Vieunite Pro Privacy Policy</strong></p>
            <p>Date updated: 29 April 2024</p>
            <p>
                This Privacy Policy ("<strong>Policy</strong>") explains how we collect, use, and process your data when
                you use our
                website ("<strong>Website</strong>"),
                our Vieunite Pro Platform ("Platform"), our Vieutopia app ("<strong>App</strong>"), and email messages
                that we send to
                you (jointly called "<strong>Services</strong>").
                If anything here applies to only one of our Services or to customers in a particular country, we'll
                explicitly point this out to you.
            </p>
            <p>
                By continuing your interactions with us, such as by submitting information to us, or using our Services,
                you confirm that you understand
                and consent to the collection, use, disclosure, and processing of your data (or the data of any
                individual within your business entity
                which you provide) as described in this Privacy Policy.
            </p>
            <hr/>
            <ol>
                <strong>
                    <li>Data we collect about you</li>
                </strong>
                <strong>
                    <li>How we protect your information</li>
                </strong>
                <strong>
                    <li>Ways we use your information</li>
                </strong>
                <strong>
                    <li>Disclosure of your data</li>
                </strong>
                <strong>
                    <li>Sharing and storing your data</li>
                </strong>
                <strong>
                    <li>Profiling and automated decision making</li>
                </strong>
                <strong>
                    <li>Cookies</li>
                </strong>
                <strong>
                    <li>Data retention</li>
                </strong>
                <strong>
                    <li>Your rights</li>
                </strong>
                <strong>
                    <li>Changes to our Privacy Policy</li>
                </strong>
                <strong>
                    <li>Contact</li>
                </strong>
            </ol>
            <hr/>
            <div>To help you understand our Privacy Policy, we have introduced a summary below.</div>
            <div><strong>1. Data we collect about you</strong></div>
            <div>Your data means any information about you as our business customer. This includes data made available
                from you about responsible persons and individuals in your business. It does not include anonymous data,
                which cannot be linked back to you. We will collect and process data about you as follows:
            </div>
            <div><strong>1.1 Information you give us.</strong></div>
            <ul>
                <li>You may give us information about yourself when you sign up to use our Services, e.g. when you
                    provide us with corporate details including your business name, trading name, phone number(s), and
                    email address(es). This also includes information you provide through your continued use of our
                    Services, your participation in the Marketplace Artwork within the Platform and App or similar
                    functions on our Website or App, or through reporting problems with our Services. Additional
                    information you give us for security, identification, and verification purposes may include,
                    inter-alia, your information on the responsible person with whom we should contact, recurring
                    payment schedule, geographic location, company registration number, proof of address, proof of
                    incorporation, and VAT registration number. If you fail to provide any of this information, it might
                    affect our ability to provide our Services to you.
                </li>
                <li>The content of your communications with us, which we collect via telephone call recordings, emails,
                    WhatsApp Business messaging, and other relevant means.
                </li>
            </ul>
            <div><strong>1.2 Information we collect about you.When you use our Services</strong> When you use our
                Services, we may collect the following information:
            </div>
            <ul>
                <li>details of the transactions you carry out when using our Services, including the geographic location
                    from which the transaction originates;
                </li>
                <li>technical information, including the internet protocol (IP) address used to connect your device to
                    the Platform dashboard, your login information, serial numbers of the Products you purchased, time
                    zone setting, browser plug-in types and versions, operating system and platform, and, if you’ve
                    installed the App, installed applications on your mobile device that have remote access and control
                    permissions;
                </li>
                <li>information about your visit, including the full Uniform Resource Locators (URL) to, through and
                    from our Website or App (including date and time), Creative Commons open license and paid artworks
                    you viewed or searched for, page response times, download errors, length of visits to certain pages,
                    page interaction information (including scrolling, clicks, and mouse-overs), and methods used to
                    browse away from the page and any phone number used to call our Customer Support service;
                </li>
                <li>information about your marketing and communication preferences.
                </li>
            </ul>
            <div><strong>1.3 Information we receive from other sources.</strong>We may receive information about you if
                you use any of the other websites we operate or the other services we provide. We also work closely with
                third parties and may receive information about you from them. This may include:
            </div>
            <ul>
                <li>business resellers or distributors may provide us with your business name, trading name, and
                    address, as well as other relevant information;
                </li>
                <li>advertising networks, analytics providers and search information providers may provide us with
                    pseudonymised information about you, including confirmation of how you found our Vieunite Pro
                    website;
                </li>
                <li>in some jurisdictions, we may check the information you have provided to us with governmental or
                    business records databases or with credit reference agencies to confirm your business and to combat
                    fraud.
                </li>
            </ul>
            <div><strong>1.4 Information from social networks.</strong></div>
            <ul>
                <li>If you log into our Services using your social network account, we will receive relevant
                    information that is necessary for us to improve our Services to you. The social network will provide
                    us with access to certain information that you have provided to them, including your name, image and
                    email address, in accordance with the social network service provider’s own privacy policy. We use
                    such information, together with any other information you directly provide to us when purchasing our
                    Products and Services , to create your account and to communicate with you about the information,
                    products and services that you request from us.
                </li>
            </ul>
            <div><strong>2. How we protect your information</strong></div>
            <div><strong>2.1 We take the safeguarding of your business information very seriously. </strong>The
                transmission of information via the internet is not completely secure. Although we do our best to
                protect your business data, we cannot guarantee the security of your data during transmission, and any
                transmission is at your own risk. Once we have received your information, we use strict procedures and
                security features to ensure it stays secure.
            </div>
            <div><strong>2.2</strong> Our third-party payment processor are regularly audited to confirm we remain
                compliant with
                world-leading security certifications, including SOC 2 and PCI-DSS. As part of these audits, our
                appointed payment processor is validated by external auditors as well as the Financial Conduct
                Authority.
            </div>
            <div><strong>2.3</strong> We restrict access to your information to our own employees who have a legitimate
                business reason for knowing such information and other third party service providers’ processing data on
                our behalf. All Vieunite employees who have access to your data are required to adhere to this Policy
                and all third-party service providers are requested by Vieunite to ensure appropriate safeguards are in
                place. In addition, contracts are in place with third-party service providers that have access to your
                data, to ensure that the level of security and protective measures required in your jurisdiction is in
                place, and that your data is processed only as instructed by Vieunite.
            </div>
            <div><strong>2.4</strong> We continuously educate and train our employees about the importance of
                confidentiality and privacy of customer information. We maintain physical, technical and organisational
                safeguards that comply with applicable laws and regulations to protect your information from
                unauthorised access.
            </div>
            <div><strong>3 Ways we use your information</strong></div>
            <div><strong>3.1 Lawful basis: </strong>We will only use your data when the law allows us to. Depending on
                the country in which you are in, we rely on the following legal bases to process your data:
            </div>
            <ul>
                <li>Where you have given us your consent to process your data - please note that when we are processing
                    your data on the basis of consent, the applicable local regulations apply;
                </li>
                <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests
                    and fundamental rights do not override those interests;
                </li>
                <li>Where we have a legal obligation to process your data to comply with laws, regulations or court
                    orders;
                </li>
                <li>Where it is necessary to fulfil our obligations under a contract with you;
                </li>
                <li>Where it is necessary to protect the vital interests of yourself or other individuals.
                </li>
            </ul>
            <div><strong>3.2 Purposes for which we will use your data: </strong>the ways we plan to use your data are
                described below, including which of the legal bases we rely on to do so in the UK, and in suitable
                cases, the EU.
            </div>
            <ul>
                <li>to carry out our obligations relating to your contract with us for provision of payments services
                    and multi-currency accounts
                </li>
                <li>to provide you with information, products and services
                </li>
                <li>to comply with any applicable legal and/or regulatory requirements, including to respond to
                    requests from public and government authorities, including public and government authorities outside
                    your country of residence, upon demonstration of lawful authority, and to comply with court orders
                    from the relevant jurisdiction.
                </li>
                <li>to prevent and detect crimes, including fraud and financial crime
                </li>
                <li>as part of our efforts to keep our Services safe and secure
                </li>
                <li>to administer our Services and for internal operational, planning, audit, troubleshooting, data
                    analysis, testing, research, statistical and survey purposes
                </li>
                <li>to undertake system or product development, including helping third party suppliers improve the
                    services they provide to us, to improve our Services and to ensure that they are presented in the
                    most effective manner
                </li>
                <li>to allow other Vieunite customers to request or send money to you through our services when
                    providing information that matches your phone number or email address
                </li>
                <li>to measure or understand the effectiveness of advertising we serve and to deliver relevant
                    advertising to you
                </li>
                <li>to use your email address to provide you with information about other similar goods and services
                    we offer
                </li>
                <li>to provide you, or permit selected third parties to provide you, with information about goods or
                    services we feel may interest you.
                </li>
                <li>to take steps to recover amounts owed to us, including via insurance claims, and to allow us to
                    pursue available remedies or limit damages that we may sustain
                </li>
                <li>to allow a third party or a financial institution that sent money to recover money received by you
                    in error or due to fraud
                </li>
                <li>to verify information you provide to us and to enforce our Customer Agreement with you
                </li>
                <li>on rare occasions, to help safeguard our customers, their employees or other individuals by
                    notifying the emergency services
                </li>
            </ul>
            <div><strong>4. Disclosure of your data</strong></div>
            <div><strong>4.1 We may share your data with the following third parties:</strong></div>
            <ul>
                <li>affiliates, business partners, suppliers and subcontractors for the performance and execution of
                    any contract we enter into with them or you and to help them improve the services they provide to
                    us;
                </li>
                <li>advertisers and advertising networks to select and serve relevant adverts to you and others;
                </li>
                <li>analytics and search engine providers that assist us in the improvement and optimisation of our
                    site;
                </li>
                <li>in the event that we sell any of our business or assets or combine with another organisation, in
                    which case we may disclose your data to the prospective buyer of such business or assets or
                    prospective organisation with which our business or assets may be combined;
                </li>
                <li>if we are under a duty to disclose or share your data in order to comply with any legal
                    obligation, or in order to enforce or apply our Customer Agreement and other applicable agreements,
                    or to protect the rights, property, or safety of Vieunite, our customers, our employees or others;
                </li>
                <li>to prevent and detect fraud or crime and to assist us in conducting or co-operating with
                    investigations of fraud or other illegal activity where we believe it is reasonable and appropriate
                    to do so. Please note that if we, or a fraud prevention agency, determine that a fraud or money
                    laundering risk is posed, we may refuse to provide the services requested or we may stop providing
                    existing products and services to a customer. A record of any fraud or money laundering risk will be
                    retained by the fraud prevention agencies, and may result in others refusing to provide services.
                </li>
                <li>in response to a subpoena, warrant, court order, properly constituted police request or as
                    Vieunite required by law;
                </li>
                <li>if you consent, to share your details when using our Services
                </li>
            </ul>
            <div>4.2 If you would like further information about who we have shared your data with, or to be provided
                with a list specific to you, you can request this by writing to <a
                    href="mailto:privacy@vieunite.com">privacy@vieunite.com</a>.
            </div>
            <div><strong>5. Sharing and storing your data</strong></div>
            <div><strong>5.1</strong> While our main servers are in the UK, we may transfer your data to and store it in
                countries
                outside of the UK jurisdiction, for example, in the EU region, which may or may not offer an equivalent
                level of protection to the UK. It may also be processed by staff operating outside of the UK
                jurisdiction. payment details and the like. We will, however, take all steps reasonably necessary to
                ensure that your data is treated with utmost security and in accordance with this Policy.
            </div>
            <div><strong>5.2</strong> In the above cases, we ensure that appropriate safeguards, including Standard
                Contractual Clauses and/or International Data Transfer Agreements, are in place. A copy of these
                documents can be provided by sending a request to <a
                    href="mailto:privacy@vieunite.com">privacy@vieunite.com</a>
            </div>
            <div><strong>6. Profiling and automated decision making</strong></div>
            <div><strong>6.1</strong> We may use some elements of your data to customise our Services and the
                information
                we provide to you, and to address your needs, including your country of residence and transaction
                history. When we do this, we take all necessary measures to ensure that your privacy and security are
                protected - and we use pseudonymised data wherever possible. This activity has no legal effect on you.
            </div>
            <div><strong>7. Cookies</strong></div>
            <div>We use small files known as cookies to distinguish you from other users and see how you use our
                website, Products and Services while providing you with the best experience. They also enable us to
                improve our services.
            </div>
            <div><strong>8. Data retention</strong></div>
            <div><strong>8.1</strong> We will retain your data only for as long as is necessary to fulfil the purposes
                for which we collected it. As a regulated financial institution, Vieunite is required by law to store
                some of your and transactional data beyond the closure of your account with us. We only access your data
                internally on a need-to-know basis, and we’ll only access or process it if absolutely necessary.
            </div>
            <div><strong>8.2</strong> We will always delete data that is no longer required by a relevant law or
                jurisdiction in which we operate. We do this automatically, so you don’t need to contact us to ask us to
                delete your data. Deletion methods include shredding, destruction and secure disposal of hardware and
                hard-copy records, and deletion or over-writing of digital data.
            </div>
            <div><strong>9. Your rights</strong></div>
            <div><strong>9.1</strong> Laws applicable in your jurisdiction may grant you certain rights regarding the
                information we hold about you. If you have any questions in relation to our use of your information,
                contact us at <a href="mailto:privacy@vieunite.com">privacy@vieunite.com</a>.
            </div>
            <div><strong>9.2</strong> Your exercise of these rights may be subject to certain exemptions, including to
                safeguard the public interest (including the prevention or detection of crime), where it is in our
                interests (including the maintenance of legal privilege), and where the rights of other persons are
                involved (including where your request relates to information about other individuals).
            </div>
            <div><strong>9.3</strong> We may need to retain certain information for record-keeping purposes and to
                comply with our obligations under applicable laws and regulations, including but not limited to our
                anti-money laundering obligations, and/or to complete any transactions that you began prior to
                requesting a change or deletion.
            </div>
            <div><strong>9.4</strong> You will not normally have to pay a fee to access your data or to exercise any of
                the other rights. However, we may charge a reasonable fee where permitted by local laws or if your
                request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with
                your request in these circumstances.
            </div>
            <div><strong>9.5</strong> We may need to request specific information from you to help us confirm your
                identity and your right to access your data or to exercise any of your other rights. This is a security
                measure to ensure that data is not disclosed to any person who has no right to receive it. We may also
                contact you to ask you for further information in relation to your request to speed up our response.
            </div>
            <div><strong>9.6</strong> If you no longer want to receive marketing-related emails from us, you may
                opt-out by following the instructions in any such email. We will try to comply with your request as soon
                as reasonably practicable. Please note that if you opt-out, we may still send you important
                administrative messages, from which you cannot opt-out.
            </div>
            <div><strong>9.7</strong> If you would like to make a request, the best way to do so would be to contact us
                at the contact details listed in the Appendix.
            </div>
            <div><strong>9.8 Subject to some country-specific variations, you have the right to:</strong></div>
            <ul>
                <li><strong>Request a copy of the data we hold about you and to check that we are lawfully processing
                    it.</strong>
                </li>
                <li><strong>Request correction of the data that we hold about you.</strong>We may need to verify the
                    accuracy of the new data you provide to us.
                </li>
                <li><strong>Ask us to delete data where there is no good reason for us to continue to process
                    it.</strong>You may also
                    have the right to ask us to delete your data where (i) you have successfully exercised your right to
                    object to processing (see below), (ii) where we may have processed your data unlawfully or (iii)
                    where we are required to delete your data to comply with local law. We may not always be able to
                    comply with your deletion request for specific legal reasons which will be notified to you, if
                    applicable, in our response to your request, including financial regulations that may require us to
                    hold your data for a period after the closure of your account.
                </li>
                <li><strong>Withdraw your consent for us to process data, where our lawful basis for processing is based
                    on
                    that consent. </strong>Note that withdrawal of consent does not affect the lawfulness of processing
                    which may
                    have taken place prior to withdrawal of consent. If you withdraw your consent, we may not be able to
                    provide certain products or services to you.
                </li>
                <li><strong>Request us to cease direct marketing to you, or profiling you for the purpose of direct
                    marketing,</strong>
                    by contacting us or adjusting your notification preferences in the settings section of your account.
                </li>
                <li><strong>Where we use wholly automated decision making-processes, request that we provide information
                    about
                    the decision-making methodology and ask us to verify that an automated decision that results in a
                    legal impact on you has been made correctly.</strong> We may reject the request, as permitted by
                    applicable
                    law, including when providing the information would result in a disclosure of a trade secret or
                    would interfere with the prevention or detection of fraud or other crime. However, generally in
                    these circumstances we will verify that the algorithm and source data are functioning as anticipated
                    without error or bias or if required by law to adjust the processing.
                </li>
                <li><strong> Object to any processing based on the legitimate interests ground when there is something
                    about
                    your particular situation where you feel processing on this ground impacts your fundamental rights
                    and freedoms.</strong>
                </li>
                <li><strong>Ask us to suspend the processing of your data in the following situations: </strong>(i) if
                    you want us to
                    determine the data's accuracy; (ii) where our processing of the data is unlawful but you do not want
                    us to delete it at this time; (iii) where you wish us to retain the data even if we no longer
                    require it because you need it to establish, exercise or defend legal claims; or (iv) you have
                    objected to us using your data but we need to confirm whether or not we have over-riding legitimate
                    grounds to continue using it.
                </li>
                <li><strong> Request the transfer of your data to a third party or yourself. </strong>We will provide
                    you or your chosen third party with the data you provided to us in a structured, commonly used,
                    machine-readable format. This right applies only to information where we used the information to
                    perform a contract with you or where you initially consented for us to use it.
                </li>
            </ul>
            <div><strong>10. Changes to our Privacy Policy</strong> <br/>To keep up with changing legislation, best
                practice
                and changes in how we process information, we may revise this Policy at any time by posting a revised
                version on this website. To stay up to date on any changes, check back periodically.
            </div>
            <div><strong>11.Contact</strong></div>
            <div><strong>11.1</strong> Please send any questions, comments or requests about this Policy to our privacy
                team at <a href="mailto:privacy@vieunite.com">privacy@vieunite.com</a>, where you can reach our Data
                Protection Officer.
            </div>
            <div><strong>11.2</strong> If you feel that we have not addressed your questions or concerns adequately, or
                you believe that your data protection or privacy rights have been infringed, you can complain to any
                supervisory authority or other public body with responsibility for enforcing business privacy laws.
            </div>
            {/*<div className={'final-style'}>*/}
            {/*    <strong>X X X</strong>*/}
            {/*</div>*/}
        </div>
    );
}

export default ProPrivacyPolicy;
