import React, {useState} from 'react';
import './style.scss';
import {Button, Card, Col, Layout, Modal, Row, Table, Tag, Tooltip} from "antd";
import {Content} from "antd/es/layout/layout";
import ProPortalHeader from "../../componets/ProPortalHeader";
import ProfileRow from "../../componets/ProfileRow";
import Navigator from "../../componets/Navigator";
import {ReactComponent as TickCircle} from "./imgs/Tick Circle.svg";
import {ReactComponent as CrossCircle} from "./imgs/Cross Circle.svg";
import {ReactComponent as InfoButton} from "./imgs/Info button.svg";
import {useNavigate} from "react-router-dom";


const MyAccount = () => {
    const [activeTab, setActiveTab] = useState('subscription');

    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <Col lg={24} style={{
                                background: '#F4F4F4',
                            }}>
                                <div className="ma-tabs-container">
                                    <Button
                                        className={`${activeTab === 'subscription' ? 'ma-active-tab' : 'ma-inactive-tab'}`}
                                        onClick={() => setActiveTab('subscription')}
                                    >
                                        Subscription
                                    </Button>
                                    <Button
                                        className={`${activeTab === 'additional' ? 'ma-active-tab' : 'ma-inactive-tab'}`}
                                        onClick={() => setActiveTab('additional')}
                                    >
                                        Additional Services
                                    </Button>
                                </div>
                                {activeTab === 'additional' && <AdditionalServices/>}
                                {activeTab === 'subscription' && <SubscriptionPro/>}
                            </Col>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>);
};
const AdditionalServices = () => {
    return (
        <Layout>
            <Content className="custom-portalContent-background">
                <Card className="ma-additional-services">
                    <div className={"ma-title"}>
                        Additional Services
                    </div>
                    {/*<div className={"ma-sub-title"}>*/}
                    {/*    Exclusive for Premium Users*/}
                    {/*</div>*/}
                    <div className="ma-service">
                        <div className="ma-service-header">
                            Allocated artist for bespoke playlists
                        </div>
                        <div className={"ma-service-content"}>
                            Premium users have exclusive access to nearly 100 leading artists from our Vieunite Pro
                            Platform. Users can specially commission their preferred artists to work on creating
                            meticulously tailored playlists catered for their businesses. As every business is unique,
                            our commissioned artists absolutely understand the importance of their role in assisting you
                            to find the most suitable style, and to craft curated playlists that shouts out your brand.
                            Kindly contact us through <strong><a
                            href="mailto:support@vieunite.com"
                            style={{color: 'black', textDecoration: 'underline'}}>email</a></strong> or at 03455-481311
                            to get a quotation and commission a
                            Vieunite Pro artist of your choice to work for you.
                        </div>
                        <hr/>
                        <div className="ma-service-header">
                            Device Installation
                        </div>
                        <div className={"ma-service-content"}>
                            On request, our dedicated partners provide device installation for all your Textura Pro
                            digital canvases at all the locations you specify. Prices are based on business requirements
                            and other requests. Kindly contact us through <strong><a
                            href="mailto:support@vieunite.com"
                            style={{color: 'black', textDecoration: 'underline'}}>email</a></strong> or at 03455-481311
                            to get a quotation
                            and have your appointment booked.
                        </div>
                        <hr/>
                        <div className="ma-service-header">
                            On-site Maintenance Service
                        </div>
                        <div className={"ma-service-content"}>
                            Where necessary, our dedicated partners offer on-site survey and maintenance service to
                            ensure that all your Textura Pro displays are in tip-top working condition. You can take
                            pride in the work of the systems integration provided by our carefully appointed partners,
                            who will provide the best services at extremely reasonable prices. Kindly contact us via
                             <strong><a
                                href="mailto:support@vieunite.com"
                                style={{color: 'black', textDecoration: 'underline'}}> email</a></strong> or 03455-481311
                            to get a quotation and have your appointment booked with our on-site
                            maintenance partners.
                        </div>
                        <hr/>
                        <div className="ma-service-header">
                            Advance replacement
                        </div>
                        <div className={"ma-service-content"}>
                            In the event of faulty Textura Pro displays, we get you one advance replacement device,
                            delivered to your business address, no questions asked. We offer this exclusive service
                            within the 3-year warranty period for all Textura Pro digital canvases.
                        </div>
                        <hr/>
                        <div className="ma-service-header">
                            Extension of Warranty
                        </div>
                        <div className={"ma-service-content"}>
                            All Textura Pro digital canvases come with a complimentary 3-year warranty. Additional
                            warranty can be purchased at £60.00 per year. Kindly contact us via <strong><a
                            href="mailto:support@vieunite.com"
                            style={{color: 'black', textDecoration: 'underline'}}>email</a></strong> or 03455-481311
                            for the purchase of additional warranty.
                        </div>
                    </div>
                </Card>
                <div className="ma-contact-section">
                    <div className="ma-CallText">Call us on 03455-481311</div>
                    <p></p>
                    <Button className="ma-EmailButton"
                            onClick={() => window.location = 'mailto:info@vieunite.com?subject=Mail from Our Website'}>Email
                        us
                    </Button>
                </div>
            </Content>
        </Layout>);
};

const SubscriptionPro = () => {
    const features = [
        {
            key: '1',
            feature: 'Number of Textura Pro Canvases Displayable',
            info: 'Standard Plan allows users’ media to be concurrently displayed on up to 2 Textura digital canvases.Both Advanced and Premium Plans allows users to display their media on unlimited number of Textura devices.',
            standard: 'Up to 2',
            advanced: 'Unlimited',
            premium: 'Unlimited',
        },
        {
            key: '2',
            feature: 'Cloud storage for your media (photos, images, videos)',
            info: 'Standard Plan allows users to store and display their media, such as artworks, images, photos, videos etc., on 500MB of cloud storage space.Advanced Plan provides a 4GB cloud space, while Premium Plans offers an unlimited cloud space for users’ own media.',
            standard: '500MB',
            advanced: '4GB',
            premium: 'Unlimited',
        },
        {
            key: '3',
            feature: 'Access to Classic Creative Commons Artwork (CC0)',
            info: 'Access an unlimited number of royalty-free artworks under the Creative Commons license. As a Standard Plan user, one can easily browse through our vast collection of royalty-free artworks in the Art Library within Vieunite Pro Platform.',
            standard: <TickCircle/>,
            advanced: <TickCircle/>,
            premium: <TickCircle/>,
        },
        {
            key: '4',
            feature: 'Access to Advanced Royalty-free Classic Artwork (CC0)',
            info: 'Advanced and Premium Plan gives users access to expertly curated advanced playlist to choose from in the Marketplace Artwork. Users are able to choose from all the advanced artwork playlist specially curated, both from classical art pieces to current artwork from living artists.',
            standard: <CrossCircle/>,
            advanced: <TickCircle/>,
            premium: <TickCircle/>,
        },
        {
            key: '5',
            feature: 'Access to Premium Playlist with Rare Artwork',
            info: 'Premium Plan users enjoy exclusively unlimited access to all premium playlists expertly curated by the Art Curation Team. They also have unlimited access to Premium Playlists that comprise both paid and rare masterpiece collection from leading artists and world-class institutions both in the UK as well as overseas.',
            standard: <CrossCircle/>,
            advanced: <CrossCircle/>,
            premium: <TickCircle/>,
        },
        {
            key: '6',
            feature: 'Priced Artwork',
            info: 'Standard Plan users can browse the Vieunite Pro’s Marketplace Artwork section to discover a treasure trove of pay-as-priced artworks from leading artists around the world, as well as other world-class museums and galleries. Advanced and Premium Plan users have exclusive unlimited access to all the artworks within Vieunite Pro’s Marketplace Artwork section. Users will be have free access to a treasure trove of all artworks from leading artists around the world, including masterpieces and other rare collections from world-class museums and galleries, both in the UK and overseas.',
            // showIcon: false,
            standard: 'Pay as priced',
            advanced: 'Unlimited',
            premium: 'Unlimited',
        },
        {
            key: '7',
            feature: 'Platform Management Services',
            info: 'Premium Plan users can leave the management of Vieunite Pro platform to our designated executive who can assist to publish desired content on demand, and schedule content over the Textura displays at specific dates in accordance with the needs of the business.',
            standard: <CrossCircle/>,
            advanced: <CrossCircle/>,
            premium: <TickCircle/>,
        },
        {
            key: '8',
            feature: 'Artwork Content Management with your own dedicated Art Curator',
            info: 'Premium Plan users get exclusive, bespoke playlists and premium art content expertly curated by our art curation team. In accordance with business requirements, our expert art curation team meticulously blends arts with corporate messages/promotions/campaigns/other business displays for an optimised brand experience. In essence, our dedicated art curation team led by UK’s key opinion leader in arts curation, Dr. Benedict Carpenter van Barthold, promises a new way of curating premium art playlists tailored to the user’s brand by combining arts with other pertinent business content provided by the user. While we do not design the corporate media provided, we make sure that the combined bespoke playlists creates the wow factor for the brand.',
            standard: <CrossCircle/>,
            advanced: <CrossCircle/>,
            premium: <TickCircle/>,
        },
        // {
        //     key: '9',
        //     feature: 'Dedicated designer for bespoke playlists',
        //     // showIcon: false,
        //     info: 'Premium Plan further allows users to have bespoke playlist carefully tailored by a dedicated designer. The designer ensures that the bespoke playlist are designed to best differentiate Premium Plan users’ businesses.',
        //     standard: <CrossCircle />,
        //     advanced: <CrossCircle />,
        //     premium:  <TickCircle />,
        // },
        {
            key: '10',
            feature: 'Dedicated Technical Support Team (Telephone and Email Support)',
            info: 'Our technical support team will provide the essential email and telephone technical support (Mon-Fri) for all Standard Plan users. Advanced Plan users will be allocated a dedicated technical support team which will provide priority email and telephone support that meets the Service Level Agreement (SLA) of offering 99.9% uptime, responding within 24 working hours (Mon-Fri) for all technical support issues. Premium Plan users will be further allocated a dedicated technical support team which will provide urgent email and telephone support that meets the Service Level Agreement (SLA) of offering 99.9% uptime, responding within 6 working hours (Mon-Fri) for all technical support issues.',
            standard: <TickCircle/>,
            advanced: <TickCircle/>,
            premium: <TickCircle/>,
        },
        {
            key: '11',
            feature: 'Dedicated Commercial Support Team (WhatsApp Business)',
            info: 'Premium Plan users enjoy an exclusive and dedicated Commercial Support Team which will be the essential point of contact for all matters related to Vieunite Pro solution suite. Users will have undivided attention through a 24/7 WhatsApp Business Support service looking after their Premium account.',
            standard: <CrossCircle/>,
            advanced: <TickCircle/>,
            premium: <TickCircle/>,
        },
    ];
    const columns = [
        {
            title: '',
            dataIndex: 'feature',
            key: 'feature',
            render: (text, record) => (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <span>{text}</span>
                    {record.showIcon !== false && (
                        <Tooltip title={record.info}>
                            <InfoButton/>
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            title: <span className='SubTable-title'>Standard</span>,
            dataIndex: 'standard',
            key: 'standard',
            align: 'center',
            width: '20%',
        },
        {
            title: <span className='SubTable-title'>Advanced</span>,
            dataIndex: 'advanced',
            key: 'advanced',
            align: 'center',
            width: '20%',
        },
        {
            title: <span className='SubTable-title'>Premium</span>,
            dataIndex: 'premium',
            key: 'premium',
            align: 'center',
            width: '20%',
        },
    ];
    const navigate = useNavigate();
    const handleChangeUpdateClick = () => {
        navigate('/proportal/UpgradeSubscription');
    };
    const handleChangeUpdateClickPremium = () => {
        navigate('/proportal/UpgradeSubscriptionPremium');
    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    // const showModal = () => {
    //     setIsModalVisible(true);
    // };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <Layout>
            <Content className="custom-portalContent-background">
                <div className="plan-titles-container">
                    {localStorage.getItem('subscription') === 'Standard' ?
                        <Row justify="space-around">
                            <Col className="Sub-Title-above1">
                                Your current plan
                            </Col>
                            <Col className="Sub-Title-above2">
                                Most popular
                            </Col>
                        </Row> : <></>}
                    {localStorage.getItem('subscription') === 'Advanced' ?
                        <Row justify="space-around">
                            <Col className="Sub-Title-above3">
                                Your current plan
                            </Col>
                            <Col className="Sub-Title-above4">
                                Upgrade to Premium
                            </Col>
                        </Row> : <></>}
                    {localStorage.getItem('subscription') === 'Premium' ?
                        <Row justify="space-around">
                            <Col className="Sub-Title-above5">
                                Your current plan
                            </Col>
                        </Row> : <></>}
                </div>
                <Card className="Subportal-Card">
                    <Table dataSource={features} columns={columns} pagination={false} className="custom-portal-table"/>
                    <div className="PortalButton-container">
                        {localStorage.getItem('subscription') === 'Standard' ?
                            <Button className="PortalSubUpgrade-Button" onClick={handleChangeUpdateClick}>
                                <div className="PortalSubCall-Button-text">
                                    Upgrade
                                </div>
                            </Button> : <></>}
                        {localStorage.getItem('subscription') === 'Standard' || localStorage.getItem('subscription') === 'Advanced' ?
                            // <Button className="PortalSubUpgrade-Button" onClick={showModal}>
                            <Button className="PortalSubUpgrade-Button" onClick={handleChangeUpdateClickPremium}>
                                <div className="PortalSubCall-Button-text">
                                    Upgrade
                                </div>
                            </Button> : <></>}
                        {/*<Modal title="Premium Subscription"*/}
                        {/*       visible={isModalVisible}*/}
                        {/*       onOk={handleOk}*/}
                        {/*       onCancel={handleCancel}*/}
                        {/*       className="custom-subscription-modal"*/}
                        {/*       footer={[*/}
                        {/*           <Button key="submit" type="primary" onClick={handleOk}*/}
                        {/*                   className="custom-modal-ok-button">*/}
                        {/*               OK*/}
                        {/*           </Button>,*/}
                        {/*       ]}*/}
                        {/*>*/}
                        {/*    <p className="modal-text-guidance" style={{ margin: '-20px 20px 20px' }}>Please contact our team for expert guidance on Premium*/}
                        {/*        level subscription.</p>*/}
                        {/*    <br/>*/}
                        {/*    <p className="modal-text-guidance">Call: <strong>01234 567 890</strong></p>*/}
                        {/*    <p className="modal-text-guidance">or email: <strong>help@vieuinite.com</strong></p>*/}
                        {/*</Modal>*/}
                    </div>

                </Card>
            </Content>
        </Layout>);
}

export default MyAccount;
